import React from "react";
import { Route } from "react-router-dom";
import { cookieService } from "../_services";
import Dashboard from "../pages/Dashboard/dashboard";
import { STRING_CONSTANTS } from "../utils/constants/stringConstants";
import SignUp from "../pages/Signup/index";
import EmployerProfileCompletion from '../pages/EmployerProfileCompletion/index';
import EmployeeProfileCompletion from "../pages/EmployeeProfileCompletion";
import { Data } from "../_services/collections";

const PublicRoute = ({ path, component: Comp, ...rest }) => {
  let user = cookieService.getCookie(STRING_CONSTANTS.cookieStrings.user) as Data

  function getComponent(props) {
    if (!user) {
      return <Comp {...props} />
    }
    if (user.user.isProfileCompleted) {
      return <Dashboard {...props} />
    }
    return <SignUp {...props} />
  }

  return (
    <Route
      path={path}
      {...rest}
      render={getComponent}
    />
  );
};

export default PublicRoute