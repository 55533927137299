import React from 'react'
import './Mission.css'

function Mission() {
  return (
    <div className='mission-wrapper'>
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-6 col-md-12 ">
                    <div className='mission-content'>
                        <div className='description'>
                        MPLOI is on a mission to revolutionise the way businesses connect with candidates, and we need your help to spread the word. Imagine the impact you could have on a struggling small business owner by introducing them to features like:

                        </div>
                        <div className='mission-list'>
                            <ul>
                                <li>AI-assisted job posting that attract the perfect candidates</li>
                                <li>Candidate geo-mapping to find local talent</li>
                                <li>An AI interview simulator to streamline the hiring process</li>
                                <li>Real-time AI job matching that saves times and money</li>
                                <li>AI Hr and Legal Assistants </li>
                                <span>And so much more!</span>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-12 ">
                    <div className='mission-img-desktop text-center'>
                        <img src='/assets/images/mission-card.png' className='img-fluid' alt=''/>
                    </div>
                    <div className='mission-img-mob text-center'>
                        <img src='/assets/images/mission-card-mob.png'  alt=''/>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Mission