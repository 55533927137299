import { combineReducers } from "redux";
import subscriptionReducer from "./subscriptionReducer";
import userReducer from "./userReducer";
import newJobReducer from "./newJobReducer";
import commonReducer from "./common";
import unreadNotificationCount from "./unreadNotificationCount";
import employeesList from "./employeesList";
import jobsList from "./jobsListReducer";
import jobFilterSearch from "./jobFilterSearchSortReducer";
import referralCode from "./referralCode";
import { store } from "../store";
import { ActionType } from "../constants";
import chatReducer from "./chatReducer";
import subscriptionPlans from "./subscriptionPlans";

const reducers = combineReducers({
  subscription: subscriptionReducer,
  user: userReducer,
  common: commonReducer,
  newJob: newJobReducer,
  unreadNotificationCount: unreadNotificationCount,
  employeesList: employeesList,
  jobsList: jobsList,
  jobFilterSearch: jobFilterSearch,
  referralCode: referralCode,
  chatReducer: chatReducer,
  subscriptionPlans: subscriptionPlans,
});

const rootReducer = (state: State, action) => {
  if (action.type === ActionType.RESET) {
    state = {} as State;
  }
  return reducers(state, action);
};

export default rootReducer;
export type State = ReturnType<typeof reducers>;
// export type State = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
