import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import './SubscriptionInNav.css';
import { userService } from "../../../_services";
import SubscriptionModal from "../Home/SubscriptionModal";
import { api } from "../../../middleware/api";

const SubsCard = (props: any) => {
    const [btnActive, setBtnActive] = useState(false);
    const [isPlanActive, setIsPlanActive] = useState(1);
    const [subsModalVisibility, setSubsModalVisibility] = useState(false);
    const [isSubPurchased, setIsSubPurchased] = useState(false);
    const [secondSubsModal, setSecondSubsModal] = useState(false);
    const toggleButtonClass = () => {
        setBtnActive(!btnActive);
    }
    const togglePlanClass = (val) => {
        setIsPlanActive(val);
    }
    // const handlePlanSelected = (index) => {
    //     setIsPlanSelected(index);
    //   };
    const handlleSubsPlan = () => {
        if (isSubPurchased && props.setChangePlan) {
            props?.setChangePlan(true);
            props?.setProductIdFromChild(props.product__Id);
        } else {
            setSubsModalVisibility(true);
            setSecondSubsModal(true);
        }
    }

    const getUserSubscription = () => {
        api.get('subscription/', {})
            .then(res => {
                if (res?.active) {
                    setIsSubPurchased(true)
                }
            }).catch((err) => {
                console.log(err)
            })
    }
    useEffect(() => {
        getUserSubscription();
    }, []);

    return <div className="" style={{ paddingBottom: '20px' }} onClick={props.onClick}>
        <div className="sub-box">
            <div className="row w-100 m-auto align-items-center me-0">
                <div className="col-md-5 col-6 p-0">
                    <div className="type-box align-items-center">
                        <div>{props?.title}</div>
                        {/* <div className="ps-1  d-flex align-items-start">
                            <img src={props.imgsrc} alt="badge" />
                        </div> */}
                    </div>
                </div>
                {/* <div className="col-md-7 col-6 p-0 center">

                    <div className="d-flex ps-0 align-items-start btn btn-secondary">
                        <div className="px-1 d-flex align-items-start">
                            <img src="/assets/images/Shape_clock.png" alt="c" />
                        </div>
                        <div >
                            <div className="btn_extra1">free trial until</div>
                            <div className="btn_extra2">24/05/22</div>
                        </div>
                    </div>

                </div> */}
            </div>
            {props.advance === "true" ?
                <div className="row w-100 m-auto mt-2 center" style={{ justifyContent: 'center', paddingTop: '20px' }}>
                    <div className="col-auto p-0">
                        <div className="contact-sales">{props.price}</div>
                    </div>
                </div>
                : props.monthly === "true" ?
                    <div className="row w-100 m-auto mt-2 center" style={{ justifyContent: 'center', paddingBottom: '23px' }}>
                        <div className="col-auto p-0">
                            <div className="contact-sales" style={{ fontSize: "32px", marginTop: "28px" }}>{props.price}</div>
                        </div>
                    </div>
                    :
                    <div className="row w-100 m-auto mt-2 center" style={{ justifyContent: 'center', paddingTop: '20px' }}>
                        <div className="col-auto p-0">
                            <div className="price_nav">{props.price}</div>
                        </div>
                        <div className="d-flex" style={{ alignItems: 'center', margin: '0px auto', justifyContent: 'center' }}>
                            <span style={{ color: '#F2F2F2', fontSize: '30px', textDecoration: 'line-through', textDecorationColor: 'var(--Turquoise, #06EFC5)' }}>
                                {props.orignalPrice}
                            </span>
                            <span style={{ color: '#FFFFFF', fontSize: '14px', paddingLeft: '10px' }}>/yearly</span>
                            <span style={{ color: 'var(--Turquoise, #06EFC5)', fontSize: '12px', paddingLeft: '5px' }}>
                                ({props.discount}% OFF)
                            </span>
                        </div>
                        {/* <img src={line} alt="" /> */}

                    </div>
            }
            {/* <div className="row">
                <div className="col divPointer" onClick={()=>togglePlanClass(1)}>
                    <div className="price-wise my-4">monthly</div>
                    <div className="row w-100 m-auto mt-2 center" style={{ justifyContent: 'center' }}>
                        <div className="col-auto p-0">
                            <div className="price_nav">{props.monthPrice}</div>
                        </div>
                    </div>
                    {isPlanActive === 1 ? <div className="hrcard mt-4" />:null }
                    
                </div>
                <div className="col divPointer" onClick={()=>togglePlanClass(2)}>
                    <div className="price-wise my-4">yearly</div>
                    <div className="row w-100 m-auto mt-2 center" style={{ justifyContent: 'center' }}>
                        <div className="col-auto p-0">
                            <div className="price_nav">{props.price}</div>
                        </div>
                    </div>
                    {isPlanActive === 2 ? <div className="hrcard mt-4" />:null }
                </div>
            </div> */}
            <div className="hrcard" style={{ marginLeft: '-20px' }}></div>


            <div className="list mt-4">
                <div className="row w-100 d-flex align-items-center list-row mt-3 ">
                    <div className="col-auto d-flex align-items-center " >
                        <div className="d-flex align-items-start">
                            <img src="/assets/images/Checkmark.png" className="check" />
                        </div>
                        <div className="col-auto ">
                            <div className="list-item">Unlimited Job Posts</div>
                        </div>
                    </div>
                </div>
                <div className="row w-100 list-row mt-3">
                    <div className="col-auto d-flex align-items-center"  >
                        <div className="d-flex align-items-start" style={{ paddingRight: "4px", paddingLeft: "0px" }} >
                            <img src="/assets/images/Checkmark.png" className="check" />
                        </div>
                        <div className="col-auto">
                            <div className="list-item">Instant Matching to Candidates </div>
                        </div>
                    </div>
                </div>
                <div className="row w-100 list-row mt-3">
                    <div className="col-auto d-flex align-items-center" >
                        <div className="d-flex align-items-start" style={{ paddingRight: "4px", paddingLeft: "0px" }} >
                            <img src="/assets/images/Checkmark.png" className="check" />
                        </div>
                        <div className="col-auto">
                            <div className="list-item">Direct Chat with Candidates </div>
                        </div>
                    </div>
                </div>
                <div className="row w-100 list-row mt-3">
                    <div className="col-auto d-flex align-items-center" >
                        <div className="d-flex align-items-start" style={{ paddingRight: "4px", paddingLeft: "0px" }} >
                            <img src="/assets/images/Checkmark.png" className="check" />
                        </div>
                        <div className="col-auto">
                            <div className="list-item">Fixed Price. No Add-Ons</div>
                        </div>
                    </div>
                </div>
                <div className="row w-100 list-row mt-3">
                    <div className="col-auto d-flex align-items-center" >
                        <div className="d-flex align-items-start" style={{ paddingRight: "4px", paddingLeft: "0px" }} >
                            <img src="/assets/images/Checkmark.png" className="check" />
                        </div>
                        <div className="col-auto">
                            <div className="list-item">NO RECRUITMENT AGENCIES</div>
                        </div>
                    </div>
                </div>

            </div>
            {/* <div className={`mt-4 ${isSelected?'button1Active':'button1'}`} */}
            <div className={`mt-4`}
            >
                {props.advance ? <a href="mailto:info@mploi.com"><div className="button1Active" >Contact Sales</div> </a> : <div className="button1Active" onClick={() => { handlleSubsPlan(); }}>Select Plan</div>}


            </div>
        </div>
        {
            subsModalVisibility ? <SubscriptionModal
                subsModal={subsModalVisibility}
                setSubsModal={setSubsModalVisibility}
                history={props.history}
                // Second={secondSubsModal}
                // setSecond={(val) => setSecondSubsModal(val)}
                cardId={props.prdct_id}
            // toggleValue={props.toggleValueCard}
            /> : ""
        }

    </div>
}
export default withRouter(SubsCard);
// ()=>setBtnActive(true)