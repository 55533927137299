import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { DatePicker } from "@blueprintjs/datetime";
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
import "@blueprintjs/core/lib/css/blueprint.css";

import ValidationErrorToast from "../../../components/validationErrorToast";
import { useDispatch, useSelector } from "react-redux";
import { actionCreators, State } from "../../../store";
import { bindActionCreators } from "redux";
import moment from "moment";
import { userService } from "../../../_services/user.service"
import { END_POINTS } from "../../../utils/constants/endPoints";
import { api } from "../../../middleware/api";
import { UtilityFunctions } from "../../../utils/UtilityFunctions";

const JobsFilterSort = (props) => {
    const dispatch = useDispatch();
    const { setJobsDateRange, setJobsSort, setJobsList } = bindActionCreators(actionCreators, dispatch);
    const state = useSelector((state: State) => state);

    const [isFromDateOpen, setIsFromDateOpen] = useState<boolean>(false);
    const [isToDateOpen, setIsToDateOpen] = useState<boolean>(false);
    const fromDateRef = useRef<any>();
    const toDateRef = useRef<any>();

    const dateFormatter = (date, days) => {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result.toISOString().substring(0, 10);
    }

    const handleCancel = () => {
        props.setNewestJob(true)
        props.setFromDate(null)
        props.setToDate(null)
        setIsFromDateOpen(false)
        setIsToDateOpen(false)
        setJobsSort(true)
        setJobsDateRange({
            "created_at__date__gte": "",
            "created_at__date__lte": ""
        });
        props?.setShowJobFilter(false);
        userService.fetchAllJobs(END_POINTS.filter_jobs, {
            filter: {
                created_at__date__gte: "",
                created_at__date__lte: "",
            },
            sort: true,
            search: state.jobFilterSearch.search
        }, (res) => {
            setJobsList(res.data)
        });
    }

    useEffect(() => {
        fromDateRef.current.value = state.jobFilterSearch.filter.created_at__date__gte || ""
        toDateRef.current.value = state.jobFilterSearch.filter.created_at__date__lte || ""
    }, [state])

    async function submitHandler() {
        userService.fetchAllJobs(END_POINTS.filter_jobs, state.jobFilterSearch, (res) => {
            setJobsList(res.data)
        });
        props?.setShowJobFilter(false)

    }

    useEffect(() => {
        if (props.fromDate || props.toDate) {
            if (props.fromDate && !props.toDate) {
                setJobsDateRange({
                    "created_at__date__gte": moment.utc(dateFormatter(props?.fromDate, 1)).format("YYYY-MM-DD")
                })
            }
            else if (!props.fromDate && props.toDate) {
                setJobsDateRange({
                    "created_at__date__lte": moment.utc(dateFormatter(props?.toDate, 1)).format("YYYY-MM-DD")
                })
            }
            else if (props.fromDate && props.toDate) {
                setJobsDateRange({
                    "created_at__date__gte": moment.utc(dateFormatter(props?.fromDate, 1)).format("YYYY-MM-DD"),
                    "created_at__date__lte": moment.utc(dateFormatter(props?.toDate, 1)).format("YYYY-MM-DD")
                })
            }
        }
        else if (!props.fromDate && !props.toDate) {
            setJobsDateRange({
                "created_at__date__gte": "",
                "created_at__date__lte": ""
            })
        }
        if (!props.newestJob) {
            setJobsSort(false)
        }
        else if (props.newestJob) setJobsSort(true)
    }, [props.fromDate, props.toDate, props.newestJob])

    const validRangeTest = (from, to) => {
        if (!from && !to) return true
        else if (!from || !to) return true
        else if (from <= to) return true
        else return false
    }

    return props?.showJobFilter ? (<div className="drop-down">
        <div className="top-icon">
            {/* <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="25" height="25" rx="12.5" stroke="white" />
                <path d="M14.125 16C14.4357 16 14.6875 16.2518 14.6875 16.5625C14.6875 16.8732 14.4357 17.125 14.125 17.125H11.875C11.5643 17.125 11.3125 16.8732 11.3125 16.5625C11.3125 16.2518 11.5643 16 11.875 16H14.125ZM16.375 12.25C16.6857 12.25 16.9375 12.5018 16.9375 12.8125C16.9375 13.1232 16.6857 13.375 16.375 13.375H9.625C9.31434 13.375 9.0625 13.1232 9.0625 12.8125C9.0625 12.5018 9.31434 12.25 9.625 12.25H16.375ZM18.625 8.5C18.9357 8.5 19.1875 8.75184 19.1875 9.0625C19.1875 9.37316 18.9357 9.625 18.625 9.625H7.375C7.06434 9.625 6.8125 9.37316 6.8125 9.0625C6.8125 8.75184 7.06434 8.5 7.375 8.5H18.625Z" fill="white" />
            </svg> */}
            <img src={UtilityFunctions.getImageURL() + "filter-round.svg"} alt="" />
        </div>
        <div className="main-title">
            <h3>Sort By Date First</h3>
        </div>
        <div className="radio-buton d-flex">
            <span className={!props.newestJob ? "active" : ""}>Oldest</span>
            <label className="switch">
                <input
                    type="checkbox"
                    // defaultChecked={state?.jobFilterSearch?.sort === false}
                    checked={state.jobFilterSearch.sort}
                    disabled={isFromDateOpen || isToDateOpen}
                    onChange={() => props.setNewestJob(prev => !prev)} />
                <span className="slider round">
                </span>
            </label>
            <span className={props.newestJob ? "active" : ""}>Newest</span>
        </div>
        <form action="">
            <div className="form-floating input-icon-right-side mb-3">
                <input type="text" style={{ caretColor: 'transparent' }} className="form-control" placeholder="From Date"
                    ref={fromDateRef}
                    onClick={() => {
                        setIsFromDateOpen(prev => !prev)
                        setIsToDateOpen(false)
                    }}
                    onChange={e => {
                        if (e.target.value) {
                            ValidationErrorToast('Date Select', 'Please select a date from the dropdown calendar')
                            fromDateRef.current.value = ""
                        }
                    }}
                />
                <label htmlFor="floatingInput">From Date</label>
                <span className="input-group-text">
                    <img src={UtilityFunctions.getImageURL() + "calendar.svg"} alt="" />
                    <img src={UtilityFunctions.getImageURL() + "calendar-fill-blue.svg"} alt="" />
                    {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.5">
                            <path d="M17.75 3C19.5449 3 21 4.45507 21 6.25V17.75C21 19.5449 19.5449 21 17.75 21H6.25C4.45507 21 3 19.5449 3 17.75V6.25C3 4.45507 4.45507 3 6.25 3H17.75ZM19.5 8.5H4.5V17.75C4.5 18.7165 5.2835 19.5 6.25 19.5H17.75C18.7165 19.5 19.5 18.7165 19.5 17.75V8.5ZM7.75 14.5C8.44036 14.5 9 15.0596 9 15.75C9 16.4404 8.44036 17 7.75 17C7.05964 17 6.5 16.4404 6.5 15.75C6.5 15.0596 7.05964 14.5 7.75 14.5ZM12 14.5C12.6904 14.5 13.25 15.0596 13.25 15.75C13.25 16.4404 12.6904 17 12 17C11.3096 17 10.75 16.4404 10.75 15.75C10.75 15.0596 11.3096 14.5 12 14.5ZM7.75 10.5C8.44036 10.5 9 11.0596 9 11.75C9 12.4404 8.44036 13 7.75 13C7.05964 13 6.5 12.4404 6.5 11.75C6.5 11.0596 7.05964 10.5 7.75 10.5ZM12 10.5C12.6904 10.5 13.25 11.0596 13.25 11.75C13.25 12.4404 12.6904 13 12 13C11.3096 13 10.75 12.4404 10.75 11.75C10.75 11.0596 11.3096 10.5 12 10.5ZM16.25 10.5C16.9404 10.5 17.5 11.0596 17.5 11.75C17.5 12.4404 16.9404 13 16.25 13C15.5596 13 15 12.4404 15 11.75C15 11.0596 15.5596 10.5 16.25 10.5ZM17.75 4.5H6.25C5.2835 4.5 4.5 5.2835 4.5 6.25V7H19.5V6.25C19.5 5.2835 18.7165 4.5 17.75 4.5Z" fill="white" />
                        </g>
                    </svg>*/}
                    {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21 8.5V17.75C21 19.5449 19.5449 21 17.75 21H6.25C4.45507 21 3 19.5449 3 17.75V8.5H21ZM7.25 15C6.55964 15 6 15.5596 6 16.25C6 16.9404 6.55964 17.5 7.25 17.5C7.94036 17.5 8.5 16.9404 8.5 16.25C8.5 15.5596 7.94036 15 7.25 15ZM12 15C11.3096 15 10.75 15.5596 10.75 16.25C10.75 16.9404 11.3096 17.5 12 17.5C12.6904 17.5 13.25 16.9404 13.25 16.25C13.25 15.5596 12.6904 15 12 15ZM7.25 10.5C6.55964 10.5 6 11.0596 6 11.75C6 12.4404 6.55964 13 7.25 13C7.94036 13 8.5 12.4404 8.5 11.75C8.5 11.0596 7.94036 10.5 7.25 10.5ZM12 10.5C11.3096 10.5 10.75 11.0596 10.75 11.75C10.75 12.4404 11.3096 13 12 13C12.6904 13 13.25 12.4404 13.25 11.75C13.25 11.0596 12.6904 10.5 12 10.5ZM16.75 10.5C16.0596 10.5 15.5 11.0596 15.5 11.75C15.5 12.4404 16.0596 13 16.75 13C17.4404 13 18 12.4404 18 11.75C18 11.0596 17.4404 10.5 16.75 10.5ZM17.75 3C19.5449 3 21 4.45507 21 6.25V7H3V6.25C3 4.45507 4.45507 3 6.25 3H17.75Z" fill="#38BBF4" />
                    </svg>  */}
                </span>
                {/* FROM DATE PICKER */}
                {isFromDateOpen && !isToDateOpen &&
                    <div style={{ background: 'inherit', right: 0 }}>
                        <DatePicker
                            value={props.fromDate}
                            maxDate={new Date()}
                            onChange={e => props.setFromDate(e)}
                            footerElement={
                                <div className="colendbut d-flex">
                                    <button type='button'
                                        onClick={() => {
                                            props.setFromDate(undefined)
                                            fromDateRef.current.value = ""
                                            setIsFromDateOpen(false)
                                        }
                                        }
                                    >
                                        Cancel
                                    </button>
                                    <button type='button' className="rightbut"
                                        onClick={() => {
                                            if (validRangeTest(props.fromDate, props.toDate)) {
                                                fromDateRef.current.value = props.fromDate ? dateFormatter(props.fromDate, 1) : ""
                                                setIsFromDateOpen(false)
                                            }
                                            else ValidationErrorToast("Invalid Range", "Please select a valid date range")
                                        }}
                                    >
                                        Confirm
                                    </button>
                                </div>
                            }
                        />
                    </div>}
            </div>
            <div className="form-floating input-icon-right-side">
                <input type="text" style={{ caretColor: 'transparent' }} className="form-control" placeholder="To Date" ref={toDateRef}
                    onClick={() => {
                        setIsFromDateOpen(false)
                        setIsToDateOpen(prev => !prev)
                    }}
                    onChange={e => {
                        if (e.target.value) {
                            ValidationErrorToast('Date Select', 'Please select a date from the dropdown calendar')
                            toDateRef.current.value = ""
                        }
                    }}
                />
                <label htmlFor="floatingInput">To Date</label>
                <span className="input-group-text">
                    <img src={UtilityFunctions.getImageURL() + "calendar.svg"} alt="" />
                    <img src={UtilityFunctions.getImageURL() + "calendar-fill-blue.svg"} alt="" />
                    {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.5">
                            <path d="M17.75 3C19.5449 3 21 4.45507 21 6.25V17.75C21 19.5449 19.5449 21 17.75 21H6.25C4.45507 21 3 19.5449 3 17.75V6.25C3 4.45507 4.45507 3 6.25 3H17.75ZM19.5 8.5H4.5V17.75C4.5 18.7165 5.2835 19.5 6.25 19.5H17.75C18.7165 19.5 19.5 18.7165 19.5 17.75V8.5ZM7.75 14.5C8.44036 14.5 9 15.0596 9 15.75C9 16.4404 8.44036 17 7.75 17C7.05964 17 6.5 16.4404 6.5 15.75C6.5 15.0596 7.05964 14.5 7.75 14.5ZM12 14.5C12.6904 14.5 13.25 15.0596 13.25 15.75C13.25 16.4404 12.6904 17 12 17C11.3096 17 10.75 16.4404 10.75 15.75C10.75 15.0596 11.3096 14.5 12 14.5ZM7.75 10.5C8.44036 10.5 9 11.0596 9 11.75C9 12.4404 8.44036 13 7.75 13C7.05964 13 6.5 12.4404 6.5 11.75C6.5 11.0596 7.05964 10.5 7.75 10.5ZM12 10.5C12.6904 10.5 13.25 11.0596 13.25 11.75C13.25 12.4404 12.6904 13 12 13C11.3096 13 10.75 12.4404 10.75 11.75C10.75 11.0596 11.3096 10.5 12 10.5ZM16.25 10.5C16.9404 10.5 17.5 11.0596 17.5 11.75C17.5 12.4404 16.9404 13 16.25 13C15.5596 13 15 12.4404 15 11.75C15 11.0596 15.5596 10.5 16.25 10.5ZM17.75 4.5H6.25C5.2835 4.5 4.5 5.2835 4.5 6.25V7H19.5V6.25C19.5 5.2835 18.7165 4.5 17.75 4.5Z" fill="white" />
                        </g>
                    </svg> */}
                    {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21 8.5V17.75C21 19.5449 19.5449 21 17.75 21H6.25C4.45507 21 3 19.5449 3 17.75V8.5H21ZM7.25 15C6.55964 15 6 15.5596 6 16.25C6 16.9404 6.55964 17.5 7.25 17.5C7.94036 17.5 8.5 16.9404 8.5 16.25C8.5 15.5596 7.94036 15 7.25 15ZM12 15C11.3096 15 10.75 15.5596 10.75 16.25C10.75 16.9404 11.3096 17.5 12 17.5C12.6904 17.5 13.25 16.9404 13.25 16.25C13.25 15.5596 12.6904 15 12 15ZM7.25 10.5C6.55964 10.5 6 11.0596 6 11.75C6 12.4404 6.55964 13 7.25 13C7.94036 13 8.5 12.4404 8.5 11.75C8.5 11.0596 7.94036 10.5 7.25 10.5ZM12 10.5C11.3096 10.5 10.75 11.0596 10.75 11.75C10.75 12.4404 11.3096 13 12 13C12.6904 13 13.25 12.4404 13.25 11.75C13.25 11.0596 12.6904 10.5 12 10.5ZM16.75 10.5C16.0596 10.5 15.5 11.0596 15.5 11.75C15.5 12.4404 16.0596 13 16.75 13C17.4404 13 18 12.4404 18 11.75C18 11.0596 17.4404 10.5 16.75 10.5ZM17.75 3C19.5449 3 21 4.45507 21 6.25V7H3V6.25C3 4.45507 4.45507 3 6.25 3H17.75Z" fill="#38BBF4" />
                    </svg> */}
                </span>
                <hr className="line-gradi" />
                <button type="button" className="apply-btn" onClick={submitHandler}>Apply</button>
                <button type="button" className="cancel-btn" onClick={handleCancel}>
                    Cancel
                </button>
                {/* TO DATE PICKER */}
                {isToDateOpen && !isFromDateOpen && <div
                    style={{ background: 'inherit', right: 0 }}>
                    <DatePicker
                        // maxDate={new Date()}
                        minDate={props.fromDate || new Date("2003-01-01")}
                        value={props.toDate || null}
                        onChange={e => props.setToDate(e)}
                        footerElement={
                            <div className="colendbut d-flex">
                                <button type='button'
                                    onClick={() => {
                                        props.setToDate(undefined)
                                        toDateRef.current.value = ""
                                        setIsToDateOpen(false)
                                    }}
                                >
                                    Cancel
                                </button>
                                <button type='button' className="rightbut"
                                    onClick={() => {
                                        if (validRangeTest(props.fromDate, props.toDate)) {
                                            toDateRef.current.value = props.toDate ? dateFormatter(props.toDate, 1) : ""
                                            setIsToDateOpen(false)
                                        }
                                        else ValidationErrorToast("Invalid Range", "Please select a valid date range")
                                    }}
                                >
                                    Confirm
                                </button>
                            </div>
                        }
                    />
                </div>}
            </div>
        </form>
    </div>) : <div></div>
}

export default withRouter(JobsFilterSort)