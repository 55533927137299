import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { UtilityFunctions } from '../../../utils/UtilityFunctions'
import moment from "moment";
import { STRING_CONSTANTS } from '../../../utils/constants/stringConstants';

const ChatReceive = (props) => {
    // console.log("jhtre678yjkljhgfgkgfgh",props)
    const [chatTime, setChatTime] = useState('');
    const [time, setTime] = useState(Date.now())
    const showTime = (currentMsg: any, prevMsg: any) => {
        if (!currentMsg || !prevMsg) {
            return false;
        }
        const currentMsgTime = moment(currentMsg?.createdAt);
        const prevMsgTime = moment(prevMsg?.createdAt);
        const duration = moment.duration(prevMsgTime.diff(currentMsgTime));
        const minutes = duration.asMinutes();
        if (minutes >= 1) return true;

        return false;
    };
    const showDate = (currentMsg: any, prevMsg: any) => {
        if (!currentMsg || !prevMsg) {
            return true;
        }
        const currentMsgTime = moment(currentMsg?.createdAt).format('MMMM Do YYYY');
        const prevMsgTime = moment(prevMsg?.createdAt).format('MMMM Do YYYY');

        if (currentMsgTime === prevMsgTime) {
            return false;
        }
        return true;
    };
    useEffect(() => {
        const intervalCall = setInterval(() => {
            if (moment().diff(props.chat?.createdAt, "hours") > 1) {
                clearInterval(intervalCall)
            }
            else {
                setTime(Date.now())
            }
        }, 60000);
        return () => {
            // clean up
            clearInterval(intervalCall);
        };
    }, []);
    useEffect(() => {
        const currentTime = moment()
        const messageTime = props.chat?.createdAt

        var timeDifferenceInDays = currentTime.diff(messageTime, "days")
        var timeDifferenceInHours = currentTime.diff(messageTime, "hours")
        var timeDifference = currentTime.diff(messageTime, "minutes")
        if (timeDifferenceInDays > 0) {
            setChatTime(moment(messageTime).format('ddd LT'))

        }
        else {
            if (timeDifferenceInHours > 0) {
                if (timeDifferenceInHours < 2) {
                    setChatTime(moment(messageTime).fromNow())
                }
                else {
                    setChatTime(moment(messageTime).format('ddd LT'))
                }

            }
            else {
                if (timeDifference > 0) {
                    if (timeDifference < 2) {
                        setChatTime(moment(messageTime).fromNow())
                    }
                    else {
                        setChatTime(moment(messageTime).fromNow())
                    }
                }
                else {
                    setChatTime("Just Now")
                }
            }
        }
    }, [Date.now()])

    const getDay = date => {
        const startTime = moment(Date.now());
        const end = moment(date);
        const duration = moment.duration(startTime.diff(end));
        const minutes = duration.asMinutes();
        if (minutes < 1) {
            return 'Just Now';
        } else if (minutes < 60) {
            return moment(date).fromNow()
        } else {
            return moment(date).format('ddd')?.toLocaleUpperCase();
        }
    };

    const getTime = date => {
        const startTime = moment(Date.now());
        const end = moment(date);
        const duration = moment.duration(startTime.diff(end));
        const minutes = duration.asMinutes();
        if (minutes < 60) {
            return '';
        } else {
            return moment(date).format('LT');
        }
    };
    // console.log(props)
    return (
        <div>
            {props.chat && showDate(props.chat, props.chatContent[props.index + 1]) ? (
                <div className="time-and-dat-groupByDate  ml-auto">
                    {moment(props.chat?.createdAt).format('MMMM Do YYYY')}
                </div>
            ) : null}
            {props?.chat?.content?.length &&
                <div className="user-cheat-data">
                    <div className="user-imag-head">
                        {props.chatContent[props.index - 1]?.user !== props.chat?.user ?
                            <img
                                className="profil"
                                src={props?.profilePhoto ||
                                    UtilityFunctions.getImageURL() + (props?.userType === STRING_CONSTANTS.userType.employer ? "chat-list-profile.png" : "bildeing-squre-img.png")
                                }
                                alt=""
                            /> : null}
                        <div className="user-cheat-content mb-0">
                            {props.chat.content}
                        </div>
                    </div>

                    <div className="user-client-bot">
                        {props.chat.user !== props.chatContent[props.index - 1]?.user || showTime(props.chat, props.chatContent[props.index - 1]) ?
                            <span className="time-and-dat mt-1">
                                <div className='dots-setup d-flex align-items-center gap-2'>
                                    <span>{getDay(props?.chat?.createdAt)}</span>
                                    {getTime(props?.chat?.createdAt) !== "" && <span>{getTime(props?.chat?.createdAt)}</span>}
                                </div>
                            </span>
                            : null}
                    </div>
                </div>
            }
        </div>
    )
}

export default withRouter(ChatReceive)