/**
 * Not keeping as ENUM, as sometimes urls need to be structured dynamically
 * And this is not supported by ENUMs
 */
// API End Points.
export const END_POINTS = {
  login: "login/",
  forgotPassword: "forgot-password/",
  resetPassword: "user-password-reset-confirm/",
  legalContent: "legal-content/",
  user: "profile/", //Employer
  userResume: "ai/",
  evaluatePhoto: "ai/",
  aiRequest: "ai/",
  signup: "signup/?referral_code=",
  profile: "profile/",
  resend: "send-welcome-mail/", //resend mail
  verifyOtp: "verify-otp/",
  sendOtp: "send-otp/", //resend otp,
  logout: "logout/",
  dashboard: "dashboard/",
  fetchStripeProducts: "stripe/products/",
  stripeSubscription: "stripe/subscription/",
  changePassword: "change-password/",
  setupIntent: "stripe/setup-intent/",
  category: "category/",
  sub_category: "sub-category/",
  filter_jobs: "jobs/all/",
  getAllMploiees: "employee/all/?relatedToJobs=true&sort=distance&jobId=",
  getActiveConversation: "chat/rooms/?jobId=",
  getPendingRequest: "chat/unread-chat-request/?jobId=",
  setJobAllocated: "/",
  transactionHistory: "stripe/transactions/",
  notificationsCount: "user-notifications/?limit=1",
  contactAdmin: "contact-admin/",
  schoolList: "list/school-type/",
  courseList: "list/course/",
  subscritionTransactionHistory: "subscription/transaction-history/",
  chose_subscription: "subscription/",
  year_subscriptions: "subscription/products/?subscription_type=year",
  month_subscriptions: "subscription/products/?subscription_type=month",
};
