import { createSlice } from "@reduxjs/toolkit";
import { fetchSubscriptionMonth, fetchSubscriptionYear, fetchSubscriptionChose } from "./apiThunk";

const subscriptionPlanSlice = createSlice({
  name: "subscriptionPlan",
  initialState: {
    yearSubscriptionPlans: {},
    monthSubscriptionPlan: {},
    choseSubscriptionPlan: {},
  },
  reducers: {
    updateYearSubscriptionPlans: (state, action) => {
      state.yearSubscriptionPlans = action.payload;
    },
    updateMonthSubscriptionPlan: (state, action) => {
      state.monthSubscriptionPlan = action.payload;
    },
    updateChoseSubscriptionPlan: (state, action) => {
      state.monthSubscriptionPlan = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSubscriptionMonth.fulfilled, (state, action) => {
      state.monthSubscriptionPlan = action.payload;
    });
    // builder.addCase(fetchSubscriptionMonth.rejected, (state, action) => {
    //   state.monthSubscriptionPlan = action.payload;
    // });
    builder.addCase(fetchSubscriptionYear.fulfilled, (state, action) => {
      state.yearSubscriptionPlans = action.payload;
    });
    // builder.addCase(fetchSubscriptionYear.rejected, (state, action) => {
    //   state.yearSubscriptionPlans = action.payload;
    // });
    builder.addCase(fetchSubscriptionChose.fulfilled, (state, action) => {
      state.choseSubscriptionPlan = action.payload;
    });
    // builder.addCase(fetchSubscriptionChose.rejected, (state, action) => {
    //   state.choseSubscriptionPlan = action.payload;
    // });
  },
});
const { reducer } = subscriptionPlanSlice;
const selectorSubscriptionPlan = (state) => state.subscriptionPlan;
const { updateYearSubscriptionPlans, updateMonthSubscriptionPlan, updateChoseSubscriptionPlan } =
  subscriptionPlanSlice.actions;
export {
  updateYearSubscriptionPlans,
  updateMonthSubscriptionPlan,
  updateChoseSubscriptionPlan,
  selectorSubscriptionPlan,
};

export default reducer;
