import React, { Component } from "react";
import ValidationErrorToast from "../../components/validationErrorToast";
import { resumeAcceptType } from "../../pages/jobManagement/utils";
import 'react-image-crop/dist/ReactCrop.css'

class FileInput extends Component<Readonly<any>, any> {
    imageRef = null;
    fileUrl = null;
    constructor(props: Readonly<any>) {
        super(props);
        this.state = {
            file: null,
            url: null,
        };
    }

    onSelectFile = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const file = evt?.target?.files[0],
            type = file?.type.split("/");
        if (evt?.target?.value)
            evt.target.value = "";
        if (resumeAcceptType.indexOf(type[1]) !== -1) {
            if (((file.size / 1000) / 1000).toFixed(4) <= this.props.size) {
                const reader = new FileReader();
                reader.addEventListener("load", () => {
                    this.setState({ url: reader.result, file: file });
                    if (this.props?.fileSet) {
                        this.props.fileSet(reader.result);
                    }
                });
                reader.readAsDataURL(file);

            } else {
                ValidationErrorToast("File", `File size more than ${this.props.size} mb is not supported`);
                this.setState({ url: null, isCropperShow: false, file: null });
                document.getElementById("file_id").innerHTML = "";
            }
        } else {
            ValidationErrorToast("File", `File type is not supported: ${type[1]}`);
            this.setState({ url: null, isCropperShow: false, file: null });
            document.getElementById("file_id").innerHTML = "";
        }
    };

    render() {
        return (
            <div>
                <input
                    id="file_id"
                    accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    style={{ display: "none" }}
                    type="file"
                    onInput={this.onSelectFile}
                    className={this.props?.inputClass}
                />
            </div>
        );
    }
}

export default FileInput;
