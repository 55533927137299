/* eslint-disable no-throw-literal */
/* eslint-disable import/first */
import ES6Promise from "es6-promise";
ES6Promise.polyfill();

// import hmac from "js-crypto-hmac";
import HmacSHA256 from "crypto-js/hmac-sha256";
import { getHmacSecretKey } from "../utils/UtilityFunctions";
import axios from "axios";
import { cookieService, userService } from "../_services";
import { UtilityFunctions } from "../utils/UtilityFunctions";
import { STRING_CONSTANTS } from "../utils/constants/stringConstants";
import ValidationErrorToast from "../components/validationErrorToast";

let override = null;
const TIMEOUT_DURATION = 60000;
const BASE_URL = UtilityFunctions.API_URL();
const BASE_V2_URL = UtilityFunctions.API_URL_V2();

// Create Axios instance with base paramaters
const instance = axios.create({
  baseURL: override !== null ? override : BASE_URL,
  timeout: TIMEOUT_DURATION,
  // withCredentials: true,
  validateStatus: function (status: number) {
    return status >= 200 && status < 300; // default
  },
});

const instance_V2 = axios.create({
  baseURL: override !== null ? override : BASE_V2_URL,
  timeout: TIMEOUT_DURATION,
  // withCredentials: true,
  validateStatus: function (status: number) {
    return status >= 200 && status < 300; // default
  },
});

const getPathArray = (url: string) => {
  let urlObj = url.replace(BASE_URL, "");
  let pathArray = urlObj?.split("/");
  return pathArray;
};

const shouldShowLoader = (pathArray: Array<string>) => {
  if (
    pathArray !== null &&
    pathArray.length > 2 &&
    pathArray[pathArray.length - 2] === "chat"
  ) {
    return false;
  }
  if (pathArray.includes("?hideLoading=true")) {
    return false;
  }
  if (pathArray.includes("profile")) {
    return false;
  }
  if (pathArray.includes("jobs")) {
    return false;
  }
  if (
    pathArray !== null &&
    (pathArray.length === 2 || pathArray.length === 3) &&
    pathArray[0] === "user-notifications"
  ) {
    return false;
  }
  if (
    pathArray !== null &&
    pathArray.length === 3 &&
    pathArray[0] === "chat" &&
    pathArray[1] === "rooms"
  ) {
    return false;
  }
  return true;
};

const shouldRemoveAuth = (pathArray: Array<string>) => {
  if (pathArray.includes("check-token")) {
    return true;
  }
  if (pathArray.includes("document-types")) {
    return true;
  }
  return false;
};

const generateHmacKey = (message) => {
  return new Promise((res, rej) => {
    let hash = HmacSHA256(message, getHmacSecretKey());
    res(hash.toString());
  });
};

// Add a request interceptor
instance.interceptors.request.use(
  async (config: any) => {
    let apiVersion = config?.baseURL?.split(UtilityFunctions.BASE_URL())?.[1];
    let API_VERSIONS = "/" + apiVersion;
    let TimeStamp = Date?.now();
    let path = (API_VERSIONS + config?.url).split("?")[0] || "";
    // console.log(API_VERSIONS, config?.url, path);
   
    let hash = await generateHmacKey(
      `${API_VERSIONS}:${TimeStamp}:path=${path}`
    );
    // console.log(API_VERSIONS,TimeStamp,path,hash)

    config.headers["Access-Control-Allow-Headers"] = "*";
    config.headers["x-timestamp"] = `${TimeStamp}`;
    config.headers["x-api-key"] = `${API_VERSIONS}=${hash}`;

    if (shouldShowLoader(getPathArray(config.url))) {
      document.body.classList.add("loading-indicator");
    }
    const accessToken = cookieService.getCookie(
      STRING_CONSTANTS.cookieStrings.token
    );
    if (accessToken && !shouldRemoveAuth(config.url)) {
      // config.headers.common.Authorization = null;
      config.headers[
        STRING_CONSTANTS.API_HEADERS.AUTHORIZATION
      ] = `${STRING_CONSTANTS.API_HEADERS.TOKEN_PREFIX} ${accessToken}`;
    }
    config.headers[STRING_CONSTANTS.API_HEADERS.CONTENT_TYPE] =
      STRING_CONSTANTS.API_HEADERS.TYPE_RAW_DATA;
    // config.headers['Accept'] = 'application/json';
    return config;
  },
  (error: any) => {
    return Promise.reject(error);
  }
);

// Add a request interceptor
instance_V2.interceptors.request.use(
  async (config: any) => {
    let apiVersion = config?.baseURL?.split(UtilityFunctions.BASE_URL())?.[1];
    let API_VERSIONS = "/" + apiVersion;
    let TimeStamp = Date?.now();
    let path = (API_VERSIONS + config?.url).split("?")[0] || "";
    // console.log(API_VERSIONS, config?.url, path);
    let hash = await generateHmacKey(
      `${API_VERSIONS}:${TimeStamp}:path=${path}`
    );

    config.headers["Access-Control-Allow-Headers"] = "*";
    config.headers["x-timestamp"] = `${TimeStamp}`;
    config.headers["x-api-key"] = `${API_VERSIONS}=${hash}`;
    if (shouldShowLoader(getPathArray(config.url))) {
      document.body.classList.add("loading-indicator");
    }
    const accessToken = cookieService.getCookie(
      STRING_CONSTANTS.cookieStrings.token
    );
    if (accessToken && !shouldRemoveAuth(config.url)) {
      // config.headers.common.Authorization = null;
      config.headers[
        STRING_CONSTANTS.API_HEADERS.AUTHORIZATION
      ] = `${STRING_CONSTANTS.API_HEADERS.TOKEN_PREFIX} ${accessToken}`;
    }
    config.headers[STRING_CONSTANTS.API_HEADERS.CONTENT_TYPE] =
      STRING_CONSTANTS.API_HEADERS.TYPE_RAW_DATA;
    // config.headers['Accept'] = 'application/json';
    return config;
  },
  (error: any) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
instance.interceptors.response.use(
  (response: any) => {
    document.body.classList.remove("loading-indicator");
    return response.data;
  },
  (error: any) => {
    // Do something with request error
    document.body.classList.remove("loading-indicator");
    return Promise.reject(errorMessageHandling(error));
  }
);

// Add a response interceptor
instance_V2.interceptors.response.use(
  (response: any) => {
    document.body.classList.remove("loading-indicator");
    return response.data;
  },
  (error: any) => {
    // Do something with request error
    document.body.classList.remove("loading-indicator");
    return Promise.reject(errorMessageHandling(error));
  }
);

const get = (url, params) => {
  if (url == "profile/")
    return instance_V2({
      url,
      method: STRING_CONSTANTS.API_REQUEST.GET,
      data: params,
    }).then((res) => res.data);
  else {
    return instance({
      url,
      method: STRING_CONSTANTS.API_REQUEST.GET,
      data: params,
    }).then((res) => res.data);
  }
};

const put = (url, params) => {
  return instance({
    url,
    method: STRING_CONSTANTS.API_REQUEST.PUT,
    data: params,
  });
};

const patch = (url, params) => {
  if (url == "profile/")
    return instance_V2({
      url,
      method: STRING_CONSTANTS.API_REQUEST.PATCH,
      data: params,
    });
  else
    return instance({
      url,
      method: STRING_CONSTANTS.API_REQUEST.PATCH,
      data: params,
    });
};

const post = (url, params) => {
  return instance({
    url,
    method: STRING_CONSTANTS.API_REQUEST.POST,
    data: params,
  });
};

const deleteApi = (url, params) => {
  return instance({
    url,
    method: STRING_CONSTANTS.API_REQUEST.DELETE,
    data: params,
  });
};

const upload = (url, params) => {
  return instance({
    url,
    method: STRING_CONSTANTS.API_REQUEST.POST,
    data: params,
  });
};
export const api = { get, put, patch, post, deleteApi, upload };

const getErrors = (arr) => {
  if (arr != undefined || arr != null) {
    //let op = "";
    let op = [];
    Object.keys(arr).forEach((e) => {
      op[0] = e.charAt(0).toUpperCase() + e.slice(1);
      op[1] = arr[e];
    });
    return op;
  }
};
// Do not remove console, it used to format an error.
const ErrorFormatting = (error1) => {
  return JSON.parse(JSON.stringify(error1.response));
};
const errorMessageHandling = (error) => {
  let errorObject = ErrorFormatting(error);
  //let store = "";
  let store = [];
  // if (errorObject && errorObject.data.errors != undefined) {
  //   store = errorObject.data.errors;
  // }
  // else if (errorObject && errorObject.data.result) {
  if (errorObject.status === 500) {
    store[0] = STRING_CONSTANTS.API_ERRORS.INTERNAL_SERVER_ERROR_HEADER;
    store[1] = STRING_CONSTANTS.API_ERRORS.INTERNAL_SERVER_ERROR;
  } else if (errorObject.status === 401) {
    if (window.location.pathname != '/signin') {
      const elements = document.getElementsByClassName('Toastify');
      while (elements.length > 0) {
        elements[0].parentNode.removeChild(elements[0]);
      }
      cookieService.removeAllCookies();
      setTimeout(() => {
        window.location.href = "/signin"
      }, 500);
    }
    // setTimeout(() => {
    ValidationErrorToast(STRING_CONSTANTS.API_401.HEADER, STRING_CONSTANTS.API_401.MESSAGE);
    // }, 1000);
    // setTimeout(() => {
    //   window.location.href = "/signin";
    // }, 2000);
    //toast not getting visible -- whether page is getting refreshed or not in both cases toast will be visible through this implementation

    // cookieService.saveCookie(STRING_CONSTANTS.cookieStrings.apiError, "true");
  } else if (errorObject.status === 403) {
    ValidationErrorToast(STRING_CONSTANTS.API_401.HEADER, errorObject?.data?.message?.[0]);
  } else {
    store = getErrors(errorObject.data.errors);
  }
  return store;
};
