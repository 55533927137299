import { Action } from "../actions/index";
import { ActionType } from "../constants";
import { get } from "lodash";


const initialState = {
    'mobileNumber': '',
};

const reducer = (state=initialState, action: Action) => {
    switch(action.type) {
        case ActionType.SET_MOBILE: {
            state = {...state, 'mobileNumber': get(action, 'item')}
            return state
        }
            
        default: 
            return state;
    }
}

export default reducer;