import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { STRING_CONSTANTS } from "../../utils/constants/stringConstants";
import { UtilityFunctions } from "../../utils/UtilityFunctions";
import { cookieService, userService } from "../../_services";

const AdvancedProfileCompletion = (props) => {

  useEffect(() => {
    userService.userProfile({ isAdvanceProfileCompleted: true })
      .then(res => cookieService.saveCookie(STRING_CONSTANTS.cookieStrings.user, res.data))
  }, [])

  return <>
    {/* <span>
      <i
        className="fa fa-angle-left text-white d-flex justify-content-between"
        aria-hidden="true"
        onClick={() => {
          props?.nav?.prev()
        }}
      ></i>
    </span> */}
    <div className="step-content signup-mar">
      <button type='button' disabled>
        {STRING_CONSTANTS.advanced_profile.step9.title}
      </button>
      <p>
        {STRING_CONSTANTS.advanced_profile.step9.subtitle}
      </p>
      <div className="step-text">
        {STRING_CONSTANTS.advanced_profile.step9.step}
      </div>
    </div>
    <div className="form-wrap">
      <div className="icon-wrap mb-0">
        <img
          width={298}
          alt=""
          src={UtilityFunctions.getImageURL() + "verifiedNew.png"}
        />
      </div>
    </div>
    <div className="next-wrap" style={{ marginTop: "5em" }}>
      <button className="m-0 btn-custom btn-cta"
        onClick={() => props.history.push(STRING_CONSTANTS.navigationString.dashboard.home)
        }>
        {STRING_CONSTANTS.advanced_profile.step9.start_exploring}
      </button>
    </div>
  </>
}

export default withRouter(AdvancedProfileCompletion)