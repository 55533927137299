import React from "react";
import { toast } from 'react-toastify';
import { UtilityFunctions } from "../../src/utils/UtilityFunctions";

const SuccessToast = (successHeader: any, successBody:any) => {

  return (
    toast.success(<div>
      <span className="toast-header">{successHeader}</span>
      <br />
      {successBody}
    </div>, {
      icon: ({ theme, type }) => <img alt="" className="toast-icon" src={UtilityFunctions.getImageURL() + "success-toast-icon.svg"} />
    })

  )
}
export default SuccessToast;