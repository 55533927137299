// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mission-wrapper {
    background-color: #ffff;
}

.mission-content {
    padding: 75px 0px 75px 100px;
}

.mission-content .description {
    color: #000;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 33px;
    padding-bottom: 30px;

}

.mission-content .mission-list {
    padding-left: 20px;
}

.mission-content .mission-list ul li {
    padding-bottom: 10px;
    font-size: 20px;
}

.mission-content .mission-list ul span {
    font-size: 20px;
}

.mission-content .mission-list ul li::marker {
    color: #06EFC5;
}

.mission-img-desktop {
    display: block;
}

.mission-img-mob {
    display: none;
}

@media (max-width: 768px) {
    .mission-content {
        padding: 47px 26px;
    }

    .mission-content .description {
        font-size: 20px;
        line-height: 25px;
    }

    .mission-content .mission-list ul li {
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 19px;
        padding-bottom: 20px;
    }

    .mission-img-desktop {
        display: none;
    }

    .mission-img-mob {
        display: block;
    }

    .mission-img-mob img {
        width: 100%;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Mission/Mission.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;AAC3B;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,WAAW;IACX,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,oBAAoB;;AAExB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,oBAAoB;IACpB,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI;QACI,kBAAkB;IACtB;;IAEA;QACI,eAAe;QACf,iBAAiB;IACrB;;IAEA;QACI,eAAe;QACf,kBAAkB;QAClB,gBAAgB;QAChB,iBAAiB;QACjB,oBAAoB;IACxB;;IAEA;QACI,aAAa;IACjB;;IAEA;QACI,cAAc;IAClB;;IAEA;QACI,WAAW;IACf;AACJ","sourcesContent":[".mission-wrapper {\n    background-color: #ffff;\n}\n\n.mission-content {\n    padding: 75px 0px 75px 100px;\n}\n\n.mission-content .description {\n    color: #000;\n    font-size: 22px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: 33px;\n    padding-bottom: 30px;\n\n}\n\n.mission-content .mission-list {\n    padding-left: 20px;\n}\n\n.mission-content .mission-list ul li {\n    padding-bottom: 10px;\n    font-size: 20px;\n}\n\n.mission-content .mission-list ul span {\n    font-size: 20px;\n}\n\n.mission-content .mission-list ul li::marker {\n    color: #06EFC5;\n}\n\n.mission-img-desktop {\n    display: block;\n}\n\n.mission-img-mob {\n    display: none;\n}\n\n@media (max-width: 768px) {\n    .mission-content {\n        padding: 47px 26px;\n    }\n\n    .mission-content .description {\n        font-size: 20px;\n        line-height: 25px;\n    }\n\n    .mission-content .mission-list ul li {\n        font-size: 15px;\n        font-style: normal;\n        font-weight: 400;\n        line-height: 19px;\n        padding-bottom: 20px;\n    }\n\n    .mission-img-desktop {\n        display: none;\n    }\n\n    .mission-img-mob {\n        display: block;\n    }\n\n    .mission-img-mob img {\n        width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
