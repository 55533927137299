import React, { useEffect, useState } from "react";
import "./Employees.css";
import Header from "../../components/Header/Header";
import JobSearch from "../../components/JobSearch/JobSearch";
import Footer from "../../components/Footer/Footer";
import Section1 from "../../components/LandingPage/Section1/Section1";
import Section3 from "../../components/LandingPage/Section3/Section3";

export default function Employees() {
  const originalAccrodianData = [
    {
      heading: (
        <>
          <div className=" mr-2">AI Assisted Profile Creation</div>
          <img
            src="/assets/images/employeesLandingPage/exploreFirst.svg"
            alt=""
            className="iconImage"
          />
        </>
      ),
      description: (
        <div>
          Introducing Assisted Profile Creation - the ultimate time-saver for
          job seekers on MPLOI. We understand that crafting the perfect profile
          can be a daunting task, which is why we've harnessed the power of AI
          to simplify the process. Simply upload your resume, CV, or provide
          your LinkedIn URL, and our intelligent system will fill in the gaps,
          ensuring your profile stands out from the crowd. With Assisted Profile
          Creation, you can focus on what matters most - finding your dream job
          - while our AI takes care of the rest. Say goodbye to the hassle of
          endless profile tweaking and hello to a seamless, stress-free job
          search experience on MPLOI.
        </div>
      ),
    },
    {
      heading: (
        <>
          <div className=" mr-2">Real AI Job Matching</div>
          <img
            src="/assets/images/employeesLandingPage/exploreSecond.svg"
            alt=""
            className="iconImage"
          />
        </>
      ),

      description: (
        <div>
          Revolutionise your job hunt with MPLOI's cutting-edge AI job matching
          feature. Say goodbye to endless scrolling and hello to a curated list
          of opportunities tailored just for you. Our sophisticated algorithms
          analyse your skills, experience, and preferences to play career cupid,
          connecting you with your dream job faster than you can say 'I quit!'
          With MPLOI, you're not just another resume in the pile; you're a
          sought-after talent with employers lining up to woo you. So sit back,
          relax, and let our AI do the heavy lifting while you prepare to embark
          on your next career adventure.
        </div>
      ),
    },
    {
      heading: (
        <>
          <div className=" mr-2">AI Online Course Suggestions</div>
          <img
            src="/assets/images/employeesLandingPage/exploreThird.svg"
            alt=""
            className="iconImage"
          />
        </>
      ),
      description: (
        <div>
          Our AI Online Course Suggestion feature of "MPLOI Academy" is a
          game-changer for any job seekers using the MPLOI platform. By
          leveraging advanced AI algorithms, this feature tailors course
          recommendations to your unique skills and career aspirations, ensuring
          you invest their time in the most relevant and impactful learning
          opportunities. This personalised approach not only enhances your
          qualifications but also significantly boosts your competitiveness in
          the job market. With MPLOI Academy, you can seamlessly up-skill and
          position yourself for better job roles, making their job search more
          efficient and effective. Embrace the future of job searching with
          MPLOI's innovative AI-driven learning solutions.
        </div>
      ),
    },
    {
      heading: (
        <>
          <div className=" mr-2">AI Interview Simulator</div>
          <img
            src="/assets/images/employeesLandingPage/exploreFourth.svg"
            alt=""
            className="iconImage"
          />
        </>
      ),

      description: (
        <div>
          Imagine being able to practise interviewing in a realistic,
          low-pressure environment from the comfort of your own home. Our AI
          Interview Simulator asks common interview questions, analyses the your
          responses, and provides constructive feedback to help you improve.
          It's an engaging, interactive way to build confidence and refine your
          interviewing skills. Plus, with MPLOI's global reach, you could help
          prepare job seekers around the world to ace their interviews and land
          their dream jobs. The simulator perfectly complements MPLOI's mission
          to connect great talent with great opportunities.
        </div>
      ),
    },
    {
      heading: (
        <>
          <div className=" mr-2">Accessible via Desktop or App</div>
          <img
            src="/assets/images/employeesLandingPage/exploreFifth.svg"
            alt=""
            className="iconImage"
          />
        </>
      ),

      description: (
        <div>
          Having both an app and desktop version of MPLOI would be a
          game-changer for job seekers. You can search for jobs anytime,
          anywhere. Talk about convenience! With the app, you get instant
          notifications about new job postings that match your skills.The
          desktop version would let them fine-tune your profile and application
          on a bigger screen. Because let's face it, typing long cover letters
          on a phone is a special kind of torture. Plus, with seamless syncing
          between the app and desktop, they'd never miss a beat in their job
          hunt. It's like having MPLOI as your trusty sidekick on this adventure
        </div>
      ),
    },
    {
      heading: (
        <>
          <div className=" mr-2">AI Sales Trainer</div>
          <img
            src="/assets/images/employeesLandingPage/exploreSixth.svg"
            alt=""
            className="iconImage"
          />
        </>
      ),
      description: (
        <div>
          Whether you're a seasoned pro or just starting your sales career,
          continuous learning and improvement are essential. But let's face it -
          traditional sales training can be time-consuming, expensive, and often
          generic. That's where the MPLOI AI Sales Trainer comes in! <br /> With
          our AI-powered sales coach, you have access to personalised training
          anytime, anywhere. Need to brush up on your closing techniques? Want
          to learn how to handle objections like a pro? The MPLOI AI Sales
          Trainer has got you covered. <br /> But it gets even better. Our AI
          Sales Trainer adapts to your unique needs and learning style. You can
          input information about your target customers, including their DISC
          personality types, and receive tailored advice on how to approach and
          connect with them effectively. It's like having a sales mentor in your
          pocket, ready to help you succeed. <br /> Investing in your sales
          skills is investing in your future. With the MPLOI AI Sales Trainer,
          you can stand out from the competition, impress your employers, and
          unlock your true sales potential. Don't just settle for average -
          become a sales superstar with the MPLOI AI Sales Trainer.
        </div>
      ),
    },
    {
      heading: (
        <>
          <div className=" mr-2">Profile Boost</div>
          <img
            src="/assets/images/employeesLandingPage/exploreSeventh.svg"
            alt=""
            className="iconImage"
          />
        </>
      ),

      description: (
        <div>
          <div style={{ fontWeight: 700 }}>
            Tired of feeling like just another face in the crowd of job seekers?
          </div>
          Introducing MPLOI's Profile Boost - your secret weapon to catapult
          your profile to the top of employer searches! <br/> For a mere $39, you can
          enjoy 30 days of VIP treatment, ensuring that your skills and
          experience catch the eye of potential employers before anyone else. No
          more waiting around hoping to be noticed - with Profile Boost, you'll
          be the star candidate that businesses can't wait to meet.<br/>Think of it
          as a fast-pass to your dream job. Skip the line, get seen first, and
          land that gig you've been chasing. MPLOI's Profile Boost - because
          sometimes, it pays to be at the front of the pack!
        </div>
      ),
    },
  ];

  const [expanded, setExpanded] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [accrodianData, setAccrodianData] = useState(() => {
    return window.innerWidth < 768
      ? originalAccrodianData.slice(0, 3)
      : originalAccrodianData;
  });

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleResetData = (slice = false) => {
    if (slice) {
      setAccrodianData(originalAccrodianData.slice(0, 3));
      setExpanded(false);
    } else {
      setAccrodianData(originalAccrodianData);
      setExpanded(true);
    }
  };

  return (
    <div className="employeesPageWrap">
      <Header />
      <Section1
        heading={
          <>
            <div className=" fw-bold heading">
              MPLOI Offers You <br /> a{" "}
              <span className=" px-2" style={{ background: "#9256E8" , paddingBottom: '8px' }}>
                {" "}
                Better Way
              </span>
            </div>
          </>
        }
        subHeading="By having access to hundreds of employers, we are looking to enhance your user experience, where you can scroll and swipe to learn more about each business, investigate your options, and connect with them, even if you're not ready to make the move."
        buttons={
          <>
            <a href="/signup" target="_blank" className="textBlack">
              {" "}
              <button className=" border-0 px-4 py-2 primaryButton">
                Find Your Job
                <img
                  src="assets/images/employeesLandingPage/searchIcon.svg"
                  alt=""
                />
              </button>
            </a>

            <a href="#employees-feature">
              <button className=" border-0 px-4 py-2 secondaryButton">
                Explore Features
                <img
                  src="assets/images/employeesLandingPage/exploreIcon.svg"
                  alt=""
                />
              </button>
            </a>
          </>
        }
        image={
          <>
            <img
              src="/assets/images/employeesPageSection1.svg"
              className="img-fluid desktopImage"
              alt=""
            />
          </>
        }
        mobileImage={
          <>
            <img
              src="/assets/images/employeesLandingPage/employeesMobileSection1.png"
              className="img-fluid mobileImage"
              alt=""
            />
          </>
        }
      />
      <div className=" d-flex align-items-center section2">
        <div className="image-wrap">
          <img
            src="/assets/images/employeesPageSection2.png"
            alt=""
            className="section2Image img-fluid"
          />

          <button className=" border-0 px-5 py-2 primaryButton mobile textBlack">
            Test Yourself Here
            <img
              src="assets/images/employeesLandingPage/testYourself.svg"
              alt=""
            />
          </button>
        </div>
        <div className="right-section">
          <div className=" px-3 py-1 tag mb-3">
            Our AI Interview Simulator
            <img
              src="assets/images/employeesLandingPage/simulator.svg"
              alt=""
            />
          </div>
          <div className="text-start fw-bold heading mb-3">
            Hone your Interview Skills <br /> with our AI Interview <br />{" "}
            Simulator
          </div>
          <div className="mb-4 description">
            <div>
              Practise and hone your Interview skills with our “AI Interview
              Simulator” before you interview for that perfect role.{" "}
            </div>
            <div>Try it now!</div>
          </div>
          <button className=" border-0 px-5 py-2 primaryButton desktop textBlack">
            Test Yourself Here
            <img
              src="assets/images/employeesLandingPage/testYourself.svg"
              alt=""
            />
          </button>
        </div>
      </div>
      <div id="employees-feature">
        <Section3
          accrodianData={accrodianData}
          tagName="For Employees"
          description="Explore Our Tools For Employees"
          image={
            <img
              src="/assets/images/employeesSection3.png"
              className="img-fluid"
               alt=""
            />
          }
          mobilebutton={
            isMobile ? (
              expanded ? (
                <button
                  className=" border-0 px-5 py-2 primaryButton textBlack"
                  onClick={() => handleResetData(true)}
                >
                  Expand Less
                  <img
                    src="assets/images/downArrowBlack.svg"
                    alt=""
                    style={{ transform: "rotate(180deg)" }}
                  />
                </button>
              ) : (
                <button
                  className=" border-0 px-5 py-2 primaryButton textBlack"
                  onClick={() => handleResetData()}
                >
                  Expand All
                  <img src="assets/images/downArrowBlack.svg" alt="" />
                </button>
              )
            ) : (
              ""
            )
          }
        />
      </div>

      <JobSearch
        description="Revolutionise Your Job Hunt with the MPLOI App - The AI-Powered Employment Solution!"
        showButtons
        title={
          <div className="title">
            Streamline Your <span>Job Search </span>
            <br /> with the MPLOI App
          </div>
        }
        subDescription={
          <div className="sub-description">
            Join Today and Find Your Dream Job!
          </div>
        }
        image={
          <img
            src="/assets/images/jobSearch.png"
            alt="For Jobs"
            className="img-fluid"
          />
        }
      />
      <Footer />
    </div>
  );
}
