import React, { useState } from "react";
import "./TrainerIntro.css";
import MobileDrawer from "../MobileDrawer/MobileDrawer";

const drawerRadioOptions = [
  "Option one",
  "Option two",
  "Option three",
  "Option four",
  "Option five",
  "Option six",
  "Option seven",
  "Option eight",
];

const TrainerIntro = () => {
  const [isAudioActive, setIsAudioActive] = useState(true);
  const [isCameraActive, setIsCameraActive] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("Select training area");
  const [openDrawer, setOpenDrawer] = useState(false);
  const toggleDrawer = (newOpen: boolean) => () => {
    setOpenDrawer(newOpen);
  };
  const handleAudioToggle = () => {
    setIsAudioActive((prevState) => !prevState);
  };

  const handleCameraToggle = () => {
    setIsCameraActive((prevState) => !prevState);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setIsOpen(false);
  };

  const items = [
    "Pitching for a house sale",
    "Pitching for a house sale 2",
    "Pitching for a house sale 3",
    "Pitching for a house sale 4",
  ];

  return (
    <div className="tranier-simulator container-fluid">
      <div className="container">
        <div className="row justify-content-center m-0">
          <div className="col-12 text-center">
            <img
              src="/assets/images/mploi-logo-white.svg"
              alt="MPLOI Logo"
              className="logo-img"
            />
            <div className="heading">Welcome to the MPLOI Trainer</div>
          </div>
        </div>

        <div className="row profile-sec-wrap">
          <div className="col-12 col-md-5 text-center">
            <div className="profile-section">
              <div className="arrow-img-wrap">
                <img src="/assets/images/arrow-line.png" alt="" />
              </div>
              <img
                src="/assets/images/user-1.png"
                alt="User"
                className="profile-img"
              />
              <div className="user-name">
                You: <br /> <span>Chris Sharkey</span>{" "}
              </div>
              <div className="icons">
                <button onClick={handleAudioToggle} className="icon-button">
                  <img
                    src={
                      isAudioActive
                        ? "/assets/images/audio-off.png"
                        : "/assets/images/audio-on.png"
                    }
                    alt="Audio"
                    className="icon-img"
                  />
                </button>
                <button onClick={handleCameraToggle} className="icon-button">
                  <img
                    src={
                      isCameraActive
                        ? "/assets/images/vdo-off.png"
                        : "/assets/images/vdo-on.png"
                    }
                    alt="Camera"
                    className="icon-img"
                  />
                </button>
              </div>
              <div className="icon-title">Click to allow access</div>
            </div>
          </div>

          <div className="col-12 col-md-5 text-center">
            <div className="profile-section">
              <img
                src="/assets/images/user-2.png"
                alt="Manager"
                className="profile-img"
              />
              <div className="caller-title">You will be training with:</div>
              <div className="caller-name"> Maxine, MPLOI Trainer</div>
            </div>
          </div>
        </div>

        <div className="row m-0 dropdown-wrap">
          <div className="col-12 text-center desktop-trainingSelect-button">
            <button
              onClick={toggleDropdown}
              className={`dropdown-button ${isOpen ? "active" : ""}`}
            >
              <span
                className="dropdown-selected-text"
                style={{
                  color:
                    selectedItem !== "Select training area"
                      ? "#242424"
                      : "#FFFFFF",
                  backgroundColor:
                    selectedItem !== "Select training area" ? "#F495D3" : "",
                }}
              >
                {selectedItem}
              </span>
              <span
                className="dropdown-arrow"
                style={{
                  transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
                }}
              >
               <img src="/assets/images/dropdown-icon.png" alt="" />
              </span>
            </button>

            {isOpen && (
              <ul className="dropdown-list">
                {items.map((item, index) => (
                  <li
                    key={index}
                    className={item === selectedItem ? "selected" : ""}
                    onClick={() => handleItemClick(item)}
                    style={{
                      backgroundColor: item === selectedItem ? "#0F0125" : "",
                      cursor: "pointer",
                    }}
                  >
                    {item}
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div className="mobile-trainingSelect-button">
            <button
              onClick={() => setOpenDrawer(true)}
              className={`dropdown-button ${isOpen ? "active" : ""}`}
            >
              <span
                className="dropdown-selected-text"
                style={{
                  color:
                    selectedItem !== "Select training area"
                      ? "#242424"
                      : "#FFFFFF",
                  backgroundColor:
                    selectedItem !== "Select training area" ? "#F495D3" : "",
                }}
              >
                {selectedItem}
              </span>
              <span
                className="dropdown-arrow"
                style={{
                  transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
                }}
              >
                ▼
              </span>
            </button>
          </div>
        </div>

        <div className="row bottom-content m-0">
          <div className="col-12 text-center">
            <button className="btn prepare-button-mob">Start training</button>
            <div className="description">
              You’ll soon begin your MPLOI training. To begin make sure you{" "}
              <b>allow access to your camera and microphone.</b>
            </div>
            <button className="btn prepare-button">Start training</button>
          </div>
        </div>
      </div>
      <MobileDrawer
        open={openDrawer}
        toggleDrawer={toggleDrawer}
        heading={
          <div className="trainerIntro-drawerDataWrap-heading">
            Select Training Area
          </div>
        }
        drawerData={
          <div className="trainerIntro-drawerDataWrap">
            {drawerRadioOptions?.map((item, index) => {
              return (
                <div key={item + index}>
                  <div
                    className="form-check"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingLeft: "0",
                      alignItems: "center",
                    }}
                    onClick={() => {
                      handleItemClick(item);
                      setOpenDrawer(false);
                    }}
                  >
                    <label
                      className="form-check-label label"
                      htmlFor={item + index}
                    >
                      {item}
                    </label>
                    <input
                      className=""
                      type="radio"
                      name={item + index}
                      id={item + index}
                      value={item}
                      style={{ width: "16px", height: "16px" }}
                      checked={selectedItem === item}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        }
      />
    </div>
  );
};

export default TrainerIntro;
