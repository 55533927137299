import React, { useState } from "react";
import './Pitching.css'; 

function Pitching() {
    const [isAudioActive, setIsAudioActive] = useState(true);
    const [isCameraActive, setIsCameraActive] = useState(true);
  
    const handleAudioToggle = () => {
      setIsAudioActive((prevState) => !prevState);
    };
  
    const handleCameraToggle = () => {
      setIsCameraActive((prevState) => !prevState);
    };
    const [micImage, setMicImage] = useState('/assets/images/mic-call.png');
    const [videoImage, setVideoImage] = useState('/assets/images/vdo-call.png');

    // Toggle the mic image
    const handleMicClick = () => {
        setMicImage((prevImage) =>
            prevImage === '/assets/images/mic-call.png'
                ? '/assets/images/audio-off.png'
                : '/assets/images/mic-call.png'
        );
    };

    // Toggle the video image
    const handleVideoClick = () => {
        setVideoImage((prevImage) =>
            prevImage === '/assets/images/vdo-call.png'
                ? '/assets/images/vdo-off.png'
                : '/assets/images/vdo-call.png'
        );
    };
  return (
   <div className='pitching-mode-wrapper'>
        <div className="pitching-mode-header">
            <div className='pitching-header-wrap'>
                <img src="/assets/images/mploi-logo-white.svg" alt="MPLOI Logo" className="logo" />
                <div className="title">
                Pitch in process <img src="/assets/images/waveform.png" alt="waveform Icon" />
                </div>
            </div>
        
            <div className="pitching-details">
                <div className="caller-details">
                    <div><span>Pitching for: </span> Pitching for a house sale </div>
                    <div className='dot'> • </div> 
                    <div>
                    <span>Pitching with: </span> Amanda Jones, Jackson Brown, Helen Anne, Jacky Armstrong
                    </div>
                    
                </div>
            </div>
            <div className='pitching-mode-wrap'>
                <div className="pitching-mode-top-content">
                    <div className="status"><img src="/assets/images/person-voice.png" alt="" /> Try saying, “good morning”</div>
                        <button className="start-pitching-button">
                        End Pitch <img src="/assets/images/call-end-icon.png" alt="Icon" />
                        </button>
      
                </div>
                <div className='pitching-mode-bottom-content'>
                    <div className="left-img-wrap">
                        <div className='top-title-mob'>
                            <img src="/assets/images/person-voice.png" alt="" />  Try saying, “good morning”
                            <span> <img src='/assets/images/wave-color.png' alt=''/></span>
                        </div>
                         <img className="caller-img" src="/assets/images/caller-1.png" alt="Candidate" />
                         <div className='top-img-mic-icon'><img src='/assets/images/audio-rang.png' alt=''/> </div>
                            <div className="bottom-icon-wrap">
                                <div className="bottom-title">Candidate: <span>John Jones (you)</span></div>
                                <div className="feature-icon-wrap">
                                <button onClick={handleAudioToggle} className="icon-button">
              <img
                src={
                  isAudioActive
                    ? "/assets/images/audio-call-icon.png"
                    : "/assets/images/audio-call-off-icon.png"
                }
                alt="Audio"
                className="icon-img"
              />
            </button>
            <button onClick={handleCameraToggle} className="icon-button">
              <img
                src={
                  isCameraActive
                    ? "/assets/images/video-call-icon.png"
                    : "/assets/images/vdo-call-off-icon.png"
                }
                alt="Camera"
                className="icon-img"
              />
            </button>
                                </div>
                            </div>
                    </div>
                    <div className='right-img-wrap'>
                        <div className='pitch-right-box'>
                            <div className='row'>
                                <div className='col-6 col-md-6'>
                                    <div className='pitch-user-card'>
                                        <img className="caller-img" src="/assets/images/pitch-1.png" alt="Candidate" />
                                        <div className="bottom-title">Amanda Jones</div>
                                    </div>
                                </div>
                                <div className='col-6 col-md-6'>
                                    <div className='pitch-user-card'>
                                        <img className="caller-img" src="/assets/images/pitch-2.png" alt="Candidate" />
                                        <div className="bottom-title">Jackson Brown</div>
                                    </div>
                                </div>
                                <div className='col-6 col-md-6'>
                                    <div className='pitch-user-card'>
                                        <img className="caller-img" src="/assets/images/pitch-3.png" alt="Candidate" />
                                        <div className="bottom-title">Helen Anne</div>
                                    </div>
                                </div>
                                <div className='col-6 col-md-6'>
                                    <div className='pitch-user-card'>
                                        <img className="caller-img" src="/assets/images/pitch-4.png" alt="Candidate" />
                                        <div className="bottom-title">Jacky Armstrong</div>
                                    </div>
                                </div>
                            
                            </div>
                           
                        </div>
                    
                    </div>
                </div>
                <div className='pitching-mode-bottom-wrap'>
            <img
                src={micImage}
                alt='Mic Call'
                onClick={handleMicClick}
            />
            <img
                src='/assets/images/call-cut.png'
                className='mid-icn'
                alt='Call Cut'
            />
            <img
                src={videoImage}
                alt='Video Call'
                onClick={handleVideoClick}
            />
        </div>

            </div>
        </div>
   </div>
  )
}

export default Pitching