import React from "react";
import { UtilityFunctions } from "./../../utils/UtilityFunctions";

export default function EmployeeSignup() {
  return <>
    <div className="after-emploer-design min-vh-100 d-flex align-items-center justify-content-center">
      <div className="after-emploer-inner-des mx-auto">
        <h3><span>Welcome!</span> It seems you are after an Employee profile</h3>
        <p>Please download the app from your respective app stores to create your account.</p>
        <ul className="list-unstyled ps-0 ms-0 mb-0 d-flex align-items-center justify-content-center">
          <li>
            <a href={process.env.REACT_APP_APPLE_LINK} >
              <img src={UtilityFunctions.getImageURL() + "mobile-app-store-badges.png"} alt="" />
            </a>
          </li>
          <li>
            <a href={process.env.REACT_APP_ANDROID_LINK}>
              <img src={UtilityFunctions.getImageURL() + "mobile-google-play-badges.png"} alt="" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </>
}