import { Action } from "../actions/index";
import { ActionType } from "../constants";
import { get } from "lodash";

const initialState: string = "";

const reducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.SET_REFERRAL_CODE: {
      state = get(action, "item");
      return state;
    }
    default:
      return state;
  }
};

export default reducer;
