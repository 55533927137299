export enum ActionType {
  SELECTED_PLAN = "SELECTED_PLAN",
  CLIENT_SECRET = "CLIENT_SECRET",
  SET_MOBILE = "SET_MOBILE",
  RESET = "RESET",
  SET_SHOW_SIDEBAR = "SET_SHOW_SIDEBAR",
  SET_SIDEBAR = "SET_SIDEBAR",
  SET_JOB_CATEGORY = "SET_JOB_CATEGORY",
  SET_JOB_SUBCATEGORY = "SET_JOB_SUBCATEGORY",
  SET_JOB_TITLE = "SET_JOB_TITLE",
  SET_JOB_COVER = "SET_JOB_COVER",
  SET_JOB_DATE = "SET_JOB_DATE",
  SET_JOB_DESC = "SET_JOB_DESC",
  SET_JOB_LOCATION = "SET_JOB_LOCATION",
  SET_JOB_MINIMUM = "SET_JOB_MINIMUM",
  SET_JOB_MAXIMUM = "SET_JOB_MAXIMUM",
  SET_JOB_EXPERIENCE = "SET_JOB_EXPERIENCE",
  SET_JOB_TYPE = "SET_JOB_TYPE",
  SET_RATE_TYPE = "SET_RATE_TYPE",
  SET_RESET_JOB = "SET_RESET_JOB",
  SET_EDIT_JOB = "SET_EDIT_JOB",
  SET_UNREAD_COUNT = "SET_UNREAD_COUNT",
  SET_EMPLOYEES_LIST = "SET_EMPLOYEES_LIST",
  SET_REMOVE_EMPLOYEE = "SET_REMOVE_EMPLOYEE",
  SET_JOBS_LIST = "SET_JOBS_LIST",
  UPDATE_JOBS_LIST = "UPDATE_JOBS_LIST",
  SET_REMOVE_JOB = "SET_REMOVE_JOB",
  SET_JOBS_SEARCH = "SET_JOBS_SEARCH",
  SET_JOBS_FETCH_STATES = "SET_JOBS_FETCH_STATES",
  SET_JOBS_DATE_RANGE = "SET_JOBS_DATE_RANGE",
  SET_JOBS_SORT = "SET_JOBS_SORT",
  SET_REFERRAL_CODE = "SET_REFERRAL_CODE",
  ADD_ONLINE_USER = "SET_ONLINE_USER",
  REMOVE_ONLINE_USER = "REMOVE_ONLINE_USER",
  UPDATE_ALL_ONLINE_USER = "UPDATE_ALL_ONLINE_USER",
  CURRENT_USER_LAST_SEEN = "CURRENT_USER_LAST_SEEN",
  CLOSE_ONLINE_OFFLINE_SOCKET = "CLOSE_ONLINE_OFFLINE_SOCKET",
  CURRENT_USER_LATEST_MESSAGE = "CURRENT_USER_LATEST_MESSAGE",
  GET_ONLINE_USER_LIST = "GET_ONLINE_USER_LIST",
  TRIGGER_CHAT_TAB = "TRIGGER_CHAT_TAB",
  RECONNECT_INSIDE_SOCKET = "RECONNECT_INSIDE_SOCKET"
}
