import React from "react";
import { UtilityFunctions } from "../../src/utils/UtilityFunctions";
import { Button, Modal } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { cookieService, userService } from "../_services";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../store";
import { STRING_CONSTANTS } from "../utils/constants/stringConstants";
import { checkData } from "../utils/checkData";
import { api } from "../middleware/api";
import ValidationErrorToast from "./validationErrorToast";
import SuccessToast from "./successToast";

const ShareApp = (props) => {
  const userData = cookieService.getCookie(STRING_CONSTANTS.cookieStrings.user)

  const handleClick = (e) => {
    e.preventDefault();
  };

  return (
    <Modal
      className="bg-transparent logout-popup height-auto"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={props.show}
      onHide={props.handleClose}
    >
      <Modal.Body>
        <img
          alt="logout"
          src={UtilityFunctions.getImageURL() + "arrow_up.svg"}
        />
        <h3 className="py-2">Add additonal information to your profile by sharing the app</h3>
        <img src={UtilityFunctions.getImageURL() + "add-additonal.png"} alt="" />
        <div className="v-form-width min-height-auto">
          <div className="form-floating mb-3 label-dark overflow-hidden v-additnal-link">
            <input type='text' className="form-control pt-2 profile-copy-link" placeholder="Search Industry" value={userData?.user?.referralLink} disabled />
            {/* <label htmlFor="floatingInput">{userData?.user?.referralLink}</label> */}
            <span className="input-group-text">
              {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.5">
                  <path d="M9.25 7C9.66421 7 10 7.33579 10 7.75C10 8.12656 9.72249 8.4383 9.36083 8.49187L9.25 8.5H7C5.067 8.5 3.5 10.067 3.5 12C3.5 13.864 4.95707 15.3876 6.79435 15.4941L7 15.5H9.25C9.66421 15.5 10 15.8358 10 16.25C10 16.6266 9.72249 16.9383 9.36083 16.9919L9.25 17H7C4.23858 17 2 14.7614 2 12C2 9.32226 4.10496 7.13615 6.75045 7.00612L7 7H9.25ZM17 7C19.7614 7 22 9.23858 22 12C22 14.6777 19.895 16.8638 17.2495 16.9939L17 17H14.75C14.3358 17 14 16.6642 14 16.25C14 15.8734 14.2775 15.5617 14.6392 15.5081L14.75 15.5H17C18.933 15.5 20.5 13.933 20.5 12C20.5 10.136 19.0429 8.6124 17.2057 8.50594L17 8.5H14.75C14.3358 8.5 14 8.16421 14 7.75C14 7.37344 14.2775 7.0617 14.6392 7.00813L14.75 7H17ZM7 11.25H17C17.4142 11.25 17.75 11.5858 17.75 12C17.75 12.3797 17.4678 12.6935 17.1018 12.7432L17 12.75H7C6.58579 12.75 6.25 12.4142 6.25 12C6.25 11.6203 6.53215 11.3065 6.89823 11.2568L7 11.25H17H7Z" fill="white" />
                </g>
              </svg> */}
              <img src={UtilityFunctions.getImageURL() + "link-icon-gray.svg"} alt="" />
              {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="1">
                  <path d="M9.25 7C9.66421 7 10 7.33579 10 7.75C10 8.12656 9.72249 8.4383 9.36083 8.49187L9.25 8.5H7C5.067 8.5 3.5 10.067 3.5 12C3.5 13.864 4.95707 15.3876 6.79435 15.4941L7 15.5H9.25C9.66421 15.5 10 15.8358 10 16.25C10 16.6266 9.72249 16.9383 9.36083 16.9919L9.25 17H7C4.23858 17 2 14.7614 2 12C2 9.32226 4.10496 7.13615 6.75045 7.00612L7 7H9.25ZM17 7C19.7614 7 22 9.23858 22 12C22 14.6777 19.895 16.8638 17.2495 16.9939L17 17H14.75C14.3358 17 14 16.6642 14 16.25C14 15.8734 14.2775 15.5617 14.6392 15.5081L14.75 15.5H17C18.933 15.5 20.5 13.933 20.5 12C20.5 10.136 19.0429 8.6124 17.2057 8.50594L17 8.5H14.75C14.3358 8.5 14 8.16421 14 7.75C14 7.37344 14.2775 7.0617 14.6392 7.00813L14.75 7H17ZM7 11.25H17C17.4142 11.25 17.75 11.5858 17.75 12C17.75 12.3797 17.4678 12.6935 17.1018 12.7432L17 12.75H7C6.58579 12.75 6.25 12.4142 6.25 12C6.25 11.6203 6.53215 11.3065 6.89823 11.2568L7 11.25H17H7Z" fill="white" />
                </g>
              </svg> */}
            </span>
            <div className="right-copy" style={{ cursor: "pointer", background: "rgba(255, 255, 255, 0.1)" }} onClick={() => {
              navigator.clipboard.writeText(userData?.user?.referralLink)
              SuccessToast("Link Copied", "")
            }}>
              <img src={UtilityFunctions.getImageURL() + "copy-icon-light.svg"} alt="" />
              {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.24">
                  <path d="M5.5028 4.62704L5.5 6.75V17.2542C5.5 19.0491 6.95507 20.5042 8.75 20.5042L17.3663 20.5045C17.0573 21.3782 16.224 22.0042 15.2444 22.0042H8.75C6.12665 22.0042 4 19.8776 4 17.2542V6.75C4 5.76929 4.62745 4.93512 5.5028 4.62704ZM17.75 2C18.9926 2 20 3.00736 20 4.25V17.25C20 18.4926 18.9926 19.5 17.75 19.5H8.75C7.50736 19.5 6.5 18.4926 6.5 17.25V4.25C6.5 3.00736 7.50736 2 8.75 2H17.75Z" fill="white" />
                </g>
              </svg> */}
            </div>
          </div>
        </div>
        <div className="mt-3">

          <Button
            className="ms-0 border-0 w-100 mt-4"
            variant="primary"
            onClick={() => props.handleClose()}
          >
            Next
          </Button>
        </div>
      </Modal.Body>
    </Modal>

  )
}
export default withRouter(ShareApp);