import React, { useEffect, useRef, useState } from 'react';
import { api } from "../../../middleware/api";
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators, State } from "../../../store";
import { UtilityFunctions, modifiedSalaray, timeConvrter } from "../../../utils/UtilityFunctions";

export default function EmployeeJobListing(props: any) {
    const dispatch = useDispatch();
    const [jobList, setJobList] = useState([]);
    // const [noJobs, setNoJobs] = useState(false);
    const { setSidebar } = bindActionCreators(actionCreators, dispatch);
    const scrollRef = useRef<any>()
    useEffect(() => {
        getJobList();
    }, [])

    useEffect(() => {
        getJobList();
    }, [props.selectedEmployee])

    const getJobList = () => {
        api.post(`jobs/?limit=1000&offset=0&employerId=${props?.employerId}`, null)
            .then((res: any) => {
                const response = res?.data?.results;
                const data = response?.filter((a) => a.title != props.employerTitle);
                if (!data.length) {
                    // console.log(1)
                    // setNoJobs(true);
                    setJobList(data);
                } else {
                    // console.log(2)
                    // setNoJobs(false);
                    setJobList(data);
                }

            })
            .catch(e => {

            })
    }
    const renderJobs = jobList?.map(job => {
        return <>
            <div className="job-list-wrap"
                // data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
                key={job.id} onClick={() => {
                    // for employee login
                    setSidebar({ sidebar_name: 'employee-details', show_sidebar: true });
                    // props?.setSelectedEmployee(job);
                    props.getUpdatedId(job);
                }}>
                <div className="jb-profl-lft">
                    <div className="job-pic"><img alt="" src={job?.cover ? job?.cover : UtilityFunctions.getImageURL() + "bildeing-squre-img.png"} />
                    </div>
                    <div className="job-name">
                        <h3 className="mt-0">{job.title}</h3>
                        <p>
                            <span>
                                {job?.location?.suburb}
                            </span>
                            &nbsp;

                            <img src={UtilityFunctions.getImageURL() + "svg-2px-dot.svg"} alt="" />
                            <span>
                                {modifiedSalaray(job.minSalary, job.maxSalary, null, job?.salaryCheckoutType)}
                            </span>
                            &nbsp;
                            <img src={UtilityFunctions.getImageURL() + "svg-2px-dot.svg"} alt="" />
                            &nbsp;
                            {UtilityFunctions.convertMonthsToYearsAndMonths(job?.experience)}
                        </p>
                    </div>
                </div>
                <div className="jb-allocat-rgt text-end">
                    <p className="trading-name">
                        <div className='trading-name-he'>{job?.employer?.tradingName}</div>
                        <img alt="" src={job?.employer?.logo ? job?.employer?.logo : UtilityFunctions.getImageURL() + "bildeing-squre-img.png"} />
                    </p>
                    <span style={{ opacity: 1 }}>{job?.type === 'fullTime' ? 'Full Time' : job?.type === 'partTime' ? 'Part Time' : job?.type === 'casual' ? 'Casual' : 'None'}</span>
                </div>
            </div>
        </>
    })

    return (<>
        {!isEmpty(jobList) &&
            <div className="employe-wrap mt-4">
                <div className="job-list-wrap-scroll">
                    <div id="scrollableDiv2" className="jobs-scrollable" ref={scrollRef} style={{ overflow: 'inherit' }}>
                        {jobList.length && <h6>Current Job Listings</h6>}
                        {renderJobs}
                    </div>
                </div>
            </div>
        }
        {/* {noJobs &&
            <div className="no-table-data no-table-one" style={{ justifyContent: 'center' }}>
                <div>
                    <div>
                        <img src={UtilityFunctions.getImageURL() + 'no-data.svg'} alt="" />
                        <p>It looks like you haven’t posted any..</p>
                    </div>
                </div>
            </div>
        } */}
    </>)
}
