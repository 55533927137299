import React, { Fragment, useState, useEffect } from "react";
import ValidationErrorToast from "../../components/validationErrorToast";
import { STRING_CONSTANTS } from "../../utils/constants/stringConstants";
import { UtilityFunctions } from "../../utils/UtilityFunctions";

export default function Signup(props) {
  let store = props?.props?.history?.location?.state?.userType;
  const [userType, setUserType] = useState(store ? store : "")
  //A FUNCTION THAT DIFFERENTIATES BETWEEN MPLOIER AND MPLOIEE SIGNUP AND SENDS THAT DATA THROUGH PROPS
  const signupNavigator = () => {
    if (userType === STRING_CONSTANTS.userType.employer) {
      props.props.history.push(STRING_CONSTANTS.navigationString.signup, { step: 1, userType: STRING_CONSTANTS.userType.employer, isGuest: props?.props?.location?.state?.isGuest, account_exists: props?.props?.location?.state?.account_exists })
    }
    else if (userType === STRING_CONSTANTS.userType.employee) {
      props.props.history.push(STRING_CONSTANTS.navigationString.signup, { step: 1, userType: STRING_CONSTANTS.userType.employee, isGuest: props?.props?.location?.state?.isGuest, account_exists: props?.props?.location?.state?.account_exists })
    }
    else ValidationErrorToast("Please select your role", "")
  }

  const onBoarding = {
    pathname: "/",
    state: { activeIndex: 2 }
  }
  return <>
    <div className="row">
      <div className="col-md-3 col-lg-4 col-xl-4 col-12">
        <div className="left-wrap">
          <div className="logo-wrap">
            <img
              alt=""
              src={UtilityFunctions.getImageURL() + "logo_White.svg"}
              onClick={() => props.props.history.push(onBoarding)}
            />
          </div>
        </div>
      </div>
      <div className="col-md-6 col-lg-4 col-xl-4 col-12">
        <div className="middle-wrap">
          <div className='top-divde-spa'>
            <span>
              <i
                className="fa fa-angle-left text-white d-flex justify-content-between"
                aria-hidden="true"
                onClick={() => {
                  if (props?.props?.location?.state?.isGuest) {
                    props?.props?.history?.push(STRING_CONSTANTS.navigationString.dashboard.home, { isGuest: true })
                  }
                  else if (props?.props?.location?.state?.account_exists) {
                    props?.props?.history?.push(STRING_CONSTANTS.navigationString.index)
                  }
                  else props?.props?.history?.goBack()
                }
                }
              ></i>
            </span>
            <div className="step-content signup-mar">
              <button type='button' disabled>{STRING_CONSTANTS.signupPages.signup.sign_up}</button>
              <p>Are you looking to hire or<br /> looking to work?</p>
              <div className="step-text">
                Step 1/6
              </div>
            </div>
          </div>
          <div className="form-wrap">
            <div className='custom-grid-setup d-flex justify-content-between mx-auto'>
              <div className='radio-button-card-set w-100'>
                <input
                  type="radio" name="select_job" id="business_owner" />
                <label className={userType === STRING_CONSTANTS.userType.employer ? "active" : ""} htmlFor="business_owner" onClick={() => setUserType(STRING_CONSTANTS.userType.employer)}>
                  <img src={UtilityFunctions.getImageURL() + "person-icon-dark-trans.svg"} className="unchecked-img" alt="" />
                  <img src={UtilityFunctions.getImageURL() + "person-icon-dark-trans-checked.svg"} className="checked-img" alt="" />
                  <p className='mb-0'>
                    I’m a <b>Business Owner</b>
                  </p>
                </label>
              </div>
              <div className='radio-button-card-set w-100'>
                <input
                  type="radio" name="select_job" id="employee" />
                <label className={userType === STRING_CONSTANTS.userType.employee ? "active" : ""} htmlFor="employee" onClick={() => setUserType(STRING_CONSTANTS.userType.employee)}>
                  <img src={UtilityFunctions.getImageURL() + "briefcase-icon-dark.svg"} className="unchecked-img" alt="" />
                  <img src={UtilityFunctions.getImageURL() + "briefcase-icon-dark-checked.svg"} className="checked-img" alt="" />
                  <p className='mb-0'>
                    I’m an <b>Employee</b>
                  </p>
                </label>
              </div>
            </div>
          </div>
          {/* <div className="btn-maccount mobile-btn d-block d-md-none">
              <button type='button' onClick={() => props.history.push(STRING_CONSTANTS.navigationString.login)}>
                {STRING_CONSTANTS.signupPages.signup.account_exists}
              </button>
            </div> */}
          <div className="next-wrap">
            <button
              className={`${userType ? "cricle-next" : "cricle-next-inactive"}`}
              onClick={() => signupNavigator()}
            >
              <img alt="" src={UtilityFunctions.getImageURL() + "arrow-bottom.svg"} />
            </button>
          </div>
        </div>
      </div>
      <div className="col-md-3 col-lg-4 col-xl-4 col-12 d-none d-md-block">
        <div className="right-wrap">
          <div className="btn-maccount">
            <button type="button"
              onClick={() => props.props.history.push(STRING_CONSTANTS.navigationString.login)}>
              {STRING_CONSTANTS.signupPages.signup.account_exists}
            </button>
          </div>
        </div>
      </div>
    </div>
  </>
}