import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import Logout from "../../components/logout";
import { STRING_CONSTANTS } from "../../utils/constants/stringConstants";
import { UtilityFunctions } from "../../utils/UtilityFunctions";
import ImageCrop from "../../common/ui/ImageCrop";
import { DatePicker } from "@blueprintjs/datetime";
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import { api } from "../../middleware/api";
import ValidationErrorToast from "../../components/validationErrorToast";
import { cookieService, userService } from "../../_services";
import SuccessToast from "../../components/successToast";
const debounce = require("debounce-promise");

const HideProfile = (props) => {
  const [searchTerm, setSearchTerm] = useState<string>("")
  const [searchemployerList, setSearchEmployerList] = useState([]);
  const [selectedEmployersList, setSelectedEmployersList] = useState<any>([]);
  const [storedEmployersList, setStoredEmployersList] = useState([]);
  const [persistingEmployers, setPersistingEmployers] = useState([]);
  const [deletedEmployers, setDeletedEmployers] = useState([]);
  const searchRef = useRef<HTMLInputElement>();
  const [errorState, setErrorState] = useState(false);
  const isProfileBeingUpdated = props?.location?.state?.updateProfile || false;

  useEffect(() => {
    if (searchTerm) {
      api.get(`employer/all/?search=${searchTerm}`, null)
        .then(res => setSearchEmployerList(res))
        .catch(e => { })
    }
  }, [searchTerm])

  const debounceClick = debounce((value: string) => {
    setSearchTerm(value)
  }, 600);

  useEffect(() => {
    let deleted = []
    let persists = []
    for (let storedEmployer of storedEmployersList) {
      if (selectedEmployersList?.findIndex(item => item?.employer?.id === storedEmployer?.employer?.id) !== -1) {
        persists?.push(storedEmployer?.employer?.id)
      }
      else deleted?.push(storedEmployer?.id)
    }
    setPersistingEmployers(persists)
    setDeletedEmployers(deleted)
  }, [selectedEmployersList, props])

  useEffect(() => {
    if (props?.userData?.experienceHistory?.length) {
      let list = []
      let IDs = []
      props?.userData?.experienceHistory?.map((item, index) => {
        if (item?.employerType !== 'C') {
          list.push(item)
          IDs.push(item?.id)
        }
      })
      setStoredEmployersList(prev => [...list])
      setSelectedEmployersList(prev => [...list])
    }
  }, [props?.userData?.experienceHistory])

  // CHECKS FIRST IF AN EMPLOYER ALEADY EXISTS, OTHERWISE REMOVES 1 EMPLOYER IF 2 EMPLOYER ALREADY EXISTS
  const addEmployerHandler = (employer) => {
    if (selectedEmployersList.findIndex(item => item?.employer?.id === employer?.id) !== -1) {
      ValidationErrorToast("Employer Exists", "Selected employer already exists")
    }
    else {
      let tempList = selectedEmployersList
      if (selectedEmployersList.length >= 2) {
        tempList.pop()
        tempList.push({ employer: employer })
        setSelectedEmployersList([...tempList])
      }
      else {
        tempList.push({ employer: employer })
        setSelectedEmployersList([...tempList])
      }
    }
  }

  // REMOVES EMPLOYER AT INDEX 1 IN AN ARRAY OF 2 EMPLOYERS 
  const removeEmployerHandler = (employer) => {
    let tempList = selectedEmployersList
    tempList.splice(tempList.findIndex(item => item?.employer?.id === employer?.employer?.id), 1)
    setSelectedEmployersList([...tempList])
  }

  const submitHandler = (e) => {
    const dataToPatch: any = {
      experienceHistory: [],
      delete_experience_history: []
    }

    // Only patching when there is a new employer (by finding through employer id)
    selectedEmployersList.map(item => {
      if (!persistingEmployers.find(id => id === item?.employer?.id)) {
        dataToPatch?.experienceHistory?.push({
          employer: `${item?.employer?.id}`,
          isProfileHidden: true,
          startDate: null,
          endDate: null,
          unregisteredEmployer: null,
          employerType: null
        })
      }
    })
    if (!dataToPatch?.experienceHistory?.length) {
      delete dataToPatch?.experienceHistory
    }
    // If any existing employer is removed
    if (deletedEmployers?.length) {
      dataToPatch.delete_experience_history = deletedEmployers
    }
    else delete dataToPatch.delete_experience_history
    if (Object.keys(dataToPatch).length) {
      document.body.classList.add("loading-indicator");
      userService.userProfile(dataToPatch)
        .then(res => {
          document.body.classList.remove("loading-indicator");
          cookieService.saveCookie(STRING_CONSTANTS.cookieStrings.user, res.data)
        }
        )
        .then(() => {
          if (isProfileBeingUpdated) {
            document.body.classList.remove("loading-indicator");
            props.history.push(STRING_CONSTANTS.navigationString.dashboard.settings, { tab: "updateProfile" })
            SuccessToast("Success!", "Your profile details have been updated successfully")
          }
          else props.nav.next()
        })
        .catch((error) => {
          document.body.classList.remove("loading-indicator");
        })
    }
    else {
      if (isProfileBeingUpdated) {
        props.history.push(STRING_CONSTANTS.navigationString.dashboard.settings, { tab: "updateProfile" })
        // SuccessToast("Success!", "Your profile details have been updated successfully")
      }
      else props.nav.next()
    }
  }

  return (
    <>
      <div className='top-divde-spa'>
        <span>
          <i
            className="fa fa-angle-left text-white d-flex justify-content-between"
            aria-hidden="true"
            onClick={() => {
              props?.nav?.back()
            }}
          ></i>
        </span>
        {!isProfileBeingUpdated && <span className="top-skip">
          <p className="mb-0" onClick={() => props.nav.next()}>Skip</p>
        </span>}
        <div className="step-content signup-mar">
          <button type='button' disabled>
            {STRING_CONSTANTS.employeeProfileCompletionPages.step10.title}
          </button>
          <p className="mw-100">
            {STRING_CONSTANTS.employeeProfileCompletionPages.step10.subtitle}
          </p>
          <div className="step-text">
            {!isProfileBeingUpdated ? STRING_CONSTANTS.employeeProfileCompletionPages.step10.step : ""}
          </div>
        </div>
      </div>
      <form
      // onSubmit={abnSubmitHandler}
      >
        <div className="form-wrap business-detailss">
          <div className="v-form-width min-height-auto">
            <div className="form-floating mb-3 label-dark">
              <>
                <input type='text'
                  className={`form-control
                     ${errorState ? "error" : ""}`
                  }
                  placeholder="Search employer..."
                  ref={searchRef}
                  onChange={(e) => {
                    // setErrorState(prev => { return { ...prev, registered: false } })
                    debounceClick(e.target.value)
                  }}
                />
                <label htmlFor="floatingInput">
                  {STRING_CONSTANTS.employeeProfileCompletionPages.step10.search_employer_ph}
                </label>
                <span className="input-group-text">
                  <img src={UtilityFunctions.getImageURL() + "search-with-shadow.svg"} alt="" />
                  <img src={UtilityFunctions.getImageURL() + "search-with-shadow-fill.svg"} alt="" />
                  {/* <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_f_4_8)">
                      <circle cx="12" cy="12" r="7" stroke="#D0D0D0" strokeWidth="2" />
                      <path d="M21 21L18 18" stroke="#D0D0D0" strokeWidth="2" strokeLinecap="round" />
                    </g>
                    <circle cx="12" cy="12" r="7" stroke="#D0D0D0" strokeWidth="2" />
                    <path d="M21 21L18 18" stroke="#D0D0D0" strokeWidth="2" strokeLinecap="round" />
                    <defs>
                      <filter id="filter0_f_4_8" x="-3" y="-3" width="32" height="32" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                        <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_4_8" />
                      </filter>
                    </defs>
                  </svg> */}
                  {/* <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_f_4_8)">
                      <circle cx="12" cy="12" r="7" stroke="#D0D0D0" strokeWidth="2" />
                      <path d="M21 21L18 18" stroke="#D0D0D0" strokeWidth="2" strokeLinecap="round" />
                    </g>
                    <circle cx="12" cy="12" r="7" stroke="#D0D0D0" strokeWidth="2" />
                    <path d="M21 21L18 18" stroke="#D0D0D0" strokeWidth="2" strokeLinecap="round" />
                    <defs>
                      <filter id="filter0_f_4_8" x="-3" y="-3" width="32" height="32" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                        <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_4_8" />
                      </filter>
                    </defs>
                  </svg> */}
                </span>
              </>
              {(searchTerm && !searchemployerList?.length) ?
                <div className="filter-dropdonw-set-blank">
                  <div className="text-center">
                    <img src={UtilityFunctions.getImageURL() + "three-dots-blue.svg"} alt="" />
                    {/* <svg width="40" height="8" viewBox="0 0 40 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="4" cy="4" r="3.5" fill="white" fillOpacity="0.2" stroke="#38BBF4" />
                      <circle cx="20" cy="4" r="3.5" fill="white" fillOpacity="0.2" stroke="#38BBF4" />
                      <circle cx="36" cy="4" r="3.5" fill="white" fillOpacity="0.2" stroke="#38BBF4" />
                    </svg> */}
                    <p>
                      {STRING_CONSTANTS.employeeProfileCompletionPages.step10.no_results_found} "{searchTerm}"
                    </p>
                    <button type="button"
                      onClick={() => { }}>
                      {STRING_CONSTANTS.employeeProfileCompletionPages.step10.search_again}
                    </button>
                  </div>
                </div>
                :
                // SEARCH TERM AND SEARCH RESULTS BOTH EXIST
                (searchemployerList?.length && searchTerm) ?
                  <ul className="list-unstyled ms-0 filter-dropdonw-set">
                    {searchemployerList.map(employer =>
                      <li key={employer?.id}
                        onClick={() => {
                          setSearchTerm("");
                          searchRef.current.value = "";
                          addEmployerHandler(employer)
                        }}>
                        <img src={employer?.logo || UtilityFunctions.getImageURL() + "bildeing-squre-img.png"} alt="" />
                        <span>
                          {employer?.businessName} ({employer?.tradingName})
                        </span>
                      </li>)}
                  </ul> :
                  <></>
              }
            </div>
            <ul className="current-employ-list list-unstyled ps-0 ms-0 text-white mb-3 d-grid gap-3">
              {selectedEmployersList?.map(employer => {
                return <li key={employer?.employer?.id} className="d-flex align-items-center justify-content-between gap-3">
                  <span className="d-flex align-items-center gap-3">
                    <img
                      src={employer?.employer?.logo || UtilityFunctions.getImageURL() + "bildeing-squre-img.png"}
                      className="bilding-ig" alt=""
                    />
                    {employer?.employer?.tradingName}
                  </span>
                  <img
                    onClick={() => removeEmployerHandler(employer)}
                    src={UtilityFunctions.getImageURL() + "Dismiss-cross-green.svg"} className="cross-icon" alt=""
                  />
                </li>
              })}
            </ul>
            <div className="employers-bottom-design-lin">
              {
                selectedEmployersList?.length ? <><hr />
                  <p>
                    {STRING_CONSTANTS.employeeProfileCompletionPages.step10.max_employers}
                  </p>
                </> : <div></div>
              }
            </div>
          </div>
        </div>
      </form>
      <div className="next-wrap">
        <button className={
          // selectedEmployersList?.length ?
          "cricle-next"
          // : "cricle-next-inactive"
        }
          onClick={(e) => submitHandler(e)}
        >
          <img
            alt=""
            src={UtilityFunctions.getImageURL() + "arrow-bottom.svg"}
          />
        </button>
      </div>
    </>
  )
}

export default withRouter(HideProfile)