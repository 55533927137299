import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";

const CONTACT_PAGE = "/contact";
const LOGIN_PAGE = "/signin";
const SIGNUP_PAGE = "/signup";
const ABOUT_PAGE = "/about";
const EMPLOYERS_PAGE = "/employers";
const EMPLOYEES_PAGE = "/employees";
const PRICING_PAGE = "/pricing";
const BLOG_PAGE = "/blogs";
const PRIVACY_POLICY_PAGE = "/privacy";
const TERMS_CONDITIONS_PAGE = "/terms";

function Footer() {
  return (
    <footer className="footer-section">
      <div className="container-fluid footer-content-wrap">
        <div className="row footer-top">
          <div className="col-lg-6 col-md-12 mb-4 mb-lg-0 footer-left">
            <img src="/assets/images/mploi-logo-white.svg" alt="footer logo" />
            <h2>Get the app</h2>
            <div className="app-buttons">
              <a href="https://apps.apple.com/au/app/mploi/id1626118075">
                <img src="/assets/images/app-store-light.svg" alt="App Store" />
              </a>
              <a href="https://play.google.com/store/apps/details?id=au.com.mploi.mobile.apps&hl=en">
                <img
                  src="/assets/images/play-store-light.svg"
                  alt="Google Play"
                />
              </a>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 footer-right">
            <div className="footer-links">
             <Link to={CONTACT_PAGE}>Contact</Link>
              <Link to={LOGIN_PAGE}>Login</Link>
              <Link to={SIGNUP_PAGE}>Signup</Link>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 footer-right">
            <div className="footer-links">
               <Link to={EMPLOYERS_PAGE}>For Employers</Link>
              <Link to={EMPLOYEES_PAGE}>For Employees</Link>
              <Link to={PRICING_PAGE}>Pricing</Link>
              <Link to={BLOG_PAGE}>Blog</Link>
            </div>
          </div>
          <div className="footer-terms-mob">
            <Link to={PRIVACY_POLICY_PAGE}>Privacy Policy</Link>
            <Link to={TERMS_CONDITIONS_PAGE}>Terms & Conditions</Link>
          </div>
          <div className="social-icons">
            <a
              href="https://www.tiktok.com/@mploi_app?_t=8pR2sdtbxY1&_r=1"
              target="_blank"
              rel="noreferrer"
            >
              <img src="/assets/images/music.svg" alt="Tik Tok" />
            </a>
            <a
              href="https://www.facebook.com/mploi.jobs"
              target="_blank"
              rel="noreferrer"
            >
              <img src="/assets/images/facebook.svg" alt="Facebook" />
            </a>
            <a
              href="https://www.instagram.com/mploi_app/?utm_medium=copy_link"
              target="_blank"
              rel="noreferrer"
            >
              <img src="/assets/images/instagram.svg" alt="Instagram" />
            </a>
            <a
              href="https://www.linkedin.com/company/mploiapp/"
              rel="noreferrer"
              target="_blank"
            >
              <img src="/assets/images/linkdin.svg" alt="LinkedIn" />
            </a>
          </div>
        </div>
        <div className="border-footer"></div>
        <div className="footer-bottom">
          <p>© MPLOI 2024</p>
          <div className="footer-terms">
            <Link to={PRIVACY_POLICY_PAGE}>Privacy Policy</Link>
            <Link to={TERMS_CONDITIONS_PAGE}>Terms & Conditions</Link>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
