import { Action } from "../actions/index";
import { ActionType } from "../constants";
import { get } from "lodash";

const initialState = {
  filter: {
    created_at__date__gte: "",
    created_at__date__lte: "",
  },
  sort: true,
  search: "",
  offset:0,
};

const reducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.SET_JOBS_DATE_RANGE: {
      state = { ...state, filter: get(action, "item") };
      return state;
    }
    case ActionType.SET_JOBS_SORT: {
      state = { ...state, sort: get(action, "item") };
      return state;
    }
    case ActionType.SET_JOBS_SEARCH: {
      state = { ...state, search: get(action, "item") };
      return state;
    }
    case ActionType.SET_JOBS_FETCH_STATES: {
      state = { ...state, offset: get(action, "item") };
      return state;
    }
    default:
      return state;
  }
};

export default reducer;
