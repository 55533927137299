import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import SuccessToast from "../../../components/successToast";
import ValidationErrorToast from "../../../components/validationErrorToast";
import { STRING_CONSTANTS } from "../../../utils/constants/stringConstants";
import { UtilityFunctions } from '../../../utils/UtilityFunctions';
import { cookieService, userService } from "../../../_services";
import DeleteQualification from "../../../components/deleteQualification"
import DeleteExperience from "../../../components/deleteExperience"
import moment from "moment";


const UpdateMyExperience = (props) => {
  const [userData, setUserData] = useState<any>()
  const [showDeleteExperienceModal, setShowDeleteExperienceModal] = useState(false);
  const [showDeleteQualificationModal, setShowDeleteQualificationModal] = useState(false);
  const [experienceToDelete, setExperienceToDelete] = useState<any>()
  const [qualificationToDelete, setQualificationToDelete] = useState<any>()
  // const [experienceArray, setExperienceArray] = useState<any>([])
  // const [qualificationArray, setQualificationArray] = useState<any>([])
  // const experienceArray = props?.location?.state?.employerArray
  // const qualificationArray = props?.location?.state?.qualificationArray
  // const deletedExperienceArray = props?.location?.state?.deletedExperiecneArray
  // const deletedQualificationArray = props?.location?.state?.deletedQualificationArray

  useEffect(() => {
    document.body.classList.add("loading-indicator");
    userService.getUserData()
      .then((res) => {
        // if (experienceArray) {
        //   setFinalExperienceArray(experienceArray)
        //   setFinalDeletedEmployerArr(deletedExperienceArray)
        // }
        // else setFinalExperienceArray(res?.experienceHistory)
        // if (qualificationArray) {
        //   setFinalQualificationArray(qualificationArray)
        //   setFinalDeletedQualificationArray(deletedQualificationArray)
        // }
        // else setFinalQualificationArray(res?.educations)
        setUserData(res)
        document.body.classList.remove("loading-indicator");
      })
  }, [])

  const deleteExperienceHandler = (experienceObj) => {
    // console.log(experienceObj)
    userService.userProfile({ delete_experience_history: [experienceObj?.id] })
      .then(res => {
        setUserData(res.data);
        setShowDeleteExperienceModal(false)
        SuccessToast("Experience Deleted", "Experience Deleted Successfully")
        setExperienceToDelete(null)
      })
      .catch((e) => ValidationErrorToast(e[0], e[1]))

    // let filteredData = finalExperienceArray?.filter((item, index) => {
    //   if (index == deleteIndex && item?.id) {
    //     setFinalDeletedEmployerArr(prev => [...prev, item?.id]);
    //   }
    //   return index !== deleteIndex;
    // });
    // setFinalExperienceArray(filteredData);
  };

  const deleteEducationHandler = (education) => {
    userService.userProfile({ delete_education: [education?.id] })
      .then(res => {
        setUserData(res.data);
        setShowDeleteQualificationModal(false)
        SuccessToast("Qualification Deleted", "Qualification Deleted Successfully")
        setQualificationToDelete(null)
      })
      .catch((e) => ValidationErrorToast(e[0], e[1]))

    // let filteredData = finalQualificationArray?.filter((item, index) => {
    //   if (index == deleteIndex && item?.id) {
    //     setFinalDeletedQualificationArray(prev => [...prev, item?.id]);
    //   }
    //   return index !== deleteIndex;
    // });
    // setFinalQualificationArray(filteredData);
  }

  const updateHandler = () => {
    // let finalPatchObject: object = {}
    // if (finalQualificationArray) {
    //   finalQualificationArray?.forEach(qualification => {
    //     delete qualification["indexToEdit"]
    //     delete qualification["action"]
    //     if (qualification?.course) {
    //       qualification["course"] = qualification?.course?.id
    //     }
    //     if (qualification?.schoolType) {
    //       qualification["schoolType"] = qualification?.schoolType?.id
    //     }
    //   })
    //   finalPatchObject["educations"] = finalQualificationArray
    // }
    // if (finalExperienceArray) {
    //   finalExperienceArray?.forEach(employer => {
    //     delete employer["indexToEdit"]
    //     delete employer["action"]
    //     if (employer?.employer) {
    //       employer["employer"] = employer?.employer?.id
    //     }
    //     if (employer?.category?.[0]) {
    //       employer["category"] = [employer?.category?.[0]?.id]
    //     }
    //     else employer["category"] = null
    //     if (employer?.subCategory?.[0]) {
    //       employer["subCategory"] = [employer?.subCategory?.[0]?.id]
    //     }
    //     else employer["subCategory"] = null
    //   })
    //   finalPatchObject["experienceHistory"] = finalExperienceArray
    // }
    // if (finalDeletedEmpArray?.length) {
    //   finalPatchObject["delete_experience_history"] = finalDeletedEmpArray
    // }
    // if (finalDeletedQualificationArray?.length) {
    //   finalPatchObject["delete_education"] = finalDeletedQualificationArray
    // }
    // userService.userProfile(finalPatchObject)
    //   .then((res) => {
    //     cookieService.saveCookie(STRING_CONSTANTS.cookieStrings.user, res.data)
    //     SuccessToast("Successfully Updated", "Your details have been successfully updated")
    //     props.history.push(STRING_CONSTANTS.navigationString.dashboard.settings, { tab: "updateMyExperience" })
    //   })
    //   .catch(e => ValidationErrorToast(e[0], e[1]))
  }
  return <div className="tab-pane fade show active " id="panel8" role="tabpanel" aria-labelledby="panel8-tab">
    <div className="container-fluid position-relative">
      <div className='title-row'>
        <h1 className="mb-4 pb-3">Update My Experience</h1>
      </div>
      <div className="row">
        <div className="col-md-12 col-lg-12 col-xl-12 col-xxl-6">
          <div className="update_my_experience_des">
            <h5>Experience</h5>
            <div className="update_my_experience_des_main">
              {/* {newEmployerObj ? */}
              <ul className="current-employ-list list-unstyled ps-0 ms-0 text-white mb-3 d-grid gap-3">
                {userData?.experienceHistory?.map((employer, index) => {
                  return <li key={employer?.employer?.id}>
                    <div className="update-my-ex mb-3">
                      <div className="d-flex align-items-center justify-content-between gap-3 mb-3">
                        <span className="d-flex align-items-start gap-3">
                          <img
                            src={employer?.employer?.logo || UtilityFunctions.getImageURL() + "bildeing-squre-img-pink.svg"}
                            className="bilding-ig" alt=""
                          />
                          <div className="v-text text-start">
                            <div className="upper-conts">{employer?.unregisteredEmployer || employer?.employer?.tradingName || 'N/A'}</div>
                            {employer?.subCategory?.[0]?.name ?
                              <p className="mb-0">{employer?.subCategory?.[0]?.name}</p>
                              :
                              <p className="mb-0 text-custom-danger">Not Selected</p>
                            }
                          </div>
                        </span>
                        <div className="d-flex gap-3 aligh-item-center">
                          <button type="button" className="bg-transparent shadow-none"
                            onClick={() => {
                              props.history.push(STRING_CONSTANTS.navigationString.advanced_profile, {
                                isProfileBeingUpdated: true,
                                employer: { ...employer, indexToEdit: index },
                                isEmployerBeingEdited: true,
                                // finalExperienceArray,
                                // finalDeletedEmpArray,
                                // finalDeletedQualificationArray
                              })
                            }}
                          >
                            <img src={UtilityFunctions.getImageURL() + "edit-green.svg"} alt="" />
                          </button>
                          <img
                            onClick={() => {
                              setShowDeleteExperienceModal(true)
                              setExperienceToDelete(employer)
                            }
                            }
                            src={UtilityFunctions.getImageURL() + "cross-pink.svg"} className="cross-icon cross-icon-pink" alt=""
                          />
                        </div>
                      </div>
                      <div className="date-varigble-css d-flex align-items-center justify-content-between pe-3 gap-2">
                        {employer?.startDate ?
                          <p className="mb-0 d-flex align-items-center gap-2">
                            <img src={UtilityFunctions.getImageURL() + "calendar-fill-white.svg"} alt="" />
                            {moment(employer?.startDate).format("DD/MM/YY")} - {employer?.endDate ? moment(employer?.endDate).format("DD/MM/YY") : "Present"}
                          </p> :
                          <p className="mb-0 d-flex align-items-center gap-2 text-custom-danger">
                            <img src={UtilityFunctions.getImageURL() + "calendar-fill-red.png"} alt="" />
                            Unspecified
                          </p>
                        }
                        {employer?.isProfileHidden ?
                          <p className="mb-0 d-flex align-items-center gap-2"><img src={UtilityFunctions.getImageURL() + "EyeOff-gray.svg"} alt="" /> Hidden Employer</p> :
                          <p className="mb-0 d-flex align-items-center gap-2"><img src={UtilityFunctions.getImageURL() + "eyeOn.png"} alt="" /> Visible Employer</p>
                        }
                      </div>
                    </div>
                    <hr />
                  </li>
                })}
              </ul>
              {/* : <span></span> */}
              {/* } */}
              <button className="add-another-button bg-transparent border-0 text-white mx-auto d-flex align-items-center gap-2"
                onClick={() => {
                  props.history.push(STRING_CONSTANTS.navigationString.advanced_profile, {
                    isProfileBeingUpdated: true,
                    employer: {},
                    isEmployerBeingAdded: true,
                    // finalExperienceArray,
                    // finalDeletedEmpArray,
                    // finalDeletedQualificationArray
                  })
                }}>
                <img src={UtilityFunctions.getImageURL() + "add-plus-icon-white.svg"} alt="" />
                Add Another Employer
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-12 col-lg-12 col-xl-12 col-xxl-6">
          <div className="update_my_experience_des">
            <h5>Education / Qualifications</h5>
            <div className="update_my_experience_des_main">
              <ul className="current-employ-list current-employ-list_right-side list-unstyled ps-0 ms-0 text-white mb-3 d-grid gap-3">
                {userData?.educations?.map((education, index) => {
                  return <li key={education?.id}>
                    <div className="update-my-ex">
                      <div className="d-flex align-items-center justify-content-between gap-3 mb-3">
                        <span className="d-flex align-items-start gap-3">
                          <img
                            src={education?.logo || UtilityFunctions.getImageURL() + "hat-graduation-bg-fill.svg"}
                            className="bilding-ig" alt=""
                          />
                          <div className="v-text">
                            <div className="upper-conts">{education?.institutionName}</div>
                            <p className="mb-0">{education?.course?.name}</p>
                          </div>
                        </span>
                        <div className="d-flex gap-3 aligh-item-center">
                          <button type="button" className="bg-transparent shadow-none"
                            onClick={() => {
                              props.history.push(STRING_CONSTANTS.navigationString.advanced_profile, {
                                isProfileBeingUpdated: true,
                                qualification: { ...education, indexToEdit: index },
                                isQualificationBeingEdited: true,
                                // finalQualificationArray,
                                // finalDeletedQualificationArray,
                                // finalDeletedEmpArray
                              })
                            }}
                          >
                            <img src={UtilityFunctions.getImageURL() + "edit-green.svg"} alt="" />
                          </button>
                          <img
                            onClick={() => {
                              setShowDeleteQualificationModal(true)
                              setQualificationToDelete(education)
                            }}
                            src={UtilityFunctions.getImageURL() + "cross-pink.svg"} className="cross-icon cross-icon-pink" alt=""
                          />
                        </div>
                      </div>
                      <div className="date-varigble-css d-flex align-items-center justify-content-between pe-3 gap-2">
                        {/* add class if go red color (text-custom-danger) */}
                        {/* and chagne image url (calendar-fill-red.png) */}
                        {education?.startDate ?
                          <p className="mb-0 d-flex align-items-center gap-2">
                            <img src={UtilityFunctions.getImageURL() + "calendar-fill-white.svg"} alt="" />
                            {moment(education?.startDate).format("DD/MM/YY")} - {education?.endDate ? moment(education?.endDate).format("DD/MM/YY") : "Present"}
                          </p> :
                          <p className="mb-0 d-flex align-items-center gap-2 text-custom-danger">
                            <img src={UtilityFunctions.getImageURL() + "calendar-fill-red.png"} alt="" />
                            Unspecified
                          </p>
                        }
                      </div>
                    </div>
                    <hr />
                  </li>
                })}
              </ul>
              <button className="add-another-button bg-transparent border-0 text-white mx-auto d-flex align-items-center gap-2"
                onClick={() => {
                  props.history.push(STRING_CONSTANTS.navigationString.advanced_profile, {
                    isProfileBeingUpdated: true,
                    qualification: {},
                    isQualificationBeingAdded: true,
                    // finalQualificationArray,
                    // finalDeletedQualificationArray,
                    // finalDeletedEmpArray
                  })
                }}
              >
                <img src={UtilityFunctions.getImageURL() + "add-plus-icon-white.svg"} alt=""
                />
                Add Another Institution
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DeleteExperience
      showPopup={showDeleteExperienceModal}
      handleClosePopup={() => setShowDeleteExperienceModal(false)}
      deleteExperienceHandler={deleteExperienceHandler}
      experienceToDelete={experienceToDelete}
    />
    <DeleteQualification
      showPopup={showDeleteQualificationModal}
      handleClosePopup={() => setShowDeleteQualificationModal(false)}
      deleteEducationHandler={deleteEducationHandler}
      qualificationToDelete={qualificationToDelete}
    />
    {/* <div className="fixed-dasbttn fixed-dasbttn-updatemyex">
      <div className="btnfgo-wrap mt-0">
        <button onClick={() => { updateHandler() }}>Update</button>
      </div>
    </div> */}

  </div >
}

export default withRouter(UpdateMyExperience);
