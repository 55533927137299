import React from "react";
import { UtilityFunctions } from "../utils/UtilityFunctions";
import { Button, Modal } from "react-bootstrap";

const NoVerifyModal = (props) => {

  return (
    <Modal
      className="bg-transparent logout-popup noverify-popup"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={props.show}
      onHide={props.handleClose}
    >
      <Modal.Body>
        <img alt="" src={UtilityFunctions.getImageURL() + "question.svg"} />

        <h3 className="py-2">Uh-oh, looks like you can’t access this feature until your account is verified.</h3>
        {/* <svg width="89" height="104" viewBox="0 0 89 104" fill="none" xmlns="http://www.w3.org/2000/svg">
          <ellipse cx="43.5" cy="101" rx="40.5" ry="3" fill="white" fill-opacity="0.1" />
          <path d="M88.197 43.8852C88.2024 50.9593 86.4872 57.9297 83.198 64.2008C79.9087 70.4719 75.1431 75.8575 69.3083 79.8975C69.2019 79.9732 69.0917 80.0488 68.9814 80.1207C68.3428 80.5596 67.6914 80.9795 67.0275 81.3805C66.0924 81.948 65.1357 82.4814 64.1574 82.9808C63.5646 83.2835 62.9676 83.571 62.3596 83.8434C62.2074 83.9115 62.0517 83.9834 61.8957 84.0477C60.8352 84.5168 59.7517 84.9443 58.6494 85.3226C55.7701 86.3275 52.7924 87.0275 49.7657 87.411C47.8874 87.6514 45.9955 87.7714 44.1018 87.7704C43.1477 87.7704 42.1974 87.7401 41.2584 87.6796C40.4373 87.6266 39.6239 87.5547 38.818 87.4564C37.5217 87.3013 36.2406 87.0932 34.9786 86.8246C34.9369 86.817 34.8912 86.8057 34.8494 86.7981C32.2276 86.2463 29.6632 85.4521 27.1896 84.426C26.517 84.1461 25.8518 83.8547 25.1979 83.5407C24.373 83.1548 23.5646 82.7425 22.7727 82.3036C15.9664 78.5611 10.2755 73.094 6.27696 66.4566C2.27842 59.8192 0.114437 52.2476 0.00441537 44.5094C-0.105606 36.7712 1.84225 29.1417 5.65049 22.3944C9.45873 15.647 14.9919 10.0219 21.689 6.08917C28.3862 2.15648 36.0091 0.0561479 43.7849 0.0011102C51.5607 -0.0539275 59.2128 1.93829 65.9655 5.77578C72.7181 9.61326 78.3312 15.1595 82.2355 21.8523C86.1397 28.545 88.1964 36.1462 88.197 43.8852Z" fill="#F1F1F1" />
          <path d="M42.8895 54.7028C48.3276 54.7028 52.736 50.3153 52.736 44.9031C52.736 39.491 48.3276 35.1035 42.8895 35.1035C37.4514 35.1035 33.043 39.491 33.043 44.9031C33.043 50.3153 37.4514 54.7028 42.8895 54.7028Z" fill="#FFB8B8" />
          <path d="M38.8159 87.4592C37.5196 87.304 36.2385 87.096 34.9766 86.8273L36.1739 60.012L36.3451 59.8342L38.7968 57.2578H46.5934L49.4709 60.0309L49.7143 60.2693L55.8686 66.137L58.6473 85.3254C55.7681 86.3303 52.7904 87.0303 49.7636 87.4138C47.8853 87.6542 45.9935 87.7742 44.0997 87.7731C43.1457 87.7731 42.1953 87.7429 41.2563 87.6824C40.4352 87.6294 39.6218 87.5575 38.8159 87.4592Z" fill="#06EFC5" />
          <path d="M25.196 83.5431C24.3711 83.1572 23.5627 82.7448 22.7708 82.3059C22.691 75.0422 22.6377 70.2186 22.6377 70.2186L23.5652 69.6549C23.5763 69.6492 23.5877 69.6441 23.5993 69.6398L34.1252 63.2764L36.3337 59.7051L36.3453 59.8337L36.9953 67.3623C38.9527 74.0128 40.3776 80.8073 41.2565 87.6819C40.4354 87.6289 39.622 87.557 38.8161 87.4587C37.5198 87.3036 36.2387 87.0955 34.9768 86.8269C34.935 86.8193 34.8893 86.808 34.8476 86.8004C32.2257 86.2486 29.6614 85.4544 27.1878 84.4283C26.5151 84.1484 25.8499 83.8571 25.196 83.5431Z" fill="#242424" />
          <path d="M58.6486 85.325C55.7694 86.3298 52.7917 87.0298 49.7649 87.4133C49.3455 84.9714 49.1912 82.4918 49.305 80.0171C50.1261 66.9537 48.2331 59.7051 49.0542 59.7051L49.4722 60.0304L54.6383 64.0936L61.4387 66.5754H61.4426L66.1221 68.2816L66.9469 68.5843V68.974C66.9506 70.3208 66.9582 75.0346 67.0267 81.3829C66.0916 81.9503 65.1349 82.4838 64.1566 82.9832C63.5638 83.2858 62.9669 83.5733 62.3588 83.8457C62.2066 83.9138 62.0509 83.9857 61.895 84.05C60.8344 84.5191 59.751 84.9466 58.6486 85.325Z" fill="#242424" />
          <path d="M33.5741 47.9703C33.5741 47.9703 34.4108 46.5081 34.2163 41.6234C34.0218 36.7387 41.4956 39.5014 47.7407 39.0355C53.9859 38.5695 52.6639 47.1906 52.6639 47.1906C52.6639 47.1906 53.1145 46.6781 54.0791 42.5173C55.0437 38.3565 52.8535 34.2101 52.8535 34.2101C52.2085 29.8379 48.7321 31.2392 48.7321 31.2392C50.4063 31.8878 50.0847 33.2748 50.0847 33.2748C48.6676 30.802 45.1912 32.2033 45.1912 32.2033C40.5547 29.3076 35.8557 33.1209 35.8557 33.1209C30.2546 34.386 33.5395 38.8191 33.5395 38.8191C27.9066 41.6521 33.5741 47.9703 33.5741 47.9703Z" fill="#242424" />
          <path d="M17.9932 79.2556C18.1378 75.9302 18.2023 73.7851 18.1262 73.4862C17.7348 71.9275 23.0795 69.8392 23.5659 69.6538C23.577 69.6481 23.5884 69.643 23.6001 69.6387L24.6874 70.2175L25.1967 83.542C24.3719 83.1561 23.5635 82.7437 22.7715 82.3049C21.1141 81.3924 19.518 80.3738 17.9932 79.2556Z" fill="#242424" />
          <path d="M67.0269 81.3817C66.0918 81.9492 65.1351 82.4826 64.1568 82.982C63.564 83.2846 62.9671 83.5722 62.359 83.8446C62.2068 83.9127 62.0511 83.9845 61.8952 84.0488L60.1846 67.2401L61.1843 66.5137C61.1843 66.5137 61.2756 66.5364 61.4389 66.5742H61.4428C62.2449 66.7709 64.7081 67.4217 66.1223 68.2804C66.4379 68.4585 66.7176 68.6932 66.9471 68.9728C67.0869 69.1396 67.1563 69.3538 67.1409 69.5705C67.0954 70.0094 67.8557 74.1709 68.9809 80.1219C68.3422 80.5607 67.6909 80.9807 67.0269 81.3817Z" fill="#242424" />
          <path d="M44.1 97.7665C49.6501 97.7665 54.1493 93.2887 54.1493 87.7651C54.1493 82.2415 49.6501 77.7637 44.1 77.7637C38.55 77.7637 34.0508 82.2415 34.0508 87.7651C34.0508 93.2887 38.55 97.7665 44.1 97.7665Z" fill="#9256E8" />
          <circle cx="38" cy="88" r="2" fill="white" />
          <circle cx="44" cy="87" r="2" fill="white" />
          <circle cx="50" cy="88" r="2" fill="white" />
        </svg> */}
        <img src={UtilityFunctions.getImageURL() + "account-verify.png"} alt="" />

        <div>
          <Button className='mt-4 border-0 w-100 btn btn-primary cancel-button' variant="primary" onClick={props.handleClose}>
            Ok, thanks
          </Button>

        </div>
      </Modal.Body>
    </Modal>

  )
}
export default NoVerifyModal;