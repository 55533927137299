
import React, { useEffect, useState } from 'react';

const Timer = props => {
  const { initialMinute = "00", initialSeconds = 30 } = props;
  const [minutes, setMinutes] = useState(initialMinute);
  const [seconds, setSeconds] = useState(initialSeconds);

  useEffect(() => {
    if (seconds === 0) {
      props.setStartTimer(false);
    }
  }, [seconds]);
  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);

    return () => {
      clearInterval(myInterval);
    };
  });
  return (
    <>
      {minutes === 0 && seconds === 0 ? null :
        <p style={{ color: 'white' }}>
          {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
        </p>
      }
    </>
  );
};

export default Timer;

