import React, { useState, useEffect } from 'react';
import { STRING_CONSTANTS } from '../utils/constants/stringConstants';
import { UtilityFunctions } from '../utils/UtilityFunctions';



const SubscriptionWeb = (props) => {
    const BASE_URL = UtilityFunctions.API_URL();
    useEffect(() => {
        const urlString = window?.location.href;
        let token = urlString?.split('token=')?.[1];


        if (token) {
            fetch(`${BASE_URL}/user/detail/`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            }).then(response => Promise.all([response, response.json()]))
                .then(([response, json]) => {
                    console.log('response', response);
                    if (!response.ok) {
                        props.history.push(STRING_CONSTANTS.navigationString.login);
                        return;
                    }
                    const realToken = json.data.user.token.access;
                    token = realToken;
                    localStorage.setItem('token', JSON.stringify(token));
                    localStorage.setItem('user', JSON.stringify(json.data));
                    props.history.push(STRING_CONSTANTS.navigationString.dashboard.home + "?subscription=true");
                })

        } else {
            props.history.push(STRING_CONSTANTS.navigationString.login);
        }

    }, [])
    return (
        <>
            Loading...
        </>
    );

}

export default SubscriptionWeb;