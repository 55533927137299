import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import ProfileWrapper from "../../common/Profile-Wrapper";
import PhotoID from "./PhotoID"
import PreviousEmployers from "./PreviousEmployers"
import Qualification from "./Qualification"
import QualificationDuration from "./QualificationDuration"
import PastJobCategory from "./PastJobCategory"
import PastJobSubcategory from "./PastJobSubcategory"
import PastJobDuration from "./PastJobDuration"
import AdvancedProfileCompletion from "./Completion"
import QualificationPreview from "./QualificationPreview"
import ExperiencePreview from "./ExperiencePreview"
import { cookieService, userService } from "../../_services";
import { STRING_CONSTANTS } from "../../utils/constants/stringConstants";

const EmployeeAdvancedProfile = (props) => {
  const [step, setStep] = useState(1);
  const [userData, setUserData] = useState<any>()
  // Employment Flow
  // const [storedEmployersList, setStoredEmployersList] = useState([]);
  const [selectedEmployerObj, setSelectedEmployerObj] = useState({})
  const [employerArray, setEmployerArray] = useState([])
  const [deletedEmployerArr, setDeletedEmployerArr] = useState<any>([])
  const state = props?.location?.state
  // Education Flow
  const [selectedQualificationObj, setSelectedQualificationObj] = useState({})
  const [qualificationArray, setQualificationArray] = useState([])
  const [deletedQualificationArr, setDeletedQualificationArr] = useState<any>([])
  const [previousScreenSkip,setPreviousScreenSkip] = useState(false)
  var url_string = window.location.href;
  var url = new URL(url_string);
  var getParamsValue = url.searchParams.get("source");

  useEffect(() => {
    if (cookieService.getCookie(STRING_CONSTANTS.cookieStrings.user)?.isAdvanceProfileCompleted && props?.location?.state?.source === "notification" || getParamsValue === "notification") {
      setStep(9)
    }
  }, [])

  useEffect(() => {
    if (userData?.isAdvanceProfileCompleted && !props?.location?.state?.isProfileBeingUpdated && props?.location?.state?.source !== "notification" && getParamsValue != "notification") {
      props.history.push(STRING_CONSTANTS.navigationString.dashboard.settings, { tab: "updateMyExperience" })
    }
  }, [userData])

  useEffect(() => {
    userService.getUserData()
      .then(res => {
        setUserData(res)
        // setStoredEmployersList(res?.experienceHistory)
        if (!state?.isProfileBeingUpdated) {
          setEmployerArray(res?.experienceHistory)
          setQualificationArray(res?.educations)
        }
      })

  }, [props])

  useEffect(() => {
    if (state?.isProfileBeingUpdated && state?.isEmployerBeingAdded) {
      setStep(1)
      // setEmployerArray(state?.finalExperienceArray)
      // setDeletedEmployerArr(state?.finalDeletedEmpArray)
      // setDeletedQualificationArr(state?.finalDeletedQualificationArray)
    }
    else if (state?.isProfileBeingUpdated && state?.isEmployerBeingEdited) {
      setStep(2)
      setSelectedEmployerObj({ ...state?.employer, action: "edit" })
      // setEmployerArray(state?.finalExperienceArray)
      // setDeletedQualificationArr(state?.finalDeletedQualificationArray)
      // setDeletedEmployerArr(state?.finalDeletedEmpArray)
    }
    else if (state?.isProfileBeingUpdated && state?.isQualificationBeingAdded) {
      setStep(6)
      // setQualificationArray(state?.finalQualificationArray)
      // setDeletedQualificationArr(state?.finalDeletedQualificationArray)
      // setDeletedEmployerArr(state?.finalDeletedEmpArray)
    }
    else if (state?.isProfileBeingUpdated && state?.isQualificationBeingEdited) {
      setStep(6)
      setSelectedQualificationObj({ ...state?.qualification, action: "edit" })
      // setQualificationArray(state?.finalQualificationArray)
      // setDeletedQualificationArr(state?.finalDeletedQualificationArray)
      // setDeletedEmployerArr(state?.finalDeletedEmpArray)
    }
  }, [props])

  const nav = {
    next(obj) {
      if(obj?.previousScreenSkip==true){
        setPreviousScreenSkip(true)
      }else{
        setPreviousScreenSkip(false)
      }
      step <= 9 && setStep((prev) => prev + 1);
    },
    prev() {  
      if(step === 1){
        props.history.push({
          pathname: "/employee/profilecompletion",
          state: {step: 9}
        });
      }else{
        step >= 2 && setStep((prev) => prev - 1);
      }
    },
  };

  return (
    <>
      <div style={{ position: "absolute" }}></div>
      <ProfileWrapper step={step}>
        {/* {step === 1 && (
          <PhotoID
            userData={userData}
            setUserData={setUserData}
            props={props}
            nav={nav}
            step={step}
            setStep={setStep} />
        )} */}
        {step === 1 && (
          <PreviousEmployers
            props={props}
            nav={nav}
            setStep={setStep}
            setSelectedEmployerObj={setSelectedEmployerObj}
            setEmployerArray={setEmployerArray}
            employerArray={employerArray}
            setDeletedEmployerArr={setDeletedEmployerArr}
            deletedEmployerArr={deletedEmployerArr}
            selectedEmployerObj={selectedEmployerObj}
          />
        )}
        {step === 2 && (
          <PastJobCategory
            props={props}
            userData={userData}
            nav={nav}
            setStep={setStep}
            selectedEmployerObj={selectedEmployerObj}
            setSelectedEmployerObj={setSelectedEmployerObj}
          />
        )}
        {step === 3 && (
          <PastJobSubcategory
            props={props}
            nav={nav}
            setStep={setStep}
            setSelectedEmployerObj={setSelectedEmployerObj}
            selectedEmployerObj={selectedEmployerObj}
          />
        )}
        {step === 4 && (
          <PastJobDuration
            props={props}
            nav={nav}
            selectedEmployerObj={selectedEmployerObj}
            setSelectedEmployerObj={setSelectedEmployerObj}
            setEmployerArray={setEmployerArray}
            employerArray={employerArray}
            deletedEmployerArr={deletedEmployerArr}
            deletedQualificationArr={deletedQualificationArr}
          />
        )}
        {step === 5 &&
          <ExperiencePreview
            props={props}
            nav={nav}
            step={step}
            setStep={setStep}
            setSelectedEmployerObj={setSelectedEmployerObj}
            employerArray={employerArray}
            setDeletedEmployerArr={setDeletedEmployerArr}
            setEmployerArray={setEmployerArray}
            deletedEmployerArr={deletedEmployerArr}
            selectedEmployerObj={selectedEmployerObj}
            setSelectedQualificationObj={setSelectedQualificationObj}
          />
        }
        {step === 6 && (
          <Qualification
            props={props}
            userData={userData}
            setUserData={setUserData}
            nav={nav}
            step={step}
            setStep={setStep}
            setEmployerArray={setEmployerArray}
            selectedQualificationObj={selectedQualificationObj}
            setSelectedQualificationObj={setSelectedQualificationObj}
            setQualificationArray={setQualificationArray}
            qualificationArray={qualificationArray}
            setDeletedQualificationArr={setDeletedQualificationArr}
            deletedQualificationArr={deletedQualificationArr}
          />
        )}
        {step === 7 && (
          <QualificationDuration
            props={props}
            userData={userData}
            setUserData={setUserData}
            nav={nav}
            step={step}
            setStep={setStep}
            deletedEmployerArr={deletedEmployerArr}
            selectedQualificationObj={selectedQualificationObj}
            setSelectedQualificationObj={setSelectedQualificationObj}
            setQualificationArray={setQualificationArray}
            qualificationArray={qualificationArray}
            setDeletedQualificationArr={setDeletedQualificationArr}
            deletedQualificationArr={deletedQualificationArr}
            previousScreenSkip={previousScreenSkip}
          />
        )}
        {step === 8 &&
          <QualificationPreview
            props={props}
            userData={userData}
            setUserData={setUserData}
            nav={nav}
            step={step}
            setStep={setStep}

            selectedQualificationObj={selectedQualificationObj}
            setSelectedQualificationObj={setSelectedQualificationObj}
            setQualificationArray={setQualificationArray}
            qualificationArray={qualificationArray}
            setDeletedQualificationArr={setDeletedQualificationArr}
            deletedQualificationArr={deletedQualificationArr}
          />
        }
        {step === 9 && (
          <AdvancedProfileCompletion
          // props={props}
          // userData={userData}
          // setUserData={setUserData}
          // nav={nav}
          // step={step}
          // setStep={setStep}

          // selectedQualificationObj={selectedQualificationObj}
          // setSelectedQualificationObj={setSelectedQualificationObj}
          // setQualificationArray={setQualificationArray}
          // qualificationArray={qualificationArray}
          // setDeletedQualificationArr={setDeletedQualificationArr}
          // deletedQualificationArr={deletedQualificationArr}
          />
        )}
      </ProfileWrapper>
    </>
  );
};

export default withRouter(EmployeeAdvancedProfile);
