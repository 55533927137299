import React, { useState } from 'react';
import './TrainerPitching.css'; 

const TrainerIntroScreen = () => {
  const [isAudioActive, setIsAudioActive] = useState(true);
  const [isCameraActive, setIsCameraActive] = useState(true);

  const handleAudioToggle = () => {
    setIsAudioActive((prevState) => !prevState);
  };

  const handleCameraToggle = () => {
    setIsCameraActive((prevState) => !prevState);
  };


  return (
    <div className="trainer-pitching-wrapper container-fluid">
     
      <div className="row justify-content-center m-0">
        <div className="col-12 text-center">
          <img src="/assets/images/mploi-logo-white.svg" alt="MPLOI Logo" className="logo-img" />
          <div className="heading">Welcome to the MPLOI Trainer</div>
          <div className='sub-title'>You are pitching for:</div>
         <button className='pitch-btn-pink'>Pitching for a house sale</button>
        </div>
      </div>

      <div className="row profile-sec-wrap">
       
        <div className="col-12 col-md-5 text-center">
          <div className="profile-section">
            <div className="profile-image-wrap">
              <div className='arrow-img-wrap-trainer'>
                <img src="/assets/images/arrow-line.png" alt="" /> 
              </div>
              <div className='mid-title'>
                 Pitching to: 
              </div>
            </div>
            <img src="/assets/images/user-1.png" alt="User" className="profile-img" />
            <div className='user-name'>You: <br /> <span>Chris Sharkey</span> </div>
            <div className="icons">
              <button onClick={handleAudioToggle} className="icon-button">
                <img
                  src={isAudioActive ? "/assets/images/audio-off.png" : "/assets/images/audio-on.png"}
                  alt="Audio"
                  className="icon-img"
                />
              </button>
              <button onClick={handleCameraToggle} className="icon-button">
                <img
                  src={isCameraActive ? "/assets/images/vdo-off.png" : "/assets/images/vdo-on.png"}
                  alt="Camera"
                  className="icon-img"
                />
              </button>
            </div>
            <div className='icon-title'>Click to allow access</div>
          </div>
        </div>
          
        
        <div className="col-12 col-md-5 text-center">
          <div className="profile-section-trainer">
            <div className='mob-title-pitch'>
              Pitching to: 
            </div>
            <div className='pitching-user-wrap'>
              <div className='pitching-user'>
                <img src="/assets/images/pitching-user-1.png" alt="Manager" />
                <div className='user-name'>Amanda Jones</div>
              </div>
              <div className='pitching-user'>
                <img src="/assets/images/pitching-user-2.png" alt="Manager"  />
                <div className='user-name'>Jackson Brown</div>
              </div>
              <div className='pitching-user'>
                <img src="/assets/images/pitching-user-3.png" alt="Manager"  />
                <div className='user-name'>Helen Anne</div>
              </div>
              <div className='pitching-user'>
                <img src="/assets/images/pitching-user-4.png" alt="Manager" />
                <div className='user-name'>Jacky Armstrong</div>
              </div>
            </div>
            
            
          </div>
        </div>
      </div>

     
      <div className="row bottom-content m-0">
        <div className="col-12 text-center">
        <button className="btn prepare-button-mob">Start pitching</button>
            <div className='description'>You’ll soon begin your MPLOI pitching practice. To begin make sure you<b> allow access to your camera and microphone. </b></div>
         
            <button className="btn prepare-button">Start pitching</button>
        </div>
      </div>

    </div>
  );
};

export default TrainerIntroScreen;
