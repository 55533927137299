import { UtilityFunctions } from "./UtilityFunctions";

export const NOTIFICATION_TYPES = [
    {
        key: 'terms',
        image: UtilityFunctions.getImageURL() + "bell_circle.png",
    },
    {
        key: 'privacy',
        image: UtilityFunctions.getImageURL() + "bell_circle.png",
    },
    {
        key: 'user_verified',
        image: UtilityFunctions.getImageURL() + "tick_circle.png",
    },
    {
        key: 'guardian_rejected',
        image: UtilityFunctions.getImageURL() + "close_circle.png",
    },
    {
        key: 'job_cancelled',
        image: UtilityFunctions.getImageURL() + "close_circle.png",
    },
    {
        key: 'job_allocated',
        image: UtilityFunctions.getImageURL() + "notification-user-icon.png",
    },
    {
        key: 'chat_request_sent',
        image: UtilityFunctions.getImageURL() + "notification-user-icon.png",
    },
    {
        key: 'chat_request_accepted',
        image: UtilityFunctions.getImageURL() + "tick_circle.png",
    },
    {
        key: 'chat_request_rejected',
        image: UtilityFunctions.getImageURL() + "close_circle.png",
    },
    {
        key: 'chat_request_recieved',
        image: UtilityFunctions.getImageURL() + "notification-user-icon.png",
    },
    {
        key: 'chat_message_received',
        image: UtilityFunctions.getImageURL() + "chat_circle.png",
    },
    {
        key: 'advance_profile',
        image: UtilityFunctions.getImageURL() + "advanced-profile-unlocked.svg",
    }
];

export const NOTIFICATION_TYPES_2 = {
    "terms": UtilityFunctions.getImageURL() + "bell_circle.png",
    "chat_request_sent": UtilityFunctions.getImageURL() + "notification-user-icon.png",
    "chat_message_received": UtilityFunctions.getImageURL() + "chat_circle.png",
    "chat_request_received": UtilityFunctions.getImageURL() + "notification-user-icon.png",
    "chat_request_recieved": UtilityFunctions.getImageURL() + "notification-user-icon.png",
    "chat_received": UtilityFunctions.getImageURL() + "chat_circle.png",
    'privacy': UtilityFunctions.getImageURL() + "bell_circle.png",
    'user_verified': UtilityFunctions.getImageURL() + "tick_circle.png",
    'guardian_rejected': UtilityFunctions.getImageURL() + "close_circle.png",
    'job_cancelled': UtilityFunctions.getImageURL() + "close_circle.png",
    'job_allocated': UtilityFunctions.getImageURL() + "notification-user-icon.png",
    'chat_request_accepted': UtilityFunctions.getImageURL() + "tick_circle.png",
    'chat_request_rejected': UtilityFunctions.getImageURL() + "close_circle.png",
    'advance_profile': UtilityFunctions.getImageURL() + "advanced-profile-unlocked.svg",
};

export const PUSH_NOTIFICATION_TYPES = {
    terms: "terms",
    privacy: "privacy",
    user_verified: "user_verified",
    guardian_rejected: "guardian_rejected",
    job_cancelled: "job_cancelled",
    job_allocated: "job_allocated",
    job_expiry_near: "job_cancelled",
    advance_profile: "advance_profile",
    chat_request_sent: "chat_request_sent",
    chat_request_accepted: "chat_request_accepted",
    chat_request_rejected: "chat_request_rejected",
    chat_request_recieved: "chat_request_recieved",
    chat_message_received: "chat_message_received",
}