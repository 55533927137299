import React, { useState } from "react";

import { Button, Form, Modal } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import ValidationErrorToast from "../../../components/validationErrorToast";
import { UtilityFunctions } from "../../../utils/UtilityFunctions";
import { api } from "../../../middleware/api";



const DeleteChat = (props) => {


    const deleteChat = (chatID) => {
        api.deleteApi(`${chatID}/chat/`, {})
            .then(() => {
                props.getChatList();
                props.setSelectedChat("");
            })
            .catch((err) => { })
        props.handleClose()
    }
    return (
        <Modal
            className="bg-transparent upgrade-popup logout-popup"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={props.show}
            onHide={() => {
                props.handleClose(false)
            }}
        >
            <Modal.Body>

                <div style={{ maxWidth: "400px", margin: "0 auto" }}>
                    <img src={UtilityFunctions.getImageURL() + "delete_job_icon.svg"} width={'26px'} height={'26px'} alt="" />

                    <h3 className="py-2">Delete Conversation</h3>
                    <p className="py-3">
                        Are you sure you want to delete the conversation
                    </p>


                    <Button className='ms-4 border-0 bg-transparent cancel-button' variant="primary" onClick={() => {

                        props.handleClose()
                    }}>
                        Cancel
                    </Button>
                    <Button
                        className="ms-4 border-0"
                        variant="primary"
                        onClick={() => { deleteChat(props?.selectedChat?.id) }}
                    >
                        Confirm
                    </Button>

                </div>
            </Modal.Body>
        </Modal>
    )
}
export default withRouter(DeleteChat);

