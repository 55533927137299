import React from 'react';
import PricingCard from '../PricingCard/PricingCard';
import PricingCardCandidate from '../PricingCardCandidate/PricingCardCandidate';
import './PricingCardWrap.css';

const PricingCardWrap = ({ activeTab, setActiveTab }) => {
  const card1Data = {
    icon: '/assets/images/job-posting-icon-1.svg',
    title: "Job Postings/Recruitment Only",
    subtitle: "Interview Simulator and Generator",
    subscriptionType: "12 month subscription",
    pricingOptions: [
      { businessType: "Small Business", employeeCount: "up to 5 employees", price: "$399pa" },
      { businessType: "Medium Business", employeeCount: "up to 20 employees", price: "$999pa" },
      { businessType: "Large Business", employeeCount: "up to 35 employees", price: "$1,399pa" },
      { businessType: "Corporate Business", employeeCount: "over 35 employees", price: "Get a quote" },
    ],
    buttonText: "Get Started",
  };

  const card2Data = {
    icon: '/assets/images/job-posting-icon-2.svg',
    title: "MPLOI HR and Jobs Bundle",
    subtitle: "Job Postings/Recruitment and HR Consultant",
    subscriptionType: "12 month subscription",
    pricingOptions: [
      { businessType: "Small Business", employeeCount: "up to 5 employees", price: "$599pa" },
      { businessType: "Medium Business", employeeCount: "up to 20 employees", price: "$1,499pa" },
      { businessType: "Large Business", employeeCount: "up to 35 employees", price: "$2,499pa" },
      { businessType: "Corporate Business", employeeCount: "over 35 employees", price: "Get a quote" },
    ],
    buttonText: "Get Started",
  };

  return (
    <div className="price-tab-wrapper">
      <div className="tabs">
        <button
          onClick={() => setActiveTab('comments')}
          className={activeTab === 'comments' ? 'active-tab' : ''}
        >
          Employer
        </button>
        <button
          onClick={() => setActiveTab('reported')}
          className={activeTab === 'reported' ? 'active-tab' : ''}
        >
          Candidate
        </button>
      </div>
      <div className="pricing-card-wrap">
        {activeTab === 'comments' ? (
          <div className="card-wrapper">
            <PricingCard {...card1Data} />
            <PricingCard {...card2Data} />
          </div>
        ) : (
          <div className="card-wrapper-2">
            <PricingCardCandidate />
          </div>
        )}
      </div>
    </div>
  );
};

export default PricingCardWrap;
