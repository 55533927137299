import React, { useState } from "react"
import { Fragment } from "react";
import { Link, withRouter } from "react-router-dom"
import { useSwiper } from "swiper/react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";
import { STRING_CONSTANTS } from "../../../utils/constants/stringConstants";
import onboardingScreenData from "../../Onboarding/collections";
import { UtilityFunctions } from "../../../utils/UtilityFunctions";
import OnboardingScreen from "../../Onboarding/OnboardingScreen";

const OnboardingTutorial = (props: any) => {
    const [activeIndex, setactiveIndex] = useState(0)

    const SwiperButtonNext = ({ children }) => {
        const swiper = useSwiper();
        if (activeIndex !== 2) {
            return <button className="next-btn" onClick={() => swiper.slideNext()}>{children}</button>
        }
        else return <button className="next-btn" onClick={() => props?.history?.push(STRING_CONSTANTS.navigationString.dashboard.settings)}>Done</button>
    };

    const SwiperButtonSkip = ({ children }) => {
        const swiper = useSwiper();
        return <button className="me-3 text-white next-btn border-0 bg-transparent skip-text"
            onClick={() => swiper.slideTo(2)}>
            {children}
        </button>;
    };

    const onboardingScreen = onboardingScreenData.map((item, index) => {
        return (
            <Fragment key={index}>
                <SwiperSlide key={index}>
                    <section
                        className="main-wrap"
                        style={{ backgroundImage: `url("${item.image}")` }}
                    >
                        <div className="row">
                            <div className="col-md-6 col-lg-6 col-xl-6 col-12">
                                <div className="left-wrap">
                                    <div className="logo-wrap">
                                        <img src={item.primaryLogo} onClick={() => props?.history?.push('/')} />
                                    </div>
                                    <div className="caption-wrap">
                                        <h2>{item.title}</h2> <p>{item.subtitle}</p>
                                    </div>
                                    <div className="progress-wrap d-none d-lg-block"> </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6 col-xl-6 col-12">
                                <div className="right-wrap">
                                    <div className="btn-maccount d-none d-md-block">
                                        {/* <Link to={STRING_CONSTANTS.navigationString.login}>
                                            <button>
                                                {STRING_CONSTANTS.onboardingScreen.buttons.account_exists}
                                            </button>
                                        </Link> */}
                                    </div>
                                    <div className="btn-nextSkip">
                                        {activeIndex !== 2 ?
                                            <SwiperButtonSkip>
                                                {STRING_CONSTANTS.onboardingScreen.buttons.skip_all}
                                            </SwiperButtonSkip> :
                                            <div></div>
                                        }
                                        <SwiperButtonNext>
                                            {STRING_CONSTANTS.onboardingScreen.buttons.next_screen}
                                            <img
                                                src={UtilityFunctions.getImageURL() + "arrow_right.svg"}
                                                alt=""
                                            />
                                        </SwiperButtonNext>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </SwiperSlide>
            </Fragment>
        );
    });
    return (
        <Swiper
            pagination={{
                type: "progressbar",
            }}
            modules={[Pagination]}
            onSlideChange={({ activeIndex }) => setactiveIndex(activeIndex)}
            initialSlide={activeIndex}
        >
            {onboardingScreen}
        </Swiper>)
}

export default withRouter(OnboardingTutorial)