import React from "react";
import { UtilityFunctions } from "../../src/utils/UtilityFunctions";
import { Button, Modal } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { STRING_CONSTANTS } from "../utils/constants/stringConstants";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../store";

const GuestPrompt = (props) => {
  const dispatch = useDispatch();
  const { setSidebar } = bindActionCreators(actionCreators, dispatch);

  return (
    <Modal
      className="bg-transparent logout-popup"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={props.show}
      onHide={props.handleClose}
    >
      <Modal.Body>
        <img
          alt="logout"
          src={UtilityFunctions.getImageURL() + "access-features.svg"}
        />
        <h3 className="py-2">Access Features</h3>
        <p className="py-3 accessfeatures-p">
          To access all our features you will need to create an account with us.
        </p>
        <div>
          <Button className='ms-4 border-0 bg-transparent cancel-button accessfeatures-c-btn' variant="primary" onClick={props.handleClose}>
            Cancel
          </Button>
          <Button
            className="ms-4 border-0"
            variant="success"
            onClick={() => {
              props.history.push(STRING_CONSTANTS.navigationString.signup, { isGuest: true })
              setSidebar({ sidebar_name: "employee-details", show_sidebar: false })
            }}
          >
            Join Now
          </Button>
        </div>
      </Modal.Body>
    </Modal>

  )
}
export default withRouter(GuestPrompt);