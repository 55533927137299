import React, { Fragment, useEffect, useState } from "react";
import { api } from "../../../middleware/api";
import { UtilityFunctions } from "../../../utils/UtilityFunctions";
import { userService } from "../../../_services";
import DeleteJob from "../../../components/deleteJob";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { actionCreators, State } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import { END_POINTS } from "../../../utils/constants/endPoints";
import moment from "moment";

const JobSidebar = (props: any) => {
    const dispatch = useDispatch()
    const { setSidebar, setJobsList } = bindActionCreators(actionCreators, dispatch);
    const state = useSelector((state: State) => state);

    const [showPendingchat, setShowPendingchat] = useState(false);
    const [selectedJob, setSelectedJob] = useState<any>();
    const [experience, setExperience] = useState<any>()
    const [showActiveConversation, setShowActiveConversation] = useState(false);
    const [showMploiees, setShowMploiees] = useState(false);
    const [mploieesData, setMploieesData] = useState([]);
    const [activeConversation, setActiveConversation] = useState([]);
    const [pendingList, setPendingList] = useState([]);
    const [showStatus, setShowStatus] = useState(false)
    const [show, setShow] = useState<boolean>();

    const getJobDetails = () => {
        props?.selectedJob?.id && api.get(`${props?.selectedJob?.id}/job/`, null)
            .then((res) => {
                setSelectedJob(res);
            })
            .catch(
        )
    }

    useEffect(() => {
        getJobDetails();
    }, [props])

    const jobStatusPatch = (job, status) => {
        api.patch(`${job?.id}/job/`, { "status": `${status}` })
            .then(res => {
                userService.fetchAllJobs(END_POINTS.filter_jobs, state.jobFilterSearch, (res) => {
                    setJobsList(res.data)
                });
            })
            .catch(
        )
    }

    useEffect(() => {
        setSelectedJob(props?.searchSelected)
    }, [props?.searchSelected])

    useEffect(() => {
        if (selectedJob?.experience) {
            setExperience(daysFormatter(selectedJob?.experience))
        }
    }, [selectedJob])


    const daysFormatter = (num) => {
        var str = num.toString();
        var numarray = str.split('.');
        var a = new Array();
        a = numarray;
        return a;
    }

    const getAllMploiees = () => {
        userService.getAllMploiees(selectedJob.id)
            .then((res) => {
                setMploieesData(res)
            })
            .catch((err) => {

            })
    }

    const getActiveConversation = () => {
        userService.getActiveConversation(selectedJob.id)
            .then((res) => {
                setActiveConversation(res.rooms)
            })
            .catch((err) => {

            })
    }

    const getPendingRequest = () => {
        userService.getPendingRequest(selectedJob.id)
            .then((res) => {
                setPendingList(res)
            })
            .catch((err) => {

            })
    }
    const handleClose = () => {
        setShow(false);
    }

    let cover = selectedJob?.cover;
    if (selectedJob?.coverCdnUrl) {
        cover = selectedJob?.coverCdnUrl;
    }

    return (
        <div>
            {!selectedJob?.id ? <div></div> : <>
                <div className={`modal-content ${showStatus && "bg-blue-selct-dropdonw"} ${showPendingchat || showActiveConversation || showMploiees ? 'd-none' : 'd-gird'}`} id="full_cheat_st">
                    <div className="modal-body">
                        <div className="job-list-wrap d-block pb-0">
                            <div className="d-flex justify-content-between align-items-start">
                                <div className="jb-profl-lft">
                                    <div className="job-pic"><img alt="" src={cover || UtilityFunctions.getImageURL() + "bildeing-squre-img.png"} className="ms-0" />
                                    </div>
                                    <div className="job-name"><h3>{selectedJob?.title}</h3> <p><span>
                                        {selectedJob?.location?.suburb}
                                    </span>
                                        &nbsp;
                                        <img src={UtilityFunctions.getImageURL() + "svg-2px-dot.svg"} alt="" />
                                        <span>
                                            &nbsp;
                                            {"$" + (selectedJob.minSalary > 1000 ? Math.round((selectedJob.minSalary * 10) / 1000) / 10 + "k" : Math.round(selectedJob.minSalary)) + ((+selectedJob.maxSalary >= +selectedJob.minSalary) ? ('-$' + (selectedJob.maxSalary > 1000 ? Math.round((selectedJob.maxSalary * 10) / 1000) / 10 + "k" : Math.round(selectedJob.maxSalary))) : "")}
                                            {selectedJob.salaryCheckoutType ?
                                                (selectedJob.salaryCheckoutType === "yearly" && "/yr" ||
                                                    selectedJob.salaryCheckoutType === "monthly" && "/mo" ||
                                                    selectedJob.salaryCheckoutType === "weekly" && "/wk" ||
                                                    selectedJob.salaryCheckoutType === "daily" && "/daily" ||
                                                    selectedJob.salaryCheckoutType === "hourly" && "/hr") :
                                                ""
                                            }
                                            &nbsp;
                                        </span>
                                        <img src={UtilityFunctions.getImageURL() + "svg-2px-dot.svg"} />
                                        &nbsp;
                                        {UtilityFunctions.convertMonthsToYearsAndMonths(selectedJob?.experience)}
                                        {/* {daysFormatter(selectedJob?.experience)[0] <= 0 || daysFormatter(selectedJob?.experience)[1].substring(0, 1) >= 0 && <img src={UtilityFunctions.getImageURL() + "svg-2px-dot.svg"} alt="" />}
                                        <span>
                                            {experience?.[0] > 0 ? experience?.[0] + (experience?.[1].substring(0, 1) > 0 ? " Yr,\u00A0" : " Yr\u00A0") : null}
                                            {(experience?.[1].substring(0, 1) > 0 ? experience?.[1].substring(0, 1) + " Mo\u00A0" : null)}
                                        </span> */}
                                    </p>
                                    </div>
                                </div>
                                <div className={`jb-allocat-rgt text-end`}>
                                    {/*allocated-green, allocated-yellow, canceled-pink, completed-blue*/}
                                    <div className={`main-dropdon-vk ${showStatus && "active"}`}
                                        onClick={() => selectedJob?.status !== "completed" && setShowStatus(prev => !prev)}>
                                        {selectedJob?.status === 'active' ?
                                            <p className="allocated-green">
                                                <img alt="" src={UtilityFunctions.getImageURL() + "allocated-green.svg"} />
                                                Active
                                            </p>
                                            : selectedJob?.status === 'allocated' ?
                                                <p className="allocated-yellow">
                                                    <img alt="" src={UtilityFunctions.getImageURL() + "allocated-yellow.svg"} />
                                                    Allocated
                                                </p>
                                                : selectedJob?.status === 'cancelled' ?
                                                    <p className="canceled-pink">
                                                        <img alt="" src={UtilityFunctions.getImageURL() + "canceled-pink.svg"} />
                                                        Cancelled
                                                    </p> : selectedJob?.status === 'completed' ?
                                                        <p className="completed-blue">
                                                            <img alt="" src={UtilityFunctions.getImageURL() + "completed-blue.svg"} />
                                                            Completed
                                                        </p> : null
                                        }
                                        <div className="dormdo-popup">
                                            {(selectedJob?.status === "cancelled") &&
                                                <button className="allocated-green"
                                                    onClick={() => jobStatusPatch(selectedJob, 'active')}>
                                                    <img alt="" src={UtilityFunctions.getImageURL() + "allocated-green.svg"} />
                                                    Active
                                                </button>}
                                            <button className="allocated-yellow" disabled style={{ opacity: 0.4 }}
                                                onClick={() => jobStatusPatch(selectedJob, 'allocated')}>
                                                <img alt="" src={UtilityFunctions.getImageURL() + "allocated-yellow.svg"} />
                                                Allocated
                                            </button>
                                            {(selectedJob.status === "active") && <button className="canceled-pink"
                                                onClick={() => jobStatusPatch(selectedJob, 'cancelled')}>
                                                <img alt="" src={UtilityFunctions.getImageURL() + "canceled-pink.svg"} />
                                                Cancelled
                                            </button>}
                                            {((selectedJob.status === "active") || (selectedJob.status === "allocated")) && <button className="completed-blue"
                                                onClick={() => jobStatusPatch(selectedJob, 'completed')}>
                                                <img alt="" src={UtilityFunctions.getImageURL() + "completed-blue.svg"} />
                                                Completed
                                            </button>}
                                        </div>
                                    </div>
                                    <span>{selectedJob?.type === 'fullTime' ? 'Full Time' : selectedJob?.type === 'partTime' ? 'Part Time' : selectedJob?.type === 'casual' ? 'Casual' : 'None'}</span>
                                </div>
                            </div>

                            <div className="bordr-grdnt"></div>
                        </div>
                        <div className='profile-detail-top'>

                            <div className='d-flex justify-content-between align-items-center mb-2'>

                                <h4>Job Details</h4>
                                <h5>
                                    {/* <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g opacity="0.5">
                                            <path d="M6 0.5C8.48528 0.5 10.5 2.51472 10.5 5C10.5 6.86262 9.0802 8.81485 6.3 10.9C6.12222 11.0333 5.87778 11.0333 5.7 10.9C2.9198 8.81485 1.5 6.86262 1.5 5C1.5 2.51472 3.51472 0.5 6 0.5ZM6 3.5C5.17157 3.5 4.5 4.17157 4.5 5C4.5 5.82843 5.17157 6.5 6 6.5C6.82843 6.5 7.5 5.82843 7.5 5C7.5 4.17157 6.82843 3.5 6 3.5Z" fill="white" />
                                        </g>
                                    </svg> */}
                                    <img src={UtilityFunctions.getImageURL() + "map-mark-12-light-gray.svg"} alt="" />

                                    {selectedJob?.location?.address}</h5>
                            </div>

                            <div className='label-design mt-0'>
                                <a className='label-button clr-green'>{selectedJob?.category?.name || "Design & Architecture"}</a>
                                <a className='label-button clr-green'>{selectedJob?.subCategory?.name || "Illustration & Animation"}</a>
                            </div>
                            <p>
                                {selectedJob?.description || "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ac sed pellentesque quam bibendum. Vitae integer felis vitae sapien. Tellus a nullam hendrerit enim. Congue turpis et, cursus diam magna. Sodales duis et enim porta praesent. Turpis aliquet sit id et, urna, tincidunt."
                                }</p>

                        </div>
                        {selectedJob?.status === "active" ? <div className='profile-detail-top'>
                            <div className='bordr-grdnt'></div>
                            <div className="mytable" >
                                <p >Matched Employees <span
                                    onClick={() => { selectedJob?.status !== 'allocated' && setShowMploiees(true); getAllMploiees(); }} className={selectedJob?.status !== 'allocated' ? 'cursor-type-pointer' : ''}
                                >{
                                        selectedJob?.matchedEmployees} Employees</span></p>
                                <p>Active Conversations <span
                                    onClick={() => { setShowActiveConversation(true); getActiveConversation(); }} className="cursor-type-pointer"
                                >
                                    {selectedJob?.activeRooms} Converstations</span></p>
                                <p id="pend_req" >Pending Chat Requests <span
                                    onClick={() => { setShowPendingchat(true); getPendingRequest(); }} className="cursor-type-pointer"
                                >{selectedJob?.requestedRooms} Pending</span></p>
                                <p id="pend_req" >Interviews Completed <span
                                    onClick={() => { setShowPendingchat(true); getPendingRequest(); }} className="cursor-type-pointer"
                                >{selectedJob?.requestedRooms} Interviews</span></p>
                            </div>
                        </div> : <div></div>}
                        {((selectedJob?.status === 'allocated') || ((selectedJob?.status === "completed") && selectedJob?.allocatedEmployee)) ?
                            <div className="pending-chets">
                                <div className="media d-flex">
                                    <img src={selectedJob?.allocatedEmployee?.profilePhoto || UtilityFunctions.getImageURL() + 'user-bg-dark.png'} alt="" width="40" height="40" />
                                    <div className="text-start">
                                        <p>Allocated to:</p>
                                        <h5>{selectedJob?.allocatedEmployee?.firstName}</h5>
                                    </div>
                                </div>
                            </div> : <div></div>}
                    </div>
                    <div className="modal-footer justify-content-between w-100 ">
                        <div className="text-white w-100 align-bottom">Posted {moment(selectedJob?.createdAt).format("DD/MM/YY")} | {moment(selectedJob?.createdAt).format("h:mm A")}</div>
                        {(selectedJob?.status === "active" || selectedJob?.status === "cancelled") ?
                            <Fragment>
                                <div className='bordr-grdnt'></div>
                                <div className="d-flex justify-content-between w-100 m-0">
                                    <button onClick={() => setShow(true)}>Delete</button>
                                    {selectedJob.status === "active" &&
                                        <button
                                            type="button"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                            onClick={() => {
                                                props?.history?.push('/dashboard/job-creation/', { job: { ...selectedJob }, editJob: true })
                                                setSidebar({ sidebar_name: 'job-details', show_sidebar: false })
                                            }} className="active">Edit
                                        </button>}
                                </div>
                            </Fragment> :
                            <div></div>}
                    </div>
                </div>
                <div className={`modal-content ${showPendingchat || showActiveConversation || showMploiees ? 'd-flex' : 'd-none'}`} id="pending_cheat_st">
                    <div className="modal-body">
                        <div className="job-list-wrap">
                            <div className="jb-profl-lft">
                                <div className="job-pic"><img alt="" src={cover || UtilityFunctions.getImageURL() + "jobs-bg-dark.png"} className="ms-0" />
                                </div>
                                <div className="job-name">
                                    <h3>{selectedJob?.title}</h3>
                                    <p>
                                        <span>
                                            {selectedJob?.location?.suburb}
                                        </span>
                                        &nbsp;
                                        <img src={UtilityFunctions.getImageURL() + "svg-2px-dot.svg"} alt="" />
                                        <span>
                                            &nbsp;
                                            {"$" + (selectedJob.minSalary > 1000 ? Math.round((selectedJob.minSalary * 10) / 1000) / 10 + "k" : Math.round(selectedJob.minSalary)) + ((+selectedJob.maxSalary >= +selectedJob.minSalary) ? ('-$' + (selectedJob.maxSalary > 1000 ? Math.round((selectedJob.maxSalary * 10) / 1000) / 10 + "k" : Math.round(selectedJob.maxSalary))) : "")}
                                            {selectedJob.salaryCheckoutType ?
                                                (selectedJob.salaryCheckoutType === "yearly" && "/yr" ||
                                                    selectedJob.salaryCheckoutType === "monthly" && "/mo" ||
                                                    selectedJob.salaryCheckoutType === "weekly" && "/wk" ||
                                                    selectedJob.salaryCheckoutType === "daily" && "/daily" ||
                                                    selectedJob.salaryCheckoutType === "hourly" && "/hr") :
                                                ""
                                            }
                                            &nbsp;
                                        </span>
                                        <img src={UtilityFunctions.getImageURL() + "svg-2px-dot.svg"} alt="" />
                                        &nbsp;
                                        {UtilityFunctions.convertMonthsToYearsAndMonths(selectedJob?.experience)}
                                        {/* &nbsp;
                                        <span>
                                            {experience?.[0] > 0 ? experience?.[0] + (experience?.[1].substring(0, 1) > 0 ? " Yr,\u00A0" : " Yr\u00A0") : null}
                                            {(experience?.[1].substring(0, 1) > 0 ? experience?.[1].substring(0, 1) + " Mo" : null)}
                                        </span> */}
                                    </p>
                                </div>
                            </div>
                            <div className={`jb-allocat-rgt text-end`}>
                                {/*allocated-green, allocated-yellow, canceled-pink, completed-blue*/}
                                <div className={`main-dropdon-vk ${showStatus && "active"}`}
                                    onClick={() => selectedJob?.status !== "completed" && setShowStatus(prev => !prev)}>
                                    {selectedJob?.status === 'active' ?
                                        <p className="allocated-green">
                                            <img alt="" src={UtilityFunctions.getImageURL() + "allocated-green.svg"} />
                                            Active
                                        </p>
                                        : selectedJob?.status === 'allocated' ?
                                            <p className="allocated-yellow">
                                                <img alt="" src={UtilityFunctions.getImageURL() + "allocated-yellow.svg"} />
                                                Allocated
                                            </p>
                                            : selectedJob?.status === 'cancelled' ?
                                                <p className="canceled-pink">
                                                    <img alt="" src={UtilityFunctions.getImageURL() + "canceled-pink.svg"} />
                                                    Cancelled
                                                </p> : selectedJob?.status === 'completed' ?
                                                    <p className="completed-blue">
                                                        <img alt="" src={UtilityFunctions.getImageURL() + "completed-blue.svg"} />
                                                        Completed
                                                    </p> : null
                                    }
                                    <div className="dormdo-popup">
                                        {(selectedJob?.status === "cancelled") &&
                                            <button className="allocated-green"
                                                onClick={() => jobStatusPatch(selectedJob, 'active')}>
                                                <img alt="" src={UtilityFunctions.getImageURL() + "allocated-green.svg"} />
                                                Active
                                            </button>}
                                        {/* <button className="allocated-yellow" disabled
                                                onClick={() => jobStatusPatch(selectedJob, 'allocated')}>
                                                <img alt="" src={UtilityFunctions.getImageURL() + "allocated-yellow.svg"} />
                                                Allocated
                                            </button> */}
                                        {(selectedJob.status === "active") && <button className="canceled-pink"
                                            onClick={() => jobStatusPatch(selectedJob, 'cancelled')}>
                                            <img alt="" src={UtilityFunctions.getImageURL() + "canceled-pink.svg"} />
                                            Cancelled
                                        </button>}
                                        {((selectedJob.status === "active") || (selectedJob.status === "allocated")) && <button className="completed-blue"
                                            onClick={() => jobStatusPatch(selectedJob, 'completed')}>
                                            <img alt="" src={UtilityFunctions.getImageURL() + "completed-blue.svg"} />
                                            Completed
                                        </button>}
                                    </div>
                                </div>
                                <span>{selectedJob?.type === 'fullTime' ? 'Full Time' : selectedJob?.type === 'partTime' ? 'Part Time' : selectedJob?.type === 'casual' ? 'Casual' : 'None'}</span>
                            </div>
                        </div>
                        {showMploiees ?
                            <>
                                <div className="pending-chets-list">
                                    <p id="back_to_all1">
                                        <img className="cursor-type-pointer" src={UtilityFunctions.getImageURL() + 'left-icon.svg'} alt="" onClick={() => setShowMploiees(false)} />
                                        <span className="main-sp">Matched Employees <span>
                                            ({selectedJob?.matchedEmployees})
                                        </span></span>
                                    </p>
                                </div>
                                <div className="pending-chets">
                                    {mploieesData?.map((data) => {
                                        return (
                                            <div className="media d-flex align-items-center" key={data?.id}>
                                                <img src={data?.profilePhoto || UtilityFunctions.getImageURL() + 'user-bg-dark.png'} alt="" width="40" height="40" />
                                                <div className="text-start">
                                                    {/* <p>Allocated to:</p> */}
                                                    <h5>{data.user.firstName
                                                        // + " " + data.user.lastName
                                                    }</h5>
                                                </div>
                                            </div>)
                                    })}

                                </div>
                            </>
                            : ''}
                        {showActiveConversation ?
                            <>
                                <div className="pending-chets-list">
                                    <p id="back_to_all1">
                                        <img className="cursor-type-pointer" src={UtilityFunctions.getImageURL() + 'left-icon.svg'} alt="" onClick={() => setShowActiveConversation(false)} />
                                        <span className="main-sp">Active Conversations<span>
                                            ({selectedJob?.activeRooms})
                                        </span></span>
                                    </p>
                                </div>
                                <div className="pending-chets">
                                    {activeConversation?.map((data) => {
                                        return (
                                            <div className="media d-flex align-items-center">
                                                <img src={data?.employee?.employee?.profilePhoto || UtilityFunctions.getImageURL() + 'user-bg-dark.png'} alt="" width="40" height="40" />
                                                <div className="text-start">
                                                    {/* <p>Allocated to:</p> */}
                                                    <h5>{data.employee.employee.firstName}</h5>
                                                </div>
                                            </div>)
                                    })}

                                </div>
                            </>
                            : ''}
                        {showPendingchat ?
                            <>
                                <div className="pending-chets-list">
                                    <p id="back_to_all">
                                        <img className="cursor-type-pointer" src={UtilityFunctions.getImageURL() + 'left-icon.svg'} alt="" onClick={() => setShowPendingchat(false)} />
                                        <span className="main-sp">Pending Chat Requests<span>
                                            ({selectedJob?.requestedRooms})
                                        </span></span>
                                    </p>
                                </div>
                                <div className="pending-chets">
                                    {pendingList?.map((data) => {
                                        return (
                                            <div className="media d-flex align-items-center">
                                                <img src={data?.employee?.employee?.profilePhoto || UtilityFunctions.getImageURL() + 'user-bg-dark.png'} alt="" width="40" height="40" />
                                                <div className="text-start">
                                                    {/* <p>Allocated to:</p> */}
                                                    <h5>{data.employee.employee.firstName}</h5>
                                                </div>
                                            </div>)
                                    })}
                                </div></>
                            : ''}

                    </div>
                    <div className="modal-footer justify-content-between w-100 ">
                        <div className="text-white w-100 align-bottom">Posted {moment(selectedJob?.createdAt).format("DD/MM/YY")} | {moment(selectedJob?.createdAt).format("h:mm A")}</div>
                        {(selectedJob?.status === "active" || selectedJob?.status === "cancelled") ?
                            <Fragment>
                                <div className='bordr-grdnt'></div>
                                <div className="d-flex justify-content-between w-100 m-0">
                                    <button onClick={() => setShow(true)}>Delete</button>
                                    {selectedJob.status === "active" &&
                                        <button
                                            type="button"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                            onClick={() => {
                                                props?.history?.push('/dashboard/job-creation/', { job: { ...selectedJob }, editJob: true })
                                                setSidebar({ sidebar_name: 'job-details', show_sidebar: false })
                                            }} className="active">Edit
                                        </button>}
                                </div>
                            </Fragment> :
                            <div></div>}
                    </div>
                </div>
            </>
            }

            <DeleteJob
                show={show}
                handleClose={handleClose}
                getAllJobs={props?.getAllJobs}
                selectedJobID={props?.selectedJob?.id}
                selectedJob={selectedJob}
            />
        </div>);
}

export default withRouter(JobSidebar);