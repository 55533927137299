import React, { useEffect, useState } from 'react';
import moment from "moment";
import { withRouter } from 'react-router-dom';
import momentUtils from "./utils"

const ChatItems = (props) => {
    const [chatTime, setChatTime] = useState('');
    const [time, setTime] = useState(Date.now())
    const showTime = (currentMsg: any, prevMsg: any) => {
        if (!currentMsg || !prevMsg) {
            return false;
        }
        const currentMsgTime = moment(currentMsg?.createdAt);
        const prevMsgTime = moment(prevMsg?.createdAt);
        const duration = moment.duration(prevMsgTime.diff(currentMsgTime));
        const minutes = duration.asMinutes();
        if (minutes >= 1) return true;

        return false;
    };
    const showDate = (currentMsg: any, prevMsg: any) => {
        if (!currentMsg || !prevMsg) {
            return true;
        }
        const currentMsgTime = moment(currentMsg?.createdAt).format('MMMM Do YYYY');
        const prevMsgTime = moment(prevMsg?.createdAt).format('MMMM Do YYYY');

        if (currentMsgTime === prevMsgTime) {
            return false;
        }
        return true;
    };

    useEffect(() => {
        const currentTime = moment()
        const messageTime = props.chat?.createdAt

        var timeDifferenceInDays = currentTime.diff(messageTime, "days")
        var timeDifferenceInHours = currentTime.diff(messageTime, "hours")
        var timeDifference = currentTime.diff(messageTime, "minutes")
        if (timeDifferenceInDays > 0) {
            setChatTime(momentUtils.formatTime(messageTime, 'ddd LT'))

        }
        else {
            if (timeDifferenceInHours > 0) {
                if (timeDifferenceInHours < 2) {
                    //setChatTime(timeDifferenceInHours.toString()+ "an hour ago")
                    setChatTime(momentUtils.timeFromNow(messageTime))
                }
                else {
                    setChatTime(momentUtils.formatTime(messageTime, 'ddd LT'))
                    //setChatTime(timeDifferenceInHours.toString()+ " hours ago")
                }

            }
            else {
                if (timeDifference > 0) {
                    if (timeDifference < 2) {
                        //setChatTime( "a minute ago")
                        setChatTime(momentUtils.timeFromNow(messageTime))
                    }
                    else {
                        //setChatTime(timeDifference.toString()+ " minutes ago")
                        setChatTime(momentUtils.timeFromNow(messageTime))
                    }
                }
                else {
                    setChatTime("Just Now")
                }
            }
        }
    }, [Date.now()])

    const getDay = date => {
        const startTime = moment(Date.now());
        const end = moment(date);
        const duration = moment.duration(startTime.diff(end));
        const minutes = duration.asMinutes();
        if (minutes < 1) {
            return 'Just Now';
        } else if (minutes < 60) {
            return moment(date).fromNow()
        } else {
            return moment(date).format('ddd')?.toLocaleUpperCase();
        }
    };

    const getTime = date => {
        const startTime = moment(Date.now());
        const end = moment(date);
        const duration = moment.duration(startTime.diff(end));
        const minutes = duration.asMinutes();
        if (minutes < 60) {
            return '';
        } else {
            return moment(date).format('LT');
        }
    };

    return (
        <div>
            {props.chat && showDate(props.chat, props.chatContent[props.index + 1]) ? (

                <div className="time-and-dat-groupByDate  ml-auto">
                    {moment(props.chat?.createdAt).format('MMMM Do YYYY')}
                </div>
            ) : null}
            <div className="other-cheat-content">
                <div className="chat-content-bl">{props.chat.content}</div>
                {props.chat.user !== props.chatContent[props.index > 0 && props.index - 1]?.user || showTime(props.chat, props.chatContent[props.index - 1]) ?
                    <span className="time-and-dat ml-auto me-1">
                        <div className='dots-setup d-flex align-items-center gap-2'>
                            <span>{props?.chat?.isRead
                                ? '   Seen'
                                : props?.chat?.delivered == true
                                    ? ' Delivered'
                                    : ' Sent'}</span>
                            <span>{getDay(props?.chat?.createdAt)}</span>
                            {getTime(props?.chat?.createdAt) !== "" && <span>{getTime(props?.chat?.createdAt)}</span>}
                        </div>
                    </span> : null}
            </div>
        </div>
    )
}
export default withRouter(ChatItems);