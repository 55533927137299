import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { STRING_CONSTANTS } from "../../utils/constants/stringConstants";
import { UtilityFunctions } from "../../utils/UtilityFunctions";
import { DatePicker } from "@blueprintjs/datetime";
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import ValidationErrorToast from "../../components/validationErrorToast";
import moment from "moment";
import { userService } from "../../_services";
import SuccessToast from "../../components/successToast";

const PastJobDuration = (props) => {
  const { setEmployerArray, employerArray, setSelectedEmployerObj, selectedEmployerObj, nav, deletedEmployerArr, deletedQualificationArr } = props
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [isFromDateOpen, setIsFromDateOpen] = useState<boolean>(false);
  const [isToDateOpen, setIsToDateOpen] = useState<boolean>(false);
  const [hideProfile, setHideProfile] = useState(false);
  const fromDateRef = useRef<any>(null);
  const toDateRef = useRef<any>(null);
  const isProfileBeingUpdated = props?.location?.state?.isProfileBeingUpdated;
  const isEmployerBeingEdited = props?.location?.state?.isEmployerBeingEdited
  const [errorState, setErrorState] = useState(false);

  useEffect(() => {
    if (selectedEmployerObj?.startDate) {
      setFromDate(new Date(selectedEmployerObj?.startDate))
    }
    if (selectedEmployerObj?.endDate) {
      setToDate(new Date(selectedEmployerObj?.endDate))
    }
    if (selectedEmployerObj?.isProfileHidden) {
      setHideProfile(true)
    }
  }, [selectedEmployerObj])

  //HANDLES EXPERIENCES AND PATCHES THEM WHEN PROFILE IS BEING UPDATED BY THE USER
  const addNewExperience = () => {
    let newSubmitExperienceData = { ...selectedEmployerObj, startDate: moment(fromDate).format("YYYY-MM-DD"), endDate: toDate ? moment(toDate).format("YYYY-MM-DD") : null, isProfileHidden: hideProfile }
    delete newSubmitExperienceData["action"]
    delete newSubmitExperienceData["indexToEdit"]
    if (newSubmitExperienceData?.category) {
      newSubmitExperienceData["category"] = [newSubmitExperienceData?.category?.[0]?.id]
    }
    if (newSubmitExperienceData?.subCategory) {
      newSubmitExperienceData["subCategory"] = [newSubmitExperienceData?.subCategory?.[0]?.id]
    }
    if (newSubmitExperienceData?.employer) {
      newSubmitExperienceData["employer"] = newSubmitExperienceData?.employer?.id
    }
    if (newSubmitExperienceData?.unregisteredEmployer) {
      newSubmitExperienceData["unregisteredEmployer"] = newSubmitExperienceData?.unregisteredEmployer
    }
    document.body.classList.add('loading-indicator');
    userService.userProfile({ experienceHistory: [newSubmitExperienceData] })
      .then(() => {
        document.body.classList.remove('loading-indicator');
        SuccessToast("Profile Update", "Your profile has been updated successfully")
        props.history.push(STRING_CONSTANTS.navigationString.dashboard.settings, { tab: "updateMyExperience" })
      })
      .catch(e => {
        ValidationErrorToast(e[0], e[1]);
        document.body.classList.remove('loading-indicator');
      })
  }

  const submitHandler = () => {
    if (!fromDate) {
      ValidationErrorToast("Start Date", "Please select a start date before proceeding")
      setErrorState(true)
    }
    else {
      setSelectedEmployerObj({ ...selectedEmployerObj, startDate: moment(fromDate).format("YYYY-MM-DD"), endDate: toDate ? moment(toDate).format("YYYY-MM-DD") : null, isProfileHidden: hideProfile })
      if (selectedEmployerObj?.action === "add") {
        setEmployerArray([...employerArray, { ...selectedEmployerObj, startDate: moment(fromDate).format("YYYY-MM-DD"), endDate: toDate ? moment(toDate).format("YYYY-MM-DD") : null, isProfileHidden: hideProfile }])
        if (isProfileBeingUpdated) {
          addNewExperience()
        }
      }
      if (selectedEmployerObj?.action === "edit" || isEmployerBeingEdited) {
        const modifiedArr = employerArray.map((employer, index) => {
          if (index === selectedEmployerObj.indexToEdit) {
            return { ...selectedEmployerObj, startDate: moment(fromDate).format("YYYY-MM-DD"), endDate: toDate ? moment(toDate).format("YYYY-MM-DD") : null, isProfileHidden: hideProfile }
          }
          else {
            return employer
          }
        })
        setEmployerArray(modifiedArr)
        if (isProfileBeingUpdated) {
          addNewExperience()
        }
      }
      if (!isProfileBeingUpdated) {
        nav.next()
      }
    }
  }

  return (
    <>
      <div className='top-divde-spa'>
        <span>
          <i
            className="fa fa-angle-left text-white d-flex justify-content-between"
            aria-hidden="true"
            onClick={() => {
              nav?.prev()
            }}
          >
          </i>
        </span>
        <div className="step-content signup-mar">
          <button type='button' disabled>
            {STRING_CONSTANTS.advanced_profile.step4.title}
          </button>
          <p>
            {STRING_CONSTANTS.advanced_profile.step4.subtitle}
          </p>
          <div className="step-text">
            {isProfileBeingUpdated ? "Step 4/4" : STRING_CONSTANTS.advanced_profile.step4.step}
          </div>
        </div>
      </div>
      <form>
        <div className="form-wrap business-detailss">
          <div className="v-form-width min-height-auto">
            <div className="v-form-width min-height-auto">
              <div className="form-floating mb-3 label-dark">
                <input type='text'
                  className={`form-control enter-buss`}
                  //  ${errorState.unregistered ? "error" : ""}`}
                  style={{ caretColor: 'transparent', background: '#2b1d3f' }}
                  placeholder="Employed Since"
                  onClick={() => {
                    setIsFromDateOpen(prev => !prev)
                    setIsToDateOpen(false)
                  }}
                  value={fromDate ? moment(fromDate).format("DD-MM-YYYY") : ""}
                  // ref={fromDateRef}
                  onChange={e => {
                  }}
                  readOnly
                />
                <label htmlFor="floatingInput">{STRING_CONSTANTS.advanced_profile.step7.start_date}</label>
                <span className="input-group-text">
                  <img src={UtilityFunctions.getImageURL() + "calendar.svg"} alt="" />
                  <img src={UtilityFunctions.getImageURL() + "calendar-fill-blue.svg"} alt="" />
                  {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g opacity="0.5">
                      <path d="M17.75 3C19.5449 3 21 4.45507 21 6.25V17.75C21 19.5449 19.5449 21 17.75 21H6.25C4.45507 21 3 19.5449 3 17.75V6.25C3 4.45507 4.45507 3 6.25 3H17.75ZM19.5 8.5H4.5V17.75C4.5 18.7165 5.2835 19.5 6.25 19.5H17.75C18.7165 19.5 19.5 18.7165 19.5 17.75V8.5ZM7.75 14.5C8.44036 14.5 9 15.0596 9 15.75C9 16.4404 8.44036 17 7.75 17C7.05964 17 6.5 16.4404 6.5 15.75C6.5 15.0596 7.05964 14.5 7.75 14.5ZM12 14.5C12.6904 14.5 13.25 15.0596 13.25 15.75C13.25 16.4404 12.6904 17 12 17C11.3096 17 10.75 16.4404 10.75 15.75C10.75 15.0596 11.3096 14.5 12 14.5ZM7.75 10.5C8.44036 10.5 9 11.0596 9 11.75C9 12.4404 8.44036 13 7.75 13C7.05964 13 6.5 12.4404 6.5 11.75C6.5 11.0596 7.05964 10.5 7.75 10.5ZM12 10.5C12.6904 10.5 13.25 11.0596 13.25 11.75C13.25 12.4404 12.6904 13 12 13C11.3096 13 10.75 12.4404 10.75 11.75C10.75 11.0596 11.3096 10.5 12 10.5ZM16.25 10.5C16.9404 10.5 17.5 11.0596 17.5 11.75C17.5 12.4404 16.9404 13 16.25 13C15.5596 13 15 12.4404 15 11.75C15 11.0596 15.5596 10.5 16.25 10.5ZM17.75 4.5H6.25C5.2835 4.5 4.5 5.2835 4.5 6.25V7H19.5V6.25C19.5 5.2835 18.7165 4.5 17.75 4.5Z" fill="white" />
                    </g>
                  </svg> */}
                  {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21 8.5V17.75C21 19.5449 19.5449 21 17.75 21H6.25C4.45507 21 3 19.5449 3 17.75V8.5H21ZM7.25 15C6.55964 15 6 15.5596 6 16.25C6 16.9404 6.55964 17.5 7.25 17.5C7.94036 17.5 8.5 16.9404 8.5 16.25C8.5 15.5596 7.94036 15 7.25 15ZM12 15C11.3096 15 10.75 15.5596 10.75 16.25C10.75 16.9404 11.3096 17.5 12 17.5C12.6904 17.5 13.25 16.9404 13.25 16.25C13.25 15.5596 12.6904 15 12 15ZM7.25 10.5C6.55964 10.5 6 11.0596 6 11.75C6 12.4404 6.55964 13 7.25 13C7.94036 13 8.5 12.4404 8.5 11.75C8.5 11.0596 7.94036 10.5 7.25 10.5ZM12 10.5C11.3096 10.5 10.75 11.0596 10.75 11.75C10.75 12.4404 11.3096 13 12 13C12.6904 13 13.25 12.4404 13.25 11.75C13.25 11.0596 12.6904 10.5 12 10.5ZM16.75 10.5C16.0596 10.5 15.5 11.0596 15.5 11.75C15.5 12.4404 16.0596 13 16.75 13C17.4404 13 18 12.4404 18 11.75C18 11.0596 17.4404 10.5 16.75 10.5ZM17.75 3C19.5449 3 21 4.45507 21 6.25V7H3V6.25C3 4.45507 4.45507 3 6.25 3H17.75Z" fill="#38BBF4" />
                  </svg> */}
                </span>

                {/* FROM DATE PICKER */}
                {isFromDateOpen && !isToDateOpen &&
                  <div style={{ position: 'initial', right: '0', height: 'auto', width: 'auto' }}>
                    <DatePicker
                      value={fromDate}
                      maxDate={new Date()}
                      onChange={e => setFromDate(e)}
                      footerElement={
                        <div className="colendbut d-flex">
                          <button type='button'
                            onClick={() => {
                              setFromDate(undefined)
                              // fromDateRef.current.value = ""
                              setIsFromDateOpen(false)
                            }
                            }
                          >
                            Cancel
                          </button>
                          <button type='button' className="rightbut"
                            onClick={() => {
                              // fromDateRef.current.value = fromDate ? dateFormatter(fromDate, 1) : ""
                              setIsFromDateOpen(false)
                            }}
                          >
                            Confirm
                          </button>
                        </div>
                      }
                    />
                  </div>}

              </div>
              <div className="form-floating mb-3 label-dark">
                <input type='text'
                  className={`form-control enter-buss`}
                  //  ${errorState.unregistered ? "error" : ""}`}
                  style={{ caretColor: 'transparent', background: '#2b1d3f' }}
                  placeholder="Employed Till (optional)"
                  value={toDate ? moment(toDate).format("DD-MM-YYYY") : ""}
                  // ref={toDateRef}
                  onClick={() => {
                    setIsFromDateOpen(false)
                    setIsToDateOpen(prev => !prev)
                  }}
                  onChange={e => {
                  }}
                  readOnly
                />
                <label htmlFor="floatingInput">{STRING_CONSTANTS.advanced_profile.step7.end_date}</label>
                <span className="input-group-text">
                  <img src={UtilityFunctions.getImageURL() + "calendar.svg"} alt="" />
                  <img src={UtilityFunctions.getImageURL() + "calendar-fill-blue.svg"} alt="" />
                  {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g opacity="0.5">
                      <path d="M17.75 3C19.5449 3 21 4.45507 21 6.25V17.75C21 19.5449 19.5449 21 17.75 21H6.25C4.45507 21 3 19.5449 3 17.75V6.25C3 4.45507 4.45507 3 6.25 3H17.75ZM19.5 8.5H4.5V17.75C4.5 18.7165 5.2835 19.5 6.25 19.5H17.75C18.7165 19.5 19.5 18.7165 19.5 17.75V8.5ZM7.75 14.5C8.44036 14.5 9 15.0596 9 15.75C9 16.4404 8.44036 17 7.75 17C7.05964 17 6.5 16.4404 6.5 15.75C6.5 15.0596 7.05964 14.5 7.75 14.5ZM12 14.5C12.6904 14.5 13.25 15.0596 13.25 15.75C13.25 16.4404 12.6904 17 12 17C11.3096 17 10.75 16.4404 10.75 15.75C10.75 15.0596 11.3096 14.5 12 14.5ZM7.75 10.5C8.44036 10.5 9 11.0596 9 11.75C9 12.4404 8.44036 13 7.75 13C7.05964 13 6.5 12.4404 6.5 11.75C6.5 11.0596 7.05964 10.5 7.75 10.5ZM12 10.5C12.6904 10.5 13.25 11.0596 13.25 11.75C13.25 12.4404 12.6904 13 12 13C11.3096 13 10.75 12.4404 10.75 11.75C10.75 11.0596 11.3096 10.5 12 10.5ZM16.25 10.5C16.9404 10.5 17.5 11.0596 17.5 11.75C17.5 12.4404 16.9404 13 16.25 13C15.5596 13 15 12.4404 15 11.75C15 11.0596 15.5596 10.5 16.25 10.5ZM17.75 4.5H6.25C5.2835 4.5 4.5 5.2835 4.5 6.25V7H19.5V6.25C19.5 5.2835 18.7165 4.5 17.75 4.5Z" fill="white" />
                    </g>
                  </svg> */}
                  {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21 8.5V17.75C21 19.5449 19.5449 21 17.75 21H6.25C4.45507 21 3 19.5449 3 17.75V8.5H21ZM7.25 15C6.55964 15 6 15.5596 6 16.25C6 16.9404 6.55964 17.5 7.25 17.5C7.94036 17.5 8.5 16.9404 8.5 16.25C8.5 15.5596 7.94036 15 7.25 15ZM12 15C11.3096 15 10.75 15.5596 10.75 16.25C10.75 16.9404 11.3096 17.5 12 17.5C12.6904 17.5 13.25 16.9404 13.25 16.25C13.25 15.5596 12.6904 15 12 15ZM7.25 10.5C6.55964 10.5 6 11.0596 6 11.75C6 12.4404 6.55964 13 7.25 13C7.94036 13 8.5 12.4404 8.5 11.75C8.5 11.0596 7.94036 10.5 7.25 10.5ZM12 10.5C11.3096 10.5 10.75 11.0596 10.75 11.75C10.75 12.4404 11.3096 13 12 13C12.6904 13 13.25 12.4404 13.25 11.75C13.25 11.0596 12.6904 10.5 12 10.5ZM16.75 10.5C16.0596 10.5 15.5 11.0596 15.5 11.75C15.5 12.4404 16.0596 13 16.75 13C17.4404 13 18 12.4404 18 11.75C18 11.0596 17.4404 10.5 16.75 10.5ZM17.75 3C19.5449 3 21 4.45507 21 6.25V7H3V6.25C3 4.45507 4.45507 3 6.25 3H17.75Z" fill="#38BBF4" />
                  </svg> */}
                </span>
                {/* TO DATE PICKER */}
                {isToDateOpen && !isFromDateOpen && <div
                  style={{ position: 'initial', right: '0', height: 'auto', width: 'auto' }}>
                  <DatePicker
                    maxDate={new Date()}
                    minDate={fromDate || new Date("2003-01-01")}
                    value={toDate || null}
                    onChange={e => setToDate(e)}
                    footerElement={
                      <div className="colendbut d-flex">
                        <button type='button'
                          onClick={() => {
                            setToDate(undefined)
                            // toDateRef.current.value = ""
                            setIsToDateOpen(false)
                          }}
                        >
                          Cancel
                        </button>
                        <button type='button' className="rightbut"
                          onClick={() => {
                            // toDateRef.current.value = toDate ? dateFormatter(toDate, 1) : ""
                            setIsToDateOpen(false)
                          }}
                        >
                          Confirm
                        </button>
                      </div>
                    }
                  />
                </div>}

              </div>
              {selectedEmployerObj?.employer ?
                <div className="hide-profile-employee d-flex align-items-center justify-content-between">
                  <p className="mb-0">Hide your profile from employer?</p>
                  <div className="radio-buton d-flex align-items-center gap-2 ">
                    <span className={
                      // !props.newestJob ? "text-white active" : 
                      "text-white"
                    }>No</span>
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={hideProfile}
                        onChange={e => setHideProfile(e.target.checked)}
                      />
                      <span className="slider round">
                      </span>
                    </label>
                    <span className={
                      "text-white"
                    }>Yes</span>
                  </div>
                </div> : <div></div>
              }
            </div>
          </div>
        </div>
      </form>
      <div className="next-wrap">
        <button className={
          // (((currentEmployer?.id && IsRegisteredEmployer) || (!IsRegisteredEmployer || businessName))) && fromDate ?
          fromDate ? "cricle-next"
            : "cricle-next-inactive"}

          onClick={(e) => submitHandler()}
        >
          <img
            alt=""
            src={UtilityFunctions.getImageURL() + "arrow-bottom.svg"}
          />
        </button>
      </div>
    </>
  )
}

export default withRouter(PastJobDuration)