import React, { useState, Fragment, useEffect } from "react";
import { getNumberFromMobileNumber, UtilityFunctions } from "../../utils/UtilityFunctions";
import { STRING_CONSTANTS } from "../../utils/constants/stringConstants";
import OTPInput from "otp-input-react";
import { withRouter } from "react-router";
import { useSelector } from "react-redux";
import { State } from "../../store";
import { cookieService } from "../../_services";
import { get } from 'lodash';
import Timer from "../../components/timer";

const EnterOTP = (props: any) => {
  const state = useSelector((state: State) => state);
  const [number, setNumber] = useState(null);
  const [otp, setOtp] = useState("");
  useEffect(() => {
    let number = state.user.mobileNumber || get(cookieService.getCookie(STRING_CONSTANTS.cookieStrings.user), 'user.mobileNumber', null)
    if (number) {
      let numberWithoutCode = getNumberFromMobileNumber(number);
      setNumber(numberWithoutCode[1]);
    }
  }, [])

  const handleChange = (otp) => {
    setOtp(otp);
    props.setOtp((prev) => { return { ...prev, otp: otp } })
  };

  return (
    <Fragment>
      <div className="row">
        <div className="col-md-3 col-lg-4 col-xl-4 col-12">
          <div className="left-wrap">
            <div className="logo-wrap">
              <img
                alt=""
                src={UtilityFunctions.getImageURL() + "logo_White.svg"}
                onClick={() => props.history.push(STRING_CONSTANTS.navigationString.index)}
              />
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-4 col-xl-4 col-12">
          <div className="middle-wrap">
            <div className='top-divde-spa'>
              {props?.updateNumber ?
                <span>
                  <i
                    className="fa fa-angle-left text-white d-flex justify-content-between"
                    aria-hidden="true"
                    onClick={() => props.history.push(STRING_CONSTANTS.navigationString.dashboard.settings, { tab: "updateProfile" })}>
                  </i>
                </span> :
                <span>
                  <i
                    className="fa fa-angle-left text-white d-flex justify-content-between"
                    aria-hidden="true"
                    onClick={() => {
                      props.previousButton();
                      setOtp('');
                    }}
                  ></i>
                </span>}
              <div className="step-content signup-mar">
                <button type='button' disabled>
                  {STRING_CONSTANTS.signupPages.step4.verify_mobile}
                </button>
                <p>{STRING_CONSTANTS.signupPages.step4.mobile_otp_sent}{
                  number
                }</p>
                {!props?.updateNumber ? <div className="step-text">
                  {STRING_CONSTANTS.signupPages.step4.step}
                </div> : <div></div>}
              </div>
            </div>
            <div className="form-wrap">
              <div className="input-group mb-3 justify-content-center">
                <OTPInput
                  value={otp}
                  onChange={handleChange}
                  autoFocus
                  OTPLength={6}
                  otpType="number"
                  disabled={false}
                  isInputSecure={false}
                  placeholder={[0, 0, 0, 0, 0, 0]}
                />
              </div>
              <p className="d-flex gap-2 justify-content-center">
                {props.startTimer2 ? STRING_CONSTANTS.signupPages.step4.otp_prompt : STRING_CONSTANTS.signupPages.step4.otp_receipt}
                <span
                  className=" text-white ho-line"
                  onClick={() => props.startTimer2 ? null : props.resendOtp()}
                >
                  {props.startTimer2 ? <Timer setStartTimer={props.setStartTimer2} /> : STRING_CONSTANTS.signupPages.step4.resend_otp}
                </span>
              </p>
            </div>

            <div className="next-wrap">
              <button className={`${otp.length === 6 ? "cricle-next" : "cricle-next-inactive"}`} onClick={() => props.nextButton()} >
                <img alt="" src={UtilityFunctions.getImageURL() + "arrow-bottom.svg"} />
              </button>
            </div>
          </div>
        </div>
      </div>

    </Fragment>
  );
};
export default withRouter(EnterOTP);
