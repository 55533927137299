import { Action } from "../actions/index";
import { ActionType } from "../constants";
import { get } from "lodash";

const initialState = {
  // _parts: null,
  title: "",
  description: "",
  experience: 0,
  maxSalary: 0,
  minSalary: 0,
  salaryCheckoutType: "monthly",
  startFrom: null,
  type: "fullTime",
  cover: null,
  location: {
    address: "",
    latitude: "",
    longitude: "",
    radius: "",
  },
  category: {
    id: "",
    createdAt: "",
    modifiedAt: "",
    name: "",
    isActive: "",
  },
  subCategory: {
    id: "",
    createdAt: "",
    modifiedAt: "",
    name: "",
    isActive: "",
    category: "",
  },
};

const reducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.SET_JOB_CATEGORY: {
      state = { ...state, category: get(action, "item") };
      return state;
    }
    case ActionType.SET_JOB_SUBCATEGORY: {
      state = { ...state, subCategory: get(action, "item") };
      return state;
    }
    case ActionType.SET_JOB_COVER: {
      state = { ...state, cover: get(action, "item") };
      return state;
    }
    case ActionType.SET_JOB_TITLE: {
      state = { ...state, title: get(action, "item") };
      return state;
    }
    case ActionType.SET_JOB_DATE: {
      state = { ...state, startFrom: get(action, "item") };
      return state;
    }
    case ActionType.SET_JOB_DESC: {
      state = { ...state, description: get(action, "item") };
      return state;
    }
    case ActionType.SET_JOB_LOCATION: {
      state = { ...state, location: get(action, "item") };
      return state;
    }
    case ActionType.SET_JOB_EXPERIENCE: {
      state = { ...state, experience: get(action, "item") };
      return state;
    }
    case ActionType.SET_JOB_MINIMUM: {
      state = { ...state, minSalary: get(action, "item") };
      return state;
    }
    case ActionType.SET_JOB_MAXIMUM: {
      state = { ...state, maxSalary: get(action, "item") };
      return state;
    }
    case ActionType.SET_JOB_TYPE: {
      state = { ...state, type: get(action, "item") };
      return state;
    }
    case ActionType.SET_RATE_TYPE: {
      state = { ...state, salaryCheckoutType: get(action, "item") };
      return state;
    }
    case ActionType.SET_EDIT_JOB: {
      state = { ...get(action, "item") };
      return state;
    }
    case ActionType.SET_RESET_JOB: {
      state = initialState;
      return state;
    }
    default:
      return state;
  }
};

export default reducer;
