import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { UtilityFunctions } from '../../../utils/UtilityFunctions';
import moment from "moment";
import { withRouter } from 'react-router-dom';
import InfiniteScroll from "react-infinite-scroll-component";
import ChatItems from './chatItems';
import ChatReceive from './chatReceive';
import { STRING_CONSTANTS } from "../../../utils/constants/stringConstants";
import { cookieService } from "../../../_services";
import { chatOnEmptyScreen, onlyOneUserReply } from "../../../utils/constants/data"
import ValidationErrorToast from '../../../components/validationErrorToast';




const ChatBubble = (props) => {
    const userType = cookieService.getCookie(STRING_CONSTANTS.cookieStrings.user);
    const getDay = date => {
        const startTime = moment(Date.now());
        const end = moment(date);
        const duration = moment.duration(startTime.diff(end));
        const minutes = duration.asMinutes();
        if (minutes < 1) {
            return 'Just Now';
        } else if (minutes < 60) {
            return moment(date).fromNow();
        } else {
            return moment(date).format('ddd');
        }
    };

    const getTime = date => {
        const startTime = moment(Date.now());
        const end = moment(date);
        const duration = moment.duration(startTime.diff(end));
        const minutes = duration.asMinutes();
        if (minutes < 60) {
            return '';
        } else {
            return moment(date).format('LT');
        }
    };
    const showTime = (currentMsg: any, prevMsg: any) => {
        if (!currentMsg || !prevMsg) {
            return false;
        }
        const currentMsgTime = moment(currentMsg?.createdAt);
        const prevMsgTime = moment(prevMsg?.createdAt);
        const duration = moment.duration(prevMsgTime.diff(currentMsgTime));
        const minutes = duration.asMinutes();
        if (minutes >= 1) return true;

        return false;
    };
    const showDate = (currentMsg: any, prevMsg: any) => {
        if (!currentMsg || !prevMsg) {
            return true;
        }
        const currentMsgTime = moment(currentMsg?.createdAt).format('MMMM Do YYYY');
        const prevMsgTime = moment(prevMsg?.createdAt).format('MMMM Do YYYY');

        if (currentMsgTime === prevMsgTime) {
            return false;
        }
        return true;
    };


    console.log("chat content", props.chatContent);
    return (
        <div>
            <div
                id="scrollableDiv1"
                style={{
                    // height: 700,
                    // overflow: "auto",
                    // display: 'flex',
                    // flexDirection: 'column-reverse',
                }}
                className="scrollAsd"
            >
                <InfiniteScroll
                    dataLength={props.chatContent.length}
                    next={props.fetchMoreData}
                    hasMore=
                    {props.hasMore}
                    style={{ display: 'flex', flexDirection: 'column-reverse' }} //To put endMessage and loader to the top.
                    inverse={true}
                    loader={<h4>Loading...</h4>}
                    scrollableTarget="scrollableDiv1">
                    {props.chatActiveMessageUsers.length === 0 ?
                        <>
                            {chatOnEmptyScreen.map((item, index) => {

                                return (
                                    <div className="auto-msg cursor-pointer" key={item} onClick={(e) => {
                                        props.submitMessageDirect(item);

                                    }}>
                                        <p className="mb-0">{item}</p>
                                    </div>
                                )
                            })}


                        </> :
                        props.chatActiveMessageUsers.length === 1 && !props.chatActiveMessageUsers.includes(userType.user.id) ? <>
                            {onlyOneUserReply.map((item, index) => {

                                return (
                                    <div className="auto-msg cursor-pointer" key={item} onClick={(e) => {
                                        props.submitMessageDirect(item);

                                    }}>
                                        <p className="mb-0">{item}</p>
                                    </div>
                                )
                            })}
                        </> :
                            ""}

                    {props.chatContent?.map((chat, index) => {
                        if (userType?.user?.type === STRING_CONSTANTS.userType.employer) {
                            if (props?.selectedChat?.employer?.id === chat?.user) {
                                return (
                                    <ChatItems chat={chat} index={index} chatContent={props.chatContent} setLatestMessageTime={props.setLatestMessageTime} />
                                );
                            } else {
                                return (
                                    <ChatReceive profilePhoto={props?.selectedChat?.employee?.employee?.profilePhoto} chat={chat} index={index} chatContent={props.chatContent}
                                        userType={userType?.user?.type}
                                    />
                                )
                            }
                        }
                        else {
                            if (props?.selectedChat?.job) {
                                if (props?.selectedChat?.employee?.id === chat?.user) {
                                    return (
                                        <ChatItems chat={chat} index={index} chatContent={props.chatContent} setLatestMessageTime={props.setLatestMessageTime} />
                                    );
                                } else {
                                    return (
                                        <ChatReceive profilePhoto={props?.selectedChat?.employer?.employer?.logo} chat={chat} index={index} chatContent={props.chatContent}
                                        />
                                    )
                                }
                            } else {
                                if (props?.selectedChat?.employer?.id === chat?.user || props?.selectedChat?.employee?.id === chat?.user) {
                                    return (
                                        <ChatItems chat={chat} index={index} chatContent={props.chatContent} setLatestMessageTime={props.setLatestMessageTime} />
                                    );
                                } else {
                                    return (
                                        <ChatReceive profilePhoto={props?.selectedChat?.employer?.employer?.logo} chat={chat} index={index} chatContent={props.chatContent}
                                        />
                                    )
                                }
                            }

                        }
                    })}
                </InfiniteScroll>
            </div>
        </div>
    )
}


export default withRouter(ChatBubble);