import React, { useState } from "react";
import { Button, Modal, ToastContainer } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { STRING_CONSTANTS } from "../../utils/constants/stringConstants";
import { UtilityFunctions } from "../../utils/UtilityFunctions";

const Completion = (props: any) => {
    const [show, setShow] = useState(true)

    return <>
        <div className="step-content signup-mar">
            <button type='button' disabled>
                {STRING_CONSTANTS.employerProfileCompletionPages.profile_completed.profile_created}
            </button>
            <p>{STRING_CONSTANTS.employerProfileCompletionPages.profile_completed.completed}</p>
            <div className="step-text">
                {STRING_CONSTANTS.employerProfileCompletionPages.profile_completed.step}
            </div>

        </div>
        <div className="form-wrap">
            <div className="icon-wrap mb-0">
                <img
                    width={298}
                    alt=""
                    src={UtilityFunctions.getImageURL() + "verifiedNew.png"}
                />
            </div>
        </div>
        <div className="next-wrap" style={{ marginTop: "5em" }}>
            {/* <p className="congrats_your_account text-white">Your account is being reviewed. You will be notified when the MPLOI team approves your account.</p> */}
            <button className="m-0 btn-custom btn-cta" onClick={() => props.history.push(STRING_CONSTANTS.navigationString.dashboard.home)}>
                {STRING_CONSTANTS.employerProfileCompletionPages.profile_completed.view_home}
            </button>
        </div>
        <Modal
            className="bg-transparent ok_thanks"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={show}
            onHide={() => setShow(false)}
        >
            <Modal.Body>
                <img
                    alt="logout"
                    className="icon-img"
                    src={UtilityFunctions.getImageURL() + "question.svg"}
                />
                <h3>We respect your privacy so will only be displaying your suburb to other users</h3>
                <img
                    alt="logout"
                    src={UtilityFunctions.getImageURL() + "modal_lock_shadow.svg"}
                />
                <div>
                    <Button
                        className="ms-4 border-0"
                        variant="primary"
                        onClick={() => setShow(false)}
                    >
                        Ok, thanks
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    </>
}


export default withRouter(Completion);