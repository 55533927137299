import React, { useEffect, useRef, useState } from "react"
import ImageCrop from "../../../common/ui/ImageCrop";

import ValidationErrorToast from "../../../components/validationErrorToast";
import { UtilityFunctions } from "../../../utils/UtilityFunctions"
import { bindActionCreators } from "redux";
import { actionCreators, State } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from 'lodash';
import { setEditJob } from "../../../store/action-creators";
import { STRING_CONSTANTS } from "../../../utils/constants/stringConstants";

const JobImage = (props: any) => {
    const [selectedFile, setSelectedFile] = useState();
    const [preview, setPreview] = useState(null);
    const [title, setTitle] = useState("")
    const titleRef = useRef<any>();
    const [btnClick, setBtnClick] = useState(false);

    const dispatch = useDispatch();
    const { setJobTitle, setJobCover, setJobDate } = bindActionCreators(actionCreators, dispatch);
    const state = useSelector((state: State) => state);
    useEffect(() => {
        setPreview(state.newJob.cover)
        setTitle(state.newJob.title)
        titleRef.current.value = state.newJob.title
    }, [])
    function validate(input) {
        // allows one space at max
        return setTitle(input.target.value.replace(/^\s+|\s+$/g, " "))
    }

    const imageHandler = {
        removeFile: () => {
            setSelectedFile(null)
            setPreview(null)
        },
    }

    const submitHandler = (e) => {
        e.preventDefault();
        setBtnClick(true)
        let croppedSpaceTitle = titleRef?.current?.value.replace(/ /g, '')
        if (croppedSpaceTitle.length > 0) {
            setJobTitle(title.trim())
            setJobCover(preview);
            props.nav.next();
        }
        else { ValidationErrorToast("Job Title", "Please add a job title before proceeding") }
    }

    return (<>
        <div className='top-divde-spa'>
            <span>
                <i
                    className="fa fa-angle-left text-white d-flex justify-content-between"
                    aria-hidden="true"
                    onClick={() => {
                        if (props?.editJob?.editJob) {
                            props.history.push(STRING_CONSTANTS.navigationString.dashboard.home)
                        }
                        else { props?.nav?.back() }
                    }}
                ></i>
            </span>
            <div className="step-content paragraph-spacin">
                <button type='button' disabled>
                    Job Image
                </button>
                <p>Upload a cover image for your job post</p>
                <div className="step-text">
                    Step 3/8
                </div>
            </div>
        </div>
        <form onSubmit={submitHandler}>
            <div className="form-wrap">
                <div className="v-form-width">
                    <div className="update-image">
                        <div className='upload-image job-upload-image'>
                            <div className='file-thumb' >
                                {(selectedFile || preview) ?
                                    <div className="crossed-icon">
                                        <img alt="Selected Image" className="image-show" src={UtilityFunctions.getImageURL() + "cross-icon.svg"} onClick={(e) => imageHandler.removeFile()} />
                                        <img alt="Preview" className="image-show" src={preview} />
                                    </div> :
                                    <div>
                                        <ImageCrop
                                            ratio={1 / 1}
                                            croppedImage={(imgSrc, file) => {
                                                setSelectedFile(file)
                                                setPreview(imgSrc);
                                            }}
                                            size={25}
                                        />
                                        <label htmlFor="image_id" className="gap-3">
                                            {/* <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9.82869 0.206923L9.94531 0.200195C10.4581 0.200195 10.8808 0.586236 10.9386 1.08357L10.9453 1.2002V8.2002H17.9453C18.4581 8.2002 18.8808 8.58624 18.9386 9.08357L18.9453 9.2002C18.9453 9.71303 18.5593 10.1357 18.0619 10.1935L17.9453 10.2002H10.9453V17.2002C10.9453 17.713 10.5593 18.1357 10.0619 18.1935L9.94531 18.2002C9.43248 18.2002 9.00981 17.8142 8.95204 17.3168L8.94531 17.2002V10.2002H1.94531C1.43248 10.2002 1.00981 9.81415 0.95204 9.31682L0.945312 9.2002C0.945312 8.68736 1.33135 8.26469 1.82869 8.20692L1.94531 8.2002H8.94531V1.2002C8.94531 0.687359 9.33135 0.264688 9.82869 0.206923L9.94531 0.200195L9.82869 0.206923Z" fill="#38BBF4"></path>
                                            </svg> */}
                                            <img src={UtilityFunctions.getImageURL() + "add_img.svg"} className="plus-icon mx-auto" alt="" />
                                            <p>Upload <br /> Image</p>
                                        </label>
                                    </div>}
                            </div>
                        </div>
                    </div>
                    <div className="form-floating mb-3">
                        <input type='text' className={`form-control ${btnClick && isEmpty(titleRef.current.value) ? 'error' : ""}`} placeholder="Job Title"
                            ref={titleRef}
                            value={title}
                            onChange={e => validate(e)} />
                        <label htmlFor="floatingInput">Job Title</label>
                        <span className="input-group-text">
                            <img src={UtilityFunctions.getImageURL() + "building-ico-form.svg"} alt="" />
                            <img src={UtilityFunctions.getImageURL() + "building-input-fill.svg"} alt="" />
                            {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.5">
                                    <path d="M8.5 5.5C7.94772 5.5 7.5 5.94772 7.5 6.5C7.5 7.05228 7.94772 7.5 8.5 7.5C9.05229 7.5 9.5 7.05228 9.5 6.5C9.5 5.94772 9.05229 5.5 8.5 5.5ZM7.5 13.5C7.5 12.9477 7.94772 12.5 8.5 12.5C9.05229 12.5 9.5 12.9477 9.5 13.5C9.5 14.0523 9.05229 14.5 8.5 14.5C7.94772 14.5 7.5 14.0523 7.5 13.5ZM8.5 9C7.94772 9 7.5 9.44771 7.5 10C7.5 10.5523 7.94772 11 8.5 11C9.05229 11 9.5 10.5523 9.5 10C9.5 9.44771 9.05229 9 8.5 9ZM11 6.5C11 5.94772 11.4477 5.5 12 5.5C12.5523 5.5 13 5.94772 13 6.5C13 7.05228 12.5523 7.5 12 7.5C11.4477 7.5 11 7.05228 11 6.5ZM12 12.5C11.4477 12.5 11 12.9477 11 13.5C11 14.0523 11.4477 14.5 12 14.5C12.5523 14.5 13 14.0523 13 13.5C13 12.9477 12.5523 12.5 12 12.5ZM14.5 13.5C14.5 12.9477 14.9477 12.5 15.5 12.5C16.0523 12.5 16.5 12.9477 16.5 13.5C16.5 14.0523 16.0523 14.5 15.5 14.5C14.9477 14.5 14.5 14.0523 14.5 13.5ZM12 9C11.4477 9 11 9.44771 11 10C11 10.5523 11.4477 11 12 11C12.5523 11 13 10.5523 13 10C13 9.44771 12.5523 9 12 9ZM6.25 2C5.00736 2 4 3.00736 4 4.25V20.75C4 21.1642 4.33579 21.5 4.75 21.5H19.2528C19.667 21.5 20.0028 21.1642 20.0028 20.75V11.7493C20.0028 10.5067 18.9954 9.4993 17.7528 9.4993H16.5V4.25C16.5 3.00736 15.4926 2 14.25 2H6.25ZM5.5 4.25C5.5 3.83579 5.83579 3.5 6.25 3.5H14.25C14.6642 3.5 15 3.83579 15 4.25V10.2493C15 10.6635 15.3358 10.9993 15.75 10.9993H17.7528C18.167 10.9993 18.5028 11.3351 18.5028 11.7493V20H16.5V17.25C16.5 16.8358 16.1642 16.5 15.75 16.5H8.25C7.83579 16.5 7.5 16.8358 7.5 17.25V20H5.5V4.25ZM15 18V20H12.75V18H15ZM11.25 18V20H9V18H11.25Z" fill="white" />
                                </g>
                            </svg> */}
                            {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.75 2C5.50736 2 4.5 3.00736 4.5 4.25V20.75C4.5 21.1642 4.83579 21.5 5.25 21.5H7.5V17.25C7.5 16.8358 7.83579 16.5 8.25 16.5H15.75C16.1642 16.5 16.5 16.8358 16.5 17.25V21.5H18.75C19.1642 21.5 19.5 21.1642 19.5 20.75V11.7493C19.5 10.5067 18.4926 9.4993 17.25 9.4993H16.5V4.25C16.5 3.00736 15.4926 2 14.25 2H6.75ZM7.5 6.5C7.5 5.94772 7.94772 5.5 8.5 5.5C9.05228 5.5 9.5 5.94772 9.5 6.5C9.5 7.05228 9.05228 7.5 8.5 7.5C7.94772 7.5 7.5 7.05228 7.5 6.5ZM8.5 12.5C9.05228 12.5 9.5 12.9477 9.5 13.5C9.5 14.0523 9.05228 14.5 8.5 14.5C7.94772 14.5 7.5 14.0523 7.5 13.5C7.5 12.9477 7.94772 12.5 8.5 12.5ZM7.5 10C7.5 9.44772 7.94772 9 8.5 9C9.05228 9 9.5 9.44772 9.5 10C9.5 10.5523 9.05228 11 8.5 11C7.94772 11 7.5 10.5523 7.5 10ZM12 5.5C12.5523 5.5 13 5.94772 13 6.5C13 7.05228 12.5523 7.5 12 7.5C11.4477 7.5 11 7.05228 11 6.5C11 5.94772 11.4477 5.5 12 5.5ZM11 13.5C11 12.9477 11.4477 12.5 12 12.5C12.5523 12.5 13 12.9477 13 13.5C13 14.0523 12.5523 14.5 12 14.5C11.4477 14.5 11 14.0523 11 13.5ZM15.5 12.5C16.0523 12.5 16.5 12.9477 16.5 13.5C16.5 14.0523 16.0523 14.5 15.5 14.5C14.9477 14.5 14.5 14.0523 14.5 13.5C14.5 12.9477 14.9477 12.5 15.5 12.5ZM11 10C11 9.44772 11.4477 9 12 9C12.5523 9 13 9.44772 13 10C13 10.5523 12.5523 11 12 11C11.4477 11 11 10.5523 11 10ZM15 21.5V18H12.7499V21.5H15ZM11.2499 21.5V18H9V21.5H11.2499Z" fill="#38BBF4" />
                            </svg> */}
                        </span>
                    </div>
                </div>
            </div>
        </form>
        <div className="next-wrap">
            <button className={title ? "cricle-next" : "cricle-next-inactive"}
                onClick={(e) => submitHandler(e)}>
                <img
                    alt=""
                    src={UtilityFunctions.getImageURL() + "arrow-bottom.svg"}
                />
            </button>
        </div>
    </>)
}

export default (JobImage)