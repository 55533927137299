"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _common = _interopRequireDefault(require("./common"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var _drawVerticalLine = function _drawVerticalLine(ctx, coordinate, style) {
  var width = style.width,
      height = style.height,
      color = style.color,
      top = style.top;
  ctx.lineWidth = width;
  ctx.strokeStyle = color;
  ctx.moveTo(coordinate, top);
  ctx.lineTo(coordinate, top + height);
  ctx.stroke();
};

var _drawLine = function _drawLine(ctx, coordinate, style) {
  var width = style.width,
      height = style.height,
      color = style.color,
      left = style.left;
  ctx.lineWidth = height;
  ctx.strokeStyle = color;
  ctx.moveTo(left, coordinate);
  ctx.lineTo(left + width, coordinate);
  ctx.stroke();
};

var _drawNumberFromTop = function _drawNumberFromTop(_ref) {
  var ctx = _ref.ctx,
      text = _ref.text,
      coordinate = _ref.coordinate,
      top = _ref.numberStyle.top;
  return ctx.fillText(parseInt(text), coordinate, top);
};

var _drawNumberFromLeft = function _drawNumberFromLeft(_ref2) {
  var ctx = _ref2.ctx,
      text = _ref2.text,
      coordinate = _ref2.coordinate,
      left = _ref2.numberStyle.left;
  return ctx.fillText(text, left, coordinate);
};

var _applyNumberNumberStyle = function _applyNumberNumberStyle(ctx, numberStyle) {
  var size = numberStyle.size,
      family = numberStyle.family,
      color = numberStyle.color,
      textAlign = numberStyle.textAlign,
      textBaseline = numberStyle.textBaseline;
  ctx.fillStyle = color;
  ctx.textAlign = textAlign;
  ctx.textBaseline = textBaseline;
  ctx.font = "".concat(size, " ").concat(family);
};

var _round = function _round(number, step) {
  return step >= 0.1 ? number : number.toFixed(_common["default"].countDecimalPlace(step) - 1);
};

var _calcNum = function _calcNum(i, step) {
  return _round(i * step, step);
};

var drawCanvas = function drawCanvas(_ref3) {
  console.log(_ref3)
  var canvas = _ref3.canvas,
      step = _ref3.step,
      markStyle = _ref3.markStyle,
      smallerMarkStyle = _ref3.smallerMarkStyle,
      numberStyle = _ref3.numberStyle,
      unit = _ref3.unit,
      min = _ref3.min,
      max = _ref3.max,
      from = _ref3.from,
      to = _ref3.to,
      calcMarkCoordinate = _ref3.calcMarkCoordinate,
      isXAxis = _ref3.isXAxis,
      sliderType=_ref3.sliderType,
      setFloatValue=_ref3.setFloatValue
  var drawLine = isXAxis ? _drawVerticalLine : _drawLine;
  var drawNumber = isXAxis ? _drawNumberFromTop : _drawNumberFromLeft;
  var lower = Math.round(min / step); // use round() in case of decimal place

  var upper = Math.round(max / step);
  var ctx = canvas.getContext('2d');

  _applyNumberNumberStyle(ctx, numberStyle);

  ctx.clearRect(0, 0, canvas.width, canvas.height);

  for (var i = from; i <= to; ++i) {
    if (i < lower || i > upper) continue;
    var coordinate = calcMarkCoordinate(i);
    ctx.beginPath();
let modVal=sliderType=="experience"?12:10
    if (i % modVal === 0) {
      drawLine(ctx, coordinate, markStyle);
      var text = _calcNum(i, step) + unit;
      drawNumber({
        ctx: ctx,
        text: text,
        coordinate: coordinate,
        numberStyle: numberStyle
      });
    } else drawLine(ctx, coordinate, smallerMarkStyle);

    ctx.closePath();
  }
};

var _default = {
  drawCanvas: drawCanvas
};
exports["default"] = _default;