import React, { useState } from 'react';
import { UtilityFunctions } from "../utils/UtilityFunctions";
import { STRING_CONSTANTS } from "../utils/constants/stringConstants";
import { userService } from '../_services';
import { toast } from 'react-toastify';
import ToastHeader from "../components/toastHeader";
import ValidationErrorToast from '../components/validationErrorToast';
import SuccessToast from '../components/successToast';

const ForgotPassword = (props) => {
  const [email, setEmail] = useState(props && props.location && props.location.state && props.location.state.email || "");
  const [btnClick, setBtnClick] = useState(false);
  const store = !UtilityFunctions.validateEmail(email);

  const handleSubmit = (e) => {
    e.preventDefault();
    setBtnClick(true);
    if (UtilityFunctions.isEmptyOrNullOrUndefined(email)) {
      ValidationErrorToast(STRING_CONSTANTS.formLabelString.label_toast_email, STRING_CONSTANTS.validationString.req_email);
    }
    else if (store) {
      ValidationErrorToast(STRING_CONSTANTS.formLabelString.label_toast_email, STRING_CONSTANTS.validationString.req_email_incorrect);
    }
    else {
      if (!(email)) {
        return;
      }
      let data = {
        email: email
      }
      userService.forgotPassword(data)
        .then((res) => {
          SuccessToast(STRING_CONSTANTS.SUCCESS_MESSAGES.SUCCESS_HEADER, STRING_CONSTANTS.SUCCESS_MESSAGES.EMAIL_SENT_MESSAGE);
          setTimeout(() => {
            props.history.push(STRING_CONSTANTS.navigationString.login);
          }, 1000);
        })
        .catch(function (error) {
          ToastHeader(error);
        });
    }
  }

  return (
    <section className="main-signup-wrap">
      <form>
        <div className="row">
          <div className="col-md-3 col-lg-4 col-xl-4 col-12">
            <div className="left-wrap">
              <div className="logo-wrap">
                <img alt="" src={UtilityFunctions.getImageURL() + "logo_White.svg"} onClick={() => props.history.push('/')} />
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 col-xl-4 col-12">
            <div className="middle-wrap">
              <div className='top-divde-spa'>
                <span><i className="fa fa-angle-left text-white d-flex justify-content-between" aria-hidden="true" onClick={() => props.history.goBack()}></i></span>
                <div className="step-content">
                  <button type='button' disabled>
                    Forgot Password
                  </button>
                  <p>Enter your email below to <br /> reset your password</p>
                  <div className="step-text">
                  </div>

                </div>
              </div>
              <div className="form-wrap">
                <div className='v-form-width'>
                  <div className="form-floating mb-3">
                    <input type="email" className={`form-control ${!btnClick ? "" : store === false ? "" : email.length > 0 ? "error" : ""}`} id="floatingInput" placeholder="Enter Email" name='email' value={email} onChange={(e) => { setEmail(e.target.value); setBtnClick(false); }} />
                    <label htmlFor="floatingInput">Enter Email</label>
                    <span className="input-group-text">
                      <img src={UtilityFunctions.getImageURL() + "mail.svg"} alt="" />
                      <img src={UtilityFunctions.getImageURL() + "mail-fill-blue.svg"} alt="" />
                      {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <g opacity="0.5">
                          <path d="M5.25 4H18.75C20.483 4 21.8992 5.35645 21.9949 7.06558L22 7.25V16.75C22 18.483 20.6435 19.8992 18.9344 19.9949L18.75 20H5.25C3.51697 20 2.10075 18.6435 2.00514 16.9344L2 16.75V7.25C2 5.51697 3.35645 4.10075 5.06558 4.00514L5.25 4H18.75H5.25ZM20.5 9.373L12.3493 13.6637C12.1619 13.7623 11.9431 13.7764 11.7468 13.706L11.6507 13.6637L3.5 9.374V16.75C3.5 17.6682 4.20711 18.4212 5.10647 18.4942L5.25 18.5H18.75C19.6682 18.5 20.4212 17.7929 20.4942 16.8935L20.5 16.75V9.373ZM18.75 5.5H5.25C4.33183 5.5 3.57881 6.20711 3.5058 7.10647L3.5 7.25V7.679L12 12.1525L20.5 7.678V7.25C20.5 6.33183 19.7929 5.57881 18.8935 5.5058L18.75 5.5Z" fill="white" />
                        </g>
                      </svg> */}
                      {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M22 8.608V16.75C22 18.483 20.6435 19.8992 18.9344 19.9949L18.75 20H5.25C3.51697 20 2.10075 18.6435 2.00514 16.9344L2 16.75V8.608L11.652 13.6644C11.87 13.7785 12.13 13.7785 12.348 13.6644L22 8.608ZM5.25 4H18.75C20.4347 4 21.8201 5.28191 21.9838 6.92355L12 12.1533L2.01619 6.92355C2.17386 5.34271 3.46432 4.09545 5.06409 4.00523L5.25 4H18.75H5.25Z" fill="#38BBF4" />
                      </svg> */}
                    </span>
                  </div>
                </div>
              </div>
              <div className="next-wrap">
                <button className={`${store === false ? "cricle-next" : "cricle-next-inactive"}`} onClick={(e) => { handleSubmit(e) }}>
                  <img alt="" src={UtilityFunctions.getImageURL() + "arrow-bottom.svg"} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </section>
  )
}
export default ForgotPassword;