import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionCreators, State } from "../../../store";
import { bindActionCreators } from "redux";
import moment from "moment";

import { UtilityFunctions } from "../../../utils/UtilityFunctions";
import SlideRule from "../../../utils/slider";

const JobExperience = (props: any) => {
  const [floatValue, setFloatValue] = useState(0);
  const [value, setValue] = useState<any>(0);
  const [months, setMonths] = useState(0);
  const dispatch = useDispatch();
  const state = useSelector((state: State) => state);
  const { setJobExperience } = bindActionCreators(actionCreators, dispatch);

  useEffect(() => {
    setValue(+state.newJob.experience / 12 || 0);
    setFloatValue(+state.newJob.experience / 12 || 0);
  }, []);

  useEffect(() => {
    setMonths(Math.round(value * 12));
  }, [value]);

  const experienceToDisplay = (totalMonths) => {
    const years = Math.floor(totalMonths / 12);
    const months = totalMonths % 12;
    return [years, months];
  };
  //

  console.log(value, months);
  const submitHandler = (e: any) => {
    e.preventDefault();
    setJobExperience(JSON.stringify(months));
    props.nav.next();
  };
  return (
    <>
      <div className="top-divde-spa">
        <span>
          <i
            className="fa fa-angle-left text-white d-flex justify-content-between"
            aria-hidden="true"
            onClick={() => {
              props?.nav?.back();
            }}
          ></i>
        </span>
        <div className="step-content paragraph-spacin">
          <button type="button" disabled>
            Job Description
          </button>
          <p>How many years of experience is required?</p>
          <div className="step-text">Step 6/8</div>
        </div>
      </div>
      <form onSubmit={submitHandler}>
        <div className="form-wrap experience-desing">
          <div className="v-form-width">
            <div className="form-floating1 mb-3">
              <div className="jobsalary-girdset">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    overflow: "hidden",
                  }}
                >
                  <p className="defind-number">
                    {experienceToDisplay(months)[0] >= 1 && (
                      <span>{experienceToDisplay(months)[0]}</span>
                    )}
                    {experienceToDisplay(months)[0] === 1
                      ? "Year"
                      : experienceToDisplay(months)[0] > 1
                      ? "Years"
                      : ""}
                    {experienceToDisplay(months)[1] >= 1 ? (
                      <span> {experienceToDisplay(months)[1]}</span>
                    ) : (
                      experienceToDisplay(months)[1] === 0 &&
                      experienceToDisplay(months)[0] === 0 && <span>0</span>
                    )}
                    {experienceToDisplay(months)[1] > 1
                      ? "Months"
                      : experienceToDisplay(months)[1] === 1
                      ? "Month"
                      : experienceToDisplay(months)[1] === 0 &&
                        experienceToDisplay(months)[0] === 0 &&
                        "Month"}
                  </p>
                  <div className="center-arrow">
                    <img
                      src={
                        UtilityFunctions.getImageURL() + "down-arrow-fill.svg"
                      }
                      alt=""
                    />
                    {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.10204 8C5.0281 8 4.45412 9.2649 5.16132 10.0731L10.6831 16.3838C11.3804 17.1806 12.6199 17.1806 13.3172 16.3838L18.839 10.0731C19.5462 9.2649 18.9722 8 17.8983 8H6.10204Z" fill="#242424" />
                                    </svg> */}
                  </div>
                  <div className="line-svg">
                    <img
                      src={
                        UtilityFunctions.getImageURL() + "line-white-fill.svg"
                      }
                      alt=""
                    />
                    {/* <svg width="361" height="10" viewBox="0 0 391 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M390.5 1H224.276C219.193 1 214.21 2.41743 209.888 5.09307V5.09307C201.072 10.5505 189.928 10.5505 181.112 5.09307V5.09307C176.79 2.41743 171.807 1 166.724 1H0" stroke="url(#paint0_linear_6101_2166)" />
                                        <defs>
                                            <linearGradient id="paint0_linear_6101_2166" x1="0" y1="1" x2="390" y2="1" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="white" stopOpacity="0" />
                                                <stop offset="0.255208" stopColor="white" />
                                                <stop offset="0.746521" stopColor="white" />
                                                <stop offset="1" stopColor="white" stopOpacity="0" />
                                            </linearGradient>
                                        </defs>
                                    </svg> */}
                  </div>

                  <div
                    className="left-right-arrow justify-content-center"
                    style={{
                      display: "flex",
                      height: "99px",
                      width: "390.55px",
                    }}
                  >
                    <button
                      style={{
                        background: "none",
                        border: "none",
                        height: "14.3px",
                      }}
                      type="button"
                      disabled={value <= 0.01 ? true : false}
                      onClick={() => {
                        var diff = Math.round(-6 / 6);
                        var increment = (Math.sign(diff) * 1) / 12; // value increment
                        let res = floatValue + increment;
                        setFloatValue((prev) => prev + increment);
                        let g = Number(res?.toFixed(2));
                        setValue(g);
                      }}
                    >
                      <img
                        alt=""
                        src={
                          UtilityFunctions.getImageURL() + "slider-minus.svg"
                        }
                      />
                    </button>
                    <SlideRule
                    setFloatValue={setFloatValue}
                      sliderType={"experience"}
                      cursor={
                        <div
                          style={{
                            width: 1,
                            height: 22,
                            background: "rgba(6, 239, 197, 1)",
                          }}
                        />
                      }
                      numberStyle={{
                        color: "white",
                        size: "14px",
                        family: "Urbanist",
                      }}
                      smallerMarkStyle={{ height: 15, width: 0.3 }}
                      markStyle={{ height: 22, width: 0.3 }}
                      width={320}
                      gap={6}
                      value={value}
                      min={0}
                      max={60}
                      step={1 / 12}
                      onChange={(e) => {
                        console.log("shivam-------->e", e);
                        
                        setValue(e);
                      }}
                    />
                    {/* <SlideRule
                                        cursor={
                                            <div style={{
                                                width: 1,
                                                height: 22,
                                                background: 'rgba(6, 239, 197, 1)'
                                            }} />
                                        }
                                        numberStyle={{ color: 'white', size: '14px', family: 'Urbanist' }}
                                        smallerMarkStyle={{ height: 15, width: 0.3 }}
                                        markStyle={{ height: 22, width: 0.3 }}
                                        width={320}
                                        gap={6}
                                        value={value}
                                        min={0}
                                        max={20}
                                        step={0.1}
                                        onChange={(e) => setValue(e)}

                                    /> */}
                    <button
                      style={{
                        background: "none",
                        border: "none",
                        height: "14.3px",
                      }}
                      type="button"
                      disabled={value >= 60}
                      onClick={() => {
                        var diff = Math.round(-6 / 6);
                        var increment = (Math.sign(diff) * 1) / 12; // value increment
                        let res = floatValue - increment;
                        setFloatValue((prev) => prev - increment);
                        let g = Number(res?.toFixed(2));
                        setValue(g);
                      }}
                    >
                      <img
                        alt=""
                        src={UtilityFunctions.getImageURL() + "slider-plus.svg"}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </form>
      <div className="next-wrap">
        <button className={"cricle-next"} onClick={(e) => submitHandler(e)}>
          <img
            alt=""
            src={UtilityFunctions.getImageURL() + "arrow-bottom.svg"}
          />
        </button>
      </div>
    </>
  );
};

export default JobExperience;
