import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useSelector } from "react-redux";
import { State } from "../../../store";
import { UtilityFunctions } from "../../../utils/UtilityFunctions";

const JobCompletion = (props: any) => {
    //clean up redux data here
    const state = useSelector((state: State) => state);

    return <>
        <div className="step-content paragraph-spacin">
            <button type='button' disabled>
                Job Complete
            </button>
            {props?.editJob?.editJob ?
                <p>Job details have been updated successfully</p> :
                <p>Congrats, your job is now live for the next 28 days</p>
            }
            <div className="step-text">
                Step 8/8
            </div>

        </div>
        <div className="form-wrap">
            <div className="icon-wrap">
                <img
                    width={298}
                    alt=""
                    src={UtilityFunctions.getImageURL() + "verifiedNew.png"}
                />
            </div>
        </div>
        <div className="next-wrap">
            <Link to='/dashboard/home'>
                <button className=" btn-custom btn-cta" onClick={() => {
                    // console.log('Redirect to Dashboard');
                }}>{props?.editJob?.editJob ? "Back to Home" : "Start Hiring"}
                </button>
            </Link>
        </div>
    </>
}

export default (JobCompletion)