import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from "../../../middleware/api";


const fetchSubscriptionMonth = createAsyncThunk('subscriptionPlan/month', async ( payload, {dispatch, getState} ) => {
    try {
      const response = await api.get(`subscription/products/?subscription_type=month`, payload)
      return response;
    } catch (err) {
      return err;
    }
  });
const fetchSubscriptionYear = createAsyncThunk('subscriptionPlan/year', async (payload, {dispatch, getState} ) => {
    try {
      const response = await api.get(`subscription/products/?subscription_type=year`, payload)
      return response;
    } catch (err) {
      return err;
    }
  });
const fetchSubscriptionChose = createAsyncThunk('subscriptionPlan/Chose', async (payload, {dispatch, getState} ) => {
    try {
      const response = await api.get(`subscription`, payload)
      return response;
    } catch (err) {
      return err;
    }
  });

  export {fetchSubscriptionMonth, fetchSubscriptionYear, fetchSubscriptionChose}