import React, { useState, useMemo, useEffect } from "react";
import { withRouter } from "react-router";
import { useSelector } from "react-redux";
import { State } from "../../store";
import "./stripeCustom.css";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import { get, forEach, isEmpty, includes } from "lodash";
import ValidationErrorToast from "../../components/validationErrorToast";
import { END_POINTS } from "../../utils/constants/endPoints";
import { api } from "../../middleware/api";
import { UtilityFunctions } from "../../utils/UtilityFunctions";
import { STRING_CONSTANTS } from "../../utils/constants/stringConstants";
import ToastHeader from "../../components/toastHeader";
import Logout from "../../components/logout";


const useResponsiveFontSize = () => {
  const getFontSize = () => (window.innerWidth < 450 ? "16px" : "18px");
  const [fontSize, setFontSize] = useState(getFontSize);

  useEffect(() => {
    const onResize = () => {
      setFontSize(getFontSize());
    };

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  });

  return fontSize;
};

const useOptions = (placeholder) => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(() => ({
    placeholder: placeholder,
    style: {
      base: {
        fontSize: '14px',
        color: "rgba(255,255,255,0.5)",
        letterSpacing: "0.025em",
        weight: '400',
        fontFamily: 'Urbanist',
        "::placeholder": {
          color: "rgba(255,255,255,0.5)",
          fontSize: '14px',
          weight: '400',
        },
      },
      invalid: {
        color: "#9e2146"
      }
    }
  }),
    [fontSize]
  );

  return options;
};

const AddCardDetails = (props: any) => {
  const [show, setShow] = useState(false);
  const state = useSelector((state: State) => state);
  const stripe = useStripe();
  const elements = useElements();
  const options1 = useOptions('Enter Card Number');
  const options2 = useOptions('Enter Exp.');
  const options3 = useOptions('Enter CVV.');
  const [name, setName] = useState('');

  function validate(input) {
    // allows one space at max
    return setName(input.target.value.replace(/^\s+|\s+$/g, " "))
  }

  const handleSubmit = event => {
    if (!stripe || !elements || isEmpty(name) || name.replace(/ /g, '').length == 0) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      if (isEmpty(name) || name.replace(/ /g, '').length == 0) {
        ValidationErrorToast("Card Holder Name", "Card holder name is mandatory")
      }
      return;
    }
    else {
      api.post(END_POINTS.setupIntent, null).then(
        res => {
          document.body.classList.add('loading-indicator');
          stripe.confirmCardSetup(get(res, 'data.clientSecret'), {
            payment_method: {
              card: elements.getElement(CardNumberElement),
              billing_details: {
                name: name
              },
            },
          }).then((result) => {
            document.body.classList.remove('loading-indicator');
            if (result.error) {
              let header = '';
              if (includes(['incomplete_cvc', 'incomplete_expiry', 'incomplete_number', "invalid_expiry_year_past", "invalid_number"], get(result, 'error.code'))) {
                forEach({
                  'incomplete_cvc': 'Incomplete CVC',
                  'incomplete_expiry': 'Incomplete Expiry',
                  'incomplete_number': 'Incomplete Number',
                  'invalid_expiry_year_past': 'Invalid Expiry',
                  "invalid_number": "Invalid Number"
                }, (val, key) => {
                  // console.log(key, val)
                  if (key == get(result, 'error.code'))
                    ValidationErrorToast(val, result.error.message);
                });
              }
              else
                ValidationErrorToast(STRING_CONSTANTS.STRIPE_SUBSCRIPTION.CARD, STRING_CONSTANTS.SOMETHING_WENT_WRONG);
            } else {
              api.post(END_POINTS.stripeSubscription, { price_id: state.subscription.selectedPlan }).then(props.nextButton());
            }
          })
        }
      ).catch(err => {
        document.body.classList.remove('indicator');
        ToastHeader(err)
      });
    }
  };
  return (
    <> <div className="row">
      <div className="col-md-3 col-lg-4 col-xl-4 col-12">
        <div className="left-wrap">
          <div className="logo-wrap">
            <img
              alt=""
              src={UtilityFunctions.getImageURL() + "logo_White.svg"}
              style={{ cursor: "default" }}
            // onClick={() => props.history.push(STRING_CONSTANTS.navigationString.index)}
            />
          </div>
        </div>
      </div>
      <div className="col-md-6 col-lg-4 col-xl-4 col-12">
        <div className="middle-wrap align-items-center">
          <div className='top-divde-spa'>
            <div className="step-content signup-mar">
              <span>
                <i
                  className="fa fa-angle-left text-white d-flex justify-content-between"
                  aria-hidden="true"
                  onClick={() => props.setCurrentStep(5)}
                ></i>
              </span>
              <button type='button' disabled>
                {STRING_CONSTANTS.signupPages.step6.payment}
              </button>
              <p>{STRING_CONSTANTS.signupPages.step6.add_payment_method}</p>
              <div className="step-text">
                {STRING_CONSTANTS.signupPages.step6.step}
              </div>

            </div>
          </div>
          <div className="form-wrap add-card">
            <div className='v-form-width'>
              <form>
                <div className="form-floating mb-3">
                  <input type="text" className="form-control" id="floatingInput" placeholder="Enter Card Holder Name" name="text"
                    value={name}
                    onChange={(e) => validate(e)}
                  />
                  <label htmlFor="floatingInput">Enter Card Holder Name</label>
                  <span className="input-group-text">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g opacity="0.5">
                        <path d="M17.7543 13.9999C18.9963 13.9999 20.0032 15.0068 20.0032 16.2488V16.8242C20.0032 17.7185 19.6836 18.5833 19.1021 19.2627C17.5327 21.0962 15.1455 22.0011 12.0001 22.0011C8.85426 22.0011 6.46825 21.0959 4.90194 19.2617C4.32218 18.5828 4.00366 17.7193 4.00366 16.8265V16.2488C4.00366 15.0068 5.01052 13.9999 6.25254 13.9999H17.7543ZM17.7543 15.4999H6.25254C5.83895 15.4999 5.50366 15.8352 5.50366 16.2488V16.8265C5.50366 17.3622 5.69477 17.8802 6.04263 18.2876C7.29594 19.7553 9.26182 20.5011 12.0001 20.5011C14.7384 20.5011 16.706 19.7553 17.9625 18.2873C18.3114 17.8797 18.5032 17.3608 18.5032 16.8242V16.2488C18.5032 15.8352 18.1679 15.4999 17.7543 15.4999ZM12.0001 2.00464C14.7615 2.00464 17.0001 4.24321 17.0001 7.00464C17.0001 9.76606 14.7615 12.0046 12.0001 12.0046C9.2387 12.0046 7.00012 9.76606 7.00012 7.00464C7.00012 4.24321 9.2387 2.00464 12.0001 2.00464ZM12.0001 3.50464C10.0671 3.50464 8.50012 5.07164 8.50012 7.00464C8.50012 8.93764 10.0671 10.5046 12.0001 10.5046C13.9331 10.5046 15.5001 8.93764 15.5001 7.00464C15.5001 5.07164 13.9331 3.50464 12.0001 3.50464Z" fill="white" />
                      </g>
                    </svg>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M17.7543 13.9999C18.9963 13.9999 20.0032 15.0068 20.0032 16.2488V17.1673C20.0032 17.7406 19.8239 18.2997 19.4906 18.7662C17.9447 20.9294 15.4204 22.0011 12.0001 22.0011C8.57915 22.0011 6.05619 20.9289 4.51403 18.7646C4.18207 18.2987 4.00366 17.7409 4.00366 17.1688V16.2488C4.00366 15.0068 5.01052 13.9999 6.25254 13.9999H17.7543ZM12.0001 2.00464C14.7615 2.00464 17.0001 4.24321 17.0001 7.00464C17.0001 9.76606 14.7615 12.0046 12.0001 12.0046C9.2387 12.0046 7.00012 9.76606 7.00012 7.00464C7.00012 4.24321 9.2387 2.00464 12.0001 2.00464Z" fill="#38BBF4" />
                    </svg>
                  </span>
                </div>

                <label className="card-label mb-2" id="456">
                  <img src={UtilityFunctions.getImageURL() + "card-number.svg"} width="20" height="20" alt="" className="card-number-icon card-icon" />
                  <CardNumberElement
                    options={options1}
                    className="card-field"

                  />
                </label>
                <div className="columns-wrap">
                  <label className="card-label">
                    <CardExpiryElement
                      options={options2}
                    />
                  </label>
                  <label className="card-label">
                    <CardCvcElement
                      options={options3}
                    />
                  </label>
                </div>

              </form>
            </div>
          </div>
          <div className="next-wrap">
            <button className={`${isEmpty(name) || name.replace(/ /g, '').length == 0 ? "cricle-next-inactive" : "cricle-next"}`} type="submit" onClick={handleSubmit}>
              <img
                alt=""
                src={UtilityFunctions.getImageURL() + "arrow-bottom.svg"}
              />
            </button>
          </div>
        </div>
      </div>
    </div>
      <Logout
        show={show}
        handleClose={() => setShow(false)}
      />
    </>
  );
};
export default withRouter(AddCardDetails);
