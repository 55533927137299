import React, { useEffect, useState } from "react"
import { withRouter } from "react-router-dom"
import ToastHeader from "../../../components/toastHeader";
import { cookieService, userService } from "../../../_services";
import parse from "html-react-parser";
import { STRING_CONSTANTS } from "../../../utils/constants/stringConstants";
import GuardianEmail from "../../../components/guardianEmail"

const RejectionReasons = (props: any) => {
  const [content, setContent] = useState('');
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [mailAddress, setMailAddress] = useState();

  const userData = cookieService.getCookie(STRING_CONSTANTS.cookieStrings.user)
  useEffect(() => {
    setContent(userData?.guardian?.rejectionReason)
  }, [])

  return <div className="tab-pane fade show active " id="panel5" role="tabpane" aria-labelledby="panel5-tab">
    <div className="container-fluid">
      <div className='title-row'>
        <h1>Rejection Reason</h1>
      </div>
      <div className="blue-shadow-box v-blue-shadow-box">
        <div className="legal-content legal-scroll">
          {content}
        </div>
        <div className="fixed-dasbttn v-fixed-dasbttn">
          <div className="btnfgo-wrap"><button onClick={() => setShowEmailModal(true)}>Request to Upload More Documents</button></div>
        </div>
      </div>
    </div>
    <GuardianEmail
      show={showEmailModal}
      handleClose={() => {
        // setDOB(null);
        // dateRef.current.value = null;
        setShowEmailModal(false)
      }}
      mailAddress={mailAddress}
      setMailAddress={setMailAddress}
    />
  </div>
}

export default withRouter(RejectionReasons)