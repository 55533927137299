import React, { useEffect, useState } from 'react'
import moment from "moment";

const LastSeenTimer = ({ chatInfo, last_seen, online_user_list }) => {
   const [count, setCount] = useState(0)
   // console.log(count)
   useEffect(() => {
      setInterval(() => {
         setCount(count => count + 1)
      }, 5000)
   }, [])

   const isUserOnline = (id) => {
      return online_user_list?.includes(id)
   }
   return (
      isUserOnline(chatInfo) ? <p>Active Now</p> : last_seen ? <h5 className='last-seenst ps-2'>{`last seen ${moment(last_seen).fromNow()}`}</h5> : null
   )
}

export default LastSeenTimer