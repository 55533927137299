import React from "react";
import "./InterviewPreparation.css";
import CallerCard from "../CallerCard/CallerCard";
import CustomAccordian from "../../components/CustomAccordion";

const InterviewPreparation = () => {

  const interviewCase: Number = 2; // 0 for not ready 1 for need improvement 2 for ready
  const backgroundCheckError = false;
  const appearanceCheckError = false;
  const soundCheckError = false;

  const accrodianData = [
    {
      heading: (
        <>
          {backgroundCheckError ? (
            <img
              src="/assets/images/error-icon-red-fill.svg"
              alt="Check"
              className="accordian-image"
            />
          ) : (
            <img
              src="/assets/images/checkmark-green.png"
              alt="Check"
              className="accordian-image"
            />
          )}
          <div className="box-title">Background</div>
        </>
      ),
      errorClass: backgroundCheckError ? "errorBg" : "",
      description: (
        <>
          <div className="box-description">
            Your background is well lit and not cluttered.
          </div>
          <div className="box-description">
            Having a well lit, neat and tidy background helps avoid any
            distraction during your interview.
          </div>
        </>
      ),
    },
    {
      heading: (
        <>
          {appearanceCheckError ? (
            <img
              src="/assets/images/error-icon-red-fill.svg"
              alt="Check"
              className="accordian-image"
            />
          ) : (
            <img
              src="/assets/images/checkmark-green.png"
              alt="Check"
              className="accordian-image"
            />
          )}
          <div className="box-title">Appearance</div>
        </>
      ),
      errorClass: appearanceCheckError ? "errorBg" : "",
      description: (
        <>
          <div className="box-description">
            Your appearance is great you are well presented and have a neutral
            expression
          </div>
          <div className="box-description">
            Maintaining a composed demeanour can be good to show you are relaxed
            and confident during the interview.
          </div>
        </>
      ),
    },
    {
      heading: (
        <>
          {soundCheckError ? (
            <img
              src="/assets/images/error-icon-red-fill.svg"
              alt="Check"
              className="accordian-image"
            />
          ) : (
            <img
              src="/assets/images/checkmark-green.png"
              alt="Check"
              className="accordian-image"
            />
          )}
          <div className="box-title">Sound</div>
        </>
      ),
      errorClass: soundCheckError ? "errorBg" : "",
      description: (
        <>
          <div className="box-description">
            You seem to be in a quiet place with no background noise
          </div>
          <div className="box-description">
            Taking an interview in a quiet and calm environment will help avoid
            any distractions for yourself as well as the interviewer.
          </div>
        </>
      ),
    },
  ];

  return (
    <div className="interview-preparation">
      <div className="interview-header">
        <div className="header-wrap">
          <img
            src="/assets/images/mploi-logo-white.svg"
            alt="MPLOI Logo"
            className="logo"
          />
          <div className="title">
            Interview in preparation{" "}
            <img src="/assets/images/loading-icon.png" alt="Loading Icon" />
          </div>
        </div>

        <div className="interview-details">
          <div className="caller-details">
            <div>
              <span>Interviewing for: </span> Project Architect at NAP
              Architects Interview
            </div>
            <div className="dot"> • </div>
            <div>
              <span>Speaking with:</span> Maxine, Hiring Manager from H&R Block
            </div>
          </div>
        </div>
      </div>

      <div className="top-content">
        {interviewCase === 0 ? (
          <div className="status error">
            <img src="/assets/images/Dismiss Circle.svg" alt="" /> Not ready.
            Please see how you can prepare below
          </div>
        ) : interviewCase === 1 ? (
          <div className="status warning">
            <img src="/assets/images/Error Circle.svg" alt="" /> Needs
            improvement. Please see how you can improve below
          </div>
        ) : (
          <div className="status">
            <img src="/assets/images/checkmark-circle.png" alt="" /> Nice Work!
            You're ready to start your interview!
          </div>
        )}
        <button
          className={`start-interview-button ${
            interviewCase !== 2 ? "disableButton" : ""
          }`}
          disabled={interviewCase !== 2}
        >
          <div>
            Start Interview{" "}
            <img
              src="/assets/images/call-icon.png"
              alt="Icon"
              className={interviewCase !== 2 ? "filterInvert" : ""}
            />
          </div>
          {interviewCase === 0 ? (
            <div
              style={{ fontWeight: "400", fontSize: "14px", marginTop: "5px" }}
            >
              (Not recommended)
            </div>
          ) : (
            ""
          )}
        </button>
      </div>

      <div className="bottom-content">
        <CallerCard interviewCase={interviewCase} />
        <div className="right-box-wrap">
          <div className={`check-box ${backgroundCheckError ? "errorBg" : ""}`}>
            <div className="box-content-wrap">
              <div className="box-title">Background</div>
              <div className="box-sub-title">
                Your background is well lit and not cluttered.{" "}
              </div>
              <div className="box-description">
                Having a well lit, neat and tidy background helps avoid any
                distraction during your interview.{" "}
              </div>
            </div>

            <div className="icon-check">
              {backgroundCheckError ? (
                <img src="/assets/images/error-icon-red-fill.svg" alt="Check" />
              ) : (
                <img src="/assets/images/checkmark-green.png" alt="Check" />
              )}
            </div>
          </div>
          <div className={`check-box ${appearanceCheckError ? "errorBg" : ""}`}>
            <div className="box-content-wrap">
              <div className="box-title">Appearance</div>
              <div className="box-sub-title">
                Your appearance is great you are well presented and have a
                neutral expression
              </div>
              <div className="box-description">
                Maintaining a composed demeanour can be good to show you are
                relaxed and confident during the interview.
              </div>
            </div>

            <div className="icon-check">
              {appearanceCheckError ? (
                <img src="/assets/images/error-icon-red-fill.svg" alt="Check" />
              ) : (
                <img src="/assets/images/checkmark-green.png" alt="Check" />
              )}
            </div>
          </div>
          <div className={`check-box ${soundCheckError ? "errorBg" : ""}`}>
            <div className="box-content-wrap">
              <div className="box-title">Sound</div>
              <div className="box-sub-title">
                You seem to be in a quiet place with no background noise
              </div>
              <div className="box-description">
                Taking an interview in a quiet and calm environment will help
                avoid any distractions for yourself as well as the interviewer.
              </div>
            </div>

            <div className="icon-check">
              {soundCheckError ? (
                <img src="/assets/images/error-icon-red-fill.svg" alt="Check" />
              ) : (
                <img src="/assets/images/checkmark-green.png" alt="Check" />
              )}
            </div>
          </div>
        </div>
        <div className="mobile-accrodians">
          <CustomAccordian
            data={accrodianData}
            customClass="interviewAccrodian"
          />
        </div>
        <div className="bottom-btn-wrap">
          <button
            className={`start-interview-button ${
              interviewCase !== 2 ? "disableButton" : ""
            }`}
            disabled={interviewCase !== 2}
          >
            <div>
              Start Interview{" "}
              <img
                src="/assets/images/call-icon.png"
                alt="Icon"
                className={interviewCase !== 2 ? "filterInvert" : ""}
              />
            </div>
            {interviewCase === 0 ? (
              <div
                style={{
                  fontWeight: "400",
                  fontSize: "14px",
                  marginTop: "5px",
                }}
              >
                (Not recommended)
              </div>
            ) : (
              ""
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default InterviewPreparation;
