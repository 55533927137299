import { withRouter } from "react-router-dom"
import React from "react";
import { UtilityFunctions } from "../../../utils/UtilityFunctions";

const DefaultPage = () => {
    return <div className="tab-pane fade show active" id="panel6" role="tabpane" aria-labelledby="panel6-tab">
        <div className="container-fluid">
            <div className="default-setting">
                <img src={UtilityFunctions.getImageURL() + "default-setting.svg"} alt="" />
                {/* <svg width="113" height="107" viewBox="0 0 113 107" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M104.957 57.8024C122.474 76.8081 113.638 115.563 47.3654 103.997C18.9418 99.0361 0.464844 89.7257 0.464844 57.8024C0.464844 25.879 23.8563 0 52.711 0C81.5657 0 80.187 30.9272 104.957 57.8024Z" fill="#38BBF4" fillOpacity="0.1" />
                    <path opacity="0.6" d="M51.0247 98.851C28.926 98.851 10.9474 80.9047 10.9474 58.8458C10.9314 52.8677 12.2704 46.9634 14.8643 41.5751C18.1292 34.7665 23.2572 29.0187 29.6558 24.9958C36.0544 20.9728 43.4626 18.8388 51.0247 18.8403C73.1233 18.8403 91.1018 36.7867 91.1018 58.8458C91.1022 64.0994 90.0658 69.3017 88.0518 74.1555C86.0379 79.0093 83.0858 83.4196 79.3643 87.1345C75.6427 90.8494 71.2245 93.7962 66.3619 95.8065C61.4994 97.8168 56.2878 98.8513 51.0247 98.851ZM51.0247 19.2316C35.8401 19.2316 21.785 28.0683 15.2177 41.7444C12.6492 47.0799 11.3233 52.9263 11.3393 58.8458C11.3393 80.6891 29.142 98.4598 51.0247 98.4598C56.2363 98.4601 61.397 97.4358 66.212 95.4451C71.027 93.4544 75.402 90.5365 79.0872 86.8579C82.7724 83.1794 85.6955 78.8122 87.6898 74.0058C89.684 69.1995 90.7103 64.048 90.7099 58.8458C90.7099 37.0024 72.9072 19.2315 51.0247 19.2315V19.2316Z" fill="white" />
                    <path d="M61.4227 75.6037C58.5724 77.4552 55.2878 78.5329 51.8929 78.7303C48.498 78.9276 45.1102 78.238 42.0638 76.7294L42.4951 75.8511C46.1671 77.6518 50.3291 78.2017 54.3439 77.4166C58.3588 76.6316 62.0053 74.5549 64.7253 71.5044C67.4453 68.4539 69.089 64.5977 69.4047 60.526C69.7203 56.4544 68.6906 52.3917 66.4732 48.9598C64.2558 45.5278 60.9727 42.9158 57.1267 41.5235C53.2807 40.1312 49.0835 40.0353 45.1777 41.2506C41.2719 42.4658 37.8726 44.9253 35.5003 48.2524C33.1279 51.5795 31.9131 55.5909 32.0419 59.6727L31.0625 59.7027C30.954 56.2683 31.7492 52.8654 33.3688 49.8334C34.9884 46.8014 37.3759 44.246 40.2932 42.4221C43.2105 40.5981 46.5558 39.5693 49.9956 39.4382C53.4354 39.307 56.8496 40.0781 59.8978 41.6746C62.946 43.2711 65.5217 45.6372 67.3683 48.5371C69.2149 51.4369 70.2678 54.7693 70.4221 58.202C70.5764 61.6347 69.8267 65.0478 68.2476 68.101C66.6686 71.1543 64.3155 73.7412 61.4227 75.6037Z" fill="#38BBF4" />
                    <path d="M52.5602 57.917C49.6629 58.5571 45.807 58.9473 42.9974 58.5399L46.5244 61.221L36.6504 67.578L37.1814 68.3999L47.0555 62.0429L48.0481 66.3569C48.8351 63.6339 50.7816 60.2884 52.5602 57.917Z" fill="#06EFC5" />
                </svg> */}
                <h5>Select a setting subheading to<br></br>be displayed</h5>
            </div>
        </div>
    </div>
}

export default withRouter(DefaultPage);