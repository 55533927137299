// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section3 {
    background: #150924;
    padding: 76px 100px;
    color: white;
}

.section3 .subheading {
    margin-top: 10px;
    font-size: 39px;
    font-weight: 700;
    line-height: 75px;
    text-align: center;
    margin-bottom: 87px;
}

.ResponsiveWrapper {
    flex-wrap: wrap;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
}


@media screen and (max-width: 1024px) {
    .ResponsiveWrapper {
        gap: 50px;
    }
}
@media screen and (max-width: 480px) {
    .section3 {
        padding: 40px 25px;
    }
    .ResponsiveWrapper {
        gap: 50px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/LandingPage/Section3/Section3.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,aAAa;IACb,6BAA6B;IAC7B,uBAAuB;AAC3B;;;AAGA;IACI;QACI,SAAS;IACb;AACJ;AACA;IACI;QACI,kBAAkB;IACtB;IACA;QACI,SAAS;IACb;AACJ","sourcesContent":[".section3 {\n    background: #150924;\n    padding: 76px 100px;\n    color: white;\n}\n\n.section3 .subheading {\n    margin-top: 10px;\n    font-size: 39px;\n    font-weight: 700;\n    line-height: 75px;\n    text-align: center;\n    margin-bottom: 87px;\n}\n\n.ResponsiveWrapper {\n    flex-wrap: wrap;\n    display: flex;\n    justify-content: space-around;\n    align-items: flex-start;\n}\n\n\n@media screen and (max-width: 1024px) {\n    .ResponsiveWrapper {\n        gap: 50px;\n    }\n}\n@media screen and (max-width: 480px) {\n    .section3 {\n        padding: 40px 25px;\n    }\n    .ResponsiveWrapper {\n        gap: 50px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
