import React, { useEffect, useState } from "react"
import { withRouter } from "react-router-dom"
import ValidationErrorToast from "../../components/validationErrorToast";
import { api } from "../../middleware/api";
import { STRING_CONSTANTS } from "../../utils/constants/stringConstants";
import { UtilityFunctions } from "../../utils/UtilityFunctions";

const PastJobCategory = (props) => {
  const { setSelectedEmployerObj, selectedEmployerObj, userData, setStep, nav } = props
  const [categories, setCategories] = useState<any>([]);
  const [selectedCategory, setSelectedCategory] = useState<any>();
  const [searchResults, setSearchResults] = useState<any>();
  const [searchInput, setSearchInput] = useState<any>();
  const isProfileBeingUpdated = props?.location?.state?.isProfileBeingUpdated;
  const isEmployerBeingAdded = props?.location?.state?.isEmployerBeingAdded;
  const isEmployerBeingEdited = props?.location?.state?.isEmployerBeingEdited;

  useEffect(() => {
    api.get('category/', null)
      .then(res => {
        setCategories(res)
        setSearchResults(res)
      })
  }, [])

  useEffect(() => {
    if (selectedEmployerObj?.category?.length) {
      setSelectedCategory(selectedEmployerObj?.category?.[0])
    }
  }, [])

  useEffect(() => {
    if (searchInput) {
      // setSelectedCategory('')
      setSearchResults(categories?.filter(category => {
        return category?.name?.toLowerCase().indexOf(searchInput.toLowerCase()) > -1;
      }))
    }
    else if (!searchInput) {
      // setSelectedCategory('')
      setSearchResults([...categories])
    }
  }, [searchInput])

  const submitHandler = (e) => {
    e.preventDefault();
    if (!selectedCategory) {
      ValidationErrorToast("Job Category", "Please select a category before proceeding")
    }
    else {
      if (selectedEmployerObj?.category?.[0] !== selectedCategory) {
        setSelectedEmployerObj(prev => { return { ...prev, subCategory: [] } })
        // props?.setSelectedSubcategory([]);
      }
      setSelectedEmployerObj(prev => { return { ...prev, category: [selectedCategory] } })
      nav.next()
      // props?.setSelectedCategory(selectedCategory)
      // setPastJobCategory([selectedCategory?.id])
      // props.nav.next()
    };
  }
  const renderCategoryList =
    searchResults?.
      map(category => {
        return (
          <button key={category?.id}
            className={selectedCategory?.id === category?.id ? 'active' : ""}
            type="button"
            // onChange={() => setJobCategory({})}
            onClick={e => {
              if (selectedCategory?.id === category?.id) {
                setSelectedCategory('')
                // inputRef.current.value = ''
              }
              else {
                // props?.setSelectedSubcategory([]);
                setSelectedCategory(category);
              }
            }}>{category?.name}</button>)
      })

  return (
    <>
      <div className='top-divde-spa'>
        <span>
          <i
            className="fa fa-angle-left text-white d-flex justify-content-between"
            aria-hidden="true"
            onClick={() => {
            if(selectedEmployerObj?.action === "edit"){
              if(isProfileBeingUpdated==true){
               
                props.history.push(STRING_CONSTANTS.navigationString.dashboard.settings, { tab: "updateMyExperience" })
                
                
              }else{
                if(selectedEmployerObj?.action === "edit"&&selectedEmployerObj?.anotherEmployer==true){
                  setStep(5)
                }else{
                  nav.prev()
                }
                
              }

              setSelectedEmployerObj(null)
            }else{
              nav.prev()
              //setSelectedEmployerObj(null)
            }
             
              console.log("isEmployerBeingAdded",isEmployerBeingAdded)
              console.log("isProfileBeingUpdated",isProfileBeingUpdated)
              console.log("selectedEmployerObj",selectedEmployerObj?.action)
              // if (!userData?.isAdvanceProfileCompleted && selectedEmployerObj?.action === "edit") {
              //  // setStep(1)
                
              //  //nav.prev()
              // }
              // isProfileBeingUpdated && !isEmployerBeingAdded ?
              //   props.history.push(STRING_CONSTANTS.navigationString.dashboard.settings, { tab: "updateMyExperience" }) :
              //   nav.prev()

            }}
          ></i>
        </span>
        <div className="step-content paragraph-spacin">
          <button type='button' disabled>
            {STRING_CONSTANTS.advanced_profile.step2.title}
          </button>
          <p>
            {STRING_CONSTANTS.advanced_profile.step2.subtitle}
          </p>
          <div className="step-text">
            {isProfileBeingUpdated ? "Step 2/4" : STRING_CONSTANTS.advanced_profile.step2.step}
          </div>
        </div>
      </div>
      <form onSubmit={submitHandler}>
        <div className="form-wrap">
          <div className="v-form-width min-height-auto">
            <div className="form-floating mb-3 label-dark">
              <input type='text'
                className="form-control"
                placeholder={STRING_CONSTANTS.advanced_profile.step2.search_industry}
                onChange={e => setSearchInput(e.target.value)}
              ></input>
              <label htmlFor="floatingInput">{STRING_CONSTANTS.advanced_profile.step2.search_industry}</label>
              <span className="input-group-text">
                <img src={UtilityFunctions.getImageURL() + "search-with-shadow.svg"} alt="" />
                <img src={UtilityFunctions.getImageURL() + "search-with-shadow-fill.svg"} alt="" />
                {/* <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g filter="url(#filter0_f_4_8)">
                    <circle cx="12" cy="12" r="7" stroke="#D0D0D0" strokeWidth="2" />
                    <path d="M21 21L18 18" stroke="#D0D0D0" strokeWidth="2" strokeLinecap="round" />
                  </g>
                  <circle cx="12" cy="12" r="7" stroke="#D0D0D0" strokeWidth="2" />
                  <path d="M21 21L18 18" stroke="#D0D0D0" strokeWidth="2" strokeLinecap="round" />
                  <defs>
                    <filter id="filter0_f_4_8" x="-3" y="-3" width="32" height="32" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                      <feFlood floodOpacity="0" result="BackgroundImageFix" />
                      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                      <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_4_8" />
                    </filter>
                  </defs>
                </svg> */}
                {/* <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g filter="url(#filter0_f_4_8)">
                    <circle cx="12" cy="12" r="7" stroke="#D0D0D0" strokeWidth="2" />
                    <path d="M21 21L18 18" stroke="#D0D0D0" strokeWidth="2" strokeLinecap="round" />
                  </g>
                  <circle cx="12" cy="12" r="7" stroke="#D0D0D0" strokeWidth="2" />
                  <path d="M21 21L18 18" stroke="#D0D0D0" strokeWidth="2" strokeLinecap="round" />
                  <defs>
                    <filter id="filter0_f_4_8" x="-3" y="-3" width="32" height="32" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                      <feFlood floodOpacity="0" result="BackgroundImageFix" />
                      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                      <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_4_8" />
                    </filter>
                  </defs>
                </svg> */}
              </span>
            </div>
          </div>
          <div className="all-cat-width text-white">
            {(!renderCategoryList?.length && !searchInput?.length) ? <div></div> : renderCategoryList?.length > 0 ? renderCategoryList : "No matching category found. Please try again !"}
          </div>
        </div>
      </form>
      <div className="next-wrap">
        <button type="button" className={
          selectedCategory?.id ?
            "cricle-next"
            : "cricle-next-inactive"
        }
          onClick={(e) => submitHandler(e)}>
          <img
            alt=""
            src={UtilityFunctions.getImageURL() + "arrow-bottom.svg"}
          />
        </button>
      </div>
    </>
  )
}

export default withRouter(PastJobCategory)