import React from "react";
import "./CustomAccordian.css";

export default function CustomAccordion({ data = [], customClass = "" }) {
  interface Item {
    heading: string;
    description: string;
    errorClass: string;
  }

  return (
    <div className={`accordion ${customClass}`} id="accordionParent">
      {data.map((item: Item | undefined, index) => {
        const isFirstItem = index === 0;
        return (
          <div className="accordion-item" key={index}>
            <h2 className="accordion-header" id={`heading-${index}`}>
              <button
                className={`accordion-button ${
                  isFirstItem ? "" : "collapsed"
                } ${item?.errorClass}`}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#collapse-${index}`}
                aria-expanded={isFirstItem ? "true" : "false"}
                aria-controls={`collapse-${index}`}
              >
                {item?.heading}
              </button>
            </h2>
            <div
              id={`collapse-${index}`}
              className={`accordion-collapse collapse ${
                isFirstItem ? "show" : ""
              }`}
              aria-labelledby={`heading-${index}`}
              data-bs-parent="#accordionParent"
            >
              <div className={`accordion-body ${item?.errorClass}`}>
                {item?.description}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
