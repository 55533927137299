// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Urbanist&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StripeElement {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    box-sizing: border-box;
    border-radius: 10px;
    /*padding: 25px 14px 9px;*/
    padding: 14.605px 9px;
    font-family: 'Urbanist', sans-serif !important;
    font-weight: 400 !important;
    width: 100%;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background-color: rgba(255, 255, 255, 0.1);
    box-shadow: none;
    color: rgba(255, 255, 255, 0.5) !important;
}

.StripeElement+label {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    padding: 9px 14px 9px;
    font-weight: 500;
    font-size: 10px;
    line-height: 13px;
    color: rgba(255, 255, 255, 0.44);
}

.form-floating-custom input+label {
    padding: 9px 20px 9px;
}

.cardNumber .StripeElement+label {
    padding: 9px 20px 9px;
}

.StripeElement.StripeElement--focus.StripeElement--invalid,
.StripeElement.StripeElement--invalid {
    border: 1px solid #EB5757;
    outline: none;
}

.StripeElement.StripeElement--focus {
    background: rgba(114, 202, 255, 0.1) !important;
    border: 1px solid #38BBF4;
    outline: rgba(112, 200, 255, 0.416) solid 2px;
    color: #fff !important;
}
.card-field{
    padding:16px 12px 16px 58px !important;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Signup/stripeCustom.css"],"names":[],"mappings":"AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,sBAAsB;IACtB,mBAAmB;IACnB,0BAA0B;IAC1B,qBAAqB;IACrB,8CAA8C;IAC9C,2BAA2B;IAC3B,WAAW;IACX,0CAA0C;IAC1C,0CAA0C;IAC1C,gBAAgB;IAChB,0CAA0C;AAC9C;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,UAAU;IACV,QAAQ;IACR,qBAAqB;IACrB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,gCAAgC;AACpC;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;;IAEI,yBAAyB;IACzB,aAAa;AACjB;;AAEA;IACI,+CAA+C;IAC/C,yBAAyB;IACzB,6CAA6C;IAC7C,sBAAsB;AAC1B;AACA;IACI,sCAAsC;AAC1C","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Urbanist&display=swap');\n\n.StripeElement {\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 18px;\n    box-sizing: border-box;\n    border-radius: 10px;\n    /*padding: 25px 14px 9px;*/\n    padding: 14.605px 9px;\n    font-family: 'Urbanist', sans-serif !important;\n    font-weight: 400 !important;\n    width: 100%;\n    border: 1px solid rgba(255, 255, 255, 0.1);\n    background-color: rgba(255, 255, 255, 0.1);\n    box-shadow: none;\n    color: rgba(255, 255, 255, 0.5) !important;\n}\n\n.StripeElement+label {\n    position: absolute;\n    left: 0px;\n    right: 0px;\n    top: 0px;\n    padding: 9px 14px 9px;\n    font-weight: 500;\n    font-size: 10px;\n    line-height: 13px;\n    color: rgba(255, 255, 255, 0.44);\n}\n\n.form-floating-custom input+label {\n    padding: 9px 20px 9px;\n}\n\n.cardNumber .StripeElement+label {\n    padding: 9px 20px 9px;\n}\n\n.StripeElement.StripeElement--focus.StripeElement--invalid,\n.StripeElement.StripeElement--invalid {\n    border: 1px solid #EB5757;\n    outline: none;\n}\n\n.StripeElement.StripeElement--focus {\n    background: rgba(114, 202, 255, 0.1) !important;\n    border: 1px solid #38BBF4;\n    outline: rgba(112, 200, 255, 0.416) solid 2px;\n    color: #fff !important;\n}\n.card-field{\n    padding:16px 12px 16px 58px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
