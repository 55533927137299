import { withRouter } from "react-router-dom"
import React from "react"

const PDFViewer = () => {
    return (
    <div style={{height: '100%'}}>
        <iframe src="../assets/documents/mploi-privacy.pdf" width="100%" height="100%" />
    </div>
    );
};

const Privacy = (props: any) => {
    return (
        <div className="tab-pane fade show active " id="panel5" role="tabpane" aria-labelledby="panel5-tab">
            <div className="container-fluid">
                <div className='title-row'>
                    <h1>Privacy Policy</h1>
                </div>
                <div className="blue-shadow-box">
                    <div className="legal-content legal-scroll">
                        <PDFViewer />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(Privacy);