import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import JobSearch from "../../components/JobSearch/JobSearch";
import Footer from "../../components/Footer/Footer";
import Section1 from "../../components/LandingPage/Section1/Section1";
import Section3 from "../../components/LandingPage/Section3/Section3";
import "./Employers.css";
import { Helmet } from 'react-helmet';
import Slider from "react-slick";

export default function MPLOIHR() {
  const originalAccrodianData = [
    {
      heading: (
        <>
          <div className=" mr-2">MPLOI AI HR Assist</div>
          <img
            src="/assets/images/employeesLandingPage/exploreFirst.svg"
            alt=""
            className="iconImage"
          />
        </>
      ),
      description: (
        <div>
          Automated, conversational reminders tailored to each client.
        </div>
      ),
    },
    {
      heading: (
        <>
          <div className=" mr-2">Software integrations: Xero, MYOB</div>
          <img
            src="/assets/images/employersLandingPage/briefcase.svg"
            alt=""
            className="iconImage"
          />
        </>
      ),

      description: (
        <div>
          Just imagine, with an AI-assisted job posting tool like the one MPLOI
          offers, crafting the perfect job ad becomes a breeze! No more staring
          at a blank screen, wondering how to make your company sound appealing
          to top talent. The AI assistant can analyse successful job postings in
          your industry and suggest the most effective language and structure to
          grab candidates' attention. It's like having a team of expert
          recruiters at your fingertips, without the hefty price tag!
        </div>
      ),
    },
    {
      heading: (
        <>
          <div className=" mr-2">Customizable Settings</div>
          <img
            src="/assets/images/employeesLandingPage/exploreSecond.svg"
            alt=""
            className="iconImage"
          />
        </>
      ),

      description: (
        <div>
          Transform your recruitment process and slash costs with MPLOI's
          groundbreaking AI job matching feature. Say goodbye to expensive
          recruitment companies and hello to a streamlined list of top-tier
          candidates handpicked for your open positions. Our advanced algorithms
          dive deep into each candidate's skills, experience, and job
          preferences to play recruitment matchmaker, delivering your dream
          hires faster than you can say 'you're hired!' – all without the hefty
          price tag of traditional recruiting firms. With MPLOI, you're not just
          another job posting lost in the digital abyss; you're a savvy employer
          with a lineup of exceptional talent eager to join your ranks while
          keeping your recruitment budget in check. So sit back, relax, and let
          our AI handle the legwork while you prepare to welcome your new
          superstars on board and enjoy the savings.
        </div>
      ),
    },
    {
      heading: (
        <>
          <div className=" mr-2">Real-time Cash Flow Insights</div>
          <img
            src="/assets/images/employersLandingPage/assist.svg"
            alt=""
            className="iconImage"
          />
        </>
      ),
      description: (
        <div>
          Imagine having a knowledgeable, always-available digital companion to
          help navigate the often complex world of human resources, without
          breaking the bank. This savvy AI sidekick would be like having your
          own personal HR guru, ready to offer advice, keep meticulous records,
          and even whip up documents faster than you can say "employee
          handbook." It's like having a superhero in your corner, minus the cape
          and spandex. Plus, with its ability to continuously learn and adapt to
          your unique business needs, it's like having a custom-tailored
          solution that grows with you. In a world where time and resources are
          precious, having such a handy tool could be the secret weapon that
          helps SMEs thrive and compete with the big guys.
        </div>
      ),
    },
    {
      heading: (
        <>
          <div className=" mr-2">Automated Payment Plans</div>
          <img
            src="/assets/images/employeesLandingPage/exploreFourth.svg"
            alt=""
            className="iconImage"
          />
        </>
      ),

      description: (
        <div>
          Picture this... a world where business owners can effortlessly create
          custom interview questions or upload their own, and then sit back as
          our sophisticated AI Avatar conducts engaging interviews with
          potential candidates. No more endless hours spent scheduling and
          conducting interviews yourself! Our AI will handle the heavy lifting,
          recording each interview and delivering it straight to your inbox.
          Imagine the time and resources you could save, all while ensuring a
          consistent and unbiased interview process. It's like having your own
          personal HR assistant, minus the coffee breaks and water cooler
          gossip! Are you ready to revolutionise your hiring process with
          MPLOI's AI Interview Simulator?
        </div>
      ),
    },
    {
      heading: (
        <>
          <div className=" mr-2">Multi-channel Communication</div>
          <img
            src="/assets/images/employersLandingPage/globe.svg"
            alt=""
            className="iconImage"
          />
        </>
      ),

      description: (
        <div>
          Picture suddenly being able to visualise the location of potential
          hires on a map. No more guesswork, no more endless scrolling through
          resumes! With geo-mapping, they can see at a glance how far each
          candidate is from their business, making the decision process a
          breeze. It adds a whole new level of convenience and efficiency to the
          hiring process. I mean, who wouldn't want to save time and energy
          while finding the best fit for their company? Geo-mapping is the way
          of the future, and at MPLOI we're at the forefront of making finding
          your next team easier and affordable.
        </div>
      ),
    },
    {
      heading: (
        <>
          <div className=" mr-2">Payment Link Generation</div>
          <img
            src="/assets/images/employersLandingPage/hammer.svg"
            alt=""
            className="iconImage"
          />
        </>
      ),

      description: (
        <div>
          <div style={{ fontWeight: 700, marginBottom: "30px" }}>
            Introducing MPLOI AI Legal Assist: Your Virtual Legal Sidekick for
            SMEs!
          </div>
          Are you tired of navigating the complex world of legal matters alone?
          Say hello to MPLOI AI Legal Assist, the game-changing feature designed
          to help small and medium businesses tackle legal challenges with
          confidence. Our AI-powered legal consultant is here to provide you
          with the guidance and support you need, 24/7. <br /> With MPLOI AI
          Legal Assist, you can: <br />
          <ul style={{ paddingLeft: "30px" }}>
            <li>Get instant answers to your legal questions</li>
            <li>Receive personalised advice tailored to your business</li>
            <li>Save time and money on minor legal issues</li>
            <li>Access a wealth of legal resources at your fingertips</li>
            <li>
              Enjoy peace of mind knowing you have a virtual legal expert by
              your side
            </li>
          </ul>
          Don't let legal hurdles slow down your business growth. Let MPLOI AI
          Legal Assist be your trusted partner in navigating the legal
          landscape. Try it now and experience the difference!
        </div>
      ),
    },
  ];

  const [expanded, setExpanded] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [accrodianData, setAccrodianData] = useState(() => {
    return window.innerWidth < 768
      ? originalAccrodianData.slice(0, 3)
      : originalAccrodianData;
  });

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleResetData = (slice = false) => {
    if (slice) {
      setAccrodianData(originalAccrodianData.slice(0, 3));
      setExpanded(false);
    } else {
      setAccrodianData(originalAccrodianData);
      setExpanded(true);
    }
  };

  const sliderData = [
    {
      value: "87%",
      description:
        "Millennials say that professional development/ career growth are important to them.",
      color: "#9256E8",
      bgColor: "#9256E817",
    },
    {
      value: "74%",
      description:
        "Workers say that a lack of employee development opportunities prevents them from reaching their full potential.",
      color: "#F495D3",
      bgColor: "#F495D317",
    },
    {
      value: "58%",
      description:
        "Employees say professional development contributes to their job satisfaction.",
      color: "#38BBF4",
      bgColor: "#38BBF417",
    },
    {
      value: "68%",
      description:
        "Employees say training and development are the most essential company policy",
      color: "#FFE35A",
      bgColor: "#2A1D29",
    },
    {
      value: "74%",
      description:
        "Workers are willing to learn new skills or retrain to stay employable say that a lack of employee development opportunities prevents them from reaching their full potential.",
      color: "#06EFC5",
      bgColor: "#06EFC517",
    },
  ];

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 5.1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 2400,
        settings: {
          slidesToShow: 4.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 2200,
        settings: {
          slidesToShow: 3.8,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1800,
        settings: {
          slidesToShow: 3.1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2.1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 825,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };


  //   Revolutionize Your Cash Flow:
  // AI-Powered Accounts Receivable
  // Made Simple with MPLOI


  return (
    <div className="employersPageWrap">
      <Helmet>
        <title>MPLOI AI HR Assist: The Ultimate AI HR Companion</title>
      </Helmet>
      <Header />
      <Section1
        heading={
          <>
            <div className=" fw-bold heading">
              The ultimate <br />
              <span className=" px-2" style={{ background: "#9256E8" }}>
                AI HR Companion

              </span>{" "}<br />
              for businesses<br /> of all sizes.
            </div>
          </>
        }
        subHeading="Whether you're a small business without a dedicated HR department or a larger company looking to streamline your HR processes, MPLOI’s AI HR Assist is your perfect solution. MPLOI’s AI-powered Agent provides instant, expert advice and support, ensuring that you have access to the HR knowledge you need, whenever you need it."
        buttons={
          <>
            <a href="/signup" target="_blank" className="textBlack">
              <button className=" border-0 px-4 py-2 primaryButton">
                Signup
                <img
                  src="assets/images/employersLandingPage/teamMembers.svg"
                  alt=""
                />
              </button>
            </a>
            <a href="#employers-feature">
              <button className=" border-0 px-4 py-2 secondaryButton">
                MPLOI AI HR Assist Features
                <img
                  src="assets/images/employeesLandingPage/exploreIcon.svg"
                  alt=""
                />
              </button>
            </a>
          </>
        }
        image={
          <>
            <img
              src="/assets/images/employersSection1.png"
              className="img-fluid desktopImage"
              alt=""
            />
          </>
        }
        mobileImage={
          <>
            <img
              src="/assets/images/employersSection1.png"
              className="img-fluid mobileImage"
              alt=""
            />
          </>
        }
      />
      <JobSearch
        description="Larger organisations with existing HR teams can also benefit from AI HR Assist, using it as a valuable sounding board and resource. Your HR professionals can rely on the tool for second opinions, data-driven insights, and help with time-consuming tasks, allowing them to focus on more strategic initiatives.

Serves as a valuable sounding board and resource for larger organisations
Offers second opinions, data-driven insights, and helps with time-consuming tasks
Reclaim valuable hours for your existing HR team
Continuously learns and improves, ensuring access to the latest HR best practices
Allows HR professionals to focus on more strategic initiatives
Elevates your HR game, unlocking the potential of your human resources
Adapts to your company's size and unique needs, providing customised support
User-friendly interface, making it easy for any team member to utilise effectively
"
        title={
          <div className="title">
            Perfect for Small to Medium Businesses
          </div>
        }
        subDescription={
          <a href="https://mploiacademy.com/">
          <button className=" border-0 px-5 py-2 primaryButton textBlack">
            Visit the Academy
            <img
              src="assets/images/employeesLandingPage/testYourself.svg"
              alt=""
            />
          </button>
          </a>
        }
        image={
          <img
            src="/assets/images/employersLandingPage/section4.png"
            alt="For Jobs"
            className="img-fluid"
          />
        }
      />

      <Footer />
    </div>
  );
}
