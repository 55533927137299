import React, { useState, useEffect } from "react";
import ReactPlayer from 'react-player/lazy';
import { UtilityFunctions, modifiedSalaray } from "../../../utils/UtilityFunctions";
import moment from "moment";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import SuccessToast from "../../../components/successToast";
import ValidationErrorToast from "../../../components/validationErrorToast";
import { api } from "../../../middleware/api";
import { actionCreators, State } from "../../../store";
import { STRING_CONSTANTS } from "../../../utils/constants/stringConstants";
import { cookieService, userService } from "../../../_services";
import { Data } from "../../../_services/collections";
import Experience from "../../EmployeeProfileCompletion/Experience";

const MapEmployeeSidebar = (props) => {
  const { selectedUser } = props;
  const reduxState = useSelector((state: State) => state);
  const isUserLoggedIn = cookieService.getCookie(STRING_CONSTANTS.cookieStrings.token)
  const userTypeCookieval = cookieService.getCookie(STRING_CONSTANTS.cookieStrings.user)
  const [notVerifiedModal, setNotVerifiedModal] = useState(false)
  const [selectedEmployee, setSelectedEmployee] = useState<any>();
  const [userDeleted, setUserDeleted] = useState(false);
  const [playVideo, setPlayVideo] = useState(false)
  const dispatch = useDispatch();
  const { setEmployeesList, setRemoveEmployee, setSidebar } = bindActionCreators(actionCreators, dispatch);

  const selectedEmployeeDetails = (jobId = "", isTrue = false) => {
    if (!isUserLoggedIn) {
      api.get(`${props?.selectedUser?.id}/job/`, null)
        .then(res => {
          setSelectedEmployee(res)
          props?.setSelectedUser(res)
          setUserDeleted(false)
        })
        .catch(e => {
          document.body.classList.add("loading-indicator");
          // setUserDeleted(true)
          // props?.setUserExists(false)
        })
    }
    else if (userTypeCookieval?.user.type === STRING_CONSTANTS.userType.employer) {
      let SELECTEDEMPLOYEEID = props?.selectedUser?.id ? props?.selectedUser?.id : reduxState?.common?.uniqueemployeeId;
      let SELECTEDJOBID = props?.selectedJobID ? props?.selectedJobID : reduxState?.common?.uniqueId;
      userService.showEmployeeSidebar(SELECTEDEMPLOYEEID, SELECTEDJOBID)
        .then(res => {
          setSelectedEmployee(res)
          props?.setSelectedUser(res)
          setUserDeleted(false)
        })
        .catch(e => {
          // props?.closeModalForcefully();
          const elements = document.getElementsByClassName('modal-backdrop fade show');
          while (elements.length > 0) {
            elements[0].parentNode.removeChild(elements[0]);
          }

          ValidationErrorToast(e?.[0], e?.[1])
          setUserDeleted(true)
          // props?.setUserExists(false)
        })
    }
    else {
      let id = ""
      if (isTrue && jobId) {
        id = jobId
      }
      else if (reduxState?.common?.uniqueId && !isTrue) {
        id = props?.selectedJobID ? props?.selectedJobID : reduxState?.common?.uniqueId;
      }
      else {
        id = props?.selectedUser?.id
      }
      if (reduxState?.common?.show_sidebar) { // only side is open then call job detail open api other it will not called.
        api.get(`${id}/job/`, null)
          .then((res: any) => {
            setSelectedEmployee(res)
            setUserDeleted(false)
            props?.setSelectedUser(res)
          })
          .catch(e => {
            // props?.closeModalForcefully();
            const elements = document.getElementsByClassName('modal-backdrop fade show');
            while (elements.length > 0) {
              elements[0].parentNode.removeChild(elements[0]);
            }
            // console.log(e, "error idhr niche");
            ValidationErrorToast(e?.[0], e?.[1])
            setUserDeleted(true)
            // props?.setUserExists(false)
          })
      }

    }
  }
  useEffect(() => {
    if (reduxState?.common?.uniqueId || reduxState?.common?.uniqueId && reduxState?.common?.uniqueemployeeId) {
      selectedEmployeeDetails();
    }
  }, [reduxState?.common?.uniqueId, (reduxState?.common?.uniqueId && reduxState?.common?.uniqueemployeeId)])

  // useEffect(() => {
  //   if ((props?.selectedUser?.id) || !isUserLoggedIn) {
  //     selectedEmployeeDetails();
  //     props?.setFlagReasons([]);
  //     props?.setFlag(true);
  //   }
  // }, [props?.selectedUser])

  const handleSendRequest = (employee) => {
    const erID = cookieService.getCookie(STRING_CONSTANTS.cookieStrings.user) as Data
    const eeList = [...reduxState.employeesList]
    eeList.splice(reduxState.employeesList.findIndex((ee) => ee.id == employee.id), 1, { ...employee, chatRequested: true, requestedByUser: true })
    // if (!employee?.chatRequested) {
    //     api.post('chat/rooms/', {
    //         "employerId": `${erID.user.id}`,
    //         "employeeId": `${employee?.user?.id}`,
    //         "jobId": `${props?.selectedJobID}`
    //     }).then(res => {
    //         setEmployeesList(eeList)
    //         selectedEmployeeDetails()
    //         SuccessToast(STRING_CONSTANTS.SUCCESS_MESSAGES.CHAT, STRING_CONSTANTS.SUCCESS_MESSAGES.CHAT_REQUEST_SENT)
    //     }).catch(e => {
    //         ValidationErrorToast(e[0], e[1])
    //     })
    // }
    console.log(employee)
    // if (!employee?.chatRequested) {
    let params = {};
    if (userTypeCookieval?.user.type === STRING_CONSTANTS.userType.employer) {
      // employer
      params = {
        employerId: erID.user.id,
        employeeId: employee?.user?.id,
        jobId: props?.selectedJobID
      }
    } else {
      // employee
      params = {
        "employerId": `${employee?.employer?.user?.id}`,
        "employeeId": `${erID.user.id}`,
        "jobId": `${employee?.id}`
      }
    }
    console.log(params)
    api.post('chat/rooms/', params).then(res => {
      selectedEmployeeDetails();
      setEmployeesList(eeList)

      props?.history.push(`/dashboard/chat?chatid=${res?.data?.room?.id}`);
      // SuccessToast('Chat Request Sent', "")
    }).catch(e => {
      ValidationErrorToast('Error occured!', 'Please try again after some time')
    })
    // }
  }
  // const handleReceivedRequest = {
  //   handleChatAccept: (employee) => {
  //     api.patch(`${employee?.chatRoomId}/chat/`,
  //       {
  //         "isActive": true
  //       })
  //       .then(res => {
  //         props?.history.push(`/dashboard/chat?chatid=${res?.data?.["id"]}`)
  //         // props?.history?.push("chat/", { chatInfo: { id: res?.data?.["id"] } })
  //         setSidebar({ sidebar_name: "employee-details", show_sidebar: false })
  //         SuccessToast('Chat Request Accepted', "Request has been successfully accepted")
  //         setRemoveEmployee(employee)
  //       })
  //       .then(() => {
  //         props?.getChatRequests()
  //       })
  //       .catch(e => {
  //         // ValidationErrorToast('Error occured!', 'Please try again after some time')
  //       })
  //   },
  //   handleChatReject: (employee) => {
  //     api.patch(`${employee?.chatRoomId}/chat/`,
  //       {
  //         "isActive": false
  //       })
  //       .then(res => {
  //         setSidebar({ sidebar_name: "employee-details", show_sidebar: false })
  //         // console.log(res)
  //         SuccessToast('Chat Request Rejected', "Request has been rejected")
  //         setRemoveEmployee(employee)
  //       })
  //       .then(() => {
  //         props?.getChatRequests()
  //       })
  //       .catch(e => {
  //         // ValidationErrorToast('Error occured!', 'Please try again after some time')
  //         // console.log(e)
  //       })
  //   }
  // }

  const handleChatButton = (userObj) => {
    if (userTypeCookieval?.user.type === STRING_CONSTANTS.userType.employer) {
      //IF JOB STATUS IS ACTIVE
      if (userObj?.jobStatus == 'active') {
        //IF CHAT IS ACTIVE
        if (userObj?.chatRoomId)
          return <button className="btn"
            data-bs-dismiss="modal"
            aria-label="Close"
            type="button"
            onClick={() => {
              props?.history.push(`/dashboard/chat?chatid=${userObj.chatRoomId}`)
              setSidebar({ sidebar_name: "employee-details", show_sidebar: false })
            }}
            style={{ backgroundColor: "#FFB800", color: "black" }}
          >
            {/* Chat */}
            Ongoing Chat, Click Here
          </button>
        //IF CHAT IS REJECTED
        // else if (userObj?.chatRejected)
        //   return <div></div>
        //IF CHAT IS REQUESTED BUT NOT REQUESTED BY USER 
        // else if (userObj?.chatRequested && !userObj?.requestedByUser) {
        //   return <>
        //     <button className='reject-btn'
        //       type="button"
        //       data-bs-dismiss="modal"
        //       aria-label="Close"
        //       onClick={() => {
        //         handleReceivedRequest.handleChatReject(selectedUser)
        //       }}>
        //       Reject
        //     </button>
        //     <button className="btn"
        //       data-bs-dismiss="modal"
        //       aria-label="Close"
        //       type="button"
        //       onClick={() => {

        //         handleReceivedRequest.handleChatAccept(selectedUser)
        //         props?.history.push(`/dashboard/chat?chatid=${userObj.chatRoomId}`)
        //       }}
        //     >
        //       Chat
        //     </button>
        //   </>
        // }
        //IF CHAT IS REQUESTED BUT REQUESTED BY USER 
        // else if (userObj?.chatRequested && userObj?.requestedByUser)
        //   return <div className='btn-effect full-width-btn v-disabled-btn'>
        //     <button className='chat-btn' disabled
        //       type="button"
        //     >
        //       Chat Request Sent
        //     </button>
        //   </div>
        //IF CHAT IS NOT REQUESTED
        else return <div className="btn-effect full-width-btn">
          <button className='btn'
            type="button"
            onClick={() => {
              if (!isUserLoggedIn) { }
              handleSendRequest(selectedUser)
            }
            }>
            {/* Send Chat Request */}
            Start Chat
          </button>
        </div>
      }
      else if (userObj?.jobStatus != 'active' && userObj?.chatRoomId) {
        return <button className="btn"
          data-bs-dismiss="modal"
          aria-label="Close"
          type="button"
          onClick={() => {
            props?.history.push(`/dashboard/chat?chatid=${userObj.chatRoomId}`)
            setSidebar({ sidebar_name: "employee-details", show_sidebar: false })
          }}
          style={{ backgroundColor: "#FFB800", color: "black" }}
        >
          {/* Chat */}
          Ongoing Chat, Click Here
        </button>
      }
      // else return <div></div>
    } else {
      //IF JOB STATUS IS ACTIVE
      if (userObj?.status == 'active') {
        //IF CHAT IS ACTIVE
        if (userObj?.chatRoomId)
          return <button className="btn"
            data-bs-dismiss="modal"
            aria-label="Close"
            type="button"
            onClick={() => {
              props?.history.push(`/dashboard/chat?chatid=${userObj.chatRoomId}`)
              setSidebar({ sidebar_name: "employee-details", show_sidebar: false })
            }}
            style={{ backgroundColor: "#FFB800", color: "black" }}
          >
            {/* Chat */}
            Ongoing Chat, Click Here
          </button>
        //IF CHAT IS REJECTED
        // else if (userObj?.chatRejected)
        //   return <div></div>
        //IF CHAT IS REQUESTED BUT NOT REQUESTED BY USER 
        // else if (userObj?.chatRequested && !userObj?.requestedByUser) {
        //   return <>
        //     <button className='reject-btn'
        //       type="button"
        //       data-bs-dismiss="modal"
        //       aria-label="Close"
        //       onClick={() => {
        //         handleReceivedRequest.handleChatReject(selectedUser)
        //       }}>
        //       Reject
        //     </button>
        //     <button className="btn"
        //       data-bs-dismiss="modal"
        //       aria-label="Close"
        //       type="button"
        //       onClick={() => {

        //         handleReceivedRequest.handleChatAccept(selectedUser)
        //         props?.history.push(`/dashboard/chat?chatid=${userObj.chatRoomId}`)
        //       }}
        //     >
        //       Chat
        //     </button>
        //   </>
        // }
        //IF CHAT IS REQUESTED BUT REQUESTED BY USER 
        // else if (userObj?.chatRequested && userObj?.requestedByUser)
        //   return <div className='btn-effect full-width-btn v-disabled-btn'>
        //     <button className='chat-btn' disabled
        //       type="button"
        //     >
        //       Chat Request Sent
        //     </button>
        //   </div>
        //IF CHAT IS NOT REQUESTED
        else return <div className="btn-effect full-width-btn">
          <button className='btn'
            type="button"
            onClick={() => {
              if (!isUserLoggedIn) {
                // props?.setShowGuestAccessPrompt(true)
              }
              else if (!userTypeCookieval?.isAgeVerified) {
                setNotVerifiedModal(true)
              }
              else handleSendRequest(selectedUser)
            }}>
            {/* Send Chat Request */}
            Start Chat
          </button>
        </div>
      }
      else if (userObj?.status != 'active' && userObj?.chatRoomId) {
        return <button className="btn"
          data-bs-dismiss="modal"
          aria-label="Close"
          type="button"
          onClick={() => {
            props?.history.push(`/dashboard/chat?chatid=${userObj.chatRoomId}`)
            setSidebar({ sidebar_name: "employee-details", show_sidebar: false })
          }}
          style={{ backgroundColor: "#FFB800", color: "black" }}
        >
          {/* Chat */}
          Ongoing Chat, Click Here
        </button>
      }
      // else return <div></div>
    }
  }

  let logo = selectedUser?.employer?.logo
  if (selectedUser?.experience && selectedUser.experience.employer?.logoCdnUrl) {
    logo = selectedUser.experience?.employer?.logoCdnUrl
  }
  console.log("DID THIS BRO HAPPEN...")

  return (
    <>
      <div className="modal right-sidepop cross-upper-chnge-design fade right show"
        style={{ display: props?.showSidebar ? 'block' : 'none' }}
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-labelledby="staticBackdropLabel">
        <button type="button"
          // ref={closeModalRef}
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={() => props?.setShowSidebar(false)}
        >
          <img src={UtilityFunctions.getImageURL() + "close-icon.svg"} width="20px" height="20px" alt="" />
        </button>
        {/* <div className='flag-icon-dash' style={{ cursor: "pointer" }}>
          <img src={UtilityFunctions.getImageURL() + "flag-icon-dash.svg"} alt="" />
        </div> */}

        <div className="modal-dialog">
          <div className="modal-content" >
            <div className="modal-body">
              <div className="prfile-se-mai d-grid align-content-between">
                <div className="profil-to">
                  <div className='profile-detail-top'>
                    <div className='d-flex justify-content-between'>
                      <div className='prodetail-pic'>
                        <img src={selectedUser?.profilePhoto || UtilityFunctions.getImageURL() + "user-bg-dark.png"} />
                      </div>
                      {selectedUser?.user?.isVerified == true &&
                        <div className='verified-accnt'>
                          <img src={UtilityFunctions.getImageURL() + "account-verified.svg"} alt="" />
                          Account Verified
                        </div>
                      }
                    </div>
                    <div className='d-flex justify-content-between mt-18'>
                      <h4>{selectedUser?.user?.firstName}</h4>
                      <h5>
                        <img src={UtilityFunctions.getImageURL() + "Globe Location.svg"} alt="" />
                        {(selectedUser?.location?.suburb ? selectedUser?.location?.suburb : "") + (selectedUser?.location?.state ? ", " : "") + (selectedUser?.location?.state ? selectedUser?.location?.state : "")}
                      </h5>
                    </div>
                    <div className='label-design'>
                      <a className='label-button clr-yllw'>
                        <img src={UtilityFunctions.getImageURL() + "line-yellow.svg"} alt="" />
                        {modifiedSalaray(selectedUser?.minSalary, selectedUser?.maxSalary, selectedUser?.jobType, selectedUser?.salaryCheckoutType)}
                      </a>
                      <a className='label-button clr-pink'>
                        <img src={UtilityFunctions.getImageURL() + "clock-icon.svg"} alt="" />
                        {UtilityFunctions.convertMonthsToYearsAndMonths(selectedUser?.experience) + 'Experience'}
                      </a>
                      <>
                        {selectedUser?.category?.map((category) => {
                          return (<a key={category?.id} className='label-button clr-yllw'>
                            <img src={UtilityFunctions.getImageURL() + "line-yellow.svg"} alt="" />

                            {category.name}</a>)
                        })}
                        {selectedUser?.subCategory.map((subcategory) => {
                          return (
                            <a key={subcategory?.id} className='label-button clr-blue'>
                              <img src={UtilityFunctions.getImageURL() + "two-line-blue.svg"} alt="" />
                              {subcategory.name}
                            </a>
                          )
                        })}
                      </>
                    </div>
                  </div>
                  <div className='bordr-grdnt'></div>
                  <div className='profile-detail-top'>
                    <h6>About {(selectedUser?.user?.firstName || '')}</h6>
                    {/* {!selectedUser?.chatIsActive ?
                      <>
                        <div className="box-design d-flex align-items-center gap-3">
                          <img src={UtilityFunctions.getImageURL() + "EyeOffGreen.svg"} alt="" />
                          <p className="mb-0">Profile is hidden until chat request is accepted</p>
                        </div>
                      </>
                      : */}
                    <>
                      <p>
                        {selectedUser?.about}
                      </p>
                      {selectedUser?.introductoryVideo &&
                        <div className="videos_react_chet">
                          <ReactPlayer
                            controls={true}
                            // onReady={() => { playVideo && setPlayVideo(true) }}
                            // playsinline={playVideo}
                            width='100%'
                            height='100%'
                            playIcon={<img className="play-icon" src={UtilityFunctions.getImageURL() + "play-video-button.svg"} alt='' />}
                            light={selectedUser?.thumbnail ? <img className="video-thumbnail" onClick={() => setPlayVideo(true)} src={selectedUser?.thumbnail} alt='' /> : false}
                            // playing={playVideo}
                            playing
                            // light={true}
                            url={selectedUser?.introductoryVideo} />
                        </div>
                      }
                      <div className="profile-detail-media-object">
                        {
                          selectedUser?.educations?.length ?
                            <div className="media-object-pro">
                              <h3 className="text-start mt-0">Education / Qualifications</h3>
                              {selectedUser?.educations.map(education =>
                                <>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <div className="d-flex align-items-center">
                                      <img src={UtilityFunctions.getImageURL() + "hat-graduation.svg"} alt="" />
                                      <div className="">
                                        <h4 className="m-0">{education?.institutionName}</h4>
                                        <h6 className="m-0">{education?.course?.name ? education?.course?.name : ""}</h6>
                                      </div>
                                    </div>
                                    <p className="mb-0">{moment(education?.startDate).format("YYYY") + " - " + (education?.endDate ? moment(education?.endDate).format("YYYY") : "Present")}</p>
                                  </div>
                                  <hr />
                                </>)}
                            </div>
                            : <div></div>
                        }
                        {/* SHOWING PREVIOUS EXPERIENCE ONLY ADVANCE PROFILE IS UNLOCKED */}
                        {(selectedUser?.experienceHistory?.length && selectedUser?.user?.referralUsedCount >= 2) ?
                          <div className="media-object-pro pink-medi">
                            <h3 className="text-start mt-0">Previous Experience</h3>
                            {selectedUser?.experienceHistory?.map(experience =>
                              <>
                                <div className="d-flex align-items-center justify-content-between">
                                  <div className="d-flex align-items-center">
                                    <img src={logo || UtilityFunctions.getImageURL() + "pink-building.svg"} className={logo ? "p-0" : ""} alt="" />
                                    <div className="">
                                      <h4 className="m-0">{experience?.employer ? experience?.employer?.tradingName : experience?.unregisteredEmployer || "N/A"}</h4>
                                      <h6 className="m-0">{experience?.subCategory?.[0]?.name || "N/A"}</h6>
                                    </div>
                                  </div>
                                  <p className="mb-0"> {!experience?.startDate ? "N/A" : (experience?.endDate == null && experience?.startDate !== null
                                    ? `${moment(experience?.startDate).format("YYYY")} -  Present`
                                    : experience?.endDate == null && experience?.startDate == null
                                      ? 'N/A'
                                      : `${moment(experience?.startDate).format("YYYY")} -  ${moment(experience?.endDate).format("YYYY")}`)}
                                  </p>
                                </div>
                                <hr />
                              </>
                            )}
                          </div>
                          : <div></div>
                        }
                      </div>
                    </>
                    {/* } */}
                  </div>
                </div>
                <div className="profil-bot-la text-white">
                  {/* {userTypeCookieval?.user?.type !== STRING_CONSTANTS.userType.employer ?
                    // <div className="text-white w-100 align-bottom my-3">
                    //   Posted {moment(selectedUser?.createdAt).fromNow()}
                    // </div>
                    //:
                    ""} */}
                  <div className="d-flex align-items-center justify-content-around">
                    {handleChatButton(selectedUser)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  )
}
export default MapEmployeeSidebar;