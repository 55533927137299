import React, { Fragment, useEffect, useState } from "react";
import { STRING_CONSTANTS } from "../../../utils/constants/stringConstants";
import { UtilityFunctions } from "../../../utils/UtilityFunctions";
import { bindActionCreators } from "redux";
import { actionCreators, State } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import ReactSlider from 'react-slider'

const EmployeeFilterSidebar = (props: any) => {
    const dispatch = useDispatch();
    const { setEmployeesList } = bindActionCreators(actionCreators, dispatch);
    const [sliderValue, setSliderValue] = useState(50);

    const handleSliderChange = (value: any) => {
        // console.log(value);
        setSliderValue(value);
        // const convertedValue = Math.floor(value / 2)
        // props?.setRequestDistance(convertedValue)
    }

    const handlerSliderStop = (value) => {
        // console.log(value / 2, '000000000000000')
        setSliderValue(value);
        let convertedValue = Math.floor(value / 2)

        if (convertedValue === 0 || convertedValue >= 50) {
            convertedValue = 1000;
        }
        props?.setRequestDistance(convertedValue)
    }

    useEffect(() => {
        if (props.requestDistance) {
            setSliderValue(Math.floor(props.requestDistance) * 2)
        }
    }, [props.requestDistance]);

    useEffect(() => {
        props?.setSelectedJobID(props?.selectedJobID ? props?.selectedJobID : props.employeeJobList?.[0]?.id)
    }, [])

    const jobListRender = props?.employeeJobList?.map((job) => {
        if (job.id === props?.selectedJobID) {
            return (<Fragment key={job.id}>
                <div className="chat-list-par d-flex align-items-center justify-content-between">
                    <div className="chat-left-par d-flex align-items-center justify-content-between">
                        <img className="profil" src={job?.cover || UtilityFunctions.getImageURL() + "bildeing-squre-img.png"} alt="" />
                        <div className="chat-cont-li">
                            <h5>{job?.title}</h5>
                            <p><img src={UtilityFunctions.getImageURL() + 'globe-location.svg'} alt="" /> {job?.location?.address}</p>
                        </div>
                    </div>
                    <a href="#" className="viewing-btn">Viewing</a>
                </div>
            </Fragment>)
        }
        return <Fragment key={job.id}>
            <div className="chat-list-par d-flex align-items-center justify-content-between">
                <div className="chat-left-par d-flex align-items-center justify-content-between">
                    <img className="profil" src={job?.cover || UtilityFunctions.getImageURL() + "bildeing-squre-img.png"} alt="" />
                    <div className="chat-cont-li">
                        <h5>{job?.title}</h5>
                        <p><img src={UtilityFunctions.getImageURL() + 'globe-location.svg'} alt="" /> {job?.location?.address}</p>
                    </div>
                </div>
                <a href="#" className="switch-btn" onClick={() => {
                    props?.setSelectedJobID(job?.id)
                    props?.setEmployeeObject(job);
                }}>Switch</a>
            </div>
        </Fragment>
    })

    return (
        <>
            <div className="modal-content">
                <div className="modal-data-all">
                    <div className="top-icon">

                        {/* <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="0.5" y="0.5" width="25" height="25" rx="12.5" stroke="white" />
                            <path d="M14.125 16C14.4357 16 14.6875 16.2518 14.6875 16.5625C14.6875 16.8732 14.4357 17.125 14.125 17.125H11.875C11.5643 17.125 11.3125 16.8732 11.3125 16.5625C11.3125 16.2518 11.5643 16 11.875 16H14.125ZM16.375 12.25C16.6857 12.25 16.9375 12.5018 16.9375 12.8125C16.9375 13.1232 16.6857 13.375 16.375 13.375H9.625C9.31434 13.375 9.0625 13.1232 9.0625 12.8125C9.0625 12.5018 9.31434 12.25 9.625 12.25H16.375ZM18.625 8.5C18.9357 8.5 19.1875 8.75184 19.1875 9.0625C19.1875 9.37316 18.9357 9.625 18.625 9.625H7.375C7.06434 9.625 6.8125 9.37316 6.8125 9.0625C6.8125 8.75184 7.06434 8.5 7.375 8.5H18.625Z" fill="white" />
                        </svg> */}
                        <img src={UtilityFunctions.getImageURL() + "filter-round.svg"} alt="" />
                    </div>
                    {props?.userType === STRING_CONSTANTS.userType.employer &&
                        <div className="chat-list-cont">
                            {props?.employeeJobList ? jobListRender : <h2 style={{ color: "white" }}>Loading...</h2>}
                        </div>
                    }
                    <div className="chat-list-checkb">
                        <div className="sho-pchange">
                            <span>Sort By Relevance</span>
                            <div className="input-w myradio">
                                <input
                                    type="radio"
                                    name="firstradio"
                                    id="rabt"
                                    defaultChecked={!props?.closest}
                                    onChange={e => {
                                        e.target.value && props?.setClosest(false)
                                        // console.log(props?.closest)
                                    }}
                                />
                                <label htmlFor="rabt"></label>
                            </div>
                        </div>
                        <div className="sho-pchange">
                            <span>Sort by Distance</span>
                            <div className="input-w myradio">
                                <input
                                    type="radio"
                                    name="firstradio"
                                    id="rabt1"
                                    defaultChecked={props?.closest}
                                    onChange={e => {
                                        e.target.value && props?.setClosest(true)
                                        // console.log(props?.closest)
                                    }}
                                />
                                <label htmlFor="rabt1"></label>
                            </div>
                        </div>
                        {/* <div className="sho-pchange">
                            <span>Request Sent</span>
                            <div className="input-w">
                                <input
                                    type="checkbox"
                                    name=""
                                    id="shr"
                                    defaultChecked={props.requestSent}
                                    onChange={(e) => {
                                        setEmployeesList(null)
                                        props?.setRequestSent(e.target.checked)
                                    }}
                                />
                                <label htmlFor="shr"></label>
                            </div>
                        </div> */}
                        {/* <div className="sho-pchange">
                            <span>Request Received</span>
                            <div className="input-w">
                                <input
                                    type="checkbox"
                                    name=""
                                    id="shr1"
                                    defaultChecked={props.requestReceived}
                                    onChange={(e) => {
                                        setEmployeesList([])
                                        props?.setRequestReceived(e.target.checked)
                                    }}
                                />
                                <label htmlFor="shr1"></label>
                            </div>
                        </div> */}

                        <div className="sho-pchange" style={{ border: 'none', marginBottom: 0 }}>
                            <span>Filter by Radius</span>
                            <div style={{ color: '#06EFC5' }}>{Math.floor(sliderValue / 2) > 50 ? '50+' : Math.floor(sliderValue / 2)} Km</div>
                        </div>

                        <ReactSlider
                            className="horizontal-slider"
                            thumbClassName="example-thumb"
                            trackClassName="example-track"
                            value={sliderValue}
                            onChange={handleSliderChange}
                            onAfterChange={handlerSliderStop}
                        // renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                        />
                        <div className="sho-pchange" style={{ border: 'none', marginBottom: 0 }}>
                            <span style={{ color: 'grey' }}>0 km</span>
                            <div style={{ color: 'grey' }}>50+ km</div>
                        </div>
                        {/* {props?.activeIndex &&
                            <>
                                <button className="btn-btn-primary" style={{ border: 'none', width: '100%', marginTop: '5%' }}>Apply</button>
                                <div className="cancel-btn" >Cancel</div>
                            </>
                        } */}
                    </div>
                </div>
            </div >
        </>);
}

export default EmployeeFilterSidebar;