import React, { useState } from "react";
import "./InterviewSimulator.css";

const InterviewSimulator = () => {
  const [isAudioActive, setIsAudioActive] = useState(true);
  const [isCameraActive, setIsCameraActive] = useState(true);

  const handleAudioToggle = () => {
    setIsAudioActive((prevState) => !prevState);
  };

  const handleCameraToggle = () => {
    setIsCameraActive((prevState) => !prevState);
  };

  return (
    <div className="interview-simulator container-fluid">
      <div className="row justify-content-center m-0">
        <div className="col-12 text-center">
          <img
            src="/assets/images/mploi-logo-white.svg"
            alt="MPLOI Logo"
            className="logo-img"
          />
          <div className="heading">
            Welcome to the MPLOI interview simulator!
          </div>
        </div>
      </div>

{/* <div className='row user-img-wrap'>
<div className='col-md-4'>
<img src="/assets/images/user-1.png" alt="User" className="profile-img" />
</div>
<div className='col-md-4'>
<img src="/assets/images/arrow-line.png" alt="" /> 
</div>
<div className='col-md-4'>
<img src="/assets/images/user-1.png" alt="User" className="profile-img" />
</div>
</div> */}
        
         
        {/* <div className='col-12'>
          <div className='arrow-img-wrap'>
            <img src="/assets/images/arrow-line.png" alt="" /> 
          </div>
        </div> */}

      <div className="row profile-sec-wrap">
        <div className="col-12 col-md-5 text-center">
          <div className="profile-section">
          <div className='arrow-img-wrap'>
            <img src="/assets/images/arrow-line.png" alt="" /> 
          </div>
            <img src="/assets/images/user-1.png" alt="User" className="profile-img" />
            <div className='user-name'>You: <br /> <span>Chris Sharkey</span> </div>
            <div className="icons">
              <button onClick={handleAudioToggle} className="icon-button">
                <img
                  src={
                    isAudioActive
                      ? "/assets/images/audio-off.png"
                      : "/assets/images/audio-on.png"
                  }
                  alt="Audio"
                  className="icon-img"
                />
              </button>
              <button onClick={handleCameraToggle} className="icon-button">
                <img
                  src={
                    isCameraActive
                      ? "/assets/images/vdo-off.png"
                      : "/assets/images/vdo-on.png"
                  }
                  alt="Camera"
                  className="icon-img"
                />
              </button>
            </div>
            <div className="icon-title">Click to allow access</div>
          </div>
        </div>

        
        {/* <div className='col-12 col-md-4'>
          <div className='arrow-img-wrap'>
            <img src="/assets/images/arrow-line.png" alt="" /> 
          </div>
        </div> */}

        <div className="col-12 col-md-5 text-center">
          <div className="profile-section">
            <img
              src="/assets/images/user-2.png"
              alt="Manager"
              className="profile-img"
            />
            <div className="caller-title">You will be speaking with:</div>
            <div className="caller-name">
              Maxine, Hiring Manager from H&R Block
            </div>
          </div>
        </div>
      </div>

      <div className="row bottom-content m-0">
        <div className="col-12 text-center">
          <div className="description">
            You’ll have time to review your appearance before starting the
            interview. Please press 'prepare' to begin and make sure you
            <b> allow access to your camera and microphone. </b>
          </div>
          <button className="btn prepare-button">Prepare for interview</button>
          <div className="bottom-line">
            Feedback will be given at the end of the interview
          </div>
        </div>
      </div>
    </div>
  );
};

export default InterviewSimulator;
