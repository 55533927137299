import React, { useEffect, useRef, useState } from "react"
import { withRouter } from "react-router-dom"
import ValidationErrorToast from "../../components/validationErrorToast";
import { api } from "../../middleware/api";
import { STRING_CONSTANTS } from "../../utils/constants/stringConstants";
import { UtilityFunctions } from "../../utils/UtilityFunctions";
import moment from "moment";
import HideProfile from "../EmployeeProfileCompletion/HideProfile";
const debounce = require("debounce-promise");

const PreviousEmployers = (props) => {
  const { setSelectedEmployerObj, employerArray, setDeletedEmployerArr, deletedEmployerArr, selectedEmployerObj, setStep, setEmployerArray } = props
  const [searchTerm, setSearchTerm] = useState<string>("")
  const [searchEmployerList, setSearchEmployerList] = useState([]);
  const searchRef = useRef<HTMLInputElement>();
  const [businessName, setBusinessName] = useState<string>("")
  const [isRegisteredEmployer, setIsRegisteredEmployer] = useState(true);
  const [selectedEmployer, setSelectedEmployer] = useState<any>()
  const initialErrorState = { business: false, employer: false }
  const [errorState, setErrorState] = useState(initialErrorState)
  const isProfileBeingUpdated = props?.location?.state?.isProfileBeingUpdated;

  useEffect(() => {
    if (searchTerm) {
      api.get(`employer/all/?search=${searchTerm}`, null)
        .then(res => setSearchEmployerList(res))
        .catch(e => { })
    }
  }, [searchTerm])

  useEffect(() => {
    if (selectedEmployerObj?.unregisteredEmployer) {
      setBusinessName(selectedEmployerObj?.unregisteredEmployer);
      setIsRegisteredEmployer(false)
    }
    else if (selectedEmployerObj?.employer) {
      setSelectedEmployer(selectedEmployerObj?.employer)
      setIsRegisteredEmployer(true)
    }
  }, [])

  const debounceClick = debounce((value: string) => {
    setSearchTerm(value)
  }, 600);

  const addEmployerHandler = (employer) => {
    if (employerArray.findIndex(item => item?.employer?.id === employer?.id) !== -1) {
      ValidationErrorToast("Employer Exists", "Selected employer already exists")
    }
    else {
      setSearchTerm("");
      searchRef.current.value = "";
      setSelectedEmployer(employer)
      setErrorState(initialErrorState)
      setSelectedEmployerObj((prev) => { return { ...prev, category: undefined } })
    }
  }

  const handleOnDelete = (deleteIndex: number) => {
    let filteredData = employerArray?.filter((item, index) => {
      if (index == deleteIndex && item?.id) {
        setDeletedEmployerArr(prev => [...prev, item?.id]);
      }
      return index !== deleteIndex;
    });
    setEmployerArray(filteredData);
  };

  const submitHandler = () => {
    if (isProfileBeingUpdated) {
      if (isRegisteredEmployer && !selectedEmployer) {
        ValidationErrorToast("Select Employer", "Please select an employer before proceeding")
        setErrorState({ business: false, employer: true })
      }
      else if (!isRegisteredEmployer && !businessName) {
        ValidationErrorToast("Business Name", "Please enter a business name before proceeding")
        setErrorState({ business: true, employer: false })
      }
      else if (businessName) {
        setSelectedEmployerObj(prev => { return { ...prev, employer: null, unregisteredEmployer: businessName, action: "add" } })
        setStep(2)
      }
      else if (selectedEmployer) {
        setSelectedEmployerObj(prev => { return { ...prev, employer: selectedEmployer, unregisteredEmployer: null, action: "add" } })
        setStep(2)
      }
    }
    else {
      if (!businessName && !selectedEmployer) {
        setStep(5)
      }
      else if (businessName) {
        setSelectedEmployerObj(prev => { return { ...prev, employer: null, unregisteredEmployer: businessName, action: "add" } })
        setStep(2)
      }
      else if (selectedEmployer) {
        setSelectedEmployerObj(prev => { return { ...prev, employer: selectedEmployer, unregisteredEmployer: null, action: "add" } })
        setStep(2)
      }
    }
  }

  return <>
    <div className='top-divde-spa'>
      <span>
        <i
          className="fa fa-angle-left text-white d-flex justify-content-between"
          aria-hidden="true"
          onClick={() => {
            props.nav.prev();
          }}
        ></i>
      </span>
      {!isProfileBeingUpdated && !props?.location?.state?.isEmployerBeingAdded && selectedEmployerObj?.action !== "add" && <span className="top-skip">
        <p className="mb-0"
          onClick={() => {
            if (isProfileBeingUpdated) {
              props.history.push(STRING_CONSTANTS.navigationString.dashboard.settings, { tab: "updateMyExperience" })
            }
            else setStep(6)
          }}
        >
          Skip
        </p>
      </span>}
      <div className="step-content signup-mar">
        <button type='button' disabled>
          {STRING_CONSTANTS.advanced_profile.step1.title}
        </button>
        <p className="mw-100">
          {STRING_CONSTANTS.advanced_profile.step1.subtitle}
        </p>
        <div className="step-text">
          {isProfileBeingUpdated ? "Step 1/4" : STRING_CONSTANTS.advanced_profile.step1.step}
        </div>
      </div>
    </div>
    <form
    >
      <div className="form-wrap business-detailss">
        <div className="v-form-width min-height-auto">
          <div className="form-floating mb-3 label-dark remove-right-icon">
            <>
              {isRegisteredEmployer ?
                <>
                  <input type='text'
                    className={`form-control search-icon-error ${errorState.employer ? "error" : ""}`}
                    placeholder="Search employer..."
                    ref={searchRef}
                    onChange={(e) => {
                      setErrorState(initialErrorState)
                      setErrorState(prev => { return { ...prev, employer: false } })
                      debounceClick(e.target.value)
                    }}
                  />
                  <label htmlFor="floatingInput">{STRING_CONSTANTS.advanced_profile.step1.search_employer_ph}</label>
                  <span className="input-group-text">
                    <img src={UtilityFunctions.getImageURL() + "search-with-shadow.svg"} alt="" />
                    <img src={UtilityFunctions.getImageURL() + "search-with-shadow-fill.svg"} alt="" />
                    {/* <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="12" cy="12" r="7" stroke="#D0D0D0" strokeWidth="2" />
                      <path d="M21 21L18 18" stroke="#D0D0D0" strokeWidth="2" strokeLinecap="round" />
                      <defs>
                        <filter id="filter0_f_4_8" x="-3" y="-3" width="32" height="32" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                          <feFlood floodOpacity="0" result="BackgroundImageFix" />
                          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                          <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_4_8" />
                        </filter>
                      </defs>
                    </svg> */}
                    {/* <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g filter="url(#filter0_f_4_8)">
                        <circle cx="12" cy="12" r="7" stroke="#D0D0D0" strokeWidth="2" />
                        <path d="M21 21L18 18" stroke="#D0D0D0" strokeWidth="2" strokeLinecap="round" />
                      </g>
                      <circle cx="12" cy="12" r="7" stroke="#D0D0D0" strokeWidth="2" />
                      <path d="M21 21L18 18" stroke="#D0D0D0" strokeWidth="2" strokeLinecap="round" />
                      <defs>
                        <filter id="filter0_f_4_8" x="-3" y="-3" width="32" height="32" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                          <feFlood floodOpacity="0" result="BackgroundImageFix" />
                          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                          <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_4_8" />
                        </filter>
                      </defs>
                    </svg> */}
                  </span>
                </> :
                <>
                  <input type='text'
                    className={`form-control enter-buss ${errorState.business ? "error" : ""}`}
                    placeholder="Enter Business Name"
                    value={businessName}
                    maxLength={50}
                    onChange={(e) => {
                      setErrorState(initialErrorState)
                      setBusinessName(e.target.value)
                      setErrorState(prev => { return { ...prev, business: false } })
                      setSelectedEmployerObj((prev) => { return { ...prev, category: undefined } })
                    }}
                  />
                  <label htmlFor="floatingInput">{STRING_CONSTANTS.advanced_profile.step1.enter_business_name}</label>
                  <span className="input-group-text">
                    <img src={UtilityFunctions.getImageURL() + "building-ico-form.svg"} alt="" />
                    <img src={UtilityFunctions.getImageURL() + "building-input-fill.svg"} alt="" />
                    {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g opacity="0.5">
                        <path d="M8.5 5.5C7.94772 5.5 7.5 5.94772 7.5 6.5C7.5 7.05228 7.94772 7.5 8.5 7.5C9.05229 7.5 9.5 7.05228 9.5 6.5C9.5 5.94772 9.05229 5.5 8.5 5.5ZM7.5 13.5C7.5 12.9477 7.94772 12.5 8.5 12.5C9.05229 12.5 9.5 12.9477 9.5 13.5C9.5 14.0523 9.05229 14.5 8.5 14.5C7.94772 14.5 7.5 14.0523 7.5 13.5ZM8.5 9C7.94772 9 7.5 9.44771 7.5 10C7.5 10.5523 7.94772 11 8.5 11C9.05229 11 9.5 10.5523 9.5 10C9.5 9.44771 9.05229 9 8.5 9ZM11 6.5C11 5.94772 11.4477 5.5 12 5.5C12.5523 5.5 13 5.94772 13 6.5C13 7.05228 12.5523 7.5 12 7.5C11.4477 7.5 11 7.05228 11 6.5ZM12 12.5C11.4477 12.5 11 12.9477 11 13.5C11 14.0523 11.4477 14.5 12 14.5C12.5523 14.5 13 14.0523 13 13.5C13 12.9477 12.5523 12.5 12 12.5ZM14.5 13.5C14.5 12.9477 14.9477 12.5 15.5 12.5C16.0523 12.5 16.5 12.9477 16.5 13.5C16.5 14.0523 16.0523 14.5 15.5 14.5C14.9477 14.5 14.5 14.0523 14.5 13.5ZM12 9C11.4477 9 11 9.44771 11 10C11 10.5523 11.4477 11 12 11C12.5523 11 13 10.5523 13 10C13 9.44771 12.5523 9 12 9ZM6.25 2C5.00736 2 4 3.00736 4 4.25V20.75C4 21.1642 4.33579 21.5 4.75 21.5H19.2528C19.667 21.5 20.0028 21.1642 20.0028 20.75V11.7493C20.0028 10.5067 18.9954 9.4993 17.7528 9.4993H16.5V4.25C16.5 3.00736 15.4926 2 14.25 2H6.25ZM5.5 4.25C5.5 3.83579 5.83579 3.5 6.25 3.5H14.25C14.6642 3.5 15 3.83579 15 4.25V10.2493C15 10.6635 15.3358 10.9993 15.75 10.9993H17.7528C18.167 10.9993 18.5028 11.3351 18.5028 11.7493V20H16.5V17.25C16.5 16.8358 16.1642 16.5 15.75 16.5H8.25C7.83579 16.5 7.5 16.8358 7.5 17.25V20H5.5V4.25ZM15 18V20H12.75V18H15ZM11.25 18V20H9V18H11.25Z" fill="white" />
                      </g>
                    </svg> */}
                    {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6.75 2C5.50736 2 4.5 3.00736 4.5 4.25V20.75C4.5 21.1642 4.83579 21.5 5.25 21.5H7.5V17.25C7.5 16.8358 7.83579 16.5 8.25 16.5H15.75C16.1642 16.5 16.5 16.8358 16.5 17.25V21.5H18.75C19.1642 21.5 19.5 21.1642 19.5 20.75V11.7493C19.5 10.5067 18.4926 9.4993 17.25 9.4993H16.5V4.25C16.5 3.00736 15.4926 2 14.25 2H6.75ZM7.5 6.5C7.5 5.94772 7.94772 5.5 8.5 5.5C9.05228 5.5 9.5 5.94772 9.5 6.5C9.5 7.05228 9.05228 7.5 8.5 7.5C7.94772 7.5 7.5 7.05228 7.5 6.5ZM8.5 12.5C9.05228 12.5 9.5 12.9477 9.5 13.5C9.5 14.0523 9.05228 14.5 8.5 14.5C7.94772 14.5 7.5 14.0523 7.5 13.5C7.5 12.9477 7.94772 12.5 8.5 12.5ZM7.5 10C7.5 9.44772 7.94772 9 8.5 9C9.05228 9 9.5 9.44772 9.5 10C9.5 10.5523 9.05228 11 8.5 11C7.94772 11 7.5 10.5523 7.5 10ZM12 5.5C12.5523 5.5 13 5.94772 13 6.5C13 7.05228 12.5523 7.5 12 7.5C11.4477 7.5 11 7.05228 11 6.5C11 5.94772 11.4477 5.5 12 5.5ZM11 13.5C11 12.9477 11.4477 12.5 12 12.5C12.5523 12.5 13 12.9477 13 13.5C13 14.0523 12.5523 14.5 12 14.5C11.4477 14.5 11 14.0523 11 13.5ZM15.5 12.5C16.0523 12.5 16.5 12.9477 16.5 13.5C16.5 14.0523 16.0523 14.5 15.5 14.5C14.9477 14.5 14.5 14.0523 14.5 13.5C14.5 12.9477 14.9477 12.5 15.5 12.5ZM11 10C11 9.44772 11.4477 9 12 9C12.5523 9 13 9.44772 13 10C13 10.5523 12.5523 11 12 11C11.4477 11 11 10.5523 11 10ZM15 21.5V18H12.7499V21.5H15ZM11.2499 21.5V18H9V21.5H11.2499Z" fill="#38BBF4" />
                    </svg> */}
                  </span>
                </>}
            </>
            <div>
            </div>
            {// SEARCH TERM AND SEARCH RESULTS BOTH EXIST
              (searchEmployerList.length && searchTerm.length) ?
                <ul className="list-unstyled ms-0 filter-dropdonw-set">
                  {searchEmployerList.map(employer =>
                    <li key={employer?.id}
                      onClick={() => addEmployerHandler(employer)}>
                      <img src={employer?.logo || UtilityFunctions.getImageURL() + "bildeing-squre-img.png"} alt="" />
                      <span>
                        {employer?.businessName} ({employer?.tradingName})
                      </span>
                    </li>)}
                </ul>
                :
                (!searchEmployerList.length && searchTerm.length) ?
                  <div className="filter-dropdonw-set-blank" style={{ display: "inherit" }}>
                    <div className="text-center">
                      <img src={UtilityFunctions.getImageURL() + "three-dots-blue.svg"} alt="" />
                      {/* <svg width="40" height="8" viewBox="0 0 40 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="4" cy="4" r="3.5" fill="white" fillOpacity="0.2" stroke="#38BBF4" />
                        <circle cx="20" cy="4" r="3.5" fill="white" fillOpacity="0.2" stroke="#38BBF4" />
                        <circle cx="36" cy="4" r="3.5" fill="white" fillOpacity="0.2" stroke="#38BBF4" />
                      </svg> */}
                      <p>{STRING_CONSTANTS.advanced_profile.step1.no_results} "{searchTerm}"</p>
                      <button type="button"
                        onClick={() => { setSearchTerm(""); searchRef.current.value = "" }}>
                        {STRING_CONSTANTS.advanced_profile.step1.search_again}
                      </button>
                    </div>
                  </div> :
                  <></>
            }
          </div>
          <div className="cant-fin-emploer text-white mt-3 pb-4 mb-3">
            {/* IF THE SELECTED EMPLOYER IS REGISTERED */}
            {isRegisteredEmployer ?
              <p>
                <span>{STRING_CONSTANTS.advanced_profile.step1.cant_find_employer}</span>
                <b className="fw-bold" onClick={() => {
                  setIsRegisteredEmployer(false);
                  setBusinessName("")
                  searchRef.current.value = ""
                  setErrorState(initialErrorState)
                }}> {STRING_CONSTANTS.advanced_profile.step1.enter_name}
                </b>
              </p>
              :
              <p onClick={() => {
                setIsRegisteredEmployer(true);
                setBusinessName("")
                if(searchRef.current){
                  searchRef.current.value = ""
                }
               
                setErrorState(initialErrorState)
              }}>{STRING_CONSTANTS.advanced_profile.step1.search_employer_question}</p>
            }
          </div>
          {/* IF THE USER HAS SELECTED AN EMPLOYER */}
          {selectedEmployer ?
            <div>
              <ul className="current-employ-list list-unstyled ps-0 ms-0 text-white mb-3 d-grid gap-3">
                <li className="d-flex align-items-center justify-content-between gap-3">
                  <span className="d-flex align-items-center gap-3">
                    <img
                      src={selectedEmployer?.logo || UtilityFunctions.getImageURL() + "bildeing-squre-img-pink.svg"}
                      className="bilding-ig" alt=""
                    />
                    {selectedEmployer?.tradingName}
                  </span>
                  <img
                    onClick={() => setSelectedEmployer(null)}
                    src={UtilityFunctions.getImageURL() + "Dismiss-cross-green.svg"} className="cross-icon" alt=""
                  />
                </li>
              </ul>
            </div> : <div></div>}
          {/* IF HE PREVIOUS EMPLOYERS LIST EXISTS AND PROFILE IS NOT BEING UPDATED */}
          {(!isProfileBeingUpdated && employerArray.length) ?
            <div className="update_my_experience_des v-advance-profile bg-transparent p-0">
              <h5 className="text-start mb-4 pb-1">{STRING_CONSTANTS.advanced_profile.step1.experiences}</h5>
              <ul className="current-employ-list list-unstyled ps-0 ms-0 text-white mb-3 d-grid gap-3">
                {employerArray?.map((employer, index) => {
                  return <li key={employer?.employer?.id}>
                    <div className="update-my-ex mb-3">
                      <div className="d-flex align-items-center justify-content-between gap-3 mb-3">
                        <span className="d-flex align-items-center gap-3">
                          <img
                            src={employer?.employer?.logo || UtilityFunctions.getImageURL() + "bildeing-squre-img-pink.svg"}
                            className="bilding-ig" alt=""
                          />
                          <div className="v-text text-start">
                            <div className="upper-conts">{employer?.unregisteredEmployer || employer?.employer?.tradingName}</div>
                            {employer?.subCategory?.[0]?.name ?
                              <p className="mb-0">{employer?.subCategory?.[0]?.name}</p>
                              :
                              <p className="mb-0 text-custom-danger">Not Selected</p>
                            }
                          </div>
                        </span>
                        {selectedEmployerObj?.anotherEmployer==true?null:
                        <div className="d-flex gap-3 aligh-item-center">
                          <button type="button" className="bg-transparent shadow-none"
                            onClick={() => { setSelectedEmployerObj({ ...employer, indexToEdit: index, action: "edit" }); setStep(2) }}
                          >
                            <img src={UtilityFunctions.getImageURL() + "edit-green.svg"} alt="" />
                          </button>
                          <img
                            onClick={() => handleOnDelete(index)}
                            src={UtilityFunctions.getImageURL() + "cross-pink.svg"} className="cross-icon cross-icon-pink" alt=""
                          />
                        </div>}
                      </div>
                      <div className="date-varigble-css d-flex align-items-center justify-content-between pe-3 gap-2">
                        {employer?.startDate ?
                          <p className="mb-0 d-flex align-items-center gap-2">
                            <img src={UtilityFunctions.getImageURL() + "calendar-fill-white.svg"} alt="" />
                            {moment(employer?.startDate).format("DD/MM/YY")} - {(employer?.endDate) ? moment(employer?.endDate).format("DD/MM/YY") : "Present"}
                          </p> :
                          <p className="mb-0 d-flex align-items-center gap-2 text-custom-danger">
                            <img src={UtilityFunctions.getImageURL() + "calendar-fill-red.png"} alt="" />
                            Unspecified
                          </p>
                        }
                        {employer?.isProfileHidden ?
                          <p className="mb-0 d-flex align-items-center gap-2"><img src={UtilityFunctions.getImageURL() + "EyeOff-gray.svg"} alt="" /> Hidden Employer</p> :
                          <p className="mb-0 d-flex align-items-center gap-2"><img src={UtilityFunctions.getImageURL() + "eyeOn.png"} alt="" /> Visible Employer</p>
                        }
                      </div>
                    </div>
                    <hr />
                  </li>
                })}
              </ul>
            </div> : <div></div>}
        </div>
      </div>
    </form>
    <div className="next-wrap">
      <button className={
        (!isProfileBeingUpdated || (isProfileBeingUpdated && (businessName || selectedEmployer))) ?
          "cricle-next"
          : "cricle-next-inactive"
      }
        onClick={(e) => submitHandler()}
      >
        <img
          alt=""
          src={UtilityFunctions.getImageURL() + "arrow-bottom.svg"}
        />
      </button>
    </div>
  </>
}

export default withRouter(PreviousEmployers)