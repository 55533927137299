import React from "react";
import CustomAccordian from "../../CustomAccordion";
import "./Section3.css"

export default function Section3({
  accrodianData = [],
  tagName = "",
  description = "",
  image = {},
  mobilebutton = {},
}) {
  return (
    <div className="section3">
      <div className="tag">{tagName}</div>
      <div className="subheading">{description}</div>
      <div className=" ResponsiveWrapper">
        <div className=" accordianWrap ">
          <CustomAccordian
            data={accrodianData}
            customClass="employeesAccordian"
          />
          {mobilebutton || ''}
        </div>
        {image}
      </div>
    </div>
  );
}
