import React, { useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { api } from "../middleware/api";
import { STRING_CONSTANTS } from "../utils/constants/stringConstants";
import { UtilityFunctions } from "../utils/UtilityFunctions";
import { cookieService, userService } from "../_services";
import ValidationErrorToast from "./validationErrorToast";
import { actionCreators, State } from "../store";
import { bindActionCreators } from "redux";

const UpdateNumber = (props) => {
  const dispatch = useDispatch();
  const state = useSelector((state: State) => state);
  const [pwdType, setPwdType] = useState("password");
  const [number, setNumber] = useState("");
  const { setSignUpMobileNumber } = bindActionCreators(actionCreators, dispatch);
  const [pwd, setPwd] = useState("");
  const userData = cookieService.getCookie(STRING_CONSTANTS.cookieStrings.user)
  const initialErrorState = { number: false, password: false }
  const [errorState, setErrorState] = useState(initialErrorState)
  const togglePassword = () => {
    if (pwdType === "password") {
      setPwdType("text");
      return;
    }
    setPwdType("password");
  };
  useEffect(() => {
    setNumber("")
  }, [])
  const dataSubmit = {
    "user": {
      "mobileNumber": `${number}`,
      "password": `${pwd}`
    }
  }
  const updateHandler = () => {
    const noSpace = /^\S+$/;
    if (userData?.user?.mobileNumber === number) {
      setErrorState(prev => { return { ...prev, number: true } })
      ValidationErrorToast("New Number", "New number cannot be the same as old number")
    }
    else if (number === "") {
      setErrorState(prev => { return { ...prev, number: true } })
      ValidationErrorToast("Invalid Number", "Number cannot be empty")
    }
    else if (pwd === "") {
      setErrorState(prev => { return { ...prev, password: true } })
      ValidationErrorToast("Invalid Password", "Password cannot be empty")
    }
    else if (!noSpace.test(pwd)) {
      setErrorState(prev => { return { ...prev, password: true } })
      ValidationErrorToast("Invalid Password", "Password cannot contain spaces")
    }
    else {
      let otpToken;
      userService.userProfile(dataSubmit)
        .then(() => {
          setSignUpMobileNumber(number)
          userService.sendOtp()
            .then(res => {
              otpToken = res?.token
            })
            .then(res => {
              props.history.push(
                {
                  pathname: STRING_CONSTANTS.navigationString.signup,
                  state: { step: 4, updateNumber: true, token: otpToken }
                })
            })
            .catch(e => ValidationErrorToast(e[0], e[1]))
        })
        .catch(e => ValidationErrorToast(e[0], e[1]))
    }
  }

  return (
    <Modal
      className="bg-transparent upgrade-popup logout-popup settings-update-hash"
      aria-labelledby="contained-modal-title-vcenter" centered
      show={props?.show}
      onHide={() => {
        props?.handleClose()
        setNumber("")
        setErrorState(initialErrorState)
      }}
    >
      <Modal.Body>
        <div className="update_number_poup" style={{ maxWidth: "100%", margin: "0 auto" }}>
          {/* <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="25" height="25" rx="12.5" stroke="white" />
            <path d="M16.375 10.75C16.9963 10.75 17.5 10.2463 17.5 9.625C17.5 9.00368 16.9963 8.5 16.375 8.5C15.7537 8.5 15.25 9.00368 15.25 9.625C15.25 10.2463 15.7537 10.75 16.375 10.75Z" fill="white" />
            <path d="M12.4375 17.5V16.9375H13.5625C13.8732 16.9375 14.125 16.6857 14.125 16.375V15.25H15.25C17.7353 15.25 19.75 13.2353 19.75 10.75C19.75 8.26472 17.7353 6.25 15.25 6.25C12.7647 6.25 10.75 8.26472 10.75 10.75C10.75 11.1911 10.8136 11.6182 10.9325 12.022L6.5795 16.375C6.36853 16.586 6.25 16.8721 6.25 17.1705V18.625C6.25 19.2463 6.75368 19.75 7.375 19.75H9.625C10.2463 19.75 10.75 19.2463 10.75 18.625V18.0625H11.875C12.1857 18.0625 12.4375 17.8107 12.4375 17.5ZM11.875 10.75C11.875 8.88604 13.386 7.375 15.25 7.375C17.114 7.375 18.625 8.88604 18.625 10.75C18.625 12.614 17.114 14.125 15.25 14.125H13.5625C13.2518 14.125 13 14.3768 13 14.6875V15.8125H11.875C11.5643 15.8125 11.3125 16.0643 11.3125 16.375V16.9375H10.1875C9.87684 16.9375 9.625 17.1893 9.625 17.5V18.625H7.375V17.1705L11.9751 12.5704C12.1327 12.4128 12.1824 12.1771 12.1018 11.9693C11.9555 11.5919 11.875 11.181 11.875 10.75Z" fill="white" />
          </svg> */}
          <img src={UtilityFunctions.getImageURL() + "key-icon-round.svg"} alt="" />

          <h3 className="py-2">Update Mobile</h3>
          <p className="py-3">
            Enter your new number and password to update your details
          </p>
          <div className={`input-group mb-3`
            //  ${focusState.contactNo ? "input-focus-profile" : ""}`
          }>
            <PhoneInput
              inputClass={`form-control ${errorState.number ? "error" : ""}`}
              // onFocus={() => setFocusState((prev) => { return { ...prev, contactNo: true } })}
              // onBlur={() => setFocusState((prev) => { return { ...prev, contactNo: false } })}
              country={'au'}
              placeholder="00 000 000"
              countryCodeEditable={false}
              masks={{ au: '... ... ....', in: '...........' }}
              enableAreaCodeStretch
              value={number}
              onChange={(phone) => {
                setNumber('+' + phone)
                setErrorState(prev => { return { ...prev, number: false } })
              }}
            />
          </div>
          <div className="form-floating mb-3 pb-3">
            <input
              type={pwdType}
              className={`form-control ${errorState.password ? "error" : ""}`}
              id="floatingInputpwd1"
              placeholder="Enter Password"
              name='password'
              onChange={(e) => {
                setErrorState(prev => { return { ...prev, password: false } })
                setPwd(e.target.value);
              }} />
            <label htmlFor="floatingInputpwd">{STRING_CONSTANTS.formLabelString.label_password_plc}</label>
            <span className="input-group-text">
              {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <g opacity="0.5">
                  <path d="M18.2493 7.00006C18.2493 7.69042 17.6896 8.25006 16.9993 8.25006C16.3089 8.25006 15.7493 7.69042 15.7493 7.00006C15.7493 6.30971 16.3089 5.75006 16.9993 5.75006C17.6896 5.75006 18.2493 6.30971 18.2493 7.00006ZM15.4992 2.05005C11.885 2.05005 8.94922 4.98584 8.94922 8.60005C8.94922 8.98726 8.99939 9.36041 9.05968 9.70406C9.07749 9.80553 9.04493 9.89368 8.99046 9.94815L2.75467 16.1839C2.23895 16.6997 1.94922 17.3991 1.94922 18.1285V20.3C1.94922 21.2665 2.73272 22.05 3.69922 22.05H6.19922C7.16572 22.05 7.94922 21.2665 7.94922 20.3V19.05H9.69922C10.3896 19.05 10.9492 18.4904 10.9492 17.8V16.05H12.6992C13.3741 16.05 13.9241 15.5152 13.9484 14.8463C14.4451 14.9741 14.9689 15.05 15.4992 15.05C19.1134 15.05 22.0492 12.1143 22.0492 8.50005C22.0492 4.8689 19.0963 2.05005 15.4992 2.05005ZM10.4492 8.60005C10.4492 5.81426 12.7134 3.55005 15.4992 3.55005C18.3021 3.55005 20.5492 5.7312 20.5492 8.50005C20.5492 11.2858 18.285 13.55 15.4992 13.55C14.8199 13.55 14.1206 13.379 13.4947 13.1107C13.2629 13.0114 12.9968 13.0351 12.7864 13.1739C12.5759 13.3127 12.4492 13.5479 12.4492 13.8V14.55H10.6992C10.0089 14.55 9.44922 15.1097 9.44922 15.8V17.55H7.69922C7.00886 17.55 6.44922 18.1097 6.44922 18.8V20.3C6.44922 20.4381 6.33729 20.55 6.19922 20.55H3.69922C3.56115 20.55 3.44922 20.4381 3.44922 20.3V18.1285C3.44922 17.797 3.58091 17.479 3.81534 17.2446L10.0511 11.0088C10.4695 10.5904 10.6349 10.002 10.5371 9.44485C10.4834 9.13889 10.4492 8.86245 10.4492 8.60005Z" fill="white" />
                </g>
              </svg> */}
              <img src={UtilityFunctions.getImageURL() + "key.svg"} alt="" />
              <img src={UtilityFunctions.getImageURL() + "key-fill.svg"} alt="" />
              {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.94922 8.60005C8.94922 4.98584 11.885 2.05005 15.4992 2.05005C19.0963 2.05005 22.0492 4.8689 22.0492 8.50005C22.0492 12.1143 19.1134 15.05 15.4992 15.05C14.9689 15.05 14.4451 14.9741 13.9484 14.8463C13.9241 15.5152 13.3741 16.05 12.6992 16.05H10.9492V17.8C10.9492 18.4904 10.3896 19.05 9.69922 19.05H7.94922V20.3C7.94922 21.2665 7.16572 22.05 6.19922 22.05H3.69922C2.73272 22.05 1.94922 21.2665 1.94922 20.3V18.1285C1.94922 17.3991 2.23895 16.6997 2.75467 16.1839L8.99046 9.94815C9.04493 9.89368 9.07749 9.80553 9.05969 9.70406C8.99939 9.36041 8.94922 8.98726 8.94922 8.60005ZM18.2493 7.00006C18.2493 6.30971 17.6896 5.75006 16.9993 5.75006C16.3089 5.75006 15.7493 6.30971 15.7493 7.00006C15.7493 7.69042 16.3089 8.25006 16.9993 8.25006C17.6896 8.25006 18.2493 7.69042 18.2493 7.00006Z" fill="#38BBF4" />
              </svg> */}
            </span>
            <div onClick={togglePassword} className="viewHide">
              <img src={pwdType === "password" ? UtilityFunctions.getImageURL() + 'eyeOff.png' : UtilityFunctions.getImageURL() + 'eyeOn.png'} alt="" />
            </div>
          </div>
          <div className="d-grid d-md-flex gap-4 align-items-center">
            <Button className='border-0 bg-transparent cancel-button w-100' variant="primary" onClick={() => {
              props.handleClose()
              setNumber("")
              setErrorState(initialErrorState)
            }}>
              Cancel
            </Button>
            <Button
              className="border-0 w-100"
              variant="primary"
              onClick={updateHandler}
            >
              Update
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal >

  )
}
export default withRouter(UpdateNumber);