import { Action } from "../actions/index";
import { ActionType } from "../constants";
import { get } from "lodash";

const initialState = {
  showSidebarOnMobileView: false,
  show_sidebar: false,
  sidebar_name: "",
  uniqueId: "",
  uniqueemployeeId: ""
};

const reducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.SET_SHOW_SIDEBAR: {
      state = { ...state, showSidebarOnMobileView: get(action, "item") };
      return state;
    }
    case ActionType.SET_SIDEBAR: {
      state = {
        ...state,
        sidebar_name: get(action, "item.sidebar_name"),
        show_sidebar: get(action, "item.show_sidebar"),
        uniqueId: get(action, "item.uniqueId"),
        uniqueemployeeId: get(action, "item.uniqueemployeeId"),

      };
      return state;
    }

    default:
      return state;
  }
};

export default reducer;
