import React, { useEffect, useState } from "react"
import { withRouter } from "react-router-dom"
import ProfileWrapper from "../../common/Profile-Wrapper";
import Resume from "./Resume";
import Introduction from "./Introduction";
import IntroductoryVideo from "./Video";
import Category from "./Category";
import Subcategory from "./Subcategory";
import Location from "./Location";
import Experience from "./Experience";
import Salary from "./Salary";
import CurrentEmployer from "./CurrentEmployer";
import HideProfile from "./HideProfile";
//import Completion from "./Completion"
import { cookieService, userService } from "../../_services";
import { STRING_CONSTANTS } from "../../utils/constants/stringConstants";

const EmployeeProfileCompletion = (props) => {
  const [step, setStep] = useState(1);
  const [userData, setUserData] = useState<any>()
  useEffect(() => {
    if (props?.location?.state?.updateProfile) {
      setStep(props?.location?.state?.step)
    }
  }, [props?.location?.state?.updateProfile])

  useEffect(() => {
    if (step <= 9) {
      userService.getUserData()
        .then(res => {
          cookieService.saveCookie(STRING_CONSTANTS.cookieStrings.user, res)
          setUserData(res)
        })
    }
  }, [step])

  const nav = {
    next() {
      step <= 10 && setStep(prev => prev + 1)
    },
    back() {
      step >= 1 && setStep(prev => prev - 1)
    }
  }
  return <>
    <div style={{ position: 'absolute' }}></div>
    <ProfileWrapper>
      {step === 1 && <Resume props={props} nav={nav} step={step} setStep={setStep} userData={userData} />}
      {step === 2 && <Introduction props={props} nav={nav} step={step} setStep={setStep} userData={userData} />}
      {step === 3 && <IntroductoryVideo props={props} nav={nav} step={step} setStep={setStep} userData={userData} />}
      {step === 4 && <Category props={props} nav={nav} step={step} setStep={setStep} userData={userData} setUserData={setUserData} />}
      {step === 5 && <Subcategory props={props} nav={nav} step={step} setStep={setStep} userData={userData} />}
      {step === 6 && <Location props={props} nav={nav} step={step} setStep={setStep} userData={userData} />}
      {step === 7 && <Experience props={props} nav={nav} step={step} setStep={setStep} userData={userData} />}
      {step === 8 && <Salary props={props} nav={nav} step={step} setStep={setStep} userData={userData} />}
      {step === 9 && <CurrentEmployer props={props} nav={nav} step={step} setStep={setStep} userData={userData} />}
      {step === 10 && <HideProfile props={props} nav={nav} step={step} setStep={setStep} userData={userData} />}
      {/* {step === 11 && <Completion props={props} nav={nav} step={step} setStep={setStep} userData={userData} />} */}
      {/* {step === 11 && <PreviousEmployers props={props} nav={nav} step={step} setStep={setStep} userData={userData} />} */}
      {step === 11 &&  props.history.push(STRING_CONSTANTS.navigationString.advanced_profile)}
    </ProfileWrapper>
  </>
}

export default EmployeeProfileCompletion;