import React from "react";
import InterviewSimulator from "../../../components/InterviewSimulator/InterviewSimulator";

function InterviewSimulatorPage() {
  return (
    <div>
      <InterviewSimulator />
    </div>
  );
}

export default InterviewSimulatorPage;
