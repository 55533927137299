import React from 'react'
import Header from '../../components/Header/Header';
import Action from '../../components/Action/Action';
import Footer from '../../components/Footer/Footer';
import Mission from '../../components/Mission/Mission';
import './Competition.css'

function Competition() {
  return (
    <div>
    <Header />
    <div className='banner-wrap-competition'>
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-8 col-md-12 banner-content">
          <div className="title">
          MPLOI Instagram <br /> Influencer Competition
          </div>
          <div className="sub-description">Attention all Visionary Creators and Influential Storytellers!</div>
          <div className="description">
          MPLOI, the revolutionary AI-powered job matching platform, is calling on YOU to showcase your talents and make a real impact on local businesses. <br /> <br />

Picture this: You craft a mind-blowing 60-second video that highlights the game-changing benefits of MPLOI for small to medium enterprises. Your masterpiece goes viral, racking up likes and comments, while simultaneously helping a deserving business connect with their dream team. It's a win-win situation!

          </div>
         
          {/* <div className="banner-buttons d-flex flex-column flex-md-row gap-2 mt-4">
            <button className="btn-dark">Find a Job <img src='/assets/images/search-icon-btn.png' alt=''/></button>
            <button className="btn-light">Find Staff Members <img src='/assets/images/member-icon-btn.png' alt=''/></button>
          </div> */}
         
        </div>
        <div className="col-lg-4 col-md-12 text-right">
        <div className='banner-right-img'>
        <img src="/assets/images/competition-banner.png" alt=''  />
        </div>
        
        </div>
      </div>
    </div>
    </div>
    <Action />
    <Mission />
    <div className='launching-date-wrap'>
      <div className='container-fluid'>
        <div className="row">
          <div className='col-lg-6 col-md-12'>
            <div className='left-content-wrap'>
              <div className='top-button'>
                <a href="" > MPLOI Challenge Dates Coming Soon..</a>
              </div>
              <div className='description'>
              So, what are you waiting for? Grab your camera, unleash your creativity, and join the #MPLOIChallenge today! Together, we can make a real difference in the lives of small business owners and job seekers alike. <br /><br />
              Get started and learn more about the incredible features that make MPLOI a game-changer.
              </div>  
              <div className='bottom-btn'>
                  <a href="/signup" target='_blank'>Learn More  <img src="/assets/images/arrow-up-left-black.svg" alt=""/></a>
              </div>
              <div className='bottom-description'>
              All clips must adhere to the Terms and Conditions set out here.
              Oh and please don't refer to MPLOI as Tinder for jobs.....

              </div>
            </div>
          </div>
          <div className='col-lg-6 col-md-12 text-right'>
            <div className='right-img'>
              <img src='/assets/images/coming-img.png' alt=''/>
            </div>
          </div>
        </div>
      </div>
    </div>
   
    <Footer />
    </div>
    
  )
}

export default Competition