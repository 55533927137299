import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import Sidebar from "../../../components/sidebar";
import { api } from "../../../middleware/api";
import { actionCreators, AppDispatch, State } from "../../../store";
import Employees from './Employees';
import Courses from './Courses';
import Jobs from './jobs';
import MapView from './mapView';
import JobsFilterSort from './jobsFilterSort'
import ChatRequest from '../../../pages/chatMangemt/ChatRequest'
import { STRING_CONSTANTS } from "../../../utils/constants/stringConstants";
import { END_POINTS } from "../../../utils/constants/endPoints";
import GuestPrompt from "../../../components/guestPrompt"
import { cookieService, userService } from "../../../_services";
import { UtilityFunctions } from "../../../utils/UtilityFunctions";
import { Button, Modal } from "react-bootstrap";
import './subscription.css';
import Switch from "react-switch";

import { checkFeatureFlag } from "../../../utils/UtilityFunctions";

import SubscriptionModal from './SubscriptionModal';
import { fetchSubscriptionChose, fetchSubscriptionMonth, fetchSubscriptionYear } from "../../../store/reducers/subscriptionPlans/apiThunk";
import moment from "moment";

const debounce = require("debounce-promise");

const Home = (props: any) => {
  const dispatch = useDispatch<AppDispatch>();
  const state = useSelector((state: State) => state);
  const { setShowSideBarOnMobileView, setSidebar, setJobsList, setJobsSearch, setJobsFetchState, updateJobList } = bindActionCreators(actionCreators, dispatch);
  const [selectedEmployee, setSelectedEmployee] = useState({});
  const [selectedJob, setSelectedJob] = useState({});
  const [showJobFilter, setShowJobFilter] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [selectedJobID, setSelectedJobID] = useState<any>();
  const [closest, setClosest] = useState(false);
  const [newestJob, setNewestJob] = useState(true);
  const [employeeJobList, setEmployeeJobList] = useState();
  const [employeeObject, setEmployeeObject] = useState(null);


  const [chatRequestData, setChatRequestData] = useState()
  const [showRequests, setShowRequests] = useState(false)

  const [jobList, setJobList] = useState([])  //list of all jobs set by its child jobs.tsx
  const inputRef = useRef<any>() //to clear input of search bar
  const [requestSent, setRequestSent] = useState();
  const [requestReceived, setRequestReceived] = useState();
  const [requestDistance, setRequestDistance] = useState();
  const [fromDate, setFromDate] = useState<Date>();
  const [toDate, setToDate] = useState<Date>();
  const [hasMore, setHasMore] = useState(false)
  const userType = cookieService.getCookie(STRING_CONSTANTS.cookieStrings.user);
  const isUserLoggedIn = cookieService.getCookie(STRING_CONSTANTS.cookieStrings.token)
  const [showGuestAccessPrompt, setShowGuestAccessPrompt] = useState(false);
  const [subsModal, setSubsModal] = useState(false);
  const [isSubPurchased, setIsSubPurchased] = useState(false)

  const tabsData = [
    { id: 0, tabName: userType?.user?.type === STRING_CONSTANTS.userType.employer ? "Employee" : "Employer" },
    { id: 1, tabName: "Map" },
    { id: 2, tabName: "Jobs" },
    
  ];
  const userTypeCookieval = cookieService.getCookie(STRING_CONSTANTS.cookieStrings.user)
  const ffCourses = checkFeatureFlag(userTypeCookieval, 'courses');
  if (ffCourses) {
    if (userType?.user?.type !== STRING_CONSTANTS.userType.employer) {
      tabsData.push({ id: 3, tabName: "Courses" });
    }
  }

  useEffect(() => {
    const fetchDataChose = async () => {
      try {
        const res = await dispatch(fetchSubscriptionChose());
        if (userType?.user?.type === STRING_CONSTANTS.userType.employer) {
          if (Object.keys(res.payload).length === 0) {
            setSubsModal(true);
          } else {
            setSubsModal(false);
          }
        } else {
          setSubsModal(false);
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    };

    fetchDataChose();
  }, []);
  const tabClick = (index) => {
    setActiveIndex(index);
  };

  // UNREAD CHAT REQUESTS CALLED ONLY WHEN DASHBOARD IS ACCESSED 
  useEffect(() => {
    if (activeIndex === 0) {
      if (isSubPurchased) {
        if (!localStorage.getItem('chat_request_sent_date')) {
          localStorage.setItem('chat_request_sent_date', moment(Date.now()))
          getChatRequests()
        } else {
          let old_time = moment(localStorage.getItem('chat_request_sent_date')).add(12, 'hours');
          if (old_time < moment(Date.now())) {
            localStorage.setItem('chat_request_sent_date', moment(Date.now()))
            getChatRequests()
          }
        }
      } else {
        getChatRequests();
      }
    }
  }, [isSubPurchased])
  // console.log("jy654refghjk",state)
  //JOB LIST API IS CALLED WHEN JOBS TAB IS CLICKED
  useEffect(() => {
    if (activeIndex == 2) {
      if (userType?.user?.type === STRING_CONSTANTS.userType.employer) {
        userService.fetchAllJobs(END_POINTS.filter_jobs, {
          filter: {
            created_at__date__gte: "",
            created_at__date__lte: "",
          },
          sort: true,
          search: "",
        }, (res) => {
          setJobsList(res.data)
        });
      } else {
        document.body.classList.add("loading-indicator");
        userService.fetchAllJobs('jobs/?limit=10&offset=0&fetchAll=true', {
          filter: {
            created_at__date__gte: "",
            created_at__date__lte: "",
          },
          sort: true,
          search: "",
        }, (res) => {
          if (res?.data?.next) {
            setHasMore(true)
          } else {
            setHasMore(false)
          }
          setJobsList(res?.data?.results)
        });
      }

      setFromDate(null)
      setToDate(null)
    }

  }, [activeIndex])

  //JOB LIST RE-RENDERS WITH SEARCH TERM CHANGES
  useEffect(() => {
    if (activeIndex === 2) {
      if (userType?.user?.type === STRING_CONSTANTS.userType.employer) {
        userService.fetchAllJobs(END_POINTS.filter_jobs, state.jobFilterSearch, (res) => {
          setJobsList(res.data)
        });
      } else {
        let store = { ...state.jobFilterSearch };
        store['onlyForJob'] = true;
        userService.fetchAllJobs(`jobs/?limit=10&offset=0&fetchAll=true`, store, (res) => {
          setJobsList(res?.data?.results)
          if (res?.data?.next) {
            setHasMore(true)
          } else {
            setHasMore(false)
          }
        });
      }
    }
  }, [state.jobFilterSearch.search])
  // useEffect(() => {
  //   alert(requestSent)
  // }, [requestSent])
  useEffect(() => {
    if (state.jobFilterSearch.offset > 0) {
      let store = { ...state.jobFilterSearch };
      store['onlyForJob'] = true;
      document.body.classList.add("loading-indicator");
      userService.fetchAllJobs(`jobs/?limit=10&offset=${state.jobFilterSearch.offset}&fetchAll=true`, store, (res) => {
        //setJobsList(res?.data?.results)
        if (res?.data?.results.length > 0) {
          updateJobList([...state.jobsList, ...res?.data?.results])
        }
        if (res?.data?.next) {
          setHasMore(true)
        } else {
          setHasMore(false)
        }
        console.log(res?.data)
      });
    }
  }, [state.jobFilterSearch.offset])

  const getChatRequests = () => {
    if (props?.isUserLoggedIn) {
      api.get('chat/unread-chat-request/', null)
        .then(res => {
          if (res.length) {
            setChatRequestData(res)
            if (isSubPurchased) {
              setShowRequests(true)
            } else {
              setShowRequests(false)
            }


          }
          else {
            setShowRequests(false)
            userService.getUserData()
              .then((res) => cookieService.saveCookie(STRING_CONSTANTS.cookieStrings.user, res))
          }
        })
    }
  }
  const getUserSubscription = () => {
    if (props?.isUserLoggedIn) {
      api.get('subscription/', {})
        .then(res => {
          if (res?.active) {
            setIsSubPurchased(true)
          }
        }).catch((err) => {
          console.log(err)
        })
    }
  }
  useEffect(() => {
    setShowJobFilter(false)
    getUserSubscription()
    if (activeIndex != 2) { setJobsSearch(""); setJobsFetchState(0) }
  }, [activeIndex])

  useEffect(() => {
    if (window?.location.search == '?subscription=card') {
      setSubsModal(true)
    }
    if (window?.location.search == '?subscription=true') {
      setSubsModal(true)
    }
  }, [])

  const debounceClick = debounce((value) => {
    setJobsSearch(value)
  }, 600);

  return (<>
    {/* showRequests ?
    <div className="chat_request">
      <>
        <ChatRequest
          getChatRequests={getChatRequests}
          setShowRequests={setShowRequests}
          chatRequestData={chatRequestData}
          setSelectedEmployee={setSelectedEmployee}
          setSelectedJobID={setSelectedJobID}
          selectedJobID={selectedJobID}
          history={props.history}
        />
        <Sidebar
          history={props.history}
          selectedEmployee={selectedEmployee}
          selectedJobID={selectedJobID}
          getChatRequests={getChatRequests}
          setSelectedEmployee={setSelectedEmployee}
          setEmployeeObject={setEmployeeObject}
        />
      </>
    </div> : */}
    <div id="content-wrapper" className={`d-flex flex-column ${state.common.showSidebarOnMobileView ? "active" : ""}`}>
      <div id="content">
        <div className="container-fluid">
          <span className={`mobile-menu ${state.common.showSidebarOnMobileView ? "active" : ""}`} onClick={() => setShowSideBarOnMobileView(!state.common.showSidebarOnMobileView)}
          >
            <span></span>
            <span></span>
            <span></span>
          </span>
          <div className='title-row'>
            <h1>Home</h1>
            {/* SEARCH BAR  */}
            {activeIndex === 2 ?
              <div className="form-floating job-search-input ms-auto me-3">

                <input
                  ref={inputRef}
                  type='text'
                  className="form-control"
                  placeholder="Search Job"
                  onChange={(e) => debounceClick(e.target.value)}
                />
                <label htmlFor="floatingInput">{userType?.user?.type === STRING_CONSTANTS.userType.employer ? "Search Job..." : "Search all Companies Listed..."}</label>
                <span className="input-group-text">
                  {/* <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_f_4_8)">
                      <circle cx="12" cy="12" r="7" stroke="#D0D0D0" strokeWidth="2" />
                      <path d="M21 21L18 18" stroke="#D0D0D0" strokeWidth="2" strokeLinecap="round" />
                    </g>
                    <circle cx="12" cy="12" r="7" stroke="#D0D0D0" strokeWidth="2" />
                    <path d="M21 21L18 18" stroke="#D0D0D0" strokeWidth="2" strokeLinecap="round" />
                    <defs>
                      <filter id="filter0_f_4_8" x="-3" y="-3" width="32" height="32" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                        <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_4_8" />
                      </filter>
                    </defs>
                  </svg> */}
                  <img src={UtilityFunctions.getImageURL() + "search-with-shadow.svg"} alt="" />
                  {/* <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_f_4_8)">
                      <circle cx="12" cy="12" r="7" stroke="#D0D0D0" strokeWidth="2" />
                      <path d="M21 21L18 18" stroke="#D0D0D0" strokeWidth="2" strokeLinecap="round" />
                    </g>
                    <circle cx="12" cy="12" r="7" stroke="#D0D0D0" strokeWidth="2" />
                    <path d="M21 21L18 18" stroke="#D0D0D0" strokeWidth="2" strokeLinecap="round" />
                    <defs>
                      <filter id="filter0_f_4_8" x="-3" y="-3" width="32" height="32" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                        <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_4_8" />
                      </filter>
                    </defs>
                  </svg> */}
                  <img src={UtilityFunctions.getImageURL() + "search-with-shadow-fill.svg"} alt="" />
                </span>
                {userType?.user?.type === STRING_CONSTANTS.userType.employer && state.jobFilterSearch.search.length && state.jobsList.length === 0 ? <div className="v-dropdonw-search p-0" style={{ position: 'initial' }}>
                  <div className="filter-dropdonw-set-blank">
                    <div className="text-center">
                      {/* <svg width="40" height="8" viewBox="0 0 40 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="4" cy="4" r="3.5" fill="white" fill-opacity="0.2" stroke="#38BBF4" />
                        <circle cx="20" cy="4" r="3.5" fill="white" fill-opacity="0.2" stroke="#38BBF4" />
                        <circle cx="36" cy="4" r="3.5" fill="white" fill-opacity="0.2" stroke="#38BBF4" />
                      </svg> */}
                      <img src={UtilityFunctions.getImageURL() + "three-dots-blue.svg"} alt="" />
                      <p>No results have been found matching "{state.jobFilterSearch.search}"</p>
                      <button type="button" onClick={() => {
                        // inputRef.current.focus()
                        // inputRef.current.value = ''
                        // setJobsSearch("");
                      }}>
                        Search Again
                      </button>
                    </div>
                  </div>
                </div> : ""}
                {userType?.user?.type === STRING_CONSTANTS.userType.employee && state.jobFilterSearch.search.length && state.jobsList.length === 0 ? <div className="v-dropdonw-search p-0" style={{ position: 'initial' }}>
                  <div className="filter-dropdonw-set-blank">
                    <div className="text-center">
                      {/* <svg width="40" height="8" viewBox="0 0 40 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="4" cy="4" r="3.5" fill="white" fill-opacity="0.2" stroke="#38BBF4" />
                        <circle cx="20" cy="4" r="3.5" fill="white" fill-opacity="0.2" stroke="#38BBF4" />
                        <circle cx="36" cy="4" r="3.5" fill="white" fill-opacity="0.2" stroke="#38BBF4" />
                      </svg> */}
                      <img src={UtilityFunctions.getImageURL() + "three-dots-blue.svg"} alt="" />
                      <p>No results have been found matching "{state.jobFilterSearch.search}"</p>
                      <button type="button" onClick={() => {
                        // inputRef.current.focus()
                        // inputRef.current.value = ''
                        // setJobsSearch("");
                      }}>
                        Search Again
                      </button>
                    </div>
                  </div>
                </div> : ""}
              </div> : <></>}
            {/* FILTER BUTTON  */}
            {(userType?.user?.type === STRING_CONSTANTS.userType.employer || (userType?.user?.type === STRING_CONSTANTS.userType.employee && activeIndex === 0)) && <div className="main-dropdonw">
              <a href='#'
                data-bs-toggle={activeIndex == 0 || activeIndex == 1 ? "modal" : ''}
                data-bs-target={activeIndex == 0 || activeIndex == 1 ? "#staticBackdrop" : ''}
                onClick={(e) => {
                  e.preventDefault()
                  if (activeIndex == 0 || activeIndex == 1)
                    setSidebar({ sidebar_name: 'employee-filter', show_sidebar: true })
                  else if (activeIndex == 2) {
                    setShowJobFilter(!showJobFilter)
                  }
                }}>
                {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"  >
                  <path d="M13.5 16C13.9142 16 14.25 16.3358 14.25 16.75C14.25 17.1642 13.9142 17.5 13.5 17.5H10.5C10.0858 17.5 9.75 17.1642 9.75 16.75C9.75 16.3358 10.0858 16 10.5 16H13.5ZM16.5 11C16.9142 11 17.25 11.3358 17.25 11.75C17.25 12.1642 16.9142 12.5 16.5 12.5H7.5C7.08579 12.5 6.75 12.1642 6.75 11.75C6.75 11.3358 7.08579 11 7.5 11H16.5ZM19.5 6C19.9142 6 20.25 6.33579 20.25 6.75C20.25 7.16421 19.9142 7.5 19.5 7.5H4.5C4.08579 7.5 3.75 7.16421 3.75 6.75C3.75 6.33579 4.08579 6 4.5 6H19.5Z" fill="#D0D0D0" />
                </svg> */}
                <img src={UtilityFunctions.getImageURL() + "filter.svg"} alt="" />
              </a>
              {showJobFilter && activeIndex === 2 &&
                <JobsFilterSort
                  showJobFilter={showJobFilter}
                  newestJob={newestJob}
                  setNewestJob={setNewestJob}
                  setJobList={setJobList}
                  jobList={jobList}
                  setShowJobFilter={setShowJobFilter}
                  fromDate={fromDate}
                  setFromDate={setFromDate}
                  toDate={toDate}
                  setToDate={setToDate}
                />}
            </div>}
            {!isUserLoggedIn &&
              <a href='#'
                onClick={(e) => {
                  e.preventDefault()
                  setShowGuestAccessPrompt(true)
                }}>
                <img src={UtilityFunctions.getImageURL() + "filter.svg"} alt="" />
              </a>
            }
          </div>
          <div className='btn-row'>
            <ul>
              {tabsData.map((tab) => {
                return (
                  <>
                    {tab.id == 0 &&
                      <li key={tab.id} onClick={() => tabClick(tab.id)}>
                        <a href='#' className={`btn-user ${tab.id == activeIndex ? 'active' : ''}`}>
                          {userType?.user?.type === STRING_CONSTANTS.userType.employer ?
                            <i>
                              {/* <svg width="29" height="32" viewBox="0 0 29 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g filter="url(#filter0_d_5973_4188)">
                                  <path d="M19.7545 18.0002C20.9966 18.0002 22.0034 19.007 22.0034 20.2491V20.8245C22.0034 21.7188 21.6838 22.5836 21.1023 23.263C19.5329 25.0965 17.1457 26.0013 14.0004 26.0013C10.8545 26.0013 8.46849 25.0962 6.90219 23.2619C6.32242 22.583 6.00391 21.7195 6.00391 20.8267V20.2491C6.00391 19.007 7.01076 18.0002 8.25278 18.0002H19.7545ZM19.7545 19.5002H8.25278C7.83919 19.5002 7.50391 19.8355 7.50391 20.2491V20.8267C7.50391 21.3624 7.69502 21.8805 8.04287 22.2878C9.29618 23.7555 11.2621 24.5013 14.0004 24.5013C16.7387 24.5013 18.7063 23.7555 19.9627 22.2876C20.3117 21.8799 20.5034 21.361 20.5034 20.8245V20.2491C20.5034 19.8355 20.1681 19.5002 19.7545 19.5002ZM14.0004 6.00488C16.7618 6.00488 19.0004 8.24346 19.0004 11.0049C19.0004 13.7663 16.7618 16.0049 14.0004 16.0049C11.2389 16.0049 9.00036 13.7663 9.00036 11.0049C9.00036 8.24346 11.2389 6.00488 14.0004 6.00488ZM14.0004 7.50488C12.0674 7.50488 10.5004 9.07189 10.5004 11.0049C10.5004 12.9379 12.0674 14.5049 14.0004 14.5049C15.9334 14.5049 17.5004 12.9379 17.5004 11.0049C17.5004 9.07189 15.9334 7.50488 14.0004 7.50488Z" fill="white" />
                                </g>
                                <defs>
                                  <filter id="filter0_d_5973_4188" x="0.00390625" y="0.00488281" width="28" height="31.9961" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                    <feOffset />
                                    <feGaussianBlur stdDeviation="3" />
                                    <feComposite in2="hardAlpha" operator="out" />
                                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.44 0" />
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5973_4188" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5973_4188" result="shape" />
                                  </filter>
                                </defs>
                              </svg> */}
                              {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.7545 14.0002C18.9966 14.0002 20.0034 15.007 20.0034 16.2491V17.1675C20.0034 17.7409 19.8242 18.2999 19.4908 18.7664C17.9449 20.9296 15.4206 22.0013 12.0004 22.0013C8.5794 22.0013 6.05643 20.9292 4.51427 18.7648C4.18231 18.2989 4.00391 17.7411 4.00391 17.169V16.2491C4.00391 15.007 5.01076 14.0002 6.25278 14.0002H17.7545ZM12.0004 2.00488C14.7618 2.00488 17.0004 4.24346 17.0004 7.00488C17.0004 9.76631 14.7618 12.0049 12.0004 12.0049C9.23894 12.0049 7.00036 9.76631 7.00036 7.00488C7.00036 4.24346 9.23894 2.00488 12.0004 2.00488Z" fill="#FFB800" />
                              </svg> */}
                              <img src={UtilityFunctions.getImageURL() + "user-icon-blank.svg"} alt="" />
                              <img src={UtilityFunctions.getImageURL() + "user-ico-fill-yellow.svg"} alt="" />
                            </i> :
                            <i>
                              {/* <svg width="30" height="29" viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g filter="url(#filter0_d_3401_9681)">
                                  <path d="M11.75 6H18.25C18.6297 6 18.9435 6.28215 18.9932 6.64823L19 6.75V10H20.75C22.5449 10 24 11.4551 24 13.25V19.75C24 21.5449 22.5449 23 20.75 23H9.25C7.45507 23 6 21.5449 6 19.75V13.25C6 11.4551 7.45507 10 9.25 10H11V6.75C11 6.3703 11.2822 6.05651 11.6482 6.00685L11.75 6H18.25H11.75ZM20.75 11.5H9.25C8.2835 11.5 7.5 12.2835 7.5 13.25V19.75C7.5 20.7165 8.2835 21.5 9.25 21.5H20.75C21.7165 21.5 22.5 20.7165 22.5 19.75V13.25C22.5 12.2835 21.7165 11.5 20.75 11.5ZM17.5 7.5H12.5V10H17.5V7.5Z" fill="white" />
                                </g>
                                <defs>
                                  <filter id="filter0_d_3401_9681" x="0" y="0" width="30" height="29" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                    <feOffset />
                                    <feGaussianBlur stdDeviation="3" />
                                    <feComposite in2="hardAlpha" operator="out" />
                                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.44 0" />
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3401_9681" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3401_9681" result="shape" />
                                  </filter>
                                </defs>
                              </svg>*/}
                              {/* <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.25 0C12.6642 0 13 0.335786 13 0.75V4H14.75C16.5449 4 18 5.45507 18 7.25V13.75C18 15.5449 16.5449 17 14.75 17H3.25C1.45507 17 0 15.5449 0 13.75V7.25C0 5.45507 1.45507 4 3.25 4H5V0.75C5 0.370304 5.28215 0.0565091 5.64823 0.00684667L5.75 0H12.25ZM11.5 1.5H6.5V4H11.5V1.5Z" fill="#FFB800" />
                              </svg>  */}
                              <img src={UtilityFunctions.getImageURL() + "brief.svg"} alt="" />
                              <img src={UtilityFunctions.getImageURL() + "briefcase-tab-fill.svg"} alt="" />
                            </i>
                          }
                          <span>{tab.tabName}</span>
                        </a>
                      </li>}
                    {tab.id == 1 &&
                      <li key={tab.id} onClick={() => { props?.isUserLoggedIn ? tabClick(tab.id) : props?.setShowGuestAccessPrompt(true) }}>
                        <a href='#' className={`btn-locatn btn-maps ${tab.id == activeIndex ? 'active' : ''}`}>
                          <i>
                            {/* <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <g filter="url(#filter0_d_3401_9676)">
                                <path d="M12.2034 6.9993L12.2504 6.99805L12.2962 6.99926C12.3186 7.00055 12.3411 7.00288 12.3636 7.00628L12.3796 7.01009C12.465 7.02381 12.5499 7.05432 12.6308 7.10224L12.6827 7.13578L17.753 10.701L22.82 7.13913C23.2878 6.81034 23.9215 7.10586 23.9944 7.6482L24.0014 7.75269V18.3852C24.0014 18.5945 23.914 18.7926 23.7632 18.9334L23.6827 18.9988L18.1827 22.8652C17.9158 23.0528 17.5948 23.0371 17.3549 22.8883L12.25 19.297L7.18035 22.8618C6.71263 23.1906 6.07889 22.8951 6.00595 22.3527L5.99902 22.2483V11.6157C5.99902 11.4064 6.08638 11.2083 6.23714 11.0676L6.3177 11.0022L11.8177 7.13578C11.8817 7.09079 11.9488 7.05749 12.0172 7.03491L12.1396 7.0069L12.2034 6.9993ZM22.5014 9.1967L18.5014 12.0086V20.8076L22.5014 17.9957V9.1967ZM11.499 9.19335L7.49902 12.0053V20.8042L11.499 17.9923V9.19335ZM13.0014 9.19335V17.9923L17.0014 20.8042V12.0053L13.0014 9.19335Z" fill="white" />
                              </g>
                              <defs>
                                <filter id="filter0_d_3401_9676" x="-0.000976562" y="0.998047" width="30.002" height="28.0049" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                  <feOffset />
                                  <feGaussianBlur stdDeviation="3" />
                                  <feComposite in2="hardAlpha" operator="out" />
                                  <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.44 0" />
                                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3401_9676" />
                                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3401_9676" result="shape" />
                                </filter>
                              </defs>
                            </svg> */}
                            {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M8.50002 4.35798V16.823L4.18035 19.8614C3.71263 20.1902 3.07889 19.8947 3.00595 19.3523L2.99902 19.2478V8.61531C2.99902 8.40599 3.08638 8.20791 3.23714 8.06714L3.3177 8.00175L8.50002 4.35798ZM20.9944 4.6478L21.0014 4.75229V15.3848C21.0014 15.5941 20.914 15.7922 20.7632 15.933L20.6827 15.9984L15.5 19.64V7.17398L19.82 4.13872C20.2878 3.80993 20.9215 4.10546 20.9944 4.6478ZM10 4.35898L14 7.17098V19.638L10 16.824V4.35898Z" fill="#F495D3" />
                            </svg>  */}
                            <img src={UtilityFunctions.getImageURL() + "tab-map-defalt.svg"} alt="" />
                            <img src={UtilityFunctions.getImageURL() + "tab-map-fill.svg"} alt="" />
                          </i>  <span>Map</span>
                        </a>
                      </li>}
                    {tab.id == 2 &&
                      <li key={tab.id} onClick={() => {
                        if (props?.isUserLoggedIn) { tabClick(tab.id); setNewestJob(true) }
                        else props?.setShowGuestAccessPrompt(true)
                      }}
                      >
                        <a href='#' className={`btn-locatn btn-job ${tab.id == activeIndex ? 'active' : ''}`}>
                          {userType?.user?.type === STRING_CONSTANTS.userType.employer ?
                            <>
                              <i>
                                {/* <svg width="30" height="29" viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <g filter="url(#filter0_d_3401_9681)">
                                    <path d="M11.75 6H18.25C18.6297 6 18.9435 6.28215 18.9932 6.64823L19 6.75V10H20.75C22.5449 10 24 11.4551 24 13.25V19.75C24 21.5449 22.5449 23 20.75 23H9.25C7.45507 23 6 21.5449 6 19.75V13.25C6 11.4551 7.45507 10 9.25 10H11V6.75C11 6.3703 11.2822 6.05651 11.6482 6.00685L11.75 6H18.25H11.75ZM20.75 11.5H9.25C8.2835 11.5 7.5 12.2835 7.5 13.25V19.75C7.5 20.7165 8.2835 21.5 9.25 21.5H20.75C21.7165 21.5 22.5 20.7165 22.5 19.75V13.25C22.5 12.2835 21.7165 11.5 20.75 11.5ZM17.5 7.5H12.5V10H17.5V7.5Z" fill="white" />
                                  </g>
                                  <defs>
                                    <filter id="filter0_d_3401_9681" x="0" y="0" width="30" height="29" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                      <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                      <feOffset />
                                      <feGaussianBlur stdDeviation="3" />
                                      <feComposite in2="hardAlpha" operator="out" />
                                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.44 0" />
                                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3401_9681" />
                                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3401_9681" result="shape" />
                                    </filter>
                                  </defs>
                                </svg>*/}
                                {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M15.25 3C15.6642 3 16 3.33579 16 3.75V7H17.75C19.5449 7 21 8.45507 21 10.25V16.75C21 18.5449 19.5449 20 17.75 20H6.25C4.45507 20 3 18.5449 3 16.75V10.25C3 8.45507 4.45507 7 6.25 7H8V3.75C8 3.3703 8.28215 3.05651 8.64823 3.00685L8.75 3H15.25ZM14.5 4.5H9.5V7H14.5V4.5Z" fill="#38BBF4" />
                                </svg>  */}
                                <img src={UtilityFunctions.getImageURL() + "tab-building-defalt.svg"} alt="" />
                                <img src={UtilityFunctions.getImageURL() + "tab-building-fill.svg"} alt="" />
                              </i>
                              <span>Jobs</span>
                            </>
                            :
                            <>
                              <i>
                                {/* <svg width="29" height="32" viewBox="0 0 29 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <g filter="url(#filter0_d_13473_23923)">
                                    <path d="M10.5 9.5C9.94772 9.5 9.5 9.94772 9.5 10.5C9.5 11.0523 9.94772 11.5 10.5 11.5C11.0523 11.5 11.5 11.0523 11.5 10.5C11.5 9.94772 11.0523 9.5 10.5 9.5ZM9.5 17.5C9.5 16.9477 9.94772 16.5 10.5 16.5C11.0523 16.5 11.5 16.9477 11.5 17.5C11.5 18.0523 11.0523 18.5 10.5 18.5C9.94772 18.5 9.5 18.0523 9.5 17.5ZM10.5 13C9.94772 13 9.5 13.4477 9.5 14C9.5 14.5523 9.94772 15 10.5 15C11.0523 15 11.5 14.5523 11.5 14C11.5 13.4477 11.0523 13 10.5 13ZM13 10.5C13 9.94772 13.4477 9.5 14 9.5C14.5523 9.5 15 9.94772 15 10.5C15 11.0523 14.5523 11.5 14 11.5C13.4477 11.5 13 11.0523 13 10.5ZM14 16.5C13.4477 16.5 13 16.9477 13 17.5C13 18.0523 13.4477 18.5 14 18.5C14.5523 18.5 15 18.0523 15 17.5C15 16.9477 14.5523 16.5 14 16.5ZM16.5 17.5C16.5 16.9477 16.9477 16.5 17.5 16.5C18.0523 16.5 18.5 16.9477 18.5 17.5C18.5 18.0523 18.0523 18.5 17.5 18.5C16.9477 18.5 16.5 18.0523 16.5 17.5ZM14 13C13.4477 13 13 13.4477 13 14C13 14.5523 13.4477 15 14 15C14.5523 15 15 14.5523 15 14C15 13.4477 14.5523 13 14 13ZM8.25 6C7.00736 6 6 7.00736 6 8.25V24.75C6 25.1642 6.33579 25.5 6.75 25.5H21.2528C21.667 25.5 22.0028 25.1642 22.0028 24.75V15.7493C22.0028 14.5067 20.9954 13.4993 19.7528 13.4993H18.5V8.25C18.5 7.00736 17.4926 6 16.25 6H8.25ZM7.5 8.25C7.5 7.83579 7.83579 7.5 8.25 7.5H16.25C16.6642 7.5 17 7.83579 17 8.25V14.2493C17 14.6635 17.3358 14.9993 17.75 14.9993H19.7528C20.167 14.9993 20.5028 15.3351 20.5028 15.7493V24H18.5V21.25C18.5 20.8358 18.1642 20.5 17.75 20.5H10.25C9.83579 20.5 9.5 20.8358 9.5 21.25V24H7.5V8.25ZM17 22V24H14.75V22H17ZM13.25 22V24H11V22H13.25Z" fill="white" />
                                  </g>
                                  <defs>
                                    <filter id="filter0_d_13473_23923" x="0" y="0" width="28.002" height="31.5" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                      <feOffset />
                                      <feGaussianBlur stdDeviation="3" />
                                      <feComposite in2="hardAlpha" operator="out" />
                                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.44 0" />
                                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_13473_23923" />
                                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_13473_23923" result="shape" />
                                    </filter>
                                  </defs>
                                </svg>*/}
                                {/* <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M2.75 0C1.50736 0 0.5 1.00736 0.5 2.25V18.75C0.5 19.1642 0.835786 19.5 1.25 19.5H3.5V15.25C3.5 14.8358 3.83579 14.5 4.25 14.5H11.75C12.1642 14.5 12.5 14.8358 12.5 15.25V19.5H14.75C15.1642 19.5 15.5 19.1642 15.5 18.75V9.7493C15.5 8.50666 14.4926 7.4993 13.25 7.4993H12.5V2.25C12.5 1.00736 11.4926 0 10.25 0H2.75ZM3.5 4.5C3.5 3.94772 3.94772 3.5 4.5 3.5C5.05228 3.5 5.5 3.94772 5.5 4.5C5.5 5.05228 5.05228 5.5 4.5 5.5C3.94772 5.5 3.5 5.05228 3.5 4.5ZM4.5 10.5C5.05228 10.5 5.5 10.9477 5.5 11.5C5.5 12.0523 5.05228 12.5 4.5 12.5C3.94772 12.5 3.5 12.0523 3.5 11.5C3.5 10.9477 3.94772 10.5 4.5 10.5ZM3.5 8C3.5 7.44772 3.94772 7 4.5 7C5.05228 7 5.5 7.44772 5.5 8C5.5 8.55228 5.05228 9 4.5 9C3.94772 9 3.5 8.55228 3.5 8ZM8 3.5C8.55228 3.5 9 3.94772 9 4.5C9 5.05228 8.55228 5.5 8 5.5C7.44772 5.5 7 5.05228 7 4.5C7 3.94772 7.44772 3.5 8 3.5ZM7 11.5C7 10.9477 7.44772 10.5 8 10.5C8.55228 10.5 9 10.9477 9 11.5C9 12.0523 8.55228 12.5 8 12.5C7.44772 12.5 7 12.0523 7 11.5ZM11.5 10.5C12.0523 10.5 12.5 10.9477 12.5 11.5C12.5 12.0523 12.0523 12.5 11.5 12.5C10.9477 12.5 10.5 12.0523 10.5 11.5C10.5 10.9477 10.9477 10.5 11.5 10.5ZM7 8C7 7.44772 7.44772 7 8 7C8.55228 7 9 7.44772 9 8C9 8.55228 8.55228 9 8 9C7.44772 9 7 8.55228 7 8ZM11 19.5V16H8.74993V19.5H11ZM7.24993 19.5V16H5V19.5H7.24993Z" fill="#38BBF4" />
                                </svg>  */}
                                <img src={UtilityFunctions.getImageURL() + "tab-building-defalt.svg"} alt="" />
                                <img src={UtilityFunctions.getImageURL() + "tab-building-fill.svg"} alt="" />
                              </i>
                              <span>Jobs</span>
                            </>
                          }
                        </a>
                      </li>}
                    {tab.id == 3 &&
                      <li key={tab.id} onClick={() => {
                        if (props?.isUserLoggedIn) { tabClick(tab.id); setNewestJob(true) }
                        else props?.setShowGuestAccessPrompt(true)
                      }}
                      >
                        <a href='#' className={`btn-locatn btn-job ${tab.id == activeIndex ? 'active' : ''}`}>
                          {userType?.user?.type === STRING_CONSTANTS.userType.employer ?
                            <>
                              <i>
                                {/* <svg width="30" height="29" viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <g filter="url(#filter0_d_3401_9681)">
                                    <path d="M11.75 6H18.25C18.6297 6 18.9435 6.28215 18.9932 6.64823L19 6.75V10H20.75C22.5449 10 24 11.4551 24 13.25V19.75C24 21.5449 22.5449 23 20.75 23H9.25C7.45507 23 6 21.5449 6 19.75V13.25C6 11.4551 7.45507 10 9.25 10H11V6.75C11 6.3703 11.2822 6.05651 11.6482 6.00685L11.75 6H18.25H11.75ZM20.75 11.5H9.25C8.2835 11.5 7.5 12.2835 7.5 13.25V19.75C7.5 20.7165 8.2835 21.5 9.25 21.5H20.75C21.7165 21.5 22.5 20.7165 22.5 19.75V13.25C22.5 12.2835 21.7165 11.5 20.75 11.5ZM17.5 7.5H12.5V10H17.5V7.5Z" fill="white" />
                                  </g>
                                  <defs>
                                    <filter id="filter0_d_3401_9681" x="0" y="0" width="30" height="29" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                      <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                      <feOffset />
                                      <feGaussianBlur stdDeviation="3" />
                                      <feComposite in2="hardAlpha" operator="out" />
                                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.44 0" />
                                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3401_9681" />
                                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3401_9681" result="shape" />
                                    </filter>
                                  </defs>
                                </svg>*/}
                                {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M15.25 3C15.6642 3 16 3.33579 16 3.75V7H17.75C19.5449 7 21 8.45507 21 10.25V16.75C21 18.5449 19.5449 20 17.75 20H6.25C4.45507 20 3 18.5449 3 16.75V10.25C3 8.45507 4.45507 7 6.25 7H8V3.75C8 3.3703 8.28215 3.05651 8.64823 3.00685L8.75 3H15.25ZM14.5 4.5H9.5V7H14.5V4.5Z" fill="#38BBF4" />
                                </svg>  */}
                                <img src={UtilityFunctions.getImageURL() + "briefcase-icon-dark.svg"} alt="" />
                                <img src={UtilityFunctions.getImageURL() + "briefcase-icon-dark.svg"} alt="" />
                              </i>
                              <span>Courses</span>
                            </>
                            :
                            <>
                              <i>
                                {/* <svg width="29" height="32" viewBox="0 0 29 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <g filter="url(#filter0_d_13473_23923)">
                                    <path d="M10.5 9.5C9.94772 9.5 9.5 9.94772 9.5 10.5C9.5 11.0523 9.94772 11.5 10.5 11.5C11.0523 11.5 11.5 11.0523 11.5 10.5C11.5 9.94772 11.0523 9.5 10.5 9.5ZM9.5 17.5C9.5 16.9477 9.94772 16.5 10.5 16.5C11.0523 16.5 11.5 16.9477 11.5 17.5C11.5 18.0523 11.0523 18.5 10.5 18.5C9.94772 18.5 9.5 18.0523 9.5 17.5ZM10.5 13C9.94772 13 9.5 13.4477 9.5 14C9.5 14.5523 9.94772 15 10.5 15C11.0523 15 11.5 14.5523 11.5 14C11.5 13.4477 11.0523 13 10.5 13ZM13 10.5C13 9.94772 13.4477 9.5 14 9.5C14.5523 9.5 15 9.94772 15 10.5C15 11.0523 14.5523 11.5 14 11.5C13.4477 11.5 13 11.0523 13 10.5ZM14 16.5C13.4477 16.5 13 16.9477 13 17.5C13 18.0523 13.4477 18.5 14 18.5C14.5523 18.5 15 18.0523 15 17.5C15 16.9477 14.5523 16.5 14 16.5ZM16.5 17.5C16.5 16.9477 16.9477 16.5 17.5 16.5C18.0523 16.5 18.5 16.9477 18.5 17.5C18.5 18.0523 18.0523 18.5 17.5 18.5C16.9477 18.5 16.5 18.0523 16.5 17.5ZM14 13C13.4477 13 13 13.4477 13 14C13 14.5523 13.4477 15 14 15C14.5523 15 15 14.5523 15 14C15 13.4477 14.5523 13 14 13ZM8.25 6C7.00736 6 6 7.00736 6 8.25V24.75C6 25.1642 6.33579 25.5 6.75 25.5H21.2528C21.667 25.5 22.0028 25.1642 22.0028 24.75V15.7493C22.0028 14.5067 20.9954 13.4993 19.7528 13.4993H18.5V8.25C18.5 7.00736 17.4926 6 16.25 6H8.25ZM7.5 8.25C7.5 7.83579 7.83579 7.5 8.25 7.5H16.25C16.6642 7.5 17 7.83579 17 8.25V14.2493C17 14.6635 17.3358 14.9993 17.75 14.9993H19.7528C20.167 14.9993 20.5028 15.3351 20.5028 15.7493V24H18.5V21.25C18.5 20.8358 18.1642 20.5 17.75 20.5H10.25C9.83579 20.5 9.5 20.8358 9.5 21.25V24H7.5V8.25ZM17 22V24H14.75V22H17ZM13.25 22V24H11V22H13.25Z" fill="white" />
                                  </g>
                                  <defs>
                                    <filter id="filter0_d_13473_23923" x="0" y="0" width="28.002" height="31.5" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                      <feOffset />
                                      <feGaussianBlur stdDeviation="3" />
                                      <feComposite in2="hardAlpha" operator="out" />
                                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.44 0" />
                                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_13473_23923" />
                                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_13473_23923" result="shape" />
                                    </filter>
                                  </defs>
                                </svg>*/}
                                {/* <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M2.75 0C1.50736 0 0.5 1.00736 0.5 2.25V18.75C0.5 19.1642 0.835786 19.5 1.25 19.5H3.5V15.25C3.5 14.8358 3.83579 14.5 4.25 14.5H11.75C12.1642 14.5 12.5 14.8358 12.5 15.25V19.5H14.75C15.1642 19.5 15.5 19.1642 15.5 18.75V9.7493C15.5 8.50666 14.4926 7.4993 13.25 7.4993H12.5V2.25C12.5 1.00736 11.4926 0 10.25 0H2.75ZM3.5 4.5C3.5 3.94772 3.94772 3.5 4.5 3.5C5.05228 3.5 5.5 3.94772 5.5 4.5C5.5 5.05228 5.05228 5.5 4.5 5.5C3.94772 5.5 3.5 5.05228 3.5 4.5ZM4.5 10.5C5.05228 10.5 5.5 10.9477 5.5 11.5C5.5 12.0523 5.05228 12.5 4.5 12.5C3.94772 12.5 3.5 12.0523 3.5 11.5C3.5 10.9477 3.94772 10.5 4.5 10.5ZM3.5 8C3.5 7.44772 3.94772 7 4.5 7C5.05228 7 5.5 7.44772 5.5 8C5.5 8.55228 5.05228 9 4.5 9C3.94772 9 3.5 8.55228 3.5 8ZM8 3.5C8.55228 3.5 9 3.94772 9 4.5C9 5.05228 8.55228 5.5 8 5.5C7.44772 5.5 7 5.05228 7 4.5C7 3.94772 7.44772 3.5 8 3.5ZM7 11.5C7 10.9477 7.44772 10.5 8 10.5C8.55228 10.5 9 10.9477 9 11.5C9 12.0523 8.55228 12.5 8 12.5C7.44772 12.5 7 12.0523 7 11.5ZM11.5 10.5C12.0523 10.5 12.5 10.9477 12.5 11.5C12.5 12.0523 12.0523 12.5 11.5 12.5C10.9477 12.5 10.5 12.0523 10.5 11.5C10.5 10.9477 10.9477 10.5 11.5 10.5ZM7 8C7 7.44772 7.44772 7 8 7C8.55228 7 9 7.44772 9 8C9 8.55228 8.55228 9 8 9C7.44772 9 7 8.55228 7 8ZM11 19.5V16H8.74993V19.5H11ZM7.24993 19.5V16H5V19.5H7.24993Z" fill="#38BBF4" />
                                </svg>  */}
                                <img src={UtilityFunctions.getImageURL() + "briefcase-icon-dark.svg"} alt="" />
                                <img src={UtilityFunctions.getImageURL() + "briefcase-icon-dark.svg"} alt="" />
                              </i>
                              <span>Courses</span>
                            </>
                          }
                        </a>
                      </li>}                      
                    </>
                );
              })}
              {!isSubPurchased && userType?.user?.type === STRING_CONSTANTS.userType.employer &&
                <li onClick={() => setSubsModal(true)}>
                  <a href='#' className={`btn-user active`}>
                    <i>
                      <img src={UtilityFunctions.getImageURL() + "user-icon-blank.svg"} alt="" />
                      <img src={UtilityFunctions.getImageURL() + "user-ico-fill-yellow.svg"} alt="" />
                    </i>
                    <span>{"Subscription"}</span>
                  </a>
                </li>
              }
            </ul>
            <GuestPrompt
              show={showGuestAccessPrompt}
              handleClose={() => setShowGuestAccessPrompt(false)}
            />
            <Sidebar
              userType={userType?.user?.type}
              selectedEmployee={selectedEmployee}
              selectedJob={selectedJob}
              setSelectedJobID={setSelectedJobID}
              selectedJobID={selectedJobID}
              setClosest={setClosest}
              closest={closest}
              employeeJobList={employeeJobList}
              setEmployeeObject={setEmployeeObject}
              // getAllJobs={getAllJobs}
              history={props.history}
              setRequestSent={setRequestSent}
              setRequestReceived={setRequestReceived}
              setRequestDistance={setRequestDistance}
              requestSent={requestSent}
              requestReceived={requestReceived}
              requestDistance={requestDistance}
              setSelectedEmployee={setSelectedEmployee}
              setShowGuestAccessPrompt={props?.setShowGuestAccessPrompt}
              activeIndex={activeIndex}
            />
            {activeIndex == 0 &&
              <Employees
                coords={props?.coords}
                userType={userType?.user?.type}
                activeIndex={activeIndex}
                setSelectedEmployee={setSelectedEmployee}
                closest={closest}
                setEmployeeJobList={setEmployeeJobList}
                setEmployeeObject={setEmployeeObject}
                setSelectedJobID={setSelectedJobID}
                selectedJobID={selectedJobID}
                requestSent={requestSent}
                requestReceived={requestReceived}
                requestDistance={requestDistance}
                history={props.history}
                isUserLoggedIn={props?.isUserLoggedIn}
                setShowGuestAccessPrompt={props?.setShowGuestAccessPrompt}
              />
            }

            {activeIndex == 1 &&
              <MapView
                employeeJobList={employeeJobList}
                selectedJobID={selectedJobID}
                setSelectedJobID={setSelectedJobID}
                employeeObject={employeeObject}
                requestSent={requestSent}
                requestReceived={requestReceived}
                requestDistance={requestDistance}
                closest={closest}
                history={props.history}

              />
            }
            {activeIndex == 2 &&
              <Jobs
                setSelectedJob={setSelectedJob}
                userType={userType?.user?.type}
                setSelectedEmployee={setSelectedEmployee}
                hasMore={hasMore}
              />}
            {activeIndex == 3 &&
              <Courses
                coords={props?.coords}
                userType={userType?.user?.type}
                activeIndex={activeIndex}
                setSelectedEmployee={setSelectedEmployee}
                closest={closest}
                setEmployeeJobList={setEmployeeJobList}
                setEmployeeObject={setEmployeeObject}
                setSelectedJobID={setSelectedJobID}
                selectedJobID={selectedJobID}
                requestSent={requestSent}
                requestReceived={requestReceived}
                requestDistance={requestDistance}
                history={props.history}
                isUserLoggedIn={props?.isUserLoggedIn}
                setShowGuestAccessPrompt={props?.setShowGuestAccessPrompt}
              />
            }
          </div>
        </div>
      </div>
      {/* Subscription modal */}
      <SubscriptionModal
        subsModal={subsModal}
        setSubsModal={setSubsModal}
        history={props.history}
      />
    </div>
  </>
  )
}
  ;
export default withRouter(Home);