import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import SuccessToast from "../../../components/successToast";
import ValidationErrorToast from "../../../components/validationErrorToast";
import { api } from "../../../middleware/api";
import { actionCreators, State } from "../../../store";
import { STRING_CONSTANTS } from "../../../utils/constants/stringConstants";
import { UtilityFunctions, modifiedSalaray, checkFeatureFlag } from "../../../utils/UtilityFunctions";
import { cookieService, userService } from "../../../_services";
import { Data } from "../../../_services/collections";
import ReactPlayer from 'react-player/lazy';
import parse from "html-react-parser";
import EmployeeJobListing from "./EmployeeJobListing";
import moment from "moment";
import NoVerifyModal from "../../../components/noVerifyModal";

const EmployeeSidebar = (props: any) => {
    const [selectedEmployee, setSelectedEmployee] = useState<any>();
    const [userDeleted, setUserDeleted] = useState(false);
    const [playVideo, setPlayVideo] = useState(false)
    const dispatch = useDispatch();
    const { setEmployeesList, setRemoveEmployee, setSidebar } = bindActionCreators(actionCreators, dispatch);
    const reduxState = useSelector((state: State) => state);
    const isUserLoggedIn = cookieService.getCookie(STRING_CONSTANTS.cookieStrings.token)
    const userTypeCookieval = cookieService.getCookie(STRING_CONSTANTS.cookieStrings.user)

    // Get feature flag for Interview Simulator
    const ffInterviewSimulator = checkFeatureFlag(userTypeCookieval, 'interview-simulator');

    const [notVerifiedModal, setNotVerifiedModal] = useState(false)
    const selectedEmployeeDetails = (jobId = "", isTrue = false) => {
        if (!isUserLoggedIn) {
            api.get(`${props?.selectedEmployee?.id}/job/`, null)
                .then(res => {
                    setSelectedEmployee(res)
                    props?.setSelectedUser(res)
                    setUserDeleted(false)
                })
                .catch(e => {
                    document.body.classList.add("loading-indicator");
                    // setUserDeleted(true)
                    props?.setUserExists(false)
                })
        }
        else if (userTypeCookieval?.user.type === STRING_CONSTANTS.userType.employer) {
            let SELECTEDEMPLOYEEID = props?.selectedEmployee?.id ? props?.selectedEmployee?.id : reduxState?.common?.uniqueemployeeId;
            let SELECTEDJOBID = props?.selectedJobID ? props?.selectedJobID : reduxState?.common?.uniqueId;
            userService.showEmployeeSidebar(SELECTEDEMPLOYEEID, SELECTEDJOBID)
                .then(res => {
                    setSelectedEmployee(res)
                    props?.setSelectedUser(res)
                    setUserDeleted(false)
                })
                .catch(e => {
                    props.closeModalForcefully();
                    const elements = document.getElementsByClassName('modal-backdrop fade show');
                    while (elements.length > 0) {
                        elements[0].parentNode.removeChild(elements[0]);
                    }
                    ValidationErrorToast(e?.[0], e?.[1])
                    setUserDeleted(true)
                    props?.setUserExists(false)
                })
        }
        else {
            let id = ""
            if (isTrue && jobId) {
                id = jobId
            }
            else if (reduxState?.common?.uniqueId && !isTrue) {
                id = props?.selectedJobID ? props?.selectedJobID : reduxState?.common?.uniqueId;
            }
            else {
                id = props?.selectedEmployee?.id
            }
            if (reduxState?.common?.show_sidebar) { // only side is open then call job detail open api other it will not called.
                api.get(`${id}/job/`, null)
                    .then((res: any) => {
                        setSelectedEmployee(res)
                        setUserDeleted(false)
                        props?.setSelectedUser(res)
                    })
                    .catch(e => {
                        props.closeModalForcefully();
                        const elements = document.getElementsByClassName('modal-backdrop fade show');
                        while (elements.length > 0) {
                            elements[0].parentNode.removeChild(elements[0]);
                        }
                        ValidationErrorToast(e?.[0], e?.[1])
                        setUserDeleted(true)
                        props?.setUserExists(false)
                    })
            }

        }
    }
    useEffect(() => {
        if (reduxState?.common?.uniqueId || reduxState?.common?.uniqueId && reduxState?.common?.uniqueemployeeId) {
            selectedEmployeeDetails();
        }
    }, [reduxState?.common?.uniqueId, (reduxState?.common?.uniqueId && reduxState?.common?.uniqueemployeeId)])

    useEffect(() => {
        if ((props?.selectedEmployee?.id) || !isUserLoggedIn) {
            selectedEmployeeDetails();
            props?.setFlagReasons([]);
            props?.setFlag(true);
        }
    }, [props?.selectedEmployee])

    const handleSendRequest = (employee) => {
        const erID = cookieService.getCookie(STRING_CONSTANTS.cookieStrings.user) as Data
        const eeList = [...reduxState.employeesList]
        eeList.splice(reduxState.employeesList.findIndex((ee) => ee.id == employee.id), 1, { ...employee, chatRequested: true, requestedByUser: true })
        // if (!employee?.chatRequested) {
        //     api.post('chat/rooms/', {
        //         "employerId": `${erID.user.id}`,
        //         "employeeId": `${employee?.user?.id}`,
        //         "jobId": `${props?.selectedJobID}`
        //     }).then(res => {
        //         setEmployeesList(eeList)
        //         selectedEmployeeDetails()
        //         SuccessToast(STRING_CONSTANTS.SUCCESS_MESSAGES.CHAT, STRING_CONSTANTS.SUCCESS_MESSAGES.CHAT_REQUEST_SENT)
        //     }).catch(e => {
        //         ValidationErrorToast(e[0], e[1])
        //     })
        // }
        // if (!employee?.chatRequested) {
        let params = {};
        // console.log(employee, "employee");
        // let idEmployeeEmployer;
        if (userTypeCookieval?.user.type === STRING_CONSTANTS.userType.employer) {
            // employer
            // idEmployeeEmployer = employee?.user?.id;
            params = {
                employerId: erID.user.id,
                employeeId: employee?.user?.id,
                jobId: props?.selectedJobID
            }
        } else {
            // employee
            // idEmployeeEmployer = employee?.employer?.user?.id;
            params = {
                "employerId": `${employee?.employer?.user?.id}`,
                "employeeId": `${erID.user.id}`,
                "jobId": `${employee?.id}`
            }
        }
        api.post('chat/rooms/', params).then(res => {
            selectedEmployeeDetails();
            setEmployeesList(eeList);

            props?.history.push(`/dashboard/chat?chatid=${res?.data?.room?.id}`)
            // SuccessToast('Chat Request Sent', "")
        }).catch(e => {
            ValidationErrorToast('Error occured!', 'Please try again after some time')
        })
        // }
    }

    const handleSimulateInterview = (employee) => {
        const erID = cookieService.getCookie(STRING_CONSTANTS.cookieStrings.user) as Data
        const eeList = [...reduxState.employeesList]
        eeList.splice(reduxState.employeesList.findIndex((ee) => ee.id == employee.id), 1, { ...employee, chatRequested: true, requestedByUser: true })

        let params = {};

        if (userTypeCookieval?.user.type === STRING_CONSTANTS.userType.employer) {

            params = {
                employerId: erID.user.id,
                employeeId: employee?.user?.id,
                jobId: props?.selectedJobID
            }
        } else {
            params = {
                "employerId": `${employee?.employer?.user?.id}`,
                "employeeId": `${erID.user.id}`,
                "jobId": `${employee?.id}`
            }
        }
        api.post('simulate-interview/', params).then(res => {
            // Now ball up everything in res.data and open a new window posting it to the interview simulator
            let form = document.createElement("form");
            form.setAttribute("method", "post");
            if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
                form.setAttribute("action", "http://localhost:3030/");
            } else {
                form.setAttribute("action", "https://simulation.mploi.com/");
            }
            form.setAttribute("target", "_blank");
            // add all the data in a big field
            let input = document.createElement("input");
            input.setAttribute("type", "hidden");
            input.setAttribute("name", "data");
            input.setAttribute("value", JSON.stringify(res.data));
            form.appendChild(input);
            document.body.appendChild(form);
            form.submit();
            document.body.removeChild(form);

            // SuccessToast('Chat Request Sent', "")
        }).catch(e => {
            ValidationErrorToast('Error occured!', 'Please try again after some time')
        })
        // }
    }
    // const handleReceivedRequest = {
    //     handleChatAccept: (employee) => {
    //         api.patch(`${employee?.chatRoomId}/chat/`,
    //             {
    //                 "isActive": true
    //             })
    //             .then(res => {
    //                 props?.history.push(`/dashboard/chat?chatid=${res?.data?.["id"]}`)
    //                 // props?.history?.push("chat/", { chatInfo: { id: res?.data?.["id"] } })
    //                 setSidebar({ sidebar_name: "employee-details", show_sidebar: false })
    //                 SuccessToast('Chat Request Accepted', "Request has been successfully accepted")
    //                 setRemoveEmployee(employee)
    //             })
    //             .then(() => {
    //                 props?.getChatRequests()
    //             })
    //             .catch(e => {
    //                 // ValidationErrorToast('Error occured!', 'Please try again after some time')
    //             })
    //     },
    //     handleChatReject: (employee) => {
    //         api.patch(`${employee?.chatRoomId}/chat/`,
    //             {
    //                 "isActive": false
    //             })
    //             .then(res => {
    //                 setSidebar({ sidebar_name: "employee-details", show_sidebar: false })
    //                 // console.log(res)
    //                 SuccessToast('Chat Request Rejected', "Request has been rejected")
    //                 setRemoveEmployee(employee)
    //             })
    //             .then(() => {
    //                 props?.getChatRequests()
    //             })
    //             .catch(e => {
    //                 // ValidationErrorToast('Error occured!', 'Please try again after some time')
    //                 // console.log(e)
    //             })
    //     }
    // }

    const handleChatButton = (userObj) => {
        if (userTypeCookieval?.user.type === STRING_CONSTANTS.userType.employer) {
            //IF JOB STATUS IS ACTIVE
            if (userObj?.jobStatus == 'active') {
                //IF CHAT IS ACTIVE
                if (userObj?.chatRoomId)
                    return <button className="btn"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        type="button"
                        onClick={() => {
                            props?.history.push(`/dashboard/chat?chatid=${userObj.chatRoomId}`)
                            setSidebar({ sidebar_name: "employee-details", show_sidebar: false })
                        }}
                        style={{ backgroundColor: "#FFB800", color: "black" }}
                    >
                        {/* Chat */}
                        Ongoing Chat, Click Here
                    </button>
                //IF CHAT IS REJECTED
                // else if (userObj?.chatRejected)
                //     return <div></div>
                //IF CHAT IS REQUESTED BUT NOT REQUESTED BY USER 
                // else if (userObj?.chatRequested && !userObj?.requestedByUser) {
                //     return <>
                //         <button className='reject-btn'
                //             type="button"
                //             data-bs-dismiss="modal"
                //             aria-label="Close"
                //             onClick={() => {
                //                 handleReceivedRequest.handleChatReject(selectedEmployee)
                //             }}>
                //             Reject
                //         </button>
                //         <button className="btn"
                //             data-bs-dismiss="modal"
                //             aria-label="Close"
                //             type="button"
                //             onClick={() => {
                //                 handleReceivedRequest.handleChatAccept(selectedEmployee)
                //             }}
                //         >
                //             Chat
                //         </button>
                //     </>
                // }
                //IF CHAT IS REQUESTED BUT REQUESTED BY USER 
                // else if (userObj?.chatRequested && userObj?.requestedByUser)
                //     return <div className='btn-effect full-width-btn v-disabled-btn'>
                //         <button className='chat-btn' disabled
                //             type="button"
                //         >
                //             Chat Request Sent
                //         </button>
                //     </div>
                //IF CHAT IS NOT REQUESTED
                else return <div className="btn-effect full-width-btn">
                    <button className='btn'
                        type="button"
                        onClick={() => {
                            if (!isUserLoggedIn) { }
                            handleSendRequest(selectedEmployee)
                        }
                        }>
                        {/* Send Chat Request */}

                        Start Chat
                    </button>
                </div>
            }
            else if (userObj?.jobStatus != 'active' && userObj?.chatRoomId) {
                return <button className="btn"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    type="button"
                    onClick={() => {
                        props?.history.push(`/dashboard/chat?chatid=${userObj.chatRoomId}`)
                        setSidebar({ sidebar_name: "employee-details", show_sidebar: false })
                    }}
                    style={{ backgroundColor: "#FFB800", color: "black" }}
                >
                    {/* Chat */}
                    Ongoing Chat, Click Here
                </button>
            }
            // else return <div></div>
        } else {
            //IF JOB STATUS IS ACTIVE
            if (userObj?.status == 'active') {
                //IF CHAT IS ACTIVE
                if (userObj?.chatRoomId) {
                    return <button className="btn"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        type="button"
                        onClick={() => {
                            props?.history.push(`/dashboard/chat?chatid=${userObj.chatRoomId}`)
                            setSidebar({ sidebar_name: "employee-details", show_sidebar: false })
                        }}
                        style={{ backgroundColor: "#FFB800", color: "black" }}
                    >
                        {/* Chat */}
                        Ongoing Chat, Click Here
                    </button>
                //IF CHAT IS REJECTED
                // else if (userObj?.chatRejected)
                //     return <div></div>
                //IF CHAT IS REQUESTED BUT NOT REQUESTED BY USER 
                // else if (userObj?.chatRequested && !userObj?.requestedByUser) {
                //     return <>
                //         <button className='reject-btn'
                //             type="button"
                //             data-bs-dismiss="modal"
                //             aria-label="Close"
                //             onClick={() => {
                //                 handleReceivedRequest.handleChatReject(selectedEmployee)
                //             }}>
                //             Reject
                //         </button>
                //         <button className="btn"
                //             data-bs-dismiss="modal"
                //             aria-label="Close"
                //             type="button"
                //             onClick={() => {
                //                 handleReceivedRequest.handleChatAccept(selectedEmployee)
                //             }}
                //         >
                //             Chat
                //         </button>
                //     </>
                // }
                //IF CHAT IS REQUESTED BUT REQUESTED BY USER 
                // else if (userObj?.chatRequested && userObj?.requestedByUser)
                //     return <div className='btn-effect full-width-btn v-disabled-btn'>
                //         <button className='chat-btn' disabled
                //             type="button"
                //         >
                //             Chat Request Sent
                //         </button>
                //     </div>
                //IF CHAT IS NOT REQUESTED
                } else {
                    if (!ffInterviewSimulator) {
                        return <div className="btn-effect">
                            <button className='btn'
                                type="button"
                                onClick={() => {
                                    if (!isUserLoggedIn) {
                                        props?.setShowGuestAccessPrompt(true)
                                    }
                                    else if (!userTypeCookieval?.isAgeVerified) {
                                        setNotVerifiedModal(true)
                                    }
                                    else {
                                        handleSendRequest(selectedEmployee)
                                    }
                                }}>
                                {/* Send Chat Request */}
                                Start Chat
                            </button>
                        </div>
                    } else {
                        return <>
                            <div className="btn-effect">
                                <button className='btn'
                                    type="button"
                                    onClick={() => {
                                        if (!isUserLoggedIn) {
                                            props?.setShowGuestAccessPrompt(true)
                                        }
                                        else if (!userTypeCookieval?.isAgeVerified) {
                                            setNotVerifiedModal(true)
                                        }
                                        else {
                                            handleSendRequest(selectedEmployee)
                                        }
                                    }}>
                                    Start Chat
                                </button>
                            </div>
                            <div className="btn-effect">
                                <button className='btn'
                                    type="button"
                                    onClick={() => {
                                        if (!isUserLoggedIn) {
                                            props?.setShowGuestAccessPrompt(true)
                                        }
                                        else {
                                            handleSimulateInterview(selectedEmployee)
                                        }
                                    }}>
                                    Practice Interview
                                </button>
                            </div>
                        </>
                    }
                }
            }
            else if (userObj?.status != 'active' && userObj?.chatRoomId) {
                return <button className="btn"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    type="button"
                    onClick={() => {
                        props?.history.push(`/dashboard/chat?chatid=${userObj.chatRoomId}`)
                        setSidebar({ sidebar_name: "employee-details", show_sidebar: false })
                    }}
                    style={{ backgroundColor: "#FFB800", color: "black" }}
                >
                    {/* Chat */}
                    Ongoing Chat, Click Here
                </button>
            }
            else return <div></div>
        }
    }
    const handleClose = () => {
        setNotVerifiedModal(false);
    }
    const getUpdatedId = (job) => {
        selectedEmployeeDetails(job?.id, true)
        props?.setSelectedEmployee?.(job) ? props?.setSelectedEmployee(job) : setSelectedEmployee(job);
    }

    let logo = selectedEmployee?.employer?.logo;
    if (selectedEmployee?.employer?.logoCdnUrl) {
        logo = selectedEmployee?.employer?.logoCdnUrl;
    }
    console.log("backpedal", selectedEmployee?.employer)

    // console.log(selectedEmployee, "selected employee");
    return (
        <>
            <NoVerifyModal show={notVerifiedModal} handleClose={handleClose} />
            <div className="modal-content" >
                <div className="modal-body">
                    {userDeleted ? <h3 style={{ color: "white" }}>This user doesn't exist</h3>
                        : !selectedEmployee?.id ? <h1 style={{ color: "white" }}>Loading...</h1>
                            : <>
                                <div className="prfile-se-mai d-grid align-content-between">
                                    <div className="profil-to">
                                        <div className='profile-detail-top'>
                                            <div className='d-flex justify-content-between'>
                                                <div className='prodetail-pic'>
                                                    {/* when you using employee level */}
                                                    {/* <p className="mb-0">LVL 2</p> */}
                                                    {
                                                        userTypeCookieval?.user.type === STRING_CONSTANTS.userType.employer ?

                                                            <>
                                                                <img
                                                                    src={selectedEmployee?.profilePhoto || UtilityFunctions.getImageURL() + "user-bg-dark.png"} />
                                                                {/* // src={selectedEmployee?.employer?.logo || UtilityFunctions.getImageURL() + "bildeing-squre-img.png"}/> */}
                                                                {/* {selectedEmployee?.user?.referralUsedCount >= 2 */}
                                                                <p className="mb-0" style={{

                                                                    background: "#9256e8",
                                                                    width: "100%",
                                                                    maxWidth: "fit-content"
                                                                }}>Advanced</p>
                                                                {/* : <div></div> */}
                                                                {/* } */}
                                                            </>
                                                            :
                                                            <img alt="" src={logo || UtilityFunctions.getImageURL() + "bildeing-squre-img.png"} />
                                                    }
                                                </div>
                                                {(selectedEmployee?.user?.isVerified || selectedEmployee?.employer?.user?.isVerified)
                                                    // && userTypeCookieval?.user?.type === STRING_CONSTANTS.userType.employer
                                                    ?
                                                    <div className='verified-accnt'>
                                                        <img src={UtilityFunctions.getImageURL() + "account-verified.svg"} alt="" />
                                                        Account Verified
                                                    </div> : null}
                                            </div>

                                            <div className='d-flex justify-content-between mt-18'>

                                                <h4>{
                                                    userTypeCookieval?.user.type === STRING_CONSTANTS.userType.employer ? (selectedEmployee?.user?.firstName || '') : (selectedEmployee?.employer?.tradingName || "")
                                                }
                                                </h4>
                                                <h5>
                                                    <img src={UtilityFunctions.getImageURL() + "Globe Location.svg"} alt="" />
                                                    {(selectedEmployee?.location?.suburb ? selectedEmployee?.location?.suburb : "") + (selectedEmployee?.location?.state ? ", " : "") + (selectedEmployee?.location?.state ? selectedEmployee?.location?.state : "")}
                                                </h5>
                                            </div>
                                            <div className='label-design'>
                                                {
                                                    < a className='label-button clr-yllw'>
                                                        <img src={UtilityFunctions.getImageURL() + "line-yellow.svg"} alt="" />
                                                        {userTypeCookieval?.user.type === STRING_CONSTANTS.userType.employer ?
                                                            modifiedSalaray(selectedEmployee?.minSalary, selectedEmployee?.maxSalary, selectedEmployee?.jobType, selectedEmployee?.salaryCheckoutType) :
                                                            modifiedSalaray(selectedEmployee?.minSalary, selectedEmployee?.maxSalary, selectedEmployee?.type, selectedEmployee?.salaryCheckoutType)}
                                                    </a>}
                                                <a className='label-button clr-pink'>
                                                    <img src={UtilityFunctions.getImageURL() + "clock-icon.svg"} alt="" />
                                                    {UtilityFunctions.convertMonthsToYearsAndMonths(selectedEmployee?.experience) + 'Experience'}
                                                </a>

                                                {userTypeCookieval?.user.type === STRING_CONSTANTS.userType.employer ? <>
                                                    {selectedEmployee?.category?.map((category) => {
                                                        return (<a key={category?.id} className='label-button clr-yllw'>
                                                            <img src={UtilityFunctions.getImageURL() + "line-yellow.svg"} alt="" />

                                                            {category.name}</a>)
                                                    })}
                                                    {selectedEmployee?.subCategory.map((subcategory) => {
                                                        return (
                                                            <a key={subcategory?.id} className='label-button clr-blue'>
                                                                <img src={UtilityFunctions.getImageURL() + "two-line-blue.svg"} alt="" />
                                                                {subcategory.name}
                                                            </a>
                                                        )
                                                    })}
                                                </> :
                                                    <>
                                                        <a className='label-button clr-yllw'>
                                                            <img src={UtilityFunctions.getImageURL() + "line-yellow.svg"} alt="" />
                                                            {selectedEmployee?.category?.name}
                                                        </a>
                                                        <a className='label-button clr-blue'>
                                                            <img src={UtilityFunctions.getImageURL() + "two-line-blue.svg"} alt="" />
                                                            {selectedEmployee?.subCategory.name}
                                                        </a>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                        <div className='bordr-grdnt'></div>
                                        {userTypeCookieval?.user.type === STRING_CONSTANTS.userType.employer ?
                                            <div className='profile-detail-top'>
                                                <h6>About {(selectedEmployee?.user?.firstName || '')}</h6>
                                                {/* {!selectedEmployee?.chatIsActive ? <><div className="box-design d-flex align-items-center gap-3">
                                                    <img src={UtilityFunctions.getImageURL() + "EyeOffGreen.svg"} alt="" />
                                                    <p className="mb-0">Profile is hidden until chat request is accepted</p>
                                                </div>

                                                </>
                                                    : */}
                                                <>
                                                    <p>
                                                        {selectedEmployee?.about}
                                                    </p>
                                                    {selectedEmployee?.introductoryVideo &&
                                                        <div className="videos_react_chet">
                                                            <ReactPlayer
                                                                controls={true}
                                                                // onReady={() => { playVideo && setPlayVideo(true) }}
                                                                // playsinline={playVideo}
                                                                width='100%'
                                                                height='100%'
                                                                playIcon={<img className="play-icon" src={UtilityFunctions.getImageURL() + "play-video-button.svg"} alt='' />}
                                                                light={selectedEmployee?.thumbnail ? <img className="video-thumbnail" onClick={() => setPlayVideo(true)} src={selectedEmployee?.thumbnail} alt='' /> : false}
                                                                // playing={playVideo}
                                                                playing
                                                                // light={true}
                                                                url={selectedEmployee?.introductoryVideo} />
                                                        </div>}
                                                    <div className="profile-detail-media-object">
                                                        {
                                                            selectedEmployee?.educations?.length ?
                                                                <div className="media-object-pro">
                                                                    <h3 className="text-start mt-0">Education / Qualifications</h3>
                                                                    {selectedEmployee?.educations.map(education =>
                                                                        <>
                                                                            <div className="d-flex align-items-center justify-content-between">
                                                                                <div className="d-flex align-items-center">
                                                                                    <img src={UtilityFunctions.getImageURL() + "hat-graduation.svg"} alt="" />
                                                                                    <div className="">
                                                                                        <h4 className="m-0">{education?.institutionName}</h4>
                                                                                        <h6 className="m-0">{education?.course?.name ? education?.course?.name : ""}</h6>
                                                                                    </div>
                                                                                </div>
                                                                                <p className="mb-0">{moment(education?.startDate).format("YYYY") + " - " + (education?.endDate ? moment(education?.endDate).format("YYYY") : "Present")}</p>
                                                                            </div>
                                                                            <hr />
                                                                        </>)}
                                                                </div> : <div></div>
                                                        }
                                                        {/* SHOWING PREVIOUS EXPERIENCE ONLY ADVANCE PROFILE IS UNLOCKED */}
                                                        {(selectedEmployee?.experienceHistory?.length && selectedEmployee?.user?.referralUsedCount >= 2) ?
                                                            <div className="media-object-pro pink-medi">
                                                                <h3 className="text-start mt-0">Previous Experience</h3>
                                                                {selectedEmployee?.experienceHistory?.map(experience => {
                                                                    let elogo = experience?.employer?.logo;
                                                                    if (experience?.employer?.logoCdnUrl) {
                                                                        elogo = experience?.employer?.logoCdnUrl;
                                                                    }
                                                                    return <><div className="d-flex align-items-center justify-content-between">
                                                                        <div className="d-flex align-items-center">
                                                                            <img src={elogo || UtilityFunctions.getImageURL() + "pink-building.svg"} className={elogo ? "p-0" : ""} alt="" />
                                                                            <div className="">
                                                                                <h4 className="m-0">{experience?.employer ? experience?.employer?.tradingName : experience?.unregisteredEmployer || "N/A"}</h4>
                                                                                <h6 className="m-0">{experience?.subCategory?.[0]?.name || "N/A"}</h6>
                                                                            </div>
                                                                        </div>

                                                                        <p className="mb-0"> {!experience?.startDate ? "N/A" : (experience?.endDate == null && experience?.startDate !== null
                                                                            ? `${moment(experience?.startDate).format("YYYY")} -  Present`
                                                                            : experience?.endDate == null && experience?.startDate == null
                                                                                ? 'N/A'
                                                                                : `${moment(experience?.startDate).format("YYYY")} -  ${moment(experience?.endDate).format("YYYY")}`)}</p>
                                                                    </div>
                                                                        <hr /></>;
                                                                        }
                                                                    )}
                                                            </div>
                                                            : <div></div>
                                                        }
                                                    </div>
                                                </>
                                            </div>
                                            :
                                            <div className='profile-detail-top'>
                                                <h6>About {(selectedEmployee?.title || '')}</h6>
                                                <p>
                                                    {selectedEmployee?.description && parse(selectedEmployee?.description)}
                                                </p>
                                                {userTypeCookieval?.user.type === STRING_CONSTANTS.userType.employee && <>
                                                    <EmployeeJobListing
                                                        selectedEmployee={selectedEmployee?.id}
                                                        employerId={selectedEmployee?.employer?.id}
                                                        employerTitle={selectedEmployee?.title}
                                                        setSelectedEmployee={props?.setSelectedEmployee}
                                                        getUpdatedId={getUpdatedId}
                                                    />

                                                </>}
                                                {/* {!selectedEmployee?.chatIsActive ? <div className="box-design d-flex align-items-center gap-3">
                                                    <img src={UtilityFunctions.getImageURL() + "EyeOffGreen.svg"} alt="" />
                                                    <p className="mb-0">Profile is hidden until chat request is accepted</p>
                                                </div> : */}
                                                <>
                                                    {selectedEmployee?.introductoryVideo &&
                                                        <div className="videos_react_chet">
                                                            <ReactPlayer
                                                                controls={true}
                                                                width='100%'
                                                                height='100%'
                                                                // light={true}
                                                                url={selectedEmployee?.introductoryVideo} />
                                                        </div>
                                                    }

                                                </>
                                            </div >

                                        }
                                    </div >
                                    <div className="profil-bot-la text-white">
                                        {userTypeCookieval?.user?.type !== STRING_CONSTANTS.userType.employer ? <div className="text-white w-100 align-bottom my-3">Posted {moment(selectedEmployee?.createdAt).fromNow()}</div> : ""}
                                        <div className="d-flex align-items-center justify-content-around">
                                            {handleChatButton(selectedEmployee)}
                                        </div>
                                    </div>
                                </div >
                            </>}
                </div >
            </div >
        </>
    );
}

export default EmployeeSidebar;