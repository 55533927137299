import React, { useEffect, useState } from 'react';
import "./verified.css"
import { STRING_CONSTANTS } from "../../../utils/constants/stringConstants";
import { api } from "../../../middleware/api";

const Failed = (props) => {
    const redirect = () => {
        props.history.push(STRING_CONSTANTS.navigationString.dashboard.settings, { tab: "subscriptionInNav" });
    }

    return (
        <div className='container-fluid position-relative p-0 d-flex justify-content-center verified_main_container'>

            <div className='logo-position'>
                <img src="/assets/images/logo_white.svg" />
            </div>

            <div className='container-sm  mt-5 d-flex justify-content-center '>
                <div className='d-flex'>
                    {/* <div className='' style={{ cursor: 'pointer' }} onClick={redirect}>
                        <img src="/assets/images/dismiss.svg" alt="" />
                    </div> */}
                    <div className="data-container">
                        <div>
                            <p className='v_cong_txt' style={{ color: "#EB5757" }}>Uh oh! Payment failure</p>
                        </div>
                        <div className="v_cong_container mx-auto mt-4 ">
                            <p className="v_subs_txt">The payment appears to have encountered an issue. Please click on "Try again" to proceed with the transaction.</p>
                        </div>


                        <div className=' design-img-container mr-tp  d-flex justify-content-center '>
                            <img className='mx-auto  ' src="/assets/images/verified_design.png" />
                        </div>

                        <div className='center my-1'>
                            <div className='v_plan_btn ' style={{ cursor: 'pointer' }} onClick={redirect}>
                                <p className='m-0 v_plan_txt'>Try Again</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Failed;

