import React, { useState, useRef, useEffect } from "react";
import { Map, Source, Layer, Marker, NavigationControl } from 'react-map-gl';
import { clusterLayer, clusterCountLayer, unclusteredPointLayer } from './mapLayers';
import { STRING_CONSTANTS } from "../../../utils/constants/stringConstants";

import type { MapRef } from 'react-map-gl';
import type { GeoJSONSource } from 'mapbox-gl';
import Pin from './mapPin';
import cities from './cities.json';
import "mapbox-gl/dist/mapbox-gl.css";
import type { FeatureCollection } from 'geojson';
import MapClusterBox from './mapClusterBox';
import './map.css';
import MapEmployeeSidebar from './mapEmployeeSidebar';
import { fromJS } from 'immutable';
import MAP_STYLE from './mapStyle.json'
import { api } from "../../../middleware/api";
import { UtilityFunctions } from "../../../utils/UtilityFunctions";
import { userService } from "../../../_services";
import groups from './cluster-grouping.json'
import { cookieService } from "../../../_services";

const MapView = (props: any) => {
    const userType = cookieService.getCookie(STRING_CONSTANTS.cookieStrings.user);
    const [pins, setPins] = useState(null);
    const [initialLatitude, setInitialLatitude] = useState(-27.5113677);
    const [initialLongitude, setInitialLongitude] = useState(152.9635374);
    const [geojsonData, setGeoJsonData] = useState([]);
    const [showSidebar, setShowSidebar] = useState(false)
    const mapRef = useRef<MapRef>(null);
    const [clustArray, setClustArray] = useState([])
    const [mapStyle, setMapStyle] = useState(null);
    const [selectedJob, setSelectedJob] = useState(null);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [showMap, setShowMap] = useState(false);
    const [showClusters, setShowClusters] = useState(true);
    const [groupClusters, setGroupClusters] = useState([])
    const [ungroupClusters, setUngroupClusters] = useState([]);

    const defaultMapStyle: any = fromJS(MAP_STYLE);
    const defaultLayers = defaultMapStyle.get('layers');

    const settings = {
        scrollZoom: true,
        boxZoom: true,
        dragRotate: true,
        dragPan: true,
        keyboard: true,
        doubleClickZoom: true,
        touchZoomRotate: true,
        touchPitch: true,
        minZoom: 3.5,
        // maxZoom: 20,
        // minPitch: 0,
        // maxPitch: 85
    }

    const [visibility, setVisibility] = useState({
        water: true,
        parks: true,
        buildings: true,
        roads: true,
        labels: true,
        background: true
    });

    const [color, setColor] = useState({
        water: '#17263c',
        parks: '#483a5b',
        buildings: '#c0c0c8',
        roads: '#463859',
        labels: '#fff',
        background: 'transparent'
    });


    var geojson: FeatureCollection = {
        type: 'FeatureCollection',
        features: geojsonData
        // features: [
        //     { type: 'Feature', properties: { icon: "./icon.svg", data: { name: "Jane Cooper", role: 'Technician Role', distance: 22 } }, geometry: { type: 'Point', coordinates: [151.206892, -33.8392738] } },
        //     { type: 'Feature', properties: { icon: "./icon.svg", data: { name: "Jane Cooper", role: 'Technician Role', distance: 22 } }, geometry: { type: 'Point', coordinates: [151.206892, -33.8392738] } },
        //     { type: 'Feature', properties: { icon: "./icon.svg", data: { name: "Jane Cooper", role: 'Technician Role', distance: 22 } }, geometry: { type: 'Point', coordinates: [151.206892, -33.8392738] } },
        //     { type: 'Feature', properties: { icon: "./icon.svg", data: { name: "Jane Cooper", role: 'Technician Role', distance: 22 } }, geometry: { type: 'Point', coordinates: [151.206892, -33.8392738] } },
        //     { type: 'Feature', properties: { icon: "./icon.svg", data: { name: "Jane Cooper", role: 'Technician Role', distance: 22 } }, geometry: { type: 'Point', coordinates: [151.206892, -33.8392738] } },
        // ]
    };

    const onClick = event => {
        const feature = event?.features[0];
        const clusterId = feature?.properties?.cluster_id;
        if (feature?.layer?.id == 'unclustered-point') {
            var tempArr = [];
            const obj = {
                properties: {
                    data: JSON.parse(feature?.properties?.data)
                }
            }
            tempArr.push(obj)
            setClustArray(tempArr)
        }
        const mapboxSource = mapRef?.current?.getSource('earthquakes') as GeoJSONSource;
        mapboxSource?.getClusterExpansionZoom(clusterId, (err, zoom) => {
            if (err) {
                return;
            }
            if (clusterId && zoom) {
                mapRef.current.easeTo({
                    center: feature.geometry.coordinates,
                    zoom,
                    duration: 500
                });
            }
        });
        // mapboxSource.getClusterChildren(clusterId, (err, name) => {
        //     setClustArray(name)
        // })

        mapboxSource?.getClusterLeaves(clusterId, 100, 0, (err, data) => {
            setClustArray(data)
        })
    };

    const handleZoom = (level) => {
        if (level.viewState.zoom > 10) {
            setShowClusters(false)
        } else {
            setShowClusters(true)
        }
    }

    const categories = ['labels', 'roads', 'buildings', 'parks', 'water', 'background'];

    // Layer id patterns by category
    const layerSelector = {
        background: /background/,
        water: /water/,
        parks: /park/,
        buildings: /building/,
        roads: /bridge|road|tunnel/,
        labels: /label|place|poi/
    };

    // Layer color class by type
    const colorClass = {
        line: 'line-color',
        fill: 'fill-color',
        background: 'background-color',
        symbol: 'text-color'
    };

    function getMapStyle({ visibility, color }) {
        const layers = defaultLayers
            .filter(layer => {
                const id = layer.get('id');
                return categories.every(name => visibility[name] || !layerSelector[name].test(id));
            })
            .map(layer => {
                const id = layer.get('id');
                const type = layer.get('type');
                const category = categories.find(name => layerSelector[name].test(id));
                if (category && colorClass[type]) {
                    return layer.setIn(['paint', colorClass[type]], color[category]);
                }
                return layer;
            });

        return defaultMapStyle.set('layers', layers);
    }

    useEffect(() => {
        setMapStyle(getMapStyle({ visibility, color }));
    }, [visibility, color]);

    useEffect(() => {
        setShowMap(false)
        props?.employeeJobList && props?.employeeJobList.map((job) => {
            if (job.id == props?.selectedJobID) {
                setSelectedJob(job)
                setInitialLatitude(job.location.latitude)
                setInitialLongitude(job.location.longitude)
                setTimeout(() => setShowMap(true), 1000)
                // setShowMap(true)
            }
        })
    }, [props?.selectedJobID])

    useEffect(() => {
        if (props.employeeObject !== null) {
            api.post(`map-box/employees/?distance=${props.requestDistance ? (props.requestDistance >= 50 ? "" : props.requestDistance) : 25}&request_received=${(props.requestReceived) ? props.requestReceived : false}&request_sent=${(props.requestSent) ? props.requestSent : false}&sort=${(props.closest) ? "distance" : "relevance"}`
                //api.post(`map-box/employees/`
                , {
                    // job: "1ba2a8d9-49fa-45a6-a4e6-4410a7222692"
                    job: props.selectedJobID
                }).then((data) => {
                    let tempData = data.data;

                    let cities_marks = tempData.map((job, index) => (
                        <Marker
                            key={`marker-${index}`}
                            longitude={job?.geometry?.coordinates[0]}
                            latitude={job?.geometry?.coordinates[1]}
                            anchor="bottom"
                            onClick={e => {
                                e.originalEvent.stopPropagation();
                                var tempArr = [];
                                // const obj = {
                                //     properties: {
                                //         data: JSON.parse(job?.properties?.data)
                                //     }
                                // }
                                tempArr.push(job)
                                setClustArray(tempArr)
                            }}
                        >
                            <div className="avatar-marker">
                                <img src={job?.properties?.data?.image || UtilityFunctions.getImageURL() + "user-bg-dark.png"} style={{ width: 50, height: 50, borderRadius: 50 }} />
                            </div>
                        </Marker>
                    ))
                    setPins(cities_marks)

                    let tempDataNew = tempData.map((item) => {
                        return {
                            "type": item.type,
                            "properties": item.properties,
                            "geometry": {
                                "type": item.geometry.type,
                                "coordinates": [item.geometry.longitude, item.geometry.latitude]
                            }
                        }
                    })
                    // setInitialLatitude(props.employeeObject.location.latitude);
                    // setInitialLongitude(props.employeeObject.location.longitude);
                    setGeoJsonData(tempDataNew)
                    setClustArray([])
                }).catch(() => {

                })

            api.post(`map-box/employees-ungrouped/?distance=${props.requestDistance ? (props.requestDistance >= 50 ? "" : props.requestDistance) : 25}&request_received=${(props.requestReceived) ? props.requestReceived : false}&request_sent=${(props.requestSent) ? props.requestSent : false}&sort=${(props.closest) ? "distance" : "relevance"}`
                , {
                    // job: "1ba2a8d9-49fa-45a6-a4e6-4410a7222692"
                    job: props.selectedJobID
                }).then((data) => {
                    let groups = data.data
                    let ungroup_marks = groups.ungrouped.map((job, index) => (
                        <Marker
                            key={`marker-${index}`}
                            longitude={parseFloat(job?.geometry?.coordinates[0])}
                            latitude={parseFloat(job?.geometry?.coordinates[1])}
                            anchor="bottom"
                            onClick={e => {
                                e.originalEvent.stopPropagation();
                                var tempArr = [];
                                tempArr.push(job)
                                setClustArray(tempArr)
                            }}
                        >
                            <div className="avatar-marker">
                                <img src={job?.properties?.data?.image || UtilityFunctions.getImageURL() + "user-bg-dark.png"} style={{ width: 35, height: 35, borderRadius: 50 }} />
                            </div>
                        </Marker>
                    ))
                    setUngroupClusters(ungroup_marks)

                    let group_marks = groups?.grouped?.map((grp) => (
                        <>
                            {grp.users?.map((job, index) => (
                                <Marker
                                    key={`marker-${index}`}
                                    longitude={parseFloat(job?.geometry?.coordinates[0])}
                                    latitude={parseFloat(job?.geometry?.coordinates[1])}
                                    anchor="bottom"
                                    onClick={e => {
                                        e.originalEvent.stopPropagation();
                                        var tempArr = [];
                                        tempArr.push(job)
                                        setClustArray(tempArr)
                                    }}
                                >
                                    <div className="avatar-marker" style={{ marginLeft: -((index + 1) * 15) }}>
                                        <img src={job?.properties?.data?.image || UtilityFunctions.getImageURL() + "user-bg-dark.png"} style={{ width: 35, height: 35, borderRadius: 50 }} />
                                    </div>
                                </Marker>
                            ))}
                        </>
                    ))
                    setGroupClusters(group_marks)
                }).catch(() => {

                })
        }
    }, [props])

    // useEffect(() => {
    //     if (selectedUserId !== null) {
    //         api.get(`eligible-employee-profile/1ba2a8d9-49fa-45a6-a4e6-4410a7222692/${selectedUserId}?`, {})
    //             .then((data) => {
    //                 setSelectedUser(data)
    //             }).catch(() => {

    //             })
    //     }

    // }, [selectedUserId])

    useEffect(() => {
        if (selectedUserId !== null) {
            userService.showEmployeeSidebar(selectedUserId, props.selectedJobID)
                .then(res => {
                    setSelectedUser(res)
                })
                .catch(e => {

                })
        }
    }, [selectedUserId])

    return (
        <>
            <div>
                {showMap == true ?
                    <Map
                        initialViewState={{
                            // latitude: -33.8380738,
                            // longitude: 151.2068921,
                            latitude: initialLatitude,
                            longitude: initialLongitude,
                            zoom: window && window.innerWidth > 600 ? 10 : 13,
                            bearing: 0,
                            pitch: 0
                        }}
                        maxZoom={11}
                        // latitude={initialLatitude}
                        // longitude={initialLongitude}
                        // mapStyle={"mapbox://styles/mapbox/dark-v9"}
                        mapStyle={mapStyle && mapStyle.toJS()}
                        mapboxAccessToken="pk.eyJ1IjoiZmVhdHVyZWFwcGRldmVsb3BlciIsImEiOiJjbG4zMnZwMWMwZmRpMnNua2NkbWszc3JiIn0.m9ndBxecCbZq8ROLMTFX2A"
                        interactiveLayerIds={[clusterLayer.id, unclusteredPointLayer.id]}
                        onClick={onClick}
                        onZoom={(e) => handleZoom(e)}
                        ref={mapRef}
                        {...settings}
                        style={{ width: '100%', height: '555px' }}
                    >
                        <Marker
                            key={`marker-1`}
                            longitude={initialLongitude}
                            latitude={initialLatitude}
                            anchor="bottom"
                        >
                            <div className="job-icon-bg">
                                <img src={selectedJob?.cover || UtilityFunctions.getImageURL() + "bildeing-squre-img.png"} style={{ width: 30, height: 30, borderRadius: 50 }} />
                            </div>
                        </Marker>

                        <MapClusterBox clustArray={clustArray} setShowSidebar={setShowSidebar} setSelectedUserId={setSelectedUserId} />

                        <MapEmployeeSidebar setShowSidebar={setShowSidebar} showSidebar={showSidebar} selectedUser={selectedUser} setSelectedUser={setSelectedUser} selectedJobID={props?.selectedJobID} history={props.history} />

                        {showClusters ?
                            <>
                                <Source
                                    id="earthquakes"
                                    type="geojson"
                                    data={geojson}
                                    cluster={true}
                                    clusterMaxZoom={20}
                                    clusterRadius={50}
                                >
                                    <Layer {...clusterLayer} />
                                    <Layer {...clusterCountLayer} />
                                    <Layer {...unclusteredPointLayer} />
                                </Source>
                            </>
                            :
                            // pins
                            <>
                                {ungroupClusters}
                                {groupClusters}
                            </>
                        }

                        {/* <Source
                            id="earthquakes"
                            type="geojson"
                            data={geojson}
                            cluster={true}
                            clusterMaxZoom={20}
                            clusterRadius={50}
                        >
                            <Layer {...clusterLayer} />
                            <Layer {...clusterCountLayer} />
                            <Layer {...unclusteredPointLayer} />
                        </Source> */}
                        <NavigationControl />
                    </Map> :
                    userType?.user?.type === STRING_CONSTANTS.userType.employer ? <>

                        <div className="no-table-data no-table-one">
                            <div>
                                <div className="arrow-inner">
                                    <img
                                        src={UtilityFunctions.getImageURL() + "pointing-arrow.png"}
                                        alt=""
                                    />
                                </div>
                                <div>
                                    <img
                                        src={UtilityFunctions.getImageURL() + "no-data.svg"}
                                        alt=""
                                    />
                                    <p>
                                        It looks like you haven’t listed any active jobs yet. Tap
                                        the plus to create your first job listing.
                                    </p>
                                </div>
                            </div></div></> : <>
                        <div className="d-flex justify-content-center  " >
                            <div style={{
                                width: "276px",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                marginTop: "121px",
                                gap: "26px"
                            }}>
                                <span className="mx-auto">
                                    <svg width="105" height="116" viewBox="0 0 105 116" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path opacity="0.1" d="M24.0646 115.042C37.3551 115.042 48.1292 104.271 48.1292 90.9857C48.1292 77.6999 37.3551 66.9297 24.0646 66.9297C10.7741 66.9297 0 77.6999 0 90.9857C0 104.271 10.7741 115.042 24.0646 115.042Z" fill="#F2F2F2" />
                                        <path opacity="0.6" d="M53.1747 101.656C41.4111 101.656 30.0114 97.5791 20.9181 90.119C11.8247 82.659 5.60021 72.2778 3.30523 60.7444C1.01024 49.211 2.78674 37.2389 8.33202 26.8681C13.8773 16.4972 22.8483 8.36925 33.7164 3.86911C44.5845 -0.631026 56.6773 -1.22491 67.9343 2.18864C79.1914 5.60219 88.9161 12.812 95.4516 22.5895C101.987 32.3671 104.929 44.1075 103.776 55.8102C102.623 67.513 97.4464 78.454 89.1283 86.7692C84.4178 91.5038 78.8146 95.2574 72.6432 97.8128C66.4717 100.368 59.8546 101.675 53.1747 101.656ZM53.1747 0.388259C25.3521 0.388259 2.71668 23.0155 2.71668 50.8283C2.71668 78.6411 25.3521 101.268 53.1747 101.268C80.9973 101.268 103.633 78.6411 103.633 50.8283C103.633 23.0155 80.9972 0.388259 53.1747 0.388259Z" fill="#38BBF4" />
                                        <path d="M82.9271 59.512L82.9223 59.5163L62.3262 79.4766L32.2693 73.3304L31.7514 73.2245L25.7574 45.3519L45.1252 21.8854L45.4336 21.9797L74.3636 30.8539L82.9271 59.512Z" fill="#32AFE6" />
                                        <path opacity="0.3" d="M82.9216 59.5165L62.3255 79.4768L32.2686 73.3306L51.3396 50.5835L82.9216 59.5165Z" fill="black" />
                                        <path opacity="0.1" d="M82.9263 59.5108L51.3395 50.5821L45.4329 21.9786L74.3628 30.8528L82.9263 59.5108Z" fill="black" />
                                        <path d="M27.6776 53.9318L27.4921 53.7775L47.1202 30.2137L76.9825 39.3876L76.9116 39.6183L47.2027 30.4915L27.6776 53.9318Z" fill="#F2F2F2" />
                                        <path d="M29.7141 63.4036L29.5316 63.246L49.2338 40.4497L49.3064 40.4686L79.3861 48.2668L79.3253 48.5004L49.3183 40.7209L29.7141 63.4036Z" fill="#F2F2F2" />
                                        <path d="M42.8169 75.5513L42.641 75.3862L62.8828 53.8497L55.1606 25.0301L55.3937 24.9676L63.1507 53.9171L42.8169 75.5513Z" fill="#F2F2F2" />
                                        <path d="M53.6192 77.7594L53.4425 77.5953L72.8856 56.6812L65.2324 28.1191L65.4655 28.0567L73.1532 56.7477L53.6192 77.7594Z" fill="#F2F2F2" />
                                        <path d="M75.501 66.7954L44.2778 58.9824L44.2622 58.9088L38.1182 30.2562L38.3541 30.2057L44.4823 58.7847L75.5597 66.5613L75.501 66.7954Z" fill="#F2F2F2" />
                                        <path d="M68.2243 73.8502L37.579 66.9732L37.5631 66.8974L31.5688 38.1896L31.8051 38.1403L37.7836 66.7719L68.2773 73.6148L68.2243 73.8502Z" fill="#F2F2F2" />
                                        <path d="M84.4183 20.7574C89.7774 20.7574 94.1218 16.4146 94.1218 11.0574C94.1218 5.70026 89.7774 1.35742 84.4183 1.35742C79.0592 1.35742 74.7148 5.70026 74.7148 11.0574C74.7148 16.4146 79.0592 20.7574 84.4183 20.7574Z" fill="#06EFC5" />
                                    </svg>
                                </span>
                                <p style={{
                                    color: "white",
                                    textAlign: "center",
                                    fontSize: "16px", fontWeight: "500",
                                    lineHeight: "normal",
                                    letterSpacing: "1px"
                                }}>
                                    It looks like this feature is still being developed and will be added at a later stage
                                </p>
                            </div>
                        </div>
                    </>

                }

                {/* <div className="no-table-data no-table-one map-outer">
                    <div>
                        <div className="arrow-inner">
                            <img src={UtilityFunctions.getImageURL() + 'pointing-arrow.png'} alt="" />
                        </div>
                        <div>
                            <img src={UtilityFunctions.getImageURL() + 'no-data-map.png'} alt="" />
                            {/* <svg width="79" height="77" viewBox="0 0 79 77" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.9" d="M49.9896 17.2635H28.7717C28.2882 17.264 27.8246 17.4561 27.4827 17.7976C27.1408 18.1391 26.9485 18.6021 26.9479 19.085V66.1844L26.7047 66.2585L21.4996 67.8505C21.2529 67.9256 20.9865 67.8999 20.7588 67.779C20.531 67.6581 20.3607 67.4519 20.285 67.2057L4.80221 16.6939C4.72686 16.4475 4.75254 16.1814 4.87361 15.9539C4.99467 15.7264 5.20121 15.5562 5.44784 15.4808L13.4689 13.0277L36.7222 5.91881L44.7432 3.46577C44.8652 3.42825 44.9935 3.41514 45.1207 3.42719C45.2478 3.43924 45.3713 3.47621 45.4842 3.53599C45.597 3.59577 45.6969 3.67719 45.7782 3.77558C45.8595 3.87397 45.9205 3.9874 45.9579 4.10939L49.9155 17.0206L49.9896 17.2635Z" fill="white" />
                <path d="M54.6189 17.0213L49.8491 1.46037C49.7697 1.20109 49.64 0.959975 49.4673 0.750797C49.2946 0.541618 49.0823 0.368477 48.8426 0.241267C48.6029 0.114056 48.3404 0.0352686 48.0702 0.00940872C47.8 -0.0164512 47.5273 0.0111236 47.2678 0.0905546L35.9907 3.53815L12.7386 10.6483L1.46154 14.0971C0.937666 14.2578 0.499018 14.6195 0.241881 15.1028C-0.0152562 15.5862 -0.0698757 16.1517 0.0900137 16.6752L16.3923 69.8562C16.5222 70.2788 16.7842 70.6487 17.14 70.9116C17.4959 71.1745 17.9267 71.3167 18.3693 71.3171C18.5741 71.3172 18.7778 71.2865 18.9735 71.226L26.704 68.8628L26.9471 68.7875V68.5337L26.704 68.6078L18.9018 70.9941C18.4394 71.1348 17.94 71.0867 17.513 70.8602C17.0861 70.6337 16.7666 70.2473 16.6245 69.7858L0.323509 16.6036C0.253121 16.3749 0.228589 16.1346 0.251317 15.8964C0.274044 15.6583 0.343583 15.4269 0.455955 15.2157C0.568327 15.0044 0.721326 14.8173 0.906184 14.6652C1.09104 14.5131 1.30413 14.3988 1.53325 14.3291L12.8103 10.8802L36.0625 3.77132L47.3396 0.322502C47.5133 0.269531 47.694 0.242522 47.8757 0.242353C48.2657 0.243227 48.6451 0.368788 48.9584 0.600644C49.2717 0.8325 49.5025 1.15847 49.6169 1.5308L54.3648 17.0213L54.4402 17.2642H54.6931L54.6189 17.0213Z" fill="#38BBF4" />
                <path d="M14.915 15.5205C14.6806 15.5203 14.4525 15.4451 14.2641 15.306C14.0756 15.1668 13.9368 14.971 13.8679 14.7473L12.3019 9.63825C12.2598 9.50101 12.2452 9.35684 12.2589 9.21396C12.2726 9.07108 12.3144 8.93231 12.3818 8.80555C12.4492 8.6788 12.541 8.56655 12.6518 8.47522C12.7627 8.38389 12.8905 8.31526 13.0279 8.27327L34.4196 1.7322C34.6971 1.64763 34.9969 1.67647 35.2531 1.81241C35.5093 1.94835 35.7011 2.18027 35.7863 2.45727L37.3524 7.5664C37.437 7.84357 37.4081 8.14295 37.272 8.39885C37.1359 8.65476 36.9037 8.84629 36.6264 8.93145L15.2347 15.4725C15.1311 15.5043 15.0233 15.5204 14.915 15.5205Z" fill="#06EFC5" />
                <path d="M23.1192 5.45807C24.4622 5.45807 25.5509 4.37069 25.5509 3.02933C25.5509 1.68797 24.4622 0.600586 23.1192 0.600586C21.7762 0.600586 20.6875 1.68797 20.6875 3.02933C20.6875 4.37069 21.7762 5.45807 23.1192 5.45807Z" fill="#06EFC5" />
                <path d="M23.1199 4.56712C23.9703 4.56712 24.6598 3.87856 24.6598 3.02917C24.6598 2.17978 23.9703 1.49121 23.1199 1.49121C22.2695 1.49121 21.5801 2.17978 21.5801 3.02917C21.5801 3.87856 22.2695 4.56712 23.1199 4.56712Z" fill="white" />
                <path opacity="0.9" d="M73.2721 70.6967H32.1761C31.9021 70.6964 31.6394 70.5875 31.4456 70.394C31.2519 70.2005 31.1429 69.9381 31.1426 69.6644V20.4824C31.1429 20.2087 31.2519 19.9464 31.4456 19.7529C31.6394 19.5593 31.9021 19.4505 32.1761 19.4502H73.2721C73.5461 19.4505 73.8088 19.5594 74.0025 19.7529C74.1963 19.9464 74.3053 20.2087 74.3056 20.4824V69.6644C74.3053 69.9381 74.1963 70.2005 74.0025 70.394C73.8088 70.5875 73.5461 70.6963 73.2721 70.6967Z" fill="white" />
                <path d="M54.3639 17.0215H28.7701C28.2221 17.0223 27.6968 17.24 27.3094 17.627C26.9219 18.014 26.7039 18.5386 26.7031 19.0859V68.608L26.9463 68.5339V19.0859C26.9469 18.603 27.1392 18.14 27.4811 17.7985C27.823 17.457 28.2866 17.2649 28.7701 17.2644H54.4393L54.3639 17.0215ZM76.6749 17.0215H28.7701C28.2221 17.0223 27.6968 17.24 27.3094 17.627C26.9219 18.014 26.7039 18.5386 26.7031 19.0859V74.7041C26.7039 75.2514 26.9219 75.7761 27.3094 76.163C27.6968 76.55 28.2221 76.7678 28.7701 76.7686H76.6749C77.2229 76.7678 77.7482 76.55 78.1356 76.163C78.5231 75.7761 78.7411 75.2514 78.7419 74.7041V19.0859C78.7411 18.5386 78.5231 18.014 78.1356 17.627C77.7482 17.24 77.2229 17.0223 76.6749 17.0215ZM78.4987 74.7041C78.4981 75.187 78.3058 75.65 77.9639 75.9915C77.622 76.333 77.1585 76.5251 76.6749 76.5257H28.7701C28.2866 76.5251 27.823 76.333 27.4811 75.9915C27.1392 75.65 26.9469 75.187 26.9463 74.7041V19.0859C26.9469 18.603 27.1392 18.14 27.4811 17.7985C27.823 17.457 28.2866 17.2649 28.7701 17.2644H76.6749C77.1585 17.2649 77.622 17.457 77.9639 17.7985C78.3058 18.14 78.4981 18.603 78.4987 19.0859V74.7041Z" fill="#38BBF4" />
                <path d="M63.9094 22.3641H41.5376C41.2475 22.3637 40.9694 22.2485 40.7642 22.0436C40.5591 21.8387 40.4437 21.5609 40.4434 21.2711V15.9279C40.4437 15.6381 40.5591 15.3603 40.7642 15.1554C40.9694 14.9505 41.2475 14.8353 41.5376 14.835H63.9094C64.1996 14.8353 64.4777 14.9505 64.6828 15.1554C64.888 15.3603 65.0034 15.6381 65.0037 15.9279V21.2711C65.0034 21.5609 64.888 21.8387 64.6829 22.0436C64.4777 22.2485 64.1996 22.3637 63.9094 22.3641Z" fill="#06EFC5" />
                <path d="M52.7227 15.1993C54.0657 15.1993 55.1545 14.1119 55.1545 12.7705C55.1545 11.4292 54.0657 10.3418 52.7227 10.3418C51.3797 10.3418 50.291 11.4292 50.291 12.7705C50.291 14.1119 51.3797 15.1993 52.7227 15.1993Z" fill="#06EFC5" />
                <path d="M52.7233 14.2507C53.5413 14.2507 54.2045 13.5883 54.2045 12.7713C54.2045 11.9543 53.5413 11.292 52.7233 11.292C51.9053 11.292 51.2422 11.9543 51.2422 12.7713C51.2422 13.5883 51.9053 14.2507 52.7233 14.2507Z" fill="white" />
            </svg> *
                            {/* <img src="./" /> *
                            <p>It looks like this feature is still being developed and will be added at a later stage</p>
                        </div>
                    </div>
                </div> 
                }

                {/* <div className="no-table-data no-table-one map-outer">
                    <div>
                        <div className="arrow-inner">
                            <img src={UtilityFunctions.getImageURL() + 'pointing-arrow.png'} alt="" />
                        </div>
                        <div>
                            <img src={UtilityFunctions.getImageURL() + 'no-data-map.png'} alt="" />
                            {/* <svg width="79" height="77" viewBox="0 0 79 77" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.9" d="M49.9896 17.2635H28.7717C28.2882 17.264 27.8246 17.4561 27.4827 17.7976C27.1408 18.1391 26.9485 18.6021 26.9479 19.085V66.1844L26.7047 66.2585L21.4996 67.8505C21.2529 67.9256 20.9865 67.8999 20.7588 67.779C20.531 67.6581 20.3607 67.4519 20.285 67.2057L4.80221 16.6939C4.72686 16.4475 4.75254 16.1814 4.87361 15.9539C4.99467 15.7264 5.20121 15.5562 5.44784 15.4808L13.4689 13.0277L36.7222 5.91881L44.7432 3.46577C44.8652 3.42825 44.9935 3.41514 45.1207 3.42719C45.2478 3.43924 45.3713 3.47621 45.4842 3.53599C45.597 3.59577 45.6969 3.67719 45.7782 3.77558C45.8595 3.87397 45.9205 3.9874 45.9579 4.10939L49.9155 17.0206L49.9896 17.2635Z" fill="white" />
                <path d="M54.6189 17.0213L49.8491 1.46037C49.7697 1.20109 49.64 0.959975 49.4673 0.750797C49.2946 0.541618 49.0823 0.368477 48.8426 0.241267C48.6029 0.114056 48.3404 0.0352686 48.0702 0.00940872C47.8 -0.0164512 47.5273 0.0111236 47.2678 0.0905546L35.9907 3.53815L12.7386 10.6483L1.46154 14.0971C0.937666 14.2578 0.499018 14.6195 0.241881 15.1028C-0.0152562 15.5862 -0.0698757 16.1517 0.0900137 16.6752L16.3923 69.8562C16.5222 70.2788 16.7842 70.6487 17.14 70.9116C17.4959 71.1745 17.9267 71.3167 18.3693 71.3171C18.5741 71.3172 18.7778 71.2865 18.9735 71.226L26.704 68.8628L26.9471 68.7875V68.5337L26.704 68.6078L18.9018 70.9941C18.4394 71.1348 17.94 71.0867 17.513 70.8602C17.0861 70.6337 16.7666 70.2473 16.6245 69.7858L0.323509 16.6036C0.253121 16.3749 0.228589 16.1346 0.251317 15.8964C0.274044 15.6583 0.343583 15.4269 0.455955 15.2157C0.568327 15.0044 0.721326 14.8173 0.906184 14.6652C1.09104 14.5131 1.30413 14.3988 1.53325 14.3291L12.8103 10.8802L36.0625 3.77132L47.3396 0.322502C47.5133 0.269531 47.694 0.242522 47.8757 0.242353C48.2657 0.243227 48.6451 0.368788 48.9584 0.600644C49.2717 0.8325 49.5025 1.15847 49.6169 1.5308L54.3648 17.0213L54.4402 17.2642H54.6931L54.6189 17.0213Z" fill="#38BBF4" />
                <path d="M14.915 15.5205C14.6806 15.5203 14.4525 15.4451 14.2641 15.306C14.0756 15.1668 13.9368 14.971 13.8679 14.7473L12.3019 9.63825C12.2598 9.50101 12.2452 9.35684 12.2589 9.21396C12.2726 9.07108 12.3144 8.93231 12.3818 8.80555C12.4492 8.6788 12.541 8.56655 12.6518 8.47522C12.7627 8.38389 12.8905 8.31526 13.0279 8.27327L34.4196 1.7322C34.6971 1.64763 34.9969 1.67647 35.2531 1.81241C35.5093 1.94835 35.7011 2.18027 35.7863 2.45727L37.3524 7.5664C37.437 7.84357 37.4081 8.14295 37.272 8.39885C37.1359 8.65476 36.9037 8.84629 36.6264 8.93145L15.2347 15.4725C15.1311 15.5043 15.0233 15.5204 14.915 15.5205Z" fill="#06EFC5" />
                <path d="M23.1192 5.45807C24.4622 5.45807 25.5509 4.37069 25.5509 3.02933C25.5509 1.68797 24.4622 0.600586 23.1192 0.600586C21.7762 0.600586 20.6875 1.68797 20.6875 3.02933C20.6875 4.37069 21.7762 5.45807 23.1192 5.45807Z" fill="#06EFC5" />
                <path d="M23.1199 4.56712C23.9703 4.56712 24.6598 3.87856 24.6598 3.02917C24.6598 2.17978 23.9703 1.49121 23.1199 1.49121C22.2695 1.49121 21.5801 2.17978 21.5801 3.02917C21.5801 3.87856 22.2695 4.56712 23.1199 4.56712Z" fill="white" />
                <path opacity="0.9" d="M73.2721 70.6967H32.1761C31.9021 70.6964 31.6394 70.5875 31.4456 70.394C31.2519 70.2005 31.1429 69.9381 31.1426 69.6644V20.4824C31.1429 20.2087 31.2519 19.9464 31.4456 19.7529C31.6394 19.5593 31.9021 19.4505 32.1761 19.4502H73.2721C73.5461 19.4505 73.8088 19.5594 74.0025 19.7529C74.1963 19.9464 74.3053 20.2087 74.3056 20.4824V69.6644C74.3053 69.9381 74.1963 70.2005 74.0025 70.394C73.8088 70.5875 73.5461 70.6963 73.2721 70.6967Z" fill="white" />
                <path d="M54.3639 17.0215H28.7701C28.2221 17.0223 27.6968 17.24 27.3094 17.627C26.9219 18.014 26.7039 18.5386 26.7031 19.0859V68.608L26.9463 68.5339V19.0859C26.9469 18.603 27.1392 18.14 27.4811 17.7985C27.823 17.457 28.2866 17.2649 28.7701 17.2644H54.4393L54.3639 17.0215ZM76.6749 17.0215H28.7701C28.2221 17.0223 27.6968 17.24 27.3094 17.627C26.9219 18.014 26.7039 18.5386 26.7031 19.0859V74.7041C26.7039 75.2514 26.9219 75.7761 27.3094 76.163C27.6968 76.55 28.2221 76.7678 28.7701 76.7686H76.6749C77.2229 76.7678 77.7482 76.55 78.1356 76.163C78.5231 75.7761 78.7411 75.2514 78.7419 74.7041V19.0859C78.7411 18.5386 78.5231 18.014 78.1356 17.627C77.7482 17.24 77.2229 17.0223 76.6749 17.0215ZM78.4987 74.7041C78.4981 75.187 78.3058 75.65 77.9639 75.9915C77.622 76.333 77.1585 76.5251 76.6749 76.5257H28.7701C28.2866 76.5251 27.823 76.333 27.4811 75.9915C27.1392 75.65 26.9469 75.187 26.9463 74.7041V19.0859C26.9469 18.603 27.1392 18.14 27.4811 17.7985C27.823 17.457 28.2866 17.2649 28.7701 17.2644H76.6749C77.1585 17.2649 77.622 17.457 77.9639 17.7985C78.3058 18.14 78.4981 18.603 78.4987 19.0859V74.7041Z" fill="#38BBF4" />
                <path d="M63.9094 22.3641H41.5376C41.2475 22.3637 40.9694 22.2485 40.7642 22.0436C40.5591 21.8387 40.4437 21.5609 40.4434 21.2711V15.9279C40.4437 15.6381 40.5591 15.3603 40.7642 15.1554C40.9694 14.9505 41.2475 14.8353 41.5376 14.835H63.9094C64.1996 14.8353 64.4777 14.9505 64.6828 15.1554C64.888 15.3603 65.0034 15.6381 65.0037 15.9279V21.2711C65.0034 21.5609 64.888 21.8387 64.6829 22.0436C64.4777 22.2485 64.1996 22.3637 63.9094 22.3641Z" fill="#06EFC5" />
                <path d="M52.7227 15.1993C54.0657 15.1993 55.1545 14.1119 55.1545 12.7705C55.1545 11.4292 54.0657 10.3418 52.7227 10.3418C51.3797 10.3418 50.291 11.4292 50.291 12.7705C50.291 14.1119 51.3797 15.1993 52.7227 15.1993Z" fill="#06EFC5" />
                <path d="M52.7233 14.2507C53.5413 14.2507 54.2045 13.5883 54.2045 12.7713C54.2045 11.9543 53.5413 11.292 52.7233 11.292C51.9053 11.292 51.2422 11.9543 51.2422 12.7713C51.2422 13.5883 51.9053 14.2507 52.7233 14.2507Z" fill="white" />
            </svg> *
                            {/* <img src="./" /> *
                            <p>It looks like this feature is still being developed and will be added at a later stage</p>
                        </div>
                    </div>
                </div> */}
            </div>
        </>)
}
export default MapView;