
import React, { useState, Fragment, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { UtilityFunctions } from "../utils/UtilityFunctions";
import { STRING_CONSTANTS } from "../utils/constants/stringConstants";
import ValidationErrorToast from '../components/validationErrorToast';
import ToastHeader from "../components/toastHeader";
import heic2any from "heic2any";
import { map, get } from 'lodash';
import { api } from "../middleware/api";
import CompletionPage from "../components/completionPage";
import ImageCrop from "../common/ui/ImageCrop";

const GuardianDocumentUpload = (props: any) => {
    const [documentTypeIds, setDocumentTypeIds] = useState([]);
    const [documentTypes, setDocumentTypes] = useState([]);
    const [documents, setDocuments] = useState({});
    const [documentImage, setImageDocument] = useState({});
    const [selectedTab, setSelectedTab] = useState("Driver's License");
    const [isTokenActive, setIsTokenActive] = useState(true);
    const [urlParams, setUrlParams] = useState({
        'uidb': null, 'token': null
    })
    const [fileUploaded, setFileUploaded] = useState(false);
    const [documentsUploadCount, setDocumentsUploadCount] = useState(0);


    useEffect(() => {
        setUrlParams({ "uidb": get(props, 'match.params.uidb'), "token": get(props, 'match.params.token') });
        api.get(`${get(props, 'match.params.uidb')}/${get(props, 'match.params.token')}/document-types/`, null)
            .then(res => {
                let document_types = {};
                map(res.choices, (key, value) => {
                    document_types = { ...document_types, [value]: null };
                });
                setDocumentTypeIds(res.choices);
                setDocumentTypes(map(res.choices, (key, value) => value));
                setImageDocument(document_types);
            })
            .catch((e) => {
                setIsTokenActive(false)
            })
    }, [])

    let handleSubmit: any = () => {
        if (documentsUploadCount > 0 && documentsUploadCount <= 2) {
            let formData = new FormData();

            map(documents, (key, value) => {
                formData.append(documentTypeIds[value], key);
            });
            api.post(`${get(urlParams, 'uidb')}/${get(urlParams, 'token')}/guardian-document-upload/`, formData).then(
                res => setFileUploaded(true)
            ).catch((err) => {
                ToastHeader(err);
            });
        } else {
            ValidationErrorToast(STRING_CONSTANTS.GUARDIAN.DOCUMENTS, STRING_CONSTANTS.GUARDIAN.NO_DOCUMENT);
        }
    }

    let profileImageRemoveCheck: any = (name: any) => {
        delete documents[name];
        setImageDocument({ ...documentImage, [name]: null });
        setDocumentsUploadCount(documentsUploadCount - 1);
    }

    return (
        (
            !fileUploaded ?
                (<Fragment>
                    <section className="main-signup-wrap">
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                            }}

                        >
                            <div className="row">
                                <div className="col-md-2 col-lg-3 col-xl-3 col-12">
                                    <div className="left-wrap">
                                        <div className="logo-wrap">
                                            <img
                                                alt=""
                                                src={UtilityFunctions.getImageURL() + "logo_White.svg"}
                                                onClick={() => props.history.push("/")}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8 col-lg-6 col-xl-6 col-12">
                                    {!isTokenActive ?
                                        <div className='middle-wrap guradian-document'>
                                            <div className="step-content">
                                                <p>The link is expired</p>
                                            </div>
                                        </div> :
                                        <div className="middle-wrap guradian-document">
                                            <div className="step-content">
                                                <button type='button' disabled>{STRING_CONSTANTS.GUARDIAN.buttons.guardian_verification}</button>
                                                <p>{STRING_CONSTANTS.GUARDIAN.DOCUMENT_UPLOAD}</p>
                                            </div>
                                            <div className='form-wrap upload-image'>
                                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                                    {map(documentTypes, val => {
                                                        return (
                                                            <li className="nav-item" role="presentation" id={val}>
                                                                <button className={`nav-links ${selectedTab === val ? 'active' : ''}`} id={val} data-bs-toggle="tab" data-bs-target="#driver" type="button" role="tab" aria-controls="driver" aria-selected="true" onClick={(e) => setSelectedTab(get(e, 'target.id'))}>{val}</button>
                                                            </li>)
                                                    })}
                                                </ul>

                                                <div className="tab-content" id={get(documentImage, selectedTab)}>
                                                    <div className="tab-pane fade show active" id={get(documentImage, selectedTab)} role="tabpanel" aria-labelledby="driver-tab">
                                                        {map(documentTypes, val => {
                                                            return val == selectedTab &&
                                                                <ImageCrop
                                                                    ratio={16 / 9}
                                                                    croppedImage={(imgSrc, file) => {
                                                                        if (documentsUploadCount < 2) {
                                                                            setDocumentsUploadCount(documentsUploadCount + 1);
                                                                            setImageDocument({ ...documentImage, [val]: imgSrc });
                                                                            setDocuments({ ...documents, [val]: file }); //here we have using base64 image for showing and api call
                                                                        }
                                                                        else {
                                                                            ValidationErrorToast(STRING_CONSTANTS.GUARDIAN.DOCUMENTS, STRING_CONSTANTS.GUARDIAN.TWO_DOCUMENTS_UPLOADED)
                                                                        }
                                                                    }}
                                                                    size={25}
                                                                />
                                                        })}
                                                        <label className='file-thumb'>
                                                            {get(documentImage, selectedTab) ?
                                                                <div className="crossed-icon">
                                                                    <img alt="" className="image-show" src={UtilityFunctions.getImageURL() + "cross-icon.svg"} onClick={() => profileImageRemoveCheck(selectedTab)} />
                                                                    <img alt="" className="image-show" src={get(documentImage, selectedTab)} />
                                                                </div> :
                                                                <label htmlFor='image_id' className="gap-2">
                                                                    {/* <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M9.82869 0.206923L9.94531 0.200195C10.4581 0.200195 10.8808 0.586236 10.9386 1.08357L10.9453 1.2002V8.2002H17.9453C18.4581 8.2002 18.8808 8.58624 18.9386 9.08357L18.9453 9.2002C18.9453 9.71303 18.5593 10.1357 18.0619 10.1935L17.9453 10.2002H10.9453V17.2002C10.9453 17.713 10.5593 18.1357 10.0619 18.1935L9.94531 18.2002C9.43248 18.2002 9.00981 17.8142 8.95204 17.3168L8.94531 17.2002V10.2002H1.94531C1.43248 10.2002 1.00981 9.81415 0.95204 9.31682L0.945312 9.2002C0.945312 8.68736 1.33135 8.26469 1.82869 8.20692L1.94531 8.2002H8.94531V1.2002C8.94531 0.687359 9.33135 0.264688 9.82869 0.206923L9.94531 0.200195L9.82869 0.206923Z" fill="#38BBF4" />
                                                                    </svg> */}
                                                                    <img src={UtilityFunctions.getImageURL() + "add_img.svg"} className="myadd" alt="" />
                                                                    <p>Upload Image</p>
                                                                </label>
                                                            }
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="next-wrap center-block">
                                                <div className="btnfgo-wrap"><button onClick={handleSubmit}>Submit</button></div>
                                            </div>

                                        </div>}
                                </div>
                            </div>
                        </form>
                    </section>
                </Fragment >
                )
                :
                <Fragment>
                    <section className="main-signup-wrap">
                        <CompletionPage
                            buttonText={STRING_CONSTANTS.GUARDIAN.buttons.guardian_verification}
                            stepText={STRING_CONSTANTS.GUARDIAN.documents_submitted} />
                    </section>
                </Fragment>
        )
    )
};
export default withRouter(GuardianDocumentUpload); 