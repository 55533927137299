import { Action } from "../actions/index";
import { ActionType } from "../constants";
import { get } from "lodash";
import { cookieService } from "../../_services";

const initialState = {
  unreadNotificationCount: 0,
};

const reducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.SET_UNREAD_COUNT: {
      state = { unreadNotificationCount: get(action, "item") };
      cookieService.saveCookie("unreadCount", get(action, "item"));
      return state;
    }
    default:
      return state;
  }
};

export default reducer;
