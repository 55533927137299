import React from "react";
import "./JobSearch.css";

function JobSearch({ title= {}, description = "", subDescription = {}, showButtons = false, image={} }) {
  return (
    <div className="container-fluid Job-search-section">
      <div className="row wrapper">
        <div className="col-lg-6 col-md-12">
          <div className="content-wrap">
            {title}
            <div className="description">{description}</div>
            {subDescription}
            {showButtons ? (
              <>
              <div className="download-btn">
                <div className="app-store">
                  {" "}
                 <a href="https://apps.apple.com/au/app/mploi/id1626118075"><img src="/assets/images/app-store.svg" alt="" /></a> 
                </div>
                <div className="play-store">
                  {" "}
                 <a href="https://play.google.com/store/apps/details?id=au.com.mploi.mobile.apps&hl=en"> <img src="/assets/images/play-store.svg" alt="" /></a>
                </div>
              </div>
              <div className="download-btn-mob">
              <div className="app-store mt-4">
                {" "}
               <a href="https://apps.apple.com/au/app/mploi/id1626118075"><img src="/assets/images/app-store-mob.svg" className="img-fluid" alt="" /></a> 
              </div>
              <div className="play-store mt-4">
                {" "}
               <a href="https://play.google.com/store/apps/details?id=au.com.mploi.mobile.apps&hl=en"><img src="/assets/images/play-store-mob.svg" className="img-fluid" alt="" /></a> 
              </div>
            </div>
            </>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="col-lg-6 col-md-12 p-0">
          <div className="right-img">
          {image}
          </div>
           
        </div>
      </div>
    </div>
  );
}

export default JobSearch;
