import React from "react";
import { withRouter } from "react-router-dom";
import "./paymentProcedure.css"
import { api } from "../../../middleware/api";
import ValidationErrorToast from "../../../components/validationErrorToast";

const PaymentProcedure = (props: any) => {

    const handlePropagation = (e) => {
        e.stopPropagation();
    }

    const handleAddCard = () => {
        api.post(`subscription/intent/`, {})
            .then((data) => {
                window.location.href = `${data?.data["customer portal"]?.url}`
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const handleCreateSubscription = () => {
        api.post(`subscription/`, {
            "product_id": props.productId,
            "promo_code": props.couponCodeValue
        })
            .then((data) => {
                if (data.data.checkoutUrl && data.data.checkoutUrl !== undefined && data.data.checkoutUrl != "") {
                    window.location.href = data.data.checkoutUrl
                } else {
                    ValidationErrorToast("Something went wrong while making payment.", "Payment error");
                }
            })
            .catch((err) => {
                ValidationErrorToast("Something went wrong while making payment.", "Payment error");
            });
    }

    return <div className=" d-flex justify-content-center align-items-center " >
        <div className=" pay_box" onClick={handlePropagation}>
            <div className="mt-3">
                <img src="/assets/images/storecard.svg" alt="" />
            </div>
            <div className=" container-fluid pay_title my-6">Payment Procedure</div>
            <div className=" container-fluid pay_desc">You are about to purchase a subscription on MPLOI</div>
            {props.isCardAdded ?
                <>
                    {/* <div className="d-flex justify-content-center align-items-center my-4">
                        <div className="card_details_btn" onClick={handleAddCard}>
                            <div className="card_details_text">Manage Cards</div>
                        </div>
                    </div> */}
                    <div className="d-flex justify-content-center align-items-center my-4">
                        <div className="btn_pay_continue" onClick={handleCreateSubscription}>
                            <div className="continue_text">Make Payment</div>
                        </div>
                    </div>

                </>
                :
                <div className="d-flex justify-content-center align-items-center my-4">
                    <div className="card_details_btn" onClick={handleAddCard}>
                        <div className="card_details_text">Add Card Details</div>
                    </div>
                </div>
            }

        </div>
    </div>
}
export default withRouter(PaymentProcedure);

