import React from 'react';
import './ExploreCard.css';

const ExploreCard = ({ title, subtitle, price, duration, description, badgeText, badgeImage, from }) => {
  return (
    <div className="explore-card">
      <div className="badge-wrap">
        <div className="badge-icon">
          <img src={badgeImage} alt="Badge" />
        </div>
        <div className="subscription-badge">{badgeText}</div>
      </div>
      <div className='content-wrap'>
      <div className="title">{title}</div>
      <div className="sub-title">{subtitle}</div>
      {
        from ? (
          <div className="sub-from">{from}</div>
        ) : ''
      }
      <div className="price">{price} {duration}</div>
      </div>
    
      <div className="description">{description}</div>
    </div>
  );
};

export default ExploreCard;
