import React, { useEffect, useRef, useState } from "react"
import ValidationErrorToast from "../../../components/validationErrorToast";
import { api } from "../../../middleware/api";
import { UtilityFunctions } from "../../../utils/UtilityFunctions";
import { useDispatch, useSelector } from "react-redux";
import { actionCreators, State } from "../../../store";
import { bindActionCreators } from "redux";
interface JobSubcategoryProps {
    nav: {
        next: Function,
        back: Function
    }
}

const JobSubcategory = (props: JobSubcategoryProps) => {
    const [subcategories, setSubCategories] = useState<any>();
    const [selectedSubcategory, setSelectedSubcategory] = useState<any>();
    const [searchInput, setSearchInput] = useState<any>();
    const [searchResults, setSearchResults] = useState<any>();
    // const inputRef = useRef<any>('')
    const dispatch = useDispatch()
    const { setJobSubcategory } = bindActionCreators(actionCreators, dispatch)
    const state = useSelector((state: State) => state)
    const selectedCategory = useSelector((state: State) => state?.newJob?.category);

    useEffect(() => {
        api.get(`sub-category/?categoryIds=${selectedCategory.id}`, null)
            .then(res => {
                setSubCategories(res)
                setSearchResults(res)
                setSelectedSubcategory(state.newJob.subCategory)
                // inputRef.current.value = state.newJob.subCategory.name || ""
            })
    }, [selectedCategory, state.newJob.subCategory])

    useEffect(() => {
        if (searchInput) {
            //setSelectedSubcategory('')
            setSearchResults(subcategories?.filter(subcategory => {
                return subcategory?.name?.toLowerCase().indexOf(searchInput.toLowerCase()) > -1;
            }))
        }
        else {
            //setSelectedSubcategory('')
            setSearchResults(subcategories)
        }
    }, [searchInput])

    const submitHandler = (e) => {
        e.preventDefault();
        if (Object.keys(selectedSubcategory).length === 0) {
            ValidationErrorToast('Job Subcategory', 'Please select a job subcategory from the results before proceeding')
        }
        else {
            setJobSubcategory(selectedSubcategory)
            props.nav.next()
        }
    }

    const renderSubcategoryList =
        searchResults?.
            map(subcategory => {
                return <button key={subcategory?.id} className={selectedSubcategory?.id === subcategory?.id ? 'active' : ""} onChange={() => setJobSubcategory({})} type="button" onClick={(e) => {
                    if (selectedSubcategory?.id === subcategory?.id) {
                        setSelectedSubcategory("")
                        // inputRef.current.value = ''
                    }
                    else {
                        setSelectedSubcategory(subcategory)
                        // inputRef.current.value = subcategory?.name

                    }
                }
                }>{subcategory?.name}</button>
            })

    return (<>
        <div className='top-divde-spa'>
            <span>
                <i
                    className="fa fa-angle-left text-white d-flex justify-content-between"
                    aria-hidden="true"
                    onClick={() => {
                        props?.nav?.back()
                    }}
                ></i>
            </span>
            <div className="step-content paragraph-spacin">
                <button type='button' disabled>
                    Create a Job
                </button>
                <p>Select a job subcategory that applies to your new job</p>
                <div className="step-text">
                    Step 2/8
                </div>
            </div>
        </div>
        <form onSubmit={submitHandler}>
            <div className="form-wrap mh-auto">
                <div className="v-form-width min-height-auto">
                    <div className="form-floating mb-3 label-dark">
                        <input type='text' className="form-control" placeholder="Search Job Title"
                            // ref={inputRef} 
                            onChange={e => setSearchInput(e.target.value)}></input>
                        <label htmlFor="floatingInput">Search Job Title</label>
                        <span className="input-group-text">
                            <img src={UtilityFunctions.getImageURL() + "search-with-shadow.svg"} alt="" />
                            <img src={UtilityFunctions.getImageURL() + "search-with-shadow-fill.svg"} alt="" />
                            {/* <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g filter="url(#filter0_f_4_8)">
                                    <circle cx="12" cy="12" r="7" stroke="#D0D0D0" strokeWidth="2" />
                                    <path d="M21 21L18 18" stroke="#D0D0D0" strokeWidth="2" strokeLinecap="round" />
                                </g>
                                <circle cx="12" cy="12" r="7" stroke="#D0D0D0" strokeWidth="2" />
                                <path d="M21 21L18 18" stroke="#D0D0D0" strokeWidth="2" strokeLinecap="round" />
                                <defs>
                                    <filter id="filter0_f_4_8" x="-3" y="-3" width="32" height="32" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                        <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_4_8" />
                                    </filter>
                                </defs>
                            </svg> */}
                            {/* <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g filter="url(#filter0_f_4_8)">
                                    <circle cx="12" cy="12" r="7" stroke="#D0D0D0" strokeWidth="2" />
                                    <path d="M21 21L18 18" stroke="#D0D0D0" strokeWidth="2" strokeLinecap="round" />
                                </g>
                                <circle cx="12" cy="12" r="7" stroke="#D0D0D0" strokeWidth="2" />
                                <path d="M21 21L18 18" stroke="#D0D0D0" strokeWidth="2" strokeLinecap="round" />
                                <defs>
                                    <filter id="filter0_f_4_8" x="-3" y="-3" width="32" height="32" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                        <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_4_8" />
                                    </filter>
                                </defs>
                            </svg> */}
                        </span>
                    </div>
                </div>
                <div className="all-cat-width text-white">
                    {(!renderSubcategoryList?.length && !searchInput?.length) ? <div></div> : renderSubcategoryList?.length > 0 ? renderSubcategoryList : "No matching category found. Please try again !"}
                </div>
            </div>
        </form >
        <div className="next-wrap">
            <button className={selectedSubcategory?.name ? "cricle-next" : "cricle-next-inactive"}
                onClick={(e) => submitHandler(e)}>
                <img
                    alt=""
                    src={UtilityFunctions.getImageURL() + "arrow-bottom.svg"}
                />
            </button>
        </div>
    </>)
}

export default (JobSubcategory)