import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import JobSearch from "../../components/JobSearch/JobSearch";
import Footer from "../../components/Footer/Footer";
import Section1 from "../../components/LandingPage/Section1/Section1";
import Section3 from "../../components/LandingPage/Section3/Section3";
import "./Employers.css";
import Slider from "react-slick";

export default function Employers() {
  const originalAccrodianData = [
    {
      heading: (
        <>
          <div className=" mr-2">AI Assisted Profile Creation</div>
          <img
            src="/assets/images/employeesLandingPage/exploreFirst.svg"
            alt=""
            className="iconImage"
          />
        </>
      ),
      description: (
        <div>
          We know you're a busy business owner, juggling a million tasks, and
          the last thing you want to do is spend hours crafting the perfect
          company profile. That's where MPLOI's AI-powered Assisted Profile
          Creation comes in like a knight in shining armour! With just a few
          clicks and some basic information, our intelligent system will whip up
          a stunning profile that captures the essence of your brand faster than
          you can say "hire me!" It's like having a personal branding guru at
          your fingertips, ensuring your company stands out from the crowd and
          attracts top-notch talent. Plus, with all the time you'll save, you
          can focus on what really matters – growing your business and making
          those big bucks!
        </div>
      ),
    },
    {
      heading: (
        <>
          <div className=" mr-2">AI Job Postings</div>
          <img
            src="/assets/images/employersLandingPage/briefcase.svg"
            alt=""
            className="iconImage"
          />
        </>
      ),

      description: (
        <div>
          Just imagine, with an AI-assisted job posting tool like the one MPLOI
          offers, crafting the perfect job ad becomes a breeze! No more staring
          at a blank screen, wondering how to make your company sound appealing
          to top talent. The AI assistant can analyse successful job postings in
          your industry and suggest the most effective language and structure to
          grab candidates' attention. It's like having a team of expert
          recruiters at your fingertips, without the hefty price tag!
        </div>
      ),
    },
    {
      heading: (
        <>
          <div className=" mr-2">Real AI Job Matching</div>
          <img
            src="/assets/images/employeesLandingPage/exploreSecond.svg"
            alt=""
            className="iconImage"
          />
        </>
      ),

      description: (
        <div>
          Transform your recruitment process and slash costs with MPLOI's
          groundbreaking AI job matching feature. Say goodbye to expensive
          recruitment companies and hello to a streamlined list of top-tier
          candidates handpicked for your open positions. Our advanced algorithms
          dive deep into each candidate's skills, experience, and job
          preferences to play recruitment matchmaker, delivering your dream
          hires faster than you can say 'you're hired!' – all without the hefty
          price tag of traditional recruiting firms. With MPLOI, you're not just
          another job posting lost in the digital abyss; you're a savvy employer
          with a lineup of exceptional talent eager to join your ranks while
          keeping your recruitment budget in check. So sit back, relax, and let
          our AI handle the legwork while you prepare to welcome your new
          superstars on board and enjoy the savings.
        </div>
      ),
    },
    {
      heading: (
        <>
          <div className=" mr-2">AI HR Assist</div>
          <img
            src="/assets/images/employersLandingPage/assist.svg"
            alt=""
            className="iconImage"
          />
        </>
      ),
      description: (
        <div>
          Imagine having a knowledgeable, always-available digital companion to
          help navigate the often complex world of human resources, without
          breaking the bank. This savvy AI sidekick would be like having your
          own personal HR guru, ready to offer advice, keep meticulous records,
          and even whip up documents faster than you can say "employee
          handbook." It's like having a superhero in your corner, minus the cape
          and spandex. Plus, with its ability to continuously learn and adapt to
          your unique business needs, it's like having a custom-tailored
          solution that grows with you. In a world where time and resources are
          precious, having such a handy tool could be the secret weapon that
          helps SMEs thrive and compete with the big guys.
        </div>
      ),
    },
    {
      heading: (
        <>
          <div className=" mr-2">AI Interview Simulator</div>
          <img
            src="/assets/images/employeesLandingPage/exploreFourth.svg"
            alt=""
            className="iconImage"
          />
        </>
      ),

      description: (
        <div>
          Picture this... a world where business owners can effortlessly create
          custom interview questions or upload their own, and then sit back as
          our sophisticated AI Avatar conducts engaging interviews with
          potential candidates. No more endless hours spent scheduling and
          conducting interviews yourself! Our AI will handle the heavy lifting,
          recording each interview and delivering it straight to your inbox.
          Imagine the time and resources you could save, all while ensuring a
          consistent and unbiased interview process. It's like having your own
          personal HR assistant, minus the coffee breaks and water cooler
          gossip! Are you ready to revolutionise your hiring process with
          MPLOI's AI Interview Simulator?
        </div>
      ),
    },
    {
      heading: (
        <>
          <div className=" mr-2">Candidate Geo Mapping</div>
          <img
            src="/assets/images/employersLandingPage/globe.svg"
            alt=""
            className="iconImage"
          />
        </>
      ),

      description: (
        <div>
          Picture suddenly being able to visualise the location of potential
          hires on a map. No more guesswork, no more endless scrolling through
          resumes! With geo-mapping, they can see at a glance how far each
          candidate is from their business, making the decision process a
          breeze. It adds a whole new level of convenience and efficiency to the
          hiring process. I mean, who wouldn't want to save time and energy
          while finding the best fit for their company? Geo-mapping is the way
          of the future, and at MPLOI we're at the forefront of making finding
          your next team easier and affordable.
        </div>
      ),
    },
    {
      heading: (
        <>
          <div className=" mr-2">AI Legal Assist</div>
          <img
            src="/assets/images/employersLandingPage/hammer.svg"
            alt=""
            className="iconImage"
          />
        </>
      ),

      description: (
        <div>
          <div style={{ fontWeight: 700, marginBottom: "30px" }}>
            Introducing MPLOI AI Legal Assist: Your Virtual Legal Sidekick for
            SMEs!
          </div>
          Are you tired of navigating the complex world of legal matters alone?
          Say hello to MPLOI AI Legal Assist, the game-changing feature designed
          to help small and medium businesses tackle legal challenges with
          confidence. Our AI-powered legal consultant is here to provide you
          with the guidance and support you need, 24/7. <br /> With MPLOI AI
          Legal Assist, you can: <br />
          <ul style={{ paddingLeft: "30px" }}>
            <li>Get instant answers to your legal questions</li>
            <li>Receive personalised advice tailored to your business</li>
            <li>Save time and money on minor legal issues</li>
            <li>Access a wealth of legal resources at your fingertips</li>
            <li>
              Enjoy peace of mind knowing you have a virtual legal expert by
              your side
            </li>
          </ul>
          Don't let legal hurdles slow down your business growth. Let MPLOI AI
          Legal Assist be your trusted partner in navigating the legal
          landscape. Try it now and experience the difference!
        </div>
      ),
    },
    {
      heading: (
        <>
          <div className=" mr-2">AI Sales Trainer</div>
          <img
            src="/assets/images/employeesLandingPage/exploreSixth.svg"
            alt=""
            className="iconImage"
          />
        </>
      ),

      description: (
        <div>
          <div style={{ fontWeight: 700, marginBottom: "30px" }}>
            Your 24/7 Sales Coaching Companion.
          </div>
          As a business owner, you understand the importance of a well-trained
          sales team. But let's be honest - not all of us are natural-born
          teachers, and finding the time to train staff can be a challenge.
          Plus, hiring external sales trainers can be a costly endeavour. That's
          where the MPLOI AI Sales Trainer comes in! <br />
          Imagine having your very own AI-powered sales coach, available
          anytime, anywhere. Your staff can log on and hone their skills at
          their own pace, ensuring they're always at the top of their game.
          Preparing for that big pitch? No problem! The MPLOI AI Sales Trainer
          has got your back. <br />
          Keep your sales team engaged, motivated, and constantly improving with
          the MPLOI AI Sales Trainer. It's not just a tool; it's an investment
          in your company's success. Say goodbye to expensive training sessions
          and hello to a new era of sales excellence.
        </div>
      ),
    },
    {
      heading: (
        <>
          <div className=" mr-2">Accessible via Desktop or App</div>
          <img
            src="/assets/images/employeesLandingPage/exploreFifth.svg"
            alt=""
            className="iconImage"
          />
        </>
      ),

      description: (
        <div>
          Having both an app and desktop version of MPLOI would be a
          game-changer for business owners. You can search for candidates
          anytime, anywhere. Talk about convenience! With the app, you get
          instant notifications about potential superstars that match your
          role.The desktop version would let them fine-tune your profile and job
          postings on a bigger screen. Because let's face it, typing jobs ads on
          a phone is a special kind of torture. Plus, with seamless syncing
          between the app and desktop, they'd never miss a beat with your
          candidate search. It's like having MPLOI as your recruitment assistant
          on this adventure.
        </div>
      ),
    },
  ];

  const [expanded, setExpanded] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [accrodianData, setAccrodianData] = useState(() => {
    return window.innerWidth < 768
      ? originalAccrodianData.slice(0, 3)
      : originalAccrodianData;
  });

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleResetData = (slice = false) => {
    if (slice) {
      setAccrodianData(originalAccrodianData.slice(0, 3));
      setExpanded(false);
    } else {
      setAccrodianData(originalAccrodianData);
      setExpanded(true);
    }
  };

  const sliderData = [
    {
      value: "87%",
      description:
        "Millennials say that professional development/ career growth are important to them.",
      color: "#9256E8",
      bgColor: "#9256E817",
    },
    {
      value: "74%",
      description:
        "Workers say that a lack of employee development opportunities prevents them from reaching their full potential.",
      color: "#F495D3",
      bgColor: "#F495D317",
    },
    {
      value: "58%",
      description:
        "Employees say professional development contributes to their job satisfaction.",
      color: "#38BBF4",
      bgColor: "#38BBF417",
    },
    {
      value: "68%",
      description:
        "Employees say training and development are the most essential company policy",
      color: "#FFE35A",
      bgColor: "#2A1D29",
    },
    {
      value: "74%",
      description:
        "Workers are willing to learn new skills or retrain to stay employable say that a lack of employee development opportunities prevents them from reaching their full potential.",
      color: "#06EFC5",
      bgColor: "#06EFC517",
    },
  ];

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 5.1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 2400,
        settings: {
          slidesToShow: 4.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 2200,
        settings: {
          slidesToShow: 3.8,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1800,
        settings: {
          slidesToShow: 3.1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2.1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 825,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="employersPageWrap">
      <Header />
      <Section1
        heading={
          <>
            <div className=" fw-bold heading">
              Affordable, Proactive <br />
              <span className=" px-2" style={{ background: "#9256E8" }}>
                AI recruitment
              </span>{" "}
              is now <br /> possible with MPLOI.
            </div>
          </>
        }
        subHeading="Scroll, connect, and build a talent pool of viable candidates who are ready for that next role in your business, with AI algorithms enhancing the process. As industries evolve, the demand for that next talented team member has become more important than ever before."
        buttons={
          <>
            <a href="/signup" target="_blank" className="textBlack">
              <button className=" border-0 px-4 py-2 primaryButton">
                Find Team Members
                <img
                  src="assets/images/employersLandingPage/teamMembers.svg"
                  alt=""
                />
              </button>
            </a>
            <a href="#employers-feature">
              <button className=" border-0 px-4 py-2 secondaryButton">
                Explore Features
                <img
                  src="assets/images/employeesLandingPage/exploreIcon.svg"
                  alt=""
                />
              </button>
            </a>
          </>
        }
        image={
          <>
            <img
              src="/assets/images/employersSection1.png"
              className="img-fluid desktopImage"
              alt=""
            />
          </>
        }
        mobileImage={
          <>
            <img
              src="/assets/images/employersSection1.png"
              className="img-fluid mobileImage"
              alt=""
            />
          </>
        }
      />
      <div className="section2">
        <div className="heading">
          MPLOI was built by business owners for business owners to <br /> give
          organisations and candidates a much better way, <br /> powered by AI.
        </div>
        <div className="sec-2-btn">
          <a href="/signup" target="_blank" className="textBlack">
            <button className=" border-0 px-5 py-2 primaryButton">
              Join MPLOI Today!
              <img
                src="assets/images/employeesLandingPage/testYourself.svg"
                alt=""
              />
            </button>
          </a>
        </div>
      </div>
      <div id="employers-feature">
        <Section3
          accrodianData={accrodianData}
          tagName="For Employers"
          description="Explore MPLOI’s Tools for Employers"
          image={
            <img
              src="/assets/images/employersSection3.png"
              className="img-fluid"
               alt=""
            />
          }
          mobilebutton={
            isMobile ? (
              expanded ? (
                <button
                  className=" border-0 px-5 py-2 primaryButton textBlack"
                  onClick={() => handleResetData(true)}
                >
                  Expand Less
                  <img
                    src="assets/images/downArrowBlack.svg"
                    alt=""
                    style={{ transform: "rotate(180deg)" }}
                  />
                </button>
              ) : (
                <button
                  className=" border-0 px-5 py-2 primaryButton textBlack"
                  onClick={() => handleResetData()}
                >
                  Expand All
                  <img src="assets/images/downArrowBlack.svg" alt="" />
                </button>
              )
            ) : (
              ""
            )
          }
        />
      </div>

      <JobSearch
        description="Not only do we love finding you candidates, we can also help you retain them. Offer professional development via our own online training courses through MPLOI academy."
        title={
          <div className="title">
            Explore the MPLOI <br /> Training Academy
          </div>
        }
        subDescription={
          <a href="https://mploiacademy.com/">
          <button className=" border-0 px-5 py-2 primaryButton textBlack">
            Visit the Academy
            <img
              src="assets/images/employeesLandingPage/testYourself.svg"
              alt=""
            />
          </button>
          </a>
        }
        image={
          <img
            src="/assets/images/employersLandingPage/section4.png"
            alt="For Jobs"
            className="img-fluid"
          />
        }
      />

      <div className="section4">
        <div className="heading">What Our Candidates Have Reported</div>
        <div>
          <div className="slider-container">
            {isMobile ? (
              sliderData.map((item) => (
                <div
                  key={item.value}
                  className="card-wrap"
                  style={{
                    background: item.bgColor,
                    border: `1px solid ${item.color}`,
                  }}
                >
                  <div style={{ color: item.color }} className="title">
                    {item.value}
                  </div>
                  <div className="description">{item.description}</div>
                </div>
              ))
            ) : (
              <Slider {...settings}>
                {sliderData.map((item) => (
                  <div key={item.value}>
                    <div
                      className="card-wrap"
                      style={{
                        background: item.bgColor,
                        border: `1px solid ${item.color}`,
                      }}
                    >
                      <div style={{ color: item.color }} className="title">
                        {item.value}
                      </div>
                      <div className="description">{item.description}</div>
                    </div>
                  </div>
                ))}
              </Slider>
            )}
          </div>
        </div>
      </div>

      <JobSearch
        description="Download the MPLOI Employer app for seamless recruitment on the fly. Manage job postings, review applications, and find your next hire with ease – all from your mobile device."
        showButtons
        title={
          <div className="title">
            Streamline Your <span>Recruitment </span>
            <br /> with the MPLOI Employer App
          </div>
        }
        subDescription={
          <div className="sub-description">
            Join Today and to Discover your Perfect Team Member!
          </div>
        }
        image={
          <img
            src="/assets/images/jobSearch.png"
            alt="For Jobs"
            className="img-fluid"
          />
        }
      />
      <Footer />
    </div>
  );
}
