import React, { Component } from "react";
import ReactCrop from "react-image-crop";
import ValidationErrorToast from "../../components/validationErrorToast";
import { imgAcceptType } from "../../pages/jobManagement/utils";
import { Button, Modal } from "react-bootstrap";
import 'react-image-crop/dist/ReactCrop.css'
import heic2any from "heic2any";
import { blobToFile, dataURLtoFile } from "../../utils/UtilityFunctions";

class ImageCrop extends Component<Readonly<any>, any> {
    imageRef = null;
    fileUrl = null;
    constructor(props: Readonly<any>) {
        super(props);
        this.state = {
            crop: {
                unit: "%",
                width: 50,
                height: 50,
                aspect: this.props.ratio || 1 / 1,
            },
            url: null,
            isCropperShow: false,
            file: null,
            boundaries: null,
            croppedImageUrl: null
        };
    }

    // If you setState the crop in here you should return false.
    onImageLoaded = image => {
        this.imageRef = image;
    };

    onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        this.setState({ crop });
    };

    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };

    makeClientCrop = async (crop) => {
        if (this?.imageRef && crop?.width && crop?.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this?.imageRef,
                crop,
            );
            this.setState({ croppedImageUrl });
        }
    }

    getCroppedImg(image, crop) {
        const canvas = document.createElement('canvas');
        const scaleX = image?.naturalWidth / image?.width;
        const scaleY = image?.naturalHeight / image?.height;
        canvas.width = crop?.width;
        canvas.height = crop?.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop?.width,
            crop?.height
        );

        // As Base64 string
        const base64Image = canvas.toDataURL('image/jpeg');

        return base64Image
    }

    getCropImg = async () => {
        const { croppedImageUrl } = this.state;
        if (croppedImageUrl) {
            let newFile = dataURLtoFile(croppedImageUrl, Date.now() + '.jpeg');
            this.props.croppedImage(croppedImageUrl, newFile);
        }
        this.setState({ isCropperShow: false, url: null });
    };

    closecrop = () => {
        this.setState({ isCropperShow: false, url: null, file: null });
    }

    onSelectFile = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const file = evt?.target?.files[0],
            type = file?.type.split("/");
        if (evt?.target?.value)
            evt.target.value = "";
        if (imgAcceptType.indexOf(type[1]) !== -1) {
            if (((file.size / 1000) / 1000).toFixed(4) <= this.props.size) {
                const reader = new FileReader();
                if (type[1] == 'heic') {
                    heic2any({ blob: file, toType: "image/jpg", quality: 1 }).then(
                        (av: Blob) => {
                            let file = blobToFile(av, Date.now() + '.png')
                            reader.addEventListener("load", () =>
                                this.setState({ url: reader.result, isCropperShow: true, file: file }),
                            );
                            reader.readAsDataURL(file);
                        });
                }
                else {
                    reader.addEventListener("load", () =>
                        this.setState({ url: reader.result, isCropperShow: true, file: file }),
                    );
                    reader.readAsDataURL(file);
                }
            } else {
                ValidationErrorToast("File", `File size more than ${this.props.size} mb is not supported`);
                this.setState({ url: null, isCropperShow: false, file: null });
                document.getElementById("image_id").innerHTML = "";
            }
        } else {
            ValidationErrorToast("File", `File type is not supported`);
            this.setState({ url: null, isCropperShow: false, file: null });
            document.getElementById("image_id").innerHTML = "";
        }
    };

    render() {
        const { crop, url } = this.state;
        return (
            <div>
                <input
                    id="image_id"
                    accept="image/png, image/gif, image/jpeg, image/heic"
                    style={{ display: "none" }}
                    type="file"
                    onInput={this.onSelectFile}
                    className={this.props?.inputClass}
                // disabled={this.props.disable || false}
                />
                {this.state?.url && (
                    <Modal className="croper-modal"
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        show={true}
                        backdrop='static'
                        onHide={() => this.setState({
                            url: null,
                            isCropperShow: false,
                            file: null
                        }, () => {
                            document.getElementById("image_id").innerHTML = "";
                        })}
                    >
                        <Modal.Body>
                            <div className="crop-close-btn" onClick={this.closecrop}>

                            </div>
                            <div className="croper_outer">
                                <ReactCrop
                                    src={url}
                                    crop={crop}
                                    ruleOfThirds
                                    onImageLoaded={this.onImageLoaded}
                                    onComplete={this.onCropComplete}
                                    onChange={this.onCropChange}
                                    keepSelection={true}
                                />
                                <Button variant="primary" onClick={this.getCropImg}>
                                    Crop
                                </Button>
                            </div>
                        </Modal.Body>
                    </Modal>
                )}
            </div>
        );
    }
}

export default ImageCrop;
