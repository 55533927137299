import React from "react";
import { UtilityFunctions } from "../utils/UtilityFunctions";
import { STRING_CONSTANTS } from "../utils/constants/stringConstants";
import { withRouter } from "react-router";
import { Button, Modal } from "react-bootstrap";

const CompletionPage = (props: any) => {
    return (
        <div className="row">
            <div className="col-md-3 col-lg-4 col-xl-4 col-12">
                <div className="left-wrap">
                    <div className="logo-wrap">
                        <img
                            alt=""
                            src={UtilityFunctions.getImageURL() + "logo_White.svg"}
                            onClick={() => props.history.push("/")}
                        />
                    </div>
                </div>
            </div>
            <div className="col-md-6 col-lg-4 col-xl-4 col-12">
                <div className="middle-wrap align-items-center">
                    <div className='top-divde-spa'>
                        <div className="step-content">
                            <div>
                                <button type='button' disabled>
                                    {props.buttonText}
                                </button>
                                <p>{props.stepText}</p>
                                {props.step &&
                                    <div className="step-text">
                                        {props.step}
                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                    <div className="form-wrap" style={{ marginBottom: '59px' }}>
                        <div className="icon-wrap">
                            <img
                                width={298}
                                alt=""
                                src={UtilityFunctions.getImageURL() + "verifiedNew.png"}
                            />
                        </div>
                        {!props.isSignUp &&
                            <p className="outer-design">{STRING_CONSTANTS.GUARDIAN.THANK_YOU_MESSAGE}</p>
                        }
                    </div>
                </div>
                {
                    props.isSignUp &&
                    <Modal
                        className="bg-transparent"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        show={props.showOffer}
                    >
                        <Modal.Body>
                            <img alt="" src={UtilityFunctions.getImageURL() + "question.svg"} />
                            <h3>Thanks, do you want to stay updated with our latest offers?</h3>
                            <img
                                alt="Verified"
                                src={UtilityFunctions.getImageURL() + "verified-main.png"}
                            />
                            <div>
                                <Button className='border-0 bg-transparent' variant="primary" onClick={(event) => props.handleSubmit(event, false)}>
                                    No, thanks
                                </Button>
                                <Button
                                    className="border-0"
                                    variant="primary"
                                    onClick={(event) => props.handleSubmit(event, true)}
                                >
                                    Yes, please
                                </Button>
                            </div>
                        </Modal.Body>
                    </Modal>
                }
            </div>
        </div>
    );
};
export default withRouter(CompletionPage);
