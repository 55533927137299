import React, { useEffect, useState } from "react"
import { withRouter } from "react-router-dom"
import ToastHeader from "../../../components/toastHeader";
import { userService } from "../../../_services";
import parse from "html-react-parser";

const About = (props: any) => {
    const [content, setContent] = useState('');
    useEffect(() => {
        userService.legalContent()
            .then((res) => {
                res.filter(i => i.contentType === "ABOUT").map((item) => {
                    let parsedContent: any = parse(item.content);
                    return setContent(parsedContent);

                });
            })
            .catch(function (error) {
                ToastHeader(error);
            });
    }, []);
    
    return <div className="tab-pane fade show active " id="panel7" role="tabpane" aria-labelledby="panel5-tab">
        <div className="container-fluid">
            <div className='title-row'>
                <h1>About</h1>
            </div>
            <div className="blue-shadow-box">
                <div className="legal-content legal-scroll">
                    {content}
                </div>
            </div>
        </div>
    </div>
}

export default withRouter(About)