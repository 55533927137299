import React from "react";
import { UtilityFunctions } from "../../utils/UtilityFunctions";
import { STRING_CONSTANTS } from "../../utils/constants/stringConstants";
import { withRouter } from "react-router";
import Timer from "../../components/timer";

const Step2 = (props: any) => {
  // please do not delete below line it handled step2 navigation.
  if (props.location.state) {
    props.location.state.step = undefined
  }

  const onBoarding = {
    pathname: "/",
    state: { activeIndex: 2 }
  }

  return (
    <div className="row">
      <div className="col-md-3 col-lg-4 col-xl-4 col-12">
        <div className="left-wrap">
          <div className="logo-wrap">
            <img
              alt=""
              src={UtilityFunctions.getImageURL() + "logo_White.svg"}
              onClick={() => props.history.push(onBoarding)}
            />
          </div>
        </div>
      </div>
      <div className="col-md-6 col-lg-4 col-xl-4 col-12">
        <div className="middle-wrap">
          <div className='top-divde-spa'>
            <div className="step-content signup-mar">
              <button type='button' disabled>{STRING_CONSTANTS.signupPages.verifyEmail.verify_email}</button>
              <p>{STRING_CONSTANTS.signupPages.verifyEmail.open_mail}</p>
              <div className="step-text">
                {STRING_CONSTANTS.signupPages.verifyEmail.step}
              </div>
            </div>
          </div>
          <div className="form-wrap">
            <div className="icon-wrap">
              <img
                alt=""
                src={UtilityFunctions.getImageURL() + "group-icon.svg"}
              />
            </div>
            <p className="d-flex gap-2 justify-content-center">
              {props.startTimer1 ? STRING_CONSTANTS.signupPages.verifyEmail.resend_prompt : STRING_CONSTANTS.signupPages.verifyEmail.email_receipt + " "}
              <span
                className="text-white ho-line"
                onClick={() => props.startTimer1 ? null : props.resend()}
              >
                {props.startTimer1 ? <Timer setStartTimer={props.setStartTimer1} /> : STRING_CONSTANTS.signupPages.verifyEmail.resend_email}
              </span>
            </p>
          </div>
          <div className="next-wrap">
            <button className="cricle-next" onClick={() => props.nextButton()}>
              <img
                alt=""
                src={UtilityFunctions.getImageURL() + "arrow-bottom.svg"}
              />
            </button>
          </div>
        </div>
      </div>
    </div>

  );
};
export default withRouter(Step2);
