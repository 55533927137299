import React, { useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { api } from "../middleware/api";
import { STRING_CONSTANTS } from "../utils/constants/stringConstants";
import { UtilityFunctions } from "../utils/UtilityFunctions";
import { cookieService, userService } from "../_services";
import SuccessToast from "./successToast";
import ValidationErrorToast from "./validationErrorToast";

const GuardianEmail = (props) => {
  const [errorState, setErrorState] = useState(false);
  const mailRef = useRef<HTMLInputElement>();
  const userData = cookieService.getCookie(STRING_CONSTANTS.cookieStrings.user)
  const patchGuardianEmailID = () => {
    if (!UtilityFunctions.validateEmail(mailRef?.current?.value)) {
      setErrorState(true);
      ValidationErrorToast("Guardian Email ID", "Please enter a valid email ID!")
    }
    else {
      userService.userProfile({ guardian: { email: props?.mailAddress || userData?.guardian?.email } })
        .then(res => {
          cookieService.saveCookie(STRING_CONSTANTS.cookieStrings.user, res?.data)
          props?.handleClose()
          SuccessToast("Email Sent Successfully", "An email has been sent for age verification")
        })
        .then(() =>
          props?.dataSubmitHandler && props?.dataSubmitHandler()
        )
        .catch(e => ValidationErrorToast(e[0], e[1]))
    }
  }

  return (
    <Modal
      className="bg-transparent upgrade-popup logout-popup settings-update-hash"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={props.show}
      onHide={() => {
        setErrorState(false)
        props.handleClose()
      }}
    >
      <Modal.Body>
        <div style={{ maxWidth: "100%", margin: "0 auto" }}>
          <div className="mb-2 pb-1">
            {/* <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0.5" y="0.5" width="25" height="25" rx="12.5" stroke="white" />
              <path d="M13.3375 5.6125C15.244 7.04239 17.1899 7.75 19.1875 7.75C19.4982 7.75 19.75 8.00184 19.75 8.3125V12.25C19.75 16.0009 17.5318 18.7568 13.2062 20.4609C13.0737 20.513 12.9263 20.513 12.7938 20.4609C8.46817 18.7568 6.25 16.0009 6.25 12.25V8.3125C6.25 8.00184 6.50184 7.75 6.8125 7.75C8.81006 7.75 10.756 7.04239 12.6625 5.6125C12.8625 5.4625 13.1375 5.4625 13.3375 5.6125ZM13 6.75836C11.1856 8.04121 9.30801 8.74439 7.375 8.85845V12.25C7.375 15.4417 9.21502 17.7842 13 19.3314C16.785 17.7842 18.625 15.4417 18.625 12.25V8.85845C16.692 8.74439 14.8144 8.04121 13 6.75836ZM13 16C13.3107 16 13.5625 16.2518 13.5625 16.5625C13.5625 16.8732 13.3107 17.125 13 17.125C12.6893 17.125 12.4375 16.8732 12.4375 16.5625C12.4375 16.2518 12.6893 16 13 16ZM13 9.25267C13.2848 9.25267 13.5201 9.46429 13.5574 9.73884L13.5625 9.81517V14.6892C13.5625 14.9999 13.3107 15.2517 13 15.2517C12.7152 15.2517 12.4799 15.0401 12.4426 14.7655L12.4375 14.6892V9.81517C12.4375 9.50451 12.6893 9.25267 13 9.25267Z" fill="white" />
            </svg> */}
            <img src={UtilityFunctions.getImageURL() + "sheld-round-white.svg"} alt="" />
          </div>
          <p className="mb-3">
            The Parent/Guardian must provide concent for under 16s to apply for jobs within the application.
          </p>

          <img src={UtilityFunctions.getImageURL() + "parent-guardian-img.png"} className="mb-3 pb-2" alt="" />
          <div className="form-floating mb-3">
            <input ref={mailRef} type='text' defaultValue={userData?.guardian?.email} className={`form-control ${errorState ? "error" : ""}`} placeholder='Enter Parent Email' style={{ background: "rgba(255, 255, 255, 0.1)" }}
              onChange={e => {
                props?.setMailAddress(e.target.value);
                setErrorState(false)
              }}
            />
            <label htmlFor="floatingInput">
              Enter Parent Email
            </label>
            <span className="input-group-text" style={{ opacity: "1" }}>
              {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <g opacity="0.5">
                  <path d="M5.25 4H18.75C20.483 4 21.8992 5.35645 21.9949 7.06558L22 7.25V16.75C22 18.483 20.6435 19.8992 18.9344 19.9949L18.75 20H5.25C3.51697 20 2.10075 18.6435 2.00514 16.9344L2 16.75V7.25C2 5.51697 3.35645 4.10075 5.06558 4.00514L5.25 4H18.75H5.25ZM20.5 9.373L12.3493 13.6637C12.1619 13.7623 11.9431 13.7764 11.7468 13.706L11.6507 13.6637L3.5 9.374V16.75C3.5 17.6682 4.20711 18.4212 5.10647 18.4942L5.25 18.5H18.75C19.6682 18.5 20.4212 17.7929 20.4942 16.8935L20.5 16.75V9.373ZM18.75 5.5H5.25C4.33183 5.5 3.57881 6.20711 3.5058 7.10647L3.5 7.25V7.679L12 12.1525L20.5 7.678V7.25C20.5 6.33183 19.7929 5.57881 18.8935 5.5058L18.75 5.5Z" fill="white"></path>
                </g>
              </svg> */}
              <img src={UtilityFunctions.getImageURL() + "mail.svg"} alt="" />
              {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M22 8.608V16.75C22 18.483 20.6435 19.8992 18.9344 19.9949L18.75 20H5.25C3.51697 20 2.10075 18.6435 2.00514 16.9344L2 16.75V8.608L11.652 13.6644C11.87 13.7785 12.13 13.7785 12.348 13.6644L22 8.608ZM5.25 4H18.75C20.4347 4 21.8201 5.28191 21.9838 6.92355L12 12.1533L2.01619 6.92355C2.17386 5.34271 3.46432 4.09545 5.06409 4.00523L5.25 4H18.75H5.25Z" fill="#38BBF4"></path>
              </svg> */}
              <img src={UtilityFunctions.getImageURL() + "mail-fill-blue.svg"} alt="" />
            </span>
          </div>


          <div className="d-grid d-md-flex gap-4 align-items-center pt-4">
            <Button
              className="border-0 w-100"
              variant="primary"
              onClick={() => patchGuardianEmailID()
              }
            >
              Send Request
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>

  )
}
export default withRouter(GuardianEmail);