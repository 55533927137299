import React, { useState } from "react";
import "./CallerCard.css";

const CallerCard = ({ interviewCase }) => {
  const [isAudioActive, setIsAudioActive] = useState(true);
  const [isCameraActive, setIsCameraActive] = useState(true);

  const handleAudioToggle = () => {
    setIsAudioActive((prevState) => !prevState);
  };

  const handleCameraToggle = () => {
    setIsCameraActive((prevState) => !prevState);
  };

  return (
    <div className="caller-card-wrap">
      <div className="left-img-wrap">
        {interviewCase === 0 ? (
          <div className="top-title-mob error">
            <img src="/assets/images/Dismiss Circle.svg" alt="" /> Not ready.
            Please see how you can prepare below
          </div>
        ) : interviewCase === 1 ? (
          <div className="top-title-mob warning">
            <div>
              <img src="/assets/images/Error Circle.svg" alt="" />
            </div>
            <div>Needs improvement. Please see how you can improve below</div>
          </div>
        ) : (
          <div className="top-title-mob">
            <img src="/assets/images/checkmark-circle.png" alt="" /> Nice work!
            You’re ready to start your interview.
          </div>
        )}
        <img
          className="caller-img"
          src="/assets/images/caller-1.png"
          alt="Candidate"
        />
        <div className="bottom-icon-wrap">
          <div className="bottom-title">
            Candidate: <span>John Jones (you)</span>
          </div>
          <div className="feature-icon-wrap">
            {/* <img src='/assets/images/audio-call-icon.png' className='icn-1' alt='' />
          <img src='/assets/images/video-call-icon.png' className='icn-2' alt='' /> */}

            <button onClick={handleAudioToggle} className="icon-button">
              <img
                src={
                  isAudioActive
                    ? "/assets/images/audio-call-icon.png"
                    : "/assets/images/audio-call-off-icon.png"
                }
                alt="Audio"
                className="icon-img"
              />
            </button>
            <button onClick={handleCameraToggle} className="icon-button">
              <img
                src={
                  isCameraActive
                    ? "/assets/images/video-call-icon.png"
                    : "/assets/images/vdo-call-off-icon.png"
                }
                alt="Camera"
                className="icon-img"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CallerCard;
