import React, { Fragment, useState } from "react";
import { STRING_CONSTANTS } from "../../utils/constants/stringConstants";
import { withRouter } from "react-router";
import { cookieService, userService } from "../../_services";
import ToastHeader from "../../components/toastHeader";
import CompletionPage from "../../components/completionPage";
import { Data, User } from "../../_services/collections";

const AccountVerified = (props: any) => {
  document.body.classList.remove('loading-indicator');
  let user = cookieService.getCookie(STRING_CONSTANTS.cookieStrings.user);
  let modalState = (user as Data).user.sendOffers;
  modalState = (modalState === null) ? true : false;
  const [showOffer, setShowOffer] = useState(modalState);
  const handleCloseOffer = () => setShowOffer(false);
  const handleSubmit = (event, shouldSendOffers) => {
    event.preventDefault();
    const params = {
      user: {
        sendOffers: shouldSendOffers,
      },
    };
    userService
      .userProfile(params)
      .then((res) => {
        cookieService.saveCookie(STRING_CONSTANTS.cookieStrings.user, { user: res.data.user });
        setShowOffer(false);
      })
      .catch((err) => {
        setShowOffer(false);
        ToastHeader(err);
        return err;
      });
  };

  return (
    <Fragment>
      <CompletionPage
        handleCloseOffer={handleCloseOffer}
        isSignUp={true}
        showOffer={showOffer}
        handleSubmit={handleSubmit}
        buttonText={STRING_CONSTANTS.signupPages.accountVerified.reg_success}
        step={STRING_CONSTANTS.signupPages.accountVerified.step}
        stepText={STRING_CONSTANTS.signupPages.accountVerified.cong_success} />
      <div className="next-wrap">
        <button className="m-0 btn-custom btn-cta" onClick={() => { props.history.push(STRING_CONSTANTS.navigationString.employer_profile_completion) }}>
          {STRING_CONSTANTS.signupPages.buttons.customise_profile}
        </button>
      </div>
    </Fragment>
  );
};
export default withRouter(AccountVerified);
