import React from "react";
import TrainerIntro from "../../../components/TrainerIntro/TrainerIntro";

function TrainerIntroPage() {
  return (
    <div>
      <TrainerIntro />
    </div>
  );
}

export default TrainerIntroPage;
