import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import Logout from "../../components/logout";
import { STRING_CONSTANTS } from "../../utils/constants/stringConstants";
import { dataURLtoFile, UtilityFunctions } from "../../utils/UtilityFunctions";
import FileInput from "../../common/ui/FileInput";
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import ValidationErrorToast from "../../components/validationErrorToast";
import { cookieService, userService } from "../../_services";
import { api } from "../../middleware/api";

const Introduction = (props) => {
  const [show, setShow] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [linkedInURL, setlinkedInURL] = useState("")

  //FOR HANDLING ERROR STATES
  const initialErrorState = { linkedInURL: false }
  const [errorState, setErrorState] = useState(initialErrorState)

  //useEffect(() => { props?.userData?.user?.isProfileCompleted && props.setStep(8) }, [props])

  useEffect(() => {
    if (props?.userData) {
    }
  }, [props])

  const inputValidator = (e) => {
    dataSubmitHandler();
  }

  const dataSubmitHandler = () => {
    if (selectedFile || linkedInURL) {
        const formData = {method: 'evaluate-resume', data: {}};
        formData.data['file'] = selectedFile;
        formData.data['linkedInURL'] = linkedInURL;

        userService.userResume(formData)
            .then(res => {
                
                // monthly / yearly / daily / weekly
                // const salaryObject = {
                //   salaryCheckoutType: checkout,
                //   jobType: type,
                //   minSalary: parseFloat(`${minValue}`?.replace(/[^\d\.]/g, '')) ? parseFloat(`${minValue}`?.replace(/[^\d\.]/g, '')) : 0,
                //   maxSalary: parseFloat(`${maxValue}`?.replace(/[^\d\.]/g, '')) ? parseFloat(`${maxValue}`?.replace(/[^\d\.]/g, '')) : 0,
                //   // user: {
                //   //   isProfileCompleted: true
                //   // }
                // }
                // userService.userProfile(salaryObject)

                const data = res.data;
                const params = {};
                if (data) {
                  if (data.aboutMe) {
                    params['about'] = data.aboutMe;
                  }
                  if (data.firstName) {
                    params['user'] = {};
                    params['user']['firstName'] = data.firstName;
                  }
                  if (data.lastName) {
                    params['user'] = params['user'] ? params['user'] : {};
                    params['user']['lastName'] = data.lastName;
                  }
                  if (data.categoryId) {
                    params['category'] = [data.categoryId];
                  }
                  if (data.subCategoryId) {
                    params['subCategory'] = [data.subCategoryId];
                  }
                  if (data.experienceMonths) {
                    params['experience'] = data.experienceMonths;
                  }
                  if (data.education) {
                    const newSubmitQualificationData = data.education.map(({ startDate, endDate, school, degree, description, courseId, schoolId}) => {
                      // if any of them are <NOT_KNOWN> set them to ''
                      if (startDate === "<NOT_KNOWN>") startDate = null;
                      if (endDate === "<NOT_KNOWN>") endDate = null;
                      if (schoolId === "<NOT_KNOWN>") schoolId = null;
                      if (courseId === "<NOT_KNOWN>") courseId = null;
                      if (degree === "<NOT_KNOWN>") degree = '';
                      if (description === "<NOT_KNOWN>") description = '';
                      // @TODO: how to select course or college ID, needs to be done on backend (one of...)
                      return {
                        startDate,
                        endDate,
                        schoolName: (school || ''),
                        degreeName: (degree || ''),
                        fieldOfStudy: (description || ''),
                        schoolType: schoolId,
                        course: courseId,

                      };
                    });
                    //params['educations'] = [...newSubmitQualificationData];
                  }
                  if (data.workHistory) {
                    const newSubmitExperienceData = data.workHistory.map(({ startDate, endDate, company, jobTitle, description }) => {
                      // if any of them are <NOT_KNOWN> set them to ''
                      if (startDate === "<NOT_KNOWN>") startDate = null;
                      if (endDate === "<NOT_KNOWN>") endDate = null;
                      if (company === "<NOT_KNOWN>") company = '';
                      if (jobTitle === "<NOT_KNOWN>") jobTitle = '';
                      if (description === "<NOT_KNOWN>") description = '';

                      const searchTerm = company;
                      let currentEmployerId = null;
                      if (searchTerm) {
                          api.get(`employer/all/?search=${searchTerm}`, null)
                            .then(res => {
                            })
                            .catch(e => { })
                      }

                      let patchObject;
                      if (currentEmployerId) {
                        patchObject = {
                          experienceHistory: [
                            {
                              id: currentEmployerId,
                              employer: `${currentEmployerId}`,
                              isProfileHidden: true,
                              startDate: (startDate || null),
                              endDate: (endDate || null),
                              unregisteredEmployer: null,
                              employerType: "C"
                            }
                          ]
                        }
                      } else {
                        patchObject = {
                          experienceHistory: [
                            {
                              id: null,
                              employer: null,
                              isProfileHidden: false,
                              startDate: startDate || null,
                              endDate: endDate || null,
                              unregisteredEmployer: `${company}`,
                              employerType: "C"
                            }
                          ]
                        }
                      }

                      return patchObject.experienceHistory[0];
                    });
                    params['experienceHistory'] = [...newSubmitExperienceData];
                  }
                userService.userProfile(params)
                  .then(res => {
                    if (data.photo) {
                        let formData = new FormData()
                        console.log("DOING PHOTO MODE...!!!")
                        formData.append("profilePhoto", dataURLtoFile(data.photo, Date.now() + '.jpeg'))
                        userService.userProfile(formData)
                          .then(res => {
                            cookieService.saveCookie(STRING_CONSTANTS.cookieStrings.user, res)
                          })
                          .then(() => props.nav.next())
                          .catch(e => ValidationErrorToast(e[0], e[1]))
                    } else {
                      cookieService.saveCookie(STRING_CONSTANTS.cookieStrings.user, res);
                      props.nav.next();
                    }
                  })
                  .catch(e => {
                    ValidationErrorToast(e[0], e[1])
                  });
                }
            })
            .catch(e => {
                ValidationErrorToast(e[0], e[1])
            });

    }
    else {
        props.nav.next();
    }
  }

  return (
    <>
      <div className='top-divde-spa'>
        <span>
          <i
            className="text-white d-flex justify-content-between"
            aria-hidden="true"
            onClick={() => setShow(true)}
          >
            <img src={UtilityFunctions.getImageURL() + "close-icon.svg"} width={'24px'} height={'24px'} alt="" />
          </i>
        </span>
        <Logout
          show={show}
          handleClose={() => setShow(false)}
        />
        <div className="step-content signup-mar">
          <button type='button' disabled>
            {STRING_CONSTANTS.employeeProfileCompletionPages.step1.title}
          </button>
          <p>
            {STRING_CONSTANTS.employeeProfileCompletionPages.step1.subtitle}
          </p>
          <div className="step-text">
            {STRING_CONSTANTS.employeeProfileCompletionPages.step1.step}
          </div>
        </div>
      </div>
      <form
        onSubmit={inputValidator}
      >
        <div className="form-wrap business-detailss">
          <div className='v-form-width'>
            <div className="update-image">
              <div className='upload-image'>
                <div className='file-thumb' >
                    <div>
                        <label htmlFor="file_id">
                        <img src={UtilityFunctions.getImageURL() + "add_img.svg"} className="plus-icon" alt="" />
                        <p id="resume-label">
                            Upload your Resume
                        </p>
                        </label>
                    </div>
                </div>
                <FileInput
                  ratio={16 / 9}
                  fileSet={(data) => {
                    setSelectedFile(data)
                    // Update the resume-label to say the filename
                    document.getElementById("resume-label").innerText = "Your resume"
                    // Hide the LinkedIn URL section
                    document.getElementById("linkedin-section").style.display = "none"
                  }}
                  size={300}
                />
              </div>
              <div className="form-floating mb-3" id="resume-feedback"></div>
            </div>
            <div className="form-floating mb-3" id="linkedin-section">
              <input type='text' className={`form-control ${errorState?.linkedInURL ? 'error' : ""}`}
                placeholder="LinkedIn URL"
                value={linkedInURL}
                onChange={(e) => {
                  setErrorState(prev => { return { ...prev, linkedInURL: false } })
                  setlinkedInURL(e.target.value);
                }}
              />
              <label htmlFor="floatingInput">
                Or enter your LinkedIn URL:
              </label>

            </div>
          </div>
        </div>
      </form>
      <div className="next-wrap">
        <button className={
          (selectedFile || linkedInURL) ?
            "cricle-next"
            : "cricle-next-inactive"
        }
          onClick={(e) => inputValidator(e)}
        >
          <img
            alt=""
            src={UtilityFunctions.getImageURL() + "arrow-bottom.svg"}
          />
        </button>
      </div>
    </>
  )
}

export default withRouter(Introduction)