import { Action } from "../actions/index";
import { ActionType } from "../constants";
import { get } from "lodash";

const initialState: any = {
  online_user_list: [],
  last_seen: "",
  close_socket: false,
  get_online_user_list: false,
  trigger_chat_tab:false,
  reconnect_inside_socket:false
};

const reducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.ADD_ONLINE_USER: {
      let newData = get(action, "item") || [];
      let oldData = state?.online_user_list;
      let newData1 = [...oldData, ...newData];
      let newSet = new Set(newData1);
      let array = [...newSet];
      return { ...state, online_user_list: array };
    }
    case ActionType.REMOVE_ONLINE_USER: {
      let newData = get(action, "item") || [];
      let oldData = state?.online_user_list;
      let f = oldData?.filter((item, index) => {
        return item !== newData?.[0];
      });
      //  let newData1 = [...f];
      let newSet = new Set(f);
      let array = [...newSet];
      const d = new Date();
let text = d.toISOString();
      return { ...state, online_user_list: array, last_seen: text };
    }
    case ActionType.UPDATE_ALL_ONLINE_USER: {
      let newData = get(action, "item") || [];
      return { ...state, online_user_list: newData };
    }
    case ActionType.CURRENT_USER_LAST_SEEN: {
      let newData = get(action, "item") || "";
      return { ...state, last_seen: newData };
    }
    case ActionType.CLOSE_ONLINE_OFFLINE_SOCKET: {
      let data = get(action, "item") || false;
      return { ...state, close_socket: data };
    }
    case ActionType.CURRENT_USER_LATEST_MESSAGE: {
      let data = get(action, "item") || null;
      return { ...state, current_user_latest_message: data };
    }
    case ActionType.GET_ONLINE_USER_LIST: {
      let data = get(action, "item") || false;
      return { ...state, get_online_user_list: data };
    }
    case ActionType.TRIGGER_CHAT_TAB: {
      let data = get(action, "item") || false;
      return { ...state, trigger_chat_tab: data };
    }
    case ActionType.RECONNECT_INSIDE_SOCKET: {
      let data = get(action, "item") || false;
      return { ...state, reconnect_inside_socket: data };
    }
    default:
      return { ...state };
  }
};

export default reducer;
