import React from "react";
import { UtilityFunctions } from "../../src/utils/UtilityFunctions";
import { Button, Modal } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { userService } from "../_services";
import SuccessToast from "./successToast";
import ValidationErrorToast from "./validationErrorToast";
import { bindActionCreators } from "redux";
import { actionCreators, State } from "../store";
import { useDispatch, useSelector } from "react-redux";
import { END_POINTS } from "../utils/constants/endPoints";

const DeleteJob = (props) => {
  const dispatch = useDispatch()
  const { setJobsList } = bindActionCreators(actionCreators, dispatch);
  const state = useSelector((state: State) => state);
  const handleClick = () => {
    userService
      .deleteJob(props.selectedJobID)
      .then((res) => {
        userService.fetchAllJobs(END_POINTS.filter_jobs, state.jobFilterSearch, (res) => {
          setJobsList(res.data)
        })
        props.handleClose();
        props.getAllJobs();
      })
      .catch((err) => {
      });
  };

  return (
    <Modal
      className="bg-transparent upgrade-popup logout-popup"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={props.show}
      onHide={props.handleClose}
    >
      <Modal.Body>
        <img
          alt="logout"
          src={`${UtilityFunctions.getImageURL()}delete_job_icon.svg`}
        />
        <h3 className="py-2">Delete Job Listing?</h3>
        <p className="py-3">
          Are you sure you want to delete listing? You won't be able to restore any data associated with listing.
        </p>
        <div>
          <Button
            className="ms-4 border-0 bg-transparent cancel-button"
            variant="primary"
            onClick={props.handleClose}
          >Cancel
          </Button>
          {props?.selectedJob?.status === "allocated" ||
            props?.selectedJob?.status === "completed" ? null : (
            <Button
              data-bs-dismiss="modal"
              aria-label="Close"
              className="ms-4 border-0"
              variant="primary"
              onClick={handleClick}
            >
              Delete
            </Button>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default withRouter(DeleteJob);
