import moment from "moment";

const momentUtils = {
  timeFromNow(time) {
    return moment(time).fromNow();
  },
  formatTime(time, format: string) {
    return moment(time).format(format);
  },
};
export default momentUtils;
