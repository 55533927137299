import React, { useEffect, useState } from "react"
import { withRouter } from "react-router-dom"
import ValidationErrorToast from "../../components/validationErrorToast";
import { STRING_CONSTANTS } from "../../utils/constants/stringConstants";
import { UtilityFunctions } from "../../utils/UtilityFunctions";
import { userService } from "../../_services";

const Qualififcation = (props) => {
  const { setSelectedQualificationObj, selectedQualificationObj, setStep, setEmployerArray } = props
  const [instituteList, setInstituteList] = useState<any>();
  const [selectedInstitute, setSelectedInstitute] = useState<any>();
  const [isInstituteListOpen, setIsInstituteListOpen] = useState(false)
  const [institutionName, setInstitutionName] = useState<string>();
  const [courseList, setCourseList] = useState<any>();
  const [isCourseListOpen, setIsCourseListOpen] = useState(false)
  const [selectedCourse, setSelectedCourse] = useState<any>();
  const initialErrorState = { institute: false, name: false, course: false }
  const [errorState, setErrorState] = useState(initialErrorState)
  const [blurred, setBlurred] = useState(true)

  const isProfileBeingUpdated = props?.location?.state?.isProfileBeingUpdated
  useEffect(() => {
    userService.fetchCourseList()
      .then(res => setCourseList(res))
    userService.fetchSchoolList()
      .then(res => setInstituteList(res))
  }, [])

  useEffect(() => {
    if(Object.keys(selectedQualificationObj)?.length==0){
      setSelectedCourse(null)
      setInstitutionName(null)
      setSelectedInstitute(null)
    }
    if (selectedQualificationObj?.course) {
      setSelectedCourse(selectedQualificationObj?.course)
    }
    if (selectedQualificationObj?.schoolType) {
      setSelectedInstitute(selectedQualificationObj?.schoolType)
    }
    if (selectedQualificationObj?.institutionName) {
      setInstitutionName(selectedQualificationObj?.institutionName)
      setBlurred(true)
    }
    else setBlurred(false)
    if (!selectedQualificationObj?.institutionName || !institutionName) {
      // setBlurred(false)
    }
  }, [])

  const submitHandler = () => {
    if (!selectedInstitute) {
      ValidationErrorToast("Institution Type", "Please select an Institute type from the list")
      setErrorState((prev => { return { institute: true, name: false, course: false } }))
    }
    else if (!institutionName) {
      ValidationErrorToast("Institution Name", "Please enter an Institution name before proceeding")
      setErrorState((prev => { return { institute: false, name: true, course: false } }))
    }
    else if (!selectedCourse) {
      ValidationErrorToast("Course Type", "Please select a Course type from the list")
      setErrorState((prev => { return { institute: false, name: false, course: true } }))
    }
    else if (selectedQualificationObj.action === "edit") {
      setSelectedQualificationObj({ ...selectedQualificationObj, course: selectedCourse, schoolType: selectedInstitute, institutionName: institutionName })
      props.nav.next()
    }
    else {
      setSelectedQualificationObj({ ...selectedQualificationObj, course: selectedCourse, schoolType: selectedInstitute, institutionName: institutionName, action: "add" })
      if(!isProfileBeingUpdated&&selectedQualificationObj?.action!=="add"){
        props?.nav?.next({previousScreenSkip:true})
      }else{
        props.nav.next()
      }
    }
  }
  return <>
    <div className='top-divde-spa'>
      <span>
        <i
          className="fa fa-angle-left text-white d-flex justify-content-between"
          aria-hidden="true"
          onClick={() => {
            if (isProfileBeingUpdated) {
              props.history.push(STRING_CONSTANTS.navigationString.dashboard.settings, { tab: "updateMyExperience" })
            }
            else if (selectedQualificationObj?.action === "add") {
              props.setStep(8)
            }
            else if(selectedQualificationObj?.action === "edit"){
              props.setStep(8)
            }
            else {
              userService.getUserData()
                .then((res) => setEmployerArray(res?.experienceHistory))
              props.setStep(5)
            }
          }}
        ></i>
      </span>
      {!isProfileBeingUpdated && selectedQualificationObj?.action !== "add"&&selectedQualificationObj?.action !== "edit" ? <span className="top-skip">
        <p className="mb-0"
          onClick={() => {
            if (isProfileBeingUpdated) {
              props.history.push(STRING_CONSTANTS.navigationString.dashboard.settings, { tab: "updateMyExperience" })
            }
            else setStep(9)
          }}
        >
          Skip
        </p>
      </span>:null}
      <div className="step-content signup-mar">
        <button type='button' disabled>
          {STRING_CONSTANTS.advanced_profile.step6.title}
        </button>
        <p className="mw-100">
          {STRING_CONSTANTS.advanced_profile.step6.subtitle}
        </p>
        <div className="step-text">
          {isProfileBeingUpdated ? "Step 1/2" : STRING_CONSTANTS.advanced_profile.step6.step}
        </div>
      </div>
    </div>
    <form
    >
      <div className="form-wrap business-detailss">
        <div className="v-form-width min-height-auto">
          <div>
            <div className="custom-select-desing mb-3">
              {/* add {focused} when focus */}
              {/* data file add this class {data-filed} */}
              <div className={`custom-select-input ${isInstituteListOpen ? "focused" : selectedInstitute ? "data-filed" : ""}`} onClick={() => setIsInstituteListOpen(!isInstituteListOpen)} >
                <input type="text" className={`${errorState.institute ? "error" : ""}`} id="mysele" disabled />
                <label htmlFor="mysele" >{selectedInstitute?.name || STRING_CONSTANTS.advanced_profile.step6.institution_type}</label>
                <div className="select-left-icon">
                  <img src={UtilityFunctions.getImageURL() + "institution-blank-form.svg"} alt="" />
                  <img src={UtilityFunctions.getImageURL() + "institution-fill-blue.svg"} alt="" />
                  {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g opacity="0.5">
                      <path d="M9.90878 3.69964C11.1832 2.88267 12.8168 2.88267 14.0912 3.69964L22.1548 8.86861C22.3689 9.0059 22.4989 9.24238 22.5 9.49677C22.5011 9.75116 22.3732 9.98876 22.1602 10.1279L19 12.1926V17.75C19 17.9122 18.9474 18.0701 18.85 18.2L18.8489 18.2014L18.8477 18.2031L18.8446 18.2071L18.8364 18.2178C18.8321 18.2233 18.8269 18.2298 18.8209 18.2373C18.8179 18.2411 18.8146 18.2452 18.8111 18.2495C18.7904 18.2751 18.7619 18.3095 18.7253 18.3513C18.6522 18.4348 18.5469 18.5483 18.4081 18.6816C18.1305 18.9481 17.7176 19.2948 17.1587 19.6387C16.0359 20.3297 14.3387 21 12 21C9.66127 21 7.96408 20.3297 6.8413 19.6387C6.2824 19.2948 5.86951 18.9481 5.59193 18.6816C5.45308 18.5483 5.34778 18.4348 5.27468 18.3513C5.23204 18.3025 5.1901 18.2531 5.15107 18.2014C5.14956 18.1994 5.15 18.2 5.15 18.2C5.05263 18.0701 5 17.9122 5 17.75V12.1926L3 10.8859V16.25C3 16.6642 2.66421 17 2.25 17C1.83579 17 1.5 16.6642 1.5 16.25V9.49997C1.5 9.22949 1.64318 8.99246 1.85788 8.86052L9.90878 3.69964ZM14.1194 15.3812C12.8317 16.2225 11.1683 16.2225 9.88058 15.3812L6.5 13.1725V17.4687C6.5368 17.5072 6.58034 17.5512 6.63073 17.5996C6.84143 17.8018 7.17072 18.0802 7.62745 18.3612C8.53592 18.9203 9.96373 19.5 12 19.5C14.0363 19.5 15.4641 18.9203 16.3726 18.3612C16.8293 18.0802 17.1586 17.8018 17.3693 17.5996C17.4197 17.5512 17.4632 17.5072 17.5 17.4687V13.1725L14.1194 15.3812ZM13.2817 4.96246C12.5006 4.46173 11.4994 4.46173 10.7183 4.96246L3.63041 9.506L10.701 14.1254C11.4902 14.6411 12.5098 14.6411 13.299 14.1254L20.3696 9.506L13.2817 4.96246Z" fill="white" />
                    </g>
                  </svg> */}
                  {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g opacity="1">
                      <path d="M9.90878 3.69964C11.1832 2.88267 12.8168 2.88267 14.0912 3.69964L22.1548 8.86861C22.3689 9.0059 22.4989 9.24238 22.5 9.49677C22.5011 9.75116 22.3732 9.98876 22.1602 10.1279L19 12.1926V17.75C19 17.9122 18.9474 18.0701 18.85 18.2L18.8489 18.2014L18.8477 18.2031L18.8446 18.2071L18.8364 18.2178C18.8321 18.2233 18.8269 18.2298 18.8209 18.2373C18.8179 18.2411 18.8146 18.2452 18.8111 18.2495C18.7904 18.2751 18.7619 18.3095 18.7253 18.3513C18.6522 18.4348 18.5469 18.5483 18.4081 18.6816C18.1305 18.9481 17.7176 19.2948 17.1587 19.6387C16.0359 20.3297 14.3387 21 12 21C9.66127 21 7.96408 20.3297 6.8413 19.6387C6.2824 19.2948 5.86951 18.9481 5.59193 18.6816C5.45308 18.5483 5.34778 18.4348 5.27468 18.3513C5.23204 18.3025 5.1901 18.2531 5.15107 18.2014C5.14956 18.1994 5.15 18.2 5.15 18.2C5.05263 18.0701 5 17.9122 5 17.75V12.1926L3 10.8859V16.25C3 16.6642 2.66421 17 2.25 17C1.83579 17 1.5 16.6642 1.5 16.25V9.49997C1.5 9.22949 1.64318 8.99246 1.85788 8.86052L9.90878 3.69964ZM14.1194 15.3812C12.8317 16.2225 11.1683 16.2225 9.88058 15.3812L6.5 13.1725V17.4687C6.5368 17.5072 6.58034 17.5512 6.63073 17.5996C6.84143 17.8018 7.17072 18.0802 7.62745 18.3612C8.53592 18.9203 9.96373 19.5 12 19.5C14.0363 19.5 15.4641 18.9203 16.3726 18.3612C16.8293 18.0802 17.1586 17.8018 17.3693 17.5996C17.4197 17.5512 17.4632 17.5072 17.5 17.4687V13.1725L14.1194 15.3812ZM13.2817 4.96246C12.5006 4.46173 11.4994 4.46173 10.7183 4.96246L3.63041 9.506L10.701 14.1254C11.4902 14.6411 12.5098 14.6411 13.299 14.1254L20.3696 9.506L13.2817 4.96246Z" fill="#38bbf4" />
                    </g>
                  </svg> */}
                </div>
                <div className="data-filed-cross" onClick={() => { setSelectedInstitute(null); setIsInstituteListOpen(false); }}>
                  <img src={UtilityFunctions.getImageURL() + "input-cross-right-img.svg"} alt="" />
                  {/* <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="38" height="38" rx="10" fill="white" fillOpacity="0.1" />
                    <path d="M12.8971 13.0538L12.9697 12.9697C13.2359 12.7034 13.6526 12.6792 13.9462 12.8971L14.0303 12.9697L19 17.939L23.9697 12.9697C24.2359 12.7034 24.6526 12.6792 24.9462 12.8971L25.0303 12.9697C25.2966 13.2359 25.3208 13.6526 25.1029 13.9462L25.0303 14.0303L20.061 19L25.0303 23.9697C25.2966 24.2359 25.3208 24.6526 25.1029 24.9462L25.0303 25.0303C24.7641 25.2966 24.3474 25.3208 24.0538 25.1029L23.9697 25.0303L19 20.061L14.0303 25.0303C13.7641 25.2966 13.3474 25.3208 13.0538 25.1029L12.9697 25.0303C12.7034 24.7641 12.6792 24.3474 12.8971 24.0538L12.9697 23.9697L17.939 19L12.9697 14.0303C12.7034 13.7641 12.6792 13.3474 12.8971 13.0538L12.9697 12.9697L12.8971 13.0538Z" fill="white" />
                  </svg> */}
                </div>
                <div className="right-side-arrow">
                  <img src={UtilityFunctions.getImageURL() + "arrow-signle-up.svg"} alt="" />
                  {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.29289 8.29289C4.68342 7.90237 5.31658 7.90237 5.70711 8.29289L12 14.5858L18.2929 8.29289C18.6834 7.90237 19.3166 7.90237 19.7071 8.29289C20.0976 8.68342 20.0976 9.31658 19.7071 9.70711L12.7071 16.7071C12.3166 17.0976 11.6834 17.0976 11.2929 16.7071L4.29289 9.70711C3.90237 9.31658 3.90237 8.68342 4.29289 8.29289Z" fill="white" />
                  </svg> */}
                </div>
              </div>
              <div className={` v-scrool-de ${isInstituteListOpen ? "" : "d-none"}`}>
                <ul className="v-list-desigh list-unstyled">
                  {instituteList?.map(institute => {
                    if (selectedInstitute === institute) { return <li className="active" key={institute?.id} style={{ cursor: "pointer" }} onClick={() => { setSelectedInstitute(institute); setIsInstituteListOpen(false); setErrorState({ ...errorState, institute: false }) }}>{institute?.name}<img src={UtilityFunctions.getImageURL() + "checkmark-white.svg"} alt="" /></li> }
                    else return <li value={institute?.id} key={institute?.id} style={{ cursor: "pointer" }} onClick={() => { setSelectedInstitute(institute); setIsInstituteListOpen(false); setErrorState({ ...errorState, institute: false }) }}>{institute?.name}</li>
                  })
                  }
                </ul>
              </div>
            </div>
          </div>
          <div>
            <div className={`form-floating mb-3 label-dark label-setup my-focuse-degrendt ${blurred ? "focused" : ""}`} >
              <input type="text"
                maxLength={250}
                className={`form-control pt-4 ${errorState?.name ? "error" : ""}`}
                value={institutionName}
                onFocus={() => { setBlurred(false) }}
                onBlur={() => { institutionName && setBlurred(true) }}
                onChange={(e) => { setInstitutionName(e.target.value); setErrorState({ ...errorState, name: false }) }}
              />
              <label htmlFor="floatingInput">{STRING_CONSTANTS.advanced_profile.step6.institution_name}</label>
              <span className="input-group-text">
                <img src={UtilityFunctions.getImageURL() + "building-ico-form.svg"} alt="" />
                <img src={UtilityFunctions.getImageURL() + "building-input-fill.svg"} alt="" />
                {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g opacity="0.5">
                    <path d="M8.5 5.5C7.94772 5.5 7.5 5.94772 7.5 6.5C7.5 7.05228 7.94772 7.5 8.5 7.5C9.05229 7.5 9.5 7.05228 9.5 6.5C9.5 5.94772 9.05229 5.5 8.5 5.5ZM7.5 13.5C7.5 12.9477 7.94772 12.5 8.5 12.5C9.05229 12.5 9.5 12.9477 9.5 13.5C9.5 14.0523 9.05229 14.5 8.5 14.5C7.94772 14.5 7.5 14.0523 7.5 13.5ZM8.5 9C7.94772 9 7.5 9.44771 7.5 10C7.5 10.5523 7.94772 11 8.5 11C9.05229 11 9.5 10.5523 9.5 10C9.5 9.44771 9.05229 9 8.5 9ZM11 6.5C11 5.94772 11.4477 5.5 12 5.5C12.5523 5.5 13 5.94772 13 6.5C13 7.05228 12.5523 7.5 12 7.5C11.4477 7.5 11 7.05228 11 6.5ZM12 12.5C11.4477 12.5 11 12.9477 11 13.5C11 14.0523 11.4477 14.5 12 14.5C12.5523 14.5 13 14.0523 13 13.5C13 12.9477 12.5523 12.5 12 12.5ZM14.5 13.5C14.5 12.9477 14.9477 12.5 15.5 12.5C16.0523 12.5 16.5 12.9477 16.5 13.5C16.5 14.0523 16.0523 14.5 15.5 14.5C14.9477 14.5 14.5 14.0523 14.5 13.5ZM12 9C11.4477 9 11 9.44771 11 10C11 10.5523 11.4477 11 12 11C12.5523 11 13 10.5523 13 10C13 9.44771 12.5523 9 12 9ZM6.25 2C5.00736 2 4 3.00736 4 4.25V20.75C4 21.1642 4.33579 21.5 4.75 21.5H19.2528C19.667 21.5 20.0028 21.1642 20.0028 20.75V11.7493C20.0028 10.5067 18.9954 9.4993 17.7528 9.4993H16.5V4.25C16.5 3.00736 15.4926 2 14.25 2H6.25ZM5.5 4.25C5.5 3.83579 5.83579 3.5 6.25 3.5H14.25C14.6642 3.5 15 3.83579 15 4.25V10.2493C15 10.6635 15.3358 10.9993 15.75 10.9993H17.7528C18.167 10.9993 18.5028 11.3351 18.5028 11.7493V20H16.5V17.25C16.5 16.8358 16.1642 16.5 15.75 16.5H8.25C7.83579 16.5 7.5 16.8358 7.5 17.25V20H5.5V4.25ZM15 18V20H12.75V18H15ZM11.25 18V20H9V18H11.25Z" fill="white" />
                  </g>
                </svg> */}
                {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g opacity="1">
                    <path d="M8.5 5.5C7.94772 5.5 7.5 5.94772 7.5 6.5C7.5 7.05228 7.94772 7.5 8.5 7.5C9.05229 7.5 9.5 7.05228 9.5 6.5C9.5 5.94772 9.05229 5.5 8.5 5.5ZM7.5 13.5C7.5 12.9477 7.94772 12.5 8.5 12.5C9.05229 12.5 9.5 12.9477 9.5 13.5C9.5 14.0523 9.05229 14.5 8.5 14.5C7.94772 14.5 7.5 14.0523 7.5 13.5ZM8.5 9C7.94772 9 7.5 9.44771 7.5 10C7.5 10.5523 7.94772 11 8.5 11C9.05229 11 9.5 10.5523 9.5 10C9.5 9.44771 9.05229 9 8.5 9ZM11 6.5C11 5.94772 11.4477 5.5 12 5.5C12.5523 5.5 13 5.94772 13 6.5C13 7.05228 12.5523 7.5 12 7.5C11.4477 7.5 11 7.05228 11 6.5ZM12 12.5C11.4477 12.5 11 12.9477 11 13.5C11 14.0523 11.4477 14.5 12 14.5C12.5523 14.5 13 14.0523 13 13.5C13 12.9477 12.5523 12.5 12 12.5ZM14.5 13.5C14.5 12.9477 14.9477 12.5 15.5 12.5C16.0523 12.5 16.5 12.9477 16.5 13.5C16.5 14.0523 16.0523 14.5 15.5 14.5C14.9477 14.5 14.5 14.0523 14.5 13.5ZM12 9C11.4477 9 11 9.44771 11 10C11 10.5523 11.4477 11 12 11C12.5523 11 13 10.5523 13 10C13 9.44771 12.5523 9 12 9ZM6.25 2C5.00736 2 4 3.00736 4 4.25V20.75C4 21.1642 4.33579 21.5 4.75 21.5H19.2528C19.667 21.5 20.0028 21.1642 20.0028 20.75V11.7493C20.0028 10.5067 18.9954 9.4993 17.7528 9.4993H16.5V4.25C16.5 3.00736 15.4926 2 14.25 2H6.25ZM5.5 4.25C5.5 3.83579 5.83579 3.5 6.25 3.5H14.25C14.6642 3.5 15 3.83579 15 4.25V10.2493C15 10.6635 15.3358 10.9993 15.75 10.9993H17.7528C18.167 10.9993 18.5028 11.3351 18.5028 11.7493V20H16.5V17.25C16.5 16.8358 16.1642 16.5 15.75 16.5H8.25C7.83579 16.5 7.5 16.8358 7.5 17.25V20H5.5V4.25ZM15 18V20H12.75V18H15ZM11.25 18V20H9V18H11.25Z" fill="white" />
                  </g>
                </svg> */}
              </span>

            </div>

          </div>
          <div>
            <div className="custom-select-desing mb-3">
              {/* add {focused} when focus */}
              {/* data file add this class {data-filed} */}
              <div className={`custom-select-input ${isCourseListOpen ? "focused" : selectedCourse ? "data-filed" : ""}`} onClick={() => setIsCourseListOpen(!isCourseListOpen)} >
                <input type="text" className={`${errorState.course ? "error" : ""}`} id="mysele" disabled />
                <label htmlFor="mysele">{selectedCourse?.name || STRING_CONSTANTS.advanced_profile.step6.course_type}</label>
                <div className="select-left-icon">
                  <img src={UtilityFunctions.getImageURL() + "book-blank-gray.svg"} alt="" />
                  <img src={UtilityFunctions.getImageURL() + "book-fill-blue.svg"} alt="" />
                  {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g opacity="0.5">
                      <path d="M4 4C2.89543 4 2 4.89543 2 6V18C2 19.1046 2.89543 20 4 20H10C10.7684 20 11.4692 19.7111 12 19.2361C12.5308 19.7111 13.2316 20 14 20H20C21.1046 20 22 19.1046 22 18V6C22 4.89543 21.1046 4 20 4H14C13.2316 4 12.5308 4.28885 12 4.7639C11.4692 4.28885 10.7684 4 10 4H4ZM11 7V17C11 17.5523 10.5523 18 10 18H4V6H10C10.5523 6 11 6.44772 11 7ZM13 17V7C13 6.44772 13.4477 6 14 6H20V18H14C13.4477 18 13 17.5523 13 17Z" fill="white" />
                    </g>
                  </svg> */}
                  {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g opacity="">
                      <path d="M4 4C2.89543 4 2 4.89543 2 6V18C2 19.1046 2.89543 20 4 20H10C10.7684 20 11.4692 19.7111 12 19.2361C12.5308 19.7111 13.2316 20 14 20H20C21.1046 20 22 19.1046 22 18V6C22 4.89543 21.1046 4 20 4H14C13.2316 4 12.5308 4.28885 12 4.7639C11.4692 4.28885 10.7684 4 10 4H4ZM11 7V17C11 17.5523 10.5523 18 10 18H4V6H10C10.5523 6 11 6.44772 11 7ZM13 17V7C13 6.44772 13.4477 6 14 6H20V18H14C13.4477 18 13 17.5523 13 17Z" fill="#38bbf4" />
                    </g>
                  </svg> */}
                </div>
                <div className="data-filed-cross" onClick={() => { setSelectedCourse(null); setIsCourseListOpen(false) }}>
                  <img src={UtilityFunctions.getImageURL() + "input-cross-right-img.svg"} alt="" />
                  {/* <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="38" height="38" rx="10" fill="white" fillOpacity="0.1" />
                    <path d="M12.8971 13.0538L12.9697 12.9697C13.2359 12.7034 13.6526 12.6792 13.9462 12.8971L14.0303 12.9697L19 17.939L23.9697 12.9697C24.2359 12.7034 24.6526 12.6792 24.9462 12.8971L25.0303 12.9697C25.2966 13.2359 25.3208 13.6526 25.1029 13.9462L25.0303 14.0303L20.061 19L25.0303 23.9697C25.2966 24.2359 25.3208 24.6526 25.1029 24.9462L25.0303 25.0303C24.7641 25.2966 24.3474 25.3208 24.0538 25.1029L23.9697 25.0303L19 20.061L14.0303 25.0303C13.7641 25.2966 13.3474 25.3208 13.0538 25.1029L12.9697 25.0303C12.7034 24.7641 12.6792 24.3474 12.8971 24.0538L12.9697 23.9697L17.939 19L12.9697 14.0303C12.7034 13.7641 12.6792 13.3474 12.8971 13.0538L12.9697 12.9697L12.8971 13.0538Z" fill="white" />
                  </svg> */}
                </div>
                <div className="right-side-arrow">
                  <img src={UtilityFunctions.getImageURL() + "arrow-signle-up.svg"} alt="" />
                  {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.29289 8.29289C4.68342 7.90237 5.31658 7.90237 5.70711 8.29289L12 14.5858L18.2929 8.29289C18.6834 7.90237 19.3166 7.90237 19.7071 8.29289C20.0976 8.68342 20.0976 9.31658 19.7071 9.70711L12.7071 16.7071C12.3166 17.0976 11.6834 17.0976 11.2929 16.7071L4.29289 9.70711C3.90237 9.31658 3.90237 8.68342 4.29289 8.29289Z" fill="white" />
                  </svg> */}
                </div>
              </div>
              <div className={` v-scrool-de ${isCourseListOpen ? "" : "d-none"}`}>
                <ul className="v-list-desigh list-unstyled">
                  {courseList?.map(course => {
                    if (selectedCourse === course) { return <li className="active" key={course?.id} style={{ cursor: "pointer" }} onClick={() => { setSelectedCourse(course); setIsCourseListOpen(false); setErrorState({ ...errorState, course: false }) }}>{course?.name}<img src={UtilityFunctions.getImageURL() + "checkmark-white.svg"} alt="" /></li> }
                    else return <li value={course?.id} key={course?.id} style={{ cursor: "pointer" }} onClick={() => { setSelectedCourse(course); setIsCourseListOpen(false); setErrorState({ ...errorState, course: false }) }}>{course?.name}</li>
                  })
                  }
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div className="next-wrap">
      <button className={
        (selectedCourse && selectedInstitute && institutionName) ?
          "cricle-next"
          : "cricle-next-inactive"
      }
        onClick={(e) => submitHandler()}
      >
        <img
          alt=""
          src={UtilityFunctions.getImageURL() + "arrow-bottom.svg"}
        />
      </button>
    </div>
  </>
}

export default withRouter(Qualififcation)