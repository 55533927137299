import React from "react";
import HRAssisstent from "../../../components/HRAssisstent/HRAssisstent";

function HRAssisstentPage() {
  return (
    <div>
      <HRAssisstent />
    </div>
  );
}

export default HRAssisstentPage;
