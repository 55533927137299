import React, { useEffect, useRef, useState } from "react";
import Header from "../../../components/Header/Header";
import "./results.css";

const feedbackData = [
  {
    icon: "/assets/images/interview-question-icon.svg",
    ques: (
      <div className="ques-wrap">
        <div className="ques-title">Interview Question</div>
        <div className="ques-desc">
          What is your experience using Microsoft excel for data entry?
        </div>
      </div>
    ),
    ans: (
      <div style={{ borderLeft: "1px solid #F495D3" }} className="ans-wrap">
        <div className="ans-title">Your Answer</div>
        <div className="ans-desc">
          <p className="lineheightPara">
            I have not used Microsoft excel for any data entry before. I have
            only used it a couple of times in the past.
          </p>
        </div>
      </div>
    ),
  },
  {
    icon: "/assets/images/ai-feedback-icon.svg",
    quesTitle: "",
    ques: (
      <div className="ques-wrap">
        <div className="ques-title">AI Feedback</div>
      </div>
    ),
    ans: (
      <div style={{ borderLeft: "1px solid #FFB800" }} className="ans-wrap">
        <div className="ans-desc">
          <p>
            You provided limited details on your experience and skills regarding
            Microsoft Excel and data entry.
          </p>
          <p className="lineheightPara">
            For future interviews, prepare specific examples of how you have
            used this in past roles, highlighting your contributions and the
            impact of the project.
          </p>
        </div>
      </div>
    ),
  },
];

export default function Results() {
  const dataKey: number = 2; //use 1 for improvement content and 2 for nice work
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);
  const mobileVideoRef = useRef(null);

  const totalPages = 4;

  // State to manage current page
  const [currentPage, setCurrentPage] = useState(1);

  // Handler for Next button
  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Handler for Previous button
  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handlePlayVideo = () => {
    const video = videoRef.current;
    if (video.paused) {
      video.play();
      setIsPlaying(true);
    } else {
      video.pause();
      setIsPlaying(false);
    }
  };

  const handleMobilePlayVideo = () => {
    const video = mobileVideoRef.current;
    if (video.paused) {
      video.play();
      setIsPlaying(true);
    } else {
      video.pause();
      setIsPlaying(false);
    }
  };

  return (
    <div className="results-page-wrap">
      <Header hideMenus />
      <div className="results-content-wrap">
        <div className="results-content-header">
          <div>
            <div className="back">
              <img
                src="/assets/images/arrow-left-sm.svg"
                alt=""
                className=" cursor-pointer"
              />
              Here’s how you went:
            </div>
            {dataKey === 1 ? (
              <div className="improvment-title">
                <span>🧐</span> Needs improvement. Please see how you can
                improve below
              </div>
            ) : (
              <div className="improvment-title greenColor">
                <span>🥳</span> Nice Work! You can browse job matches based on
                this interview.
              </div>
            )}
          </div>
          <button className=" border-0 secondaryButton downloadButton">
            <img src="assets/images/Cloud Arrow Down.svg" alt="" />
            Download Results
          </button>
        </div>
        <div className="result-mobile-header">
          <div className=" d-flex justify-content-between align-items-center w-100">
            <div className="back">
              <img
                src="/assets/images/arrow-left-sm.svg"
                alt=""
                className=" cursor-pointer"
              />
              Here’s how you went:
            </div>
            <button className=" border-0 px-3 py-3 secondaryButton roundButton">
              <img src="assets/images/Cloud Arrow Down.svg" alt="" />
            </button>
          </div>
          {dataKey === 1 ? (
            <div className="improvment-title">
              <span>🧐</span> Needs improvement. Please see how you can improve
              below
            </div>
          ) : (
            <div className="improvment-title greenColor">
              <span>🥳</span> Nice Work! You can browse job matches based on
              this interview.
            </div>
          )}
        </div>
        <div className="based-on-title">
          Based on your results we recommend you take a course
        </div>
        <div className="buttons-wrap">
          {dataKey === 1 ? (
            <button className="border-0 primaryButton">
              Browse Recommended Courses
              <img src="assets/images/arrow-up-left-black.svg" alt="" />
            </button>
          ) : (
            <button className="border-0 primaryButton">
              See Job Matches
              <img src="assets/images/arrow-up-left-black.svg" alt="" />
            </button>
          )}
          <button className="border-0 secondaryButton">
            Take Interview Again
            <img src="/assets/images/Arrow Repeat All.svg" alt="" />
          </button>
        </div>
        <div className=" feedback-footer-wrap">
          <div className="feedbacks-content-wrap">
            <div className="feedback-heading">
              Here is your summery and feedback:
            </div>
            <div className="feedback-content-wrap">
              {feedbackData?.map((item, index) => {
                return (
                  <div className="feedback-card-wrap" key={index}>
                    <div>
                      <img src={item?.icon} alt="" />
                    </div>
                    <div>
                      {item?.ques}
                      {item?.ans}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="video-container" onClick={() => handlePlayVideo()}>
            <video
              ref={videoRef}
              poster="/assets/images/Screen Shot 2024-04-29 at 9.53 1.png"
              // onClick={(e) => e.stopPropagation()}
              onPause={() => setIsPlaying(false)}
              onEnded={() => setIsPlaying(false)}
              // controls
            >
              <source
                src="http://media.w3.org/2010/05/sintel/trailer.mp4"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
            {!isPlaying && (
              <div
                className="play-button-overlay"
                onClick={() => handlePlayVideo()}
              >
                <img src="/assets/images/Group 421.svg" alt="" />
              </div>
            )}
          </div>
        </div>
        <div className=" mobile-feedback-footer-wrap">
          <div className="feedbacks-content-wrap">
            <div className="feedback-heading">
              Here is your summery and feedback:
            </div>
            <div className="feedback-content-wrap">
              {feedbackData?.map((item, index) => {
                return (
                  <div className="feedback-card-wrap" key={index}>
                    <div>
                      <img src={item?.icon} alt="" className="feedback-icons" />
                    </div>
                    <div>
                      {item?.ques}
                      {item?.ans}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="mobile-video-wrap">
              <div
                className="video-container"
                onClick={() => handleMobilePlayVideo()}
              >
                <video
                  ref={mobileVideoRef}
                  poster="/assets/images/Screen Shot 2024-04-29 at 9.53 1.png"
                  // onClick={(e) => e.stopPropagation()}
                  onPause={() => setIsPlaying(false)}
                  onEnded={() => setIsPlaying(false)}
                  // controls
                >
                  <source
                    src="http://media.w3.org/2010/05/sintel/trailer.mp4"
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
                {!isPlaying && (
                  <div
                    className="play-button-overlay"
                    onClick={() => handleMobilePlayVideo()}
                  >
                    <img src="/assets/images/Group 421.svg" alt="" />
                  </div>
                )}
              </div>
              <div>
                <div className="video-heading">AI Feedback</div>
                <div className="video-desc">
                  Receive feedback directly from Maxine. <span>Play now</span>
                </div>
              </div>
            </div>
            <div className="mobile-results-pagination-wrap">
              <img
                src="../assets/images/Caret.svg"
                alt=""
                className="cursor-pointer"
                onClick={handlePrevious}
              />
              <span>
                {currentPage} of {totalPages}
              </span>
              <img
                src="../assets/images/Caret.svg"
                alt=""
                style={{ rotate: "180deg" }}
                className="cursor-pointer"
                onClick={handleNext}
              />
            </div>
          </div>
        </div>
        <div className="mobile-buttons-wrap">
          <button className="border-0 secondaryButton">
            Retake Interview
            <img src="/assets/images/Arrow Repeat All.svg" alt="" />
          </button>
          {dataKey === 1 ? (
            <button className="border-0 primaryButton">
              Browse Courses
              <img src="assets/images/arrow-up-left-black.svg" alt="" />
            </button>
          ) : (
            <button className="border-0 primaryButton">
              Job Matches
              <img src="assets/images/arrow-up-left-black.svg" alt="" />
            </button>
          )}
        </div>
        <div className="results-pagination-wrap">
          <img
            src="../assets/images/Caret.svg"
            alt=""
            className="cursor-pointer"
            onClick={handlePrevious}
          />
          <span>
            {currentPage} of {totalPages}
          </span>
          <img
            src="../assets/images/Caret.svg"
            alt=""
            style={{ rotate: "180deg" }}
            className="cursor-pointer"
            onClick={handleNext}
          />
        </div>
      </div>
    </div>
  );
}
