import React from 'react';
import './TermsAndPrivacy.css';

const TermsAndConditionsPage = () => {
    return (
        <div className="blog-details-page">
            <header className="blog-header">
                <img
                    src="/assets/images/blog-header.png" 
                    alt="Header"
                    className="blog-header-image"
                />
            </header>

            <div className='blog-details-wrap'>
                <div className="row">
                    <div className="col-12">
                        <div className="main-heading">Setting up your MPLOI profile as a job seeker</div>
                        <div className='post-date'>12 March 2024</div>
                        <div className="main-content">
                        Lorem ipsum dolor sit amet consectetur. Magnis elementum congue egestas sodales ultrices eros. Posuere enim et quis eros velit sem lacus facilisi. Erat condimentum mollis enim enim est pellentesque tortor enim ac. In adipiscing feugiat aliquam nulla. Vestibulum aliquam venenatis iaculis ultrices consequat ipsum sit id egestas. Malesuada sed scelerisque ut nunc nulla amet vitae nunc. Volutpat in tempor venenatis lectus mollis purus. Amet orci pretium donec aliquet in. A quis neque quis volutpat ultrices. Et aliquet mi gravida libero nulla euismod. Ut suspendisse eget porta feugiat massa. Amet est id tristique ac sed turpis non arcu id. Volutpat egestas dis commodo vel aenean tempor urna. Dui montes vitae id duis mattis. Velit ullamcorper at facilisis ut augue ut viverra. Ut interdum viverra purus gravida montes aliquam nisl convallis. Orci amet enim massa tempor risus. Dolor scelerisque enim velit massa pulvinar. Suspendisse non nibh ac tristique placerat pellentesque at. Odio etiam dui nulla quam mi sed congue. Nibh aliquam tellus tortor augue amet. Enim in nunc diam enim vel neque mauris integer. Etiam neque vel netus sagittis eu. Viverra at sit eget sit condimentum tortor diam feugiat. Vitae tellus lorem in vitae et praesent sapien quam fames. Est ligula proin egestas eget. Elementum quis enim volutpat magna sed id quis nec. Sed imperdiet turpis sed sed ut accumsan. Scelerisque amet sed sit elit neque. Dictum suspendisse faucibus id semper sollicitudin ut molestie. Id dictum arcu gravida turpis eget vehicula sed pharetra. Accumsan vivamus at euismod maecenas ridiculus sed rutrum auctor. Egestas venenatis varius egestas molestie fermentum non id vitae. Ut eu eget aenean mattis fusce lorem non cursus.
                        </div>
                    </div>
                </div>

                <div className="row sub-section">
                    <div className="col-md-5">
                        <img
                            src="/assets/images/blog-details-img.png" 
                            alt="Sub section"
                            className="sub-image"
                        />
                    </div>
                    <div className="col-md-7">
                        <div className="sub-content">
                            <div className="sub-heading">This is an example of a sub heading</div>
                            <div className='description'>
                            Lorem ipsum dolor sit amet consectetur. Magnis elementum congue egestas sodales ultrices eros. Posuere enim et quis eros velit sem lacus facilisi. Erat condimentum mollis enim enim est pellentesque tortor enim ac. In adipiscing feugiat aliquam nulla. Vestibulum aliquam venenatis iaculis ultrices consequat ipsum sit id egestas. Malesuada sed scelerisque ut nunc nulla amet vitae nunc. Volutpat in tempor venenatis lectus mollis purus. Amet orci pretium donec aliquet in. A quis neque quis volutpat ultrices. 
<br /><br />
Et aliquet mi gravida libero nulla euismod. Ut suspendisse eget porta feugiat massa. Amet est id tristique ac sed turpis non arcu id. Volutpat egestas dis commodo vel aenean tempor urna. Dui montes vitae id duis mattis. Velit ullamcorper at facilisis ut augue ut viverra. Ut interdum viverra purus gravida montes aliquam nisl convallis. Orci amet enim massa tempor risus. Dolor scelerisque enim velit massa pulvinar. Suspendisse non nibh ac tristique placerat pellentesque at. Odio etiam dui nulla quam mi sed congue.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row sub-section">
                    <div className="col-md-12">
                        <div className="sub-content">
                            <div className="sub-heading">This is an example of a sub heading</div>
                            <div className='description'>
                            Lorem ipsum dolor sit amet consectetur. Magnis elementum congue egestas sodales ultrices eros. Posuere enim et quis eros velit sem lacus facilisi. Erat condimentum mollis enim enim est pellentesque tortor enim ac. In adipiscing feugiat aliquam nulla. Vestibulum aliquam venenatis iaculis ultrices consequat ipsum sit id egestas. Malesuada sed scelerisque ut nunc nulla amet vitae nunc. Volutpat in tempor venenatis lectus mollis purus. Amet orci pretium donec aliquet in. A quis neque quis volutpat ultrices. Et aliquet mi gravida libero nulla euismod. Ut suspendisse eget porta feugiat massa. Amet est id tristique ac sed turpis non arcu id. Volutpat egestas dis commodo vel aenean tempor urna. Dui montes vitae id duis mattis. Velit ullamcorper at facilisis ut augue ut viverra. Ut interdum viverra purus gravida montes aliquam nisl convallis. Orci amet enim massa tempor risus. Dolor scelerisque enim velit massa pulvinar. Suspendisse non nibh ac tristique placerat pellentesque at. Odio etiam dui nulla quam mi sed congue. Nibh aliquam tellus tortor augue amet. Enim in nunc diam enim vel neque mauris integer. Etiam neque vel netus sagittis eu. Viverra at sit eget sit condimentum tortor diam feugiat. Vitae tellus lorem in vitae et praesent sapien quam fames. Est ligula proin egestas eget. Elementum quis enim volutpat magna sed id quis nec. Sed imperdiet turpis sed sed ut accumsan. Scelerisque amet sed sit elit neque. Dictum suspendisse faucibus id semper sollicitudin ut molestie. Id dictum arcu gravida turpis eget vehicula sed pharetra. Accumsan vivamus at euismod maecenas ridiculus sed rutrum auctor. Egestas venenatis varius egestas molestie fermentum non id vitae. Ut eu eget aenean mattis fusce lorem non cursus.
                            </div>
                        </div>
                    </div>
                   
                </div>
                <div className="row sub-section">
                    <div className="col-md-7">
                        <div className="sub-content">
                            <div className="sub-heading">This is an example of a sub heading</div>
                            <div className='description'>
                            Lorem ipsum dolor sit amet consectetur. Magnis elementum congue egestas sodales ultrices eros. Posuere enim et quis eros velit sem lacus facilisi. Erat condimentum mollis enim enim est pellentesque tortor enim ac. In adipiscing feugiat aliquam nulla. Vestibulum aliquam venenatis iaculis ultrices consequat ipsum sit id egestas. Malesuada sed scelerisque ut nunc nulla amet vitae nunc. Volutpat in tempor venenatis lectus mollis purus. Amet orci pretium donec aliquet in. A quis neque quis volutpat ultrices. 
<br /><br />
Et aliquet mi gravida libero nulla euismod. Ut suspendisse eget porta feugiat massa. Amet est id tristique ac sed turpis non arcu id. Volutpat egestas dis commodo vel aenean tempor urna. Dui montes vitae id duis mattis. Velit ullamcorper at facilisis ut augue ut viverra. Ut interdum viverra purus gravida montes aliquam nisl convallis. Orci amet enim massa tempor risus. Dolor scelerisque enim velit massa pulvinar. Suspendisse non nibh ac tristique placerat pellentesque at. Odio etiam dui nulla quam mi sed congue.
                            </div>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <img
                            src="/assets/images/blog-details-img.png" 
                            alt="Sub section"
                            className="sub-image"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TermsAndConditionsPage;
