import React from "react";
import { UtilityFunctions } from "../../src/utils/UtilityFunctions";
import { Button, Modal } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { STRING_CONSTANTS } from "../utils/constants/stringConstants";

const LocationAccessPrompt = (props) => {

  return (
    <Modal
      className="bg-transparent logout-popup height-296"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={props?.show}
      onHide={props?.handleClose}
    >
      <Modal.Body>
        <img
          alt="logout"
          src={UtilityFunctions.getImageURL() + "location-icon-whtie.svg"}
        />
        <h3 className="pt-2 mb-0">In order to view jobs in your area we will need to turn on Location Services</h3>
        <div className="radio-buton d-flex align-items-center gap-2 justify-content-center mt-4 pt-3 mb-5">
          <span className={
            // !props.newestJob ? "text-white active" : 
            "text-white"
          }>No</span>
          <label className="switch">
            <input
              type="checkbox"
              checked={props?.locationPermission}
              onClick={() => props?.handleClose()}
              onChange={e => props?.setLocationPermission(e.target.checked)}
            />
            <span className="slider round">
            </span>
          </label>
          <span className={
            "text-white"
          }>Yes</span>
        </div>
        <div>
          <Button className='mx-auto border-0 bg-transparent cancel-button p-0' variant="primary" onClick={props.handleClose}>
            No, thanks
          </Button>

        </div>
      </Modal.Body>
    </Modal>

  )
}
export default withRouter(LocationAccessPrompt);