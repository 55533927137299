import React, { useState, useEffect } from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import PricingCardWrap from '../../components/PricingCardWrap/PricingCardWrap';
import Explore from '../../components/Explore/Explore';
import ExploreMobile from '../../components/ExploreMobile/ExploreMobile';
import './Pricing.css';

function Pricing() {
  const [activeTab, setActiveTab] = useState('comments'); // State to manage active tab

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const employerSubTitle =`We believe that great hiring tools shouldn't break the bank!`;
  const candidateSubTitle =`Unlock your full potential and let MPLOI be your partner in success`;
  const employerDescription = `
    That's why our pricing is based on the size of your team - the smaller your crew, the lower your cost. 
    We understand that SMEs don't have the same recruitment budgets as the big corporates, so we've structured our pricing to be super affordable without sacrificing any of the good stuff. 
    So whether you're a team of two or two hundred, MPLOI has a pricing plan that fits your budget and your recruitment needs.
  `;

  const candidateDescription = `
    We're dedicated to empowering you throughout your job search journey. With our premium features, you can take your career to new heights and stand out from the competition. Boost your profile visibility and ensure that your unique skills and experience catch the eye of top employers. Invest in your professional development with our cutting-edge online training courses, designed to help you up-skill and stay ahead in your field. And when you're ready to take your sales game to the next level, subscribe to our revolutionary AI Sales Trainer module, your personal coach for mastering the art of selling. With MPLOI's premium offerings, you'll have the tools and support you need to achieve your career goals and land your dream job.
  `;

  return (
    <div className='pricing-page-wrap'>
      <Header />
      <div className='content-wrap-price'>
        <div className='content'>
          <div className='title'>MPLOI Pricing</div>
          <div className='sub-title'>

          {activeTab === 'comments' ? employerSubTitle : candidateSubTitle}
          </div>
          <div className='description'>
            {activeTab === 'comments' ? employerDescription : candidateDescription}
          </div>
        </div>
      </div>
      <PricingCardWrap activeTab={activeTab} setActiveTab={setActiveTab} /> {/* Pass state as props */}
      <Explore />
      <ExploreMobile />
      <Footer />
    </div>
  );
}

export default Pricing;
