import React, { useEffect, useRef, useState } from "react"
import ValidationErrorToast from "../../../components/validationErrorToast";
import { UtilityFunctions } from "../../../utils/UtilityFunctions"
import { bindActionCreators } from "redux";
import { actionCreators, State } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from 'lodash';
import { STRING_CONSTANTS } from "../../../utils/constants/stringConstants";
import { mploiAI } from "../../../store/reducers/mploiAI";
import { setJobLocation } from "../../../store/action-creators";

const JobTitle = (props: any) => {
    const [title, setTitle] = useState("")
    const titleRef = useRef<any>();
    const [btnClick, setBtnClick] = useState(false);

    const dispatch = useDispatch();
    const { setJobTitle, setJobCover, setJobCategory, setJobSubcategory, setJobDesc, setJobExperience, setJobMaxSalary, setJobMinSalary, setJobType, setJobRateType, } = bindActionCreators(actionCreators, dispatch);

    const state = useSelector((state: State) => state);
    useEffect(() => {
        setTitle(state.newJob.title)
        titleRef.current.value = state.newJob.title
    }, [])
    function validate(input) {
        // allows one space at max
        return setTitle(input.target.value.replace(/^\s+|\s+$/g, " "))
    }

    const submitHandler = async (e) => {
        e.preventDefault();
        setBtnClick(true);

        let croppedSpaceTitle = titleRef?.current?.value.replace(/ /g, '')
        if (croppedSpaceTitle.length < 3) {
            ValidationErrorToast("Job Title", "Please add a job title before proceeding")
            return;
        }

        const result = await mploiAI({ method: 'enhance-job', data: {job_title: title.trim()}});
        if (result) {
            setJobTitle(result.title);
            setJobCover(result.cover);
            setJobCategory(result.category);
            setJobSubcategory(result.subcategory);
            setJobDesc(result.description);
            setJobExperience(result.experience);
            setJobMinSalary(result.minSalary);
            setJobMaxSalary(result.maxSalary);
            setJobType(result.jobType);
            setJobRateType(result.rateType);
            console.log('result', result.location)
            setJobLocation(result.location);

            props.nav.next();
        }
    }

    return (<>
        <div className='top-divde-spa'>
            <span>
                <i
                    className="fa fa-angle-left text-white d-flex justify-content-between"
                    aria-hidden="true"
                    onClick={() => {
                        if (props?.editJob?.editJob) {
                            props.history.push(STRING_CONSTANTS.navigationString.dashboard.home)
                        }
                        else { props?.nav?.back() }
                    }}
                ></i>
            </span>
            <div className="step-content paragraph-spacin">
                <button type='button' disabled>
                    Job Setup
                </button>
                <p>In a few words, describe the role you are hiring for</p>
                <div className="step-text">
                    Example "Part-time junior accountant"
                </div>
                <div className="step-text">
                    Example "Dental assistant 1 year experience"
                </div>
                <div className="step-text">
                    Example "Trainee barista"
                </div>
            </div>
        </div>
        <form onSubmit={submitHandler}>
            <div className="form-wrap">
                <div className="v-form-width">
                    <div className="form-floating mb-3">
                        <input type='text' className={`form-control ${btnClick && isEmpty(titleRef.current.value) ? 'error' : ""}`} placeholder="Job Title"
                            ref={titleRef}
                            value={title}
                            onChange={e => validate(e)} />
                        <label htmlFor="floatingInput">Role description</label>
                        <span className="input-group-text">
                            <img src={UtilityFunctions.getImageURL() + "building-ico-form.svg"} alt="" />
                            <img src={UtilityFunctions.getImageURL() + "building-input-fill.svg"} alt="" />
                        </span>
                    </div>
                </div>
            </div>
        </form>
        <div className="next-wrap">
            <button className={title ? "cricle-next" : "cricle-next-inactive"}
                onClick={(e) => submitHandler(e)}>
                <img
                    alt=""
                    src={UtilityFunctions.getImageURL() + "arrow-bottom.svg"}
                />
            </button>
        </div>
    </>)
}

export default (JobTitle)