import React, { useState } from "react";

import { Button, Form, Modal } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import ValidationErrorToast from "../../../components/validationErrorToast";
import { UtilityFunctions } from "../../../utils/UtilityFunctions";

const flagReason = [
    "Inappropriate Content",
    "Fake or Misleading Information",
    "Suspicious Activity",
    "Harassment or Abuse",
    "Impersonation",
    "Violation of Terms of Service",
    "Spam or Scam"
]



const FlagConverstion = (props) => {

    const [selectedReason, setSelectedReason] = useState<any>(0);
    const [reasonValue, setReasonValue] = useState<any>(null);

    const handleSubmit = () => {
        // console.log(reasonValue);
        if (reasonValue.length > 250) {
            ValidationErrorToast("error", "word limit exceed");
            return;
        }
        if (reasonValue.length === 0) {
            ValidationErrorToast("error", "empty field");
        }

        props.sendToFlag(props?.selectedChat?.id, true, reasonValue);
        props.handleClose();

    }


    return (
        <Modal
            className=" flag-popup "
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={props.show}
            onHide={props.handleClose}
        >
            <Modal.Body>

                <div className=" my-3 position-relative" style={{ width: "100%" }} >
                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.5 16.5V4.5H21.8915L18.3653 10.1004C18.2116 10.3446 18.2116 10.6554 18.3653 10.8996L21.8915 16.5H5.5ZM5.5 18H23.25C23.8402 18 24.1991 17.3498 23.8847 16.8504L19.8863 10.5L23.8847 4.14961C24.1991 3.65017 23.8402 3 23.25 3H4.75C4.33579 3 4 3.33579 4 3.75V24.25C4 24.6642 4.33579 25 4.75 25C5.16421 25 5.5 24.6642 5.5 24.25V18Z" fill="white" />
                    </svg>
                    <div className="position-absolute top-0 end-0 cursor-pointer"
                        onClick={() => { props.handleClose() }}
                        style={{ cursor: "pointer" }}
                    >
                        <img src={` ${UtilityFunctions.getImageURL()}close-icon.svg`} />
                    </div>
                </div>
                <p className="flag-title">Flag this conversation?</p>
                <div>
                    Your feedback matters! Use this feature to report any users that violate our community guidelines. Help us keep our platform safe and enjoyable for everyone. Thank you!
                </div>

                <div className="flag-reason-wrapper my-3">

                    {flagReason.map((item, index) => {
                        return (
                            <div className="d-flex gap-3 align-items-center my-3">

                                <input
                                    type="radio"
                                    value={index}
                                    checked={(selectedReason === index + 1) ? true : false}
                                    onChange={() => {
                                        setSelectedReason(index + 1);
                                        setReasonValue(item);
                                    }}
                                    className="flag-radio-appearance"
                                />
                                <p className="mb-0">{item}</p>
                            </div>
                        )
                    })}
                    <div className="d-flex gap-3 align-items-center">
                        <input
                            type="radio"
                            value="other"
                            checked={selectedReason === "other" ? true : false}
                            onChange={() => { setSelectedReason("other"); setReasonValue("") }}
                            className="flag-radio-appearance"
                        />
                        <p className="mb-0">Other (Please provide details)</p>
                    </div>
                    {selectedReason === "other" &&
                        <div className="d-flex  align-items-center flex-column my-3 flag-reason-textarea">
                            <div className=" d-flex justify-content-between text-label">
                                <p className="mb-0">other</p>
                                <p className={`mb-0 ${reasonValue?.length > 250 ? "text-red" : ""} `}>{reasonValue?.length ? reasonValue?.length : "0"}/250</p>
                            </div>
                            <Form.Control
                                as="textarea"
                                row={2}
                                style={{ border: "0px", padding: "0px" }}
                                placeholder="Your feedback helps us improve."
                                value={reasonValue}
                                onChange={(e) => { setReasonValue(e.target.value) }}
                            />

                        </div>
                    }

                </div>





                <div>

                    <Button
                        className={` border-0 ${reasonValue ? "btn-active" : "btn-disabled"}`}
                        variant="primary"
                        onClick={() => { handleSubmit() }}
                        // style={{ backgroundColor: "#06EFC5", color: "black" }}
                        disabled={!reasonValue}
                    >
                        Submit
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};
export default withRouter(FlagConverstion);
