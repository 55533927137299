import React, { useEffect } from "react";
import Header from "../../components/Header/Header";
import Banner from "../../components/Banner/Banner";
import Member from "../../components/Member/Mebmer";
import JobSearch from "../../components/JobSearch/JobSearch";
// import BlogSlider from '../../components/BlogSlider/BlogSlider';
import Footer from "../../components/Footer/Footer";
import Slider from "react-slick";
import sliderLeftArrow from "../../components/Icons/sliderLeftArrow.svg";
import SliderRightArrow from "../../components/Icons/sliderRightArrow.svg";
import { Helmet } from 'react-helmet';

import "./About.css";

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <img src={SliderRightArrow} alt="Next Arrow" />
    </div>
  );
};

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <img src={sliderLeftArrow} alt="Previous Arrow" />
    </div>
  );
};

function About() {
  const sliderData = [
    {
      id: 0,
      img: "/assets/images/blog-img.png",
      title: "Setting up your MPLOI profile as a job seeker",
      date: "12 March 2024",
      description:
        "Lorem ipsum dolor sit amet consectetur. Orci eu cursus donec augue lectus lectus id orci enim. Vivamus lobortis adipiscing pretium faucibus iaculis at tristique. ",
    },
    {
      id: 0,
      img: "/assets/images/blog-img.png",
      title: "Setting up your MPLOI profile as a job seeker",
      date: "12 March 2024",
      description:
        "Lorem ipsum dolor sit amet consectetur. Orci eu cursus donec augue lectus lectus id orci enim. Vivamus lobortis adipiscing pretium faucibus iaculis at tristique. ",
    },
    {
      id: 0,
      img: "/assets/images/blog-img.png",
      title: "Setting up your MPLOI profile as a job seeker",
      date: "12 March 2024",
      description:
        "Lorem ipsum dolor sit amet consectetur. Orci eu cursus donec augue lectus lectus id orci enim. Vivamus lobortis adipiscing pretium faucibus iaculis at tristique. ",
    },
    {
      id: 0,
      img: "/assets/images/blog-img.png",
      title: "Setting up your MPLOI profile as a job seeker",
      date: "12 March 2024",
      description:
        "Lorem ipsum dolor sit amet consectetur. Orci eu cursus donec augue lectus lectus id orci enim. Vivamus lobortis adipiscing pretium faucibus iaculis at tristique. ",
    },
    {
      id: 0,
      img: "/assets/images/blog-img.png",
      title: "Setting up your MPLOI profile as a job seeker",
      date: "12 March 2024",
      description:
        "Lorem ipsum dolor sit amet consectetur. Orci eu cursus donec augue lectus lectus id orci enim. Vivamus lobortis adipiscing pretium faucibus iaculis at tristique. ",
    },
  ];

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4.3,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 2200,
        settings: {
          slidesToShow: 3.3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1800,
        settings: {
          slidesToShow: 2.1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 1.3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Helmet>
        <title>MPLOI - Bridging the gap between great employers and candidates with AI</title>
      </Helmet>
      <Header />
      <Banner />
      <Member />
      <div className="aboutSection4">
        <div className="blog-header">
          <div className="heading">From The Blog</div>
          <div className="all-btn">
            <a href="/blogs"> View All</a>
          </div>
        </div>
        <div>
          <div className="slider-container">
            <Slider {...settings}>
              {sliderData.map((item) => {
                return (
                  <div key={item.id}>
                    <div className="card-wrap">
                      <div className="image">
                        <img src={item.img} alt="" />
                      </div>
                      <div className="card-content-wrap">
                        <div className="title">{item.title}</div>
                        <div className="blog-date">{item.date}</div>
                        <div className="description">{item.description}</div>
                        <a href="/blog-details">
                          <div className="read-btn">
                            Read More{" "}
                            <img
                              src="/assets/images/arrow-up-right.png"
                              alt=""
                            />
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
          <div className="blog-card-mob">
            <div className="card-wrap-blog">
              <div className="image">
                <img src="/assets/images/blog-img.png" alt="" />
              </div>
              <div className="card-content-wrap">
                <div className="title">
                  Setting up your MPLOI profile as a job seeker
                </div>
                <div className="blog-date">12 March 2024</div>
                <div className="description">
                  Lorem ipsum dolor sit amet consectetur. Orci eu cursus donec
                  augue lectus lectus id orci enim. Vivamus lobortis adipiscing
                  pretium faucibus iaculis at tristique.{" "}
                </div>
                <div className="read-btn">
                  Read More{" "}
                  <img src="/assets/images/arrow-up-right.png" alt="" />
                </div>
              </div>
            </div>
            <div className="card-wrap-blog">
              <div className="image">
                <img src="/assets/images/blog-img.png" alt="" />
              </div>
              <div className="card-content-wrap">
                <div className="title">
                  Setting up your MPLOI profile as a job seeker
                </div>
                <div className="blog-date">12 March 2024</div>
                <div className="description">
                  Lorem ipsum dolor sit amet consectetur. Orci eu cursus donec
                  augue lectus lectus id orci enim. Vivamus lobortis adipiscing
                  pretium faucibus iaculis at tristique.{" "}
                </div>
                <div className="read-btn">
                  Read More{" "}
                  <img src="/assets/images/arrow-up-right.png" alt="" />
                </div>
              </div>
            </div>
            <div className="card-wrap-blog">
              <div className="image">
                <img src="/assets/images/blog-img.png" alt="" />
              </div>
              <div className="card-content-wrap">
                <div className="title">
                  Setting up your MPLOI profile as a job seeker
                </div>
                <div className="blog-date">12 March 2024</div>
                <div className="description">
                  Lorem ipsum dolor sit amet consectetur. Orci eu cursus donec
                  augue lectus lectus id orci enim. Vivamus lobortis adipiscing
                  pretium faucibus iaculis at tristique.{" "}
                </div>
                <div className="read-btn">
                  Read More{" "}
                  <img src="/assets/images/arrow-up-right.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="all-btn-mob mt-5">
            <a href=""> View All</a>
          </div>
        </div>
      </div>
      <JobSearch
        description="Transform your job search with MPLOI's AI-powered matching. Say hello to seamless opportunities and goodbye to endless scrolling."
        showButtons
        title={
          <div className="title">
            <span>Candidate or Job Search </span>
            <br /> All From Your MPLOI App
          </div>
        }
        subDescription={
          <div className="sub-description">
            Join Today and Find Your Perfect Fit!
          </div>
        }
        image={
          <img
            src="/assets/images/jobSearch.png"
            alt="For Jobs"
            className="img-fluid"
          />
        }
      />
      {/* <BlogSlider /> */}
      <Footer />
    </div>
  );
}

export default About;
