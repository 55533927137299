import React from 'react';
import { withRouter } from 'react-router-dom';

const PDFViewer = () => {
    return (
    <div style={{height: '100%'}}>
        <iframe src="../assets/documents/mploi-terms.pdf" width="100%" height="100%" />
    </div>
    );
};
   

const Terms = (props) => {
    return (
        <div className="tab-pane fade show active" id="panel4" role="tabpane" aria-labelledby="panel4-tab">
            <div className="container-fluid">
                <div className='title-row'>
                    <h1>Terms &amp; Conditions</h1>
                </div>
                <div className="blue-shadow-box" style={{height: '1200px'}}>
                    <PDFViewer />
                </div>
            </div>
        </div>
    )
}

export default withRouter(Terms);