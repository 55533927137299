// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pricing-card-wrap {
    display: flex;
    justify-content: center;
    background-color: #140b21;
}

.pricing-card-wrap .card-wrapper {
    display: flex;
    gap: 100px;
}

.pricing-card-wrap .pricing-page {
    padding: 20px;
    text-align: center;
    background-color: #1e102d;
    color: white;
}

.price-tab-wrapper .tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 100px;
}

.price-tab-wrapper .tabs button {
    color: white;
    font-size: 18.33px;
    line-height: 13.33px;
    font-weight: 600;
    padding: 18px 38px;
    border: none;
    background-color: #1e102d;
}

.price-tab-wrapper .tabs button.active-tab {
    background-color: #06EFC5;
    color: black;
}

@media (max-width: 768px) {
    .pricing-card-wrap .card-wrapper {
        flex-direction: column;
    }

}`, "",{"version":3,"sources":["webpack://./src/components/PricingCardWrap/PricingCardWrap.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,UAAU;AACd;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,oBAAoB;AACxB;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,oBAAoB;IACpB,gBAAgB;IAChB,kBAAkB;IAClB,YAAY;IACZ,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI;QACI,sBAAsB;IAC1B;;AAEJ","sourcesContent":[".pricing-card-wrap {\n    display: flex;\n    justify-content: center;\n    background-color: #140b21;\n}\n\n.pricing-card-wrap .card-wrapper {\n    display: flex;\n    gap: 100px;\n}\n\n.pricing-card-wrap .pricing-page {\n    padding: 20px;\n    text-align: center;\n    background-color: #1e102d;\n    color: white;\n}\n\n.price-tab-wrapper .tabs {\n    display: flex;\n    justify-content: center;\n    margin-bottom: 100px;\n}\n\n.price-tab-wrapper .tabs button {\n    color: white;\n    font-size: 18.33px;\n    line-height: 13.33px;\n    font-weight: 600;\n    padding: 18px 38px;\n    border: none;\n    background-color: #1e102d;\n}\n\n.price-tab-wrapper .tabs button.active-tab {\n    background-color: #06EFC5;\n    color: black;\n}\n\n@media (max-width: 768px) {\n    .pricing-card-wrap .card-wrapper {\n        flex-direction: column;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
