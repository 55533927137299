import React from "react";
import './Action.css';  

const stepsData = [
  { description: "Create your 60-second viral video showcasing why MPLOI is a must-have for local businesses." },
  { description: "Upload your video to our competition landing page and provide your Instagram handle." },
  { description: "Nominate a small business in your area that could benefit from MPLOI's AI-powered hiring tools." },
  { description: "We'll share your video on our Instagram, tagging you and the nominated business. Rally your followers, friends, and family to engage with the post. " },
  { description: "The video with the most combined likes and comments after 30 days takes home the grand prize!." }
];

function Action() {
  return (
    <div className="action-container">
      <div className="title">But wait, <span className="highlight">there's more!</span></div>
      <p className="description">
        Not only will you be making a difference in your community, but you'll also have the chance to
        win incredible cash prizes. We're talking cold, hard cash – $10,000 for the top spot, $3,000 for 
        second place, and $1,000 for third. That's some serious dough for doing what you love!
      </p>

      <div className="sub-title">So, how do you get in on this action? It's simple:</div>

      <div className="steps-wrapper">
       
        <div className="steps-row">
          <div className="action-icon-1">
            <img src="/assets/images/action-icon-1.png" alt="" />
          </div>
        <div className="before-connector"></div>
          <div className="step-item">
            <div className="circle">1</div>
          </div>
          <div className="connector"></div>
          <div className="step-item">
            <div className="circle">2</div>
          </div>
          <div className="connector"></div>
          <div className="step-item">
            <div className="circle">3</div>
          </div>
        </div>
      
        <div className="steps-description">
          <div className="desription-wrap"><div className="step-desc">Create your 60-second viral video showcasing why MPLOI is a must-have for local businesses.</div></div>
          <div className="desription-wrap"><div className="step-desc">Upload your video to our competition landing page and provide your Instagram handle.</div></div>
          <div className="desription-wrap"><div className="step-desc">Nominate a small business in your area that could benefit from MPLOI's AI-powered hiring tools.</div></div>
        </div>

        
        <div className="steps-row bottom-row">
          <div className="step-item">
            <div className="circle">4</div>
          </div>
          <div className="connector"></div>
          <div className="step-item">
            <div className="circle">5</div>
          </div>
       
        <div className="before-connector"></div>
        <div className="action-icon-1">
            <img src="/assets/images/action-icon-2.png" alt="" />
          </div>
        </div>
    
        <div className="steps-description">
        <div className="desription-wrap"> <div className="step-desc">We'll share your video on our Instagram, tagging you and the nominated business. Rally your followers, friends, and family to engage with the post.</div></div>
        <div className="desription-wrap"> <div className="step-desc">The video with the most combined likes and comments after 30 days takes home the grand prize!</div></div>
        </div>
      </div>
      <div className="steps-wrapper-mob ">
      {stepsData.map((step, index) => (
        <div key={index} className="step-content-wrapper">
          <div className="step-number">{index + 1}</div>
          <div className="step-description">{step.description}</div>
        </div>
      ))}
        
      </div>
    </div>
  );
}

export default Action;
