import React, { useRef } from "react";
import "./ChatMessages.css";

const ChatMessages = ({ chatData = [], closeChat }) => {
  const inputRef = useRef(null);

  const handleIconClick = () => {
    inputRef.current.click();
  };

  return (
    <div className="chat-container">
      <div className="chat-header">
        <div className="title">Call messages</div>
        <div className="close-icon cursor-pointer" onClick={() => closeChat()}>
          <img src="assets/images/chat-close-icon.png" alt="" />
        </div>
      </div>

      <div className="chat-messages">
        {chatData.map((message, index) => (
          <div
            key={index}
            className={`chat-bubble ${
              message.sender.includes("you") ? "user-bubble" : "manager-bubble"
            }`}
          >
            {message.text && (
              <div
                className="msg"
                dangerouslySetInnerHTML={{ __html: message?.text }}
              />
            )}
            <div className="chat-info">
              <img src={message.avatar} alt="avatar" className="avatar" />
              <span className="sender-name">{message.sender}</span>
            </div>
            {message.attachments.map((file, i) => (
              <div className="attachment" key={i}>
                <a
                  href={file.link}
                  download
                  className="attachment-link cursor-pointer"
                >
                  <img src="/assets/images/document-pdf.png" alt="" />{" "}
                  {file.name}
                </a>
                <a href={file.link} className="download-icon">
                  <img src="/assets/images/download-pdf.png" alt="" />
                </a>
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className="chat-input">
        <div className="input-box-wrap">
          <input type="text" placeholder="Type message..." />
          <div className="attach-file cursor-pointer" onClick={handleIconClick}>
            <input type="file" style={{ display: "none" }} ref={inputRef} />
            <img src="/assets/images/attatchment-icon.png" alt="" />
          </div>
        </div>

        <button type="submit" className="send-btn">
          <img src="/assets/images/send-icon.png" alt="" />
        </button>
      </div>
    </div>
  );
};

export default ChatMessages;
