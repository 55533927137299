import React, { useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import "./HrAvatarMode.css";
import ChatMessages from "../../../components/ChatMessages/ChatMessages";
import MobileDrawer from "../../../components/MobileDrawer/MobileDrawer";


const chatData = [
  {
    sender: "Maxine HR Manager",
    text: `I will need some more information: <br/> 1. What are the names of the parties<br/>2. What date did the incident occur?<br/>3. Who will witness the document?`,
    attachments: [],
    avatar: "/assets/images/hr-manager-chat.png",
  },
  {
    sender: "John Jones (you)",
    text: "No worries. See attached the pdf with more information which might help.",
    attachments: [{ name: "Agreement.pdf", link: "#" }],
    avatar: "/assets/images/john-chat.png",
  },
  {
    sender: "Maxine HR Manager",
    text: "I have created a checklist for you.",
    attachments: [{ name: "Checklist.pdf", link: "#" }],
    avatar: "/assets/images/hr-manager-chat.png",
  },
];

export default function HrAvatarMode() {
  const [isAudioActive, setIsAudioActive] = useState(true);
  const [isCameraActive, setIsCameraActive] = useState(true);
  const [files, setFiles] = useState([]);
  const [showChatScreen, setShowChatScreen] = useState(false);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: { "application/pdf": [] },
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });
  const inputRef = useRef(null);

  const handleIconClick = () => {
    inputRef.current.click();
  };

  const handleAudioToggle = () => {
    setIsAudioActive((prevState) => !prevState);
  };

  const handleCameraToggle = () => {
    setIsCameraActive((prevState) => !prevState);
  };
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("Select training area");
  const [openDrawer, setOpenDrawer] = useState(false);
  const toggleDrawer = (newOpen: boolean) => () => {
    setOpenDrawer(newOpen);
  };
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const handleItemClick = (item) => {
    setSelectedItem(item);
    setIsOpen(false);
  };
  const drawerRadioOptions = [
    "Option one",
    "Option two",
    "Option three",
    "Option four",
    "Option five",
    "Option six",
    "Option seven",
    "Option eight",
  ];

  const renderfilesDropBar = () => {
    return (
      <div className="desktop-footer-wrap">
        <div className="drop-file-wrap">
          <div
            {...getRootProps()}
            style={{
              border: isDragActive ? "" : "2px dashed #ccc",
              textAlign: "center",
              cursor: "pointer",
              position: isDragActive ? "fixed" : "relative",
              top: isDragActive ? 0 : "auto",
              left: isDragActive ? 0 : "auto",
              width: isDragActive ? "100vw" : "auto",
              height: isDragActive ? "100vh" : "auto",
              backgroundColor: isDragActive ? "#241534E8" : "transparent",
              zIndex: isDragActive ? 1000 : "auto",
              display: isDragActive ? "flex" : "block",
            }}
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <p className="drop-file-title">
                <img src="/assets/images/drop-file-icon.png" alt=""/>
                Drop file here to upload</p>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "24px",
                }}
              >
                <img src="/assets/images/Arrow Upload.svg" alt="" />
                <div className="dropFileText">
                  Drop files here to help Maxine assist you
                </div>
              </div>
            )}
          </div>
        </div>
        <div
          className="chat-button-wrap cursor-pointer"
          onClick={() => setShowChatScreen(true)}
        >
          <img src="/assets/images/Chat.svg" alt="" />
          <div className="message-count">2</div>
        </div>
      </div>
    );
  };

  return (
    <div className="trainer-mode-wrapper hr-avatar-page-wrap">
      <div className="trainer-mode-header">
        <div className="header-wrap">
          <div className="header-left-wrap">
            <img
              src="/assets/images/mploi-logo-white.svg"
              alt="MPLOI Logo"
              className="logo"
            />
            <div className="title">
              Training in process{" "}
              <img src="/assets/images/waveform.png" alt="waveform Icon" />
            </div>
          </div>
          <div className="header-right-wrap">
            <button className="green-trainer-button">
              Practice Pitching{" "}
              <img src="/assets/images/person-voice-2.png" alt="Icon" />
            </button>
          </div>
        </div>
      </div>

      <div className="trainer-details">
        <div className="caller-details">
          <div>
            <span>Training for: </span> Pitching for a house sale
          </div>
          <div className="dot"> • </div>
          <div>
            <span>Training with:</span> Maxine, MPLOI Trainer
          </div>
        </div>
      </div>
      <div className="trainer-mode-wrap">
        <div className="trainer-mode-top-content">
          <div className="status">
            <img src="/assets/images/person-voice.png" alt="" /> Try saying,
            “good morning”
          </div>
          <button className="start-trainer-button">
            End Interview Early{" "}
            <img src="/assets/images/call-end-icon.png" alt="Icon" />
          </button>
        </div>
        <div className="trainer-mode-bottom-content">
          <div
            className={
              "left-img-wrap " +
              `${showChatScreen ? "left-content-resize" : ""}`
            }
          >
            <div className="top-title-mob">
              <img src="/assets/images/person-voice.png" alt="" /> Try saying,
              “good morning”
              <span>
                {" "}
                <img src="/assets/images/wave-color.png" alt="" />
              </span>
            </div>
            <img
              className="caller-img"
              src="/assets/images/caller-1.png"
              alt="Candidate"
            />
            <div className="top-img-mic-icon">
              <img src="/assets/images/audio-rang.png" alt="" />{" "}
            </div>
            <div className="bottom-icon-wrap">
              <div className="bottom-title">
                Candidate: <span>John Jones (you)</span>
              </div>
              <div className="feature-icon-wrap">
                <button onClick={handleAudioToggle} className="icon-button">
                  <img
                    src={
                      isAudioActive
                        ? "/assets/images/audio-call-icon.png"
                        : "/assets/images/audio-call-off-icon.png"
                    }
                    alt="Audio"
                    className="icon-img"
                  />
                </button>
                <button onClick={handleCameraToggle} className="icon-button">
                  <img
                    src={
                      isCameraActive
                        ? "/assets/images/video-call-icon.png"
                        : "/assets/images/vdo-call-off-icon.png"
                    }
                    alt="Camera"
                    className="icon-img"
                  />
                </button>
              </div>
            </div>
          </div>
          <div
            className={
              "right-img-wrap " + (showChatScreen ? "right-content-resize" : "")
            }
          >
            <div className="thinking-btn">
              <img src="/assets/images/thinking.png" alt="" />
              <span>Thinking..</span>
            </div>
            <div className="ready-btn">
              <img src="/assets/images/ready.png" alt="" />
              <span>Ready..</span>
            </div>
            <div className="disable-btn">
              <span>Disable</span>
            </div>
            <div className="enable-btn">
              <span>Enable</span>
            </div>
            <img
              className="caller-img"
              src="/assets/images/caller-2.png"
              alt="Candidate"
            />
            <div className="bottom-title">
              Assistant: <span>Maxine, MPLOI HR Assistant</span>
            </div>
          </div>
          {showChatScreen ? (
            <div className="desktop-chat">
              <ChatMessages
                chatData={chatData}
                closeChat={() => setShowChatScreen(false)}
              />
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="trainer-mode-bottom-wrap">
        <div className="attach-file cursor-pointer" onClick={handleIconClick}>
            <input type="file" style={{ display: "none" }} ref={inputRef} />
            <img src="/assets/images/gallery-icon-mob.png" alt=" " />
          </div>
        
        <button onClick={handleAudioToggle} className="icon-button">
                  <img
                    src={
                      isAudioActive
                        ? "/assets/images/audio-call-icon.png"
                        : "/assets/images/audio-call-off-icon.png"
                    }
                    alt="Audio"
                    className="icon-img"
                  />
                </button>
          <img src="/assets/images/call-cut.png" className="mid-icn" alt=" " />
          <button onClick={handleCameraToggle} className="icon-button">
                  <img
                    src={
                      isCameraActive
                        ? "/assets/images/video-call-icon.png"
                        : "/assets/images/vdo-call-off-icon.png"
                    }
                    alt="Camera"
                    className="icon-img"
                  />
                </button>
          <img src="/assets/images/chat-icon-mob.png"  onClick={() => setOpenDrawer(true)} alt=" " />
        </div>
        <div>{renderfilesDropBar()}</div>
      </div>



      <MobileDrawer
        open={openDrawer}
        toggleDrawer={toggleDrawer}
        customClass="mobile-chat-wrap"
        drawerData={
          <div className="chat-mob-screen"><ChatMessages
          chatData={chatData}
          closeChat={() => setShowChatScreen(false)}
        /></div>
        }
      />
    </div>
  );
}
