import React from 'react'
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import BlogDetailsPage from '../../components/BlogDetailsPage/BlogDetailsPage';

function BlogDetails() {
  return (
    <div>
        <Header />
        <BlogDetailsPage />
        <Footer />
    </div>
  )
}

export default BlogDetails