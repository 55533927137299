import { parsePhoneNumberFromString } from "libphonenumber-js";
import HmacSHA256 from "crypto-js/hmac-sha256";
import moment from 'moment';

export const UtilityFunctions = {
  getImageURL,
  validateEmail,
  validatePassword,
  validatePhone,
  validateOtp,
  isValidURL,
  nameFormatter,
  isEmptyOrNullOrUndefined,
  API_URL,
  checkSpaceInPassword,
  CHAT_URL,
  API_URL_V2,
  BASE_URL,
  SECRET_KEY,
  hasDataType,
  convertMonthsToYearsAndMonths,
};

function nameFormatter(name) {
  let fullName = name;
  let g = fullName.trim();
  g = g.replace(/  +/g, " ");
  const [first, ...rest] = g.split(" ");
  const last = rest.join(" ") || "";
  return [first, last];
}

function isValidURL(urlString) {
  var urlPattern = new RegExp(
    "^(https?:\\/\\/)?" + // validate protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
    "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // validate fragment locator
  return !!urlPattern.test(urlString);
}

function getImageURL(): string {
  return process.env.REACT_APP_LIVE_IMAGEURL;
}
function CHAT_URL() {
  return process.env.REACT_APP_LIVE_CHATURL;
}
function API_URL() {
  return process.env.REACT_APP_LIVE_APIURL;
}

function API_URL_V2() {
  return process.env.REACT_APP_LIVE_API_V2_URL;
}
function BASE_URL() {
  return process.env.REACT_APP_LIVE_BASE_URL;
}

function SECRET_KEY() {
  return process.env.REACT_APP_SECRET_KEY;
}

function validateEmail(value: any) {
  return /^[\w.%+-]+@[\w.-]+\.[A-Za-z]{2,}$/.test(value);
}
function validatePassword(value: any) {
  return /^(?=.*\d).{8,}$/.test(value);
}

function validatePhone(value: any) {
  return /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(value); //9999999999
}

function checkSpaceInPassword(str) {
  return /\s/.test(str);
}

function validateOtp(value: any) {
  if (value !== 6) {
    return true;
  } else {
    return false;
  }
}

function isEmptyOrNullOrUndefined(str: string) {
  str =
    str === undefined || str == null || str === "0"
      ? ""
      : str.toString().trim();
  if (str === "") return true;
  return false;
}

export const getCurrentYear = () => {
  return new Date().getFullYear();
};

/** Phone Utils */
export const getNumberFromMobileNumber = (mobileNum) => {
  if (!mobileNum) {
    return;
  }

  //this module is used to make country code separate from combined mobile number
  const parsedNumber = parsePhoneNumberFromString(mobileNum)
    ?.format("INTERNATIONAL")
    ?.split(" ");

  const isd = parsedNumber?.shift() || "<default country code>";

  return [isd, mobileNum.substring(isd.length)];
};

export const blobToFile = (theBlob: Blob, name = "filename.png"): File => {
  return new File([theBlob], name, {
    lastModified: new Date().getTime(),
    type: theBlob.type,
  });
};

export const dataURLtoFile = (dataurl, filename) => {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

export const firebaseConfig = {
  apiKey: "AIzaSyCupxYdRcG0K5D4INip3X4lBqm03S2dFl4",
  authDomain: "mploi-76ad8.firebaseapp.com",
  projectId: "mploi-76ad8",
  storageBucket: "mploi-76ad8.appspot.com",
  messagingSenderId: "869243866361",
  appId: "1:869243866361:web:b28825df31202319936628",
};

export const salaryCheckoutType = (type: string) => {
  const g = salaryType.filter((item) => {
    if (item.value == type) return item;
  });
  if (g.length > 0) {
    return g[0].abbr;
  } else return "";
};

export const salaryType = [
  { value: "hourly", status: false, showValue: "Hourly", abbr: "hr" },
  { value: "daily", status: false, showValue: "Daily", abbr: "dy" },
  { value: "weekly", status: false, showValue: "Weekly", abbr: "wk" },
  { value: "monthly", status: true, showValue: "Monthly", abbr: "mo" },
  { value: "yearly", status: false, showValue: "Yearly", abbr: "yr" },
];

export const jobType = [
  { value: "fullTime", status: true, showValue: "Full Time" },
  { value: "partTime", status: false, showValue: "Part Time" },
  { value: "casual", status: false, showValue: "Casual" },
];

export const getJobType = (type: string) => {
  const g = jobType.filter((item) => {
    if (item.value == type) return item;
  });
  if (g.length > 0) {
    return g[0].showValue;
  } else return "";
};

export const modifiedSalaray = (min, max, jobType, salaryType) => {
  // console.log('fghjgfdfghjgfg', min, max, jobType, salaryType);
  min = min?.toString();
  max = max?.toString();

  return min !== max && max != 0
    ? `${getJobType(jobType)} ${min < 1000
      ? `$${min
        ?.replace(/\.0+$/, "")
        ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
      : `$${(min / 1000)
        ?.toFixed(2)
        ?.replace(/\.0+$/, "")
        ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
    }${min < 1000 ? `` : `k`}-${max < 1000
      ? `$${max
        ?.replace(/\.0+$/, "")
        ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
      : `$${(max / 1000)
        ?.toFixed(2)
        ?.replace(/\.0+$/, "")
        ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
    }${min < 1000 ? `` : `k`}${salaryCheckoutType(salaryType) ? "/" : ""
    }${salaryCheckoutType(salaryType)}`
    : `${getJobType(jobType)} ${min < 1000
      ? `$${min
        ?.replace(/\.0+$/, "")
        ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
      : `$${(min / 1000)
        //  .toString()
        ?.toFixed(2)
        ?.replace(/\.0+$/, "")
        //?.replace(/\D/g, '')
        ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
    }${min < 1000 ? `` : `k`}${salaryCheckoutType(salaryType) ? "/" : ""
    }${salaryCheckoutType(salaryType)}`;
  // } else {
  //   return 'ghjk';
  // }
};

export const timeConvrter = (experience) => {
  const exp = experience * 10;
  const year = exp / 12;
  const month = exp % 12;

  return {
    year: Math.floor(year),
    month: Math.floor(month),
  };
};
//HMAC functions

export const setHmacHeaders = (config, hash) => {
  return new Promise((res, rej) => {
    let constants = getHmacConstants(config);
    config.headers["TIMESTAMP"] = `${constants.TimeStamp}`;
    config.headers["API-KEY"] = `${constants.API_VERSIONS}=${hash}`;
    res("header set");
  });
};
export const generateHmacKey = (config) => {
  return new Promise((res, rej) => {
    let hash = HmacSHA256(
      getHmacMessage(config),
      getHmacSecretKey()
    ).toString();
    res(hash);
  });
};
export const getHmacConstants = (config) => {
  console.log(config);
  console.log(UtilityFunctions.BASE_URL());
  let apiVersion = "678";
  //config?.baseURL?.split(UtilityFunctions.BASE_URL())?.[1];
  // config?.baseURL?.split(UtilityFunctions.BASE_URL())?.[1];
  let API_VERSIONS = "/" + apiVersion;
  let TimeStamp = Date?.now();
  // let configURL = config?.url;
  let path = API_VERSIONS;
  // const url = new URL(configURL);
  // let path = url.pathname;
  console.log(apiVersion, API_VERSIONS, TimeStamp, path);
  return { API_VERSIONS, TimeStamp, path };
};

export const getHmacMessage = (config) => {
  let constants = getHmacConstants(config);
  console.log(
    `${constants.API_VERSIONS}:${constants.TimeStamp}:path=${constants.path}`
  );
  return `${constants.API_VERSIONS}:${constants.TimeStamp}:path=${constants.path}`;
};
export const getHmacSecretKey = () => {
  return UtilityFunctions.SECRET_KEY() || "";
};

//CHECKS IF A ARRAY HAS A SPECIFIC DATA TYPE
export function hasDataType(arr, dataType) {
  return arr.some((item) => typeof item === dataType);
}

//CONVERTS NUMBER OF MONTHS TO YEARS AND MONTHS
function convertMonthsToYearsAndMonths(month) {
  let store = ""
  if (month > 0) {
    const duration = moment.duration(month, 'months');
    const years = duration.years();
    const months = duration.months();
    store = (years ? years + " Yr " : "") + (months ? months + " Mo " : "")
  }
  else {
    store = " 0 Mo "
  }

  return store;
}

export const isOnlySpaces = (text: string) => {
  if (!text.replace(/\s/g, '').length) {
    return true;
  } else return false;
};

export const checkFeatureFlag = (userCookieData, flagName) => {
  return true;
  
  if (!userCookieData) return false;
  if (!userCookieData.user) return false;
  if (!userCookieData.user.email) return false;

  const email = userCookieData.user.email;
  const validFlags = ['interview-simulator', 'chatbot', 'jobcreation', 'courses'];

  if (!validFlags.includes(flagName)) return false;

  const emailPatterns = ['mploi.com', 'simtheory.ai', 'designmotion.com.au', 'gmail.com'];

  // Check if the email ends with any of the patterns
  for (const pattern of emailPatterns) {
    if (email.endsWith(pattern)) {
      return true;
    }
  }
  
  return false;
};
