import React from "react";
import { Button, Modal } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { UtilityFunctions } from "../utils/UtilityFunctions";

const DeleteExperience = (props) => {
  const { showPopup, handleClosePopup, deleteExperienceHandler, experienceToDelete } = props

  return (
    <Modal
      className="bg-transparent logout-popup height-auto previous-qualifications"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={showPopup}
      onHide={() => {
        handleClosePopup()
      }}
    >
      <Modal.Body>
        <div style={{ maxWidth: "400px", margin: "0 auto" }}>
          {/* <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="25" height="25" rx="12.5" stroke="white" />
            <path d="M11.3125 11.875V16.375C11.3125 16.6857 11.5643 16.9375 11.875 16.9375C12.1857 16.9375 12.4375 16.6857 12.4375 16.375L12.4375 11.875C12.4375 11.5643 12.1857 11.3125 11.875 11.3125C11.5643 11.3125 11.3125 11.5643 11.3125 11.875ZM14.125 11.3125C14.4357 11.3125 14.6875 11.5643 14.6875 11.875V16.375C14.6875 16.6857 14.4357 16.9375 14.125 16.9375C13.8143 16.9375 13.5625 16.6857 13.5625 16.375V11.875C13.5625 11.5643 13.8143 11.3125 14.125 11.3125ZM15.25 8.5H18.625C18.9357 8.5 19.1875 8.75184 19.1875 9.0625C19.1875 9.37316 18.9357 9.625 18.625 9.625H18.0035L17.1564 17.2481C16.9982 18.6724 15.7943 19.75 14.3611 19.75H11.6388C10.2057 19.75 9.0018 18.6724 8.84354 17.2481L7.99653 9.625H7.375C7.06434 9.625 6.8125 9.37316 6.8125 9.0625C6.8125 8.75184 7.06434 8.5 7.375 8.5H10.75C10.75 7.25736 11.7574 6.25 13 6.25C14.2426 6.25 15.25 7.25736 15.25 8.5ZM13 7.375C12.3787 7.375 11.875 7.87868 11.875 8.5H14.125C14.125 7.87868 13.6213 7.375 13 7.375ZM9.12845 9.625L9.96166 17.1239C10.0566 17.9785 10.779 18.625 11.6388 18.625H14.3611C15.221 18.625 15.9434 17.9785 16.0383 17.1239L16.8715 9.625H9.12845Z" fill="white" />
          </svg> */}
          <img src={UtilityFunctions.getImageURL() + "delete-icon-white.svg"} alt="" />

          <h3 className="py-2">
            Delete Previous Employer
            {/* {STRING_CONSTANTS.formLabelString.label_delete_account} */}
          </h3>
          <p className="py-3">
            Once you delete a previous employer all data associated with it will be removed from your employment history & profile.
            {/* {STRING_CONSTANTS.modal_text.delete_text} */}
          </p>
          <div className="d-flex gap-4 align-items-center justify-content-between">
            <Button className='border-0 bg-transparent cancel-button text-white' variant="primary" onClick={() => {
              handleClosePopup()
            }}>
              Cancel
            </Button>
            <Button
              className="border-0 px-5"
              variant="primary"
              onClick={() => deleteExperienceHandler(experienceToDelete)}
              style={{ color: "#EB5757" }}
            >
              Delete
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}
export default withRouter(DeleteExperience);