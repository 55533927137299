import React from "react";
import { UtilityFunctions } from "../../src/utils/UtilityFunctions";
import { Button, Modal } from "react-bootstrap";
import { withRouter } from "react-router-dom";

const VerifyUser = (props) => {

  return (
    <Modal
      className="bg-transparent upgrade-popup logout-popup"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={props.show}
      onHide={props.handleClose}
    >
      <Modal.Body>
        <img
          alt="logout"
          src={UtilityFunctions.getImageURL() + "modal_question.svg"}
        />
        <h3 className="py-2">Verification Pending!</h3>
        <p className="py-3">
          Your account is currently being reviewed by the MPLOI team.
        </p>
        <div>
          <Button
            className="ms-4 border-0"
            variant="primary"
            onClick={props.handleClose}
          >
            Ok, thanks
          </Button>
        </div>
      </Modal.Body>
    </Modal>

  )
}
export default withRouter(VerifyUser);