import React, { useEffect } from "react";
import { cookieService } from "../_services";
// import io from 'socket.io-client';
import { STRING_CONSTANTS } from "../utils/constants/stringConstants";
import AppChat2 from "./AppChat2";
import { useDispatch, useSelector } from "react-redux";
import { actionCreators, State } from "../store";

import { bindActionCreators } from "redux";


export default function SocketClient() {
  const state = useSelector((state: State) => state);
  const dispatch = useDispatch();
  const {
    addOnlineUser,
    removeOnlineUser,
    updateAllOnlineUser,
    updateCurrentUserLatestMessage,
  } = bindActionCreators(actionCreators, dispatch);
  // console.log(state)
  // useEffect(() => {
  const userTypeCookieval = cookieService.getCookie(
    STRING_CONSTANTS.cookieStrings.user
  );
  //     var userId = userTypeCookieval?.user?.id;
  //     const socketurl = process.env.REACT_APP_SOCKETURL + `${"userId=" + userId}`;
  //     const isUserLoggedIn = cookieService.getCookie(STRING_CONSTANTS.cookieStrings.token)

  //     let socket = socketIOClient(socketurl);
  //     socket.on("connect", () => {
  //         console.log("true", socket.connected); // true
  //     });

  //     socket.on("disconnect", () => {
  //         console.log("false", socket.connected); // false
  //     });

  //     // send data
  //     // socket.emit('message', 'hello world');

  // })

  return (
    <AppChat2
      path={"app-connect"}
      params={{ userId: userTypeCookieval?.user?.id }}
      onNewMessage={(data: any) => {
        // console.log("jhy765rthjk", data?.type, JSON.stringify(data));
        if (data?.type == "online_report") {
          addOnlineUser(data?.users);
          // Alert.alert('online_report');
          // dispatch(
          //     actionCreators.updateOnlineUserList({
          //         data: data?.users || [],
          //         all: false,
          //     }),
          // );
        }
        if (data?.type == "online_report_echo") {
          updateAllOnlineUser(data?.users);
        }
        if (data?.type == "offline_report") {
          console.log(
            "ki876rfghjkluytfghjki765rfg",
            JSON.stringify(data?.users)
          );
          removeOnlineUser(data?.users);
          //Alert.alert('offline_report');

          // dispatch(
          //     actionCreators.updateOfflineUserList({
          //         data: data?.users || [],
          //         all: false,
          //     }),
          // );
        }
        if (data?.type == "latest_message_echo") {
          // updateAllOnlineUser(data?.users)
          //Alert.alert('offline_report');
          updateCurrentUserLatestMessage(data?.room);
        }
        //Alert.alert(JSON.stringify(data));
      }}
      onSendingMessage={(data: any) => {}}
      onMessageSent={() => {}}
      // onSocketError={(e: any) => {
      //     console.log('socleterror567', e);
      //     setServerState(NETWORK_CONNECTED.DISCONNECTED);
      // }}
      ref={null}
      // onConnectionOpen={() => {
      //     console.log('fghjkjgfghjhgf');
      //     setServerState(NETWORK_CONNECTED.CONNECTED);
      // }}
      // onConnectionClose={() => {
      //     console.log('fghjkjgfghjhgfclose');
      //     setServerState(NETWORK_CONNECTED.DISCONNECTED);
      // }}
    />
  );
}
