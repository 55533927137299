import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import ImageCrop from "../../common/ui/ImageCrop";
import ValidationErrorToast from "../../components/validationErrorToast";
import { api } from "../../middleware/api";
import { STRING_CONSTANTS } from "../../utils/constants/stringConstants";
import { UtilityFunctions } from "../../utils/UtilityFunctions";
import { userService } from "../../_services";

const BusinessDetails = (props: any) => {
    const [savedData, setSavedData] = useState<any>(null);
    const businessRef = useRef<any>(null);
    const tradingRef = useRef<any>(null);
    const employeeRef = useRef<any>(null);
    const [selectedFile, setSelectedFile] = useState();
    const [preview, setPreview] = useState(null);
    const [btnClick, setBtnClick] = useState(false);
    const [counter, setCounter] = useState(0);
    let userData;

    useEffect(() => {
        userService.getUserData()
            .then((res) => {
                setSavedData(res);
                setPreview(res.logo)
                setSelectedFile(null);
            })
    }, [])

    const onSubmitHandler = (e) => {
        e.preventDefault();
        setCounter(prev => prev + 1)
        userData = {
            businessName: businessRef?.current?.value.trim(),
            tradingName: tradingRef?.current?.value.trim(),
            totalEmployees: employeeRef?.current?.value
        };
        if (!UtilityFunctions.isEmptyOrNullOrUndefined(userData.businessName) &&
            !UtilityFunctions.isEmptyOrNullOrUndefined(userData.tradingName) &&
            !UtilityFunctions.isEmptyOrNullOrUndefined(userData.totalEmployees) &&
            userData.businessName.replace(/ /g, '').length > 0 &&
            userData.tradingName.replace(/ /g, '').length > 0 &&
            userData.totalEmployees > 0) {
            // console.log("Submission Starts")
            let formData = new FormData();
            if (selectedFile && preview) {
                formData.append('logo', selectedFile)
            }
            else if (!selectedFile && !preview) {
                formData.append('logo', '')
            }
            formData.append('totalEmployees', savedData.totalEmployees)
            formData.append('businessName', savedData.businessName)
            formData.append('tradingName', savedData.tradingName)

            userService.userProfile(formData)
                .then((res: any) => {
                    // console.log(res.data);
                    props?.nav?.next()

                })
                .catch((e) => {
                    ValidationErrorToast(e[0], e[1])
                })
            // console.log("Submission Ends")
        }
        else {
            setBtnClick(true);
            ValidationErrorToast("Error!", "One or more field(s) is empty or invalid")
        }
    }

    // Replaces key values with updated values from input
    const onChangeHandler = (arg, value) => {
        setSavedData((prevState) => {
            return { ...prevState, [arg]: value }
        })
    }

    // For handling image selection and deletion 
    const imageHandler = {
        removeFile: () => {
            setSelectedFile(null)
            setPreview(null)
        },
    }
    return <>
        <div className='top-divde-spa'>
            <span>
                <i
                    className="fa fa-angle-left text-white d-flex justify-content-between"
                    aria-hidden="true"
                    onClick={() => {
                        props?.nav?.back()
                    }}
                ></i>
            </span>
            <div className="step-content signup-mar">
                <button type='button' disabled>
                    {STRING_CONSTANTS.employerProfileCompletionPages.business_details.business_details}
                </button>
                <p>{STRING_CONSTANTS.employerProfileCompletionPages.business_details.enter_business_details}</p>
                <div className="step-text">
                    {STRING_CONSTANTS.employerProfileCompletionPages.business_details.step}
                </div>
            </div>
        </div>
        <form>
            <div className="form-wrap business-detailss">
                <div className='v-form-width'>
                    <div className="update-image">
                        <div className='upload-image '>
                            <div className='file-thumb' >
                                {(selectedFile || preview) ?
                                    <div className="crossed-icon">
                                        <img alt="Selected Image" className="image-show" src={UtilityFunctions.getImageURL() + "cross-icon.svg"} onClick={(e) => imageHandler.removeFile()} />
                                        <img alt="Preview" className="image-show" src={preview} />
                                    </div> :
                                    <div>
                                        <ImageCrop
                                            ratio={1 / 1}
                                            croppedImage={(imgSrc, file) => {
                                                setSelectedFile(file)
                                                setPreview(imgSrc);
                                            }}
                                            size={25}
                                        />
                                        <label htmlFor="image_id">
                                            {/* <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9.82869 0.206923L9.94531 0.200195C10.4581 0.200195 10.8808 0.586236 10.9386 1.08357L10.9453 1.2002V8.2002H17.9453C18.4581 8.2002 18.8808 8.58624 18.9386 9.08357L18.9453 9.2002C18.9453 9.71303 18.5593 10.1357 18.0619 10.1935L17.9453 10.2002H10.9453V17.2002C10.9453 17.713 10.5593 18.1357 10.0619 18.1935L9.94531 18.2002C9.43248 18.2002 9.00981 17.8142 8.95204 17.3168L8.94531 17.2002V10.2002H1.94531C1.43248 10.2002 1.00981 9.81415 0.95204 9.31682L0.945312 9.2002C0.945312 8.68736 1.33135 8.26469 1.82869 8.20692L1.94531 8.2002H8.94531V1.2002C8.94531 0.687359 9.33135 0.264688 9.82869 0.206923L9.94531 0.200195L9.82869 0.206923Z" fill="#38BBF4"></path>
                                            </svg> */}
                                            <img src={UtilityFunctions.getImageURL() + "add_img.svg"} className="plus-icon" alt="" />
                                            <p>Upload <br /> Image</p>
                                        </label></div>}
                            </div>
                            <ImageCrop
                                ratio={16 / 9}
                                croppedImage={(imgSrc, file) => {
                                    setSelectedFile(file)
                                    setPreview(imgSrc);
                                }}
                                size={25}
                            />
                        </div>
                    </div>
                    <div className="form-floating mb-3">
                        <input type='text'
                            className={`form-control ${btnClick && UtilityFunctions.isEmptyOrNullOrUndefined(businessRef.current.value) ? 'error' : ""}`}
                            ref={businessRef}
                            defaultValue={savedData?.businessName}
                            onChange={(e) => {
                                onChangeHandler('businessName', e.target.value);
                                setBtnClick(false)
                            }} />
                        <label htmlFor="floatingInput">Enter Business Name</label>
                        <span className="input-group-text">
                            <img src={UtilityFunctions.getImageURL() + "building-ico-form.svg"} alt="" />
                            <img src={UtilityFunctions.getImageURL() + "building-input-fill.svg"} alt="" />
                            {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.5">
                                    <path d="M8.5 5.5C7.94772 5.5 7.5 5.94772 7.5 6.5C7.5 7.05228 7.94772 7.5 8.5 7.5C9.05229 7.5 9.5 7.05228 9.5 6.5C9.5 5.94772 9.05229 5.5 8.5 5.5ZM7.5 13.5C7.5 12.9477 7.94772 12.5 8.5 12.5C9.05229 12.5 9.5 12.9477 9.5 13.5C9.5 14.0523 9.05229 14.5 8.5 14.5C7.94772 14.5 7.5 14.0523 7.5 13.5ZM8.5 9C7.94772 9 7.5 9.44771 7.5 10C7.5 10.5523 7.94772 11 8.5 11C9.05229 11 9.5 10.5523 9.5 10C9.5 9.44771 9.05229 9 8.5 9ZM11 6.5C11 5.94772 11.4477 5.5 12 5.5C12.5523 5.5 13 5.94772 13 6.5C13 7.05228 12.5523 7.5 12 7.5C11.4477 7.5 11 7.05228 11 6.5ZM12 12.5C11.4477 12.5 11 12.9477 11 13.5C11 14.0523 11.4477 14.5 12 14.5C12.5523 14.5 13 14.0523 13 13.5C13 12.9477 12.5523 12.5 12 12.5ZM14.5 13.5C14.5 12.9477 14.9477 12.5 15.5 12.5C16.0523 12.5 16.5 12.9477 16.5 13.5C16.5 14.0523 16.0523 14.5 15.5 14.5C14.9477 14.5 14.5 14.0523 14.5 13.5ZM12 9C11.4477 9 11 9.44771 11 10C11 10.5523 11.4477 11 12 11C12.5523 11 13 10.5523 13 10C13 9.44771 12.5523 9 12 9ZM6.25 2C5.00736 2 4 3.00736 4 4.25V20.75C4 21.1642 4.33579 21.5 4.75 21.5H19.2528C19.667 21.5 20.0028 21.1642 20.0028 20.75V11.7493C20.0028 10.5067 18.9954 9.4993 17.7528 9.4993H16.5V4.25C16.5 3.00736 15.4926 2 14.25 2H6.25ZM5.5 4.25C5.5 3.83579 5.83579 3.5 6.25 3.5H14.25C14.6642 3.5 15 3.83579 15 4.25V10.2493C15 10.6635 15.3358 10.9993 15.75 10.9993H17.7528C18.167 10.9993 18.5028 11.3351 18.5028 11.7493V20H16.5V17.25C16.5 16.8358 16.1642 16.5 15.75 16.5H8.25C7.83579 16.5 7.5 16.8358 7.5 17.25V20H5.5V4.25ZM15 18V20H12.75V18H15ZM11.25 18V20H9V18H11.25Z" fill="white" />
                                </g>
                            </svg> */}
                            {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.75 2C5.50736 2 4.5 3.00736 4.5 4.25V20.75C4.5 21.1642 4.83579 21.5 5.25 21.5H7.5V17.25C7.5 16.8358 7.83579 16.5 8.25 16.5H15.75C16.1642 16.5 16.5 16.8358 16.5 17.25V21.5H18.75C19.1642 21.5 19.5 21.1642 19.5 20.75V11.7493C19.5 10.5067 18.4926 9.4993 17.25 9.4993H16.5V4.25C16.5 3.00736 15.4926 2 14.25 2H6.75ZM7.5 6.5C7.5 5.94772 7.94772 5.5 8.5 5.5C9.05228 5.5 9.5 5.94772 9.5 6.5C9.5 7.05228 9.05228 7.5 8.5 7.5C7.94772 7.5 7.5 7.05228 7.5 6.5ZM8.5 12.5C9.05228 12.5 9.5 12.9477 9.5 13.5C9.5 14.0523 9.05228 14.5 8.5 14.5C7.94772 14.5 7.5 14.0523 7.5 13.5C7.5 12.9477 7.94772 12.5 8.5 12.5ZM7.5 10C7.5 9.44772 7.94772 9 8.5 9C9.05228 9 9.5 9.44772 9.5 10C9.5 10.5523 9.05228 11 8.5 11C7.94772 11 7.5 10.5523 7.5 10ZM12 5.5C12.5523 5.5 13 5.94772 13 6.5C13 7.05228 12.5523 7.5 12 7.5C11.4477 7.5 11 7.05228 11 6.5C11 5.94772 11.4477 5.5 12 5.5ZM11 13.5C11 12.9477 11.4477 12.5 12 12.5C12.5523 12.5 13 12.9477 13 13.5C13 14.0523 12.5523 14.5 12 14.5C11.4477 14.5 11 14.0523 11 13.5ZM15.5 12.5C16.0523 12.5 16.5 12.9477 16.5 13.5C16.5 14.0523 16.0523 14.5 15.5 14.5C14.9477 14.5 14.5 14.0523 14.5 13.5C14.5 12.9477 14.9477 12.5 15.5 12.5ZM11 10C11 9.44772 11.4477 9 12 9C12.5523 9 13 9.44772 13 10C13 10.5523 12.5523 11 12 11C11.4477 11 11 10.5523 11 10ZM15 21.5V18H12.7499V21.5H15ZM11.2499 21.5V18H9V21.5H11.2499Z" fill="#38BBF4" />
                            </svg> */}
                        </span>
                    </div>
                    <div className="form-floating mb-3">
                        <input type='text'
                            className={`form-control ${btnClick && UtilityFunctions.isEmptyOrNullOrUndefined(tradingRef.current.value) ? 'error' : ""}`}
                            ref={tradingRef}
                            defaultValue={savedData?.tradingName}
                            onChange={(e) => {
                                onChangeHandler('tradingName', e.target.value);
                                setBtnClick(false);
                            }} />
                        <label htmlFor="floatingInput">Enter Business Trading Name</label>
                        <span className="input-group-text">
                            <img src={UtilityFunctions.getImageURL() + "building-ico-form.svg"} alt="" />
                            <img src={UtilityFunctions.getImageURL() + "building-input-fill.svg"} alt="" />
                            {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.5">
                                    <path d="M8.5 5.5C7.94772 5.5 7.5 5.94772 7.5 6.5C7.5 7.05228 7.94772 7.5 8.5 7.5C9.05229 7.5 9.5 7.05228 9.5 6.5C9.5 5.94772 9.05229 5.5 8.5 5.5ZM7.5 13.5C7.5 12.9477 7.94772 12.5 8.5 12.5C9.05229 12.5 9.5 12.9477 9.5 13.5C9.5 14.0523 9.05229 14.5 8.5 14.5C7.94772 14.5 7.5 14.0523 7.5 13.5ZM8.5 9C7.94772 9 7.5 9.44771 7.5 10C7.5 10.5523 7.94772 11 8.5 11C9.05229 11 9.5 10.5523 9.5 10C9.5 9.44771 9.05229 9 8.5 9ZM11 6.5C11 5.94772 11.4477 5.5 12 5.5C12.5523 5.5 13 5.94772 13 6.5C13 7.05228 12.5523 7.5 12 7.5C11.4477 7.5 11 7.05228 11 6.5ZM12 12.5C11.4477 12.5 11 12.9477 11 13.5C11 14.0523 11.4477 14.5 12 14.5C12.5523 14.5 13 14.0523 13 13.5C13 12.9477 12.5523 12.5 12 12.5ZM14.5 13.5C14.5 12.9477 14.9477 12.5 15.5 12.5C16.0523 12.5 16.5 12.9477 16.5 13.5C16.5 14.0523 16.0523 14.5 15.5 14.5C14.9477 14.5 14.5 14.0523 14.5 13.5ZM12 9C11.4477 9 11 9.44771 11 10C11 10.5523 11.4477 11 12 11C12.5523 11 13 10.5523 13 10C13 9.44771 12.5523 9 12 9ZM6.25 2C5.00736 2 4 3.00736 4 4.25V20.75C4 21.1642 4.33579 21.5 4.75 21.5H19.2528C19.667 21.5 20.0028 21.1642 20.0028 20.75V11.7493C20.0028 10.5067 18.9954 9.4993 17.7528 9.4993H16.5V4.25C16.5 3.00736 15.4926 2 14.25 2H6.25ZM5.5 4.25C5.5 3.83579 5.83579 3.5 6.25 3.5H14.25C14.6642 3.5 15 3.83579 15 4.25V10.2493C15 10.6635 15.3358 10.9993 15.75 10.9993H17.7528C18.167 10.9993 18.5028 11.3351 18.5028 11.7493V20H16.5V17.25C16.5 16.8358 16.1642 16.5 15.75 16.5H8.25C7.83579 16.5 7.5 16.8358 7.5 17.25V20H5.5V4.25ZM15 18V20H12.75V18H15ZM11.25 18V20H9V18H11.25Z" fill="white" />
                                </g>
                            </svg> */}
                            {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.75 2C5.50736 2 4.5 3.00736 4.5 4.25V20.75C4.5 21.1642 4.83579 21.5 5.25 21.5H7.5V17.25C7.5 16.8358 7.83579 16.5 8.25 16.5H15.75C16.1642 16.5 16.5 16.8358 16.5 17.25V21.5H18.75C19.1642 21.5 19.5 21.1642 19.5 20.75V11.7493C19.5 10.5067 18.4926 9.4993 17.25 9.4993H16.5V4.25C16.5 3.00736 15.4926 2 14.25 2H6.75ZM7.5 6.5C7.5 5.94772 7.94772 5.5 8.5 5.5C9.05228 5.5 9.5 5.94772 9.5 6.5C9.5 7.05228 9.05228 7.5 8.5 7.5C7.94772 7.5 7.5 7.05228 7.5 6.5ZM8.5 12.5C9.05228 12.5 9.5 12.9477 9.5 13.5C9.5 14.0523 9.05228 14.5 8.5 14.5C7.94772 14.5 7.5 14.0523 7.5 13.5C7.5 12.9477 7.94772 12.5 8.5 12.5ZM7.5 10C7.5 9.44772 7.94772 9 8.5 9C9.05228 9 9.5 9.44772 9.5 10C9.5 10.5523 9.05228 11 8.5 11C7.94772 11 7.5 10.5523 7.5 10ZM12 5.5C12.5523 5.5 13 5.94772 13 6.5C13 7.05228 12.5523 7.5 12 7.5C11.4477 7.5 11 7.05228 11 6.5C11 5.94772 11.4477 5.5 12 5.5ZM11 13.5C11 12.9477 11.4477 12.5 12 12.5C12.5523 12.5 13 12.9477 13 13.5C13 14.0523 12.5523 14.5 12 14.5C11.4477 14.5 11 14.0523 11 13.5ZM15.5 12.5C16.0523 12.5 16.5 12.9477 16.5 13.5C16.5 14.0523 16.0523 14.5 15.5 14.5C14.9477 14.5 14.5 14.0523 14.5 13.5C14.5 12.9477 14.9477 12.5 15.5 12.5ZM11 10C11 9.44772 11.4477 9 12 9C12.5523 9 13 9.44772 13 10C13 10.5523 12.5523 11 12 11C11.4477 11 11 10.5523 11 10ZM15 21.5V18H12.7499V21.5H15ZM11.2499 21.5V18H9V21.5H11.2499Z" fill="#38BBF4" />
                            </svg> */}
                        </span>
                    </div>
                    <div className="form-floating mb-3">

                        <input type='number' min={0}
                            className={`form-control ${btnClick && (UtilityFunctions.isEmptyOrNullOrUndefined(employeeRef.current.value) || employeeRef.current.value < 0) ? 'error' : ""}`}
                            ref={employeeRef}
                            defaultValue={savedData?.totalEmployees}
                            onChange={(e) => {
                                onChangeHandler('totalEmployees', e.target.value)
                                setBtnClick(false);
                            }} />
                        <span id="inc-button" className="spinner-button">
                            <img src={UtilityFunctions.getImageURL() + "input-number-arrow-up.svg"} className="d-block" alt="" />
                            {/* <svg width="6" height="4" viewBox="0 0 6 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.0761497 3.38268C0.230931 3.75636 0.595567 4 1.00003 4H5.00003C5.40449 4 5.76913 3.75636 5.92391 3.38268C6.07869 3.00901 5.99313 2.57889 5.70714 2.29289L3.70714 0.292893C3.31661 -0.097631 2.68345 -0.097631 2.29292 0.292893L0.292922 2.29289C0.00692445 2.57889 -0.0786313 3.00901 0.0761497 3.38268Z" fill="white" />
                            </svg> */}
                        </span>
                        <span id="dec-button" className="spinner-button">
                            <img src={UtilityFunctions.getImageURL() + "input-number-arrow-down.svg"} className="d-block" alt="" />
                            {/* <svg width="6" height="4" viewBox="0 0 6 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.92385 0.617317C5.76907 0.243642 5.40443 -5.20661e-08 4.99997 -8.74253e-08L0.999971 -4.37116e-07C0.595509 -4.72476e-07 0.230872 0.243642 0.0760916 0.617316C-0.0786893 0.990991 0.0068662 1.42111 0.292865 1.70711L2.29286 3.70711C2.68339 4.09763 3.31655 4.09763 3.70708 3.70711L5.70708 1.70711C5.99308 1.42111 6.07863 0.990991 5.92385 0.617317Z" fill="white" />
                            </svg> */}
                        </span>
                        <label htmlFor="floatingInput">Enter Number of Employees</label>
                        <span className="input-group-text">
                            <img src={UtilityFunctions.getImageURL() + "input-user-blank.svg"} alt="" />
                            <img src={UtilityFunctions.getImageURL() + "input-user-fill-blue.svg"} alt="" />
                            {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.5">
                                    <path d="M17.7545 13.9997C18.9966 13.9997 20.0034 15.0065 20.0034 16.2486V16.824C20.0034 17.7183 19.6838 18.5831 19.1023 19.2625C17.5329 21.096 15.1457 22.0008 12.0004 22.0008C8.8545 22.0008 6.46849 21.0957 4.90219 19.2614C4.32242 18.5825 4.00391 17.719 4.00391 16.8262V16.2486C4.00391 15.0065 5.01076 13.9997 6.25278 13.9997H17.7545ZM17.7545 15.4997H6.25278C5.83919 15.4997 5.50391 15.835 5.50391 16.2486V16.8262C5.50391 17.3619 5.69502 17.88 6.04287 18.2874C7.29618 19.755 9.26206 20.5008 12.0004 20.5008C14.7387 20.5008 16.7063 19.755 17.9627 18.2871C18.3117 17.8795 18.5034 17.3605 18.5034 16.824V16.2486C18.5034 15.835 18.1681 15.4997 17.7545 15.4997ZM12.0004 2.00439C14.7618 2.00439 17.0004 4.24297 17.0004 7.00439C17.0004 9.76582 14.7618 12.0044 12.0004 12.0044C9.23894 12.0044 7.00036 9.76582 7.00036 7.00439C7.00036 4.24297 9.23894 2.00439 12.0004 2.00439ZM12.0004 3.50439C10.0674 3.50439 8.50036 5.0714 8.50036 7.00439C8.50036 8.93739 10.0674 10.5044 12.0004 10.5044C13.9334 10.5044 15.5004 8.93739 15.5004 7.00439C15.5004 5.0714 13.9334 3.50439 12.0004 3.50439Z" fill="white" />
                                </g>
                            </svg> */}
                            {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.7543 13.9999C18.9963 13.9999 20.0032 15.0068 20.0032 16.2488V17.1673C20.0032 17.7406 19.8239 18.2997 19.4906 18.7662C17.9447 20.9294 15.4204 22.0011 12.0001 22.0011C8.57915 22.0011 6.05619 20.9289 4.51403 18.7646C4.18207 18.2987 4.00366 17.7409 4.00366 17.1688V16.2488C4.00366 15.0068 5.01052 13.9999 6.25254 13.9999H17.7543ZM12.0001 2.00464C14.7615 2.00464 17.0001 4.24321 17.0001 7.00464C17.0001 9.76606 14.7615 12.0046 12.0001 12.0046C9.2387 12.0046 7.00012 9.76606 7.00012 7.00464C7.00012 4.24321 9.2387 2.00464 12.0001 2.00464Z" fill="#38BBF4" />
                            </svg> */}
                        </span>
                    </div>
                </div>
            </div>

        </form>
        <div className="next-wrap">
            <button className={(savedData?.businessName && savedData?.tradingName && (savedData?.totalEmployees > 0)) ? 'cricle-next' : 'cricle-next-inactive'} onClick={(e) => onSubmitHandler(e)}>
                <img
                    alt=""
                    src={UtilityFunctions.getImageURL() + "arrow-bottom.svg"}
                />
            </button>
        </div>
    </>
}


export default withRouter(BusinessDetails);
