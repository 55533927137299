import React, { useState } from "react";
import './TrainerMode.css'; 

function TrainerMode() {
    const [isAudioActive, setIsAudioActive] = useState(true);
    const [isCameraActive, setIsCameraActive] = useState(true);
  
    const handleAudioToggle = () => {
      setIsAudioActive((prevState) => !prevState);
    };
  
    const handleCameraToggle = () => {
      setIsCameraActive((prevState) => !prevState);
    };
    const [micImage, setMicImage] = useState('/assets/images/mic-call.png');
    const [videoImage, setVideoImage] = useState('/assets/images/vdo-call.png');

    // Toggle the mic image
    const handleMicClick = () => {
        setMicImage((prevImage) =>
            prevImage === '/assets/images/mic-call.png'
                ? '/assets/images/audio-off.png'
                : '/assets/images/mic-call.png'
        );
    };

    // Toggle the video image
    const handleVideoClick = () => {
        setVideoImage((prevImage) =>
            prevImage === '/assets/images/vdo-call.png'
                ? '/assets/images/vdo-off.png'
                : '/assets/images/vdo-call.png'
        );
    };
  return (
   <div className='trainer-mode-wrapper'>
        <div className="trainer-mode-header">
            <div className='header-wrap'>
                <div className='header-left-wrap'>
                    <img src="/assets/images/mploi-logo-white.svg" alt="MPLOI Logo" className="logo" />
                    <div className="title">
                    Training in process <img src="/assets/images/waveform.png" alt="waveform Icon" />
                    </div>
                </div>
                <div className='header-right-wrap'>
                    <button className="green-trainer-button">
                    Practice Pitching <img src="/assets/images/person-voice-2.png" alt="Icon" />
                    </button>
                </div>
                
            </div>
        </div>
        
        <div className="trainer-details">
            <div className="caller-details">
                <div>
                <span>Training for: </span> Pitching for a house sale
                </div>
                <div className='dot'> • </div>
                 <div>
                    <span>Training with:</span> Maxine, MPLOI Trainer
                 </div>
            </div>
        </div>
        <div className='trainer-mode-wrap'>
            <div className="trainer-mode-top-content">
                <div className="status"><img src="/assets/images/person-voice.png" alt="" /> Try saying, “good morning”</div>
                    <button className="start-trainer-button">
                    End Interview Early <img src="/assets/images/call-end-icon.png" alt="Icon" />
                    </button>
    
            </div>
            <div className='trainer-mode-bottom-content'>
                <div className="left-img-wrap">
                    <div className='top-title-mob'>
                        <img src="/assets/images/person-voice.png" alt="" />  Try saying, “good morning”
                        <span> <img src='/assets/images/wave-color.png' alt=''/></span>
                    </div>
                        <img className="caller-img" src="/assets/images/caller-1.png" alt="Candidate" />
                        <div className='top-img-mic-icon'><img src='/assets/images/audio-rang.png' alt=''/> </div>
                        <div className="bottom-icon-wrap">
                            <div className="bottom-title">Candidate: <span>John Jones (you)</span></div>
                            <div className="feature-icon-wrap">
                            <button onClick={handleAudioToggle} className="icon-button">
              <img
                src={
                  isAudioActive
                    ? "/assets/images/audio-call-icon.png"
                    : "/assets/images/audio-call-off-icon.png"
                }
                alt="Audio"
                className="icon-img"
              />
            </button>
            <button onClick={handleCameraToggle} className="icon-button">
              <img
                src={
                  isCameraActive
                    ? "/assets/images/video-call-icon.png"
                    : "/assets/images/vdo-call-off-icon.png"
                }
                alt="Camera"
                className="icon-img"
              />
            </button>
                            </div>
                        </div>
                </div>
                <div className='right-img-wrap'>
                    <div className='thinking-btn'>
                        <img src="/assets/images/thinking.png" alt="" /><span>Thinking..</span> 
                    </div>
                    <div className='ready-btn'>
                        <img src="/assets/images/ready.png" alt="" /><span>Ready..</span> 
                    </div>
                    <div className='disable-btn'>
                       <span>Disable</span> 
                    </div>
                    <div className='enable-btn'>
                        <span>Enable</span> 
                    </div>
                <img className="caller-img" src="/assets/images/caller-2.png" alt="Candidate" />
                <div className="bottom-title">Interviewer: <span>Maxine, Hiring Manager from H&R Block</span></div>
                </div>
            </div>
            <div className='trainer-mode-bottom-wrap'>
            <img
                src={micImage}
                alt='Mic Call'
                onClick={handleMicClick}
            />
            <img
                src='/assets/images/call-cut.png'
                className='mid-icn'
                alt='Call Cut'
            />
            <img
                src={videoImage}
                alt='Video Call'
                onClick={handleVideoClick}
            />
                <button className="green-trainer-button-mob">
                <img src="/assets/images/person-voice-2.png" alt="Icon" /> Start Pitch 
                    </button>
            </div>

        </div>
        
   </div>
  )
}

export default TrainerMode