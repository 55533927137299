// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section1 {
    background: #150924;
    color: white;
    padding: 51px 100px;
    flex-wrap: wrap;
    justify-content: center;
}

.section1 .heading-wrap {
    padding-top: 40px;
    flex: 1 1;
}

.section1 .subheading {
    line-height: 34px;
}

.section1 .mobileImage {
    display: none;
}

.section1 .buttons-group {
    padding-top: 30px;
}

.section1 .buttons-group a {
    text-decoration: none !important;
    text-align: center;
    color: #141414 !important;

}

.section1 .buttons-group a:hover {
    text-decoration: none !important;
}

@media screen and (max-width: 480px) {
    .section1 {
        padding: 30px 25px;
        gap: 50px;
    }

    .section1 .heading-wrap {
        flex: auto;
    }

    .section1 .mobileImage {
        display: block;
        padding: 50px 0;
        position: relative;
    }

    .section1 .desktopImage {
        display: none;
    }

    .section1 .buttons-group {
        padding-top: 0px;
    }

    .heading-wrap span {
        padding: 0px 4px !important;
        display: inline-block;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/LandingPage/Section1/Section1.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,YAAY;IACZ,mBAAmB;IACnB,eAAe;IACf,uBAAuB;AAC3B;;AAEA;IACI,iBAAiB;IACjB,SAAO;AACX;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,gCAAgC;IAChC,kBAAkB;IAClB,yBAAyB;;AAE7B;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI;QACI,kBAAkB;QAClB,SAAS;IACb;;IAEA;QACI,UAAU;IACd;;IAEA;QACI,cAAc;QACd,eAAe;QACf,kBAAkB;IACtB;;IAEA;QACI,aAAa;IACjB;;IAEA;QACI,gBAAgB;IACpB;;IAEA;QACI,2BAA2B;QAC3B,qBAAqB;IACzB;AACJ","sourcesContent":[".section1 {\n    background: #150924;\n    color: white;\n    padding: 51px 100px;\n    flex-wrap: wrap;\n    justify-content: center;\n}\n\n.section1 .heading-wrap {\n    padding-top: 40px;\n    flex: 1;\n}\n\n.section1 .subheading {\n    line-height: 34px;\n}\n\n.section1 .mobileImage {\n    display: none;\n}\n\n.section1 .buttons-group {\n    padding-top: 30px;\n}\n\n.section1 .buttons-group a {\n    text-decoration: none !important;\n    text-align: center;\n    color: #141414 !important;\n\n}\n\n.section1 .buttons-group a:hover {\n    text-decoration: none !important;\n}\n\n@media screen and (max-width: 480px) {\n    .section1 {\n        padding: 30px 25px;\n        gap: 50px;\n    }\n\n    .section1 .heading-wrap {\n        flex: auto;\n    }\n\n    .section1 .mobileImage {\n        display: block;\n        padding: 50px 0;\n        position: relative;\n    }\n\n    .section1 .desktopImage {\n        display: none;\n    }\n\n    .section1 .buttons-group {\n        padding-top: 0px;\n    }\n\n    .heading-wrap span {\n        padding: 0px 4px !important;\n        display: inline-block;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
