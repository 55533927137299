import React, { useRef } from "react";
import { UtilityFunctions } from "../../src/utils/UtilityFunctions";
import { Button, Modal } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { STRING_CONSTANTS } from "../utils/constants/stringConstants";
import QRCode from "react-qr-code";
import { cookieService } from "../_services";
import { jsPDF } from "jspdf";
import { saveSvgAsPng } from 'save-svg-as-png';
import 'svg2pdf.js';

const QRCodeModal = (props) => {
  const qrRef = useRef();
  const userData = cookieService.getCookie(STRING_CONSTANTS.cookieStrings.user);
  // @TODO: set this properly
  userData.link = "https://google.com";
  const generatePDF = () => {
    const doc = new jsPDF()
    const element = qrRef.current;
    doc.svg(element, { x: 50, y: 50, width: 100, height: 100 })
      .then(() => {
        doc.save(`QR_${userData?.tradingName}.pdf`)
      })
  }

  const downloadQR = () => {
    saveSvgAsPng(qrRef.current, `QR_${userData?.tradingName}.png`, { scale: 6 });
  };

  return (
    <Modal
      className="bg-transparent upgrade-popup logout-popup settings-update-hash"
      aria-labelledby="contained-modal-title-vcenter" centered
      show={props?.show}
      onHide={() => props?.handleClose()}
    >
      <Modal.Body>
        <div className="business_name_barcode" style={{ maxWidth: "100%", margin: "0 auto" }}>
          <img src={userData?.logo || userData?.profilePhoto || UtilityFunctions.getImageURL() + "profile-pic-2x.png"} className="top-profile" />
          <h3>{userData?.tradingName}</h3>
          <div className="form-floating mb-4 pb-4">
            <div className="main-qr-code mx-auto">
              {userData?.link && <QRCode fgColor="#fff" bgColor="#1c2127" ref={qrRef} value={userData?.link} />}
            </div>
          </div>
          <div className="d-grid gap-3 align-items-center">
            <div className="d-grid d-md-flex gap-4 align-items-center">
              <Button
                className="border-0 w-100 d-flex align-items-center gap-2"
                variant="primary"
                onClick={generatePDF}
              >
                <img src={UtilityFunctions.getImageURL() + "arrow_download.svg"} alt="" /> PDF
              </Button>
              <Button
                className="border-0 w-100 d-flex align-items-center gap-2"
                variant="primary"
                onClick={downloadQR}
              >
                <img src={UtilityFunctions.getImageURL() + "arrow_download.svg"} alt="" /> Image
              </Button>
            </div>
            <Button className='border-0 bg-transparent cancel-button w-100' variant="primary" onClick={() => {
              props?.handleClose()
            }}>
              Cancel
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>

  )
}
export default withRouter(QRCodeModal);