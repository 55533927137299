import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { UtilityFunctions } from "../../utils/UtilityFunctions";
import { STRING_CONSTANTS } from "../../utils/constants/stringConstants";

const EmployerSignup = (props: any) => {
  const [pwdType, setPwdType] = useState("password");
  const [btnClick, setBtnClick] = useState(false);
  const hasNumber = /\d/;

  const store = !UtilityFunctions.validateEmail(props.email);
  const testPwd = UtilityFunctions.validatePassword(props.password);

  const togglePassword = () => {
    if (pwdType === "password") {
      setPwdType("text");
      return;
    }
    setPwdType("password");
  };

  const handleSubmit = () => {
    setBtnClick(true);
    props.nextButton();
  }
  const terms = {
    pathname: `/terms`,
    state: {
      disableButton: true,
      enableBackButton: true,
      isGuest: props?.location?.state?.isGuest,
      userType: props?.history?.location?.state?.userType,
      account_exists: props?.location?.state?.account_exists
    },
  };

  const privacy = {
    pathname: `/privacy`,
    state: {
      disableButton: true,
      enableBackButton: true,
      isGuest: props?.location?.state?.isGuest,
      userType: props?.history?.location?.state?.userType,
      account_exists: props?.location?.state?.account_exists
    },
  };

  const onBoarding = {
    pathname: "/",
    state: { activeIndex: 2 }
  }
  return (
    <div className="row">
      <div className="col-md-3 col-lg-4 col-xl-4 col-12">
        <div className="left-wrap">
          <div className="logo-wrap">
            <img
              alt=""
              src={UtilityFunctions.getImageURL() + "logo_White.svg"}
              onClick={() => props.history.push(onBoarding)}
            />
          </div>
        </div>
      </div>
      <div className="col-md-6 col-lg-4 col-xl-4 col-12">
        <div className="middle-wrap">
          <div className='top-divde-spa'>
            <span>
              <i
                className="fa fa-angle-left text-white d-flex justify-content-between"
                aria-hidden="true"
                onClick={() => {
                  props.history.push(STRING_CONSTANTS.navigationString.signup, { step: 0, isGuest: props?.location?.state?.isGuest || false, userType: props?.history?.location?.state?.userType, account_exists: props?.history?.location?.state?.account_exists })
                }
                }
              ></i>
            </span>
            <div className="step-content signup-mar">
              <button type='button' disabled>{STRING_CONSTANTS.signupPages.signup.sign_up}</button>
              <p>{STRING_CONSTANTS.signupPages.signup.first_step}</p>
              <div className="step-text">
                {STRING_CONSTANTS.signupPages.signup.step}
              </div>
            </div>
          </div>
          <div className="form-wrap">
            <div className='v-form-width'>
              <div className="form-floating mb-3">
                <input type="email" className={`form-control ${btnClick && store === true ? 'error' : ""}`} id="floatingInput" placeholder={STRING_CONSTANTS.formLabelString.label_email_plc} name='email' value={props.email} onChange={(e) => { props.setEmail(e.target.value); setBtnClick(false); }} />
                <label htmlFor="floatingInput">{STRING_CONSTANTS.formLabelString.label_email_plc}</label>
                <span className="input-group-text">
                  <img src={UtilityFunctions.getImageURL() + "mail.svg"} alt="" />
                  <img src={UtilityFunctions.getImageURL() + "mail-fill-blue.svg"} alt="" />
                  {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <g opacity="0.5">
                      <path d="M5.25 4H18.75C20.483 4 21.8992 5.35645 21.9949 7.06558L22 7.25V16.75C22 18.483 20.6435 19.8992 18.9344 19.9949L18.75 20H5.25C3.51697 20 2.10075 18.6435 2.00514 16.9344L2 16.75V7.25C2 5.51697 3.35645 4.10075 5.06558 4.00514L5.25 4H18.75H5.25ZM20.5 9.373L12.3493 13.6637C12.1619 13.7623 11.9431 13.7764 11.7468 13.706L11.6507 13.6637L3.5 9.374V16.75C3.5 17.6682 4.20711 18.4212 5.10647 18.4942L5.25 18.5H18.75C19.6682 18.5 20.4212 17.7929 20.4942 16.8935L20.5 16.75V9.373ZM18.75 5.5H5.25C4.33183 5.5 3.57881 6.20711 3.5058 7.10647L3.5 7.25V7.679L12 12.1525L20.5 7.678V7.25C20.5 6.33183 19.7929 5.57881 18.8935 5.5058L18.75 5.5Z" fill="white" />
                    </g>
                  </svg> */}
                  {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M22 8.608V16.75C22 18.483 20.6435 19.8992 18.9344 19.9949L18.75 20H5.25C3.51697 20 2.10075 18.6435 2.00514 16.9344L2 16.75V8.608L11.652 13.6644C11.87 13.7785 12.13 13.7785 12.348 13.6644L22 8.608ZM5.25 4H18.75C20.4347 4 21.8201 5.28191 21.9838 6.92355L12 12.1533L2.01619 6.92355C2.17386 5.34271 3.46432 4.09545 5.06409 4.00523L5.25 4H18.75H5.25Z" fill="#38BBF4" />
                  </svg> */}
                </span>
              </div>

              <div className="form-floating mb-3">
                <input type={pwdType} className={`form-control ${btnClick && !store && testPwd === false ? "error" : ""}`} id="floatingInputpwd" placeholder="Enter Password" name='password' value={props.password} onChange={(e) => { props.setPassword(e.target.value); setBtnClick(false); }} />
                <label htmlFor="floatingInputpwd">{STRING_CONSTANTS.formLabelString.label_password_plc} </label>
                <span className="input-group-text">
                  <img src={UtilityFunctions.getImageURL() + "key.svg"} alt="" />
                  <img src={UtilityFunctions.getImageURL() + "key-fill.svg"} alt="" />
                  {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <g opacity="0.5">
                      <path d="M18.2493 7.00006C18.2493 7.69042 17.6896 8.25006 16.9993 8.25006C16.3089 8.25006 15.7493 7.69042 15.7493 7.00006C15.7493 6.30971 16.3089 5.75006 16.9993 5.75006C17.6896 5.75006 18.2493 6.30971 18.2493 7.00006ZM15.4992 2.05005C11.885 2.05005 8.94922 4.98584 8.94922 8.60005C8.94922 8.98726 8.99939 9.36041 9.05968 9.70406C9.07749 9.80553 9.04493 9.89368 8.99046 9.94815L2.75467 16.1839C2.23895 16.6997 1.94922 17.3991 1.94922 18.1285V20.3C1.94922 21.2665 2.73272 22.05 3.69922 22.05H6.19922C7.16572 22.05 7.94922 21.2665 7.94922 20.3V19.05H9.69922C10.3896 19.05 10.9492 18.4904 10.9492 17.8V16.05H12.6992C13.3741 16.05 13.9241 15.5152 13.9484 14.8463C14.4451 14.9741 14.9689 15.05 15.4992 15.05C19.1134 15.05 22.0492 12.1143 22.0492 8.50005C22.0492 4.8689 19.0963 2.05005 15.4992 2.05005ZM10.4492 8.60005C10.4492 5.81426 12.7134 3.55005 15.4992 3.55005C18.3021 3.55005 20.5492 5.7312 20.5492 8.50005C20.5492 11.2858 18.285 13.55 15.4992 13.55C14.8199 13.55 14.1206 13.379 13.4947 13.1107C13.2629 13.0114 12.9968 13.0351 12.7864 13.1739C12.5759 13.3127 12.4492 13.5479 12.4492 13.8V14.55H10.6992C10.0089 14.55 9.44922 15.1097 9.44922 15.8V17.55H7.69922C7.00886 17.55 6.44922 18.1097 6.44922 18.8V20.3C6.44922 20.4381 6.33729 20.55 6.19922 20.55H3.69922C3.56115 20.55 3.44922 20.4381 3.44922 20.3V18.1285C3.44922 17.797 3.58091 17.479 3.81534 17.2446L10.0511 11.0088C10.4695 10.5904 10.6349 10.002 10.5371 9.44485C10.4834 9.13889 10.4492 8.86245 10.4492 8.60005Z" fill="white" />
                    </g>
                  </svg> */}
                  {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.94922 8.60005C8.94922 4.98584 11.885 2.05005 15.4992 2.05005C19.0963 2.05005 22.0492 4.8689 22.0492 8.50005C22.0492 12.1143 19.1134 15.05 15.4992 15.05C14.9689 15.05 14.4451 14.9741 13.9484 14.8463C13.9241 15.5152 13.3741 16.05 12.6992 16.05H10.9492V17.8C10.9492 18.4904 10.3896 19.05 9.69922 19.05H7.94922V20.3C7.94922 21.2665 7.16572 22.05 6.19922 22.05H3.69922C2.73272 22.05 1.94922 21.2665 1.94922 20.3V18.1285C1.94922 17.3991 2.23895 16.6997 2.75467 16.1839L8.99046 9.94815C9.04493 9.89368 9.07749 9.80553 9.05969 9.70406C8.99939 9.36041 8.94922 8.98726 8.94922 8.60005ZM18.2493 7.00006C18.2493 6.30971 17.6896 5.75006 16.9993 5.75006C16.3089 5.75006 15.7493 6.30971 15.7493 7.00006C15.7493 7.69042 16.3089 8.25006 16.9993 8.25006C17.6896 8.25006 18.2493 7.69042 18.2493 7.00006Z" fill="#38BBF4" />
                  </svg> */}
                </span>
                <div onClick={togglePassword} className="viewHide">
                  <img src={pwdType === "password" ? UtilityFunctions.getImageURL() + 'eyeOff.png' : UtilityFunctions.getImageURL() + 'eyeOn.png'} alt="" />
                </div>
              </div>

              <ul className="list-unstyled circle">
                <li className={` ${props.password.length <= 0 ? "" : props.password.length >= 8 && !UtilityFunctions.checkSpaceInPassword(props.password) ? "text-ok" : "text-red"}`}>
                  <span>
                    {STRING_CONSTANTS.validationString.min_chars}
                  </span>
                </li>
                <li className={`${props.password.length <= 0 ? "" : hasNumber.test(props.password) ? "text-ok" : "text-red"}`}>
                  <span>
                    {STRING_CONSTANTS.validationString.number_req}
                  </span>
                </li>
              </ul>

              <div className="btn-maccount mobile-btn d-block d-md-none">
                <button type='button' onClick={() => props.history.push(STRING_CONSTANTS.navigationString.login)}>
                  {STRING_CONSTANTS.signupPages.signup.account_exists}
                </button>
              </div>
            </div>
          </div>
          <div className="next-wrap">
            <p>
              {STRING_CONSTANTS.signupPages.signup.agree_terms}
              <br />
              <span className="text-white fw-bold" onClick={() => props.history.push(terms)}>
                {STRING_CONSTANTS.signupPages.signup.terms}
              </span>
              {/* <Link to={terms}>
                {STRING_CONSTANTS.signupPages.signup.terms}
              </Link> */}
              <span className="text-white fw-bold">&nbsp; & &nbsp;</span>
              {/* <Link to={privacy}>
                {STRING_CONSTANTS.signupPages.signup.services}
              </Link> */}
              <span className="text-white fw-bold" onClick={() => props.history.push(privacy)}>
                {STRING_CONSTANTS.signupPages.signup.services}
              </span>
            </p>
            <button className={`${store === false && testPwd === true ? "cricle-next" : "cricle-next-inactive"}`} onClick={handleSubmit}>
              <img alt="" src={UtilityFunctions.getImageURL() + "arrow-bottom.svg"} />
            </button>
          </div>
        </div>
      </div>
      <div className="col-md-3 col-lg-4 col-xl-4 col-12 d-none d-md-block">
        <div className="right-wrap">
          <div className="btn-maccount">
            <button onClick={() => props.history.push("/signin")}>
              {STRING_CONSTANTS.signupPages.signup.account_exists}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default withRouter(EmployerSignup);
