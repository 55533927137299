import React, { useEffect, useState } from "react";
import moment from "moment";

const ChatList = (props) => {
  const [time, setTime] = useState(null);
  const [count, setCount] = useState(0);
  const [localIdStatus, setLocalIdStatus] = useState(false);
  // console.log(props);
  useEffect(() => {
    setInterval(() => {
      setCount((count) => count + 1);
    }, 5000);
  }, []);

  // useEffect(() => {
  //   if (props.chat?.chat?.createdAt) {
  //     if (
  //       props.latestMessage !== "" &&
  //       props.chat?.id === props.selectedChat?.id
  //     ) {
  //       setTime(moment(props.latestMessageTime).fromNow());
  //     } else {
  //       setTime(moment(props.chat?.chat?.createdAt).fromNow());
  //     }
  //   }
  //   if (props.chat?.chat?.created_at) {
  //     if (
  //       props.latestMessage !== "" &&
  //       props.chat?.id === props.selectedChat?.id
  //     ) {
  //       setTime(moment(props.latestMessageTime).fromNow());
  //     } else {

  //       setTime(moment(props.chat?.chat?.created_at).fromNow());
  //     }
  //   }
  // }, [props.chat?.chat?.created_at]);

  return (
    <div>
      <span className="time">
        {props?.chat?.chat?.createdAt
          ? moment(props?.chat?.chat?.createdAt).fromNow()
          : props?.chat?.chat?.created_at
            ? moment(props?.chat?.chat?.created_at).fromNow()
            : ""}
      </span>
    </div>
  );
};

export default ChatList;
