import React from "react";
import { Route } from "react-router-dom";
import { cookieService } from "../_services";
import { STRING_CONSTANTS } from "../utils/constants/stringConstants";
import Login from "../pages/SignIn";
import Terms from "../pages/legal/terms";
import Privacy from "../pages/legal/privacy";
import EmployerProfileCompletion from "../pages/EmployerProfileCompletion";
import SignUp from "../pages/Signup";
import { Data } from "../_services/collections";
import EmployeeProfileCompletion from "../pages/EmployeeProfileCompletion";
import Dashboard from "../pages/Dashboard/dashboard";
import About from "../pages/About/About";

const PrivateRoute = ({ path, component: Comp, ...rest }) => {
  let user = cookieService.getCookie(STRING_CONSTANTS.cookieStrings.user)
  let isGuest = cookieService.getCookie(STRING_CONSTANTS.cookieStrings.isGuest)
  function getComponent(props) {
    if (isGuest && !user) {
      return <Dashboard {...props} />
    }
    else if (!user) {
      return <Login {...props} />
    }
    else if ((user as Data)?.user?.sendOffers === null) {
      return <SignUp {...props} step={7} />
    }
    else if ((user as Data)?.user?.sendOffers === null) {
      return <About {...props} step={7} />
    }
    else if (!(user as Data)?.user?.isTermsConditionAccepted) {
      // console.log('Terms not accepted')
      return <Terms {...props} />
    }
    else if (!(user as Data)?.user?.isPrivacyPolicyAccepted) {
      // console.log('Privacy not accepted', user?.user?.isPrivacyPolicyAccepted)
      return <Privacy {...props} />
    }
    else if (!(user as Data)?.user?.isProfileCompleted && (user as Data)?.user?.isMobileVerified === true) {
      if (user?.user?.type === STRING_CONSTANTS?.userType?.employer) {
        return <EmployerProfileCompletion {...props} />
      }
      if (user?.user?.type === STRING_CONSTANTS?.userType?.employee) {
        return <EmployeeProfileCompletion {...props} />
      }
    }
    else return <Comp {...props} />
  }

  return (
    <Route
      path={path}
      {...rest}
      render={getComponent}
    />
  );
};

export default PrivateRoute