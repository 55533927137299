import React from 'react';
import './ExploreMobile.css';

const ExploreMobile = ({ title, subtitle, price, duration, description, badgeText, badgeImage, from }) => {
  return (
    <div className="explore-card-mobile">
      <div className="badge-wrap">
        <div className="badge-icon">
          <img src={badgeImage} alt="Badge" />
        </div>
        <div className="subscription-badge">{badgeText}</div>
      </div>
      <div className="title">{title}</div>
      <div className="sub-title">{subtitle}</div>
      {from && <div className="sub-from">{from}</div>}
      <div className="price">{price} {duration}</div>
      <div className="description">{description}</div>
    </div>
  );
};

const ExplorecardsData = [
  {
    badgeImage: "/assets/images/boost.svg",
    title: "MPLOI Candidate Boost",
    subtitle: "Profile Boost",
    price: "$39.00",
    description:
      "That's why our pricing is based on the size of your team - the smaller your crew, the lower your cost. We understand that SMEs don't have the same recruitment budgets as the big corporates, so we've structured our pricing to be super affordable without sacrificing any of the good stuff. So whether you're a team of two or two hundred, MPLOI has a pricing plan that fits your budget and your recruitment needs.",
    badgeText: "One off payment",
  },
  {
    badgeImage: "/assets/images/learning-app.svg",
    title: "MPLOI Academy",
    subtitle: "Online Training Courses",
    price: "$79.00",
    from: "from",
    description:
      "That's why our pricing is based on the size of your team - the smaller your crew, the lower your cost. We understand that SMEs don't have the same recruitment budgets as the big corporates, so we've structured our pricing to be super affordable without sacrificing any of the good stuff. So whether you're a team of two or two hundred, MPLOI has a pricing plan that fits your budget and your recruitment needs.",
    badgeText: "Per Course",
  },
  {
    badgeImage: "/assets/images/legal-assist.svg",
    title: "AI Legal Assist",
    subtitle: "Legal Assistance for SME's",
    price: "$899",
    duration: "- 12 months",
    description:
      "Are you tired of navigating the complex world of legal matters alone? MPLOI AI Legal Assist, is the game-changing feature designed to help small and medium businesses tackle legal challenges with confidence. Our AI-powered legal consultant is here to provide you with the guidance and support you need, 24/7. Don't let legal hurdles slow down your business growth. Let MPLOI AI Legal Assist be your trusted partner in navigating the legal landscape.",
    badgeText: "12 Month Subscription",
  },
  {
    badgeImage: "/assets/images/sales-trainer.svg",
    title: "AI Sales Trainer",
    subtitle: "Individual",
    price: "$41.60 per month",
    description:
      "Imagine having your very own AI-powered sales coach, available anytime, anywhere. You can log on and hone your skills at your own pace, ensuring you’re always at the top of your game. Preparing for that big pitch? No problem! The MPLOI AI Sales Trainer has got your back. Our AI Sales Trainer adapts to your unique needs and learning style. You can input information about your target customers, including their DISC personality types, and receive tailored advice on how to approach and connect with them effectively. It's like having a sales mentor in your pocket, ready to help you succeed.",
    badgeText: "12 Month Subscription",
  },
  {
    badgeImage: "/assets/images/sales-trainer.svg",
    title: "AI Sales Trainer",
    subtitle: "SME Teams",
    price: "$2,499",
    from: "from",
    duration: "- 12 months",
    description:
      "As a business owner, you understand the importance of a well-trained team. But let's be honest - not all of us are natural-born teachers, and finding the time to train staff can be a challenge. Plus, hiring external sales trainers can be a costly endeavour.Imagine having your very own AI-powered sales coach, available anytime, anywhere. Your staff can log on and hone their skills at their own pace, ensuring they're always at the top of their game. Preparing for that big pitch or want new staff to know your company systems? No problem! The MPLOI AI Sales Trainer has got your back.",
    badgeText: "12 Month Subscription",
  },
  {
    badgeImage: "/assets/images/sales-trainer.svg",
    title: "AI Sales Trainer",
    subtitle: "Corporate Teams",
    price: "$20,000",
    from: "from",
    duration: "- 12 months",
    description:
      "At MPLOI, we understand that corporate sales teams have unique needs and requirements. That's why we offer specialised onboarding for all our systems, ensuring a seamless integration with your existing processes and infrastructure. But that's not all. With the MPLOI AI Sales Trainer, you can take customisation to the next level. Our secure platform allows you to upload your own internal training materials, enabling the AI Sales Trainer to adapt and align with your company's specific sales methodology and best practices. It's like having a virtual sales coach tailored exclusively to your team's needs.",
    badgeText: "12 Month Subscription",
  },
];

const ExploreMobileList = () => {
  return (
    <div>
      {ExplorecardsData.map((card, index) => (
        <ExploreMobile
          key={index}
          title={card.title}
          subtitle={card.subtitle}
          price={card.price}
          from={card.from}
          duration={card.duration}
          description={card.description}
          badgeText={card.badgeText}
          badgeImage={card.badgeImage}
        />
      ))}
    </div>
  );
};

export default ExploreMobileList;
