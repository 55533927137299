import React from "react";
import TrainerPitching from "../../../components/TrainerPitching/TrainerIntroScreen";

function TrainerPitchingPage() {
  return (
    <div>
      <TrainerPitching />
    </div>
  );
}

export default TrainerPitchingPage;
