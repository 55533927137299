import React, { useEffect, useState } from "react";
import { UtilityFunctions } from "../utils/UtilityFunctions";
import { STRING_CONSTANTS } from "../utils/constants/stringConstants";
import ToastHeader from "../components/toastHeader";
import ValidationErrorToast from "../components/validationErrorToast";
import { api } from "../middleware/api";
import SuccessToast from "../components/successToast";
import { get } from 'lodash';
import { END_POINTS } from "../utils/constants/endPoints";

const ResetPassword = (props) => {
  const [password, setPassword] = useState("");
  const [pwdType, setPwdType] = useState("password");
  const [isTokenActive, setIsTokenActive] = useState(true);
  const parameters = { uidb: props.match.params.uidb, token: props.match.params.token }
  const [btnClick, setBtnClick] = useState(false);
  const hasNumber = /\d/;

  const testPwd = UtilityFunctions.validatePassword(password);

  useEffect(() => {
    api.get(`${parameters.uidb}/${parameters.token}/check-token/`, null)
      .then(
    )
      .catch(e => {
        setIsTokenActive(false)
      })
  }, [])

  const togglePassword = () => {
    if (pwdType === "password") {
      setPwdType("text");
      return;
    }
    setPwdType("password");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (UtilityFunctions.isEmptyOrNullOrUndefined(password)) {
      ValidationErrorToast(STRING_CONSTANTS.formLabelString.label_password, STRING_CONSTANTS.validationString.req_password);
    } else {
      if (!password) {
        return;
      }
      let data = {
        newPassword: password,
      };
      api.post(`${END_POINTS.resetPassword}${parameters.uidb}/${parameters.token}/`, data)
        .then((res) => {
          // redirect to successful page
          SuccessToast(STRING_CONSTANTS.formLabelString.label_password, get(res, 'data.password[0]'))
          props.history.push('/')
        })
        .catch(function (error) {
          ToastHeader(error);
        });
    }
  };
  return (
    <section className="main-signup-wrap">
      <form>
        <div className="row">
          <div className="col-md-4 col-lg-4 col-xl-4 col-12">
            <div className="left-wrap">
              <div className="logo-wrap">
                <img
                  alt=""
                  src={UtilityFunctions.getImageURL() + "logo_White.svg"}
                  onClick={() => props.history.push("/")}
                />
              </div>
            </div>
          </div>
          <div className="col-md-4 col-lg-4 col-xl-4 col-12">
            <div className="middle-wrap">
              {!isTokenActive ?
                <div className='top-divde-spa'>
                  <div className="step-content">
                    <p>Reset Password Link is expired for security purpose. Please reset your password again.</p>
                  </div>
                </div> :
                <div>
                  {/* <span>
                    <i
                      className="fa fa-angle-left text-white d-flex justify-content-between"
                      aria-hidden="true"
                      onClick={() => props.history.goBack()}
                    ></i>
                  </span> */}
                  <div className='top-divde-spa'>
                    <div className="step-content">
                      <button type='button' disabled>Reset Password</button>
                      <p>Create your new<br /> password below</p>
                      <div className="step-text">Step 2/2</div>
                    </div>
                  </div>
                  <div className="form-wrap">
                    <div className='v-form-width'>
                      <div className="form-floating mb-3">
                        <input type={pwdType} className={`form-control ${btnClick && testPwd === false ? "error" : ""}`} id="floatingInputpwd" placeholder="Enter Password" name='password' value={password} onChange={(e) => { setPassword(e.target.value); setBtnClick(false); }} />
                        <label htmlFor="floatingInputpwd">Enter Password</label>
                        <span className="input-group-text">
                          <img src={UtilityFunctions.getImageURL() + "key.svg"} alt="" />
                          <img src={UtilityFunctions.getImageURL() + "key-fill.svg"} alt="" />
                          {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <g opacity="0.5">
                              <path d="M18.2493 7.00006C18.2493 7.69042 17.6896 8.25006 16.9993 8.25006C16.3089 8.25006 15.7493 7.69042 15.7493 7.00006C15.7493 6.30971 16.3089 5.75006 16.9993 5.75006C17.6896 5.75006 18.2493 6.30971 18.2493 7.00006ZM15.4992 2.05005C11.885 2.05005 8.94922 4.98584 8.94922 8.60005C8.94922 8.98726 8.99939 9.36041 9.05968 9.70406C9.07749 9.80553 9.04493 9.89368 8.99046 9.94815L2.75467 16.1839C2.23895 16.6997 1.94922 17.3991 1.94922 18.1285V20.3C1.94922 21.2665 2.73272 22.05 3.69922 22.05H6.19922C7.16572 22.05 7.94922 21.2665 7.94922 20.3V19.05H9.69922C10.3896 19.05 10.9492 18.4904 10.9492 17.8V16.05H12.6992C13.3741 16.05 13.9241 15.5152 13.9484 14.8463C14.4451 14.9741 14.9689 15.05 15.4992 15.05C19.1134 15.05 22.0492 12.1143 22.0492 8.50005C22.0492 4.8689 19.0963 2.05005 15.4992 2.05005ZM10.4492 8.60005C10.4492 5.81426 12.7134 3.55005 15.4992 3.55005C18.3021 3.55005 20.5492 5.7312 20.5492 8.50005C20.5492 11.2858 18.285 13.55 15.4992 13.55C14.8199 13.55 14.1206 13.379 13.4947 13.1107C13.2629 13.0114 12.9968 13.0351 12.7864 13.1739C12.5759 13.3127 12.4492 13.5479 12.4492 13.8V14.55H10.6992C10.0089 14.55 9.44922 15.1097 9.44922 15.8V17.55H7.69922C7.00886 17.55 6.44922 18.1097 6.44922 18.8V20.3C6.44922 20.4381 6.33729 20.55 6.19922 20.55H3.69922C3.56115 20.55 3.44922 20.4381 3.44922 20.3V18.1285C3.44922 17.797 3.58091 17.479 3.81534 17.2446L10.0511 11.0088C10.4695 10.5904 10.6349 10.002 10.5371 9.44485C10.4834 9.13889 10.4492 8.86245 10.4492 8.60005Z" fill="white" />
                            </g>
                          </svg>*/}
                          {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.94922 8.60005C8.94922 4.98584 11.885 2.05005 15.4992 2.05005C19.0963 2.05005 22.0492 4.8689 22.0492 8.50005C22.0492 12.1143 19.1134 15.05 15.4992 15.05C14.9689 15.05 14.4451 14.9741 13.9484 14.8463C13.9241 15.5152 13.3741 16.05 12.6992 16.05H10.9492V17.8C10.9492 18.4904 10.3896 19.05 9.69922 19.05H7.94922V20.3C7.94922 21.2665 7.16572 22.05 6.19922 22.05H3.69922C2.73272 22.05 1.94922 21.2665 1.94922 20.3V18.1285C1.94922 17.3991 2.23895 16.6997 2.75467 16.1839L8.99046 9.94815C9.04493 9.89368 9.07749 9.80553 9.05969 9.70406C8.99939 9.36041 8.94922 8.98726 8.94922 8.60005ZM18.2493 7.00006C18.2493 6.30971 17.6896 5.75006 16.9993 5.75006C16.3089 5.75006 15.7493 6.30971 15.7493 7.00006C15.7493 7.69042 16.3089 8.25006 16.9993 8.25006C17.6896 8.25006 18.2493 7.69042 18.2493 7.00006Z" fill="#38BBF4" />
                          </svg>  */}
                        </span>
                        <div onClick={togglePassword} className="viewHide">
                          <img src={pwdType === "password" ? UtilityFunctions.getImageURL() + 'eyeOff.png' : UtilityFunctions.getImageURL() + 'eyeOn.png'} alt="" />
                        </div>
                      </div>
                    </div>
                    <ul className="list-unstyled circle">
                      <li className={` ${password.length <= 0 ? "" : password.length >= 8 ? "text-ok" : "text-red"}`}>
                        <span>
                          Min. 8 characters
                        </span>
                      </li>
                      <li className={`${password.length <= 0 ? "" : hasNumber.test(password) ? "text-ok" : "text-red"}`}>
                        <span>
                          1 Char. should be a number
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="next-wrap">
                    <button
                      className={testPwd === true ? "cricle-next" : "cricle-next-inactive"}
                      onClick={(e) => {
                        handleSubmit(e);
                      }}
                    >
                      <img
                        alt=""
                        src={UtilityFunctions.getImageURL() + "tick.svg"}
                      />
                    </button>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </form>
    </section>
  );
};
export default ResetPassword;
