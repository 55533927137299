import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import JobSearch from "../../components/JobSearch/JobSearch";
import Footer from "../../components/Footer/Footer";
import Section1AR from "../../components/LandingPage/Section1AR/Section1AR";
import Section3 from "../../components/LandingPage/Section3/Section3";
import "./Employers.css";
import EmployerForm from "./EmployerForm";
import Slider from "react-slick";
import { Helmet } from 'react-helmet';

export default function AccountsReceivable() {
  const originalAccrodianData = [
    {
      heading: (
        <>
          <div className=" mr-2">AI-Powered Invoice Follow-ups</div>
          <img
            src="/assets/images/employeesLandingPage/exploreFirst.svg"
            alt=""
            className="iconImage"
          />
        </>
      ),
      description: (
        <div>
          Automated, conversational reminders tailored to each client.
        </div>
      ),
    },
    {
      heading: (
        <>
          <div className=" mr-2">Software integrations: Xero, MYOB</div>
          <img
            src="/assets/images/employersLandingPage/briefcase.svg"
            alt=""
            className="iconImage"
          />
        </>
      ),

      description: (
        <div>
          Just imagine, with an AI-assisted job posting tool like the one MPLOI
          offers, crafting the perfect job ad becomes a breeze! No more staring
          at a blank screen, wondering how to make your company sound appealing
          to top talent. The AI assistant can analyse successful job postings in
          your industry and suggest the most effective language and structure to
          grab candidates' attention. It's like having a team of expert
          recruiters at your fingertips, without the hefty price tag!
        </div>
      ),
    },
    {
      heading: (
        <>
          <div className=" mr-2">Automated Payment Plans</div>
          <img
            src="/assets/images/employeesLandingPage/exploreFourth.svg"
            alt=""
            className="iconImage"
          />
        </>
      ),

      description: (
        <div>
          Picture this... a world where business owners can effortlessly create
          custom interview questions or upload their own, and then sit back as
          our sophisticated AI Avatar conducts engaging interviews with
          potential candidates. No more endless hours spent scheduling and
          conducting interviews yourself! Our AI will handle the heavy lifting,
          recording each interview and delivering it straight to your inbox.
          Imagine the time and resources you could save, all while ensuring a
          consistent and unbiased interview process. It's like having your own
          personal HR assistant, minus the coffee breaks and water cooler
          gossip! Are you ready to revolutionise your hiring process with
          MPLOI's AI Interview Simulator?
        </div>
      ),
    },
    {
      heading: (
        <>
          <div className=" mr-2">Payment Link Generation</div>
          <img
            src="/assets/images/employersLandingPage/hammer.svg"
            alt=""
            className="iconImage"
          />
        </>
      ),

      description: (
        <div>
          <div style={{ fontWeight: 700, marginBottom: "30px" }}>
            Introducing MPLOI AI Legal Assist: Your Virtual Legal Sidekick for
            SMEs!
          </div>
          Are you tired of navigating the complex world of legal matters alone?
          Say hello to MPLOI AI Legal Assist, the game-changing feature designed
          to help small and medium businesses tackle legal challenges with
          confidence. Our AI-powered legal consultant is here to provide you
          with the guidance and support you need, 24/7. <br /> With MPLOI AI
          Legal Assist, you can: <br />
          <ul style={{ paddingLeft: "30px" }}>
            <li>Get instant answers to your legal questions</li>
            <li>Receive personalised advice tailored to your business</li>
            <li>Save time and money on minor legal issues</li>
            <li>Access a wealth of legal resources at your fingertips</li>
            <li>
              Enjoy peace of mind knowing you have a virtual legal expert by
              your side
            </li>
          </ul>
          Don't let legal hurdles slow down your business growth. Let MPLOI AI
          Legal Assist be your trusted partner in navigating the legal
          landscape. Try it now and experience the difference!
        </div>
      ),
    },
  ];

  const [expanded, setExpanded] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [accrodianData, setAccrodianData] = useState(() => {
    return window.innerWidth < 768
      ? originalAccrodianData.slice(0, 3)
      : originalAccrodianData;
  });

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleResetData = (slice = false) => {
    if (slice) {
      setAccrodianData(originalAccrodianData.slice(0, 3));
      setExpanded(false);
    } else {
      setAccrodianData(originalAccrodianData);
      setExpanded(true);
    }
  };

  const sliderData = [
    {
      value: "87%",
      description:
        "Millennials say that professional development/ career growth are important to them.",
      color: "#9256E8",
      bgColor: "#9256E817",
    },
    {
      value: "74%",
      description:
        "Workers say that a lack of employee development opportunities prevents them from reaching their full potential.",
      color: "#F495D3",
      bgColor: "#F495D317",
    },
    {
      value: "58%",
      description:
        "Employees say professional development contributes to their job satisfaction.",
      color: "#38BBF4",
      bgColor: "#38BBF417",
    },
    {
      value: "68%",
      description:
        "Employees say training and development are the most essential company policy",
      color: "#FFE35A",
      bgColor: "#2A1D29",
    },
    {
      value: "74%",
      description:
        "Workers are willing to learn new skills or retrain to stay employable say that a lack of employee development opportunities prevents them from reaching their full potential.",
      color: "#06EFC5",
      bgColor: "#06EFC517",
    },
  ];

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 5.1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 2400,
        settings: {
          slidesToShow: 4.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 2200,
        settings: {
          slidesToShow: 3.8,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1800,
        settings: {
          slidesToShow: 3.1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2.1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 825,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };


  //   Revolutionize Your Cash Flow:
  // AI-Powered Accounts Receivable
  // Made Simple with MPLOI

  // Experience the Revolution in Invoice Collection Firsthand
  return (
    <div className="employersPageWrap">
      <Helmet>
        <title>MPLOI Accounts Recievable: Experience the Revolution in Invoice Collection</title>
      </Helmet>
      <Header />
      <Section1AR
        heading={
          <>
            <div className=" fw-bold heading">
              Experience the <br />
              <span className=" px-2" style={{ background: "#9256E8" }}>
                Revolution
              </span>{" "}<br />
              In Invoice Collection<br />
            </div>
          </>
        }
        subHeading="Our advanced AI voice simulator takes the hassle out of chasing unpaid invoices. Equipped with natural language processing capabilities, our AI-powered Model engages with your customers in personalised, human-like conversations. It intelligently interacts with your clients, reminding them of outstanding invoices and facilitating prompt payments, all while maintaining a professional and friendly tone."
        buttons={
          <>
            <a href="/signup" target="_blank" className="textBlack">
              <button className=" border-0 px-4 py-2 primaryButton">
                Collect Cash Now
                <img
                  src="assets/images/employersLandingPage/teamMembers.svg"
                  alt=""
                />
              </button>
            </a>
            <a href="#employers-feature">
              <button className=" border-0 px-4 py-2 secondaryButton">
                Explore Accounts Receivable Features
                <img
                  src="assets/images/employeesLandingPage/exploreIcon.svg"
                  alt=""
                />
              </button>
            </a>
          </>
        }
        image={
          <>
            <EmployerForm />
          </>
        }
        mobileImage={
          <>
            <img
              src="/assets/images/employersSection1.png"
              className="img-fluid mobileImage"
              alt=""
            />
          </>
        }
      />
      <div className="section2">
        <div className="heading">
        MPLOI: AI-powered accounts receivable,<br />designed by business owners<br />for smarter cash flow management.
        </div>
        <div className="sec-2-btn">
          <a href="/signup" target="_blank" className="textBlack">
            <button className=" border-0 px-5 py-2 primaryButton">
              Join MPLOI Today!
              <img
                src="assets/images/employeesLandingPage/testYourself.svg"
                alt=""
              />
            </button>
          </a>
        </div>
      </div>
      <div id="employers-feature">
        <Section3
          accrodianData={accrodianData}
          tagName="For Business Owners"
          description="Explore MPLOI’s Accounts Receivable Tool for Business Owners"
          image={
            <img
              width="650"
              src="/assets/images/lp_images/accounts_receivable/accounts_receivable_lp3.png"
              className="img-fluid"
               alt=""
            />
          }
          mobilebutton={
            isMobile ? (
              expanded ? (
                <button
                  className=" border-0 px-5 py-2 primaryButton textBlack"
                  onClick={() => handleResetData(true)}
                >
                  Expand Less
                  <img
                    src="assets/images/downArrowBlack.svg"
                    alt=""
                    style={{ transform: "rotate(180deg)" }}
                  />
                </button>
              ) : (
                <button
                  className=" border-0 px-5 py-2 primaryButton textBlack"
                  onClick={() => handleResetData()}
                >
                  Expand All
                  <img src="assets/images/downArrowBlack.svg" alt="" />
                </button>
              )
            ) : (
              ""
            )
          }
        />
      </div>




      <Footer />
    </div>
  );
}
