import React from 'react';
import "./PricingCardCadidate.css";

const PricingCardCandidate = () => {

  const cardsCandidate = [
    {
      icon: '/assets/images/boost.svg',
      paymentType: "One off payment",
      title: "MPLOI Candidate Boost",
      subtitle: "Profile Boost",
      price: "$39.00",
      description: "For a mere $39, you can enjoy 30 days of VIP treatment, ensuring that your skills and experience catch the eye of potential employers before anyone else. No more waiting around hoping to be noticed - with Profile Boost, you'll be the star candidate that businesses can't wait to meet.",
      buttonText: "Get Started",
    },
    {
      icon: '/assets/images/learning-app.svg',
      paymentType: "Per Course",
      title: "MPLOI Academy",
      subtitle: "Online Training Courses",
      from: "from",
      price: " $79.00",
      description: "“MPLOI Academy\" is a game-changer for any job seekers using the MPLOI platform. By leveraging advanced AI algorithms, this feature tailors course recommendations to your unique skills and career aspirations, ensuring you invest their time in the most relevant and impactful learning opportunities. This personalised approach not only enhances your qualifications but also significantly boosts your competitiveness in the job market.",
      buttonText: "Get Started",
    },
    {
      icon: '/assets/images/sales-trainer.svg',
      paymentType: "12 Month Subscription",
      title: "AI Sales Trainer",
      subtitle: "Individual",
      price: "$41.60 per month",
      description: "Imagine having your very own AI-powered sales coach, available anytime, anywhere. You can log on and hone your skills at your own pace, ensuring you’re always at the top of your game. Preparing for that big pitch? No problem! The MPLOI AI Sales Trainer has got your back. Our AI Sales Trainer adapts to your unique needs and learning style. You can input information about your target customers, including their DISC personality types, and receive tailored advice on how to approach and connect with them effectively. It's like having a sales mentor in your pocket, ready to help you succeed.",
      buttonText: "Get Started",
    },
  ];

  return (
    <div className="pricing-candidate-wrap">
      {cardsCandidate.map((cardWrap, index) => (
        <div key={index} className="pricing-candidate">
            <div className='content-wrap'>
            <div className='header-card '>
                <img src={cardWrap.icon} alt="icon" className="candidate-icon" />
                <div className="payment-type">{cardWrap.paymentType}</div>
            </div>
          <h3 className="candidate-title">{cardWrap.title}</h3>
          <h4 className="candidate-subtitle">{cardWrap.subtitle}</h4>
          {cardWrap.from && <div className="candidate-from">{cardWrap.from} </div>}
          <div className="candidate-price">{cardWrap.price}</div>
            </div>
           
          <p className="candidate-description">{cardWrap.description}</p>
          <a href="/signup" target="_blank"><button className="candidate-button">
            {cardWrap.buttonText} <span className="arrow"><img src="/assets/images/arrow-up-left-black.svg" alt=""/></span>
          </button>
          </a>
        </div>
      ))}
    </div>
  );
};

export default PricingCardCandidate;
