import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import "./Blogs.css";

export default function Blogs() {
  const sliderData = [
    {
      id: 0,
      img: "/assets/images/blog-img.png",
      title: "Setting up your MPLOI profile as a job seeker",
      date: "12 March 2024",
      description:
        "Lorem ipsum dolor sit amet consectetur. Orci eu cursus donec augue lectus lectus id orci enim. Vivamus lobortis adipiscing pretium faucibus iaculis at tristique. ",
    },
    {
      id: 0,
      img: "/assets/images/blog-img.png",
      title: "Setting up your MPLOI profile as a job seeker",
      date: "12 March 2024",
      description:
        "Lorem ipsum dolor sit amet consectetur. Orci eu cursus donec augue lectus lectus id orci enim. Vivamus lobortis adipiscing pretium faucibus iaculis at tristique. ",
    },
    {
      id: 0,
      img: "/assets/images/blog-img.png",
      title: "Setting up your MPLOI profile as a job seeker",
      date: "12 March 2024",
      description:
        "Lorem ipsum dolor sit amet consectetur. Orci eu cursus donec augue lectus lectus id orci enim. Vivamus lobortis adipiscing pretium faucibus iaculis at tristique. ",
    },
    {
      id: 0,
      img: "/assets/images/blog-img.png",
      title: "Setting up your MPLOI profile as a job seeker",
      date: "12 March 2024",
      description:
        "Lorem ipsum dolor sit amet consectetur. Orci eu cursus donec augue lectus lectus id orci enim. Vivamus lobortis adipiscing pretium faucibus iaculis at tristique. ",
    },
    {
      id: 0,
      img: "/assets/images/blog-img.png",
      title: "Setting up your MPLOI profile as a job seeker",
      date: "12 March 2024",
      description:
        "Lorem ipsum dolor sit amet consectetur. Orci eu cursus donec augue lectus lectus id orci enim. Vivamus lobortis adipiscing pretium faucibus iaculis at tristique. ",
    },
    {
      id: 0,
      img: "/assets/images/blog-img.png",
      title: "Setting up your MPLOI profile as a job seeker",
      date: "12 March 2024",
      description:
        "Lorem ipsum dolor sit amet consectetur. Orci eu cursus donec augue lectus lectus id orci enim. Vivamus lobortis adipiscing pretium faucibus iaculis at tristique. ",
    },
  ];
  const totalPages = 4;

  // State to manage current page
  const [currentPage, setCurrentPage] = useState(1);

  // Handler for Next button
  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Handler for Previous button
  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <div className="blogs-wrap">
        <div className="heading">MPLOI Blog Articles</div>
        <div className="description">
          Subscribe to our newsletter to get updates on our latest blogs
        </div>
        <div
          className="email-wrap"
          style={{ display: "flex", alignItems: "center" }}
        >
          <input
            type="email"
            id="email"
            placeholder="Email address"
            style={{ flex: 1, padding: "8px" }}
            className="email-input"
          />
          <button className="btn rounded-0 border-0 px-5 py-2 primaryButton">
            Subscribe
            <img
              src="assets/images/employeesLandingPage/testYourself.svg"
              alt=""
            />
          </button>
        </div>
        <div className="subheading">Our Latest Blogs</div>
        <div className="blogs-card-wrapper">
          {sliderData.map((item) => {
            return (
              <div className="card-wrap" key={item.id + 1}>
                <div className="image">
                  <img src={item.img} alt="" className="img-fluid w-100" />
                </div>
                <div className="card-content-wrap">
                  <div className="title">{item.title}</div>
                  <div className="blog-date">{item.date}</div>
                  <div className="description">{item.description}</div>

                  <div className="read-btn">
                    <a
                      href="/blog-details"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Read More{" "}
                      <img
                        src="/assets/images/arrow-up-right.png"
                        className="img-fluid arrow"
                        alt=""
                      />
                    </a>
                  </div>

                </div>
              </div>
            );
          })}
        </div>
        <div className="d-flex align-items-center justify-content-between pagination-wrap">
          <button
            className="btn rounded-0 border-0 px-4 py-2 primaryButton"
            onClick={handlePrevious}
            disabled={currentPage === 1}
          >
            <img src="../assets/images/arrow-left.svg" alt="" srcset="" />
            <span className="btn-text">Previous</span>
          </button>
          <span>
            {currentPage} of {totalPages}
          </span>
          <button
            className="btn rounded-0 border-0 px-4 py-2 primaryButton"
            onClick={handleNext}
            disabled={currentPage === totalPages}
          >
            <span className="btn-text">Next</span>
            <img src="../assets/images/arrow-right.svg" alt="" srcset="" />
          </button>
        </div>
      </div >
      <Footer />
    </>
  );
}
