import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logout from "../components/logout";
import { checkData } from "../utils/checkData";

import { STRING_CONSTANTS } from "../utils/constants/stringConstants";
import { UtilityFunctions } from "../utils/UtilityFunctions";
import { cookieService, userService } from "../_services";


const ProfileWrapper = (props: any) => {
    return (
        <section className="main-signup-wrap">
            <div className="row">
                <div className="col-md-3 col-lg-4 col-xl-4 col-12">
                    <div className="left-wrap">
                        <div className="logo-wrap">
                            <Link to={props.module === "JobCreation" ? STRING_CONSTANTS.navigationString.dashboard.home : STRING_CONSTANTS.navigationString.index}>
                                <img
                                    alt=""
                                    src={UtilityFunctions.getImageURL() + "logo_White.svg"}
                                />
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="col-md-6 col-lg-4 col-xl-4 col-12">
                    <div className={`middle-wrap employee-add-jobs-bottom-button-fixed ${(props?.step === 5 || props?.step === 1) ? "pb-0" : ""}`}>
                        {props.children}
                    </div>
                </div>
            </div>
        </section >)
}

export default ProfileWrapper;
