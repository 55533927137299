import React from "react";
import { UtilityFunctions } from "../../src/utils/UtilityFunctions";
import { Button, Modal } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { cookieService, userService } from "../_services";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../store";
import { STRING_CONSTANTS } from "../utils/constants/stringConstants";
import { checkData } from "../utils/checkData";

const UpdatePlan = (props) => {
  const dispatch = useDispatch();
  const handleClick = (e) => {
    props.history.push(STRING_CONSTANTS.navigationString.dashboard.settings, { tab: "subscription" })
  };
  return (
    <Modal
      className="bg-transparent upgrade-popup logout-popup"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={props.show}
      onHide={props.handleClose}
    >
      <Modal.Body>
        <img
          alt="logout"
          src={UtilityFunctions.getImageURL() + "arrow_up.svg"}
        />
        <h3 className="py-2">Upgrade your plan?</h3>
        <p className="py-3">
          In order to create more job listings you will need to upgrade your current subscription plan.
        </p>
        <div>
          <Button className='ms-4 border-0 bg-transparent cancel-button' variant="primary" onClick={props.handleClose}>
            Cancel
          </Button>
          <Button
            className="ms-4 border-0"
            variant="primary"
            onClick={handleClick}
          >
            View Plans
          </Button>
        </div>
      </Modal.Body>
    </Modal>

  )
}
export default withRouter(UpdatePlan);