import { STRING_CONSTANTS } from "../../utils/constants/stringConstants";
import { UtilityFunctions } from "../../utils/UtilityFunctions";

const logoUrl = UtilityFunctions.getImageURL() + 'logo_White.svg';
export const onboardingScreenData = [
  {
    primaryLogo: logoUrl,
    image: UtilityFunctions.getImageURL() + 'slide1.jpg',
    title: STRING_CONSTANTS.onboardingScreen.screen1.title,
    subtitle: STRING_CONSTANTS.onboardingScreen.screen1.subtitle,
  },
  {
    primaryLogo: logoUrl,
    image: UtilityFunctions.getImageURL() + 'slide2.jpg',
    title: STRING_CONSTANTS.onboardingScreen.screen2.title,
    subtitle: STRING_CONSTANTS.onboardingScreen.screen2.subtitle,
  },
  {
    primaryLogo: logoUrl,
    image: UtilityFunctions.getImageURL() + 'slide3.jpg',
    title: STRING_CONSTANTS.onboardingScreen.screen3.title,
    subtitle: STRING_CONSTANTS.onboardingScreen.screen3.subtitle,
  },
];

export default onboardingScreenData;
