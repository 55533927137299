import { withRouter } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import StripePrices from '../../../components/subscription';
import { handleStripePrices } from "../../../utils/interfaces";
import { userService } from "../../../_services";
import moment from "moment";
import ChangePlan from "../../../components/changePlan"

const Subscription = (props) => {
    const childRef = useRef<handleStripePrices>(null);
    const [transactionHistory, setTransactionHistory] = useState([]);
    const [planUpdate, setPlanUpdate] = React.useState<boolean>(false);
    const [show, setShow] = useState<boolean>(false);

    const updatePlan = () => {
        // userService.showTransactionHistory()
        //     .then((res) => {
        //         document.body.classList.add("loading-indicator");
        //         setTransactionHistory(res)
        //         setPlanUpdate(false)
        //         //setShow(false);
        //     })
        //     .catch((err) => {
        //         // console.log(err);
        //     })
    }

    useEffect(() => {
        updatePlan()
    }, [])

    useEffect(() => {
        if (planUpdate) { updatePlan() }
    }, [planUpdate])


    const handleClose = () => {
        setShow(false);
    }
    const handleSubmit = () => {
        childRef.current?.handleSubmit()

    }

    return <div className="tab-pane fade show active" id="panel3" role="tabpanel" aria-labelledby="panel3-tab" >
        <div className="container-fluid">
            <div className='title-row'>
                <h1>Subscription</h1>
            </div>
            <div className="scroll-bar-dash">
                <div className="blue-shadow-box right-padding0 mt-0">
                    <h3>Current Plan</h3>
                    <div className="plan-wrap-dashboard">
                        <StripePrices setPlanUpdate={setPlanUpdate} setShow={setShow} {...props} ref={childRef} />
                    </div>

                </div>

                <div className="blue-shadow-box">
                    <h3>Payment History</h3>
                    <div className="table-responsive">
                        <table className="table custome-table">
                            <thead>
                                <tr><th>Date</th>
                                    <th>Plan</th>
                                    <th>Amount</th></tr>

                            </thead>
                            {transactionHistory.map((value) => {
                                return (
                                    <tbody key={value.id}>
                                        <tr><td>{moment(value.createdAt).format('MM/DD/YY | hh:mm a')}</td>
                                            <td>{value.plan}</td>
                                            <td>{value.amount}</td></tr>

                                    </tbody>
                                )
                            })}

                        </table>
                    </div>
                </div>
            </div>

            <div className="btn-wrap"><button onClick={() => { setShow(true); }}>Update</button></div>
            <ChangePlan show={show} handleClose={handleClose} handleSubmit={handleSubmit} />

        </div>
    </div>

}

export default withRouter(Subscription);