import React, { useEffect, useMemo, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import ValidationErrorToast from "../../../components/validationErrorToast";
import { api } from "../../../middleware/api";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { UtilityFunctions, modifiedSalaray, timeConvrter, dataURLtoFile } from "../../../utils/UtilityFunctions";
import UpdateEmailNumber from "../../../components/updateEmailNumber";
import UpdateNumber from "../../../components/updateNumber"
import SuccessToast from "../../../components/successToast";
import { STRING_CONSTANTS } from "../../../utils/constants/stringConstants";
import ImageCrop from "../../../common/ui/ImageCrop";
import { cookieService, userService } from "../../../_services";
import ReactPlayer from 'react-player/lazy'
import moment from "moment";

const UpdateProfileEmployee = (props: any) => {
  const [remoteUserData, setRemoteUserData] = useState<any>();
  const [about, setAbout] = useState("");
  const [selectedFile, setSelectedFile] = useState<File>();
  const [preview, setPreview] = useState(null);
  const [number, setNumber] = useState();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("")
  const nameRef = useRef<HTMLInputElement>();
  const emailRef = useRef<HTMLInputElement>();
  const aboutRef = useRef<any>();
  const vidRef = useRef<any>(null);
  const viewRef = useRef<any>(null);
  const [videoSrc, setVideoSrc] = useState(null);
  const [finalVidSrc, setFinalVidSrc] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  const [playVideo, setPlayVideo] = useState(false)

  // for modals
  const [showPwdModal, setShowPwdModal] = useState(false);
  const [showNumberModal, setShowNumberModal] = useState(false)
  const [pwd, setPwd] = useState<any>();

  // input states
  const [errorState, setErrorState] = useState({ email: false, name: false, about: false })
  const [focusState, setFocusState] = useState({ email: false, name: false })
  const categories = cookieService.getCookie(STRING_CONSTANTS.cookieStrings.jobCategories)

  useEffect(() => {
    getUserData()
  }, [])

  const getUserData = () => {
    userService.getUserData()
      .then(res => {
        setRemoteUserData(res)
        setNumber(res?.user?.mobileNumber)
        setVideoSrc(res?.introductoryVideo)
        setFinalVidSrc(res?.introductoryVideo)
        setThumbnail(res?.thumbnail)
        setAbout(res?.about)
        setEmail(res?.user?.email)
        setName(res?.user?.firstName + " " + res?.user?.lastName)
        emailRef.current.value = res?.user?.email
        nameRef.current.value = (res?.user?.firstName + " " + res?.user?.lastName)
        cookieService.saveCookie(STRING_CONSTANTS.cookieStrings.user, res)
      })
      .then(() => {
        props.history.push("/dashboard/settings/", { tab: "updateProfile" })
        setSelectedFile(null)
        setPreview(null)
      })
  }

  // Below function checks if any input is being updated
  const updateDetector = () => {
    if ((preview || selectedFile)
      || vidRef.current.files[0]
      || ((remoteUserData?.user?.firstName + " " + remoteUserData?.user?.lastName) !== nameRef.current.value)
      || (remoteUserData?.user?.email !== emailRef.current.value)
      || UtilityFunctions.isValidURL(remoteUserData?.introductoryVideo) && !videoSrc
      || (remoteUserData?.about !== aboutRef.current.value)
    ) {
      // alert("something changed")
      inputValidator()
    }
    // else return SuccessToast("Details upto date", "Please change one or more details before updating")
  }

  // Below function checks for invalid/empty inputs
  const inputValidator = () => {
    if (!UtilityFunctions.validateEmail(emailRef.current.value)) {
      ValidationErrorToast("Email Address", "Please enter a valid email before proceeding")
      setErrorState(prev => { return { ...prev, email: true } })
    }
    else if (UtilityFunctions.isEmptyOrNullOrUndefined(nameRef.current.value)) {
      ValidationErrorToast("Full Name", "Please enter a valid name before proceeding")
      setErrorState(prev => { return { ...prev, name: true } })
    }
    else if (UtilityFunctions.isEmptyOrNullOrUndefined(aboutRef.current.value)) {
      ValidationErrorToast("About Me", "This field cannot be empty")
      setErrorState(prev => { return { ...prev, about: true } })
    }
    else if (about?.replace(/ /g, '').length > 2500) {
      ValidationErrorToast('Word Limit Exceeded', "This field cannot contain more than 2500 letters/characters")
      setErrorState(prev => { return { ...prev, about: true } })
    }
    else profileUpdater()
  }

  // Below function handles image/video update specifically
  const mediaUpdater = () => {
    let formData = new FormData();
    // Video/Image has been added by the user
    if (videoSrc && !UtilityFunctions.isValidURL(videoSrc)) {
      formData.append("introductoryVideo", vidRef?.current?.files[0])
      if (preview && selectedFile) {
        formData.append("profilePhoto", dataURLtoFile(preview, Date.now() + '.jpeg'))
      }
      document.body.classList.add("loading-indicator");
      userService.userProfile(formData)
        .then(res => {
          cookieService.saveCookie(STRING_CONSTANTS.cookieStrings.user, res.data)
        })
        .then(() => {
          SuccessToast("Success!", "Your profile details have been updated successfully")
          getUserData()
        })
        .catch(e => ValidationErrorToast(e[0], e[1]))
    }
    // Exisiting video has been removed by the user and new image uploaded
    else if (!vidRef?.current?.files[0] && !videoSrc && UtilityFunctions.isValidURL(remoteUserData.introductoryVideo)) {
      userService.userProfile({ "introductoryVideo": null, "thumbnail": null })
        .then(res =>
          cookieService.saveCookie(STRING_CONSTANTS.cookieStrings.user, res.data)
        )
        .then(() => {
          if (preview && selectedFile) {
            let formData = new FormData();
            formData.append("profilePhoto", dataURLtoFile(preview, Date.now() + '.jpeg'))
            userService.userProfile(formData)
              .then(res =>
                cookieService.saveCookie(STRING_CONSTANTS.cookieStrings.user, res.data)
              )
              .then(() => {
                SuccessToast("Success!", "Your profile details have been updated successfully")
                getUserData()
              })
              .catch(e => ValidationErrorToast(e[0], e[1]))
          }
          else {
            SuccessToast("Success!", "Your profile details have been updated successfully")
            getUserData()
          }
        })
        .catch(e => ValidationErrorToast(e[0], e[1]))
    }
    // only image uploaded
    else if (preview && selectedFile) {
      formData.append("profilePhoto", dataURLtoFile(preview, Date.now() + '.jpeg'))
      userService.userProfile(formData)
        .then(res =>
          cookieService.saveCookie(STRING_CONSTANTS.cookieStrings.user, res.data)
        )
        .then(() => {
          SuccessToast("Success!", "Your profile details have been updated successfully")
          getUserData()
        })
        .catch(e => ValidationErrorToast(e[0], e[1]))
    }
    // no media changes
    else {
      SuccessToast("Success!", "Your profile details have been updated successfully")
      getUserData()
    }
  }

  // Below function handles profile update collectively
  const profileUpdater = () => {
    document.body.classList.add("loading-indicator");
    const objForPatch: any = {
      user: {
        firstName: UtilityFunctions.nameFormatter(nameRef.current.value)[0],
        lastName: UtilityFunctions.nameFormatter(nameRef.current.value)[1],
      },
      about: aboutRef.current.value
    }
    if ((remoteUserData?.user?.email !== emailRef.current.value) && !pwd) {
      setShowPwdModal(true)
    }
    else if ((remoteUserData?.user?.email !== emailRef.current.value) && pwd) {
      objForPatch.user.email = emailRef.current.value
      objForPatch.user.password = pwd
      userService.userProfile(objForPatch)
        .then(res => {
          cookieService.saveCookie(STRING_CONSTANTS.cookieStrings.user, res.data)
          setShowPwdModal(false)
          setPwd("")
        }
        )
        .then(() => mediaUpdater())
        .catch(e => ValidationErrorToast(e[0], e[1]))
    }
    else userService.userProfile(objForPatch)
      .then(res => cookieService.saveCookie(STRING_CONSTANTS.cookieStrings.user, res.data))
      .then(() => mediaUpdater())
  }

  const experienceToDisplay = (totalMonths) => {
    const years = Math.floor(totalMonths / 10)
    const months = totalMonths % 10
    return [years, months]
  }

  function videoDurationValidator(duration: number) {
    if (duration > 30) {
      setFinalVidSrc("")
      setVideoSrc("");
      vidRef.current.value = ""
      setThumbnail(null)
      ValidationErrorToast("Video length exceeded", "Video length cannot be greater than 30 seconds");
    }
    else setFinalVidSrc(videoSrc);
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    // duration !== null && document.body.classList.add("loading-indicator");
    // console.log(duration.target.duration)
    if (event?.target?.files[0]?.size > 157286400) {
      setFinalVidSrc("");
      setVideoSrc("");
      ValidationErrorToast("File Size Exceeded", "File size cannot exceed 150mb")
      vidRef.current.value = "";
      setThumbnail(null)
    }
    // else if (duration.target.duration > 30) {
    //   ValidationErrorToast("Video length exceeded", "Video length cannot be greater than 30 seconds");
    //   setVideoSrc("");
    //   vidRef.current.value = null;
    // }
    else {
      setVideoSrc(URL.createObjectURL(event?.target?.files[0]))
    };
    // return;
  };
  return (
    remoteUserData ?
      <>
        <div className="tab-pane fade show active" id="panel1" role="tabpanel" aria-labelledby="panel1-tab">
          <div className="container-fluid">
            <div className='title-row'>
              <h1>Update Profile</h1>
            </div>
            <div className="scroll-bar-dash-full margn-top38">
              <div className="row">
                <div className="col-xl-6">
                  <div className="blue-shadow-box mt-0 mb-3">
                    <h3>Personal Details</h3>
                    <div className="row margn-top26">
                      <div className="col-xl-12 col-md-12 col-sm-12 updateprofile-employ-design">
                        <div className="profile-pic-wrap mb-4">
                          <div className="profile-image">
                            <img src={
                              remoteUserData?.profilePhoto ||
                              UtilityFunctions.getImageURL() + "employee-profile-pic.svg"} />
                          </div>
                          <div className="upload-image">
                            <div className='update-image'>
                              {(selectedFile || preview) ?
                                <div className="crossed-icon after-image-upload">
                                  <img alt="Selected Image"
                                    className="image-show"
                                    src={UtilityFunctions.getImageURL() + "cross-icon.svg"}
                                    onClick={(e) => {
                                      setSelectedFile(null)
                                      setPreview(null)
                                    }} />
                                  <img alt="Preview" className="image-show" src={preview} />
                                </div> :
                                <span>
                                  <ImageCrop
                                    ratio={1 / 1}
                                    croppedImage={(imgSrc, file) => {
                                      setSelectedFile(file)
                                      setPreview(imgSrc);
                                    }}
                                    size={25}
                                  />
                                  <label htmlFor="image_id" style={{ cursor: "pointer" }}>
                                    {/* <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M9.82869 0.206923L9.94531 0.200195C10.4581 0.200195 10.8808 0.586236 10.9386 1.08357L10.9453 1.2002V8.2002H17.9453C18.4581 8.2002 18.8808 8.58624 18.9386 9.08357L18.9453 9.2002C18.9453 9.71303 18.5593 10.1357 18.0619 10.1935L17.9453 10.2002H10.9453V17.2002C10.9453 17.713 10.5593 18.1357 10.0619 18.1935L9.94531 18.2002C9.43248 18.2002 9.00981 17.8142 8.95204 17.3168L8.94531 17.2002V10.2002H1.94531C1.43248 10.2002 1.00981 9.81415 0.95204 9.31682L0.945312 9.2002C0.945312 8.68736 1.33135 8.26469 1.82869 8.20692L1.94531 8.2002H8.94531V1.2002C8.94531 0.687359 9.33135 0.264688 9.82869 0.206923L9.94531 0.200195L9.82869 0.206923Z" fill="#38BBF4">
                                      </path>
                                    </svg> */}
                                    <img src={UtilityFunctions.getImageURL() + "add_img.svg"} className="plus-icon" alt="" />
                                    <p>Update Image</p>
                                  </label>
                                </span>}
                            </div>
                          </div>
                        </div >
                        <div className="form-wrap2">
                          <div className=" mb-3">
                            <div
                              className={`input-group input-group-lg mb-3 ${errorState.email ? "error" : focusState.email ? "input-focus-profile" : ""}`}>
                              <span className="input-group-text" id="basic-addon1">
                                {/* <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M20 4.608V12.75C20 14.483 18.6435 15.8992 16.9344 15.9949L16.75 16H3.25C1.51697 16 0.100754 14.6435 0.00514483 12.9344L0 12.75V4.608L9.65199 9.66437C9.86995 9.77854 10.13 9.77854 10.348 9.66437L20 4.608ZM3.25 0H16.75C18.4347 0 19.8201 1.28191 19.9838 2.92355L10 8.15334L0.0161917 2.92355C0.173861 1.34271 1.46432 0.09545 3.06409 0.00522852L3.25 0H16.75H3.25Z" fill="white" />
                                </svg> */}
                                <img src={UtilityFunctions.getImageURL() + "mail-fill-gray.svg"} alt="" />
                                <img src={UtilityFunctions.getImageURL() + "mail-fill-blue.svg"} alt="" />
                              </span>
                              <input type="text"
                                onFocus={() => setFocusState((prev) => { return { ...prev, email: true } })}
                                onBlur={() => setFocusState((prev) => { return { ...prev, email: false } })}
                                className="form-control"
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                                ref={emailRef}
                                onChange={(e) => {
                                  setEmail(e.target.value)
                                  setErrorState(prev => { return { ...prev, email: false } })
                                }}
                                value={email}
                              />
                            </div>
                          </div>
                          <div className=" mb-3">
                            <div className="input-group input-group-lg mb-3 justify-content-between disabled">
                              <span>
                                <PhoneInput
                                  disabled
                                  country={'au'}
                                  placeholder="000 000 000"
                                  countryCodeEditable={false}
                                  masks={{ au: '... ... ....', in: '...........' }}
                                  enableAreaCodeStretch
                                  value={number}
                                />
                              </span>
                              <span
                                className="input-group-text tex-upbut "
                                id="basic-addon1"
                                onClick={() => setShowNumberModal(true)}
                              >
                                Update
                              </span>
                            </div >
                          </div>
                        </div>
                        <div className="form-wrap2">
                          <div className="mb-3">
                            <div className={`input-group input-group-lg mb-3
                            ${errorState.name ? "error" : focusState.name ? "input-focus-profile" : ""}`}
                            >
                              <span className="input-group-text" id="basic-addon1">
                                {/* <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M13.7545 11.9997C14.9966 11.9997 16.0034 13.0065 16.0034 14.2486V15.167C16.0034 15.7404 15.8242 16.2994 15.4908 16.7659C13.9449 18.9292 11.4206 20.0008 8.00036 20.0008C4.5794 20.0008 2.05643 18.9287 0.514274 16.7643C0.182312 16.2984 0.00390625 15.7406 0.00390625 15.1685V14.2486C0.00390625 13.0065 1.01076 11.9997 2.25278 11.9997H13.7545ZM8.00036 0.00439453C10.7618 0.00439453 13.0004 2.24297 13.0004 5.00439C13.0004 7.76582 10.7618 10.0044 8.00036 10.0044C5.23894 10.0044 3.00036 7.76582 3.00036 5.00439C3.00036 2.24297 5.23894 0.00439453 8.00036 0.00439453Z" fill="white" />
                                </svg> */}
                                <img src={UtilityFunctions.getImageURL() + "person-icon-dark-trans.svg"} alt="" />
                                <img src={UtilityFunctions.getImageURL() + "person-icon-dark-trans-checked.svg"} alt="" />
                              </span>
                              <input type="text"
                                onFocus={() => setFocusState((prev) => { return { ...prev, name: true } })}
                                onBlur={() => setFocusState((prev) => { return { ...prev, name: false } })}
                                className="form-control"
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                                ref={nameRef}
                                value={name}
                                onChange={e => {
                                  setErrorState(prev => { return { ...prev, name: false } })
                                  setName(e.target.value)
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-wrap mt-0 mb-0">
                          <div className="input-group input-group-lg mb-3 justify-content-between disabled">
                            <span className="input-group-text">
                              {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.5">
                                  <path d="M21 8.5V17.75C21 19.5449 19.5449 21 17.75 21H6.25C4.45507 21 3 19.5449 3 17.75V8.5H21ZM7.25 15C6.55964 15 6 15.5596 6 16.25C6 16.9404 6.55964 17.5 7.25 17.5C7.94036 17.5 8.5 16.9404 8.5 16.25C8.5 15.5596 7.94036 15 7.25 15ZM12 15C11.3096 15 10.75 15.5596 10.75 16.25C10.75 16.9404 11.3096 17.5 12 17.5C12.6904 17.5 13.25 16.9404 13.25 16.25C13.25 15.5596 12.6904 15 12 15ZM7.25 10.5C6.55964 10.5 6 11.0596 6 11.75C6 12.4404 6.55964 13 7.25 13C7.94036 13 8.5 12.4404 8.5 11.75C8.5 11.0596 7.94036 10.5 7.25 10.5ZM12 10.5C11.3096 10.5 10.75 11.0596 10.75 11.75C10.75 12.4404 11.3096 13 12 13C12.6904 13 13.25 12.4404 13.25 11.75C13.25 11.0596 12.6904 10.5 12 10.5ZM16.75 10.5C16.0596 10.5 15.5 11.0596 15.5 11.75C15.5 12.4404 16.0596 13 16.75 13C17.4404 13 18 12.4404 18 11.75C18 11.0596 17.4404 10.5 16.75 10.5ZM17.75 3C19.5449 3 21 4.45507 21 6.25V7H3V6.25C3 4.45507 4.45507 3 6.25 3H17.75Z" fill="white" />
                                </g>
                              </svg> */}
                              <img src={UtilityFunctions.getImageURL() + "calendar-fill-gray.svg"} alt="" />
                              <img src={UtilityFunctions.getImageURL() + "calender.svg"} alt="" />
                            </span>
                            <input type='text'
                              style={{ caretColor: 'transparent' }}
                              className='form-control'
                              placeholder="Start Date (optional)"
                              value={moment(remoteUserData?.dob).format("DD-MM-YYYY")}
                              disabled
                            />
                          </div>
                          <div className={`form-floating v-textarea-scorll mb-3 ${errorState.about ? "error" : ""}`} >
                            <textarea
                              className={`form-control my-textarea`}
                              placeholder="Enter About Me"
                              ref={aboutRef}
                              value={about}
                              onChange={e => {
                                setErrorState(prev => { return { ...prev, about: false } })
                                setAbout(e.target.value)
                              }
                              }
                            />
                            <label htmlFor="floatingInput ">Enter About Me</label>
                            <span className="input-group-text ">
                              <img src={UtilityFunctions.getImageURL() + "Edit-fill-gray.svg"} alt="" />
                              <img src={UtilityFunctions.getImageURL() + "Edit-fill-blue.svg"} alt="" />
                              {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.5">
                                  <path d="M13.94 4.99988L19.001 10.0609L9.06283 19.999C8.78596 20.2758 8.44162 20.4757 8.06386 20.5787L2.94817 21.9739C2.38829 22.1266 1.87456 21.6128 2.02726 21.053L3.42244 15.9373C3.52547 15.5595 3.7253 15.2152 4.00217 14.9383L13.94 4.99988ZM21.0312 2.96997C22.4286 4.36743 22.4286 6.63317 21.0312 8.03063L20.061 8.99988L15 3.93988L15.9705 2.96997C17.368 1.57251 19.6337 1.57251 21.0312 2.96997Z" fill="white" />
                                </g>
                              </svg> */}
                              {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g opacity="1">
                                  <path d="M13.94 4.99988L19.001 10.0609L9.06283 19.999C8.78596 20.2758 8.44162 20.4757 8.06386 20.5787L2.94817 21.9739C2.38829 22.1266 1.87456 21.6128 2.02726 21.053L3.42244 15.9373C3.52547 15.5595 3.7253 15.2152 4.00217 14.9383L13.94 4.99988ZM21.0312 2.96997C22.4286 4.36743 22.4286 6.63317 21.0312 8.03063L20.061 8.99988L15 3.93988L15.9705 2.96997C17.368 1.57251 19.6337 1.57251 21.0312 2.96997Z" fill="white" />
                                </g>
                              </svg> */}
                            </span>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6">
                  <div className="update-prodile-desi align-content-between d-grid">
                    <div className="blue-shadow-box mt-0 update-profile-employee mb-4">
                      <h3>Self Introductory Video</h3>
                      <div className="custom-file-upload">
                        {videoSrc ?
                          <>
                            <button className="mycrossbut" type="button" onClick={() => { vidRef.current.value = null; setVideoSrc(null); setFinalVidSrc(null); setThumbnail(null) }}>
                              {/* <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.89705 3.05379L2.96967 2.96967C3.23594 2.7034 3.6526 2.6792 3.94621 2.89705L4.03033 2.96967L7 5.939L9.96967 2.96967C10.2626 2.67678 10.7374 2.67678 11.0303 2.96967C11.3232 3.26256 11.3232 3.73744 11.0303 4.03033L8.061 7L11.0303 9.96967C11.2966 10.2359 11.3208 10.6526 11.1029 10.9462L11.0303 11.0303C10.7641 11.2966 10.3474 11.3208 10.0538 11.1029L9.96967 11.0303L7 8.061L4.03033 11.0303C3.73744 11.3232 3.26256 11.3232 2.96967 11.0303C2.67678 10.7374 2.67678 10.2626 2.96967 9.96967L5.939 7L2.96967 4.03033C2.7034 3.76406 2.6792 3.3474 2.89705 3.05379L2.96967 2.96967L2.89705 3.05379Z" fill="#100027" />
                              </svg> */}
                              <img src={UtilityFunctions.getImageURL() + "cross-white-bg-blue.svg"} alt="" />
                            </button>
                            <div className="videos_react_chet">
                              <video
                                src={videoSrc}
                                style={{
                                  display: "none"
                                }}
                                onLoadedMetadata={(data: any) => videoDurationValidator(data.target.duration)}
                              >
                              </video>
                              <ReactPlayer
                                controls={true}
                                width='100%'
                                height='100%'
                                light={
                                  thumbnail ?
                                    <img className="video-thumbnail"
                                      onClick={() => setPlayVideo(true)} src={thumbnail || null} alt='' />
                                    : false}
                                // ref={viewRef}
                                // playing={playVideo}
                                playing
                                playIcon={<img className="play-icon" src={UtilityFunctions.getImageURL() + "play-video-button.svg"} alt='' />}
                                url={finalVidSrc}
                              />
                            </div>
                          </> :
                          <label htmlFor='video_id' style={{ cursor: "pointer" }} >
                            {/* <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M9.82869 0.206923L9.94531 0.200195C10.4581 0.200195 10.8808 0.586236 10.9386 1.08357L10.9453 1.2002V8.2002H17.9453C18.4581 8.2002 18.8808 8.58624 18.9386 9.08357L18.9453 9.2002C18.9453 9.71303 18.5593 10.1357 18.0619 10.1935L17.9453 10.2002H10.9453V17.2002C10.9453 17.713 10.5593 18.1357 10.0619 18.1935L9.94531 18.2002C9.43248 18.2002 9.00981 17.8142 8.95204 17.3168L8.94531 17.2002V10.2002H1.94531C1.43248 10.2002 1.00981 9.81415 0.95204 9.31682L0.945312 9.2002C0.945312 8.68736 1.33135 8.26469 1.82869 8.20692L1.94531 8.2002H8.94531V1.2002C8.94531 0.687359 9.33135 0.264688 9.82869 0.206923L9.94531 0.200195L9.82869 0.206923Z" fill="#38BBF4" />
                            </svg> */}
                            <img src={UtilityFunctions.getImageURL() + "add_img.svg"} alt="" />
                            <p>Upload Video</p>
                          </label>
                        }
                        <input
                          type="file"
                          ref={vidRef}
                          style={{ display: "none" }}
                          id="video_id"
                          max="150mb"
                          accept=".mov, .mp4"
                          onChange={(e) => {
                            handleChange(e)
                          }} />
                      </div>
                    </div>
                    <div className="blue-shadow-box mt-0 update-profile-employee">
                      <h3>Job Preference</h3>
                      <div className="row margn-top26">
                        <ul className="right-side-lis list-unstyled m-0 p-0">
                          <li className="d-flex justify-content-between align-items-center px-3">
                            <b className="text-white">Industry</b>
                            <span className="d-flex align-items-center" style={{ cursor: "pointer" }} onClick={() => props.history.push({
                              pathname: STRING_CONSTANTS.navigationString.employee_profile_completion,
                              state: { step: 3, updateProfile: true }
                            })}>
                              {categories?.map(category => category?.id === remoteUserData?.category[0] ? category?.name : "")}
                              <img src={UtilityFunctions.getImageURL() + "arrow_right-small-gray.svg"} alt="" />
                              {/* <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.4">
                                  <path d="M5.73966 12.7996C5.43613 12.5177 5.41855 12.0432 5.70041 11.7397L9.22652 8L5.70041 4.26033C5.41855 3.9568 5.43613 3.48225 5.73966 3.2004C6.0432 2.91855 6.51774 2.93612 6.7996 3.23966L10.7996 7.48966C11.0668 7.77742 11.0668 8.22257 10.7996 8.51034L6.79959 12.7603C6.51774 13.0639 6.04319 13.0814 5.73966 12.7996Z" fill="white" />
                                </g>
                              </svg> */}
                            </span>
                          </li>
                          <li className="d-flex justify-content-between align-items-center px-3">
                            <b className="text-white">Location</b>
                            <span className="d-flex align-items-center" style={{ cursor: "pointer" }} onClick={() => props.history.push({
                              pathname: STRING_CONSTANTS.navigationString.employee_profile_completion,
                              state: { step: 5, updateProfile: true }
                            })}>
                              {remoteUserData?.location?.address}
                              {/* <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g opacity="0.4"><path d="M5.73966 12.7996C5.43613 12.5177 5.41855 12.0432 5.70041 11.7397L9.22652 8L5.70041 4.26033C5.41855 3.9568 5.43613 3.48225 5.73966 3.2004C6.0432 2.91855 6.51774 2.93612 6.7996 3.23966L10.7996 7.48966C11.0668 7.77742 11.0668 8.22257 10.7996 8.51034L6.79959 12.7603C6.51774 13.0639 6.04319 13.0814 5.73966 12.7996Z" fill="white" /></g></svg> */}
                              <img src={UtilityFunctions.getImageURL() + "arrow_right-small-gray.svg"} alt="" />
                            </span>
                          </li>
                          <li className="d-flex justify-content-between align-items-center px-3">
                            <b className="text-white">Experience</b>
                            <span className="d-flex align-items-center" style={{ cursor: "pointer" }} onClick={() => props.history.push({
                              pathname: STRING_CONSTANTS.navigationString.employee_profile_completion,
                              state: { step: 6, updateProfile: true }
                            })}>
                              {UtilityFunctions.convertMonthsToYearsAndMonths(remoteUserData?.experience)}
                              {/* {experienceToDisplay(remoteUserData?.experience)[0] >= 1 && `${experienceToDisplay(remoteUserData?.experience)[0]}`}
                              {experienceToDisplay(remoteUserData?.experience)[0] === 1 ? " Year" : experienceToDisplay(remoteUserData?.experience)[0] > 1 ? " Years" : ""}
                              &nbsp;
                              {experienceToDisplay(remoteUserData?.experience)[1] >= 1 ? `${experienceToDisplay(remoteUserData?.experience)[1]}` :
                                (experienceToDisplay(remoteUserData?.experience)[1] === 0 && experienceToDisplay(remoteUserData?.experience)[0] === 0) && <span>0</span>}
                              {experienceToDisplay(remoteUserData?.experience)[1] > 1 ? " Months" : experienceToDisplay(remoteUserData?.experience)[1] === 1 ? " Month" :
                                (experienceToDisplay(remoteUserData?.experience)[1] === 0 && experienceToDisplay(remoteUserData?.experience)[0] === 0) && " Month"} */}
                              <img src={UtilityFunctions.getImageURL() + "arrow_right-small-gray.svg"} alt="" />
                              {/* <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.4"><path d="M5.73966 12.7996C5.43613 12.5177 5.41855 12.0432 5.70041 11.7397L9.22652 8L5.70041 4.26033C5.41855 3.9568 5.43613 3.48225 5.73966 3.2004C6.0432 2.91855 6.51774 2.93612 6.7996 3.23966L10.7996 7.48966C11.0668 7.77742 11.0668 8.22257 10.7996 8.51034L6.79959 12.7603C6.51774 13.0639 6.04319 13.0814 5.73966 12.7996Z" fill="white" /></g>
                              </svg> */}
                            </span>
                          </li>
                          <li className="d-flex justify-content-between align-items-center px-3">
                            <b className="text-white">Expected Salary</b>
                            <span className="d-flex align-items-center " style={{ cursor: "pointer" }} onClick={() => props.history.push({
                              pathname: STRING_CONSTANTS.navigationString.employee_profile_completion,
                              state: { step: 7, updateProfile: true }
                            })}>{remoteUserData?.jobType === 'fullTime' ? 'Full Time' : remoteUserData?.jobType === 'partTime' ? 'Part Time' : remoteUserData?.jobType === 'casual' ? 'Casual' : 'None'}
                              &nbsp;
                              {"$" + (remoteUserData?.minSalary >= 1000 ? Math.round((remoteUserData?.minSalary * 10) / 1000) / 10 + "k" : Math.round(remoteUserData?.minSalary)) + ((+remoteUserData?.maxSalary > +remoteUserData?.minSalary) ? ('-$' + (remoteUserData?.maxSalary > 1000 ? Math.round((remoteUserData?.maxSalary * 10) / 1000) / 10 + "k" : Math.round(remoteUserData?.maxSalary))) : "")}
                              {remoteUserData?.salaryCheckoutType ?
                                (remoteUserData?.salaryCheckoutType === "yearly" && "/yr" ||
                                  remoteUserData?.salaryCheckoutType === "monthly" && "/mo" ||
                                  remoteUserData?.salaryCheckoutType === "weekly" && "/wk" ||
                                  remoteUserData?.salaryCheckoutType === "daily" && "/daily" ||
                                  remoteUserData?.salaryCheckoutType === "hourly" && "/hr") :
                                ""
                              }
                              <img src={UtilityFunctions.getImageURL() + "arrow_right-small-gray.svg"} alt="" />
                              {/* <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.4">
                                  <path d="M5.73966 12.7996C5.43613 12.5177 5.41855 12.0432 5.70041 11.7397L9.22652 8L5.70041 4.26033C5.41855 3.9568 5.43613 3.48225 5.73966 3.2004C6.0432 2.91855 6.51774 2.93612 6.7996 3.23966L10.7996 7.48966C11.0668 7.77742 11.0668 8.22257 10.7996 8.51034L6.79959 12.7603C6.51774 13.0639 6.04319 13.0814 5.73966 12.7996Z" fill="white" />
                                </g>
                              </svg> */}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="btnfgo-wrap justify-content-end active mb-0 mt-4">
                      <button className="w-100 mw-100" onClick={updateDetector}>
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <UpdateEmailNumber
          show={showPwdModal}
          handleClose={() => setShowPwdModal(false)}
          setPwd={setPwd}
          pwd={pwd}
          updateHandler={profileUpdater}
        />
        <UpdateNumber
          show={showNumberModal}
          handleClose={() => setShowNumberModal(false)}
        />
      </>
      : <div></div>
  )
}

export default withRouter(UpdateProfileEmployee)