import { api } from "../../middleware/api";

const mploiAI = async ( payload ) => {
    try {
      const response = await api.post(`ai/`, payload)
      return response.data;
    } catch (err) {
      return err;
    }
  };

  export {mploiAI}
