import React from 'react';
import './ContactForm.css';

const ContactForm = () => {
  return (
    <div className="contact-container">
      <div className="form-section">
        <div className='title'>Contact Us</div>
        <div className='description'>Enter your information and enquiry below</div>
        <form>
          <div className="input-group">
            <label htmlFor="fullname">Fullname</label>
            <input type="text" id="fullname" placeholder="James Jones" />
          </div>
          <div className="input-group">
            <label htmlFor="email">Email Address</label>
            <input type="email" id="email" placeholder="james@example.com" />
          </div>
          <div className="input-group">
            <label htmlFor="message">Message</label>
            <textarea id="message" placeholder="Type message"></textarea>
          </div>
          <button type="submit" className="submit-btn">
            Submit <img src="/assets/images/arrow-up-left-black.svg" alt="" />
          </button>
        </form>
      </div>
      <div className="image-section">
        <img
          src="/assets/images/contact-form.png" 
          alt="contact form"
        />
      </div>
    </div>
  );
};

export default ContactForm;
