import React, { useEffect } from 'react'
import ContactForm from '../../components/ContactForm/ContactForm'
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
        <Header />
        <ContactForm />
        <Footer /> 
   </div>
  )
}

export default Contact