import React from "react";
import { UtilityFunctions } from "../../src/utils/UtilityFunctions";
import { Button, Modal } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { cookieService, userService } from "../_services";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../store";
import { STRING_CONSTANTS } from "../utils/constants/stringConstants";
import { checkData } from "../utils/checkData";

const ChangePlan = (props) => {
  const dispatch = useDispatch();
  const { resetState } = bindActionCreators(actionCreators, dispatch);
  return (
    <Modal
      className="bg-transparent upgrade-popup logout-popup"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={props.show}
      onHide={props.handleClose}
    >
      <Modal.Body>
        <img
          alt="logout"
          src={UtilityFunctions.getImageURL() + "arrow_up.svg"}
        />
        <h3 className="py-2">Change your plan?</h3>
        <p className="py-3">
          Please confirm that you are changing your subscription plan.
        </p>
        <div>
          <Button className='ms-4 border-0 bg-transparent cancel-button' variant="primary" onClick={props.handleClose}>
            Cancel
          </Button>
          <Button
            className="ms-4 border-0"
            variant="primary"
            // onClick={props.childRef.current?.handleSubmit()}
            onClick={props.handleSubmit}
          >
            Confirm
          </Button>
        </div>
      </Modal.Body>
    </Modal>

  )
}
export default withRouter(ChangePlan);