import React from 'react';
import { UtilityFunctions } from '../../../utils/UtilityFunctions';

const MapClusterBox = (props) => {
  return (
    <div>
      <div className="control-panel-mobile">
        {props?.clustArray && props?.clustArray.length > 0 &&
          props?.clustArray?.map((clus) => (
            <div className='mob-card' onClick={() => {
              if (clus?.properties?.data?.userId) {
                props.setShowSidebar(true)
                props.setSelectedUserId(clus?.properties?.data?.userId)
              }
            }}>
              <div className='ep-row'>
                <div className='col-auto'>
                  <img src={clus?.properties?.data?.image ? clus?.properties?.data?.image : UtilityFunctions.getImageURL() + "user-bg-dark.png"} className='dp' />
                </div>
                <div style={{ width: 180 }}>
                  <div className='name'>{clus?.properties?.data?.name}</div>
                  <div className='role'>{clus?.properties?.data?.role}</div>
                </div>
                <div style={{ width: 55 }}>
                  <div className='dist-box'>
                    <div className='dist'>{parseFloat(clus?.properties?.data?.distance).toFixed(2)}</div>
                    <div className='kms'>kms Away</div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>

      <div className="control-panel d-none d-md-block">
        {props?.clustArray && props.clustArray.length > 0 ?
          props?.clustArray?.map((clus) => (
            <div className='ep-row' onClick={() => {
              if (clus?.properties?.data?.userId) {
                props.setShowSidebar(true)
                props.setSelectedUserId(clus?.properties?.data?.userId)
              }
            }}>
              <div className='col-md-auto p-0'>
                <img src={clus?.properties?.data?.image ? clus?.properties?.data?.image : UtilityFunctions.getImageURL() + "user-bg-dark.png"} className='dp' />
              </div>
              <div className='col-md-7' style={{ paddingLeft: 10 }}>
                <div className='name'>{clus?.properties?.data?.name}</div>
                <div className='role'>{clus?.properties?.data?.role}</div>
              </div>
              <div className='col-md-auto p-0'>
                <div className='dist-box'>
                  <div className='dist'>{parseInt(clus?.properties?.data?.distance)}</div>
                  <div className='kms'>kms Away</div>
                </div>
              </div>
            </div>
          ))
          :
          <div className='content'>
            <img src='/assets/images/map.png' className='center-img' />
            <div className='text'>Select Cluster to view potential Emploiess</div>
          </div>
        }
      </div>
    </div>
  );
}

export default MapClusterBox;
