// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.explore-container {
    padding: 50px 100px;
    background-color: #140b21;
    overflow: hidden;
}

.explore-card-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.explore-container .title {
    color: #fff;
    font-size: 42px;
    font-style: normal;
    font-weight: 700;
    line-height: 68px;
    margin-bottom: 40px;
    font-family: "Inter", sans-serif;
    text-align: left;
}

.explore-container .slider-container {
    width: 100%;
    display: block;
}

.explore-container .slick-prev:before {
    display: none;
}

.explore-container .slick-next:before {
    display: none;
}

.explore-container .slick-prev {
    z-index: 1;
    top: -89px;
    right: 130px;
    left: unset;
}

.explore-container .slick-next {
    z-index: 1;
    top: -89px;
    right: 32px;
}

.explore-container .slick-list {
    overflow: visible;
}

@media(max-width:768px) {
    .explore-card-wrap {
        display: none;
    }

    .explore-container {
        padding: 30px;
    }

    .explore-container .title {
        margin-bottom: 10px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Explore/Explore.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,yBAAyB;IACzB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,uBAAuB;IACvB,SAAS;AACb;;AAEA;IACI,WAAW;IACX,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;IACnB,gCAAgC;IAChC,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,cAAc;AAClB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,UAAU;IACV,UAAU;IACV,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,UAAU;IACV,UAAU;IACV,WAAW;AACf;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI;QACI,aAAa;IACjB;;IAEA;QACI,aAAa;IACjB;;IAEA;QACI,mBAAmB;IACvB;AACJ","sourcesContent":[".explore-container {\n    padding: 50px 100px;\n    background-color: #140b21;\n    overflow: hidden;\n}\n\n.explore-card-wrap {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: center;\n    gap: 20px;\n}\n\n.explore-container .title {\n    color: #fff;\n    font-size: 42px;\n    font-style: normal;\n    font-weight: 700;\n    line-height: 68px;\n    margin-bottom: 40px;\n    font-family: \"Inter\", sans-serif;\n    text-align: left;\n}\n\n.explore-container .slider-container {\n    width: 100%;\n    display: block;\n}\n\n.explore-container .slick-prev:before {\n    display: none;\n}\n\n.explore-container .slick-next:before {\n    display: none;\n}\n\n.explore-container .slick-prev {\n    z-index: 1;\n    top: -89px;\n    right: 130px;\n    left: unset;\n}\n\n.explore-container .slick-next {\n    z-index: 1;\n    top: -89px;\n    right: 32px;\n}\n\n.explore-container .slick-list {\n    overflow: visible;\n}\n\n@media(max-width:768px) {\n    .explore-card-wrap {\n        display: none;\n    }\n\n    .explore-container {\n        padding: 30px;\n    }\n\n    .explore-container .title {\n        margin-bottom: 10px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
