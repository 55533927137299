import { Action } from "../actions/index";
import { ActionType } from "../constants";
import { get } from "lodash";

const initialState = [];

const reducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.SET_EMPLOYEES_LIST: {
      state = get(action, "item");
      return state;
    }
    case ActionType.SET_REMOVE_EMPLOYEE: {
      //if there's an employee list, then remove an specific employee from that list, otherwise do nothing
      state.length &&
        (state = state.filter(
          (employee) => employee.id !== get(action, "item")["id"]
        ));
      return state;
    }
    default:
      return state;
  }
};

export default reducer;
