import React from "react";
import firebase from "firebase/app";
import "firebase/messaging";
import { api } from "../middleware/api";
import { cookieService } from "../_services";
import { STRING_CONSTANTS } from "../utils/constants/stringConstants";
import { firebaseConfig } from "../utils/UtilityFunctions";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { actionCreators, State } from "../store";
import { Data } from "../_services/collections";
import { PUSH_NOTIFICATION_TYPES } from "../utils/notificationTypes";
var messaging: any = null;

const FCM = (props) => {
    const dispatch = useDispatch();
    const { setUnreadNotificationCount } = bindActionCreators(
        actionCreators,
        dispatch
    );
    const initFirebase = () => {
        if ("serviceWorker" in navigator) {
            navigator.serviceWorker
                .register("./firebase-messaging-sw.js")
                .then((registration) => { })
                .catch((err) => { });
            //   navigator.serviceWorker.addEventListener("message", ({ data }) => {
            //     console.warn("initFirebase", data);
            //     const payload = data?.firebaseMessaging?.payload;
            //     setUnreadNotificationCount(parseInt(payload?.data?.badge_count));
            //     if (data?.firebaseMessaging?.type === "notification-clicked") {
            //       window.open("/dashboard/settings", "_self");
            //     } else {
            //       createNotification(payload);
            //     }
            //   });
        }

        if (firebase?.messaging?.isSupported()) {
            //   if (!firebase?.apps?.length) {
            firebase.initializeApp(firebaseConfig);
            //   }
            messaging = firebase.messaging();
            Notification.requestPermission()
                .then(async () => {
                    const token = await messaging.getToken();
                    const stored_token = cookieService.getCookie("fcm");
                    // save device token in local storage
                    // if (stored_token !== token) {
                    cookieService.saveCookie("fcm", token);
                    // }
                    if (cookieService.getCookie(STRING_CONSTANTS.cookieStrings.token)) {
                        regDeviceToken();
                    }
                })
                .catch(() => {
                    //console.log('firebase notifications are not allowed by u...')
                });
            messaging.onMessage((payload) => {
                createNotification(payload);
            });
        } else {
            // ValidationErrorToast("Registration Failed", "Firebase not supported",)
        }
    };

    const regDeviceToken = () => {
        if (cookieService.getCookie("fcm")) {
            api
                .post("device-token/", {
                    deviceType: "WEB",
                    user: `${(
                        cookieService.getCookie(
                            STRING_CONSTANTS.cookieStrings.user
                        ) as Data
                    ).user?.id
                        }`,
                    token: `${cookieService.getCookie("fcm")}`,
                })
                .then()
                .catch();
        }
    };
    const updateUnreadCount = (id: string) => {
        api
            .get(`user-notifications/${id}/`, null)
            .then((res) =>
                api
                    .get("user-notifications/?limit=1", null)
                    .then((res) =>
                        setUnreadNotificationCount(res?.results?.unreadNotificationCount)
                    )
            )
            .catch
            // e => console.log(e)
            ();
    };
    const createNotification = (payload: any) => {
        console.warn(payload, "createnotification");
        const title = payload?.notification?.title;
        const options = {
            body: payload?.notification?.body,
        };
        let notification = new Notification(title, options);
        notification.onclick = (event) => {
            window?.parent?.focus();
            window?.focus();
            event?.preventDefault();
            resolveNotification(payload?.data?.notification_type, payload?.data);
            // notification?.close();
            updateUnreadCount(payload?.data?.id);
        };
    };

    const resolveNotification = (type: any, payload: any) => {
        switch (type) {
            case PUSH_NOTIFICATION_TYPES.terms:
                window.open("/dashboard/settings?activeTab=terms", "_self");
                break;
            case PUSH_NOTIFICATION_TYPES.privacy:
                window.open("/dashboard/settings?activeTab=privacy", "_self");
                break;
            case PUSH_NOTIFICATION_TYPES.user_verified:
                window.open("/dashboard/settings", "_self");
                break;
            case PUSH_NOTIFICATION_TYPES.guardian_rejected:
                window.open("/dashboard/settings", "_self");
                break;
            case PUSH_NOTIFICATION_TYPES.job_cancelled:
                window.open("/dashboard/notifications", "_self");
                break;
            case PUSH_NOTIFICATION_TYPES.job_allocated:
                window.open("/dashboard/notifications", "_self");
                break;
            case PUSH_NOTIFICATION_TYPES.advance_profile:
                window.open("/dashboard/settings", "_self");
                break;
            case PUSH_NOTIFICATION_TYPES.chat_request_sent:
                window.open("/dashboard/notifications", "_self");
                break;
            case PUSH_NOTIFICATION_TYPES.chat_request_recieved:
                window.open("/dashboard/notifications", "_self");
                break;
            case PUSH_NOTIFICATION_TYPES.chat_request_accepted:
                window.open(`/dashboard/chat?chatid=${payload?.data_id}`, "_self");
                break;
            case PUSH_NOTIFICATION_TYPES.chat_request_rejected:
                window.open("/dashboard/notifications", "_self");
                break;
            case PUSH_NOTIFICATION_TYPES.chat_message_received:
                window.open(`/dashboard/chat?chatid=${payload?.data_id}`, "_self");
                break;
        }
    };

    React.useEffect(initFirebase, []);

    return null;
};

export default FCM;