import React from 'react';
import './Banner.css';
import { UtilityFunctions, } from "../../utils/UtilityFunctions";


function Banner() {
  return (
    <div className="container-fluid banner-wrap">
      <div className="row">
        <div className="col-lg-7 col-md-12 banner-content">
          <div className="title">
            <span>Bridging the gap</span> between great employers & phenomenal candidates with AI.
          </div>
          <div className="description">
            MPLOI aims to connect talented individuals with their perfect roles and assist employers in finding their next star team member.
          </div>
          <div className="sub-description">We started out as a jobs platform...But now we are so much more!</div>
          <div className='mob-banner-img'>
            <img src='/assets/images/about-banner-mob.png' className='img-fluid' alt=''/>
          </div>
          <div className="banner-buttons d-flex flex-column flex-md-row gap-2 mt-4">
           <a href="/signup"><button className="btn-dark">Find a Job <img src='/assets/images/search-icon-btn.png' alt=''/></button></a> 
          <a href="/signup"><button className="btn-light">Find Staff Members <img src='/assets/images/member-icon-btn.png' alt=''/></button></a>  
          </div>
          <div className='testimonial-card'>
            <div className='testimonial-quote'> <img src='/assets/images/testimonial-quote.png' alt=''/></div>
            <div className='title'>
              <img src='/assets/images/testimonial-img.png' alt='' />
            “I landed the job!”
            </div>
            <div className='description'>Interview Master AI was a game-changer for me. The detailed feedback helped me refine my responses and I went into my actual interview feeling prepared and confident.</div>
            <div className='client-name'>Marcus S</div>
          </div>
        </div>
        <div className="col-lg-5 col-md-12 banner-right-img">
        
        <img src="/assets/images/banner-mokup.png" alt=''  />
        </div>
      </div>
    </div>
  );
}

export default Banner;
