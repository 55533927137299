import React, { Fragment, useState, useEffect } from "react";
import { UtilityFunctions } from "../../utils/UtilityFunctions";
import { STRING_CONSTANTS } from "../../utils/constants/stringConstants";
import { userService, cookieService } from "../../_services";
import ToastHeader from "../../components/toastHeader";
import ValidationErrorToast from "../../components/validationErrorToast";

import Signup from "./Signup";
import VerifyEmail from "./VerifyEmail";
import VerifyMobile from "./VerifyMobile";
import EnterOTP from "./EnterOTP";
import AccountVerified from "./AccountVerified";
import AddCardDetails from "./addCardDetails_6";
import SelectPlan from "./selectPlan_5";
import { api } from "../../middleware/api";
import { END_POINTS } from "../../utils/constants/endPoints";
import SuccessToast from "../../components/successToast";
import { includes, get } from "lodash";
import { Data } from "../../_services/collections";
import EmployerSignup from "./EmployerSignup";
import { useSelector } from "react-redux";
import { State } from "../../store";

const SignUp = (props: any) => {
  const state = useSelector((state: State) => state);
  const index = cookieService.getCookie(STRING_CONSTANTS.cookieStrings.user) ? -1 : 0;
  const [currentStep, setCurrentStep] = useState(index);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [otp, setOtp] = useState({ otp: "", token: "" });
  const [startTimer1, setStartTimer1] = useState(false);
  const [startTimer2, setStartTimer2] = useState(false);
  const [userData, setUserData] = useState({
    isTermsConditionAccepted: false,
    isPrivacyPolicyAccepted: false,
    isMobileVerified: false,
    // isStripeSubscriptionActive: false,
    sendOffers: false
  });
  // REGISTERS FCM TOKEN WITH THE BACKEND FOR PUSH NOTIFICATIONS 
  const regDeviceToken = () => {
    api.post('device-token/', {
      "deviceType": "WEB",
      "user": `${(cookieService.getCookie(STRING_CONSTANTS.cookieStrings.user) as Data).user.id}`,
      "token": `${cookieService.getCookie('fcm')}`
    }).then(
    )
      .catch(
    )
  }
  useEffect(() => {
    props?.location?.state?.token && setOtp(prev => { return { ...prev, token: props?.location?.state?.token } })
  }, [props])

  useEffect(() => {
    props?.step && setCurrentStep(props?.step)
  }, [props.step])

  useEffect(() => {
    if (props?.location?.state?.step !== undefined) {
      setCurrentStep(parseInt(props?.location?.state?.step))
    }
  }, [props])

  useEffect(() => {
    if (props?.location?.state?.updateNumber) {
      setCurrentStep(4)
    }
    else if (cookieService.getCookie(STRING_CONSTANTS.cookieStrings.token)) {
      api.get(END_POINTS.user, null).then(res => {
        setUserData({
          isTermsConditionAccepted: get(res, 'user.isTermsConditionAccepted', false),
          isPrivacyPolicyAccepted: get(res, 'user.isPrivacyPolicyAccepted', false),
          isMobileVerified: get(res, 'user.isMobileVerified', false),
          // isStripeSubscriptionActive: get(res, 'user.isStripeSubscriptionActive', false),
          sendOffers: get(res, 'user.sendOffers', false)
        });
        // console.log(res.user)
        if (!get(res, 'user.isMobileVerified', false) && get(res, 'user.isTermsConditionAccepted', false) && get(res, 'user.isPrivacyPolicyAccepted', false)) {
          setCurrentStep(3)
        }
        // else if (!get(res, 'user.isStripeSubscriptionActive', false) && get(res, 'user.isMobileVerified', false)) {
        //   setCurrentStep(5)
        // }
        else if (get(res, 'user.isStripeSubscriptionActive', true) && get(res, 'user.sendOffers', null)) {
          setCurrentStep(7)
        }
        else props.history.push(STRING_CONSTANTS.navigationString.employer_profile_completion)
        cookieService.saveCookie(STRING_CONSTANTS.cookieStrings.user, res);
      });
    }
  }, []);

  // Step 2
  const resend = () => {
    let isEmailVerified: boolean;
    api.get(END_POINTS.user, {})
      .then(res => isEmailVerified = (res.user.isEmailVerified))
      .then(() => {
        if (isEmailVerified === false) {
          userService
            .resendMail()
            .then(() => { })
            .catch(
          )
          SuccessToast(STRING_CONSTANTS.SUCCESS_MESSAGES.SUCCESS_HEADER, STRING_CONSTANTS.SUCCESS_MESSAGES.RESEND_VERIFICATION_EMAIL);
          setStartTimer1(true);
        }
        else {
          setTimeout(() => {
            props.history.push(STRING_CONSTANTS.navigationString.index)
          }, 2000)
          SuccessToast(STRING_CONSTANTS.SUCCESS_MESSAGES.SUCCESS_HEADER, STRING_CONSTANTS.SUCCESS_MESSAGES.EMAIL_VERIFIED)

        }
      })
      .catch(
    )


  };
  // step 4
  const resendOtp = () => {
    console.log("index sendOTP called")
    userService
      .sendOtp()
      .then((res) => {
        setOtp((prev) => { return { ...prev, token: res?.["token"] } })
        SuccessToast(STRING_CONSTANTS.SUCCESS_MESSAGES.SUCCESS_HEADER, STRING_CONSTANTS.SUCCESS_MESSAGES.OTP_SENT_MESSAGE);
        setStartTimer2(true);
      })
      .catch((err) => {
        ToastHeader(err);
        return err;
      });
  };

  const _next = () => {
    if (currentStep === 1) {
      // Step 1
      if (UtilityFunctions.isEmptyOrNullOrUndefined(email)) {
        ValidationErrorToast(STRING_CONSTANTS.formLabelString.label_toast_email, STRING_CONSTANTS.validationString.req_email);
      }
      else if (!UtilityFunctions.validateEmail(email)) {
        ValidationErrorToast(STRING_CONSTANTS.formLabelString.label_toast_email, STRING_CONSTANTS.validationString.req_email_incorrect);
      }
      else if (UtilityFunctions.isEmptyOrNullOrUndefined(password)) {
        ValidationErrorToast(STRING_CONSTANTS.formLabelString.label_password, STRING_CONSTANTS.validationString.req_password);
      }
      else if (!UtilityFunctions.validatePassword(password)) {
        ValidationErrorToast(STRING_CONSTANTS.formLabelString.label_password, STRING_CONSTANTS.validationString.req_password_notvalid);
      }
      else if (UtilityFunctions.checkSpaceInPassword(password)) {
        ValidationErrorToast(STRING_CONSTANTS.formLabelString.label_password, STRING_CONSTANTS.validationString.white_space_in_password);
      }
      else {
        if (!(email && password)) {
          return;
        }
        const params = {
          email: email,
          password: password,
          type: props?.history?.location?.state?.userType,
          isTermsConditionAccepted: true,
          isPrivacyPolicyAccepted: true,
        };
        userService
          .signup(params, state.referralCode)
          .then((res) => {
            let response = res?.data?.user?.token?.access
            setCurrentStep(currentStep + 1);
            cookieService.saveCookie(STRING_CONSTANTS.cookieStrings.token, response);
            cookieService.saveCookie(STRING_CONSTANTS.cookieStrings.user, res.data);
            // cookieService.saveCookie(STRING_CONSTANTS.cookieStrings.apiError, "false")
            api.get("category/", null)
              .then(res => cookieService.saveCookie(STRING_CONSTANTS.cookieStrings.jobCategories, res))
            if (cookieService.getCookie('fcm')) {
              regDeviceToken()
            }
          })
          .catch((err: any) => {
            ToastHeader(err);
            return err;
          });

        return;
      }
    }
    else if (includes([2], currentStep)) {
      setCurrentStep(currentStep + 1);
      return;
    }
    else if (includes([5, 6, 3], currentStep)) {
      api.get(END_POINTS.user, null).then(res =>
        cookieService.saveCookie(STRING_CONSTANTS.cookieStrings.user, res)
      );
      setCurrentStep(currentStep + 1);
    }
    else if (currentStep === 4) {
      // Step 4
      if (UtilityFunctions.validateOtp(otp?.otp?.length)) {
        ValidationErrorToast(STRING_CONSTANTS.formLabelString.label_otp, STRING_CONSTANTS.validationString.req_otp);
      }
      else {
        const params = {
          otp: parseInt(otp?.otp),
          token: otp?.token
        };
        userService
          .verifyOtp(params)
          .then((response) => {
            if (props?.location?.state?.updateNumber) {
              props.history.push(STRING_CONSTANTS.navigationString.dashboard.settings, { tab: "updateProfile" })
              SuccessToast("Mobile Number", "Mobile Number has been successfully updated")
            }
            else setCurrentStep(7);

          }).then(() => {
            api.get(END_POINTS.user, {})
              .then(res => cookieService.saveCookie(STRING_CONSTANTS.cookieStrings.user, res))
              .catch(
              // (e) => console.log(e)
            )
          })
          .catch((err) => {
            ToastHeader(err);
            return err;
          });
        return;
      }
    }
    // else console.log("RETURN");
  };

  const prev = () => {
    setCurrentStep(currentStep - 1);
  };

  return (
    <Fragment>
      <section className={`main-signup-wrap ${currentStep === 5 ? "select-plan" : ""}`}>
        <form noValidate
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >{
            currentStep == 0 &&
            <Signup
              props={props}
              currentStep={currentStep}
              setEmail={setEmail}
              setPassword={setPassword}
              nextButton={_next}
              password={password}
              email={email}
            />
          }
          {
            currentStep == 1 &&
            <EmployerSignup
              currentStep={currentStep}
              setEmail={setEmail}
              setPassword={setPassword}
              nextButton={_next}
              password={password}
              email={email}
            />
          }
          {
            currentStep == 2 &&
            <VerifyEmail
              currentStep={currentStep}
              resend={resend}
              nextButton={_next}
              previousButton={prev}
              startTimer1={startTimer1}
              setStartTimer1={setStartTimer1}
            />

          }
          {
            currentStep == 3 &&
            <VerifyMobile
              currentStep={currentStep}
              setOtp={setOtp}
              setMobileNumber={setMobileNumber}
              nextButton={_next}
              previousButton={prev}
            />
          }
          {
            currentStep == 4 &&
            <EnterOTP
              updateNumber={props?.location?.state?.updateNumber}
              currentStep={currentStep}
              setOtp={setOtp}
              resendOtp={resendOtp}
              nextButton={_next}
              previousButton={prev}
              startTimer2={startTimer2}
              setStartTimer2={setStartTimer2}
            />
          }
          {
            currentStep == 5 &&
            <SelectPlan
              currentStep={currentStep}
              nextButton={_next}
              previousButton={prev} />
          }
          {
            currentStep == 6 &&
            <AddCardDetails
              setCurrentStep={setCurrentStep}
              currentStep={currentStep}
              nextButton={_next}
              previousButton={prev} />
          }
          {
            currentStep == 7 &&
            <AccountVerified
              currentStep={currentStep}
            />
          }
        </form>
        {/* {previousButton()} */}
        {/* {nextButton()} */}
        {/* {state.currentStep === 5 && (
          <button className="btn btn-primary float-right">
            {STRING_CONSTANTS.signupPages.buttons.submit}
          </button>
        )} */}
      </section>
    </Fragment>
  );
};

export default SignUp;
