import React, { useEffect, useState } from "react"
import { withRouter } from "react-router-dom"
import { UtilityFunctions } from "../../../utils/UtilityFunctions";
import { api } from "../../../middleware/api";
import { STRING_CONSTANTS } from "../../../utils/constants/stringConstants";
import { Button } from "react-bootstrap";
import ValidationErrorToast from "../../../components/validationErrorToast";

const CancelSubscription = (props: any) => {


    const [confirmSubModel, setConfirmSubModel] = useState(false);

    const handleCancelSubscription = () => {
        api.patch(`cancel-subscription/`, {})
            .then((data) => {
                setConfirmSubModel(true);
            })
            .catch((err) => {
                ValidationErrorToast(err[0], err[1]);
            })
    }
    return (<>

        {confirmSubModel ? <div className="container-fluid d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
            <div style={{ width: "348px" }} className="  p-3 cancel-sub-box d-flex flex-column justify-content-between align-items-between">
                <div className="d-flex  justify-content-center align-items-between mb-3 position-relative">
                    <img src={UtilityFunctions.getImageURL() + "delete_job_icon.svg"} width={'26px'} height={'26px'} alt="" />

                    <div className="position-absolute " style={{ right: "0px", cursor: "pointer" }}
                        onClick={() => {
                            props.activeTabSet('defaultPage');
                            props.setCancelSub(true);
                        }}>
                        <img src={UtilityFunctions.getImageURL() + "cross-icon-white.svg"} width={'24px'} height={'24px'} alt="" />
                    </div>
                </div>
                <div className="cancel-heading mb-4">You have successfully cancelled your subscription plan.</div>
                <div className="cancel-sub-subHeading mb-3">Incase if you change your mind, Kindly revisit us & get the plan of your choice.</div>
            </div>
        </div>
            :
            <div className="container-fluid d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
                <div style={{ width: "348px" }} className="  p-3 cancel-sub-box">
                    <div className="d-flex flex-column  justify-content-center align-items-center mb-3">
                        <img src={UtilityFunctions.getImageURL() + "delete_job_icon.svg"} width={'26px'} height={'26px'} alt="" />
                    </div>
                    <div className="cancel-heading mb-4">Cancel your Subscription ?</div>
                    <div className="cancel-sub-subHeading mb-3">If you cancel your subscription, you will lose the premium services offered by MPLOI. Please press “Confirm” to process the cancellation of your subscription plan.</div>
                    <div className="cancel-sub-terms mb-3 "><span
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                            props.activeTabSet('terms')
                        }}>*Terms & Conditions</span> Applied</div>
                    <div className="d-flex gap-3 justify-content-center ">
                        <div className="cancel-sub-btn cancel-sub-btn-cancel px-5 py-3 " onClick={() => { props.activeTabSet('defaultPage') }}><span>Cancel</span></div>
                        <div className="cancel-sub-btn cancel-sub-btn-confirm px-5 py-3" onClick={() => { handleCancelSubscription() }}><span className="confirm">Confirm</span></div>
                    </div>
                </div>
            </div>
        }

    </>);
}

export default withRouter(CancelSubscription);