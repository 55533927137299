import React from "react";
import "./PricingCard.css"; // Import the CSS file for styling

// Define the interface for the props
interface PricingCardProps {
  title: string;
  subtitle: string;
  subscriptionType: string;
  pricingOptions: Array<{
    businessType: string;
    employeeCount: string;
    price: string;
  }>;
  buttonText: string;
  icon: string; // Updated to string to accept image path
}

const PricingCard: React.FC<PricingCardProps> = ({
  title,
  subtitle,
  subscriptionType,
  pricingOptions,
  buttonText,
  icon,
}) => {
  return (
    <div className="pricing-card">
      <div className="icon">
        <img src={icon} alt="Icon" />
      </div>
      <div className="title">{title}</div>
      <div className="subtitle">{subtitle}</div>
      <span className="subscription-type">{subscriptionType}</span>
      <ul>
        {pricingOptions.map((option, index) => (
          <li key={index}>
            <div className="business-type-wrapper">
              <div className="business-type-details">
                <div className="business-type">{option.businessType}</div>
                <div className="employee-count"><img src="/assets/images/employee-icon.png" alt=""/>{option.employeeCount}</div>
              </div>
              <div className="price">{option.price}</div>
            </div>
          </li>
        ))}
      </ul>
     <a href="/signup" target="_blank"><button className="get-started-button">{buttonText}  <img src="/assets/images/arrow-up-left-black.svg" alt=""/></button> </a> 
    </div>
  );
};

export default PricingCard;
