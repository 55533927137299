import moment from "moment";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { STRING_CONSTANTS } from "../../utils/constants/stringConstants";
import { UtilityFunctions } from "../../utils/UtilityFunctions";
import { cookieService, userService } from "../../_services";

const PreviousEmployers = (props) => {
  const {
    setSelectedEmployerObj,
    employerArray,
    setDeletedEmployerArr,
    setEmployerArray,
    deletedEmployerArr,
    setStep,
    nav,
    setSelectedQualificationObj
  } = props;
  const [showDiscardModal, setShowDiscardModal] = useState(false);

  // DISCARDS ALL DATA THAT IS NOT SAVED/PATCHED BY THE USER
  const discardData = () => {
    userService
      .getUserData()
      .then((res) => setEmployerArray(res?.experienceHistory));
    setSelectedEmployerObj({});
    setDeletedEmployerArr([]);
    setStep(1);
  };

  // DELETES AN EXISTING EXPERIENCE OBJECT FROM THE QUALIFICATION ARRAY
  const handleOnDelete = (deleteIndex: number) => {
    let filteredData = employerArray?.filter((item, index) => {
      if (index == deleteIndex && item?.id) {
        setDeletedEmployerArr((prev) => [...prev, item?.id]);
      }
      return index !== deleteIndex;
    });
    setEmployerArray(filteredData);
  };

  const submitDateHandler = () => {
    employerArray.forEach((employer) => {
      delete employer["indexToEdit"];
      delete employer["action"];
      if (employer?.employer) {
        employer["employer"] = employer?.employer?.id;
      }
      if (employer?.category?.[0]) {
        employer["category"] = [employer?.category?.[0]?.id];
      } else employer["category"] = null;
      if (employer?.subCategory?.[0]) {
        employer["subCategory"] = [employer?.subCategory?.[0]?.id];
      } else employer["subCategory"] = null;
    });
    const datatoPatch = { experienceHistory: employerArray };
    // console.log(datatoPatch)
    if (deletedEmployerArr.length) {
      datatoPatch["delete_experience_history"] = deletedEmployerArr;
    }
    userService
      .userProfile(datatoPatch)
      .then((res) => {
        cookieService.saveCookie(STRING_CONSTANTS.cookieStrings.user, res.data);
      })
      .then(()=>{
        nav.next()
        setSelectedQualificationObj({})
      });
  };

  return (
    <>
      <div className="top-divde-spa">
        <span>
          <i
            className="fa fa-angle-left text-white d-flex justify-content-between"
            aria-hidden="true"
            onClick={() => {
              setShowDiscardModal(true);
            }}
          ></i>
        </span>
        <div className="step-content signup-mar">
          <button type="button" disabled>
            {STRING_CONSTANTS.advanced_profile.step5.title}
          </button>
          <p className="mw-100">
            {STRING_CONSTANTS.advanced_profile.step5.subtitle}
          </p>
          <div className="step-text">
            {STRING_CONSTANTS.advanced_profile.step5.step}
          </div>
        </div>
      </div>
      <form>
        <div className="form-wrap business-detailss">
          <div className="update_my_experience_des v-advance-profile outer-set bg-transparent p-0 v-form-width min-height-auto">
            <ul className="current-employ-list list-unstyled ps-0 ms-0 text-white mb-3 d-grid gap-3">
              {employerArray?.map((employer, index) => {
                return (
                  <li key={employer?.employer?.id}>
                    <div className="update-my-ex mb-3">
                      <div className="d-flex align-items-center justify-content-between gap-3 mb-3">
                        <span className="d-flex align-items-center gap-3">
                          <img
                            src={
                              employer?.employer?.logo ||
                              UtilityFunctions.getImageURL() +
                                "bildeing-squre-img-pink.svg"
                            }
                            className="bilding-ig"
                            alt=""
                          />
                          <div className="v-text text-start">
                            <div className="upper-conts">
                              {employer?.unregisteredEmployer ||
                                employer?.employer?.tradingName}
                            </div>
                            {employer?.subCategory?.[0]?.name ? (
                              <p className="mb-0">
                                {employer?.subCategory?.[0]?.name}
                              </p>
                            ) : (
                              <p className="mb-0 text-custom-danger">
                                Not Selected
                              </p>
                            )}
                          </div>
                        </span>
                        <div className="d-flex gap-3 aligh-item-center">
                          <button
                            type="button"
                            className="bg-transparent shadow-none"
                            onClick={() => {
                              setSelectedEmployerObj({
                                ...employer,
                                indexToEdit: index,
                                action: "edit",
                                anotherEmployer: true,
                              });
                              setStep(2);
                            }}
                          >
                            <img
                              src={
                                UtilityFunctions.getImageURL() +
                                "edit-green.svg"
                              }
                              alt=""
                            />
                          </button>
                          <img
                            onClick={() => handleOnDelete(index)}
                            src={
                              UtilityFunctions.getImageURL() + "cross-pink.svg"
                            }
                            className="cross-icon cross-icon-pink"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="date-varigble-css d-flex align-items-center justify-content-between pe-3 gap-2">
                        {employer?.startDate ? (
                          <p className="mb-0 d-flex align-items-center gap-2">
                            <img
                              src={
                                UtilityFunctions.getImageURL() +
                                "calendar-fill-white.svg"
                              }
                              alt=""
                            />
                            {moment(employer?.startDate).format("DD/MM/YY")} -{" "}
                            {employer?.endDate
                              ? moment(employer?.endDate).format("DD/MM/YY")
                              : "Present"}
                          </p>
                        ) : (
                          <p className="mb-0 d-flex align-items-center gap-2 text-custom-danger">
                            <img
                              src={
                                UtilityFunctions.getImageURL() +
                                "calendar-fill-red.png"
                              }
                              alt=""
                            />
                            Unspecified
                          </p>
                        )}
                        {employer?.isProfileHidden ? (
                          <p className="mb-0 d-flex align-items-center gap-2">
                            <img
                              src={
                                UtilityFunctions.getImageURL() +
                                "EyeOff-gray.svg"
                              }
                              alt=""
                            />{" "}
                            Hidden Employer
                          </p>
                        ) : (
                          <p className="mb-0 d-flex align-items-center gap-2">
                            <img
                              src={UtilityFunctions.getImageURL() + "eyeOn.png"}
                              alt=""
                            />{" "}
                            Visible Employer
                          </p>
                        )}
                      </div>
                    </div>
                    <hr />
                  </li>
                );
              })}
            </ul>
            <button
              className="add-another-button bg-transparent border-0 text-white mx-auto d-flex align-items-center gap-2"
              onClick={() => {
                setSelectedEmployerObj({
                  action: "add",
                  anotherEmployer: true,
                });
                setStep(1);
              }}
            >
              <img
                src={UtilityFunctions.getImageURL() + "add-plus-icon-white.svg"}
                alt=""
              />
              Add Another Employer
            </button>
          </div>
        </div>
      </form>
      <div className="next-wrap">
        <button
          className={
            // selectedEmployersList?.length ?
            "cricle-next"
            // : "cricle-next-inactive"
          }
          onClick={(e) => submitDateHandler()}
        >
          <img
            alt=""
            src={UtilityFunctions.getImageURL() + "arrow-bottom.svg"}
          />
        </button>
      </div>
      <Modal
        className="bg-transparent logout-popup"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showDiscardModal}
        onHide={() => setShowDiscardModal(false)}
      >
        <Modal.Body>
          {/* <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0.5" y="0.5" width="25" height="25" rx="12.5" stroke="white" />
          <path d="M11.3125 11.875V16.375C11.3125 16.6857 11.5643 16.9375 11.875 16.9375C12.1857 16.9375 12.4375 16.6857 12.4375 16.375L12.4375 11.875C12.4375 11.5643 12.1857 11.3125 11.875 11.3125C11.5643 11.3125 11.3125 11.5643 11.3125 11.875ZM14.125 11.3125C14.4357 11.3125 14.6875 11.5643 14.6875 11.875V16.375C14.6875 16.6857 14.4357 16.9375 14.125 16.9375C13.8143 16.9375 13.5625 16.6857 13.5625 16.375V11.875C13.5625 11.5643 13.8143 11.3125 14.125 11.3125ZM15.25 8.5H18.625C18.9357 8.5 19.1875 8.75184 19.1875 9.0625C19.1875 9.37316 18.9357 9.625 18.625 9.625H18.0035L17.1564 17.2481C16.9982 18.6724 15.7943 19.75 14.3611 19.75H11.6388C10.2057 19.75 9.0018 18.6724 8.84354 17.2481L7.99653 9.625H7.375C7.06434 9.625 6.8125 9.37316 6.8125 9.0625C6.8125 8.75184 7.06434 8.5 7.375 8.5H10.75C10.75 7.25736 11.7574 6.25 13 6.25C14.2426 6.25 15.25 7.25736 15.25 8.5ZM13 7.375C12.3787 7.375 11.875 7.87868 11.875 8.5H14.125C14.125 7.87868 13.6213 7.375 13 7.375ZM9.12845 9.625L9.96166 17.1239C10.0566 17.9785 10.779 18.625 11.6388 18.625H14.3611C15.221 18.625 15.9434 17.9785 16.0383 17.1239L16.8715 9.625H9.12845Z" fill="white" />
        </svg> */}
          <img
            src={UtilityFunctions.getImageURL() + "delete-icon-white.svg"}
            alt=""
          />
          <h3 className="py-2">Discard Changes</h3>
          <p className="py-3">Are you sure you want to discard any changes?</p>
          <div>
            <Button
              className="ms-4 border-0 bg-transparent cancel-button"
              variant="primary"
              onClick={() => setShowDiscardModal(false)}
            >
              Cancel
            </Button>
            <Button
              className="ms-4 border-0"
              variant="primary"
              onClick={() => discardData()}
            >
              Confirm
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default withRouter(PreviousEmployers);
