import React, { useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { api } from "../middleware/api";
import { STRING_CONSTANTS } from "../utils/constants/stringConstants";
import { UtilityFunctions } from "../utils/UtilityFunctions";
import { cookieService, userService } from "../_services";
import SuccessToast from "./successToast";
import ValidationErrorToast from "./validationErrorToast";

const UpdateAbn = (props) => {

  const abnRef = useRef<any>();

  const abnInputHandler = event => {
    const result = event.target.value.replace(/\D/g, '');
    abnRef.current.value = result
  };

  const submitABN = () => {
    if (abnRef.current.value === props?.prevAbn) {
      ValidationErrorToast("Error!", "New ABN is the same as old ABN")
      abnRef.current.value = ""
    }
    else if (UtilityFunctions.isEmptyOrNullOrUndefined(abnRef.current.value)) {
      ValidationErrorToast(STRING_CONSTANTS.formLabelString.label_abn_number, STRING_CONSTANTS.validationString.req_abn);
    }
    else userService.
      userProfile({ "abn": abnRef.current.value })
      .then((res) => {
        props.setAbn(abnRef.current.value)
        SuccessToast("Success", "ABN updated successfully")
        props.handleClose()
      })
      .catch(e => ValidationErrorToast(e[0], e[1]))
  };

  return (
    <Modal
      className="bg-transparent upgrade-popup logout-popup settings-update-hash"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={props.show}
      onHide={props.handleClose}
    >
      <Modal.Body>
        <div style={{ maxWidth: "100%", margin: "0 auto" }}>
          {/* <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.98691 0.889534C9.0639 0.482538 8.79637 0.0901919 8.38938 0.0132045C7.98238 -0.0637829 7.59003 0.203742 7.51305 0.610738L6.49396 5.99815L1.74965 6.00037C1.33544 6.00056 0.999807 6.3365 1 6.75072C1.00019 7.16493 1.33614 7.50056 1.75035 7.50037L6.2102 7.49828L5.26445 12.498L0.74965 12.5001C0.335436 12.5003 -0.000193421 12.8363 8.36311e-08 13.2505C0.000193672 13.6647 0.336137 14.0003 0.75035 14.0001L4.98068 13.9981L4.01302 19.1137C3.93603 19.5207 4.20356 19.9131 4.61055 19.99C5.01755 20.067 5.4099 19.7995 5.48688 19.3925L6.50742 13.9974L11.9814 13.9949L11.0131 19.1137C10.9361 19.5207 11.2036 19.9131 11.6106 19.99C12.0176 20.067 12.41 19.7995 12.4869 19.3925L13.5081 13.9942L18.2503 13.9919C18.6646 13.9917 19.0002 13.6558 19 13.2416C18.9998 12.8274 18.6639 12.4918 18.2496 12.4919L13.7919 12.494L14.7376 7.4943L19.2503 7.49219C19.6646 7.49199 20.0002 7.15605 20 6.74184C19.9998 6.32762 19.6639 5.99199 19.2496 5.99219L15.0214 5.99416L15.987 0.889534C16.064 0.482538 15.7964 0.0901919 15.3894 0.0132045C14.9824 -0.0637829 14.5901 0.203742 14.5131 0.610738L13.4946 5.99488L8.0207 5.99744L8.98691 0.889534ZM7.73693 7.49757L13.2109 7.49501L12.2651 12.4947L6.79118 12.4973L7.73693 7.49757Z" fill="white" />
          </svg> */}
          <h3 className="py-2">Update ABN</h3>
          <p className="py-3">
            New ABN will update your Business name and Trading name
          </p>


          <div className="form-floating mb-3">
            <input type="text" className="form-control" aria-label="Username" aria-describedby="basic-addon1" ref={abnRef} onChange={(e) => abnInputHandler(e)} />
            <label htmlFor="floatingInputpwd">Enter ABN Number</label>
            <span className="input-group-text">
              <img src={UtilityFunctions.getImageURL() + "hashtag.svg"} alt="" />
              <img src={UtilityFunctions.getImageURL() + "hashtag-fill.svg"} alt="" />
              {/* <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.98691 0.889534C9.0639 0.482538 8.79637 0.0901919 8.38938 0.0132045C7.98238 -0.0637829 7.59003 0.203742 7.51305 0.610738L6.49396 5.99815L1.74965 6.00037C1.33544 6.00056 0.999807 6.3365 1 6.75072C1.00019 7.16493 1.33614 7.50056 1.75035 7.50037L6.2102 7.49828L5.26445 12.498L0.74965 12.5001C0.335436 12.5003 -0.000193421 12.8363 8.36311e-08 13.2505C0.000193672 13.6647 0.336137 14.0003 0.75035 14.0001L4.98068 13.9981L4.01302 19.1137C3.93603 19.5207 4.20356 19.9131 4.61055 19.99C5.01755 20.067 5.4099 19.7995 5.48688 19.3925L6.50742 13.9974L11.9814 13.9949L11.0131 19.1137C10.9361 19.5207 11.2036 19.9131 11.6106 19.99C12.0176 20.067 12.41 19.7995 12.4869 19.3925L13.5081 13.9942L18.2503 13.9919C18.6646 13.9917 19.0002 13.6558 19 13.2416C18.9998 12.8274 18.6639 12.4918 18.2496 12.4919L13.7919 12.494L14.7376 7.4943L19.2503 7.49219C19.6646 7.49199 20.0002 7.15605 20 6.74184C19.9998 6.32762 19.6639 5.99199 19.2496 5.99219L15.0214 5.99416L15.987 0.889534C16.064 0.482538 15.7964 0.0901919 15.3894 0.0132045C14.9824 -0.0637829 14.5901 0.203742 14.5131 0.610738L13.4946 5.99488L8.0207 5.99744L8.98691 0.889534ZM7.73693 7.49757L13.2109 7.49501L12.2651 12.4947L6.79118 12.4973L7.73693 7.49757Z" fill="white" />
              </svg> */}
              {/* <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.98691 0.889534C9.0639 0.482538 8.79637 0.0901919 8.38938 0.0132045C7.98238 -0.0637829 7.59003 0.203742 7.51305 0.610738L6.49396 5.99815L1.74965 6.00037C1.33544 6.00056 0.999807 6.3365 1 6.75072C1.00019 7.16493 1.33614 7.50056 1.75035 7.50037L6.2102 7.49828L5.26445 12.498L0.74965 12.5001C0.335436 12.5003 -0.000193421 12.8363 8.36311e-08 13.2505C0.000193672 13.6647 0.336137 14.0003 0.75035 14.0001L4.98068 13.9981L4.01302 19.1137C3.93603 19.5207 4.20356 19.9131 4.61055 19.99C5.01755 20.067 5.4099 19.7995 5.48688 19.3925L6.50742 13.9974L11.9814 13.9949L11.0131 19.1137C10.9361 19.5207 11.2036 19.9131 11.6106 19.99C12.0176 20.067 12.41 19.7995 12.4869 19.3925L13.5081 13.9942L18.2503 13.9919C18.6646 13.9917 19.0002 13.6558 19 13.2416C18.9998 12.8274 18.6639 12.4918 18.2496 12.4919L13.7919 12.494L14.7376 7.4943L19.2503 7.49219C19.6646 7.49199 20.0002 7.15605 20 6.74184C19.9998 6.32762 19.6639 5.99199 19.2496 5.99219L15.0214 5.99416L15.987 0.889534C16.064 0.482538 15.7964 0.0901919 15.3894 0.0132045C14.9824 -0.0637829 14.5901 0.203742 14.5131 0.610738L13.4946 5.99488L8.0207 5.99744L8.98691 0.889534ZM7.73693 7.49757L13.2109 7.49501L12.2651 12.4947L6.79118 12.4973L7.73693 7.49757Z" fill="#38BBF4" />
              </svg> */}
            </span>
          </div>


          <div className="d-grid d-md-flex gap-4 align-items-center justify-content-around">
            <Button className='border-0 bg-transparent cancel-button' variant="primary" onClick={props.handleClose}>
              Cancel
            </Button>
            <Button
              className="border-0"
              variant="primary"
              onClick={() => submitABN()}
            >
              Update
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>

  )
}
export default withRouter(UpdateAbn);