import React, { useState, useRef, useEffect } from "react";
import { withRouter } from "react-router-dom";
import SubsCard from "./SubsCard";
import Switch from "react-switch";
import "./SubscriptionInNav.css";
import { userService } from "../../../_services";
import PaymentProcedure from "../Home/PaymentProcedure";
import { api } from "../../../middleware/api";
import { STRING_CONSTANTS } from "../../../utils/constants/stringConstants";
import { UtilityFunctions } from "../../../utils/UtilityFunctions";

const SubscriptionInNav = (props: any) => {
  const [dotActive, setDotActive] = useState(1);
  const [chosePlan, setChosePlan] = useState({
    amount: "",
    name: "",
    id: "",
    interval: "",
    metadata: { staffUnder: "", originalPrice: "" },
  });
  const [yearPlan, setYearPlan] = useState([]);
  const [monthPlan, setMonthPlan] = useState([]);
  const [toggle, setToggle] = useState(true);
  const [changePlanModal, setChangePlanModal] = useState(false);
  const [productIdFromChild, setProductidFromChild] = useState();
  const scrollContainerRef = useRef(null);
  const toggleDot = (val) => {
    setDotActive(val);
  };

  const onChangePlan = () => {
    setChangePlanModal(false);
    if (productIdFromChild) {
      api.patch(`subscription/`, { "product_id": productIdFromChild })
        .then(() => {
          window.location.href = '/dashboard/settings?type=subscription';
        })
    }
  }

  useEffect(() => {
    userService.fetchChoseSubscription().then((res) => {
      setChosePlan(res);
      // console.log(res.subscriptionCanceled);
      // alert('ji');
    });
  }, []);

  useEffect(() => {
    userService.fetchYearSubscription().then((res) => {
      setYearPlan(res);
    });
  }, []);

  useEffect(() => {
    userService.fetchMonthSubscription().then((res) => {
      setMonthPlan(res);
    });
  }, []);

  const handleScroll = (val) => {
    if (val === "left") {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollLeft -= 315;
      }
    }

    if (val === "right") {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollLeft += 315;
      }
    }
  };

  return (
    <>
      <div className="position-relative">
        <div className="container-fluid title-row">
          <h1>Subscription</h1>
        </div>
        {Object.keys(chosePlan).length > 0 ? (
          <div className="container-fluid margn-top38   ">
            <div
              className="navSubscription container-fluid "
              style={{
                width: "fit-content",
                margin: "0px",
                paddingBottom: "15px",
              }}
            >
              <div className="container-fluid d-flex justify-content-between align-items-center">
                <p className="navSubsCardTitle" style={{ fontSize: "16px" }}>{`Current Plan ${chosePlan.interval === "month" ? "(Monthly)" : "(Yearly)"
                  }`}</p>
              </div>
              <div
                style={{ scrollBehavior: "smooth" }}
                className=" d-flex gap-4"
                ref={scrollContainerRef}
              >
                <div
                  className="sub-box"
                  style={{ width: "fit-content", padding: "10px" }}
                >
                  <div
                    className="d-flex"
                    style={{ justifyContent: "center", paddingLeft: "10px" }}
                  >
                    <div>
                      <div className="col-md-5 col-6 p-0">
                        <div
                          className="type-box"
                          style={{
                            border: "0.897px solid var(--Turquoise, #06EFC5)",
                            background: "var(--Turquoise, #06EFC5)",
                            width: "fit-content",
                          }}
                        >
                          <div style={{ color: "#000000" }}>{chosePlan.name}</div>
                        </div>
                      </div>
                      <div className="col-auto mt-3 p-0">
                        <div
                          className="price_nav"
                          style={{ textAlign: "inherit" }}
                        >
                          $ {chosePlan.amount}
                        </div>
                      </div>
                      {chosePlan.interval === "year" ? (
                        <div
                          className="d-flex"
                          style={{
                            alignItems: "center",
                            margin: "0px auto",
                            justifyContent: "center",
                          }}
                        >
                          <span
                            style={{
                              color: "#F2F2F2",
                              fontSize: "30px",
                              textDecoration: "line-through",
                              textDecorationColor: "var(--Turquoise, #06EFC5)",
                            }}
                          >
                            $ {chosePlan.metadata.originalPrice}
                          </span>
                          <span
                            style={{
                              color: "#FFFFFF",
                              fontSize: "14px",
                              paddingLeft: "10px",
                            }}
                          >
                            /{chosePlan.interval}
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="list-add">
                      <div className="row w-100 list-row mt-1">
                        <div className="col-auto d-flex align-items-center ">
                          <div className="col-auto">
                            <img
                              src="/assets/images/Checkmark.png"
                              className="check"
                            />
                          </div>
                          <div className="col-auto" style={{ paddingLeft: "0px" }}>
                            <div className="list-item">Unlimited Job Posts</div>
                          </div>
                        </div>
                      </div>
                      <div className="row w-100 list-row mt-1">
                        <div className="col-auto d-flex align-items-center ">
                          <div className="col-auto">
                            <img
                              src="/assets/images/Checkmark.png"
                              className="check"
                            />
                          </div>
                          <div className="col-auto" style={{ paddingLeft: "0px" }}>
                            <div className="list-item">Instant Matching to Candidates</div>
                          </div>
                        </div>
                      </div>
                      <div className="row w-100 list-row mt-1">
                        <div className="col-auto d-flex align-items-center">
                          <div className="col-auto">
                            <img
                              src="/assets/images/Checkmark.png"
                              className="check"
                            />
                          </div>
                          <div className="col-auto" style={{ paddingLeft: "0px" }}>
                            <div className="list-item">Direct Chat with Candidates</div>
                          </div>
                        </div>
                      </div>
                      <div className="row w-100 list-row mt-1">
                        <div className="col-auto d-flex align-items-center">
                          <div className="col-auto">
                            <img
                              src="/assets/images/Checkmark.png"
                              className="check"
                            />
                          </div>
                          <div className="col-auto" style={{ paddingLeft: "0px" }}>
                            <div className="list-item">Fixed Price. No Add-Ons</div>
                          </div>
                        </div>
                      </div>

                      <div className="row w-100 list-row mt-1">
                        <div className="col-auto d-flex align-items-center">
                          <div className="col-auto">
                            <img
                              src="/assets/images/Checkmark.png"
                              className="check"
                            />
                          </div>
                          <div className="col-auto" style={{ paddingLeft: "0px" }}>
                            <div className="list-item">NO RECRUITMENT AGENCIES</div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="navSubscription container-fluid ms-0"
            // style={{ width: "746px" }}
            >

              {
                (chosePlan?.subscriptionCanceled) ?
                  <div className="container-fluid d-flex justify-content-center align-items-center" style={{ height: "50vh" }}>
                    <div style={{ width: "348px" }} className="  p-3 cancel-sub-box">

                      <div className="cancel-heading mb-4">Upgrade/Downgrade Restricted !</div>

                      <div className="cancel-sub-subHeading mb-3">You have already cancelled your current subscription so you cannot upgrade and downgrade until the billing cycle is over and new subscription is purchased.</div>

                    </div>
                  </div> :
                  <><div className="container-fluid d-flex justify-content-between align-items-center">
                    <div>
                      <p
                        className="navSubsCardTitle"
                        style={{ marginBottom: "10px", fontSize: "16px" }}
                      >
                        Change Subscription Plan
                      </p>
                      <p
                        className="navSubsCardTitle"
                        style={{
                          fontWeight: "300",
                          fontSize: "14px",
                          marginTop: "0px",
                        }}
                      >
                        Choose the plan based on the size of your team.
                      </p>
                    </div>
                    <div className="d-flex" style={{ alignItems: "center" }}>
                      <Switch
                        className="sw"
                        checked={toggle}
                        onChange={() => setToggle(!toggle)}
                        onColor="#110128"
                        offColor="#110128"
                        uncheckedIcon={false}
                        checkedIcon={false}
                      />
                      {toggle === true ? (
                        <span style={{ color: "#FFFFFF", paddingLeft: "10px" }}>
                          Yearly
                        </span>
                      ) : (
                        <span style={{ color: "#FFFFFF", paddingLeft: "10px" }}>
                          Monthly
                        </span>
                      )}
                    </div>
                  </div>
                    <div
                      style={{ scrollBehavior: "smooth", padding: "30px 6px 10px" }}
                      className=" d-flex gap-4 overflow-scroll"
                      ref={scrollContainerRef}
                    >
                      {toggle === true
                        ? yearPlan
                          .filter((element) => element.id !== chosePlan.id)
                          .map((element, index) => {
                            return (
                              <SubsCard
                                title={element.name}
                                price={`$ ${element.prices[0].unitAmount}`}
                                orignalPrice={`$ ${element.originalPrice}`}
                                discount={element.discount}
                                staff={`Upto ${element.staffUnder} Staff`}
                                cardKey={index}
                                product__Id={element.id}
                                setProductIdFromChild={(data) => setProductidFromChild(data)}
                                setChangePlan={(val) => setChangePlanModal(val)}
                              // toggleValue={true}
                              />
                            );
                          })
                        : monthPlan
                          .filter((element) => element.id !== chosePlan.id)
                          .map((element, index) => {
                            return (
                              <SubsCard
                                title={element.name}
                                price={`$ ${element.prices[0].unitAmount}`}
                                staff={`Upto ${element.staffUnder} Staff`}
                                monthly="true"
                                cardKey={index}
                                product__Id={element.id}
                                setProductIdFromChild={(data) => setProductidFromChild(data)}
                                setChangePlan={(val) => setChangePlanModal(val)}
                              // toggleValue={false}
                              />
                            );
                          })}

                      <SubsCard
                        imgsrc="/assets/images/badge-lev3.svg"
                        title="Over 35 Staff"
                        price="Contact Sales Team"
                        advance="true"
                        staff="Over 35 Staff"
                      />
                    </div></>
              }



            </div>



          </div>
        ) : (
          <div className="container-fluid margn-top38   ">
            <div className="des" style={{ width: "445px" }}>
              <span className="noSub">
                You have no active subscription, please subscribe to access our
                premium services.
              </span>
            </div>
            <div className="navSubscription container-fluid">
              <div className="container-fluid d-flex justify-content-between align-items-center">
                <div className="d-flex" style={{ margin: "20px auto" }}>
                  <div className="col-auto mt-4 mon">Monthly</div>

                  <div className="col-auto mt-4">
                    <Switch
                      checked={toggle}
                      onChange={() => setToggle(!toggle)}
                      onColor="#110128"
                      offColor="#110128"
                      uncheckedIcon={false}
                      checkedIcon={false}
                    />
                  </div>
                  <div className="col-auto mt-4" style={{ textAlign: "left" }}>
                    <div className="mon">Yearly</div>
                    <div className="mon" style={{ fontSize: "14px" }}>
                      (get 20% discount)
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{ scrollBehavior: "smooth", padding: "30px 6px 10px" }}
                className=" d-flex gap-1 overflow-scroll"
                ref={scrollContainerRef}
              >
                {toggle === true
                  ? yearPlan.map((element, index) => {
                    return (
                      <SubsCard
                        title={element.name}
                        price={`$ ${element.prices[0].unitAmount}`}
                        orignalPrice={`$ ${element.originalPrice}`}
                        discount={element.discount}
                        staff={`Upto ${element.staffUnder} Staff`}
                        cardKey={index}
                        prdct_id={element.id}
                        toggleValueCard={true}
                      />
                    );
                  })
                  : monthPlan.map((element, index) => {
                    return (
                      <SubsCard
                        title={element.name}
                        price={`$ ${element.prices[0].unitAmount}`}
                        staff={`Upto ${element.staffUnder} Staff`}
                        monthly="true"
                        cardKey={index}
                        prdct_id={element.id}
                        toggleValueCard={false}
                      />
                    );
                  })}

                <SubsCard
                  imgsrc="/assets/images/badge-lev3.svg"
                  title="Over 35 Staff"
                  price="Contact Sales Team"
                  advance="true"
                  staff="Over 35 Staff"
                  id=""
                  toggleValueCard={toggle ? true : false}
                />
              </div>
            </div>
          </div>
        )}
        {/* {changePlanModal && <div className="bg-white position-absolute top-0" style={{ width: "100%", height: "100%" }}>black</div>} */}
        {changePlanModal && <div style={{ height: "100%" }} className="container-fluid position-absolute outer_container top-0" >
          <div className=" d-flex align-items-center justify-content-center" style={{ width: "100%", height: "100%" }}>
            <div className=" d-flex justify-content-center align-items-center " >
              <div className=" pay_box p-3" >

                <div className=" container-fluid pay_title my-6">Are you sure you want to change your plan</div>
                {/* <div className=" container-fluid pay_desc">To continue payment procedure please add your card details.</div> */}

                <div className="d-flex gap-3 justify-content-center align-items-center my-4">
                  <div className="btn_pay_continue" onClick={() => onChangePlan()} >
                    <div className="continue_text">YES</div>
                  </div>
                  <div className="btn_pay_continue" onClick={() => setChangePlanModal(false)}>
                    <div className="continue_text" >NO</div>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>}
      </div>

    </>
  );
};

export default withRouter(SubscriptionInNav);
