import React from "react";
import { toast } from 'react-toastify';
import { UtilityFunctions } from "../../src/utils/UtilityFunctions";

const ToastHeader = (err: any) => {
  return (
    (err && !err.length ? "" : toast(<div>
      <span className="toast-header">{err && err[0]}</span>
      <br />
      {err && err[1]}
    </div>, {
      icon: ({ theme, type }) => <img alt="" className="toast-icon" src={UtilityFunctions.getImageURL() + "error-toast-icon.svg"} />
    })

    ))
}
export default ToastHeader;