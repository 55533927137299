import React from "react";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { useDispatch } from "react-redux";
import { actionCreators } from "../../../store";

import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";

import { api } from "../../../middleware/api";
import { UtilityFunctions } from "../../../utils/UtilityFunctions";
import { NOTIFICATION_TYPES, NOTIFICATION_TYPES_2 } from '../../../utils/notificationTypes'

const NotificationsList = (props) => {
    const dispatch = useDispatch();
    const { setUnreadNotificationCount } = bindActionCreators(actionCreators, dispatch);

    const updateUnreadCount = (id: string) => {
        api.get(`user-notifications/${id}/`, null)
            .then(res =>
                api.get('user-notifications/?limit=1', null)
                    .then(res =>
                        setUnreadNotificationCount(res?.results?.unreadNotificationCount)
                    )
            )
            .catch(
            // e => console.log(e)
        )
    }

    const renderNotifications = props?.notifications?.map((notification) => {
        return (
            <div className={`nottifications-list-set d-grid d-lg-flex ${notification.isRead === false && "active"}`}
                data-bs-toggle={
                    notification?.notificationType === "chat_request_sent" ||
                        // notification?.notificationType === "chat_request_rejected" ||
                        notification?.notificationType === "chat_request_recieved" || notification?.notificationType == "chat_request_received" ? "modal" : ""}
                data-bs-target={notification?.notificationType === "chat_request_sent" ||
                    // notification?.notificationType === "chat_request_rejected" ||
                    notification?.notificationType === "chat_request_recieved" || notification?.notificationType == "chat_request_received" ? "#staticBackdrop" : ""}
                key={notification?.id}
                onClick={() => {
                    notification.isRead = true
                    props?.redirectionHandler(notification?.notificationType, notification?.extraData)
                    updateUnreadCount(notification?.id)
                }}>
                {
                    // NOTIFICATION_TYPES.map(notif => notif.key === notification.notificationType ?
                    //     <img src={notif.image} key={notification?.id} /> : null)
                    (NOTIFICATION_TYPES_2[notification.notificationType]) ?
                        <img src={NOTIFICATION_TYPES_2[notification.notificationType]} key={notification?.id} />
                        : null
                }
                < div className="nottifications-data-set" >
                    <h5>{notification?.title} <span>{moment(notification?.createdAt).fromNow()}
                    </span>
                    </h5>
                    {notification?.body ? <p>{notification?.body}</p> : ""}
                </div >
            </div >
        )
    })

    const fetchMoreData = () => {
        setTimeout(() => {
            if (props.hasMore) {
                const nextURL = props.nextURL?.split(UtilityFunctions.API_URL())?.[1]
                api.get(nextURL, null)
                    .then((res) => {
                        if (res?.next) {
                            props.setNextURL(res?.next);
                            props.setHasMore(true);
                        } else {
                            props.setHasMore(false);
                        }
                        props.setNotifications(res?.results?.results?.length > 0 ? res?.results?.results : []);

                    });
            }
        }, 100);
    };

    return (
        <div
            id="scrollableDiv1"
            style={{ height: 1000, overflow: "auto" }} className="chat-list-data px-0"
        >
            <InfiniteScroll
                dataLength={props.notifications.length}
                next={fetchMoreData}
                hasMore=
                {props.hasMore}
                //style={{ display: 'flex', flexDirection: 'column-reverse' }} //To put endMessage and loader to the top.
                loader={<h4 className='text-white text-center pt-4'>Loading...</h4>}
                scrollableTarget="scrollableDiv1"
            >
                {renderNotifications}
            </InfiniteScroll>
        </div>
    )
}

export default withRouter(NotificationsList);