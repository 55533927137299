import React, { useEffect, useState } from "react";
import { UtilityFunctions } from "../../src/utils/UtilityFunctions";
import { Button, Modal } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { cookieService, userService } from "../_services";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../store";
import { STRING_CONSTANTS } from "../utils/constants/stringConstants";
import { checkData } from "../utils/checkData";
import ValidationErrorToast from "./validationErrorToast";
import SuccessToast from "./successToast";

const DeleteAccount = (props) => {
  const [text, setText] = useState("");
  const dispatch = useDispatch();
  const { closeOnlineOfflineSocket } = bindActionCreators(
    actionCreators,
    dispatch
  );

  const handleSubmitPassword = () => {
    if (text.toLowerCase() === "delete") {
      closeOnlineOfflineSocket(true);
      props.handleClosePopup();
      userService.deleteAccount()
        .then((res) => {
          SuccessToast(STRING_CONSTANTS.formLabelString.label_account, res.data.user);
          cookieService.removeAllCookies();
          props.history.push(STRING_CONSTANTS.navigationString.login);
        })
        .catch((err) => {
          console.log(err)
          ValidationErrorToast(err[0], err[1])
        })
    }
    else {
      ValidationErrorToast(STRING_CONSTANTS.formLabelString.label_delete_account, STRING_CONSTANTS.validationString.req_del_field);
    }
  }

  return (
    <Modal
      className="bg-transparent upgrade-popup logout-popup"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={props.showPopup}
      onHide={() => {
        props.handleClosePopup()
        setText("");
      }}
    >
      <Modal.Body>
        <div style={{ maxWidth: "400px", margin: "0 auto" }}>
          <img src={UtilityFunctions.getImageURL() + "delete_job_icon.svg"} width={'26px'} height={'26px'} alt="" />

          <h3 className="py-2">{STRING_CONSTANTS.formLabelString.label_delete_account}</h3>
          <p className="py-3">
            {STRING_CONSTANTS.modal_text.delete_text}
          </p>

          <div className="form-floating mb-3">
            <input type="text" id="floatingInputpwd1" className={`form-control`} placeholder="Enter Delete" name='delete' value={text} onChange={(e) => { setText(e.target.value); }} />
            <label htmlFor="floatingInputpwd">Enter Delete</label>
            <span className="input-group-text">
              {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.5">
                  <path d="M21.0304 2.96997C22.4279 4.36743 22.4279 6.63317 21.0304 8.03063L9.0621 19.999C8.78522 20.2758 8.44089 20.4757 8.06312 20.5787L2.94743 21.9739C2.38756 22.1266 1.87383 21.6128 2.02652 21.053L3.42171 15.9373C3.52474 15.5595 3.72456 15.2152 4.00144 14.9383L15.9698 2.96997C17.3672 1.57251 19.633 1.57251 21.0304 2.96997ZM15.0003 6.06088L5.0621 15.999C4.96981 16.0913 4.9032 16.206 4.86886 16.332L3.81901 20.1814L7.66845 19.1315C7.79437 19.0972 7.90915 19.0306 8.00144 18.9383L17.9393 8.99988L15.0003 6.06088ZM17.0304 4.03063L16.0603 4.99988L18.9993 7.93988L19.9698 6.96997C20.7814 6.1583 20.7814 4.84231 19.9698 4.03063C19.1581 3.21896 17.8421 3.21896 17.0304 4.03063Z" fill="white" />
                </g>
              </svg> */}
              <img src={UtilityFunctions.getImageURL() + "edit.svg"} alt="" />
              <img src={UtilityFunctions.getImageURL() + "edit-fill.svg"} alt="" />
              {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21.0304 2.96997C22.4279 4.36743 22.4279 6.63317 21.0304 8.03063L9.0621 19.999C8.78522 20.2758 8.44089 20.4757 8.06312 20.5787L2.94743 21.9739C2.38756 22.1266 1.87383 21.6128 2.02652 21.053L3.42171 15.9373C3.52474 15.5595 3.72456 15.2152 4.00144 14.9383L15.9698 2.96997C17.3672 1.57251 19.633 1.57251 21.0304 2.96997ZM15.0003 6.06088L5.0621 15.999C4.96981 16.0913 4.9032 16.206 4.86886 16.332L3.81901 20.1814L7.66845 19.1315C7.79437 19.0972 7.90915 19.0306 8.00144 18.9383L17.9393 8.99988L15.0003 6.06088ZM17.0304 4.03063L16.0603 4.99988L18.9993 7.93988L19.9698 6.96997C20.7814 6.1583 20.7814 4.84231 19.9698 4.03063C19.1581 3.21896 17.8421 3.21896 17.0304 4.03063Z" fill="#38BBF4" />
              </svg> */}
            </span>
          </div>
          <div>
            <Button className='ms-4 border-0 bg-transparent cancel-button' variant="primary" onClick={() => {
              setText("");
              props.handleClosePopup()
            }}>
              Cancel
            </Button>
            <Button
              className="ms-4 border-0"
              variant="primary"
              onClick={handleSubmitPassword}
            >
              Confirm
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>

  )
}
export default withRouter(DeleteAccount);