// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accordion-button{
    background: #443A50;
    color: white;
}

.accordion-item{
    border: 0;
    background: none;
}

.accordion-button:focus{
    box-shadow: none;
}

`, "",{"version":3,"sources":["webpack://./src/components/CustomAccordian.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,SAAS;IACT,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".accordion-button{\n    background: #443A50;\n    color: white;\n}\n\n.accordion-item{\n    border: 0;\n    background: none;\n}\n\n.accordion-button:focus{\n    box-shadow: none;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
