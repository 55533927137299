import { Action } from "../actions/index";
import { ActionType } from "../constants";
import { get } from "lodash";

const initialState = {
    'selectedPlan': '',
    'clientSecret': '',
    'mobileNumber': '',
};

const reducer = (state=initialState, action: Action) => {
    switch(action.type) {
        case ActionType.SELECTED_PLAN: {
            
            state = {...state, 'selectedPlan': get(action, 'item')}
            return state
        }
        case ActionType.CLIENT_SECRET: {
            
            state = {...state, 'clientSecret': get(action, 'item')}
            return state
        }
        default: 
            return state;
    }
}

export default reducer;